import React from "react";
import PropTypes from "prop-types";
import { OMSButtonStyleL } from "../../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";
import { OMSNumericBox } from "../../../../app/common/common-components/OMSComponent";
import { OMSMultiselectDropdown2 } from "../../../../app/common/common-components/OMSMultiselectDropdown";
import DatePicker from "react-datepicker";

const moment = require("moment");

export class ImportFeaturesStepView extends React.Component {
    static propTypes = {
        data: PropTypes.object
    };

    getStateFromData = (data) => {

        var ExternalFields = [
            { field: "Covered Parking", default: false, type: "numeric" },
            {
              field: "Electrical Supply",
              default: false,
              type: "multiselect",
              options:
                "Backup Battery,Backup Generator,Prepaid Electricity,Single Phase Power,Solar Panels,Three Phase Power,Wind Turbine",
            },
            { field: "Fibre Internet", default: false, type: "boolean" },
            { field: "Flatlet", default: false, type: "boolean" },
            { field: "Garages", default: true, type: "numeric" },
            { field: "Garden", default: true, type: "boolean" },
            { field: "No Pets Allowed", default: false, type: "boolean" },
            { field: "Open Parking", default: false, type: "numeric" },
            { field: "Pets Allowed", default: false, type: "boolean" },
            { field: "Pool", default: false, type: "boolean" },
            {
              field: "Roof Type",
              default: false,
              type: "dropdown",
              options:
                "Aluminium,Asbestos,Concrete,Flat,Iron,Slate,Steel,Tiled,Thatch,Other",
            },
            { field: "Security", default: true, type: "boolean" },
            { field: "Staff Accommodation", default: false, type: "boolean" },
            {
              field: "Wall Type",
              default: false,
              type: "dropdown",
              options:
                "Clinker Brick,Dry Wall,Face Brick,Face Brick and Plaster,Nutec,Plaster,Prefab,Other",
            },
            {
              field: "Water Supply",
              default: false,
              type: "multiselect",
              options:
                "Borehole,Dam Water,Municipal Water,Prepaid Water,Rain Water Harvesting",
            },
          ];


        var mBuiltDate = data.built === "" ? null : moment(data.built, "DD-MM-yyyy"); 

        return {
            Step: 'Listing capture: Features',
            PropertyID: this.ToString(data.propertyID),
            ImportID: this.ToString(data.importID),
            Classification: this.ToString(data.classification),
            Type: this.ToString(data.type),
            Ownership: this.ToString(data.ownership),
            NewDevelopment: this.ToString(data.newDevelopment),
            Zoning: this.ToString(data.zoning),
            //Built: this.ToString(data.built),
            Built: mBuiltDate ? mBuiltDate.toDate() : null,
            ErfSize: this.ToString(data.erfSize),
            ErfSizeUnits: this.ToString(data.erfSizeUnits),
            FloorSize: this.ToString(data.floorSize),
            FloorSizeUnits: this.ToString(data.floorSizeUnits),
            RatesTaxes: this.ToString(data.ratesTaxes),
            RatesTaxesUnit: this.ToString(data.ratesTaxesUnit),
            Levy: this.ToString(data.levy),
            LevyUnit: this.ToString(data.levyUnit),
            Facing: this.ToString(data.facing),
            Rooms: JSON.parse(data.rooms),
            External: JSON.parse(data.external),
            ClassificationDD: [],
            OwnershipDD: [],
            TypeDD: [],
            RoomFields: [{ field: 'Bedrooms', default: true, type: 'numeric' },
            { field: 'Bathrooms', default: true, type: 'numeric' },
            { field: 'Living Areas', default: true, type: 'numeric' },
            { field: 'Study', default: false, type: 'numeric' },
            { field: 'Furnished', default: false, type: 'boolean' }
            ],
            ExternalFields: ExternalFields,
            mLabel: '',
            mFields: [],
            mField: '',
            mFieldItems: []
        }
    };

    componentDidMount() {
        this.loadCountrySettingsDropdown(this.state.Classification);

        /* window.$('#Built').datetimepicker({
            format: 'DD-MM-YYYY',
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fa fa-arrow-left",
                next: "fa fa-arrow-right",
                today: 'fa fa-screenshot',
                clear: 'fa fa-trash',
                close: 'fa fa-remove'
            }
        }); */

       /*  window.$('#Built').on('dp.change', function (e) {
            var dateString = window.$('#Built').val();
            this.setState({ Built: dateString });
        }.bind(this)); */
    }

    doSetFormData = (data) => {
        /* data.append('Features.PropertyID', this.state.PropertyID);
        data.append('Features.ImportID', this.state.ImportID);
        data.append('Features.Classification', this.state.Classification);
        data.append('Features.Type', this.state.Type);
        data.append('Features.Ownership', this.state.Ownership);
        data.append('Features.NewDevelopment', this.state.NewDevelopment);
        data.append('Features.Zoning', this.state.Zoning);
        data.append('Features.Built', this.state.Built);

        data.append('Features.ErfSize', this.state.ErfSize);
        data.append('Features.ErfSizeUnits', this.state.ErfSizeUnits);
        data.append('Features.FloorSize', this.state.FloorSize);
        data.append('Features.FloorSizeUnits', this.state.FloorSizeUnits);
        data.append('Features.RatesTaxes', this.state.RatesTaxes.replace(',', ''));
        data.append('Features.RatesTaxesUnit', this.state.RatesTaxesUnit);
        data.append('Features.Levy', this.state.Levy.replace(',', ''));
        data.append('Features.LevyUnit', this.state.LevyUnit);
        data.append('Features.Facing', this.state.Facing);

        data.append('Features.Rooms', JSON.stringify(this.state.Rooms));
        data.append('Features.External', JSON.stringify(this.state.External)); */
        const featuresData = {
            PropertyID: this.state.PropertyID,
            ImportID: this.state.ImportID,
            Classification: this.state.Classification,
            Type: this.state.Type,
            Ownership: this.state.Ownership,
            NewDevelopment: this.state.NewDevelopment,
            Zoning: this.state.Zoning,
            Built: this.state.Built,
            ErfSize: this.state.ErfSize,
            ErfSizeUnits: this.state.ErfSizeUnits,
            FloorSize: this.state.FloorSize,
            FloorSizeUnits: this.state.FloorSizeUnits,
            RatesTaxes: this.state.RatesTaxes.replace(',', ''),
            RatesTaxesUnit: this.state.RatesTaxesUnit,
            Levy: this.state.Levy.replace(',', ''),
            LevyUnit: this.state.LevyUnit,
            Facing: this.state.Facing,
            Rooms: JSON.stringify(this.state.Rooms),
            External: JSON.stringify(this.state.External),
        };
        data.append('FeaturesJson', JSON.stringify(featuresData));
    };

    doCheckFormData = () => {
        var result = { status: 'error', message: 'Fields are required.' };
        var resultSuccess = { status: 'success', message: '' };

        if (!this.state.Classification) {
            result.message = "Please select property Classification";
            return result;
        }
        else if (!this.state.Type) {
            result.message = "Please select property type";
            return result;
        }
        else if (!this.state.Ownership) {
            result.message = "Please select property ownership type";
            return result;
        }
        return resultSuccess;
    };

    doSetState = (data) => {
        this.setState(this.getStateFromData(data), function () {
            this.loadCountrySettingsDropdown(this.state.Classification);
        });
    };

    doGetState = () => {
        return this.state;
    };

    ToString = (value) => {
        if (value === undefined || value == null)
            return "";
        else
            return value;
    };

    loadCountrySettingsDropdown = (classification) => {
        var ownershipDD = [];
        var classificationDD = [];

        ownershipDD.push({ Text: '- Select -', Value: '' });
        ownershipDD = ownershipDD.concat(this.props.OwnershipTypes.map(function (item) {
            return { Text: item, Value: item };
        }));

        classificationDD.push({ Text: '- Select -', Value: '' });
        classificationDD = classificationDD.concat(this.props.PropertyTypes.map(function (item) {
            return { Text: item.classification, Value: item.classification };
        }));
        this.setState({ ClassificationDD: classificationDD, OwnershipDD: ownershipDD });
        this.loadTypeDD(classification);
    };

    loadTypeDD = (classification) => {
        var typeDD = [];
        typeDD.push({ Text: '- Select -', Value: '' });
        for (var i = 0; i < this.props.PropertyTypes.length; i++) {
            if (this.props.PropertyTypes[i].classification == classification) {
                typeDD = typeDD.concat(this.props.PropertyTypes[i].types.map(function (item) {
                    return { Text: item, Value: item };
                }));
            }
        }
        this.setState({ TypeDD: typeDD });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleDropdownChange = (e) => {
        this.setState({ [e.name]: e.newValue });
        if (e.name == "Classification")
            this.loadTypeDD(e.newValue);
    };

    handleNumericChange = (name, value) => {
        this.setState({ [name]: value });
    };

    handleNewDevelopmentChange = (e) => {
        var NewDevelopment = "N";
        if (e.target.checked)
            NewDevelopment = "Y"
        this.setState({ NewDevelopment: NewDevelopment });
    };

    handleUpdateRoomClick = (e) => {

        var mFieldItems = this.state.Rooms.slice(0);
        if (mFieldItems.length == 0) {
            for (var i = 0; i < this.state.RoomFields.length; i++) {
                if (this.state.RoomFields[i].default == true) {
                    mFieldItems.push(this.getDefaultFieldItem(this.state.RoomFields[i]));
                }
            }
        }
        else {
            for (var i = 0; i < this.state.RoomFields.length; i++) {
                for (var j = 0; j < mFieldItems.length; j++) {
                    if (this.state.RoomFields[i].field == mFieldItems[j].field) {
                        mFieldItems[j].options = this.state.RoomFields[i].options;
                    }
                }
            }
        }

        this.setState({ mLabel: 'Rooms', mField: '', mFields: this.getFieldsDropdownData(this.state.RoomFields, mFieldItems), mFieldItems: mFieldItems });
        window.$('#FeaturesFieldModal').modal('show');
    };

    handleUpdateExternalClick = (e) => {
        var mFieldItems = this.state.External.slice(0);
        if (mFieldItems.length == 0) {
            for (var i = 0; i < this.state.ExternalFields.length; i++) {
                if (this.state.ExternalFields[i].default == true) {
                    mFieldItems.push(this.getDefaultFieldItem(this.state.ExternalFields[i]));
                }
            }
        }
        else {

            for (var i = 0; i < mFieldItems.length; i++) {
                if (mFieldItems[i].field == "WiFi / Fibre to the home") {
                    mFieldItems[i].field = "Fibre Internet";
                }
            }
            //console.log(mFieldItems);

            for (var i = 0; i < this.state.ExternalFields.length; i++) {
                for (var j = 0; j < mFieldItems.length; j++) {
                    if (this.state.ExternalFields[i].field == mFieldItems[j].field) {
                        mFieldItems[j].options = this.state.ExternalFields[i].options;
                        if (mFieldItems[j].type == "multiselect") {
                            var arr = mFieldItems[j].value.split(',');
                            for (var index = 0; index < arr.length; index++) {
                                arr[index] = arr[index].trim();
                                if (arr[index] == "Municipal")
                                    arr[index] = "Municipal Water";
                                else if (arr[index] == "Dam")
                                    arr[index] = "Dam Water";
                                else if (arr[index] == "Rainwater Harvesting")
                                    arr[index] = "Rain Water Harvesting";
                            }
                            mFieldItems[j].values = arr;
                        }
                    }
                }
            }
        }
        this.setState({ mLabel: 'External', mField: '', mFields: this.getFieldsDropdownData(this.state.ExternalFields, mFieldItems), mFieldItems: mFieldItems });
        window.$('#FeaturesFieldModal').modal('show');
    };

    getDefaultFieldItem = (fieldItem) => {
        var defaultValue = "";
        if (fieldItem.type == "boolean")
            defaultValue = "1";
        else if (fieldItem.type == "numeric")
            defaultValue = "1";
        else if (fieldItem.type == "dropdown")
            defaultValue = fieldItem.options.split(',')[0];

        return {
            field: fieldItem.field,
            type: fieldItem.type,
            value: defaultValue,
            options: fieldItem.options
        }
    };

    getFieldsDropdownData = (fieldItems, selectedItems) => {

        var mFields = [];
        mFields.push({ field: '', type: '', options: '' });

        var selectedItemFields = selectedItems.map(function (I) {
            return I.field;
        });

        for (var i = 0; i < fieldItems.length; i++) {
            if (selectedItemFields.indexOf(fieldItems[i].field) < 0) {
                mFields.push({ field: fieldItems[i].field, type: fieldItems[i].type, options: fieldItems[i].options })
            }
        }
        return mFields;
    };

    onAddFeatureFieldClick = (e) => {
        if (this.state.mField !== undefined && this.state.mField != null && this.state.mField != "") {
            var index = -1;
            for (var i = 0; i < this.state.mFields.length; i++) {
                if (this.state.mFields[i].field == this.state.mField) {
                    index = i;
                    break;
                }
            }

            if (index > 0) {
                var mFieldItems = this.state.mFieldItems;
                mFieldItems.push(this.getDefaultFieldItem(this.state.mFields[index]));
                if (this.state.mLabel == "Rooms")
                    this.setState({ mFieldItems: mFieldItems, mField: '', mFields: this.getFieldsDropdownData(this.state.RoomFields, mFieldItems) });
                else
                    this.setState({ mFieldItems: mFieldItems, mField: '', mFields: this.getFieldsDropdownData(this.state.ExternalFields, mFieldItems) });
            }
        }
    };

    onRemoveFeatureFieldClick = (field) => {
        if (field !== undefined && field != null && field != "") {
            var index = -1;
            for (var i = 0; i < this.state.mFieldItems.length; i++) {
                if (this.state.mFieldItems[i].field == field) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                var mFieldItems = this.state.mFieldItems;
                mFieldItems.splice(index, 1);
                if (this.state.mLabel == "Rooms")
                    this.setState({ mFieldItems: mFieldItems, mField: '', mFields: this.getFieldsDropdownData(this.state.RoomFields, mFieldItems) });
                else
                    this.setState({ mFieldItems: mFieldItems, mField: '', mFields: this.getFieldsDropdownData(this.state.ExternalFields, mFieldItems) });
            }
        }
    };

    onSaveFeatureFieldsClick = (e) => {
        if (this.state.mLabel == "Rooms") {
            var rooms = this.state.mFieldItems.slice();
            this.setState({ Rooms: rooms, mLabel: '', mField: '', mFields: [], mFieldItems: [] });
        }
        else {
            var external = this.state.mFieldItems.slice();
            this.setState({ External: external, mLabel: '', mField: '', mFields: [], mFieldItems: [] });
        }
        window.$('#FeaturesFieldModal').modal('hide');
    };

    handleNumericFieldChange = (name, value) => {
        this.performFieldValueChange(name, value);
    };

    handleFieldChange = (e) => {
        this.performFieldValueChange(e.target.name, e.target.value);
    };

    handleDropdownFieldChange = (e) => {
        this.performFieldValueChange(e.name, e.newValue);
    };

    handleMultiselectDropdownFieldChange = (e) => {
        this.performFieldValueChange(e.name, e.newValue);
    };

    handleCheckBoxFieldChange = (e) => {
        var value = "0";
        if (e.target.checked)
            value = "1";

        this.performFieldValueChange(e.target.name, value);

    };

    performFieldValueChange = (name, value) => {
        var index = name.replace('mFieldItems-', '');
        index = parseInt(index);
        if (index > -1) {
            var mFieldItems = this.state.mFieldItems;
            if (Array.isArray(value)) {
                mFieldItems[index].values = value;
                mFieldItems[index].value = value.join();
            }
            else
                mFieldItems[index].value = value;
            this.setState({ mFieldItems: mFieldItems });
        }
    };

    renderFieldItemValue = (item) => {
        if (item.type == "boolean") {
            return (item.value == "1" ? item.field : item.field + " (no)");
        }
        else if (item.type == "numeric") {
            return (item.value + " " + item.field);
        }
        else if (item.type == "dropdown") {
            return ("(" + item.value + ") " + item.field);
        }
        else if (item.type == "multiselect") {
            return ("(" + item.value + ") " + item.field);
        }
        else {
            return (item.value + " " + item.field);
        }
    };

    renderFieldItem = (item, index) => {
        console.log(item);
        if (item.type == "boolean") {
            return (
                //<label style={{ width: 20 }}><input type="checkbox" id={"mFieldItems-" + index} name={"mFieldItems-" + index} defaultChecked={item.value == "1"} onChange={this.handleCheckBoxFieldChange} /></label>
                <label style={{ width: 20 }}><i className="fa fa-check-square" style={{ fontSize: 18, color: '#A9A9A9' }} aria-hidden="true"></i></label>
            )
        }
        else if (item.type == "numeric") {
            return (
                <OMSNumericBox style={{ width: 100 }} isDecimal={true} id={"mFieldItems-" + index} name={"mFieldItems-" + index} maxLength={20} value={item.value} onChange={this.handleNumericFieldChange} />
            )
        }
        else if (item.type == "dropdown") {
            return (
                <OMSDropdown id={"mFieldItems-" + index} name={"mFieldItems-" + index} options={item.options.split(',')} valueField="" labelField="" value={item.value} onChange={this.handleDropdownFieldChange} />
            )
        }
        else if (item.type == "multiselect") {
            return (
                <OMSMultiselectDropdown2 style={{ width: 100 }} id={"mFieldItems-" + index} name={"mFieldItems-" + index} options={item.options.split(',')} value={item.values} onChange={this.handleMultiselectDropdownFieldChange} />
            )
        }
        else {
            return (
                <input style={{ width: 100 }} id={"mFieldItems-" + index} name={"mFieldItems-" + index} type="text" className="form-control" value={item.value} onChange={this.handleFieldChange} />
            )

        }
    };


    state = this.getStateFromData(this.props.data);

    render() {
        var self = this;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-horizontal">
                            <div className="form-group">
                                <label className="col-md-2 control-label">Classification</label>
                                <div className="col-md-4">
                                    <OMSDropdown id="Classification" name="Classification" options={this.state.ClassificationDD} valueField="Value" labelField="Text" value={this.state.Classification} onChange={this.handleDropdownChange} />
                                </div>
                                <label className="col-md-2 control-label">Type</label>
                                <div className="col-md-4">
                                    <OMSDropdown id="Type" name="Type" options={this.state.TypeDD} valueField="Value" labelField="Text" value={this.state.Type} onChange={this.handleDropdownChange} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-md-2 control-label">Ownership</label>
                                <div className="col-md-4">
                                    <OMSDropdown id="Ownership" name="Ownership" options={this.state.OwnershipDD} valueField="Value" labelField="Text" value={this.state.Ownership} onChange={this.handleDropdownChange} />
                                </div>
                                <div className="col-md-6">
                                    <div className="checkbox">
                                        <label><input id="NewDevelopent" name="NewDevelopent" type="checkbox" defaultChecked={this.state.NewDevelopent == "Y"} onChange={this.handleNewDevelopmentChange} />New Development</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-md-2 control-label">Zoning</label>
                                <div className="col-md-4">
                                    <input id="Zoning" name="Zoning" type="text" className="form-control" value={this.state.Zoning} onChange={this.handleChange} />
                                </div>
                                <label className="col-md-2 control-label">Facing</label>
                                <div className="col-md-4">
                                    <select id="Facing" name="Facing" value={this.state.Facing} className="form-control" onChange={this.handleChange}>
                                        <option value="">Select</option>
                                        <option value="N">North</option>
                                        <option value="NE">Northeast</option>
                                        <option value="NW">Northwest</option>
                                        <option value="S">South</option>
                                        <option value="SE">Southeast</option>
                                        <option value="SW">Southwest</option>
                                        <option value="E">East</option>
                                        <option value="W">West</option>
                                    </select>
                                </div>

                            </div>

                            <div className="form-group">
                                <br />
                            </div>

                            <div className="form-group">
                                <label className="col-md-2 control-label">Floor size</label>
                                <div className="col-md-4 features">
                                    <div className="input">
                                        <OMSNumericBox isDecimal={true} id="FloorSize" name="FloorSize" maxLength={20} className="form-control" value={this.state.FloorSize} placeholder="Floor size" onChange={this.handleNumericChange} />
                                    </div>
                                    <div className="unit">
                                        <select id="FloorSizeUnits" name="FloorSizeUnits" value={this.state.FloorSizeUnits} className="form-control" onChange={this.handleChange}>
                                            <option value="1">m²</option>
                                            <option value="2">ft²</option>
                                            <option value="3">hectares</option>
                                        </select>
                                    </div>
                                </div>
                                <label className="col-md-2 control-label">Land size</label>
                                <div className="col-md-4 features">
                                    <div className="input">
                                        <OMSNumericBox isDecimal={true} id="ErfSize" name="ErfSize" maxLength={20} value={this.state.ErfSize} placeholder="Land size" onChange={this.handleNumericChange} />
                                    </div>
                                    <div className="unit">
                                        <select id="ErfSizeUnits" name="ErfSizeUnits" value={this.state.ErfSizeUnits} className="form-control" onChange={this.handleChange}>
                                            <option value="1">m²</option>
                                            <option value="2">ft²</option>
                                            <option value="3">hectares</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-md-2 control-label">Built date</label>
                                <div className="col-md-4">
                                    {/* <input id="Built" name="Built" type="text" className="form-control datepickerfx" value={this.state.Built} onChange={this.handleChange} /> */}
                                    <DatePicker
                                        selected={this.state.Built}
                                        onChange={(date) => this.setState({ Built: date })}
                                        dateFormat="dd-MM-yyyy"
                                        value={this.state.Built}
                                        name="Built"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        isClearable={true}
                                        //style={{ width: 150 }}
                                        className="form-control datepickerfx"
                                    />
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-md-2 control-label">Rate &amp; Taxes</label>
                                <div className="col-md-4 features">
                                    <div className="input">
                                        <OMSNumericBox isDecimal={true} separator="," id="RatesTaxes" name="RatesTaxes" maxLength={20} value={this.state.RatesTaxes} placeholder="Rate &amp; Taxes" onChange={this.handleNumericChange} />
                                    </div>
                                    <div className="unit">
                                        <select id="RatesTaxesUnit" name="RatesTaxesUnit" value={this.state.RatesTaxesUnit} className="form-control" onChange={this.handleChange}>
                                            <option value="1">per month</option>
                                            <option value="2">per year</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-md-2 control-label">Levies</label>
                                <div className="col-md-4 features">
                                    <div className="input">
                                        <OMSNumericBox isDecimal={true} separator="," id="Levy" name="Levy" maxLength={20} value={this.state.Levy} placeholder="Levies" onChange={this.handleNumericChange} />
                                    </div>
                                    <div className="unit">
                                        <select id="RatesTaxesUnit" name="LevyUnit" value={this.state.LevyUnit} className="form-control" onChange={this.handleChange}>
                                            <option value="1">per month</option>
                                            <option value="2">per year</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>

                            <div className="form-group">
                                <br />
                            </div>

                            <div className="form-group">
                                <label>Rooms<i className="fa fa-edit" onClick={this.handleUpdateRoomClick} style={{ marginLeft: 5, cursor: 'pointer' }} aria-hidden="true"></i></label>
                                <div className="col-md-12">
                                    {
                                        this.state.Rooms.map((item, index) => {
                                            return (
                                                <span key={index} className="btn btn-tag-grid-label" style={{ backgroundColor: '#333' }}>
                                                    {this.renderFieldItemValue(item)}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="form-group">
                                <label>External<i className="fa fa-edit" onClick={this.handleUpdateExternalClick} style={{ marginLeft: 5, cursor: 'pointer' }} aria-hidden="true"></i></label>
                                <div className="col-md-12">
                                    {
                                        this.state.External.map((item, index) => {
                                            return (
                                                <span key={index} className="btn btn-tag-grid-label" style={{ backgroundColor: '#333' }}>
                                                    {this.renderFieldItemValue(item)}
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div id="FeaturesFieldModal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="FeaturesFieldModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="FeaturesFieldModalLabel">{this.state.mLabel}</h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group form-inline col-md-12">
                                        <OMSDropdown id="mField" name="mField" options={this.state.mFields} valueField="field" labelField="field" value={this.state.mField} onChange={this.handleDropdownChange} />
                                        <button style={{ marginLeft: 10 }} className={this.state.mField == "" ? "btn btn btn-success-oms disabled" : "btn btn btn-success-oms"} onClick={this.onAddFeatureFieldClick}>Add</button>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        this.state.mFieldItems.map((item, index) => {
                                            return (
                                                <div key={index} className="form-group form-inline col-md-12">
                                                    <span style={{ marginRight: 10, cursor: 'pointer' }}><i onClick={() => self.onRemoveFeatureFieldClick(item.field)} className="fa fa-times" aria-hidden="true" /></span>
                                                    {this.renderFieldItem(item, index)}
                                                    <label style={{ marginLeft: 5 }}>{item.field}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <OMSButtonStyleL type="button" style={{ marginRight: 10 }} ui_icon="fa-check" ui_type="save" ui_text="Save" ui_processing_text="Saving.." processing={false} disabled={false} onClick={this.onSaveFeatureFieldsClick} />
                                <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}