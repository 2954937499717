import React, { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableCompDynamicD } from "../../../app/common/common-components/OMSDatatables";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const DealGrid = ({ actions, filterData, loopFilterOpen }) => {
  const history = useHistory();
  const [searchComponentData, setSearchComponentData] = useState({
    SDNType: 2,
    Search: "",
    CurrentSearch: "",
    Processing: false,
    Data: [],
  });
  const [extraParams, setExtraParams] = useState(JSON.stringify(filterData));

  useEffect(() => {
    console.log("useEffect >> DealGrid");
    console.log(filterData);
    setExtraParams(JSON.stringify(filterData));
  }, [filterData]);

  const loadGrid = (requestParams) => {
    return apiAgent.Deal.dealDynamicAjax(
      requestParams,
      filterData.AgentId,
      filterData.PipelineId,
      filterData.WorkspaceAgentId,
      filterData.WorkspacePipelineId,
      filterData.PropertyTypes,
      filterData.Areas,
      filterData.RegistrationStartFrom,
      filterData.RegistrationEndTo,
      filterData.NeedUpdate,
      filterData.StageId,
      filterData.InternalDealTags
    );
  };

  const onSearchComponentInputChange = (e) => {
    if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(
      function () {
        if (searchComponentData.Search != searchComponentData.CurrentSearch) {
          loadSearchComponentResult(searchComponentData.Search);
        }
      }.bind(this),
      1000
    );

    var searchComponentData = {
      ...searchComponentData,
      Search: e.target.value,
    };
    setSearchComponentData(searchComponentData);
  };

  const renderSearchComponentResult = (e) => {
    if (searchComponentData.Processing) {
      return <div className="form-inline">Searching....</div>;
    } else if (searchComponentData.Data.length == 0) {
      return <div className="form-inline"></div>;
    } else {
      return searchComponentData.Data.map((item) => (
        <div key={"SR" + item.id} className="form-inline searchresult">
          <a
            onClick={() => onSearchComponentSelectClick(item.id, item.name)}
            style={{ cursor: "pointer" }}
          >
            <i className={"fa " + item.typeIcon}></i>&nbsp;{item.name}
          </a>
        </div>
      ));
    }
  };

  const loadSearchComponentResult = (Search) => {
    setSearchComponentData({
      ...searchComponentData,
      Processing: true,
      Data: [],
    });

    apiAgent.Diary.searchPropertiesAjax(Search)
      .then((response) => {
        setSearchComponentData({
          ...searchComponentData,
          CurrentSearch: Search,
          Processing: false,
          Data: response.data,
        });
      })
      .catch((error) => toast.error(error.response.data));
  };

  const onSearchComponentSelectClick = (id, name) => {
    window.$("#dealSearchModel").modal("hide");
    this.props.history.push(`/deal/saveDeal?ID=&propertyID=${id}`);
  };

  const onCreateDealClick = (e) => {
    window.$("#dealSearchModel").modal("show");
  };

  const handleRowClick = (data) => {
    history.push("/Deal/saveDeal?ID=" + data.dealID);
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-11 col-sm-12">
          <div className="panel panel-default panel-table oms-box">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="form-inline form-group">
                    <div className="element">
                      <div className="truncate">
                        <label>
                          <i className="fa fa-filter" aria-hidden="true"></i>
                          &nbsp;
                        </label>
                        <a
                          className="filter-text"
                          onClick={() => loopFilterOpen()}
                        >
                          <span>
                            <b>Filter: </b>
                            {filterData.getFilterStringFull()}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {actions.indexOf("create") != -1 && (
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="pull-right boxRightMenu">
                      <div className="form-inline">
                        <OMSButtonStyleL
                          type="button"
                          ui_icon=""
                          ui_type="create"
                          ui_text="add deal"
                          ui_processing_text=""
                          processing={false}
                          disabled={false}
                          onClick={onCreateDealClick}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="panel-body">
              <div style={{ marginBottom: "30px" }}>
                <DataTableCompDynamicD
                  id="dealTable"
                  onRowClick={handleRowClick}
                  makeAjaxRequest={loadGrid}
                  ExtraParams={extraParams}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={"dealSearchModel"}
        tabIndex={-1}
        data-backdrop="static"
        aria-labelledby={"DealSearchModelLabel"}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
              <h4 className="modal-title" id={"DealSearchModelLabel"}>
                Search listing
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group has-feedback">
                    <label htmlFor="SearchComponent.Search">Search</label>
                    <input
                      id="SearchComponent.Search"
                      name="SearchComponent.Search"
                      type="text"
                      className="form-control"
                      value={searchComponentData.Search}
                      onChange={onSearchComponentInputChange}
                    />
                    <span
                      className={
                        searchComponentData.Processing
                          ? "fa fa-spin fa-spinner form-control-feedback"
                          : "fa fa-search form-control-feedback"
                      }
                      style={{ marginTop: 5, maxHeight: 20 }}
                    />
                  </div>
                </div>
                <div className="col-md-12">{renderSearchComponentResult()}</div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealGrid;
