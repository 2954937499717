import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSMultiselectDropdown } from "../../../app/common/common-components/OMSMultiselectDropdown";
import { withRouter } from 'react-router-dom';

class ClearCacheBox extends React.Component {
  state = {
    OfficeID: "",
    Caches: [],
    processing: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCachesChange = (selected) => {
    this.setState({
      Caches: selected,
    });
  };

  handleDropdownChange = (e) => {
    this.setState({ [e.name]: e.newValue });
  };

  onCancelClick = (e) => {
    this.props.history.push('/Settings');
  };

  onPerformClick = (e) => {
    this.setState({ processing: true });
    /* var data = new FormData();
        data.append('OfficeID', this.state.OfficeID);
        data.append('CacheID', this.state.Caches.join(','));
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Admin/ClearCacheAjax', true);
        xhr.onload = function () {
          var data = JSON.parse(xhr.responseText);
          if (data.message == "")
            data.message = "success";
    
          $.toaster(data.message, 'Result', 'info');
          //bootbox.alert(data.message);
          this.setState({ processing: false });
        }.bind(this);
        xhr.send(data); */
    apiAgent.Admin.clearCacheAjax(this.state.OfficeID, this.state.Caches.join(","))
      .then((response) => {
        if (response.message == "") response.message = "success";
        toast.info(response.message);
      })
      .catch((error) => {
        toast.info("Fail");
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  render() {
    return (
      <div className="col-sm-12">
        <div className="panel panel-default oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-12">
                <label>Clear Cache</label>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="OfficeID">Office</label>
                <OMSDropdown
                  id="OfficeID"
                  name="OfficeID"
                  options={this.props.officeDD}
                  valueField="value"
                  labelField="text"
                  value={this.state.OfficeID}
                  onChange={this.handleDropdownChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Caches">Cache</label>
                <OMSMultiselectDropdown
                  id="Caches"
                  name="Caches"
                  options={this.props.cacheDD}
                  valueField="value"
                  labelField="text"
                  value={this.state.Caches}
                  onChange={this.handleCachesChange}
                />
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCancelClick}
                  />
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Remove"
                    ui_processing_text="Removing.."
                    processing={this.state.processing}
                    disabled={this.state.processing}
                    onClick={this.onPerformClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ClearCacheBox);
