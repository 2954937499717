import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import bootbox from "bootbox";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from 'react-router-dom';

class UserTransferBox extends React.Component {
  state = {
    OfficeID: "",
    UserDD: [],
    FromUserID: "",
    ToUserID: "",
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  componentDidMount() {}

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  loadUsersDDFromServer = (OfficeID) => {
    this.setState({ UserDD: [], FromUserID: "", ToUserID: "" });
    /* var xhr = new XMLHttpRequest();
      xhr.open('get', '/Admin/GetUsersDDAjax?OfficeID=' + OfficeID, true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        this.setState({ UserDD: data, OfficeID: OfficeID });
      }.bind(this);
      xhr.send(); */
    apiAgent.Admin.getUsersDDAjax(OfficeID)
      .then((response) => {
        this.setState({ UserDD: response, OfficeID: OfficeID });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  handleDDChange = (e) => {
    if (e.name.indexOf("OfficeID") != -1) this.loadUsersDDFromServer(e.newValue);
    else this.setState({ [e.name]: e.newValue });
  };

  onCancelClick = (e) => {
    this.props.history.push('/Settings');
  };

  executeSaveClick = () => {
    this.setState({ processing: true });
    /* var data = new FormData();
      data.append('FromUserID', this.state.FromUserID);
      data.append('ToUserID', this.state.ToUserID);
      data.append('OfficeID', this.state.OfficeiD);
      var xhr = new XMLHttpRequest();
      xhr.open('post', '/Admin/UserTransferAjax', true);
      xhr.onload = function () {
        this.setState({ processing: false });
        var data = JSON.parse(xhr.responseText);
        if (data.status == "success") {
          $.toaster(data.message, 'Success', 'info');
        }
        else
          $.toaster(data.message, 'Fail', 'danger');
      }.bind(this);
      xhr.send(data); */
    apiAgent.Admin.userTransferAjax(this.state.FromUserID, this.state.ToUserID)
      .then((response) => {
        toast.info(response.message);
      })
      .catch((error) => {
        toast.error("Fail");
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  onSaveClick = (e) => {
    if (this.state.FromUserID == "" || this.state.ToUserID == "" || this.state.OfficeID == "") {
      toast.info("Please select users properly");
      return;
    }

    if (this.state.FromUserID == this.state.ToUserID) {
      toast.info("Selected users should not be same");
      return;
    }

    /* bootbox.confirm({
        title: "Transfer user?",
        message: "Do you want to transfer user. now? This cannot be undone.",
        buttons: {
          cancel: {
            label: '<i class="fa fa-times"></i> Cancel'
          },
          confirm: {
            label: '<i class="fa fa-check"></i> Confirm'
          }
        },
        callback: function (result) {
          if (result) {
            this.executeSaveClick();
          }
        }.bind(this)
      }); */
    let title = "Transfer user?";
    let body = "Do you want to transfer user. now? This cannot be undone.";
    let callback = this.executeSaveClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  render() {
    return (
      <div>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div className="col-sm-12">
          <div className="panel panel-default oms-box">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-12">
                  <label>User Transfer</label>
                </div>
              </div>
            </div>
            <div className="panel-body">
              <div className="col-md-12">
                <div className="form-group form-inline">
                  <label htmlFor="OfficeID" style={{ width: 80 }}>
                    Office
                  </label>
                  <OMSDropdown
                    id="OfficeID"
                    options={this.props.OfficeDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.OfficeID}
                    onChange={this.handleDDChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>From User</label>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="FromUserID" style={{ width: 80 }}>
                    User
                  </label>
                  <OMSDropdown
                    id="FromUserID"
                    options={this.state.UserDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.FromUserID}
                    onChange={this.handleDDChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>To User</label>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="ToUserID" style={{ width: 80 }}>
                    User
                  </label>
                  <OMSDropdown
                    id="ToUserID"
                    options={this.state.UserDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.ToUserID}
                    onChange={this.handleDDChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCancelClick}
                  />
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Process"
                    ui_processing_text="Processing.."
                    processing={this.state.processing}
                    disabled={this.state.processing}
                    onClick={this.onSaveClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserTransferBox);
