import { makeAutoObservable, runInAction } from "mobx";
import apiAgent from "../api/apiAgent"; // Adjust the path to your apiAgent
import { PropertyReportModel } from "../models/PropertyReportModel";

export default class PropertyReportsStore {
  private _newReportsCount: number = 0;
  private _isReportGenerating: boolean = false;
  private _propertyReports: PropertyReportModel[] = [];
  private _searchedPropertyReports: PropertyReportModel[] = [];
  private _provinceId: string = "3";

  constructor() {
    makeAutoObservable(this);
    //console.log("PropertyReportsStore initialized");
  }

  get provinceId(): string {
    return this._provinceId;
  }

  set provinceId(value: string) {
    runInAction(() => {
      this._provinceId = value;
    });
  }

  get newReportsCount(): number {
    return this._newReportsCount;
  }

  set newReportsCount(value: number) {
    this._newReportsCount = value;
  }

  get isReportGenerating(): boolean {
    return this._isReportGenerating;
  }

  set isReportGenerating(value: boolean) {
    this._isReportGenerating = value;
  }

  get propertyReports(): PropertyReportModel[] {
    //console.log("Getting propertyReports");
    const sortedReports = this.sortReportsByMostRecentDate([...this._propertyReports]);
    // console.log("Sorted reports:", sortedReports.map(r => ({
    //   address: r.streetAddress,
    //   reportDate: r.reportGenerationDate,
    //   ownerDate: r.ownerDetailsGenerationDate,
    //   mostRecent: this.getMostRecentDate(r)
    // })));
    return sortedReports;
  }

  set propertyReports(value: PropertyReportModel[]) {
    //console.log("Setting propertyReports", value);
    runInAction(() => {
      this._propertyReports = this.sortReportsByMostRecentDate(value);
    });
  }

  get searchedPropertyReports(): PropertyReportModel[] {
    //console.log("Getting searchedPropertyReports");
    return [...this._searchedPropertyReports];
  }

  set searchedPropertyReports(value: PropertyReportModel[]) {
    //console.log("Setting searchedPropertyReports", value);
    runInAction(() => {
      this._searchedPropertyReports = this.sortReportsByMostRecentDate(value);
    });
  }

  private sortReportsByMostRecentDate(reports: PropertyReportModel[]): PropertyReportModel[] {
    //console.log("Sorting reports");
    return reports.sort((a, b) => {
      const dateA = this.getMostRecentDate(a);
      const dateB = this.getMostRecentDate(b);
      //console.log(`Comparing: ${a.streetAddress} (${dateA}) vs ${b.streetAddress} (${dateB})`);
      return dateB.getTime() - dateA.getTime();
    });
  }

  private getMostRecentDate(report: PropertyReportModel): Date {
    const reportDate = new Date(report.reportGenerationDate || 0);
    const ownerDate = new Date(report.ownerDetailsGenerationDate || 0);
    const mostRecent = reportDate > ownerDate ? reportDate : ownerDate;
    //console.log(`${report.streetAddress}: Report date: ${reportDate}, Owner date: ${ownerDate}, Most recent: ${mostRecent}`);
    return mostRecent;
  }

  updateOrAddPropertyReport(newReport: PropertyReportModel) {
    //console.log("Updating or adding property report", newReport);
    runInAction(() => {
      const index = this._propertyReports.findIndex(report => report.propertyId === newReport.propertyId);
      
      if (index !== -1) {
        //console.log("Updating existing report");
        this._propertyReports[index] = {
          ...this._propertyReports[index],
          ...newReport,
          isNewReport: true,
        };
      } else {
        //console.log("Adding new report");
        this._propertyReports.push(newReport);
      }

      //console.log("Re-sorting reports after update");
      this._propertyReports = this.sortReportsByMostRecentDate(this._propertyReports);
    });
  }

  // Optionally, a method to set searched reports based on criteria
  setSearchedPropertyReports(reports: PropertyReportModel[]) {
    //console.log("Setting searched reports");
    runInAction(() => {
      this._searchedPropertyReports = this.sortReportsByMostRecentDate(reports);
    });
  }

  // Commented out methods left as-is for reference
  // addPropertyReport(report: PropertyReportModel) {
  //   this._propertyReports.push(report);
  // }

  // updatePropertyReport(updatedReport: PropertyReportModel) {
  //   const index = this._propertyReports.findIndex(report => report.propertyId === updatedReport.propertyId);
  //   if (index !== -1) {
  //     this._propertyReports[index] = updatedReport;
  //   }
  // }

  // removePropertyReport(propertyId: number) {
  //   this._propertyReports = this._propertyReports.filter(report => report.propertyId !== propertyId);
  // }

  // setPropertyReports(reports: PropertyReportModel[]) {
  //   this._propertyReports = reports;
  // }
}
