import React from "react";
import PropTypes from "prop-types";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import $ from "jquery";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";

export class DescriptionStepView extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    IsExternalListing: PropTypes.bool,
  };
  state = {
    virtualAssistantOptionValue: 0,
    virtualAssistantDescValue: "",
    processingVrReq: false,
    requestLeft: "",
    resetIn: "",
  };
  getStateFromData = (data) => {
    return {
      Step: "Listing capture: Description",
      PropertyID: this.ToString(data.propertyID),
      UserID: this.ToString(data.userID),
      Headline: this.ToString(data.headline),
      Description: this.ToString(data.description),
      ItemsIncluded: this.ToString(data.itemsIncluded),
      ItemsExcluded: this.ToString(data.itemsExcluded),
      Defects: this.ToString(data.defects),
      ExtraDescs: JSON.parse(data.extraDescs),
      ExtraDescriptionFields: "",
      ExtraDescriptionFieldsDD: this.loadExtraDescriptionFieldsItemDD(
        JSON.parse(data.extraDescs)
      ),
      GeneralListingTags: JSON.parse(data.generalListingTags),
      mTags: [],
      mExtraField: "",
      mExtraFieldIndex: -1,
      activeTabID: this.state
        ? this.state.activeTabID
        : "description-description",
    };
  };

  componentDidMount() {
    this.setState((prev) => ({
      ...prev,
      activeTabID: "description-description",
    }));
  }

  doSetFormData = (data) => {
    const descriptionData = {
      PropertyID: this.state.PropertyID,
      UserID: this.state.UserID,
      Headline: this.state.Headline,
      Description: this.state.Description,
      ItemsIncluded: this.state.ItemsIncluded,
      ItemsExcluded: this.state.ItemsExcluded,
      Defects: this.state.Defects,
      ExtraDescs: JSON.stringify(this.state.ExtraDescs),
      GeneralListingTags: JSON.stringify(this.state.GeneralListingTags),
    };

    data.append("DescriptionJson", JSON.stringify(descriptionData));
  };

  doCheckFormData = (data) => {
    var result = { status: "error", message: "Fields are required." };
    var resultSuccess = { status: "success", message: "" };
    if (!this.props.IsExternalListing) {
      if (!this.state.Headline) {
        result.message = "Please enter headline";
        return result;
      } else if (!this.state.Description) {
        result.message = "Please enter description";
        return result;
      }
    }

    return resultSuccess;
  };

  doSetState = (data) => {
    this.setState(this.getStateFromData(data));
  };

  doGetState = () => {
    return this.state;
  };

  ToString = (value) => {
    if (value === undefined || value == null) return "";
    else return value;
  };

  loadExtraDescriptionFieldsItemDD = (ExtraDescs) => {
    //console.log("inside loadExtraDescriptionFieldsItemDD, ExtraDescs=",ExtraDescs);

    var optionsDD = this.props.ExtraDescriptionFieldsDD;
    //console.log("inside loadExtraDescriptionFieldsItemDD, ExtraDescriptionFieldsDD=",this.props.ExtraDescriptionFieldsDD);
    var selectedItems = ExtraDescs.map(function (item) {
      return item.Field;
    });

    var itemDD = [];
    for (var i = 0; i < optionsDD.length; i++) {
      if (selectedItems.indexOf(optionsDD[i].value) == -1) {
        itemDD.push(optionsDD[i]);
      }
    }
    return itemDD;
  };

  handeExtraDescriptionFieldsDropdownChange = (e) => {
    this.setState({ [e.name]: e.newValue });
  };

  onAddExtraDescriptionFieldClick = (e) => {
    //console.log("inside onAddExtraDescriptionFieldClick, this.state.ExtraDescriptionFields=",this.state.ExtraDescriptionFields);
    if (this.state.ExtraDescriptionFields) {
      var ExtraDescs = this.state.ExtraDescs;
      ExtraDescs.push({
        Field: this.state.ExtraDescriptionFields,
        Value: "",
        Tags: [],
      });
      this.setState({
        ExtraDescs: ExtraDescs,
        ExtraDescriptionFields: "",
        ExtraDescriptionFieldsDD: this.loadExtraDescriptionFieldsItemDD(
          ExtraDescs
        ),
        mTags: [],
        mExtraField: "",
        mExtraFieldIndex: 0,
      });
    }
  };

  onRemoveExtraDescriptionFieldClick = (field) => {
    if (field) {
      var ExtraDescs = this.state.ExtraDescs;
      var index = -1;
      for (var i = 0; i < ExtraDescs.length; i++) {
        if (ExtraDescs[i].Field == field) {
          index = i;
          break;
        }
      }

      if (index != -1) {
        ExtraDescs.splice(index, 1);
        this.setState({
          ExtraDescs: ExtraDescs,
          ExtraDescriptionFieldsDD: this.loadExtraDescriptionFieldsItemDD(
            ExtraDescs
          ),
          mTags: [],
          mExtraField: "",
          mExtraFieldIndex: 0,
        });
      }
    }
  };

  handleExtraDescChange = (e) => {
    var field = e.target.name.replace("ExtraDesc_", "");
    var value = e.target.value;
    var selectedItems = this.state.ExtraDescs;
    for (var i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].Field == field) {
        selectedItems[i].Value = value;
        break;
      }
    }
    this.setState({ ExtraDescs: selectedItems });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onAddTagsClick = (name) => {
    var tExtraDescs = this.state.ExtraDescs;
    var selectedIndex = -1;
    for (var i = 0; i < tExtraDescs.length; i++) {
      if (tExtraDescs[i].Field == name) {
        selectedIndex = i;
      }
    }

    if (selectedIndex == -1) return;

    var tExtraFieldTags = this.props.ExtraDescriptionTagFields;
    //console.log("inside onAddTagsClick, tExtraFieldTags=",this.props.ExtraDescriptionTagFields,name);
    var tTags = [];
    for (var i = 0; i < tExtraFieldTags.length; i++) {
      if (tExtraFieldTags[i].name == name) {
        tTags = tExtraFieldTags[i].tags;
      }
    }

    window.$("#fieldTagModel").modal("show");
    this.setState({
      mTags: tTags,
      mExtraField: name,
      mExtraFieldIndex: selectedIndex,
    });
  };
  setActiveTabId = (tabId) => {
    //console.log("inside setActiveTabId tabID=", tabId);
    this.setState({ activeTabID: tabId });
  };
  onTagCheckboxClick = (tagName, isSelect) => {
    var tExtraDescs = this.state.ExtraDescs;
    if (isSelect) {
      tExtraDescs[this.state.mExtraFieldIndex].Tags.push(tagName);
      this.setState({ ExtraDescs: tExtraDescs });
    } else {
      var index = tExtraDescs[this.state.mExtraFieldIndex].Tags.indexOf(
        tagName
      );
      if (index != -1) {
        tExtraDescs[this.state.mExtraFieldIndex].Tags.splice(index, 1);
        this.setState({ ExtraDescs: tExtraDescs });
      }
    }
  };

  onAddGeneralListingTagsClick = () => {
    window.$("#generalListingTagsModel").modal("show");
  };
  onRunVirtualAssistantClick = () => {
    apiAgent.Property.GetOpenAIRequestLeftAjax().then((res) => {
      this.setState({ requestLeft: res.requestLeft, resetIn: res.resetIn });
    });
    window.$("#virtualAssistantModal").modal("show");
  };

  onAddGeneralListingTagCheckboxClick = (tagName, isSelect) => {
    var items = this.state.GeneralListingTags;
    if (isSelect) {
      items.push(tagName);
      this.setState({ GeneralListingTags: items });
    } else {
      var index = items.indexOf(tagName);
      if (index != -1) {
        items.splice(index, 1);
        this.setState({ GeneralListingTags: items });
      }
    }
  };

  state = this.getStateFromData(this.props.data);

  handleVirtualAssistantOptionChange = async (e) => {
    var virtualAssistantOptionValue = e.target.value;
    if (virtualAssistantOptionValue == 0) {
      this.setState({
        processingVrReq: true,
        virtualAssistantOptionValue: virtualAssistantOptionValue,
        virtualAssistantDescValue: "",
      });
    } else {
      this.setState({
        processingVrReq: true,
        virtualAssistantOptionValue: virtualAssistantOptionValue,
      });
      await apiAgent.Property.runVirtualAssistantAjax(
        e.target.value,
        this.state.Description
      )
        .then((data) => {
          this.setState({
            processingVrReq: false,
            virtualAssistantDescValue: data.description,
            requestLeft: data.requestLeft,
          });
          if (data.success == false) {
            toast.info("Something went wrong, Try again");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  OnCopyVRText = () => {
    var text = $("#VirtualAssistantDescription").val();
    if (text) {
      navigator.clipboard.writeText(text);
      toast.info("Text copied successfully");
    }
  };
  onReplaceHeadlineOrDescDescription = () => {
    this.setState({ Description: this.state.virtualAssistantDescValue });
  };
  render() {
    var self = this;
    var tabContent = [
      { link: "#description-description", title: "Description" },
      { link: "#description-extra", title: "Extra" },
      { link: "#description-itemincluded", title: "Item Included" },
      { link: "#description-itemexcluded", title: "Item Excluded" },
      { link: "#description-defects", title: "Defects" },
    ];
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <OMSTabBox
              id="descriptionTabs"
              value={tabContent}
              setActiveTabId={this.setActiveTabId}
            ></OMSTabBox>

            <div className="tab-content">
              <div
                id="description-description"
                className={
                  this.state.activeTabID === "description-description"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="Headline">Headline catchphrase</label>
                      <input
                        type="text"
                        className="form-control"
                        id="Headline"
                        name="Headline"
                        placeholder="Headline catchphrase"
                        maxLength={160}
                        value={this.state.Headline}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="Description">
                        Full advertising description
                      </label>
                      <textarea
                        rows={10}
                        className="form-control"
                        id="Description"
                        name="Description"
                        placeholder="Full advertising description"
                        value={this.state.Description}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => this.onRunVirtualAssistantClick()}
                      >
                        <i className="fa fa-magic"></i> Run Virtual Assistant
                      </a>
                    </div>
                    <div className="form-group">
                      <label htmlFor="GeneralListingTags">
                        General listing tags
                      </label>
                      <a
                        style={{
                          marginLeft: 8,
                          textDecoration: "underline",
                          fontWeight: "normal",
                          cursor: "pointer",
                        }}
                        onClick={() => this.onAddGeneralListingTagsClick()}
                      >
                        Add Tags
                      </a>
                      <div style={{ marginBottom: 10 }}>
                        {this.state.GeneralListingTags.map(function (item) {
                          return (
                            <span
                              key={"GLT-" + item}
                              className="btn btn-tag-nonclick"
                              style={{
                                backgroundColor: "gray",
                                padding: "2px 10px",
                              }}
                            >
                              {item}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="description-extra"
                className={
                  this.state.activeTabID === "description-extra"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <br />
                  <div className="col-md-12  "></div>
                  {
                    <div className="col-sm-12 col-xs-12">
                      <label>Field:&nbsp; &nbsp;</label>
                      <OMSDropdown
                        id="ExtraDescriptionFields"
                        style={{ width: "auto" }}
                        name="ExtraDescriptionFields"
                        options={this.state.ExtraDescriptionFieldsDD}
                        valueField="value"
                        labelField="text"
                        value={this.state.ExtraDescriptionFields}
                        onChange={
                          this.handeExtraDescriptionFieldsDropdownChange
                        }
                      />
                      <button
                        style={{ marginTop: 5 }}
                        className={
                          this.state.ExtraDescriptionFields
                            ? "btn btn-success-oms"
                            : "btn btn-success-oms disabled"
                        }
                        onClick={this.onAddExtraDescriptionFieldClick}
                      >
                        Add
                      </button>
                    </div>
                  }
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                  <div className="col-md-12 ">
                    {this.state.ExtraDescs.map(function (item, index) {
                      //console.log("inside render of step4-extra, item=",item);
                      return (
                        <div
                          key={item.Field + "-" + index}
                          className="form-group"
                        >
                          <label>
                            {item.Field}
                            <span
                              style={{
                                marginLeft: 2,
                                cursor: "pointer",
                                display: "inline-block",
                              }}
                              onClick={() =>
                                self.onRemoveExtraDescriptionFieldClick(
                                  item.Field
                                )
                              }
                            >
                              <i
                                className="fa fa-times-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <a
                              style={{
                                marginLeft: 8,
                                textDecoration: "underline",
                                fontWeight: "normal",
                                cursor: "pointer",
                              }}
                              onClick={() => self.onAddTagsClick(item.Field)}
                            >
                              Add Tags
                            </a>
                          </label>
                          <textarea
                            rows={2}
                            className="form-control"
                            id={"ExtraDesc_" + item.Field}
                            name={"ExtraDesc_" + item.Field}
                            value={item.Value}
                            placeholder={item.Field}
                            onChange={self.handleExtraDescChange}
                          />
                          <div style={{ marginBottom: 10 }}>
                            {item.Tags.map(function (item2, index2) {
                              return (
                                <span
                                  key={item.Field + "-" + index + "-" + item2}
                                  className="btn btn-tag-nonclick"
                                  style={{
                                    backgroundColor: "gray",
                                    padding: "2px 10px",
                                  }}
                                >
                                  {item2}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                id="description-itemincluded"
                className={
                  this.state.activeTabID === "description-itemincluded"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="ItemsIncluded">Items included</label>
                      <textarea
                        rows={10}
                        className="form-control"
                        id="ItemsIncluded"
                        name="ItemsIncluded"
                        placeholder=""
                        value={this.state.ItemsIncluded}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="description-itemexcluded"
                className={
                  this.state.activeTabID === "description-itemexcluded"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="ItemsExcluded">Items excluded</label>
                      <textarea
                        rows={10}
                        className="form-control"
                        id="ItemsExcluded"
                        name="ItemsExcluded"
                        placeholder=""
                        value={this.state.ItemsExcluded}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="description-defects"
                className={
                  this.state.activeTabID === "description-defects"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="Defects">Defects</label>
                      <textarea
                        rows={10}
                        className="form-control"
                        id="Defects"
                        name="Defects"
                        placeholder=""
                        value={this.state.Defects}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="fieldTagModel"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="fieldTagModelLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4 className="modal-title" id="fieldTagModelLabel">
                  Extra description fields tags [{this.state.mExtraField}]
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    {this.state.mTags.map(function (item, index) {
                      var isChecked =
                        self.state.ExtraDescs[
                          self.state.mExtraFieldIndex
                        ].Tags.indexOf(item) != -1;
                      return (
                        <div
                          key={"EF-" + item + "-" + index}
                          className="form-group"
                        >
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                value={item}
                                checked={isChecked}
                                onClick={() =>
                                  self.onTagCheckboxClick(
                                    item,
                                    isChecked ? 0 : 1
                                  )
                                }
                              />
                              {item}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="generalListingTagsModel"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="generalListingTagsModelLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4
                  style={{ display: "inline-block" }}
                  className="modal-title"
                  id="generalListingTagsModelLabel"
                >
                  General listing tags{" "}
                </h4>
                <a
                  href="https://help.entegral.net/en/articles/5588235-how-to-use-listing-tags"
                  target="_blank"
                  style={{
                    verticalAlign: "bottom",
                    marginLeft: 5,
                    fontSize: 14,
                  }}
                >
                  more info
                </a>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    {this.props.GeneralListingTags.map(function (item, index) {
                      var isChecked =
                        self.state.GeneralListingTags.indexOf(item) != -1;
                      return (
                        <div
                          key={"GLTCHK-" + item + "-" + index}
                          className="form-group"
                        >
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                value={item}
                                checked={isChecked}
                                onClick={() =>
                                  self.onAddGeneralListingTagCheckboxClick(
                                    item,
                                    isChecked ? 0 : 1
                                  )
                                }
                              />
                              {item}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="virtualAssistantModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="generalListingTagsModelLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4
                  style={{ display: "inline-block" }}
                  className="modal-title"
                  id="VirtualAssistantLabel"
                >
                  Virtual Assistant
                </h4>
              </div>
              <div className="modal-body">
                <div className="row" style={{ marginBottom: 10 }}>
                  <div className="col-md-8">
                    <select
                      id="VirtualAssistantOption"
                      className="form-control"
                      onChange={this.handleVirtualAssistantOptionChange.bind(
                        this
                      )}
                    >
                      <option value="0">What would you like to do?</option>
                      <option value="1">Do a grammar check</option>
                      <option value="2">
                        Rewrite your listing description
                      </option>
                      <option value="3">
                        Write a new listing ad based on your keywords
                      </option>
                      <option value="4">
                        Write a Tweet using this description
                      </option>
                      <option value="5">
                        Write a short social media post using the description
                      </option>
                      <option value="6">
                        Write a few headline catchphrases
                      </option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <a
                      style={{ marginTop: 7, display: "block" }}
                      target="_blank"
                      href="https://help.entegral.net/en/articles/6933959-base-virtual-assistant"
                    >
                      Click here for help
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <textarea
                      rows={10}
                      readOnly={true}
                      className="form-control"
                      id="VirtualAssistantDescription"
                      name="VirtualAssistant"
                      placeholder=""
                      value={this.state.virtualAssistantDescValue}
                      onChange={(e) =>
                        this.setState({
                          virtualAssistantDescValue: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <p>
                      You have{" "}
                      <span id="spanAIRequestLeft">
                        {this.state.requestLeft}
                      </span>
                      /40 request left. Reset in{" "}
                      <span id="spanAIResetIn">{this.state.resetIn}</span>{" "}
                      hours.
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {["1", "2", "3"].indexOf(
                  this.state.virtualAssistantOptionValue
                ) != -1 && (
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="save"
                    ui_text={
                      this.state.VROption != "6"
                        ? "Replace Description"
                        : "Replace Headline"
                    }
                    ui_processing_text="Processing.."
                    processing={this.state.processingVrReq}
                    disabled={this.state.processingVrReq}
                    onClick={this.onReplaceHeadlineOrDescDescription}
                  />
                )}
                <OMSButtonStyleL
                  type="button"
                  ui_icon=""
                  ui_type="save"
                  ui_text="Copy Text"
                  ui_processing_text="Processing.."
                  processing={this.state.processingVrReq}
                  disabled={this.state.processingVrReq}
                  onClick={this.OnCopyVRText}
                />
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
