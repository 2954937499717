import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { OMSButtonStyleL } from "../OMSButton";
import { OMSTwitterAutocompleteAgent } from "../OMSAutocomplete";
import bootbox from "bootbox";
import apiAgent from "../../../api/apiAgent";
import ConfirmationModalPopup from "../../common-components/ModalPopups/ConfirmationModalPopup";
import { Link } from "react-router-dom";

export class OMSLeadModel extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    IsReadOnly: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    id: "LD",
  };

  state = {
    mLeadID: "",
    mReceivedDate: "",
    mMessageDetails: {},
    mPropertyID: "",
    mOfficeID: "",
    mSource: "",
    mUserID: "",
    mType: 1,
    mNote: "",
    mTitle: "",
    mContactID: "",
    mProcessing: true,
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  onInboxClick = (userID, ItemID, IsRead, Title) => {
    this.setState({ mProcessing: true });
    window.$("#leadviewmodal_" + this.props.id).modal("show");
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Inbox/SelectLeadAjax?leadID=' + ItemID, true);
        xhr.onload = function () {
            this.setState({ mProcessing: false });
            var data = JSON.parse(xhr.responseText);
            this.setState({ mLeadID: data.leadID, mUserID: userID, mIsRead: IsRead, mTitle: Title, mContactID: data.contactID, mType: 2, mReceivedDate: data.ReceivedDate, mMessageDetails: JSON.parse(data.MessageDetails), mPropertyID: data.propertyID, mOfficeID: data.OfficeID, mSource: data.Source, mNote: data.Note, mAddress: data.Address });
        }.bind(this);
        xhr.send(); */
    apiAgent.Inbox.selectLeadAjax(ItemID)
      .then((response) => {
        this.setState({
          mLeadID: response.leadID,
          mUserID: userID,
          mIsRead: IsRead,
          mTitle: Title,
          mContactID: response.contactID,
          mType: 2,
          mReceivedDate: response.receivedDate,
          mMessageDetails: JSON.parse(response.messageDetails),
          mPropertyID: response.propertyID,
          mOfficeID: response.officeID,
          mSource: response.source,
          mNote: response.note,
          mAddress: response.address,
        });
      })
      .catch((error) => toast.error(error.response.data))
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  onMarkReadOrUnread = (IsRead) => {
    this.setState({ mProcessing: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Inbox/MarkInboxAjax?ItemID=' + this.state.mLeadID + '&userID=' + this.state.mUserID + '&IsRead=' + IsRead, true);
        xhr.onload = function () {
            this.setState({ mProcessing: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == 'success') {
                $('#leadviewmodal_' + this.props.id).modal('hide');
            }
            else
                bootbox.alert(data.message);
        }.bind(this);
        xhr.send(); */
    apiAgent.Inbox.markInboxAjax(this.state.mLeadID, this.state.mUserID, IsRead)
      .then((response) => {
        window.$("#leadviewmodal_" + this.props.id).modal("hide");
      })
      .catch((error) => bootbox.alert(error.response.message))
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  onCloseModal = (e) => {
    window.$("#leadviewmodal_" + this.props.id).modal("hide");
  };

  executeInboxDeleteClick = (e) => {
    this.setState({ mProcessing: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Inbox/DeleteInboxAjax?ItemID=' + this.state.mLeadID + '&userID=' + this.state.mUserID + '&Type=' + this.state.mType, true);
        xhr.onload = function () {
            this.setState({ mProcessing: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == 'success') {
                $('#leadviewmodal_' + this.props.id).modal('hide');
            }
            else
                bootbox.alert(data.message);
        }.bind(this);
        xhr.send(); */
    apiAgent.Inbox.deleteInboxAjax(
      this.state.mLeadID,
      this.state.mUserID,
      this.state.mType
    )
      .then((response) => {
        window.$("#leadviewmodal_" + this.props.id).modal("hide");
      })
      .catch((error) => bootbox.alert(error.response.message))
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };

    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  onInboxDeleteClick = (e) => {
    // bootbox.confirm({
    //   title: "Delete inbox item?",
    //   message: "Do you want to delete inbox item now? This cannot be undone.",
    //   buttons: {
    //     cancel: {
    //       label: '<i class="fa fa-times"></i> Cancel',
    //     },
    //     confirm: {
    //       label: '<i class="fa fa-check"></i> Confirm',
    //     },
    //   },
    //   callback: function (result) {
    //     if (result) {
    //       this.executeInboxDeleteClick();
    //     }
    //   }.bind(this),
    // });
    let title = "Delete inbox item?";
    let body = "Do you want to delete inbox item now? This cannot be undone.";
    let callback = this.executeInboxDeleteClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onReassignClick = (e) => {
    window.$("#searchagent_" + this.props.id).val("");
    window.$("#agentid_" + this.props.id).val("");
    window.$("#note_" + this.props.id).val("");
    window.$("#reassignmodal_" + this.props.id).modal("show");
  };

  handleAgentChange = (e) => {
    window.$("#agentid_" + this.props.id).val(e.ID);
  };

  onCancelReassignClick = (e) => {
    window.$("#reassignmodal_" + this.props.id).modal("hide");
  };

  onSaveReassignClick = (e) => {
    var UserIDToAssign = window.$("#agentid_" + this.props.id).val();
    var Note = window.$("#note_" + this.props.id).val();

    if (UserIDToAssign == "") return;

    this.setState({ mProcessing: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Inbox/ReassignAjax?ItemID=' + this.state.mLeadID + '&userID=' + this.state.mUserID + '&UserIDToAssign=' + UserIDToAssign + '&Note=' + Note, true);
        xhr.onload = function () {
            this.setState({ mProcessing: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == 'success') {
                $('#reassignmodal_' + this.props.id).modal('hide');
                $('#leadviewmodal_' + this.props.id).modal('hide');
            }
            else
                bootbox.alert(data.message);
        }.bind(this);
        xhr.send(); */
    apiAgent.Inbox.reassignAjax(
      this.state.mLeadID,
      this.state.mUserID,
      UserIDToAssign,
      Note
    )
      .then((response) => {
        window.$("#reassignmodal_" + this.props.id).modal("hide");
        window.$("#leadviewmodal_" + this.props.id).modal("hide");
      })
      .catch((error) => bootbox.alert(error.response.message))
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  handlePrintClick = (e) => {
    var printHtml = window.open("", "PRINT", "height=800,width=600");

    printHtml.document.write(
      "<html><head><title>Base(Entegral) - Tenant Application</title></head>"
    );
    printHtml.document.write("<h1>Tenant Applications</h1><hr/><br/>");
    printHtml.document.write(
      document.getElementById("cbTenantApplication").innerHTML
    );
    printHtml.document.write("</body></html>");

    printHtml.document.close();
    printHtml.focus();
    printHtml.print();
    //printHtml.close();
  };

  renderMessageDetail = (mMessageDetails, mPropertyID) => {
    var propertyref = mMessageDetails.propertyref;
    if (mMessageDetails.externalid) {
      propertyref = mMessageDetails.externalid;
      if (
        mMessageDetails.referer &&
        mMessageDetails.referer.indexOf("property24") != -1
      )
        propertyref = "P24-" + propertyref;
    }

    return Object.keys(mMessageDetails).map((item, index) => {
      if (Array.isArray(mMessageDetails[item])) return null;

      if (item == "externalid") return null;

      let mSpan;
      if (item == "referer" || item == "link") {
        if (
          propertyref &&
          mMessageDetails[item].indexOf("myproperty.com.na") != -1
        ) {
          mSpan = (
            <span>
              <b className="json-key">{item}:</b>&nbsp;{" "}
              <a
                href={"https://www.myproperty.com.na?webref=" + propertyref}
                target="_blank"
              >
                {"https://www.myproperty.com.na?webref=" + propertyref}
              </a>
            </span>
          );
        } else {
          mSpan = (
            <span>
              <b className="json-key">{item}:</b>&nbsp;{" "}
              <a href={mMessageDetails[item]} target="_blank">
                {mMessageDetails[item]}
              </a>
            </span>
          );
        }
      } else if (item == "propertyref") {
        if (mPropertyID) {
          mSpan = (
            <span>
              <b className="json-key">{item}:</b>&nbsp;{" "}
              {/* <a href={"/Property/SaveProperty?ID=" + mPropertyID} target="_blank">
                {propertyref}
          </a> */}
              <Link
                to={`/Property/SaveProperty?ID=${mPropertyID}`}
                target="_blank"
              >
                {propertyref}
              </Link>
            </span>
          );
        } else {
          mSpan = (
            <span>
              <b className="json-key">{item}:</b>&nbsp; {propertyref}
            </span>
          );
        }
      } else if (
        item == "message" &&
        mMessageDetails["formtype"].indexOf("Tenant Application") != -1
      ) {
        mSpan = (
          <span data-toggle="modal" data-target="#TenantApplicationModal">
            <b className="json-key">{item}:</b>&nbsp;{" "}
            <a href="#">View tenant application</a>
          </span>
        );
      } else {
        mSpan = (
          <span>
            <b className="json-key">{item}:</b>&nbsp; {mMessageDetails[item]}
          </span>
        );
      }

      return (
        <div key={"LI-" + index}>
          {mSpan}
          <br />
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div
          id={"leadviewmodal_" + this.props.id}
          className="modal fade"
          data-backdrop="static"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4 className="modal-title">
                  {this.state.mMessageDetails.formtype}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <span>
                        <i
                          className="fa fa-id-card-o"
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            marginRight: 8,
                            width: 15,
                          }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;<b>From:</b> {this.state.mSource}
                      </span>
                    </div>
                    <div className="form-group">
                      <span>
                        <i
                          className="fa fa-clock-o"
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            marginRight: 8,
                            width: 15,
                          }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;<b>Received:</b> {this.state.mReceivedDate}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      style={{ height: 15, borderTop: "1px solid #e5e5e5" }}
                    ></div>
                  </div>
                  <div className="col-md-12">
                    {this.props.IsReadOnly ? (
                      <div className="form-group">
                        {this.state.mContactID ? (
                          <span>
                            <b>Contact Name:</b>&nbsp;
                            {/* <a style={{ cursor: "pointer" }} href={"/Contacts/SaveContact?ID=" + this.state.mContactID} target="_blank">
                              {this.state.mMessageDetails.contactname}
                        </a> */}
                            <Link
                              to={`/Contacts/SaveContact?ID=${this.state.mContactID}`}
                              target="_blank"
                              style={{ cursor: "pointer" }}
                            >
                              {this.state.mMessageDetails.contactname}
                            </Link>
                          </span>
                        ) : null}
                      </div>
                    ) : (
                      <div className="form-group">
                        {this.state.mContactID ? (
                          <span>
                            <b>Contact Name:</b>&nbsp;
                            {/*  <a style={{ cursor: "pointer" }} href={"/Contacts/SaveContact?ID=" + this.state.mContactID} target="_blank">
                              {this.state.mMessageDetails.contactname}
                        </a> */}
                            <Link
                              to={`/Contacts/SaveContact?ID=${this.state.mContactID}`}
                              target="_blank"
                              style={{ cursor: "pointer" }}
                            >
                              {this.state.mMessageDetails.contactname}
                            </Link>
                            &nbsp;|&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={this.onReassignClick}
                            >
                              Reassign
                            </a>
                          </span>
                        ) : null}
                      </div>
                    )}
                    {this.state.mPropertyID ? (
                      <div className="form-group">
                        <span>
                          <b>Property Ref:</b>{" "}
                          {/* <a href={"/Property/SaveProperty?ID=" + this.state.mPropertyID} target="_blank">
                            #{this.state.mMessageDetails.propertyref}
                    </a> */}
                          <Link
                            to={`/Property/SaveProperty?ID=${this.state.mPropertyID}`}
                            target="_blank"
                          >
                            #{this.state.mMessageDetails.propertyref}
                          </Link>
                          &nbsp;
                          {this.state.mAddress
                            ? "(" + this.state.mAddress + ")"
                            : ""}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <div
                      style={{ height: 8, borderTop: "1px dotted #e5e5e5" }}
                    ></div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      {this.renderMessageDetail(
                        this.state.mMessageDetails,
                        this.state.mPropertyID
                      )}
                    </div>
                  </div>
                  {this.state.mNote ? (
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="SNote">Note: </label> <br />
                        <span>{this.state.mNote}</span>
                      </div>{" "}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <div className="pull-left">
                  {this.props.IsReadOnly ? null : (
                    <OMSButtonStyleL
                      style={{ marginRight: 10 }}
                      type="button"
                      ui_icon="fa-trash-o"
                      ui_type="cancel"
                      ui_text="Delete"
                      ui_processing_text="Deleting.."
                      processing={this.state.mProcessing}
                      disabled={this.state.mProcessing}
                      onClick={this.onInboxDeleteClick}
                    />
                  )}
                </div>
                {this.state.mIsRead ? (
                  <div>
                    {this.props.IsReadOnly ? null : (
                      <OMSButtonStyleL
                        type="button"
                        ui_icon=""
                        onClick={() => this.onMarkReadOrUnread(false)}
                        ui_type="cancel"
                        ui_text="Mark Unread"
                        ui_processing_text="Marking.."
                        processing={this.state.mProcessing}
                        disabled={this.state.mProcessing}
                      />
                    )}
                    <OMSButtonStyleL
                      type="button"
                      ui_icon=""
                      ui_type="save"
                      ui_text="Close"
                      ui_processing_text="Close"
                      processing={false}
                      disabled={this.state.mProcessing}
                      onClick={this.onCloseModal}
                    />
                  </div>
                ) : (
                  <div>
                    <OMSButtonStyleL
                      type="button"
                      ui_icon=""
                      ui_type="cancel"
                      ui_text="Leave unread"
                      ui_processing_text="Leave unread"
                      processing={false}
                      disabled={this.state.mProcessing}
                      onClick={this.onCloseModal}
                    />
                    {this.props.IsReadOnly ? null : (
                      <OMSButtonStyleL
                        type="button"
                        ui_icon=""
                        onClick={() => this.onMarkReadOrUnread(true)}
                        ui_type="save"
                        ui_text="Mark Read"
                        ui_processing_text="Marking.."
                        processing={this.state.mProcessing}
                        disabled={this.state.mProcessing}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          id={"reassignmodal_" + this.props.id}
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4 className="modal-title">Reassign lead to another agent</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="AgentID">Agent</label>
                      <OMSTwitterAutocompleteAgent
                        id={"searchagent_" + this.props.id}
                        name={"searchagent_" + this.props.id}
                        style={{ width: 250 }}
                        onChange={this.handleAgentChange}
                        OfficeID={this.state.mOfficeID}
                      />
                      <input
                        type="hidden"
                        id={"agentid_" + this.props.id}
                        name={"agentid_" + this.props.id}
                        value=""
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="Note">Add a note</label>
                      <textarea
                        rows={3}
                        className="form-control"
                        id={"note_" + this.props.id}
                        name={"note_" + this.props.id}
                        value={this.state.Note}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div>
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text="Cancel"
                    processing={false}
                    disabled={this.state.mProcessing}
                    onClick={this.onCancelReassignClick}
                  />
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    onClick={this.onSaveReassignClick}
                    ui_type="save"
                    ui_text="Reassign"
                    ui_processing_text="Reassigning.."
                    processing={this.state.mProcessing}
                    disabled={this.state.mProcessing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.mMessageDetails !== undefined &&
          this.state.mMessageDetails !== null &&
          this.state.mMessageDetails["formtype"] !== undefined &&
          this.state.mMessageDetails["formtype"].indexOf(
            "Tenant Application"
          ) != -1 && (
            <div
              id="TenantApplicationModal"
              className="modal fade"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h4 className="modal-title">Tenant Application</h4>
                  </div>
                  <div className="modal-body tenant-application">
                    <div className="row">
                      <div
                        className="col-md-12"
                        id="cbTenantApplication"
                        style={{ maxHeight: 450, overflow: "auto" }}
                        dangerouslySetInnerHTML={{
                          __html: this.state.mMessageDetails[
                            "message"
                          ].replaceAll(
                            /(https:\/\/[^<]+)/g,
                            "<a href='$1' target='_blank'><i class='fa fa-download'></i><span class='only-print'>$1</span></a>"
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="pull-left">
                      <span
                        onClick={this.handlePrintClick}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-print" aria-hidden="true"></i>
                        &nbsp;Print
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
