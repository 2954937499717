import React, { useEffect, useState } from "react";
import PiplelineDashboardFilterModel from "../../app/models/PiplelineDashboardFilterModel";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSMultiselectPTDropdown } from "../../app/common/common-components/OMSMultiselectTagDropdown";
import { OMSMultiselectDropdown } from "../../app/common/common-components/OMSMultiselectDropdown";
import { OMSTwitterAutocomplete2 } from "../../app/common/common-components/OMSAutocomplete";
import { FromDateStringToJavascriptDate, FromJavascriptDateToDateString, isEntegralSuperUser } from "../../app/common/common-functions/functions";
import DatePicker from "react-datepicker";
import { useStore } from "../../app/stores/store";

const LoopFilterComponent = ({ PipelinesDD, PropertyTypesDD, AgentsDD, Data, isAccessPipelinesMenu, loopFilterChanged, PipelineStagesDD, InternalDealTagsDD }) => {

  const [WorkspaceAgentId, setWorkspaceAgentId] = useState("");
  const [WorkspaceAgentName, setWorkspaceAgentName] = useState("");
  const [WorkspacePipelineId, setWorkspacePipelineId] = useState("");
  const [WorkspacePipeline, setWorkspacePipeline] = useState("");
  const [AgentId, setAgentId] = useState("");
  const [AgentName, setAgentName] = useState("");
  const [PipelineId, setPipelineId] = useState("");
  const [Pipeline, setPipeline] = useState("");
  const [StageId, setStageId] = useState("");
  const [Stage, setStage] = useState("");
  const [RegistrationEndTo, setRegistrationEndTo] = useState("");
  const [RegistrationEndToDT, setRegistrationEndToDT] = useState(null);
  const [RegistrationStartFrom, setRegistrationStartFrom] = useState("");
  const [RegistrationStartFromDT, setRegistrationStartFromDT] = useState(null);
  const [Areas, setAreas] = useState([]);
  const [PropertyTypes, setPropertyTypes] = useState([]);
  const [StagesDD, setStagesDD] = useState([]);
  const [InternalDealTagsList, setInternalDealTagsList] = useState([]);
  const [InternalDealTags, setInternalDealTags] = useState([]);

  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  console.log("userSession :: ", userSession);


  useEffect(() => {
    if (Data !== undefined && Data != null) {
      setWorkspaceAgentId(Data.WorkspaceAgentId);
      setWorkspaceAgentName(Data.WorkspaceAgentName);
      setWorkspacePipelineId(Data.WorkspacePipelineId);
      setWorkspacePipeline(Data.WorkspacePipeline);
      setAgentId(Data.AgentId);
      setAgentName(Data.AgentName);
      setPipelineId(Data.PipelineId);
      setPipeline(Data.Pipeline);
      setRegistrationEndTo(Data.RegistrationEndTo);
      setRegistrationStartFrom(Data.RegistrationStartFrom);
      setAreas([...Data.Areas]);
      setPropertyTypes([...Data.PropertyTypes]);
      setRegistrationEndToDT(FromDateStringToJavascriptDate(Data.RegistrationEndTo));
      setRegistrationStartFromDT(FromDateStringToJavascriptDate(Data.RegistrationStartFrom));
      setStageId(Data.StageId);
      setInternalDealTags([...Data.InternalDealTags]);
      setInternalDealTagsList(getDefaultInternalDealTags(InternalDealTagsDD));
    }
  }, [Data]);

  const getDefaultInternalDealTags = (tags) => {
    var items = [];
    for (const tagItem of tags) {
      var item = {
        value: tagItem,
        text: tagItem
      }
      items.push(item);

    }
    return items

  }

  useEffect(() => {

    let mStageDD = [];
    const milestoneOption = {
      StageDescription: "",
      StageID: "",
      StageName: "Milestone",
      StageOrder: 0,
    };

    if (PipelineId && PipelineStagesDD) {

      console.log("PipelineStagesDD Data ", PipelineStagesDD, " Data.PipelineId ", Data.PipelineId);

      const selectedPipeline = PipelineStagesDD
        .find(pipeline => pipeline.PipelineID == PipelineId);

      if (selectedPipeline) {
        mStageDD = selectedPipeline.Stages ? [...selectedPipeline.Stages] : [];


        if (!mStageDD.some(stage => stage.StageName === "Milestone")) {
          mStageDD.unshift(milestoneOption);
        }
      }
      else {
        mStageDD = [];
        mStageDD.unshift(milestoneOption);
        setStageId("");
        setStage("");
      }
      setStagesDD(mStageDD);

    }
    else {
      mStageDD = [];
      mStageDD.unshift(milestoneOption);
      setStageId("");
      setStage("");
      setStagesDD(mStageDD);
    }
  }, [PipelineId])

  const areaSelectionRemoved = (e) => {
    var index = Areas.indexOf(e);
    if (index > -1) {
      Areas.splice(index, 1);
      setAreas([...Areas]);
    }
  }

  const areaSelectionChanged = (e) => {
    var selectedArea = e.suburb == "" ? e.town : e.town + ", " + e.suburb;
    if (Areas.indexOf(selectedArea) === -1) {
      Areas.push(selectedArea);
      setAreas([...Areas]);
    }
  }

  const handleApplyFilterClick = () => {
    const filterModel = new PiplelineDashboardFilterModel();
    filterModel.setData(WorkspaceAgentId, WorkspaceAgentName, WorkspacePipelineId, WorkspacePipeline, AgentId, AgentName, PipelineId, Pipeline, RegistrationStartFrom, RegistrationEndTo, Areas, PropertyTypes, true, StageId, Stage, InternalDealTags);
    loopFilterChanged(filterModel);
    window.$("#pipelineFilterModel").modal("hide");
  }

  const handleResetFilterClick = () => {
    const filterModel = new PiplelineDashboardFilterModel();
    filterModel.setData(WorkspaceAgentId, WorkspaceAgentName, WorkspacePipelineId, WorkspacePipeline, "", "", "", "", "", "", [], [], true, "", "", []);
    loopFilterChanged(filterModel);
    window.$("#pipelineFilterModel").modal("hide");
  }

  const handlePipelineChange = (e) => {
    setPipelineId(e.newValue);
    setPipeline(e.newText);

  }
  const handlePipelineStageChange = (e) => {
    setStageId(e.newValue);
    setStage(e.newText);
  }

  return (
    <div className="modal fade" id="pipelineFilterModel" tabIndex={-1} aria-labelledby="pipelineFilterModel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
            <h4 className="modal-title" id="pipelineFilterModel">
              Filter
            </h4>
          </div>
          <div className="modal-body">

            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12 mt-10 mb-10">
                <OMSMultiselectPTDropdown
                  id="mPropertyTypes"
                  selected={PropertyTypes}
                  data={PropertyTypesDD}
                  onChange={(e) => { setPropertyTypes(e); }}
                  labelField="Property Type(s)"
                  style={{ textAlign: "left", width: "100%" }}
                />


              </div>
              {
                  <div className="col-md-4 col-sm-6 col-xs-12 mt-10 mb-10">
                    <OMSMultiselectDropdown
                      id="mInternalDealTags"
                      name="InternalDealTagsDD"
                      value={InternalDealTags}
                      options={InternalDealTagsList}
                      valueField="value"
                      labelField="text"
                      onChange={(e) => { setInternalDealTags(e); }}
                      label="Deal Tags"
                    />
                  </div>
              }
              {
                !isAccessPipelinesMenu &&
                <>
                  <div className="col-md-4 col-sm-6 col-xs-12 mt-10 mb-10">
                    <OMSDropdown
                      id="mAgent"
                      name="mAgent"
                      style={{ marginRight: 10 }}
                      options={AgentsDD}
                      valueField="Value"
                      labelField="Text"
                      value={AgentId}
                      onChange={(e) => { setAgentId(e.newValue); setAgentName(e.newText); }}
                    />
                  </div>

                </>
              }

            </div>

            {
              !isAccessPipelinesMenu &&
              <>
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12 mt-10 mb-10">
                    <OMSDropdown
                      id="mPipeline"
                      name="mPipeline"
                      options={PipelinesDD}
                      style={{ marginRight: 10 }}
                      valueField="Value"
                      labelField="Text"
                      value={PipelineId}
                      onChange={handlePipelineChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12 mt-10 mb-10">
                    <OMSDropdown
                      id="mPipelineStage"
                      name="mPipelineStage"
                      options={StagesDD}
                      style={{ marginRight: 10 }}
                      valueField="StageID"
                      labelField="StageName"
                      value={StageId}
                      onChange={handlePipelineStageChange}
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12 mt-10 mb-10">

                  </div>
                </div>
              </>
            }

            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12  mt-10 mb-10">
                <label>Expected between</label>
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12  mb-10">

                    <DatePicker
                      selected={RegistrationStartFromDT}
                      onChange={(date) => {
                        setRegistrationStartFrom(FromJavascriptDateToDateString(date));
                        setRegistrationStartFromDT(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                      value={RegistrationStartFromDT}
                      placeholderText="From date"
                      name="mRegistrationStartFrom"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      style={{ width: 150 }}
                      className="form-control MandateDatePicker2"
                    />

                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12  mb-10">
                    <DatePicker
                      selected={RegistrationEndToDT}
                      onChange={(date) => {
                        setRegistrationEndTo(FromJavascriptDateToDateString(date));
                        setRegistrationEndToDT(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                      value={RegistrationEndToDT}
                      placeholderText="To date"
                      name="mRegistrationEndTo"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      style={{ width: 150 }}
                      className="form-control MandateDatePicker2"
                    />

                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-12  mb-10">

                  </div>
                </div>
              </div>

            </div>
            <div className="row mt-10 mb-10">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <label>Area(s)</label>
                <OMSTwitterAutocomplete2 id="SelectedAreas" name="SelectedAreas" value="" onSelect={(e) => areaSelectionChanged(e)} />
              </div>
            </div>
            <div className="row mt-10 mb-10">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="search-bar" style={{ marginTop: 10, marginBottom: 15 }}>
                  {Areas.length === 0 ? null : (
                    <div className="search-bar__container">
                      <div className="search-bar__autocomplete">
                        <div className="search-bar__autocomplete-input">
                          {Areas.map(function (e, index) {
                            return (
                              <div key={index} className="search-bar__autocomplete-selected">
                                {e}{" "}
                                <i
                                  onClick={() => areaSelectionRemoved(e)}
                                  style={{ fontSize: 18, cursor: "pointer", margin: 2, marginRight: 10 }}
                                  className="fa fa-trash"
                                ></i>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" style={{ marginRight: 5 }} onClick={handleResetFilterClick}>
              Reset
            </a>
            <button type="button" className="btn btn-primary" onClick={handleApplyFilterClick}>
              Apply
            </button>
            <button type="button" className="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoopFilterComponent;