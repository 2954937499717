import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from "react-router-dom";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";

class IntegrationBox extends React.Component {
  getFields = (fields, values) => {
    var result = [];
    for (var i = 0; i < fields.length; i++) {
      result.push(fields[i]);
      result[i].value = "";
      for (var j = 0; j < values.length; j++) {
        if (result[i].field == values[j].field)
          result[i].value = values[j].value;
      }
    }
    return result;
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeChk = (e) => {
    var value = false;
    if (e.target.name == "FeedEnabled") {
      this.setState({ FeedEnabled: !this.state.FeedEnabled });
    } else if (e.target.name == "UserSelectable") {
      this.setState({ UserSelectable: !this.state.UserSelectable });
    } else if (e.target.name == "DefaultSelected") {
      this.setState({ DefaultSelected: !this.state.DefaultSelected });
    }
  };

  onCancelClick = (e) => {
    this.props.history.push(
      `/Settings/Integrations?OfficeID=${this.state.OfficeID}`
    );
  };

  getFormData = () => {
    var data = new FormData();
    data.append("officeID", this.state.OfficeID);
    data.append("portalID", this.state.PortalID);
    data.append("username", this.state.Username);
    data.append("password", this.state.Password);
    data.append("agencyID", this.state.AgencyID);
    data.append("package", this.state.Package);
    data.append("strFeedEnabled", this.state.FeedEnabled);
    data.append("strDefaultSelected", this.state.DefaultSelected);
    data.append("strUserSelectable", this.state.UserSelectable);

    var Values = [];
    for (var i = 0; i < this.state.Fields.length; i++) {
      if (this.state.Fields[i].value != "")
        Values.push({
          field: this.state.Fields[i].field,
          value: this.state.Fields[i].value,
        });
    }

    data.append("values", JSON.stringify(Values));
    return data;
  };

  executeClick = (url) => {
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: true,
        processing_delete: false,
      },
    });
    var data = this.getFormData();
    let formData = Object.fromEntries(data.entries());
    var self = this;

    if (url === "/Settings/SaveIntegrationActivateAjax") {
      apiAgent.Setting.saveIntegrationActivateAjax(formData)
        .then((response) => {
          self.props.history.push(
            `/Settings/Integrations?OfficeID=${this.state.OfficeID}`
          );
        })
        .catch((error) => {
          //toast.error(error.response.data);
          toast.error("Failed");
          self.setState({
            ButtonStatus: {
              disabled: false,
              processing_save: false,
              processing_delete: false,
            },
          });
        });
    } else if (url === "/Settings/SaveIntegrationRequestActivateAjax") {
      apiAgent.Setting.saveIntegrationRequestActivateAjax(formData)
        .then((response) => {
          self.props.history.push(
            `/Settings/Integrations?OfficeID=${this.state.OfficeID}`
          );
        })
        .catch((error) => {
          //toast.error(error.response.data);
          toast.error("Failed");
          self.setState({
            ButtonStatus: {
              disabled: false,
              processing_save: false,
              processing_delete: false,
            },
          });
        });
    } else if (url === "/Settings/SaveIntegrationRequestDeactivateAjax") {
      apiAgent.Setting.saveIntegrationRequestDeactivateAjax(formData)
        .then((response) => {
          self.props.history.push(
            `/Settings/Integrations?OfficeID=${this.state.OfficeID}`
          );
        })
        .catch((error) => {
          //toast.error(error.response.data);
          toast.error("Failed");
          self.setState({
            ButtonStatus: {
              disabled: false,
              processing_save: false,
              processing_delete: false,
            },
          });
        });
    } else if (url === "/Settings/SaveIntegrationAjax") {
      apiAgent.Setting.saveIntegrationAjax(formData)
        .then((response) => {
          self.props.history.push(
            `/Settings/Integrations?OfficeID=${this.state.OfficeID}`
          );
        })
        .catch((error) => {
          //toast.error(error.response.data);
          toast.error("Failed");
          self.setState({
            ButtonStatus: {
              disabled: false,
              processing_save: false,
              processing_delete: false,
            },
          });
        });
    }
  };

  onActivateClick = (e) => {
    this.executeClick("/Settings/SaveIntegrationActivateAjax");
  };

  onRequestActivateClick = (e) => {
    this.executeClick("/Settings/SaveIntegrationRequestActivateAjax");
  };

  onRequestDeactivateClick = (e) => {
    let title = "Request deactivate integration?";
    let body = "Are you sure you want to deactivate this integration?";
    let callback = this.executeRequestDeactivateClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  executeRequestDeactivateClick = (e) => {
    this.executeClick("/Settings/SaveIntegrationRequestDeactivateAjax");
  };

  onSaveClick = (e) => {
    this.executeClick("/Settings/SaveIntegrationAjax");
  };

  executeDeactivateClick = (e) => {
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: false,
        processing_delete: true,
      },
    });
    var data = new FormData();
    data.append("officeID", this.state.OfficeID);
    data.append("portalID", this.state.PortalID);
    let formData = Object.fromEntries(data.entries());

    apiAgent.Setting.saveIntegrationDeactivateAjax(formData)
      .then((response) => {
        this.props.history.push(
          `/Settings/Integrations?OfficeID=${this.state.OfficeID}`
        );
      })
      .catch((error) => {
        toast.error(error.response.data);
        //toast.info("Unsuccessful")
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      });
  };

  onDeactivateClick = (e) => {
    let title = "Deactivate integration?";
    let body = "Are you sure you want to  deactivate this integration?";
    let callback = this.executeDeactivateClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  handleDropdownFieldChange = (e) => {
    this.setFieldItem(e.name, e.newValue);
  };

  handleFieldChange = (e) => {
    this.setFieldItem(e.target.name, e.target.value);
  };

  handleCheckBoxFieldChange = (e) => {
    var value = "0";
    if (e.target.checked) value = "1";

    this.setFieldItem(e.target.name, value);
  };

  setFieldItem = (name, value) => {
    var fields = this.state.Fields;
    for (var i = 0; i < fields.length; i++) {
      if (fields[i].field == name) fields[i].value = value;
    }
    this.setState({ Fields: fields });
  };

  renderFieldItem = (item) => {
    if (item.type == "dropdown") {
      var options = [];
      options.push("");
      options = options.concat(item.options.split(","));
      return (
        <div className="form-group">
          <label>{item.title}</label>
          <OMSDropdown
            id={item.field}
            name={item.field}
            options={options}
            valueField=""
            labelField=""
            value={item.value}
            onChange={this.handleDropdownFieldChange}
          />
        </div>
      );
    } else if (item.type == "boolean") {
      return (
        <div className="form-group">
          <span>
            <input
              id={item.field}
              name={item.field}
              type="checkbox"
              value={item.value}
              defaultChecked={item.value == "1"}
              onChange={this.handleCheckBoxFieldChange}
            />
            &nbsp;{item.title}
          </span>
        </div>
      );
    } else if (item.type == "password") {
      return (
        <div className="form-group">
          <label>{item.title}</label>
          <input
            id={item.field}
            name={item.field}
            type="password"
            autocomplete="none"
            className="form-control"
            value={item.value}
            onChange={this.handleFieldChange}
          />
        </div>
      );
    } else {
      return (
        <div className="form-group">
          <label>{item.title}</label>
          <input
            id={item.field}
            name={item.field}
            type="text"
            autocomplete="none"
            className="form-control"
            value={item.value}
            onChange={this.handleFieldChange}
          />
        </div>
      );
    }
  };

  state = {
    OfficeID: this.props.data.officeID,
    PortalID: this.props.data.portalID,
    Username: this.props.data.username,
    Password: this.props.data.password,
    AgencyID: this.props.data.agencyID,
    Package: this.props.data.package,
    FeedEnabled: this.props.data.feedEnabled,
    DefaultSelected: this.props.data.defaultSelected,
    UserSelectable: this.props.data.userSelectable,
    Fields: this.getFields(
      JSON.parse(this.props.data.fields),
      JSON.parse(this.props.data.values)
    ),

    ButtonStatus: {
      disabled: false,
      processing_save: false,
      processing_delete: false,
    },
    ConfirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
    activeTabID: "save-integration-setup-instruction",
  };

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.ConfirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ ConfirmationModalProps: varConfirmationModalProps });
  };
  setActiveTabId = (tabId) => {
    //console.log("inside setActiveTabId tabID=", tabId);
    this.setState({ activeTabID: tabId });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ ConfirmationModalProps: varConfirmationModalProps });
  };

  render() {
    var StateIcon = null;
    var tabContent = [
      {
        link: "#save-integration-setup-instruction",
        title: "Setup Instruction",
      },
      {
        link: "#save-integration-configuration",
        title: "Configuration",
      },
    ];
    if (this.props.data.status == 1) {
      // Disabled
      StateIcon = (
        <i
          className="fa fa-minus-circle fa-2x"
          title="disabled"
          style={{ color: "grey" }}
        ></i>
      );
    } else if (this.props.data.status == 2) {
      // Activation pending
      StateIcon = (
        <i
          className="fa fa-check-circle fa-2x"
          title="activation pending"
          style={{ color: "orange" }}
        ></i>
      );
    } else if (this.props.data.status == 3) {
      // Active
      StateIcon = (
        <i
          className="fa fa-check-circle fa-2x"
          title="active"
          style={{ color: "green" }}
        ></i>
      );
    } else if (this.props.data.status == 4) {
      // Deactivation pending
      StateIcon = (
        <i
          className="fa fa-check-circle fa-2x"
          title="deactivation pending"
          style={{ color: "orange" }}
        ></i>
      );
    }
    return (
      <div>
        <ConfirmationModalPopup {...this.state.ConfirmationModalProps} />
        <form
          className="SaveIntegrationForm"
          autoComplete="off"
          autoCapitalize="off"
          autoCorrect="off"
        >
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={this.props.data.logoUrl}
                      alt={this.props.data.name}
                      className="media-object"
                      style={{ width: 150 }}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="pull-right state-icon">{StateIcon}</div>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="col-md-12">
                  <OMSTabBox
                    id="saveIntegrationTabs"
                    value={tabContent}
                    setActiveTabId={this.setActiveTabId}
                  ></OMSTabBox>
                  <div className="tab-content">
                    <div
                      id="save-integration-setup-instruction"
                      className={
                        this.state.activeTabID ===
                        "save-integration-setup-instruction"
                          ? "tab-pane fade in active"
                          : "tab-pane fade"
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.data.setupInstructions,
                            }}
                          />
                          <input
                            type="text"
                            style={{
                              visibility: "hidden",
                              opacity: 0,
                              height: 0,
                              background: "transparent",
                              border: "none",
                            }}
                            data-description="dummyUsername"
                          ></input>
                          <input
                            type="password"
                            style={{
                              visibility: "hidden",
                              opacity: 0,
                              height: 0,
                              background: "transparent",
                              border: "none",
                            }}
                            data-description="dummyPassword"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div
                      id="save-integration-configuration"
                      className={
                        this.state.activeTabID ===
                        "save-integration-configuration"
                          ? "tab-pane fade in active"
                          : "tab-pane fade"
                      }
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div>
                            {this.state.Fields.map((item) => {
                              return this.renderFieldItem(item);
                            })}
                          </div>
                          <div className="form-group">
                            <span>
                              <input
                                name="FeedEnabled"
                                type="checkbox"
                                checked={this.state.FeedEnabled}
                                onChange={this.handleChangeChk}
                              />
                              &nbsp;Feed enabled for users
                            </span>
                            <br />
                            <span>
                              <input
                                name="DefaultSelected"
                                type="checkbox"
                                checked={this.state.DefaultSelected}
                                onChange={this.handleChangeChk}
                              />
                              &nbsp;Default selected on new listings
                            </span>
                            <br />
                            <span>
                              <input
                                name="UserSelectable"
                                type="checkbox"
                                checked={this.state.UserSelectable}
                                onChange={this.handleChangeChk}
                              />
                              &nbsp;User selectable on listings
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-left">
                      {this.props.data.status != 1 &&
                      this.props.actions.indexOf("deactive") > 0 ? (
                        <OMSButtonStyleL
                          type="button"
                          style={{ marginRight: 10 }}
                          ui_icon="fa-trash-o"
                          ui_type="delete"
                          ui_text="Deactivate integration"
                          ui_processing_text="Deactivating.."
                          processing={this.state.ButtonStatus.processing_delete}
                          disabled={this.state.ButtonStatus.disabled}
                          onClick={this.onDeactivateClick}
                        />
                      ) : null}

                      {(this.props.data.status == 2 ||
                        this.props.data.status == 3) &&
                      this.props.actions.indexOf("request-deactive") > 0 ? (
                        <OMSButtonStyleL
                          type="button"
                          ui_icon="fa-trash-o"
                          ui_type="delete"
                          ui_text="Request deactivate"
                          ui_processing_text="Requesting.."
                          processing={this.state.ButtonStatus.processing_save}
                          disabled={this.state.ButtonStatus.disabled}
                          onClick={this.onRequestDeactivateClick}
                        />
                      ) : null}
                    </div>
                    <div className="pull-right">
                      <OMSButtonStyleL
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={this.state.ButtonStatus.disabled}
                        onClick={this.onCancelClick}
                      />
                      {this.props.actions.indexOf("save") >= 0 ? (
                        <OMSButtonStyleL
                          style={{ marginLeft: 10 }}
                          type="button"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Save"
                          ui_processing_text="Saving.."
                          processing={this.state.ButtonStatus.processing_save}
                          disabled={this.state.ButtonStatus.disabled}
                          onClick={this.onSaveClick}
                        />
                      ) : null}
                      {(this.props.data.status == 1 ||
                        this.props.data.status == 2) &&
                      this.props.actions.indexOf("activate") >= 0 ? (
                        <OMSButtonStyleL
                          style={{ marginLeft: 10 }}
                          type="button"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Activate"
                          ui_processing_text="Activating.."
                          processing={this.state.ButtonStatus.processing_save}
                          disabled={this.state.ButtonStatus.disabled}
                          onClick={this.onActivateClick}
                        />
                      ) : null}
                      {this.props.data.status == 1 &&
                      this.props.actions.indexOf("request-activate") >= 0 ? (
                        <OMSButtonStyleL
                          style={{ marginLeft: 10 }}
                          type="button"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Request activate"
                          ui_processing_text="Requesting.."
                          processing={this.state.ButtonStatus.processing_save}
                          disabled={this.state.ButtonStatus.disabled}
                          onClick={this.onRequestActivateClick}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(IntegrationBox);
