import React from "react";
import { OMSButtonStyleL } from "../../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";

const TownDropdown = ({
  TownDD,
  ProvinceID,
  TownID,
  handleTownDDChange,
  ButtonStatus,
  onCreateTownClick,
  onUpdateTownClick,
  onDeleteTownClick
}) => (
  <div className="form-group">
    <label htmlFor="TownDD">Town</label>
    <OMSDropdown
      id="TownDD"
      options={TownDD}
      valueField="value"
      labelField="text"
      value={TownID}
      onChange={handleTownDDChange}
    />
    {ProvinceID === "" ? null : TownID === "" ? (
      <div style={{ marginTop: 5 }}>
        <OMSButtonStyleL
          type="button"
          style={{ marginRight: 10 }}
          ui_icon="fa-plus"
          ui_type="delete"
          ui_text="Add"
          ui_processing_text=""
          processing={false}
          disabled={ButtonStatus.disabled}
          onClick={onCreateTownClick}
        />
      </div>
    ) : (
      <div style={{ marginTop: 5 }}>
        <OMSButtonStyleL
          type="button"
          style={{ marginRight: 10 }}
          ui_icon="fa-plus"
          ui_type="delete"
          ui_text="Add"
          ui_processing_text=""
          processing={false}
          disabled={ButtonStatus.disabled}
          onClick={onCreateTownClick}
        />
        <OMSButtonStyleL
          type="button"
          style={{ marginRight: 10 }}
          ui_icon="fa-edit"
          ui_type="delete"
          ui_text="Edit"
          ui_processing_text=""
          processing={false}
          disabled={ButtonStatus.disabled}
          onClick={onUpdateTownClick}
        />
        <OMSButtonStyleL
          type="button"
          ui_icon="fa-trash-o"
          ui_type="delete"
          ui_text="Delete"
          ui_processing_text="Deleting.."
          processing={ButtonStatus.processing_delete}
          disabled={ButtonStatus.disabled}
          onClick={onDeleteTownClick}
        />
      </div>
    )}
  </div>
);

export default TownDropdown;
