import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { AuditTrailResponseModel } from "../../../app/models/AuditTrailResponseModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import { AuditTrailGrid } from "./AuditTrailGrid";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function AuditTrailPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [auditTrail, setAuditTrail] = useState<AuditTrailResponseModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); 
    let moduleID = 0;
    let objectID: string = "";
    if (params.has("ModuleID")) {
      //const oContact:ContactModel=new ContactModel();

      const id = params.get("ModuleID");
      if(id)
      {
        moduleID = parseInt(id);
      }else{moduleID = 0;}
      //moduleID = id !== null ? id : "";

      //console.log("Inside useEffect:contact id is",contactId);
    }
    if (params.has("ObjectID")) {
      //const oContact:ContactModel=new ContactModel();

      const id2 = params.get("ObjectID");
      objectID = id2 !== null ? id2 : "";

      //console.log("Inside useEffect:contact id is",contactId);
    }
    setIsLoading(true);
    apiAgent.Setting.getAuditTrail(objectID, moduleID)
      .then((response: AuditTrailResponseModel) => {
        let oAuditTrail: AuditTrailResponseModel = response;
        //console.log("Audit Trail Page", oAuditTrail);
        setAuditTrail(oAuditTrail);
      })
      .catch((error: any) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Audit Trail";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && auditTrail) {
    return (
      <>
        <HeaderWrapper />

        <div className="auditTrail-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Audit Trail" },
            ]}
          />

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              <AuditTrailGrid
                propUserDD={auditTrail.userViewDD}
                propOfficeDD={auditTrail.officeDD}
                propOfficeID={auditTrail.officeID}
                propUserID={auditTrail.userID}
                propModuleID={auditTrail.moduleID}
                propObjectID={auditTrail.objectID}
                propObjectName={auditTrail.objectName}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
