import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { SyncIssuesResponseModel } from "../../../app/models/SyncIssuesResponseModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import SyncIssuesBox from "./SyncIssuesBox";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function SyncIssuesPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [syncIssues, setSyncIssues] = useState<SyncIssuesResponseModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    setIsLoading(true);
    apiAgent.Admin.getSyncIssues()
      .then((response: SyncIssuesResponseModel) => {
        let oSyncIssues: SyncIssuesResponseModel = response;
        setSyncIssues(oSyncIssues);
        //console.log("Sync Issues", oSyncIssues);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Sync Issues";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && syncIssues) {
    return (
      <>
        <HeaderWrapper />

        <div className="container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Sync Issues" },
            ]}
          />

          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <SyncIssuesBox OfficeDD={syncIssues.officeDD} />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
