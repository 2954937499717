import { makeAutoObservable, runInAction } from "mobx";
import { history } from "../..";
import apiAgent from "../api/apiAgent";
import { UserFormValues, UserSession } from "../models/User";
// import { User, UserFormValues, UserSession } from "../models/User";
import { store } from "./store";

export default class UserStore {
  userSession: UserSession | null = null;
  //loginSession: LoginSession | null = null;

  refreshTokenTimeout: any;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    if (!this.userSession) {
      this.getUserSession();
    }
    return !!this.userSession;
  }

  login = async (userSession: UserSession) => {
    try {
      //const userSession = await apiAgent.Account.login(creds);
      //console.log("Inside UserStore, Login, userSession=",userSession);
      store.commonStore.setToken(userSession.token);
      //this.startRefreshTokenTimer(user);
      runInAction(() => (this.userSession = userSession));
      history.push("/");
    } catch (error: any) {
      throw error;
    }
  };

  loginAs= async (userID: string) => {
    try {      
      const userSession = await apiAgent.Account.loginAs(userID);
       //console.log("Inside UserStore, Login",userSession);
      store.commonStore.setToken(userSession.token);
      //this.startRefreshTokenTimer(user);
      runInAction(() => (this.userSession = userSession));
      history.push("/");
    } catch (error: any) {
      throw error;
    }
  };

  logout = () => {
    // this.loginSession={
    //   themeColors:this.userSession?.themeColors!
    // }    

    if (!this.userSession) {
      this.getUserSession();
    }
    //console.log("inside logout, this.userSession=",this.userSession!);
    if(this.userSession?.isRemember){
      localStorage.setItem('userDetails', JSON.stringify({ profilePic: this.userSession.profilePic, email: this.userSession.emailAddress, name: this.userSession.displayName, systemTheme: this.userSession.themeColors }));
    }
    else{
      localStorage.removeItem('userDetails');
    }
    store.commonStore.setToken(null);
    window.localStorage.removeItem("jwt");
    runInAction(() => (this.userSession = null));  

   
    
    //console.log("inside logout",this.loginSession);


    // if(themeColors){
    //   console.log("inside logout themeColors",themeColors);
    //   this.userSession!.themeColors = themeColors;
    // }

    history.push("/");
  };
  logoutAs = async () => {
    // store.commonStore.setToken(null);
    // window.localStorage.removeItem("jwt");
    // this.userSession = null;
    // history.push("/");

    // It would work like loginAs. Just get the updated token based on the mainUserID and assign here.
    // If main user ID is not present there, then create the mainUserID field in token.

    try {      
      const userSession = await apiAgent.Account.logoutAs();
       //console.log("Inside UserStore, logout as",userSession);
      store.commonStore.setToken(userSession.token);
      //this.startRefreshTokenTimer(user);
      runInAction(() => (this.userSession = userSession));
      history.push("/");
    } catch (error: any) {
      throw error;
    }
  };

  getUserSession = async () => {
    try {
      if (store.commonStore.token) {
        const userSession = await apiAgent.Account.current();
        
        store.commonStore.setToken(userSession.token);
        runInAction(() => (this.userSession = userSession));
      }

      //this.startRefreshTokenTimer(user);
    } catch (error) {
      //console.log(error);
    }
  };

 




  register = async (creds: UserFormValues) => {
    try {
      await apiAgent.Account.register(creds);
      history.push(`/account/registerSuccess?emailAddress=${creds.emailAddress}`);
      //store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  setImage = (image: string) => {
    if (this.userSession) this.userSession.profilePic = image;
  };

  setDisplayName = (name: string) => {
    if (this.userSession) this.userSession.displayName = name;
  };

  // refreshToken = async () => {
  //     this.stopRefreshTokenTimer();
  //     try {
  //         const user = await apiAgent.Account.refreshToken();
  //         runInAction(() => this.user = user);
  //         store.commonStore.setToken(user.token);
  //         this.startRefreshTokenTimer(user);
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  // private startRefreshTokenTimer(user: User) {
  //     const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
  //     const expires = new Date(jwtToken.exp * 1000);
  //     const timeout = expires.getTime() - date.now() - (60 * 1000);
  //     this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  // }

  // private stopRefreshTokenTimer() {
  //     clearTimeout(this.refreshTokenTimeout);
  // }
}
