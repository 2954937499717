import React, { useState } from "react";
import { LoginModel } from "../../app/models/LoginModel";

import BrowserInfoModal from "./BrowserInfoModal";
import LoginBox from "./LoginBox";
import { Redirect } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react";
import AuthFooter from "./AuthFooter";
import logo from "../../assets/images/base-login.png";
import AuthCodeBox from "./AuthCodeBox";



export default observer(function AuthCodePage() {

  const { userStore } = useStore();


  document.title = "Base - Login";

  if (userStore.isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <div className="login container">
        <div className="row oms-login-box">
          <div className="col-lg-4 col-md-3 col-sm-2"></div>
          <div className="col-lg-4 col-md-6 col-sm-8">
            <div className="logo">
              <img src={logo} alt="Base" />
            </div>

            <AuthCodeBox />

            <br />
            <br />
          </div>
          <div className="col-lg-4 col-md-3 col-sm-2"></div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <AuthFooter />
          </div>
        </div>
      </div>
    </>
  );
});
