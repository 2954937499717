import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';

export function OMSActionButtonUrl(props) {
  let history = useHistory();

  return (
    <div className="omsactionbutton" onClick={() => history.push(props.url)}>
      <span className={"fa " + props.ui_icon + " fa-5x"}></span>
      <br />
      <label>{props.ui_header}</label>
      <p>{props.ui_description}</p>
    </div>
  );
}

export function OMSActionButtonUrlDisabled(props) {
  return (
    <div className="omsactionbutton-disabled">
      <span className={"fa " + props.ui_icon + " fa-5x"}></span>
      <br />
      <label>{props.ui_header}</label>
      <p>{props.ui_description}</p>
    </div>
  );
}

export class OMSButtonStyleL extends React.Component {
  static propTypes = {
    ui_type: PropTypes.string.isRequired,
    ui_icon: PropTypes.string.isRequired,
    ui_text: PropTypes.string.isRequired,
    ui_processing_text: PropTypes.string.isRequired,
    processing: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    ui_class: PropTypes.string,
  };

  state = { hover: false };

  getClassName = () => {
    var cssClass = "btn";
    if (this.props.ui_type === "save" || this.props.ui_type === "create") cssClass += " btn-success-oms";

    if (this.props.disabled === true) cssClass += " disabled";

    if (this.props.ui_class) cssClass += " " + this.props.ui_class;

    return cssClass;
  };

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    return this.props.processing ? (
      <div className={this.getClassName()} style={this.props.style}>
        <span className="fa fa-spin fa-spinner" /> {this.props.ui_processing_text}
      </div>
    ) : this.props.ui_type === "save" ? (
      <button
        type="submit"
        style={this.props.style}
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}
        className={this.getClassName()}
        onClick={this.props.onClick}
      >
        {this.props.ui_icon === "" ? null : <span className={"fa " + this.props.ui_icon} />} {this.props.ui_text}
      </button>
    ) : (
      <a href="#" style={this.props.style} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} className={this.getClassName()} onClick={this.props.onClick}>
        {this.props.ui_icon === "" ? null : <span className={"fa " + this.props.ui_icon} />} {this.props.ui_text}
      </a>
    );
  }
}

export class OMSButtonStyleL2 extends React.Component {
  static propTypes = {
    ui_type: PropTypes.string.isRequired,
    ui_icon: PropTypes.string.isRequired,
    ui_text: PropTypes.string.isRequired,
    ui_processing_text: PropTypes.string.isRequired,
    processing: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
  };

  state = { hover: false };

  getClassName = () => {
    var cssClass = "btn";
    if (this.props.ui_type === "save" || this.props.ui_type === "create") cssClass += " btn-success-oms";

    if (this.props.disabled === true) cssClass += " disabled";

    return cssClass;
  };

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    return this.props.processing ? (
      <div className={this.getClassName()} style={this.props.style}>
        <span className="fa fa-spin fa-spinner" /> {this.props.ui_processing_text}
      </div>
    ) : this.props.ui_type === "save" ? (
      <button
        type="button"
        style={this.props.style}
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}
        className={this.getClassName()}
        onClick={this.props.onClick}
      >
        {this.props.ui_icon === "" ? null : <span className={"fa " + this.props.ui_icon} />} {this.props.ui_text}
      </button>
    ) : (
      <a href="#" style={this.props.style} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut} className={this.getClassName()} onClick={this.props.onClick}>
        {this.props.ui_icon === "" ? null : <span className={"fa " + this.props.ui_icon} />} {this.props.ui_text}
      </a>
    );
  }
}
