export function webref_format(value, PropertyID) {
  // NotEnabledOrSelected = 0, InProcess = 6, Active = 2, Removed = 3, Error = 4
  var jValue = get_json(value);
  var syncHtml = "";
  if (jValue.syncstatus == 2) {
    syncHtml = "<i class='fa fa-check-circle' style='color: green'></i>";
  } else if (jValue.syncstatus == 4) {
    syncHtml = "<i class='fa fa-exclamation-triangle' style='color: red'></i>";
  } else if (jValue.syncstatus == 6) {
    syncHtml = "<i class='fa fa-clock-o' style='color: orange'></i>";
  } else {
    syncHtml = "<i class='fa fa-minus-circle' style='color: transparent'></i>";
  }

  return "<span>" + syncHtml + "&nbsp;<a href='/Property/SaveProperty?ID=" + PropertyID + "' title='Open listing'>" + jValue.webref + "</a></span>";
} 
export function deal_webref_format(value, DealID) {
    return "<span><a title='Open Deal'>" + value + "</a></span>";
  }

export function task_roles_format(data) {
  let roles = "";
  data?.map((curRole) => {
   // console.log(curRole);
    roles += curRole.assignedRole;
    if (curRole.name && curRole.name.length > 0) {
      roles += ":";
      for (let i = 0; i < curRole.name.length; i++) {
        if (i == 0) roles += curRole.name[i].text;
        else roles += "," + curRole.name[i].text;
      }
    }
    roles += ", ";
  });

  if (roles) return roles.substring(0, roles.length - 2);
  return roles;
}

export function ref_format2(value) {
  var data = get_json(value);
  if (data.status === undefined) return "<span><i class='fa fa-minus-circle' style='color: grey'></i>&nbsp;not enabled</span>";

  if (data.selected == "0" && data.status == "3") {
    if (data.ref == "") data.ref = "removed";

    return "<span><i class='fa fa-minus-circle' style='color: grey'></i>&nbsp;" + data.ref + "</span>";
  } else if (data.status == "2") {
    if (data.ref == "") data.ref = "active";

    return "<span><i class='fa fa-check-circle' style='color: green'></i>&nbsp;" + data.ref + "</span>";
  } else if (data.status == "3") {
    if (data.ref == "") data.ref = "removed";

    return "<span><i class='fa fa-times-circle' style='color: red'></i>&nbsp;" + data.ref + "</span>";
  } else if (data.status == "4") {
    if (data.ref == "") data.ref = "error";

    return "<span><i class='fa fa-exclamation-triangle' style='color: red'></i>&nbsp;" + data.ref + "</span>";
  } else if (data.status == "5" || data.status == "6") {
    if (data.ref == "") data.ref = "pending";

    return "<span><i class='fa fa-clock-o' style='color: orange'></i>&nbsp;" + data.ref + "</span>";
  } else {
    if (data.ref == "") data.ref = "not enabled";

    return "<span><i class='fa fa-minus-circle' style='color: grey'></i>&nbsp;" + data.ref + "</span>";
  }
}

export function ref_format(value) {
  var data = get_json(value);
  if (data.status === undefined) return "<span title='not enabled'><i class='fa fa-minus-circle' style='color: grey'></i></span>";

  if (data.selected == "0" && data.status == "3") {
    if (data.ref == "") data.ref = "removed";

    return "<span title='" + data.ref + "'><i class='fa fa-minus-circle' style='color: grey'></i></span>";
  } else if (data.status == "2") {
    if (data.ref == "") data.ref = "active";

    return "<span title='" + data.ref + "'><i class='fa fa-check-circle' style='color: green'></i></span>";
  } else if (data.status == "3") {
    if (data.ref == "") data.ref = "removed";

    return "<span title='" + data.ref + "'><i class='fa fa-times-circle' style='color: red'></i></span>";
  } else if (data.status == "4") {
    if (data.ref == "") data.ref = "error";

    return "<span title='" + data.ref + "'><i class='fa fa-exclamation-triangle' style='color: red'></i></span>";
  } else if (data.status == "5" || data.status == "6") {
    if (data.ref == "") data.ref = "pending";

    return "<span title='" + data.ref + "'><i class='fa fa-clock-o' style='color: orange'></i></span>";
  } else {
    if (data.ref == "") data.ref = "not enabled";

    return "<span title='" + data.ref + "'><i class='fa fa-minus-circle' style='color: grey'></i></span>";
  }
}

export function get_json(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return { error: "" };
  }
}

export function ToPriceString(value) {
  if (isNaN(value)) return "";

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(value));
  // return parseFloat(value).toFixed(2);
}

export function ToPriceStringWithoutDecimal(value) {
  if (isNaN(value)) return "";

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(parseFloat(value));
  // return parseFloat(value).toFixed(2);
}

export function convertToISODate(dateStr) {
  // console.log("inside convertToISODate, dateStr: ", dateStr);
  if (dateStr && typeof dateStr === "string") {
    dateStr = dateStr.trim();

    // Check if the dateStr is already in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ)
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
    if (isoDatePattern.test(dateStr)) {
      return dateStr;
    }

    // Attempt to convert non-ISO date strings to ISO format
    try {
      let parsedDate = new Date(dateStr.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
      return parsedDate.toISOString();
    } catch (error) {
      console.error("Date parsing error: ", error);
      return dateStr;
    }
  } else {
    return ""; // Return empty string if the value is null or empty
  }
}

export function ToTaskStageString(status) {
  if (status == 1) return "Not Started";
  else if (status == 2) return "In Progress";
  else if (status == 3) return "Issue";
  else if (status == 4) return "Completed";
  else if (status == 5) return "Deleted";
  else return "";
}

export function FromDateStringToJavascriptDate(dateStr) {
  //console.log('FromDateStringToJavascriptDate');
  //console.log(dateStr);
  try {
    if (dateStr) {
      let [day, month, year] = dateStr.split("-");
      return new Date(+year, +month - 1, +day);
    }
    return null;
  } catch {
    return null;
  }
}

export function FromJavascriptDateToDateString(date) {
  console.log("FromJavascriptDateToDateString");
  console.log(date);
  try {
    if (date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());

      return `${day}-${month}-${year}`;
    }

    return "";
  } catch {
    return "";
  }
}

export function FromJavascriptDateToDateInputStandardString(date) {
  try {
    if (date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());

      return `${year}-${month}-${day}`;
    }

    return "";
  } catch {
    return "";
  }
}

export function formatDate(dateString) {
   
  if(!dateString)
    return "";

  if (!/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    return "";
  }

  const parts = dateString.split("-");
  if(parts.length != 3)
    return "";

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return "";
  }

  if (month < 1 || month > 12) {
    return "";
  }

  const inputDate = new Date(year, month - 1, day);
  if (inputDate.getDate() !== day || inputDate.getMonth() !== month - 1 || inputDate.getFullYear() !== year)
    return "";

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfMonth = inputDate.getDate();
  const abbreviatedMonth = monthNames[inputDate.getMonth()];
  const fullYear = inputDate.getFullYear();

  const formattedDateStr = `${dayOfMonth} ${abbreviatedMonth}`;
  return formattedDateStr;
}


export function formatDateForPropertyReports(dateString) {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}, ${date.toLocaleTimeString(
    "en-US",
    { hour12: true }
  )}`;
}

 export function isEntegralSuperUser(userSession){
    return userSession.role === "EntegralSuperUser";
  }