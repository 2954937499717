import React, { useState } from "react";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { toast } from "react-toastify";
import HeaderWrapper from "../../header/HeaderWrapper";
import apiAgent from "../../../app/api/apiAgent";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";
import { useHistory } from 'react-router-dom';

const FlexAgentSyncDBox = () => {
  const [webRef, setWebRef] = useState("");
  const [sourceId, setSourceId] = useState("");
  const [processing, setprocessing] = useState(false);

  let history = useHistory();

  const onCancelClick = (e) => {
    history.push("/Settings");
  };

  const onPerformClick = (e) => {
    if (webRef) {
      setprocessing(true);
      apiAgent.Admin.FlexAgentSyncDAjax(webRef,sourceId).then((data)=>toast.info(data.message)).catch((err)=>console.log(err))
      setWebRef("");
      setSourceId("");
      setprocessing(false);
    } else {
      toast.warn("Please enter AgentID");
    }
  };

  return (
    <>
      <HeaderWrapper />

      <div className="container-fluid">
        <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Flex Agent Removal" },
            ]}
          />
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-sm-8">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <label>Flex Agent Removal</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="WebRef">
                      AgentID<span className="danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="WebRef"
                      className="form-control required-control text-350"
                      value={webRef}
                      onChange={(e) => setWebRef(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="SourceId">SourceId</label>
                    <input
                      type="text"
                      name="SourceId"
                      className="form-control required-control text-350"
                      value={sourceId}
                      onChange={(e) => setSourceId(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={false}
                        onClick={onCancelClick}
                      />
                      <OMSButtonStyleL
                        type="button"
                        ui_icon="fa-check"
                        ui_type="save"
                        ui_text="Remove"
                        ui_processing_text="Removing.."
                        processing={processing}
                        disabled={processing}
                        onClick={onPerformClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  );
};

export default FlexAgentSyncDBox;
