import React, { useRef, useEffect, useState } from "react";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer/dist/cjs/main";


function TelerikReportViewerComponent(props) {
  //const [key, setKey] = useState(1);
 
  //const [reportValuesJson, setReportValuesJson] = useState({});
  const viewerRef = useRef();

  const concatenatedKey = Object.values(props.reportValues).join('|');
    // Extract the expression to a variable

  return (
    <>
      {props.reportValues.reportLocation && (
        <TelerikReportViewer
          
          key={concatenatedKey}
          ref={viewerRef}
          reportSource={{
           
            report: props.reportValues.reportLocation ,
            
            parameters: {
              Token: props.reportValues.officeToken,
              OfficeToken: props.reportValues.officeToken,
              OfficeName: props.reportValues.officeName,
              ApiKey: props.reportValues.officeToken,
              OfficeLogo: props.reportValues.officeLogo,
              OfficeWebsiteURL: props.reportValues.officeWebsiteURL,
              SnapshotToken: props.reportValues.snapshotToken,
              mandate_start_from: props.reportValues.mandate_start_from,
              mandate_start_to: props.reportValues.mandate_start_to,
              mandate_end_from: props.reportValues.mandate_end_from,
              mandate_end_to: props.reportValues.mandate_end_to,
              mandate_saletype: props.reportValues.mandate_saletype,
              mandate_status: props.reportValues.mandate_status,
              mandate_agentid: props.reportValues.mandate_agentid,
              mandate_price_to: props.reportValues.mandate_price_to,
              mandate_price_from: props.reportValues.mandate_price_from,
              mandate_type: props.reportValues.mandate_type,
              property_type: props.reportValues.property_type,
              DealID: props.reportValues.dealID,
              UserID: props.reportValues.userID,
              Currency: props.reportValues.currency,
              agent_id: props.reportValues.mandate_agentid,
              pipeline_id: props.reportValues.pipelineId,
              registration_start_date: props.reportValues.registrationStartDate,
              registration_end_date: props.reportValues.registrationEndDate,
              milestone_id: props.reportValues.milestoneId
            },
          }}
          viewerContainerStyle={{
            position: "absolute",
            left: "5px",
            right: "5px",
            top: "40px",
            bottom: "5px",
            overflow: "hidden",
            clear: "both",
            fontFamily: "ms sans serif",
          }}
          serviceUrl={process.env.REACT_APP_ROOT_URL + "/api/reports"}
          scale={1.0}
          viewMode="INTERACTIVE"
          //scaleMode="SPECIFIC"
          //enableAccessibility={false}
          persistSession={false}
          parametersAreaVisible={true}
          documentMapVisible={true}
          pageMode="CONTINUOUS_SCROLL"
          scaleMode="FIT_PAGE_WIDTH"
          printMode="AUTO_SELECT"
          enableAccessibility={false}
          searchMetadataOnDemand={false}
          renderingBegin=""
          renderingEnd=""
          printBegin=""
          printEnd=""
          exportBegin=""
          exportEnd=""
          updateUi=""
          error=""
          pageReady=""
          interactiveActionExecuting=""
          interactiveActionEnter=""
          interactiveActionLeave=""
          viewerToolTipOpening=""
          parameterEditors={[]}
          ready={() => {
            window.$("[data-command*='telerik_ReportViewer_print']").parent().hide();
          }}
        />
      )}
      
    </>
  );
}

export default TelerikReportViewerComponent;
