import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import MapToolBox from "./MapToolBox";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function MapAreaToolPage() {
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  document.title = "Base - Map Area Tool";

  return (
    <>
      <HeaderWrapper />

      <div className="mapAreaTool-page container-fluid">
        <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Map Area Tool" },
            ]}
          />

        <div className="row">
          <div className="hidden-sm col-md-2"></div>
          <div id="content" className="col-sm-12 col-md-8">
            <MapToolBox />
          </div>
          <div className="hidden-sm col-md-2"></div>
        </div>
      </div>
    </>
  );
});
