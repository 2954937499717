import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { CustomizeModel } from "../../../app/models/CustomizeModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import CustomizeBox from "./CustomizeBox";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function CustomizePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [customize, setCustomize] = useState<CustomizeModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    setIsLoading(true);
    apiAgent.Setting.getCustomize()
      .then((response: CustomizeModel) => {
        let oCustomize: CustomizeModel = response;
        //console.log("after api call,oCustomize=", oCustomize);
        setCustomize(oCustomize);
        //console.log("customize", oCustomize);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Customize";

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading && customize) {
    return (
      <>
        <HeaderWrapper />

        <div className="customize-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Customize" },
            ]}
          />

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              <CustomizeBox
                data={customize}
                favourite={customize.favourite}
                actions={customize.actions}
                super_access={customize.super_access}
                userSession={userSession}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
