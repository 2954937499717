import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import { withRouter } from 'react-router-dom';

class ReportsGrid extends React.Component {
  loadReportsFromServer = (view) => {
    this.setState({ loading: true, data: [], data2: [], view: view });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Admin/ReportsAjax?View=' + view, true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (view == 1)
                this.setState({ data: data, hasTableShown: false, loading: false, view: view });
            else
                this.setState({ data2: data, hasTableShown: true, loading: false, view: view });
        }.bind(this);
        xhr.send(); */
    apiAgent.Admin.reportsAjax(view)
      .then((response) => {
        if (view == 1)
          this.setState({
            data: response,
            hasTableShown: false,
            loading: false,
            view: view,
          });
        else
          this.setState({
            data2: response,
            hasTableShown: true,
            loading: false,
            view: view,
          });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  componentDidMount() {
    this.loadReportsFromServer(1);
  }

  onCreateClick = (e) => {
    if (this.state.view == 2) 
    this.props.history.push('/Admin/SaveReport');
    else 
    this.props.history.push('/Admin/SaveBrochure');
  };

  onViewDropDownChange = (e) => {
    this.loadReportsFromServer(e.target.value);
  };

  handleRowClickBrochure = (data) => {
    this.props.history.push('/Admin/SaveBrochure?ID=' + data.brochureID);
  };

  handleRowClickReport = (data) => {
    this.props.history.push('/Admin/SaveReport?ID=' + data.reportID);
  };

  state = {
    data: [],
    data2: [],
    view: 1,
    loading: false,
    hasTableShown: false,
  };

  render() {
    var columns = [
      { data: "brochureName", title: "BrochureName" },
      { data: "formatString", title: "Format" },
      { data: "fileType", title: "Source FileType" },
      { data: "exportType", title: "Export Type" },
      { data: "isGeneric", title: "IsGeneric" },
      { data: "officeName", title: "OfficeName" },
      { data: "groupName", title: "GroupName" },
    ];

    var columns2 = [
      { data: "reportName", title: "ReportName" },
      { data: "categoriesString", title: "Categories" },
      { data: "isGeneric", title: "IsGeneric" },
      { data: "officeName", title: "OfficeName" },
      { data: "groupName", title: "GroupName" },
    ];

    return (
      <div className="col-md-12">
        <div className="panel panel-default panel-table oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline form-group">
                  <div className="element">
                    <div className="truncate">
                      <label>
                        <i className="fa fa-filter" aria-hidden="true"></i>
                        &nbsp;
                      </label>
                      <select style={{ marginRight: 10 }} className="form-control" onChange={this.onViewDropDownChange} value={this.state.view}>
                        <option value="1">Brochures</option>
                        <option value="2">Reports</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pull-right">
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="create"
                    ui_text={this.state.view == 2 ? "add report" : "add brochure"}
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCreateClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="table-responsive" style={{ display: this.state.view == 1 ? "block" : "none" }}>
              <DataTableComp id="brochureTable" columns={columns} columnDefs={[]} data={this.state.data} order={[[1, "asc"]]} onRowClick={this.handleRowClickBrochure} />
            </div>
            <div className="table-responsive" style={{ display: this.state.view == 2 ? "block" : "none" }}>
              <DataTableComp
                id="reportTable"
                columns={columns2}
                columnDefs={[]}
                hasTableShown={this.state.hasTableShown}
                data={this.state.data2}
                order={[[1, "asc"]]}
                onRowClick={this.handleRowClickReport}
              />
            </div>
            <DataTableLoading loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportsGrid);
