import React from "react";

function ImageWithFallback({ src, alt, fallbackSrc, ...props }) {
    const handleError = (e) => {
      e.target.onerror = null; // Prevents infinite loop in case fallback image also fails to load
      e.target.src = fallbackSrc;
    };
  
    return <img src={src} alt={alt} onError={handleError} {...props} />;
  }

  export default ImageWithFallback;