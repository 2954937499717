import React from "react";
import PropTypes from "prop-types";
import { OMSTabBox } from "../../../../app/common/common-components/OMSTabBox";

export class ImportDescriptionStepView extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  };

  getStateFromData = (data) => {
    return {
      Step: "Listing capture: Description",
      PropertyID: this.ToString(data.propertyID),
      ImportID: this.ToString(data.importID),
      Headline: this.ToString(data.headline),
      Description: this.ToString(data.description),
      ItemsIncluded: this.ToString(data.itemsIncluded),
      ItemsExcluded: this.ToString(data.itemsExcluded),
      Defects: this.ToString(data.defects),
      activeTabID: this.state
        ? this.state.activeTabID
        : "import-description-description",
    };
  };

  componentDidMount() {}

  doSetFormData = (data) => {
    /* data.append('Description.PropertyID', this.state.PropertyID);
        data.append('Description.ImportID', this.state.ImportID);
        data.append('Description.Headline', this.state.Headline);
        data.append('Description.Description', this.state.Description);
        data.append('Description.ItemsIncluded', this.state.ItemsIncluded);
        data.append('Description.ItemsExcluded', this.state.ItemsExcluded);
        data.append('Description.Defects', this.state.Defects); */
    const descriptionData = {
      PropertyID: this.state.PropertyID,
      ImportID: this.state.ImportID,
      Headline: this.state.Headline,
      Description: this.state.Description,
      ItemsIncluded: this.state.ItemsIncluded,
      ItemsExcluded: this.state.ItemsExcluded,
      Defects: this.state.Defects,
    };

    data.append("DescriptionJson", JSON.stringify(descriptionData));
  };

  doCheckFormData = (data) => {
    var result = { status: "error", message: "Fields are required." };
    var resultSuccess = { status: "success", message: "" };

    if (!this.state.Headline) {
      result.message = "Please enter headline";
      return result;
    } else if (!this.state.Description) {
      result.message = "Please enter description";
      return result;
    }

    return resultSuccess;
  };

  doSetState = (data) => {
    this.setState(this.getStateFromData(data));
  };

  doGetState = () => {
    return this.state;
  };

  ToString = (value) => {
    if (value === undefined || value == null) return "";
    else return value;
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  state = this.getStateFromData(this.props.data);
  setActiveTabId = (tabId) => {
    //console.log("inside setActiveTabId tabID=", tabId);
    this.setState({ activeTabID: tabId });
  };
  render() {
    var tabContent = [
      { link: "#import-description-description", title: "Description" },
      { link: "#import-description-itemincluded", title: "Items Included" },
      { link: "#import-description-itemexcluded", title: "Items Excluded" },

      { link: "#import-description-defects", title: "Defects" },
    ];
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <OMSTabBox
              id="importDescriptionTabs"
              value={tabContent}
              setActiveTabId={this.setActiveTabId}
            ></OMSTabBox>
            <div className="tab-content">
              <div
                id="import-description-description"
                className={
                  this.state.activeTabID === "import-description-description"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="Headline">Headline catchphrase</label>
                      <input
                        type="text"
                        className="form-control"
                        id="Headline"
                        name="Headline"
                        placeholder="Headline catchphrase"
                        value={this.state.Headline}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="Description">
                        Full advertising description
                      </label>
                      <textarea
                        rows={10}
                        className="form-control"
                        id="Description"
                        name="Description"
                        placeholder="Full advertising description"
                        value={this.state.Description}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="import-description-itemincluded"
                className={
                  this.state.activeTabID === "import-description-itemincluded"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="ItemsIncluded">Items included</label>
                      <textarea
                        rows={10}
                        className="form-control"
                        id="ItemsIncluded"
                        name="ItemsIncluded"
                        placeholder=""
                        value={this.state.ItemsIncluded}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="import-description-itemexcluded"
                className={
                  this.state.activeTabID === "import-description-itemexcluded"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="ItemsExcluded">Items excluded</label>
                      <textarea
                        rows={10}
                        className="form-control"
                        id="ItemsExcluded"
                        name="ItemsExcluded"
                        placeholder=""
                        value={this.state.ItemsExcluded}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="import-description-defects"
                className={
                  this.state.activeTabID === "import-description-defects"
                    ? "tab-pane fade in active"
                    : "tab-pane fade"
                }
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <label htmlFor="Defects">Defects</label>
                      <textarea
                        rows={10}
                        className="form-control"
                        id="Defects"
                        name="Defects"
                        placeholder=""
                        value={this.state.Defects}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
