import React from "react";
import { Modal } from "react-bootstrap";
import { OMSButtonStyleL2 } from "../../../../app/common/common-components/OMSButton";

interface ConfirmReportPurchaseModalPopupProps {
  showModal: boolean;
  title: string;
  creditBalance: number;
  reportCost: number;
  closeModalPopup: () => void;
  confirmModalPopup: () => void;
  openPurchaseCreditsModal: () => void; // Function to open the purchase credits modal
}

const ConfirmReportPurchaseModalPopup: React.FC<ConfirmReportPurchaseModalPopupProps> = ({
  showModal,
  title,
  creditBalance,
  reportCost,
  closeModalPopup,
  confirmModalPopup,
  openPurchaseCreditsModal,
}) => {
  const remainingCredits = creditBalance - reportCost;

  return (
    <Modal show={showModal} onHide={closeModalPopup}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Current Credit Balance: {creditBalance} Credits</p>
        <p>This report will cost: {reportCost} Credits</p>
        <p>Credits remaining after deduction: {remainingCredits >= 0 ? remainingCredits : 0}</p>
        {remainingCredits < 0 && (
          <p style={{ color: "red" }}>
            Not enough credits?{" "}
            <a href="#" onClick={openPurchaseCreditsModal}>
              Purchase Credits
            </a>
          </p>
        )}
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-default" onClick={closeModalPopup}>Cancel</button>
        <OMSButtonStyleL2
          type="button"
          style={{ marginRight: 10 }}
          ui_icon=""
          ui_type="save"
          ui_text="Confirm"
          ui_processing_text="Processing.."
          disabled={remainingCredits < 0}
          onClick={confirmModalPopup}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmReportPurchaseModalPopup;
