import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { UsersResponseModel } from "../../../app/models/UsersResponseModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import UserGrid from "./UserGrid";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function UsersPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<UsersResponseModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let officeID: string = "";
    if (params.has("OfficeID")) {
      //const oContact:ContactModel=new ContactModel();

      const id = params.get("OfficeID");
      officeID = id !== null ? id : "";

      //console.log("Inside useEffect:contact id is",contactId);
    }
    setIsLoading(true);
    apiAgent.Setting.getUsers(officeID)
      .then((response) => {
        //console.log("inside users page, response", response);
        let oUsers: UsersResponseModel = response;
        setUsers(oUsers);
        //console.log("Users page", oUsers);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Users";

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading && users) {
    return (
      <>
        <HeaderWrapper />

        <div className="users-page container-fluid">
          
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Manage Users" }, // No path for the active breadcrumb
            ]}
          />

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              <UserGrid
                actions={users.actions}
                officeDD={users.officeDD}
                OfficeID={users.officeID}
                super_admin={users.super_admin}
                reportAccess={users.reportAccess}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
