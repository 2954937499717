import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Header from "../header/Header";
import HeaderWrapper from "../header/HeaderWrapper";
// import Header from "../header/Header-Archive";
import MySettingsPage from "./MySettingsPage";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";

export default observer(function SettingsPage() {
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  document.title = "Base - Settings";

  return (
    <>
      <HeaderWrapper />

      <div  className="settings-page container-fluid">
        <Breadcrumb
            breadcrumbs={[
              { text: "Settings" },
            ]}
          />

        <div className="row">
          <div className="col-md-12">
            <MySettingsPage />
          </div>
        </div>
      </div>
    </>
  );
});
