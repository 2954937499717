import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { OMSButtonStyleL } from "../../../../app/common/common-components/OMSButton";

const ListingLeadsModal = ({
  mViewReportFrom,
  mViewReportTo,
  mProcessing,
  handleListingLeadsRunClick,
  handleLeadsPerPortalsChanged,
  setViewReportFrom,
  setViewReportTo,
  handleLeadsCloseModal
}) => {
  return (
    <div className="modal fade" id="listingLeadReportModal" tabIndex={-1} aria-labelledby="listingLeadReportModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => handleLeadsCloseModal()}
            >
              ×
            </button>
            <h4 className="modal-title" id="listingLeadReportModalLabel">Listing Leads Report</h4>
          </div>
          <div className="modal-body">
            <div className="row" style={{ marginTop: 10 }}>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <label>Date range</label>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 mt-2">
                <DatePicker
                  selected={mViewReportFrom}
                  onChange={(date) => setViewReportFrom(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="From Date"
                  name="mViewReportFromLeads"
                  id="mViewReportFromLeads"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control MandateDatePicker3"
                  isClearable={true}
                />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 mt-2">
                <DatePicker
                  selected={mViewReportTo}
                  onChange={(date) => setViewReportTo(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="To Date"
                  id="mViewReportToLeads"
                  name="mViewReportToLeads"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control MandateDatePicker3"
                  isClearable={true}
                />
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 marginBottom10">
                <label className="switch" style={{ marginTop: 10 }}>
                  <input
                    type="checkbox"
                    id="LeadsPerPortals"
                    name="LeadsPerPortals"
                    defaultChecked={true}
                    onChange={handleLeadsPerPortalsChanged}
                  />
                  <div className="slider round" />
                  <span id="spanLeadReportViewText" style={{ marginLeft: 70, display: "block", width: 120, marginTop: 7 }}>
                    Leads per portal
                  </span>
                </label>
              </div>
            </div>
            <div className="row" style={{ marginTop: 25, marginBottom: 15 }}>
              <div className="col-md-12">
                <div id="PageLeadChart"></div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="pull-left">
              <a href="https://help.entegral.net/en/articles/5631079-listing-lead-reports" target="_blank">Help with listing leads</a>
            </div>
            <a
              style={{ marginRight: 20, cursor: "pointer" }}
              onClick={() => handleLeadsCloseModal()}
              data-dismiss="modal"
            >
              Close
            </a>
            <OMSButtonStyleL
              type="button"
              ui_icon="fa-check"
              ui_type="save"
              ui_text="View"
              ui_processing_text="Viewing.."
              processing={mProcessing}
              disabled={mProcessing}
              onClick={handleListingLeadsRunClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ListingLeadsModal.propTypes = {
  mViewReportFrom: PropTypes.instanceOf(Date).isRequired,
  mViewReportTo: PropTypes.instanceOf(Date).isRequired,
  mProcessing: PropTypes.bool.isRequired,
  handleListingLeadsRunClick: PropTypes.func.isRequired,
  handleLeadsPerPortalsChanged: PropTypes.func.isRequired,
  setViewReportFrom: PropTypes.func.isRequired,
  setViewReportTo: PropTypes.func.isRequired,
};

export default ListingLeadsModal;
