import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import { OMSPaging } from "../OMSPaging";
import { OMSButtonStyleL } from "../OMSButton";
import { OMSTwitterAutocompleteAgent } from "../OMSAutocomplete";
import { OMSSaveContact } from "../OMSSaveContact";
import bootbox from "bootbox";
import apiAgent from "../../../api/apiAgent";
import ConfirmationModalPopup from "../../common-components/ModalPopups/ConfirmationModalPopup";
import { Link } from "react-router-dom";

export class OMSLead extends React.Component {
  static propTypes = {
    ID: PropTypes.string.isRequired,
    IsReadOnly: PropTypes.bool.isRequired,
    userID: PropTypes.string,
    View: PropTypes.number, // 0:unread, 1:Unread
  };

  static defaultProps = {
    ID: "inbox1",
    userID: "",
    View: 0,
  };

  constructor(props) {
    super(props);

    this.saveContactModalRef = React.createRef();
  }

  getDefaultData = () => {
    return {
      Models: [],
      Paging: {
        PageNo: 1,
        PageSize: 10,
        Total: 0,
        HasNext: false,
        HasPrevious: false,
        ShowPaging: false,
        ViewPages: [],
      },
    };
  };

  loadInboxFromServer = (view, page) => {
    this.setState({ loading: true, Data: this.getDefaultData() });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Inbox/IndexAjax?View=' + view + '&Page=' + page, true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            this.setState({ Data: data, view: view, page: page, loading: false });
        }.bind(this);
        xhr.send(); */
    apiAgent.Inbox.indexAjax(this.state.mUserID, page, view)
      .then((response) => {
        this.setState({
          Data: response,
          view: view,
          page: page,
          loading: false,
        });
      })
      .catch((error) => toast.error(error.response.data));
  };

  handleViewChange = (e) => {
    this.loadInboxFromServer(e.target.value, 1);
  };

  componentDidMount() {
    this.loadInboxFromServer(0, 1);
    //this.interval = setInterval(this.RefreshInbox, 60000);
  }

  RefreshInbox = () => {
    if (this.state.view == 0 && this.state.page == 1) {
      this.setState({ loading: true });
      /* var xhr = new XMLHttpRequest();
            xhr.open('get', '/Inbox/IndexAjax?View=' + this.state.view + '&Page=' + this.state.page, true);
            xhr.onload = function () {
                var data = JSON.parse(xhr.responseText);
                this.setState({ Data: data, loading: false });
            }.bind(this);
            xhr.send(); */
      apiAgent.Inbox.indexAjax(
        this.state.mUserID,
        this.state.page,
        this.state.view
      )
        .then((response) => {
          this.setState({ Data: response, loading: false });
        })
        .catch((error) => toast.error(error.response.data));
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onPageChange = (page) => {
    this.loadInboxFromServer(this.state.view, page);
  };

  onInboxClick = (userID, ItemID, IsRead, Title) => {
    this.setState({ mProcessing: true });
    window.$("#LeadModal").modal("show");
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Inbox/SelectLeadAjax?leadID=' + ItemID, true);
        xhr.onload = function () {
            this.setState({ mProcessing: false });
            var data = JSON.parse(xhr.responseText);
            this.setState({ mLeadID: data.leadID, mUserID: userID, mIsRead: IsRead, mTitle: Title, mContactID: data.contactID, mType: 2, mReceivedDate: data.ReceivedDate, mMessageDetails: JSON.parse(data.MessageDetails), mPropertyID: data.propertyID, mOfficeID: data.OfficeID, mSource: data.Source, mNote: data.Note, mAddress: data.Address });
        }.bind(this);
        xhr.send(); */
    //console.log("inside onInboxClick, userID=",userID);
    apiAgent.Inbox.selectLeadAjax(ItemID)
      .then((response) => {
        //console.log("inside selectLeadAjax, response=",response);
        this.setState({
          mLeadID: response.leadID,
          mUserID: userID,
          mIsRead: IsRead,
          mTitle: Title,
          mContactID: response.contactID,
          mType: 2,
          mReceivedDate: response.receivedDate,
          mMessageDetails: JSON.parse(response.messageDetails),
          mPropertyID: response.propertyID,
          mOfficeID: response.officeID,
          mSource: response.source,
          mNote: response.note,
          mAddress: response.address,
        });
      })
      .catch((error) => toast.error(error.response.data))
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  onMarkReadOrUnread = (IsRead) => {
    this.setState({ mProcessing: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Inbox/MarkInboxAjax?ItemID=' + this.state.mLeadID + '&userID=' + this.state.mUserID + '&IsRead=' + IsRead, true);
        xhr.onload = function () {
            this.setState({ mProcessing: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == 'success') {
                $('#LeadModal').modal('hide');
                this.loadInboxFromServer(this.state.view, 1);
                if (this.props.RefreshFun !== undefined && this.props.RefreshFun != null)
                    this.props.RefreshFun();
                //this.setState({ mLeadID: [], mReceivedDate: [], mMessageDetails: JSON.parse(data.MessageDetails), mPropertyID: data.propertyID, mOfficeID: data.OfficeID, mSource: data.Source });
            }
            else
                bootbox.alert(data.message);
        }.bind(this);
        xhr.send(); */
    apiAgent.Inbox.markInboxAjax(this.state.mLeadID, this.state.mUserID, IsRead)
      .then((response) => {
        window.$("#LeadModal").modal("hide");
        this.loadInboxFromServer(this.state.view, 1);
        if (
          this.props.RefreshFun !== undefined &&
          this.props.RefreshFun != null
        )
          this.props.RefreshFun();
        //this.setState({ mLeadID: [], mReceivedDate: [], mMessageDetails: JSON.parse(data.MessageDetails), mPropertyID: data.propertyID, mOfficeID: data.OfficeID, mSource: data.Source });
      })
      .catch((error) => bootbox.alert(error.response.message))
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  onCloseModal = (e) => {
    window.$("#LeadModal").modal("hide");
  };

  executeInboxDeleteClick = (e) => {
    this.setState({ mProcessing: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Inbox/DeleteInboxAjax?ItemID=' + this.state.mLeadID + '&userID=' + this.state.mUserID + '&Type=' + this.state.mType, true);
        xhr.onload = function () {
            this.setState({ mProcessing: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == 'success') {
                $('#LeadModal').modal('hide');
                this.loadInboxFromServer(this.state.view, 1);
                if (this.props.RefreshFun !== undefined && this.props.RefreshFun != null)
                    this.props.RefreshFun();
            }
            else
                bootbox.alert(data.message);
        }.bind(this);
        xhr.send(); */

    apiAgent.Inbox.deleteInboxAjax(
      this.state.mLeadID,
      this.state.mUserID,
      this.state.mType
    )
      .then((response) => {
        window.$("#LeadModal").modal("hide");
        this.loadInboxFromServer(this.state.view, 1);
        if (
          this.props.RefreshFun !== undefined &&
          this.props.RefreshFun != null
        )
          this.props.RefreshFun();
      })
      .catch((error) => bootbox.alert(error.response.message))
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  onInboxDeleteClick = (e) => {
    // bootbox.confirm({
    //   title: "Delete inbox item?",
    //   message: "Do you want to delete inbox item now? This cannot be undone.",
    //   buttons: {
    //     cancel: {
    //       label: '<i class="fa fa-times"></i> Cancel',
    //     },
    //     confirm: {
    //       label: '<i class="fa fa-check"></i> Confirm',
    //     },
    //   },
    //   callback: function (result) {
    //     if (result) {
    //       this.executeInboxDeleteClick();
    //     }
    //   }.bind(this),
    // });
    let title = "Delete inbox item?";
    let body = "Do you want to delete inbox item now? This cannot be undone.";
    let callback = this.executeInboxDeleteClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onSendNewMessageClick = (e) => {
    alert("send message");
  };

  onSuccessContact = (e) => {
    this.setState({ mContactID: e.contactID, mIsRead: true });
    this.loadInboxFromServer(this.state.view, 1);
    if (this.props.RefreshFun !== undefined && this.props.RefreshFun != null)
      this.props.RefreshFun();
  };

  onOpenContact = (e) => {
    var ContactEmail = this.state.mMessageDetails.contactemail;
    var ContactNo = this.state.mMessageDetails.contactnumber;
    var contactName = this.state.mMessageDetails.contactname;
    this.saveContactModalRef.current.onOpenContact(
      contactName,
      ContactEmail,
      ContactNo
    );
  };

  onReassignClick = (e) => {
    window.$("#searchAgent").val("");
    window.$("#AgentID").val("");
    window.$("#Note").val("");
    window.$("#ReassignModal").modal("show");
  };

  handleAgentChange = (e) => {
    //console.log("handleAgentChange", e);
    window.$("#AgentID").val(e.id);
  };

  onCancelReassignClick = (e) => {
    window.$("#ReassignModal").modal("hide");
  };

  onSaveReassignClick = (e) => {
    var UserIDToAssign = window.$("#AgentID").val();
    var Note = window.$("#Note").val();

    //console.log("onSaveReassignClick, UserIDToAssign", UserIDToAssign);
    //console.log("onSaveReassignClick, Note", Note);

    if (UserIDToAssign == "") return;

    this.setState({ mProcessing: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Inbox/ReassignAjax?ItemID=' + this.state.mLeadID + '&userID=' + this.state.mUserID + '&UserIDToAssign=' + UserIDToAssign + '&Note=' + Note, true);
        xhr.onload = function () {
            this.setState({ mProcessing: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == 'success') {
                $('#ReassignModal').modal('hide');
                //bootbox.alert('Lead reassigned successfully.');
                $('#LeadModal').modal('hide');
                this.loadInboxFromServer(this.state.view, 1);
                if (this.props.RefreshFun !== undefined && this.props.RefreshFun != null)
                    this.props.RefreshFun();
            }
            else
                bootbox.alert(data.message);
        }.bind(this);
        xhr.send(); */
    apiAgent.Inbox.reassignAjax(
      this.state.mLeadID,
      this.state.mUserID,
      UserIDToAssign,
      Note
    )
      .then((response) => {
        window.$("#ReassignModal").modal("hide");
        //bootbox.alert('Lead reassigned successfully.');
        window.$("#LeadModal").modal("hide");
        this.loadInboxFromServer(this.state.view, 1);
        if (
          this.props.RefreshFun !== undefined &&
          this.props.RefreshFun != null
        )
          this.props.RefreshFun();
      })
      .catch((error) => bootbox.alert(error.response.message))
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  onMessageClick = (userID, ParentItemID) => {
    if (this.props.onMessageClick) {
      this.props.onMessageClick(userID, ParentItemID);
    }
  };

  handlePrintClick = (e) => {
    var printHtml = window.open("Base(Entegral)", "PRINT", "height=800,width=600");

    printHtml.document.write(
      "<html><head><title>Base(Entegral) - Tenant Application</title></head>"
    );
    printHtml.document.write("<h1>Tenant Application</h1><hr/><br/>");
    printHtml.document.write(
      document.getElementById("cbTenantApplication").innerHTML
    );
    printHtml.document.write("</body></html>");

    printHtml.document.close();
    printHtml.focus();
    printHtml.print();
    //printHtml.close();
  };

  state = {
    Data: this.getDefaultData(),
    mLeadID: "",
    mReceivedDate: "",
    mMessageDetails: {},
    mPropertyID: "",
    mOfficeID: "",
    mSource: "",
    mUserID: "",
    mType: 1,
    mNote: "",
    mTitle: "",
    mContactID: "",
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  renderMessageDetail = (mMessageDetails, mPropertyID) => {
    var propertyref = mMessageDetails.propertyref;
    if (mMessageDetails.externalid) {
      propertyref = mMessageDetails.externalid;
      if (
        mMessageDetails.referer &&
        mMessageDetails.referer.indexOf("property24") != -1
      )
        propertyref = "P24-" + propertyref;
    }

    return Object.keys(mMessageDetails).map((item, index) => {
      if (Array.isArray(mMessageDetails[item])) return null;

      if (item == "externalid") return null;

      let mSpan;
      if (item == "referer" || item == "link") {
        if (
          propertyref &&
          mMessageDetails[item].indexOf("myproperty.com.na") != -1
        ) {
          mSpan = (
            <span>
              <b className="json-key">{item}:</b>&nbsp;{" "}
              <a
                href={"https://www.myproperty.com.na?webref=" + propertyref}
                target="_blank"
              >
                {"https://www.myproperty.com.na?webref=" + propertyref}
              </a>
            </span>
          );
        } else {
          mSpan = (
            <span>
              <b className="json-key">{item}:</b>&nbsp;{" "}
              <a href={mMessageDetails[item]} target="_blank">
                {mMessageDetails[item]}
              </a>
            </span>
          );
        }
      } else if (item == "propertyref") {
        if (mPropertyID) {
          mSpan = (
            <span>
              <b className="json-key">{item}:</b>&nbsp;{" "}
              {/* <a href={"/Property/SaveProperty?ID=" + mPropertyID} target="_blank">{propertyref}</a> */}
              <Link
                to={`/Property/SaveProperty?ID=${mPropertyID}`}
                target="_blank"
              >
                {propertyref}
              </Link>
            </span>
          );
        } else {
          mSpan = (
            <span>
              <b className="json-key">{item}:</b>&nbsp; {propertyref}
            </span>
          );
        }
      } else if (
        item == "message" &&
        mMessageDetails["formtype"].indexOf("Tenant Application") != -1
      ) {
        mSpan = (
          <span data-toggle="modal" data-target="#TenantApplicationModal">
            <b className="json-key">{item}:</b>&nbsp;{" "}
            <a href="#">View tenant application</a>
          </span>
        );
      } else {
        mSpan = (
          <span>
            <b className="json-key">{item}:</b>&nbsp; {mMessageDetails[item]}
          </span>
        );
      }

      return (
        <div key={"LI-" + index}>
          {mSpan}
          <br />
        </div>
      );
    });
  };

  render() {
    return (
      <div>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        {this.props.show ? (
          <div className="panel panel-table panel-default oms-box">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-inline form-group">
                    <label>
                      <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                    </label>
                    <select
                      id="view"
                      name="view"
                      value={this.state.view}
                      onChange={this.handleViewChange}
                      className="form-control"
                    >
                      <option value="0">My Inbox (Unread)</option>
                      <option value="1">My Inbox (Read)</option>
                      <option value="2">My Inbox (All)</option>
                    </select>
                    &nbsp;
                    {this.state.loading ? (
                      <label>
                        <i
                          className="fa fa-spin fa-spinner"
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
            <div className="panel-body">
              <div className="col-md-12">
                <table className="table table-hover table-responsive table-extended">
                  <tbody>
                    {this.state.Data.Models.map((item, index) => {
                      if (item.Type == 4) {
                        return (
                          <tr
                            key={index}
                            onClick={() =>
                              this.onMessageClick(
                                item.userID,
                                item.ParentItemID
                              )
                            }
                          >
                            <td className="visible-xs">
                              <span
                                className="main-color"
                                style={{ fontWeight: "bold" }}
                              >
                                <i
                                  className="fa fa-commenting"
                                  aria-hidden="true"
                                  style={{ marginRight: 8 }}
                                ></i>
                                SMS from {item.Source}
                              </span>
                              <br />
                              <p
                                className="main-color"
                                style={{ marginLeft: 32, marginTop: 8 }}
                              >
                                {item.Title}
                              </p>
                              <span className="main-color pull-right">
                                {item.Duration}
                              </span>
                            </td>
                            <td className="hidden-xs">
                              <span
                                className="main-color"
                                style={{ fontWeight: "bold" }}
                              >
                                <i
                                  className="fa fa-commenting"
                                  aria-hidden="true"
                                  style={{ marginRight: 8 }}
                                ></i>
                                SMS from {item.Source}
                              </span>
                            </td>
                            <td className="hidden-xs">{item.Title}</td>
                            <td className="hidden-xs">{item.Duration}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr
                            key={index}
                            onClick={() =>
                              this.onInboxClick(
                                item.UserID,
                                item.ItemID,
                                item.IsRead,
                                item.Title
                              )
                            }
                          >
                            <td className="visible-xs">
                              <span
                                className="main-color"
                                style={{ fontWeight: "bold" }}
                              >
                                <i
                                  className="fa fa-id-card-o"
                                  aria-hidden="true"
                                  style={{ marginRight: 8 }}
                                ></i>
                                {item.Source}
                              </span>
                              <br />
                              <p
                                className="main-color"
                                style={{ marginLeft: 32, marginTop: 8 }}
                              >
                                {item.Title}
                              </p>
                              <span className="main-color pull-right">
                                {item.Duration}
                              </span>
                            </td>
                            <td className="hidden-xs">
                              <span
                                className="main-color"
                                style={{ fontWeight: "bold" }}
                              >
                                <i
                                  className="fa fa-id-card-o"
                                  aria-hidden="true"
                                  style={{ marginRight: 8 }}
                                ></i>
                                {item.Source}
                              </span>
                            </td>
                            <td className="hidden-xs">{item.Title}</td>
                            <td className="hidden-xs">{item.Duration}</td>
                          </tr>
                        );
                      }
                    })}
                    {this.state.loading == false &&
                    this.state.Data.Models.length == 0 ? (
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan="3">
                          <span
                            className="main-color"
                            style={{ fontWeight: "bold" }}
                          >
                            No lead/messages!
                          </span>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="panel-footer">
              <OMSPaging
                data={this.state.Data.Paging}
                onPageChange={this.onPageChange}
              />
            </div>
          </div>
        ) : null}
        <div
          id="LeadModal"
          className="modal fade"
          data-backdrop="static"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4 className="modal-title">
                  {this.state.mMessageDetails.formtype}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <span>
                        <i
                          className="fa fa-id-card-o"
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            marginRight: 8,
                            width: 15,
                          }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;<b>From:</b> {this.state.mSource}
                      </span>
                    </div>
                    <div className="form-group">
                      <span>
                        <i
                          className="fa fa-clock-o"
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            marginRight: 8,
                            width: 15,
                          }}
                          aria-hidden="true"
                        ></i>
                        &nbsp;<b>Received:</b> {this.state.mReceivedDate}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      style={{ height: 15, borderTop: "1px solid #e5e5e5" }}
                    ></div>
                  </div>
                  <div className="col-md-12">
                    {this.props.IsReadOnly ? (
                      <div className="form-group">
                        {this.state.mContactID ? (
                          <span>
                            <b>Contact Name:</b>&nbsp;
                            {/* <a style={{ cursor: "pointer" }} href={"/Contacts/SaveContact?ID=" + this.state.mContactID} target="_blank">
                              {this.state.mMessageDetails.contactname}
                        </a> */}
                            <Link
                              to={`/Contacts/SaveContact?ID=${this.state.mContactID}`}
                              target="_blank"
                              style={{ cursor: "pointer" }}
                            >
                              {this.state.mMessageDetails.contactname}
                            </Link>
                          </span>
                        ) : (
                          <span>
                            <b>Contact Name:</b>{" "}
                            {this.state.mMessageDetails.contactname}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="form-group">
                        {this.state.mContactID ? (
                          <span>
                            <b>Contact Name:</b>&nbsp;
                            {/* <a style={{ cursor: "pointer" }} href={"/Contacts/SaveContact?ID=" + this.state.mContactID} target="_blank">
                              {this.state.mMessageDetails.contactname}
                        </a> */}
                            <Link
                              to={`/Contacts/SaveContact?ID=${this.state.mContactID}`}
                              target="_blank"
                              style={{ cursor: "pointer" }}
                            >
                              {this.state.mMessageDetails.contactname}
                            </Link>
                            &nbsp;|&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={this.onReassignClick}
                            >
                              Reassign
                            </a>
                          </span>
                        ) : (
                          <span>
                            <b>Contact Name:</b>{" "}
                            {this.state.mMessageDetails.contactname} &nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={this.onOpenContact}
                            >
                              Convert to contact
                            </a>
                            &nbsp;|&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={this.onReassignClick}
                            >
                              Reassign
                            </a>
                          </span>
                        )}
                      </div>
                    )}
                    {this.state.mPropertyID ? (
                      <div className="form-group">
                        <span>
                          <b>Property Ref:</b>{" "}
                          {/* <a href={"/Property/SaveProperty?ID=" + this.state.mPropertyID} target="_blank">
                            #{this.state.mMessageDetails.propertyref}
                    </a> */}
                          <Link
                            to={`/Property/SaveProperty?ID=${this.state.mPropertyID}`}
                            target="_blank"
                          >
                            #{this.state.mMessageDetails.propertyref}
                          </Link>
                          &nbsp;
                          {this.state.mAddress
                            ? "(" + this.state.mAddress + ")"
                            : ""}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <div
                      style={{ height: 8, borderTop: "1px dotted #e5e5e5" }}
                    ></div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      {this.renderMessageDetail(
                        this.state.mMessageDetails,
                        this.state.mPropertyID
                      )}
                    </div>
                  </div>
                  {this.state.mNote ? (
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="SNote">Note: </label> <br />
                        <span>{this.state.mNote}</span>
                      </div>{" "}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <div className="pull-left">
                  {this.props.IsReadOnly ? null : (
                    <OMSButtonStyleL
                      style={{ marginRight: 10 }}
                      type="button"
                      ui_icon="fa-trash-o"
                      ui_type="cancel"
                      ui_text="Delete"
                      ui_processing_text="Deleting.."
                      processing={this.state.mProcessing}
                      disabled={this.state.mProcessing}
                      onClick={this.onInboxDeleteClick}
                    />
                  )}
                </div>
                {this.state.mIsRead ? (
                  <div>
                    {this.props.IsReadOnly ? null : (
                      <OMSButtonStyleL
                        type="button"
                        ui_icon=""
                        onClick={() => this.onMarkReadOrUnread(false)}
                        ui_type="cancel"
                        ui_text="Mark Unread"
                        ui_processing_text="Marking.."
                        processing={this.state.mProcessing}
                        disabled={this.state.mProcessing}
                      />
                    )}
                    <OMSButtonStyleL
                      type="button"
                      ui_icon=""
                      ui_type="save"
                      ui_text="Close"
                      ui_processing_text="Close"
                      processing={false}
                      disabled={this.state.mProcessing}
                      onClick={this.onCloseModal}
                    />
                  </div>
                ) : (
                  <div>
                    <OMSButtonStyleL
                      type="button"
                      ui_icon=""
                      ui_type="cancel"
                      ui_text="Leave unread"
                      ui_processing_text="Leave unread"
                      processing={false}
                      disabled={this.state.mProcessing}
                      onClick={this.onCloseModal}
                    />
                    {this.props.IsReadOnly ? null : (
                      <OMSButtonStyleL
                        type="button"
                        ui_icon=""
                        onClick={() => this.onMarkReadOrUnread(true)}
                        ui_type="save"
                        ui_text="Mark Read"
                        ui_processing_text="Marking.."
                        processing={this.state.mProcessing}
                        disabled={this.state.mProcessing}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          id="ReassignModal"
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
                <h4 className="modal-title">Reassign lead to another agent</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="AgentID">Agent</label>
                      <OMSTwitterAutocompleteAgent
                        id="searchAgent"
                        name="searchAgent"
                        style={{ width: 250 }}
                        onChange={this.handleAgentChange}
                        OfficeID={this.state.mOfficeID}
                      />
                      <input
                        type="hidden"
                        id="AgentID"
                        name="AgentID"
                        value=""
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="Note">Add a note</label>
                      <textarea
                        rows={3}
                        className="form-control"
                        id="Note"
                        name="Note"
                        value={this.state.Note}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div>
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text="Cancel"
                    processing={false}
                    disabled={this.state.mProcessing}
                    onClick={this.onCancelReassignClick}
                  />
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    onClick={this.onSaveReassignClick}
                    ui_type="save"
                    ui_text="Reassign"
                    ui_processing_text="Reassigning.."
                    processing={this.state.mProcessing}
                    disabled={this.state.mProcessing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <OMSSaveContact
          ref={this.saveContactModalRef}
          ID="Lead"
          leadID={this.state.mLeadID}
          leadUserID={this.state.mUserID}
          leadTitle={this.state.mTitle}
          LeadMarkRead={!this.state.mIsRead}
          userID=""
          OfficeDefaultTags={this.props.OfficeContactTags}
          UserDefinedTags={this.props.UserContactTags}
          ContactSourceTypes={this.props.ContactSourceTypes}
          onSuccess={this.onSuccessContact}
          DialingCode={this.props.DialingCode}
          DialingCodeDD={this.props.DialingCodeDD}
        />
        {this.state.mMessageDetails !== undefined &&
          this.state.mMessageDetails !== null &&
          this.state.mMessageDetails["formtype"] !== undefined &&
          this.state.mMessageDetails["formtype"].indexOf(
            "Tenant Application"
          ) != -1 && (
            <div
              id="TenantApplicationModal"
              className="modal fade"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                    <h4 className="modal-title">Tenant Application</h4>
                  </div>
                  <div className="modal-body tenant-application">
                    <div className="row">
                      <div
                        className="col-md-12"
                        id="cbTenantApplication"
                        style={{ maxHeight: 450, overflow: "auto" }}
                        dangerouslySetInnerHTML={{
                          __html: this.state.mMessageDetails[
                            "message"
                          ].replaceAll(
                            /<p>([^<]+:<br>)(https:\/\/[^<]+(?:<br>https:\/\/[^<]+)*)<\/p>/g,
                            (match, label, urls) => {
                              let labelUrl = label.slice(0, label.length - 5);

                              const urlArray = urls.split("<br>");
                              const links = urlArray
                                .map((url, index) => {
                                  const ind =
                                    urlArray.length > 1 ? index + 1 : "";

                                  return `<a href='${url}' target='_blank'><i class='fa fa-download'></i><span class='only-print'> &nbsp; ${labelUrl} ${ind}</span></a>`;
                                })
                                .join("<br>");
                              return `<p>${links}</p>`;
                            }
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="pull-left">
                      <span
                        onClick={this.handlePrintClick}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-print" aria-hidden="true"></i>
                        &nbsp;Print
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
