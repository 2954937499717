import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

export class OMSPaging extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };

  onPageClick = (e) => {
    var pageNo = e.target.getAttribute("data-item");
    if (pageNo !== undefined && pageNo != null) {
      this.props.onPageChange(pageNo);
    }
    return false;
  };

  render() {
    var self = this;
    var pagingHtml = this.props.data.ViewPages.map(function (i) {
      return (
        <li key={i}>
          <a data-item={i} className={i == self.props.data.PageNo ? "btn disabled" : ""} onClick={self.onPageClick} href="#">
            {i}
          </a>
        </li>
      );
    });
    return (
      <div className="row">
        {this.props.data.Total > 0 ? (
          <div className="col col-xs-6">
            Page {this.props.data.PageNo} of {this.props.data.NoOfPages}
          </div>
        ) : null}
        {this.props.data.ShowPaging ? (
          <div className="col col-xs-6">
            <ul className="pagination pull-right">
              {this.props.data.HasPrevious ? (
                <li>
                  <a data-item={this.props.data.PageNo - 1} onClick={this.onPageClick} href="#">
                    «
                  </a>
                </li>
              ) : null}
              {pagingHtml}
              {this.props.data.HasNext ? (
                <li>
                  <a data-item={this.props.data.PageNo + 1} onClick={this.onPageClick} href="#">
                    »
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}
