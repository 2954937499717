import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export class MarketingStepView extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    isDeleted: PropTypes.bool,
  };

  getStateFromData = (data) => {
    //console.log("insisde getStateFromData, marketing step ,data=", data);
    return {
      Step: "Listing capture: Marketing",
      PropertyID: this.ToString(data.propertyID),
      UserID: this.ToString(data.userID),
      RemainingPortalCount: this.ToString(data.remainingPortalCount),
      PropertyPortals: data.propertyPortals,
      PropertyType: "",
      SaleType: "",
      Status: 1,
      OpenPortalIndex: null,
      PortalDescriptions: {
        7: "5min updates", // Zoopla
        8: "24hr updates", // IOL Property
        10: "24hr updates", // ImmoAfrica
        3: "5min updates", // MyProperty Namibia
        2: "5min updates", // MyProperty South Africa
        21: "24hr updates", // Property Central
        18: "24hr updates", // SA Bundle: Ananzi
        12: "24hr updates", // Qwengo
        9: "12hr updates", // Gumtree
        4: "5min updates", // Property24
        16: "24hr updates", // Flow
        17: "24hr updates", // Namibia Bundle: House Finder
        19: "24hr updates", // Flex Dynamic Ads
        1: "10min updates", // Flex website
        5: "5min updates", // Private Property
        22: "24hr updates", // JamesEdition
        15: "24hr updates", // Property Matcher
        11: "24hr updates", // Locanto, GotProperty
      },
    };
  };

  componentDidMount() {
    this.performPortalSettingCheck(false);
  }

  componentDidUpdate(prevProps, prevState) {
    var isMadeActive = false;
    if (
      prevProps.Status != 1 &&
      prevProps.Status != 5 &&
      this.props.Status == 1
    )
      isMadeActive = true;
    this.performPortalSettingCheck(isMadeActive);
  }

  performPortalSettingCheck = (isMadeActive) => {
    if (this.props.isDeleted) return;

    if (
      this.state.PropertyType != this.props.PropertyType ||
      this.state.SaleType != this.props.SaleType ||
      this.state.Status != this.props.Status
    ) {
      this.setState(
        {
          PropertyType: this.props.PropertyType,
          SaleType: this.props.SaleType,
          Status: this.props.Status,
        },
        function () {
          var PropertyPortals = this.state.PropertyPortals;
          var changed = false;
          for (var i = 0; i < PropertyPortals.length; i++) {
            if (
              PropertyPortals[i].allowedSaleTypes ||
              PropertyPortals[i].allowedPropertyTypes
            ) {
              if (PropertyPortals[i].allowedSaleTypes) {
                var saleTypes = PropertyPortals[i].allowedSaleTypes.split(",");
                if (saleTypes.indexOf(this.props.SaleType) == -1) {
                  PropertyPortals[i].notAllowed = true;
                  changed = true;
                }
              }
              if (PropertyPortals[i].allowedPropertyTypes) {
                var propertyTypes = PropertyPortals[
                  i
                ].allowedPropertyTypes.split(",");
                if (propertyTypes.indexOf(this.props.PropertyType) == -1) {
                  PropertyPortals[i].notAllowed = true;
                  changed = true;
                }
              }

              if (changed == false && PropertyPortals[i].notAllowed == true) {
                PropertyPortals[i].notAllowed = false;
                changed = true;
              }
            }

            if (isMadeActive && PropertyPortals[i].defaultSelected) {
              PropertyPortals[i].enabled = true;
              PropertyPortals[i].selected = 1;
              changed = true;
            }
          }
          if (changed) this.setState({ PropertyPortals: PropertyPortals });
        }.bind(this)
      );
    }
  };

  doSetFormData = (data) => {
    // data.append('Marketing.PropertyID', this.state.PropertyID);
    // data.append('Marketing.UserID', this.state.UserID);

    // for (var i = 0; i < this.state.PropertyPortals.length; i++) {
    //     data.append('Marketing.PropertyPortals[' + i + '].PortalID', this.state.PropertyPortals[i].PortalID);
    //     data.append('Marketing.PropertyPortals[' + i + '].PortalName', this.state.PropertyPortals[i].PortalName);
    //     data.append('Marketing.PropertyPortals[' + i + '].PortalUrl', this.state.PropertyPortals[i].PortalUrl);
    //     data.append('Marketing.PropertyPortals[' + i + '].PortalLogoUrl', this.state.PropertyPortals[i].PortalLogoUrl);
    //     if (this.state.PropertyPortals[i].NotAllowed == true)
    //         data.append('Marketing.PropertyPortals[' + i + '].Selected', "0");
    //     else
    //         data.append('Marketing.PropertyPortals[' + i + '].Selected', this.state.PropertyPortals[i].Selected);
    // }

    const marketingData = {
      PropertyID: this.state.PropertyID,
      UserID: this.state.UserID,
      PropertyPortals: this.state.PropertyPortals.map((propertyPortal, i) => {
        return {
          PortalID: propertyPortal.portalID,
          PortalName: propertyPortal.portalName,
          PortalUrl: propertyPortal.portalUrl,
          PortalLogoUrl: propertyPortal.portalLogoUrl,
          Selected: this.state.PropertyPortals[i].notAllowed
            ? "0"
            : this.state.PropertyPortals[i].selected,
        };
      }),
    };
    data.append("MarketingJson", JSON.stringify(marketingData));

    // const mandateData = {

    //     PropertyID: this.state.PropertyID,
    //     MandateID: this.state.MandateID,
    //     Price: this.state.PriceOnApplication?"0": this.state.Price.replace(",", ""),
    //     SaleType: this.state.SaleType,
    //     SaleTypeUnits: this.state.SaleTypeUnits,

    //     ItemisedCosts: this.state.ItemisedCosts.map((itemisedItem, i) => {
    //       return {
    //         ItemName: itemisedItem.ItemName,
    //         SizeOrUnits: itemisedItem.SizeOrUnits,
    //         UnitType: itemisedItem.UnitType,
    //         Cost: itemisedItem.Cost.replace(",", ""),
    //       };
    //     }),

    // };

    //data.append('MandateJson', JSON.stringify(mandateData));
  };

  doCheckFormData = (data) => {
    var result = { status: "error", message: "Fields are required." };
    var resultSuccess = { status: "success", message: "" };
    return resultSuccess;
  };

  doSetState = (data) => {
    this.setState(
      this.getStateFromData(data),
      function () {
        this.performPortalSettingCheck();
      }.bind(this)
    );
  };

  doGetState = () => {
    return this.state;
  };

  ToString = (value) => {
    if (value === undefined || value == null) return "";
    else return value;
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePortalSelectChange = ({ portalID, selected }) => {
    if (this.props.isDeleted) return;
    // On grey area click, toggle portal selected
    let PropertyPortals = this.state.PropertyPortals;
    for (let i = 0; i < PropertyPortals.length; i++) {
      if (PropertyPortals[i].portalID == portalID) {
        if (
          PropertyPortals[i].notAllowed ||
          !PropertyPortals[i].enabled ||
          PropertyPortals[i].restrictedPortal
        )
          return;
        PropertyPortals[i].selected = selected === "1" ? "0" : "1";
      }
    }
    this.setState({ PropertyPortals: PropertyPortals });
  };

  renderSyncStatusTitle = (item) => {
    // NotEnabledOrSelected = 0, InProcess = 6, Active = 2, Removed = 3, Error = 4

    if (item.selected == "0" && item.syncStatus == 3) {
      let title =
        "Not enabled/selected, removed from portal on " + item.syncUpdatedOn;
      return title;
    } else if (item.syncStatus == 2) {
      let title = "Active on portal since " + item.syncUpdatedOn;
      return title;
    } else if (item.syncStatus == 3) {
      let title = "Removed from portal on " + item.syncUpdatedOn;
      return title;
    } else if (item.syncStatus == 4) {
      let title = "Error reported on " + item.syncUpdatedOn;
      return title;
    } else if (item.syncStatus == 5 || item.syncStatus == 6) {
      let title = "In process since " + item.syncUpdatedOn;
      return title;
    } else {
      return "";
    }
  };

  renderSyncIcon = (item) => {
    // NotEnabledOrSelected = 0, InProcess = 6, Active = 2, Removed = 3, Error = 4

    if (item.selected == "0" && item.syncStatus == 3) {
      var title = this.renderSyncStatusTitle(item);
      return (
        <i
          className="fa fa-minus-circle"
          data-toggle="tooltip"
          data-original-title={title}
          data-title={title}
          title={title}
          style={{ color: "grey" }}
        ></i>
      );
    } else if (item.syncStatus == 2) {
      var title = this.renderSyncStatusTitle(item);
      return (
        <i
          className="fa fa-check-circle"
          data-toggle="tooltip"
          data-original-title={title}
          data-title={title}
          title={title}
          style={{ color: "green" }}
        ></i>
      );
    } else if (item.syncStatus == 3) {
      var title = this.renderSyncStatusTitle(item);
      return (
        <i
          className="fa fa-times-circle"
          data-toggle="tooltip"
          data-original-title={title}
          data-title={title}
          title={title}
          style={{ color: "red" }}
        ></i>
      );
    } else if (item.syncStatus == 4) {
      var title = this.renderSyncStatusTitle(item);
      if (item.syncMessage == "")
        title = title + "\n" + "No description specified";
      else title = title + "\n" + item.syncMessage + "";
      return (
        <i
          className="fa fa-exclamation-triangle"
          data-html="false"
          data-toggle="tooltip"
          data-original-title={title}
          data-title={title}
          title={title}
          style={{ color: "red" }}
        ></i>
      );
    } else if (item.syncStatus == 5 || item.syncStatus == 6) {
      var title = this.renderSyncStatusTitle(item);
      return (
        <i
          className="fa fa-clock-o"
          data-toggle="tooltip"
          data-title={title}
          data-original-title={title}
          title={title}
          style={{ color: "orange" }}
        ></i>
      );
    } else {
      return (
        <i
          className="fa fa-minus-circle"
          data-toggle="tooltip"
          data-title="Not enabled/selected"
          title="Not enabled/selected"
          data-original-title={title}
          style={{ color: "grey" }}
        ></i>
      );
    }
  };

  state = this.getStateFromData(this.props.data);

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <span>
              Select your portal feeds
              {/* {" "}
              {this.state.RemainingPortalCount !== "0" && "There are " + this.state.RemainingPortalCount + " portal(s) not activated for your office."}{" "} */}
            </span>
            <br />
            <br />
          </div>
          <div className="col-md-12">
            <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              {this.state.PropertyPortals.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      flexBasis: "30%",
                      flexGrow: 1,
                      flexShrink: 1,
                      maxWidth: "345px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "20px",
                        padding: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          OpenPortalIndex:
                            this.state.OpenPortalIndex !== index && index,
                        })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: 0,
                            borderRadius: "15px",
                            backgroundColor: "#fff",
                            padding: "5px 15px",
                            width: "fit-content",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handlePortalSelectChange(item);
                          }}
                        >
                          <div className="checkbox">
                            <label
                              title={
                                item.restrictedPortal
                                  ? "Access restricted by your office"
                                  : ""
                              }
                            >
                              <input
                                id={"PropertyPortals-" + item.portalID}
                                name={"PropertyPortals-" + item.portalID}
                                type="checkbox"
                                value={
                                  item.notAllowed || item.restrictedPortal
                                    ? "Off"
                                    : null
                                }
                                checked={item.selected == "1"}
                                readOnly={true}
                                disabled={
                                  item.notAllowed ||
                                  item.restrictedPortal ||
                                  !item.enabled
                                }
                              />
                            </label>
                          </div>
                          <img
                            src={item.portalLogoUrl}
                            style={{ maxWidth: 100 }}
                            alt={item.portalName}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginLeft: "10px",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {item.restrictedPortal ? (
                              <i
                                className="fa fa-ban"
                                data-toggle="tooltip"
                                data-original-title="Access restricted by your office"
                                data-title="Access restricted by your office"
                                style={{ color: "grey" }}
                              ></i>
                            ) : (
                              this.state.PropertyID && this.renderSyncIcon(item)
                            )}
                            <i
                              className={`fa ${
                                this.state.OpenPortalIndex === index
                                  ? "fa-chevron-down"
                                  : "fa-chevron-right"
                              }`}
                            ></i>
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: 15,
                          backgroundColor: "#fff",
                          padding: 15,
                          borderRadius: 15,
                          flexDirection: "column",
                          display:
                            this.state.OpenPortalIndex === index
                              ? "flex"
                              : "none",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {item.portalName}
                        </span>
                        {item.restrictedPortal && (
                          <span>Access restricted by your office</span>
                        )}
                        {!this.state.PropertyID && (
                          <span>
                            {this.state.PortalDescriptions[item.portalID]}
                          </span>
                        )}

                        <span>
                          {item.externalID &&
                            item.externalID !== "unavailable" &&
                            (item.propertyUrl ? (
                              <span>
                                <b>Ref:</b>&nbsp;
                                <a
                                  href={item.propertyUrl}
                                  title="Open listing on portal"
                                  target="_blank"
                                >
                                  {item.externalID
                                    ? item.externalID
                                    : "not available"}
                                </a>
                                &nbsp;
                                {/* {item.promoteUrl && (
                                  <span className="badge promote">
                                    <a href={item.promoteUrl} title="Promote listing on MyProperty" target="_blank">
                                      Promote
                                    </a>
                                  </span>
                                )} */}
                              </span>
                            ) : (
                              <span>
                                <b>Ref:</b>&nbsp;{item.externalID}
                              </span>
                            ))}
                        </span>
                        {this.renderSyncStatusTitle(item) && (
                          <span style={{ paddingTop: "10px" }}>
                            {this.renderSyncStatusTitle(item)}
                          </span>
                        )}
                        {item.syncMessage && (
                          <span style={{ paddingTop: "10px" }}>
                            {item.syncMessage}
                          </span>
                        )}
                        {item.syncStatus == 4 && (
                          <span style={{ paddingTop: "10px" }}>
                            <a
                              href="https://help.entegral.net/en/articles/5203115-resolving-common-listing-syndication-errors"
                              target="_blank"
                            >
                              Help with portal errors
                            </a>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-12">
            <br />
            <br />
            <a href="https://help.entegral.net/en/articles/5198783-an-overview-of-listing-syndication-to-portals-and-your-own-website" target="_blank">
              Overview of syndication
            </a>
            <br />
            <a href="https://help.entegral.net/en/articles/5203115-resolving-common-listing-syndication-errors" target="_blank">
              Common errors
            </a>
          </div>
        </div>
      </div>
    );
  }
}
