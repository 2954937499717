import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import { ImportPropertyModel } from "../../../app/models/ImportPropertyModel";
import ImportPropertyBox from "./ImportPropertyBox";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function SaveProperty() {
  const [importProperty, setImportProperty] = useState<ImportPropertyModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [importID, setImportID] = useState<string>("");
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let importId: string = "";
    let propertyId: string = "";
    if (params.has("ImportID")) {
      const id = params.get("ImportID");
      importId = id !== null ? id : "";
      setImportID(importId);
    }
    if (params.has("PropertyID")) {
        const id = params.get("PropertyID");
        propertyId = id !== null ? id : "";
      }

    setIsLoading(true);
    apiAgent.Import.saveProperty(importId, propertyId)
      .then((response: ImportPropertyModel) => {
        let oImportProperty: ImportPropertyModel = response;
        setImportProperty(oImportProperty);

        //console.log("ImportProperty", oImportProperty);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save Import Property";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && importProperty) {
    return (
      <>
        <HeaderWrapper />

        <div className="save-Property-Import-Page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Settings", path: "/settings" },
              { text: "Import", path: "/import/index" },
              { text: "Properties", path: `/import/properties?ImportID=${importID}` },
              { text: "Save Property"},
            ]}
          />
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <ImportPropertyBox 
                data = {importProperty} 
                OwnershipTypes = {importProperty.ownershipTypes} 
                PropertyTypes = {importProperty.propertyTypes} 
                MandateTypes = {importProperty.mandateTypes}
                officeDD = {importProperty.officeDD} 
                />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
