import React from "react";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import apiAgent from "../../../app/api/apiAgent";

export class MediaFileComponent extends React.Component {
  state = {
    expanded: false,
    ftFileID: "",
    ftDescription: "",
    ftFile: null,
    ftFileicon: "",
    ftFilename: "",
    Files: this.props.data,
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  componentDidMount() {
    //console.log("inside MediaFileComponent componentDidMount, files=", this.state.Files);
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  handleChange = (e) => {
    //console.log("inside handleChange",e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleAddFilesClick = () => {
    window.$("#ftUploadPhoto").click();
  };
  onDownloadFile = (fileName) => {

    apiAgent.Property.DownloadMandateFile(fileName,this.props.officeSyncID)
      .then((response) => {
        //console.log("after apiAgent.Property.DownloadMandateFile, response.headers=", response.headers);
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = downloadUrl;
        // const disposition = response.headers['content-disposition'];
        // const parts = disposition.split(';');
        // const filenamePart = parts.find(part => part.trim().startsWith('filename='));
        // const filenameWithExtension = filenamePart.split('=')[1].trim();
        // const extensionIndex = filenameWithExtension.lastIndexOf(".");
        // a.download = filenameWithExtension.substring(0, extensionIndex);
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Failed to download file:", error);
      });
  };
  handleUploadFileChange = (e) => {
    //console.log("inside handleUploadFileChange", e.target.files[0]);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files === undefined || files.length == 0) return;

    var uploadedFile = files[0];
    var mSize = uploadedFile.size;
    if (!isNaN(mSize) && mSize > 200000000) {
      toast.info("File is too big to upload. Max filesize allowed: 200MB");
      return;
    }

    if (!uploadedFile.name.match(/.(exe|apk|bat|csh|ipa|msi|reg|vb|cs|js|jsx|jar)$/i)) {
      this.setState({
        ftFileID: "",
        ftDescription: "",
        ftFile: uploadedFile,
        ftFileicon: this.getFileIcon(uploadedFile.name),
        ftFilename: uploadedFile.name,
      });
      window.$("#ftUploadPhoto").val("");
      window.$("#FTModal").modal("show");
    } else {
      toast.info("Executable files are not allowed!");
    }
  };

  getFileIcon = (fileName) => {
    var fileicon = "";
    if (fileName.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/))
        fileicon = 'fa-file-photo-o';
    else if (fileName.match(/\.(pdf|PDF)$/))
        fileicon = 'fa-file-pdf-o';
    else if (fileName.match(/\.(txt|TXT)$/))
        fileicon = 'fa-file-text-o';
    else if (fileName.match(/\.(csv|xls|xlsx|CSV|XLS|XLSX)$/))
        fileicon = 'fa-file-excel-o';
    else if (fileName.match(/\.(ppt|pptx|PPT|PPTX)$/))
        fileicon = 'fa-file-powerpoint-o';
    else if (fileName.match(/\.(doc|docx|DOC|DOCX)$/))
        fileicon = 'fa-file-word-o';
    else
        fileicon = '';

    return fileicon;
};

  formatBytes = (bytes, decimals) => {
    if (bytes == 0) return "0 Bytes";
    var k = 1024,
      dm = decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleUpdateFileLinkClick = (FileID) => {
    //console.log("inside handleUpdateFileLinkClick, FileID=", FileID);
    var index = -1;
    var files = this.state.Files;
    var filesLength = files ? files.length : 0;
    for (var i = 0; i < filesLength; i++) {
      if (files[i].fileID == FileID) {
        index = i;
        break;
      }
    }

    this.setState({
      ftFileID: files[i].fileID,
      ftDescription: files[i].description,
      ftFile: null,
      ftFileicon: files[i].fileicon,
      ftFilename: files[i].fileName,
    });
    window.$("#FTModal").modal("show");
  };

  onSaveFileClick = () => {
    //console.log("inside onSaveFileClick, files=", this.state.Files);
    var files = this.state.Files.slice(0);

    var fileLength = files ? files.length : 0;
    if (this.state.ftFileID == "") {
      var Order = 0;
      if (fileLength > 0) {
        var maxOrder = Math.max.apply(
          Math,
          files.map(function (o) {
            return o.order;
          })
        );
        Order = maxOrder + 1;
      }

      var fileItem = {
        fileID: "NAP" + Order,
        description: this.state.ftDescription,
        size: this.formatBytes(this.state.ftFile.size),
        fileName: this.state.ftFilename,
        file: this.state.ftFile,
        fileicon: this.state.ftFileicon,
        order: Order,
      };
      //console.log("inside onSaveFileClick, fileItem=", fileItem);
      files.push(fileItem);
    } else {
      for (var i = 0; i < fileLength; i++) {
        if (files[i].fileID == this.state.ftFileID) {
          var iFile = files[i].File;
          files[i].file = null;
          var fileItem = JSON.parse(JSON.stringify(files[i]));
          fileItem.file = iFile;
          //fileItem.FileName = this.state.ftFilename;
          fileItem.description = this.state.ftDescription;
          files[i] = fileItem;
          break;
        }
      }
    }
    this.setState({
      Files: files,
      ftFileID: "",
      ftDescription: "",
      ftFile: null,
      ftFileicon: "",
      ftFilename: "",
      expanded: true,
    });

    window.$("#FTModal").modal("hide");

    if (this.props.onChange !== undefined) this.props.onChange(files);
  };

  executeDeleteFileClick = () => {
    var index = -1;
    var files = this.state.Files;
    var fileLength = files ? files.length : 0;

    for (var i = 0; i < fileLength; i++) {
      if (files[i].fileID == this.state.ftFileID) {
        index = i;
        //console.log("inside executeDeleteFileClick before calling apiAgent, files[i].fileName=", files[i].fileName, "this.props.officeSyncID=", this.props.officeSyncID);
        apiAgent.S3Helper.deleteFilesFromS3(files[i].fileName,this.props.propertyID, this.props.officeSyncID);

      }
    }

    if (index != -1) {
      files.splice(index, 1);
      this.setState({ Files: files });
      window.$("#FTModal").modal("hide");

      if (this.props.onChange !== undefined) this.props.onChange(files);
    }
  };

  onDeleteFileClick = () => {
    // bootbox.confirm({
    //     title: "Delete file?",
    //     message: "Do you want to delete file now?",
    //     buttons: {
    //         cancel: {
    //             label: '<i class="fa fa-times"></i> Cancel'
    //         },
    //         confirm: {
    //             label: '<i class="fa fa-check"></i> Confirm'
    //         }
    //     },
    //     callback: function (result) {
    //         if (result) {
    //             this.executeDeleteFileClick();
    //         }
    //     }.bind(this)
    // });
    let title = "Delete file?";
    let body = "Do you want to delete file now?";
    let callback = this.executeDeleteFileClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onChangeExpand = (e) => {
    var expanded = !this.state.expanded;
    this.setState({ expanded: expanded });
  };

  doSetState = (data) => {
    this.setState({ Files: data });
  };

  render() {
    var filesLength = this.state.Files ? this.state.Files.length : 0;
    var self = this;
    return (
      <div>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        {filesLength == 0 ? null : (
          <div className="panel panel-default">
            <div className="panel-heading" style={{ cursor: "pointer" }} onClick={this.onChangeExpand}>
              <div className="row">
                <div className="col-md-12">
                  <a style={{ cursor: "pointer", display: "inline-block", paddingTop: 5 }}>
                    <i className={this.state.expanded ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
                  </a>
                  <span style={{ marginLeft: 10, fontWeight: "bold" }}>Files ({filesLength})</span>
                </div>
              </div>
            </div>
            <div className="panel-body">
              <div className="col-md-12">
                {this.state.expanded ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "90%" }}>File</th>
                        {/*<th style={{ width: '45%' }}>Description</th>*/}
                        <th style={{ textAlign: "right", width: "10%", minWidth: "25px" }}>Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.Files.map((item) => {
                        return [
                          <tr>
                            <td>
                              <div className="library_grid_title">
                                {item.fileID.startsWith("NAP") == false ? (
                                  <a style={{ cursor: "pointer", marginRight: 15 }} onClick={()=>this.onDownloadFile(item.fileName)} title="open/download file">
                                    <i className="fa fa-download" aria-hidden="true"></i>
                                  </a>
                                ) : (
                                  <span style={{ marginRight: 15, color: "grey" }}>
                                    <i className="fa fa-download" aria-hidden="true"></i>
                                  </span>
                                )}
                                <a style={{ cursor: "pointer" }} title="update description" onClick={() => self.handleUpdateFileLinkClick(item.fileID)}>
                                  <i className={"fa " + item.fileicon} aria-hidden="true"></i>&nbsp;
                                  {item.description ? item.description : "No description added."}
                                </a>
                              </div>
                            </td>
                            {/*<td>
                                         <div className="library_grid_description">
                                             <span>{item.Description}</span>
                                         </div>
                                     </td>*/}
                            <td>
                              <span className="label label-default">{item.size}</span>
                            </td>
                          </tr>,
                        ];
                      })}
                    </tbody>
                  </table>
                ) : null}
              </div>
            </div>
          </div>
        )}
        <div>
          <input type="file" id="ftUploadPhoto" name="ftUploadPhoto" style={{ display: "none" }} onChange={this.handleUploadFileChange} multiple={false} />
        </div>
        <div id="FTModal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="FTModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="FTModalLabel">
                  Add file
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-md-12 text-center">
                    <span style={{ width: 100, height: 100 }}>
                      <i className={"fa " + this.state.ftFileicon} aria-hidden="true" style={{ fontSize: 60 }}></i>
                    </span>
                  </div>
                  <div className="form-group col-md-12" style={{ display: "none" }}>
                    <label htmlFor="ftFilename">FileName</label>
                    <input type="text" className="form-control" id="ftFilename" name="ftFilename" value={this.state.ftFilename} onChange={this.handleChange} />
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="ftDescription">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ftDescription"
                      name="ftDescription"
                      value={this.state.ftDescription}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {this.state.ftFileID != "" ? (
                  <div className="pull-left">
                    <a style={{ cursor: "pointer", display: "inline-block", paddingTop: 5 }} onClick={this.onDeleteFileClick}>
                      Delete
                    </a>
                  </div>
                ) : null}
                <a data-dismiss="modal" style={{ cursor: "pointer" }}>
                  Cancel
                </a>
                <button type="button" style={{ marginLeft: 10 }} className="btn btn-success-oms" onClick={this.onSaveFileClick}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
