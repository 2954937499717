import { useEffect, useState } from "react";
import "./OMSTooltip.scss";

const OMSTooltip = ({ infoText, children }) => {
  const [toolTipHover, setToolTipHover] = useState(false);
  const [toolTipTouch, setToolTipTouch] = useState(false);

  //const [toolTipClick, setToolTipClick] = useState(false);

  return (
    <>
      <div
        className="tooltip-container"
        onMouseEnter={() => setToolTipHover(true)}
        onMouseLeave={() => setToolTipHover(false)}
        onTouchStart={() => setToolTipTouch(true)}
        onTouchEnd={() => setToolTipTouch(false)}
        // onClick={() => setToolTipClick(!toolTipClick)}
      >
        {children}
        {toolTipHover && (
          <div className="tooltip-box">
            {infoText}
            <div className="arrow" />
          </div>
        )}
        {toolTipTouch && (
          <div className="tooltip-box">
            {infoText}
            <div className="arrow" />
          </div>
        )}
        {/* {toolTipClick && (
          <div className="tooltip-box">
            {infoText}
            <div className="arrow" />
          </div>
        )} */}
      </div>
    </>
  );
};

export default OMSTooltip;
