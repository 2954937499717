import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { AreaTransferResponseModel } from "../../../app/models/AreaTransferResponseModel";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import AreaTransferBox from "./AreaTransferBox";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function AreaTransferPage() {
  let varConfirmModalProps: ConfirmationModalProps = {
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  };

  const [isLoading, setIsLoading] = useState(true);
  const [areaTransfer, setAreaTransfer] = useState<AreaTransferResponseModel>();
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>(varConfirmModalProps);
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    setIsLoading(true);
    apiAgent.Admin.getAreaTransfer()
      .then((response: AreaTransferResponseModel) => {
        let oAreaTransfer: AreaTransferResponseModel = response;
        setAreaTransfer(oAreaTransfer);
        //console.log("Area Transfer", oAreaTransfer);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - area transfer";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && areaTransfer) {
    return (
      <>
        <HeaderWrapper />

        <div className="container-fluid">
        <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Area Transfer" },
            ]}
          />

          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <AreaTransferBox CountryDD={areaTransfer.countryDD} setConfirmationModalProps={setConfirmationModalProps} />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
