import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import apiAgent from "../../api/apiAgent";
import { OMSPinnedNoteItem } from "./OMSPinnedNoteItem";
import { OMSActivityDisplayItem } from "./OMSActivityDisplayItem";
import { convertToISODate } from "../common-functions/functions";

//import moment from 'moment'
const moment = require("moment");

export class OMSActivityTimeline extends React.Component {
  static propTypes = {
    onActionFunc: PropTypes.func,
    ReadOnly: PropTypes.bool,
    DNType: PropTypes.number,
    selectedEvents: PropTypes.array,
    ID: PropTypes.string,
  };

  state = {
    data: null,
    currentDate: new Date(),
    draggingId: "",
    draggedOverId: "",
  };

  componentDidMount() {
    //console.log("OMS Activity Timeline componentDidMount, props=", this.props);
    this.getData();
  }

  componentDidUpdate() {
    //this.updateData();
  }

  getData = () => {
    /* var xhr = new XMLHttpRequest();
    xhr.open('get', `/Diary/TimelineDiariesAjax?ID=${this.props.ID}&SDNType=${this.props.DNType}`, true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      this.setState({ data: data });
    }.bind(this);
    xhr.send(); */
    apiAgent.Diary.timelineDiariesAjax(this.props.ID, this.props.DNType)
      .then((response) => {
        //console.log("OMS Activity Timeline data", response);
        this.setState({ data: response });
      })
      .catch((error) => toast.error(error.response.data));
  };

  comparaData = (data, stateData) => {
    var stateDataMap = {};
    stateData.forEach((it) => (stateDataMap[it.diaryID] = it));
    for (var it of data) {
      var stateDataObj = stateDataMap[it.diaryID];
      for (var key of Object.keys(it)) {
        if (it[key] !== stateDataObj[key]) {
          return false;
        }
      }
    }
    return true;
  };

  updateData = () => {
    apiAgent.Diary.timelineDiariesAjax(this.props.ID, this.props.DNType)
      .then((response) => {
        const stateData = this.state.data;
        if (response && stateData) {
          if (response.length !== stateData.length) {
            this.setState({ ...this.state, data: response });
          } else {
            if (!this.comparaData(response, stateData)) {
              this.setState({ ...this.state, data: response });
            }
          }
        } else {
          if (!((null === response || undefined === response) && (null === response || undefined === response))) {
            this.setState({ ...this.state, data: response });
          }
        }
      })
      .catch((error) => toast.error(error.response.data));
  };

  isUpcomingEvent = (date) => {
    const startDate = new Date(moment(date, "DD-MM-YYYY hh:mm A"));
    return startDate >= this.state.currentDate;
  };

  getRecentEvent = (event1, event2) => {
    const startDate1 = new Date(moment(event1.startDate, "DD-MM-YYYY hh:mm A"));
    const startDate2 = new Date(moment(event2.startDate, "DD-MM-YYYY hh:mm A"));
    return startDate1 - startDate2;
  };

  togglePinNote = (noteItem) => {
    //console.log("togglePinNote", noteItem);

    var data = new FormData();

    // Create a deep copy of noteItem to modify
    var modifiedNoteItem = JSON.parse(JSON.stringify(noteItem));

    Object.keys(modifiedNoteItem).forEach(function (key) {
      if (key === "pinned") {
        data.append("strPinned", modifiedNoteItem[key]);
      } else if (key === "startDate") {
        let startDateISO = convertToISODate(modifiedNoteItem[key]);
        //console.log("startDateISO", startDateISO);
        data.append("startDate", startDateISO);
        //modifiedNoteItem[key] = startDateISO; // Update the modifiedNoteItem object
      } else if (key === "endDate") {
        let endDateISO = convertToISODate(modifiedNoteItem[key]);
        data.append("endDate", endDateISO);
        //modifiedNoteItem[key] = endDateISO; // Update the modifiedNoteItem object
      } else {
        data.append(key, modifiedNoteItem[key]);
      }
    });

    const formDataObj = Object.fromEntries(data.entries());

    //console.log("inside togglePinNote, formDataObj=", formDataObj);

    apiAgent.Diary.updateDiaryAjax(formDataObj)
      .then((response) => {
        var updateData = this.state.data.map((item) => (item.diaryID === modifiedNoteItem.diaryID ? modifiedNoteItem : item));
        //console.log("after updateDiaryAjax", updateData)
        this.setState({ ...this.state, data: updateData });
        if (this.props.togglePinNote !== undefined) this.props.togglePinNote(modifiedNoteItem);
      })
      .catch((error) => toast.error(error.response.data));
  };

  updatePinOrder = (noteItem) => {
    //console.log("updatePinOrder", noteItem);
    var data = new FormData();

    var modifiedNoteItem = JSON.parse(JSON.stringify(noteItem));
    Object.keys(modifiedNoteItem).forEach(function (key) {
      if (key === "pinned") {
        data.append("strPinned", modifiedNoteItem[key]);
      } else if (key === "startDate") {
        let startDateISO = convertToISODate(modifiedNoteItem[key]);
        //console.log("startDateISO", startDateISO);
        data.append("startDate", startDateISO);
        //modifiedNoteItem[key] = startDateISO; // Update the modifiedNoteItem object
      } else if (key === "endDate") {
        let endDateISO = convertToISODate(modifiedNoteItem[key]);
        data.append("endDate", endDateISO);
        //modifiedNoteItem[key] = endDateISO; // Update the modifiedNoteItem object
      } else {
        data.append(key, modifiedNoteItem[key]);
      }
    });
    /* var xhr = new XMLHttpRequest();
    xhr.open('post', '/Diary/UpdateDiaryAjax', true);
    xhr.send(data); */
    const formDataObj = Object.fromEntries(data.entries());
    //console.log("inside updatePinOrder, formDataObj=", formDataObj);
    apiAgent.Diary.updateDiaryAjax(formDataObj)
      .then((response) => {
        toast.success(response);
      })
      .catch((error) => toast.error(error.response.data));
  };

  setDragging = (diaryID) => {
    this.setState({ ...this.state, draggingId: diaryID });
  };

  setDraggedOver = (e, diaryID) => {
    e.preventDefault();

    this.setState({ ...this.state, draggedOverId: diaryID });
  };

  onDrop = () => {
    var allItems = this.state.data;
    var pinnedNotes = allItems.filter((e) => e.typeID.toLowerCase() === "note" && e.pinned).sort((it1, it2) => it1.pinOrder - it2.pinOrder);

    const draggedItem = allItems.find((it) => it.diaryID === this.state.draggingId);
    const indexOfDragging = pinnedNotes.indexOf(draggedItem);
    const indexOfDraggedOver = pinnedNotes.indexOf(allItems.find((it) => it.diaryID === this.state.draggedOverId));
    pinnedNotes.splice(indexOfDragging, 1);
    pinnedNotes.splice(indexOfDraggedOver, 0, draggedItem);

    var orderIndex = 1;
    var diaryIdOrderMap = {};
    pinnedNotes.forEach((note) => {
      note.pinOrder = orderIndex++;
      diaryIdOrderMap[note.diaryID] = note.pinOrder;
      this.updatePinOrder(note);
    });

    allItems.forEach((it) => {
      if (it.typeID === "note") {
        it.pinOrder = diaryIdOrderMap[it.diaryID];
      }
    });
    this.setState({
      ...this.state,
      data: allItems,
      draggingId: "",
      draggedOverId: "",
    });
  };

  onDiaryItemSubmit = () => {
    this.updateData();
  };

  handleChildActionFunc = (diaryID, isDeleted) => {
    var selected = null;
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].diaryID == diaryID) {
        selected = this.state.data[i];
      }
    }
    //console.log("inside handleChildActionFunc, selected=", selected);
    if (selected != null && this.props.onActionFunc !== undefined) this.props.onActionFunc(selected, isDeleted);
  };

  render() {
    const allItems = this.state.data;
    var pinnedNotes = [];
    var upcomingEvents = [];
    var pastEvents = [];

    if (allItems) {
      const currentDate = new Date();
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);

      upcomingEvents = allItems
        .filter((item) => this.isUpcomingEvent(item.startDate) && new Date(item.startDateInDate) <= oneYearFromNow)
        .sort((it1, it2) => this.getRecentEvent(it1, it2));

      pastEvents = allItems.filter((item) => !this.isUpcomingEvent(item.startDate)).sort((it1, it2) => -this.getRecentEvent(it1, it2));

      if (this.props.selectedEvents.length > 0) {
        var selectedEventTypes = this.props.selectedEvents.map((tag) => tag.name.toLowerCase());
        upcomingEvents = upcomingEvents.filter((event) => selectedEventTypes.indexOf(event.typeID.toLowerCase()) !== -1);
        pastEvents = pastEvents.filter((event) => selectedEventTypes.indexOf(event.typeID.toLowerCase()) !== -1);
      }

      //console.log("inside render of OMS Activity Timeline, upcomingEvents=", upcomingEvents);

      pinnedNotes = allItems.filter((e) => e.typeID.toLowerCase() === "note" && e.pinned).sort((it1, it2) => it1.pinOrder - it2.pinOrder);
    }

    return (
      <div className="events-root">
        <div className={`col-md-${pinnedNotes.length > 0 ? 8 : 12} col-sm-12 d-flex d-flex-column timeline-root`}>
          <div className="panel panel-default">
            <div className="activity-panel panel-heading" data-toggle="collapse" data-target="#upcoming-events">
              <span>Upcoming</span>
              <i className="fa fa-chevron-up" aria-hidden="true"></i>
            </div>
            <div className="panel-body collapse in" id="upcoming-events">
              {upcomingEvents && upcomingEvents.length > 0 ? (
                upcomingEvents.map((item) => (
                  <OMSActivityDisplayItem
                    item={item}
                    isUpcomingEvent
                    onActionFunc={this.handleChildActionFunc}
                    ReadOnly={this.props.ReadOnly}
                    DNType={this.props.DNType}
                    togglePinNote={this.togglePinNote}
                    key={item.diaryID}
                  />
                ))
              ) : (
                <div>No upcoming events</div>
              )}
            </div>
          </div>
          <div className="panel panel-default">
            <div className="activity-panel panel-heading" data-toggle="collapse" data-target="#past-events">
              <span>Past</span>
              <i className="fa fa-chevron-up" aria-hidden="true"></i>
            </div>
            <div className="panel-body collapse in" id="past-events">
              {pastEvents && pastEvents.length > 0 ? (
                pastEvents.map((item) => (
                  <OMSActivityDisplayItem
                    item={item}
                    isUpcomingEvent={false}
                    onActionFunc={this.handleChildActionFunc}
                    ReadOnly={this.props.ReadOnly}
                    DNType={this.props.DNType}
                    togglePinNote={this.togglePinNote}
                    key={item.diaryID}
                  />
                ))
              ) : (
                <div>No past events</div>
              )}
            </div>
          </div>
        </div>
        {pinnedNotes.length > 0 && (
          <div className="col-md-4 col-sm-12 pin-section">
            {pinnedNotes.map((note) => (
              <OMSPinnedNoteItem
                item={note}
                onActionFunc={this.handleChildActionFunc}
                togglePinNote={this.togglePinNote}
                setDragging={this.setDragging}
                setDraggedOver={this.setDraggedOver}
                onDrop={this.onDrop}
                draggingId={this.state.draggingId}
                key={note.diaryID}
              />
            ))}
            {/* <div className="panel panel-default">
              <div className="panel-heading">pinned Notes</div>
              <div className="panel-body">
                {pinnedNotes.map((note) => (
                  <OMSPinnedNoteItem
                    item={note}
                    onActionFunc={this.handleChildActionFunc}
                    togglePinNote={this.togglePinNote}
                    setDragging={this.setDragging}
                    setDraggedOver={this.setDraggedOver}
                    onDrop={this.onDrop}
                    draggingId={this.state.draggingId}
                    key={note.diaryID}
                  />
                ))}
              </div>
            </div> */}
          </div>
        )}
      </div>
    );
  }
}
