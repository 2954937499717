import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { history } from "../..";
import { LoginModel } from "../../app/models/LoginModel";
import { UserFormValues, UserSession } from "../../app/models/User";
import { useStore } from "../../app/stores/store";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import ImageWithFallback from "../../app/common/common-components/ImageWithFallback";

//import { ImageWithFallback } from "mobx-react-lite";

function LoginBox() {
  const [isRememberState, setIsRememberState] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isforgotpassword, setIsforgotpassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loginModel, setLoginModel] = useState<LoginModel>();
  const { userStore } = useStore();
  const [emailAddressState, setEmailAddressState] = useState("");

  useEffect(() => {
    let userDetailsObject = window.localStorage.getItem("userDetails");
    let userDetails: any;
    if (userDetailsObject) {
      setIsLoading(true);
      userDetails = JSON.parse(userDetailsObject);
      //console.log("inside loginBox useEffect, userDetails=", userDetails);
      let oLoginModel: LoginModel = {
        emailAddress: userDetails.email,
        profilePic: userDetails.profilePic,
        isRemember: true,
        password: "",
        systemTheme: userDetails.systemTheme,
        systemLogo: "",
        name: userDetails.name,
      };
      setEmailAddressState(userDetails.email);
      setLoginModel(oLoginModel);
      setIsRememberState(true);
      setIsLoading(false);
    } else {
      setIsLoading(true);

      apiAgent.Account.getLoginModel()
        .then((response: LoginModel) => {
          let oLoginModel: LoginModel = response;
          setLoginModel(oLoginModel);
          //setIsLoading(false);
          //console.log("loginModel obtained from apiAgent", oLoginModel);
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          //console.log("Login Model is", loginModel);

          setIsLoading(false);
        });
    }
  }, []);

  const onSignInDifferentAccount = (e: any) => {
    setIsRememberState(false);
  };

  const onForgotPasswordClick = (e: any) => {
    setIsforgotpassword(true);
  };

  const onBackToSignInClick = (e: any) => {
    setIsforgotpassword(false);
  };

  const handleLoginSubmit = (values: UserFormValues) => {
    const { emailAddress, password, isRemember } = values;
    let proceed: boolean = true;
    if (emailAddress === "") {
      if (emailAddressState !== "") {
        values.emailAddress = emailAddressState;
      } else {
        proceed = false;
        toast.info("Please enter Username");
      }
    }

    let isRememberValue = isRememberState || isRemember;
    values.isRemember = isRememberValue;
    if (password === "") {
      proceed = false;
      toast.info("Please enter Password");
    }
    //setIsRemember(isRemember);
    //values.isRemember = isRemember;

    if (proceed) {
      setProcessing(true);

      apiAgent.Account.login(values)
      .then((response:any) => {
        
        

        if ('message' in response && response.message === "2FARequired") {
          // Handle 2FA required logic here, such as redirecting to a 2FA page
          console.log("2FA verification required", response);
          localStorage.setItem('userEmail', response.email);
          let strIsRemember = isRememberValue.toString();
          localStorage.setItem('strIsRemember', strIsRemember);
          
          history.push({
            pathname: '/authcode'
            
        });
          
          // Redirect to 2FA verification page, possibly passing along the userId or other needed info
        }else         
        {
          const session: UserSession = response.value;
          // Handle UserSession logic here
          console.log("Login successful", response);
          userStore.login(session)
          .catch((error) => toast.error(error.response.data));
        }
          
        
      })
      .catch((error:any) => {
      console.log("error in login", error);
      toast.error(error.response.data)})
        .finally(() => setProcessing(false)).finally(() => setProcessing(false));;
    }


      
    
  };

  const handleForgotPasswordSubmit = (values: { emailAddress: string }) => {
    //console.log("inside forgot password submit, values:", values);

    const { emailAddress } = values;
    let proceed: boolean = true;
    if (emailAddress === "") {
      proceed = false;
      toast.info("Please enter Email Address");
    }
    if (proceed) {
      setProcessing(true);
      apiAgent.Account.forgotPassword(emailAddress)
      .then((response:any) => {
        
          //console.log("after submit in forgot password: ", response);
          toast.info(response);
          //var update = this.state.update + 1;
          
        
      })
      .catch((error:any) => toast.error(error.response))
        .finally(() => setProcessing(false));
    }
  };

  const Login = () => {
    return (
      <Formik
        initialValues={{
          emailAddress: "",
          password: "",
          isRemember: false,
          error: null,
        }}
        onSubmit={handleLoginSubmit}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form className="LoginForm" onSubmit={handleSubmit}>
            {isRememberState ? (
              <div className="col-sm-12">
                {/* <img className="profile-img" src={loginModel?.profilePic === "" ? "images/img-default-user.png" : loginModel?.profilePic} alt="" /> */}
                <ImageWithFallback
                  className="profile-img"
                  src={loginModel?.profilePic === "" ? "images/img-default-user.png" : loginModel?.profilePic}
                  fallbackSrc="images/img-default-user.png"
                  alt=""
                />
                <div className="text-center">
                  <label>{loginModel?.name}</label>
                  <br />
                  <span>{loginModel?.emailAddress}</span>
                  <br />
                  <br />
                </div>
                <Field type="text" name="emailAddress" className="form-control" style={{ display: "none" }} placeholder="Email Address or Username" />
              </div>
            ) : (
              <div className="col-sm-12">
                <Field type="text" name="emailAddress" className="form-control" placeholder="Email Address or Username" />
              </div>
            )}

            <div className="col-sm-12">
              <Field type="password" name="password" className="form-control" placeholder="Password" />

              <div style={{ float: "right", marginTop: -10 }}>
                <a href="#" onClick={onForgotPasswordClick}>
                  <small>Forgot Password?</small>
                </a>
              </div>
            </div>

            {isRememberState ? null : (
              <div className="col-sm-12">
                <Field type="checkbox" name="isRemember" /> Remember me
              </div>
            )}
            <div className="col-sm-12">
              {processing === true ? (
                <div className="btn submitButton">
                  <span className="fa fa-spin fa-spinner" /> Authenticating..
                </div>
              ) : (
                <button type="submit" className="btn submitButton">
                  Submit
                </button>
                // <Button className="btn submitButton" positive content='Login' type='submit' fluid />
              )}

              {isRememberState ? (
                <div className="text-center">
                  <a style={{ cursor: "pointer" }} href="#" onClick={onSignInDifferentAccount}>
                    Sign in with a different account
                  </a>
                  <br />
                  <br />
                </div>
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const ForgotPassword = () => {
    return (
      <Formik onSubmit={handleForgotPasswordSubmit} initialValues={{ emailAddress: "", error: null }}>
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form className="ForgotPasswordForm" method="post" onSubmit={handleSubmit}>
            <div className="col-sm-12">
              <p style={{ marginLeft: -12 }}>Enter your email address to reset your password.</p>
              {/*You will receive an email with link to reset your password.*/}
            </div>

            <div className="col-sm-12">
              <Field type="text" name="emailAddress" className="form-control" placeholder="Email Address" />
            </div>

            <div className="col-sm-12">
              {processing === true ? (
                <div className="btn submitButton">
                  <span className="fa fa-spin fa-spinner" /> Processing..
                </div>
              ) : (
                <button type="submit" className="btn submitButton">
                  Submit
                </button>
              )}

              <div className="col-sm-12">
                <small>
                  If you don't get an email from us within a few minutes please be sure to check your spam filter. You will receive an email from{" "}
                  <b>noreply@base.entegral.net</b>.
                </small>
              </div>

              <div className="text-center">
                <br />
                <a style={{ cursor: "pointer" }} href="#" onClick={onBackToSignInClick}>
                  Sign In
                </a>
                <br />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading && loginModel) {
    //console.log("Before return: Login Model is", loginModel);
    return (
      <div className="row loginbox">
        <div className="col-lg-12 text-center">
          <span className="signIntext">{isforgotpassword ? "Forgot password?" : "Sign into your account"}</span>
          <br />
          <br />
        </div>
        <div id="content">{isforgotpassword ? <ForgotPassword /> : <Login />}</div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default LoginBox;
