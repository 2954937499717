import React from 'react';
import PropTypes from 'prop-types';

export class OMSGeoLocation extends React.Component {
  static propTypes = {
    onLocationSubmit: PropTypes.func,
    Address: PropTypes.string,
  };

  state = {
    LocationLat: null,
    LocationLong: null,
    IsViewLocation: false,
  };

  componentDidMount() {
    window
      .$("#getLocationModel")
      .off("shown.bs.modal")
      .on(
        "shown.bs.modal",
        function () {
          if (this.map === undefined) {
            this.loadMap();
            setTimeout(
              function () {
                if (this.state.IsViewLocation) this.addDraggableMarker(this.state.LocationLat, this.state.LocationLong);
                else this.searchAddressLocation();
              }.bind(this),
              2000
            );
          } else {
            if (this.state.IsViewLocation) this.addDraggableMarker(this.state.LocationLat, this.state.LocationLong);
            else this.searchAddressLocation();
          }
        }.bind(this)
      );
    document.getElementById("Search").value = this.props.Address;
  }

  loadMap = () => {
    const H = window.H;
    var mapContainer = document.getElementById("map");

    var apiKey = 'QJF2sIKVcgIx_0Au3uj74g262vyhqKaE7UwbXSC9jUk';

    if (!this.platform) {
      var platform = new H.service.Platform({
        apikey: apiKey,
      });

      var defaultLayers = platform.createDefaultLayers();
      var geocoder = platform.getSearchService();

      var map = new H.Map(mapContainer, defaultLayers.vector.normal.map, {
        center: { lat: 52.516, lng: 13.3779 },
        zoom: 3,
      });

      window.addEventListener('resize', () => map.getViewPort().resize());

      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      var ui = H.ui.UI.createDefault(map, defaultLayers);

      this.platform = platform;
      this.geocoder = geocoder;
      this.ui = ui;
      this.map = map;
      this.behavior = behavior;
    }
  };

  onLocationSubmitClick = (e) => {
    e.preventDefault();
    if (this.props.onLocationSubmit) {
      this.props.onLocationSubmit(this.state.LocationLat, this.state.LocationLong);
      window.$("#getLocationModel").modal("hide");
    }
  };

  onGeocodeClick = (e) => {
    document.getElementById("Search").value = this.props.Address.replace(/\n/g, ",");
    this.setState({ IsViewLocation: false }, () => {
      window.$("#getLocationModel").modal("show");
    });
  };

  funcViewLocationOnMap = (lat, long) => {
    this.setState({ IsViewLocation: true, LocationLat: lat, LocationLong: long }, () => {
      window.$("#getLocationModel").modal("show");
    });
  };

  onSearchLocationClick = (e) => {
    e.preventDefault();
    this.searchAddressLocation();
  };

  searchAddressLocation = () => {
    if (!this.map || !this.geocoder) return;

    const search = document.getElementById("Search").value;
    this.geocoder.geocode({ q: search }, this.onGeocodeSuccess, this.onGeocodeError);
  };

  onGeocodeSuccess = (result) => {
    if (result.items && result.items.length > 0) {
      const location = result.items[0].position;
      this.addDraggableMarker(location.lat, location.lng);
    }
  };

  onGeocodeError = (error) => {
    alert("Error obtained while searching location. Please contact the administrator.");
  };

  addDraggableMarker = (lat, long) => {
    const H = window.H;
    if (!this.map) return;

    if (!this.marker) {
      var marker = new H.map.Marker({ lat: lat, lng: long });
      marker.draggable = true;
      this.map.addObject(marker);
      this.marker = marker;

      this.map.setZoom(15);
      this.map.setCenter({ lat: lat, lng: long });

      this.map.addEventListener("dragstart", (ev) => {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
          this.behavior.disable();
        }
      });

      this.map.addEventListener("dragend", (ev) => {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
          this.behavior.enable();
          var location = target.getGeometry();
          this.map.setCenter(location);
          this.setState({
            LocationLat: location.lat.toFixed(5),
            LocationLong: location.lng.toFixed(5),
          });
        }
      });

      this.map.addEventListener("drag", (ev) => {
        var target = ev.target,
          pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          target.setGeometry(this.map.screenToGeo(pointer.viewportX, pointer.viewportY));
        }
      });
    } else {
      if (this.marker instanceof H.map.Marker) {
        var point = new H.geo.Point(lat, long);
        this.marker.setGeometry(point);
        this.map.setCenter({ lat: lat, lng: long });
      }
    }

    this.setState({ LocationLat: lat, LocationLong: long });
  };

  render() {
    return (
      <div className="form-group">
        <input type="button" className="btn btn-primary" value="Geocode from Address" onClick={this.onGeocodeClick} style={{ marginLeft: "10px" }} />
        <div className="modal fade" id="getLocationModel" tabIndex={-1} role="dialog" aria-labelledby="getLocationModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                <h4 className="modal-title" id="getLocationModelLabel">Physical Location</h4>
              </div>
              <div className="modal-body">
                <br />
                <div id="map" style={{ height: "350px" }}></div>
                <br />
              </div>
              <div className="modal-footer">
                {!this.state.IsViewLocation && (
                  <div className="col-md-12">
                    <div className="form-group pull-left" style={{ marginBottom: "10px" }}>
                      <span htmlFor="SearchAddress">Search Address</span>
                      <input type="text" className="form-control" style={{ marginLeft: "10px" }} id="Search" name="Search" placeholder="Search Address" />
                      <button type="button" className="btn btn-primary" style={{ marginLeft: "10px" }} onClick={this.onSearchLocationClick}>
                        Search
                      </button>
                    </div>
                  </div>
                )}
                {!this.state.IsViewLocation && (
                  <div className="col-md-12">
                    <div className="form-group form-inline pull-left">
                      <span htmlFor="SearchAddress">Marker position (lat,long):
                        <label>{this.state.LocationLat},{this.state.LocationLong}</label>
                      </span>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  {!this.state.IsViewLocation && (
                    <button type="button" className="btn btn-primary" onClick={this.onLocationSubmitClick}>Use Position</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}




export class OMSGeoMap extends React.Component {
  static propTypes = {
    onGeoMapChange: PropTypes.func,
  };

  componentDidMount() {
    this.loadMap();
  }

  loadMap = () => {
    const H = window.H;
    var mapContainer = document.getElementById("map");

    var apiKey = 'QJF2sIKVcgIx_0Au3uj74g262vyhqKaE7UwbXSC9jUk';

    if (!this.platform) {
      var platform = new H.service.Platform({
        apikey: apiKey,
      });

      var defaultLayers = platform.createDefaultLayers();
      var geocoder = platform.getSearchService();

      var map = new H.Map(mapContainer, defaultLayers.vector.normal.map, {
        center: { lat: 52.516, lng: 13.3779 },
        zoom: 3,
      });

      window.addEventListener('resize', () => map.getViewPort().resize());

      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      var ui = H.ui.UI.createDefault(map, defaultLayers);

      this.platform = platform;
      this.geocoder = geocoder;
      this.ui = ui;
      this.map = map;
      this.behavior = behavior;
    }
  };

  resizeMap = () => {
    if (this.map) {
      this.map.getViewPort().resize();
    }
  };

  onLocationChanged = (lat, long) => {
    this.addDraggableMarker(lat, long);
  };

  onSearchAddressLocation = (search) => {
    if (this.map === undefined) return;
    if (this.geocoder === undefined) return;

    this.geocoder.geocode({ q: search }, this.onGeocodeSuccess, this.onGeocodeError);
  };

  onGeocodeSuccess = (result) => {
    if (result.items && result.items.length > 0) {
      const location = result.items[0].position;
      this.addDraggableMarker(location.lat, location.lng);
    }
  };

  onGeocodeError = (error) => {
    alert("Error obtained while searching location. Please contact the administrator.");
  };

  addDraggableMarker = (lat, long) => {
    const H = window.H;
    if (this.map === undefined) return;
  
    if (this.marker === undefined) {
      var marker = new H.map.Marker({ lat: lat, lng: long });
      marker.draggable = true;
      this.map.addObject(marker);
      this.marker = marker;
  
      this.map.setZoom(15);
      this.map.setCenter({ lat: lat, lng: long });
  
      this.map.addEventListener(
        "dragstart",
        function (ev) {
          var target = ev.target;
          if (target instanceof H.map.Marker) {
            this.behavior.disable();
          }
        }.bind(this),
        false
      );
  
      this.map.addEventListener(
        "dragend",
        function (ev) {
          var target = ev.target;
          if (target instanceof H.map.Marker) {
            this.behavior.enable();
            var location = target.getGeometry();
            this.map.setCenter(location);
            if (this.props.onGeoMapChange !== undefined) this.props.onGeoMapChange(location.lat, location.lng);
          }
        }.bind(this),
        false
      );
  
      this.map.addEventListener(
        "drag",
        function (ev) {
          var target = ev.target,
            pointer = ev.currentPointer;
          if (target instanceof H.map.Marker) {
            target.setGeometry(this.map.screenToGeo(pointer.viewportX, pointer.viewportY));
          }
        }.bind(this),
        false
      );
    } else {
      if (this.marker instanceof H.map.Marker) {
        var point = new H.geo.Point(lat, long);
        this.marker.setGeometry(point);
        this.map.setCenter({ lat: lat, lng: long });
      }
    }
  
    if (this.props.onGeoMapChange !== undefined) this.props.onGeoMapChange(lat, long);
  };
  
  render() {
    return (
      <div>
        <br />
        <div id="map" style={{ height: "350px" }}></div>
        <br />
      </div>
    );
  }
}



