import React, { useEffect, useState, useRef } from "react";
import apiAgent from "../../../../app/api/apiAgent";
import { DataTableComp, DataTableLoading } from "../../../../app/common/common-components/OMSDatatables";

interface OfficeInsightsSummaryModel {
  officeName: string;
  propertyReportPurchaseCount: number;
  ownerDetailPurchaseCount: number;
  month: number;
  year: number;
  officeID: string;
}

const OfficeInsightsSummary: React.FC = () => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [data, setData] = useState<OfficeInsightsSummaryModel[]>([]);
  const [filteredData, setFilteredData] = useState<OfficeInsightsSummaryModel[]>([]);
  const [months, setMonths] = useState<{ label: string; month: number; year: number }[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<number>(currentMonth);
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);
  const [loading, setLoading] = useState<boolean>(false);

  const dataTableRef = useRef<any>(null);

  const loadReportsFromServer = async () => {
    setLoading(true);
    try {
      const response = await apiAgent.Admin.getOfficeInsightsSummaryInfo();
      const updatedResponse = response.map((item: OfficeInsightsSummaryModel) => ({
        ...item,
        officeName: item.officeName || "Unknown Office" // Fallback if officeName is missing
      }));
      setData(updatedResponse);
      filterData(updatedResponse, currentMonth, currentYear);
    } finally {
      setLoading(false);
    }
  };

  const filterData = (data: OfficeInsightsSummaryModel[], month: number, year: number) => {
    const filtered = data.filter((item) => item.month === month && item.year === year);
    setFilteredData(filtered);

    if (dataTableRef.current) {
      dataTableRef.current.reloadTableData(filtered);
    }
  };

  const updateMonths = () => {
    const updatedMonths = [];
    let month = currentMonth;
    let year = currentYear;

    for (let i = 0; i < 12; i++) {
      updatedMonths.push({
        label: `${monthNames[month - 1]} ${year}`,
        month,
        year,
      });
      month--;
      if (month === 0) {
        month = 12;
        year--;
      }
    }
    setMonths(updatedMonths);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const [newMonth, newYear] = e.target.value.split("-").map(Number);
    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
    filterData(data, newMonth, newYear);
  };

  useEffect(() => {
    updateMonths();
    loadReportsFromServer();
  }, []);

  const columns = [
    { data: "officeName", title: "Office Name" },
    { data: "propertyReportPurchaseCount", title: "Property Report Purchase Count" },
    { data: "ownerDetailPurchaseCount", title: "Owner Detail Purchase Count" },
  ];

  return (
    <div className="col-md-12">
      <div className="panel panel-default panel-table oms-box">
        <div className="panel-heading">
          <div className="row">
            <div className="col-md-6">
              <div className="form-inline form-group">
                <div className="element">
                  <select
                    id="insightsMonths"
                    className="form-control"
                    onChange={handleMonthChange}
                    value={`${selectedMonth}-${selectedYear}`}
                  >
                    {months.map((monthOption, index) => (
                      <option key={index} value={`${monthOption.month}-${monthOption.year}`}>
                        {monthOption.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <div className="table-responsive">
            <DataTableComp
              ref={dataTableRef}
              id="insightsSummaryTable"
              columns={columns}
              data={filteredData}
              order={[[1, "asc"]]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeInsightsSummary;
