import React from "react";
import { toast } from "react-toastify";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../app/api/apiAgent";
//import bootbox from "bootbox";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from 'react-router-dom';

class ImportPropertiesGrid extends React.Component {
    state = {
        ImportID: this.props.ImportID,
        view: 0,
        ExtraParams: JSON.stringify({ ID: this.props.ImportID, Status: 0 }),
        loading: false,
        OfficeID: '',
        TaskID: 1,
        ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false
        },
        importPropertiesData: [],
        confirmationModalProps: {},
    };

    loadImportPropertiesFromServer = (importID, status) => {
        this.setState({ loading: true });
        //console.log("load import properties data, importID=", importID)
        apiAgent.Import.getImportPropertiesData(importID, status)
          .then((response) => {
            //console.log("import properties data", this.state.importPropertiesData);
            this.setState({
                importPropertiesData: response.data,
            });
            //console.log("import properties Data", response.data);
          })
          .catch((error) => {
            toast.error("Failed");
            //toast.error(error.response.data))
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      };

      componentDidMount() {
        //console.log("import properties data", this.state.ImportID);
        this.loadImportPropertiesFromServer(this.state.ImportID);

        let varConfirmModalProps = {
            showModal: false,
            isConfirmed: (result) => {},
            title: "",
            body: "",
          };
          this.setState({ confirmationModalProps: varConfirmModalProps });
      }
    

    onViewDropDownChange = (e) => {
        this.setState({ ExtraParams: JSON.stringify({ ID: this.props.ImportID, Status: e.target.value }), view: e.target.value });
        this.loadImportPropertiesFromServer(this.props.ImportID, e.target.value);
    };

    handleRowClick = (data) => {
        this.props.history.push('/Import/SaveProperty?ImportID=' + data.importID + '&PropertyID=' + data.propertyID);
    };

    openCloseDeleteModal = (isOpen) => {
        let varConfirmationModalProps = this.state.confirmationModalProps;
        varConfirmationModalProps.showModal = isOpen;
        this.setState({ confirmationModalProps: varConfirmationModalProps });
      };
    
      showPopupAndDeleteOnConfirmation = (title, body, callback) => {
        let varConfirmationModalProps = {
          title,
          body,
          showModal: true,
          isConfirmed: function (isConfirmed) {
            if (isConfirmed) {
              callback();
            }
            this.openCloseDeleteModal(false);
          }.bind(this),
        };
        this.setState({ confirmationModalProps: varConfirmationModalProps });
      }; 

    onDeleteImportClick = (e) => {
        /* bootbox.confirm({
            title: "Delete import?",
            message: "Do you want to delete import now? This cannot be undone.",
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancel'
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Confirm'
                }
            },
            callback: function (result) {
                if (result) {
                    this.executeDeleteImportClick();
                }
            }.bind(this)
        }); */
        let title = "Delete import?";
        let body = "Do you want to delete import now? This cannot be undone.";
        let callback = this.executeDeleteImportClick;
        this.showPopupAndDeleteOnConfirmation(title, body, callback);
    };

    executeDeleteImportClick = (e) => {
        this.setState({ ButtonStatus: { disabled: true, processing_save: false, processing_delete: true } });
        apiAgent.Import.deleteImportAjax(this.state.ImportID)
      .then((response) => {
        this.props.history.push('/import/index');
      })
      .catch((error) => {
        this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
        toast.error(error.message, 'Fail', 'danger');
      })
    };

    handleOfficeIDChange = (e) => {
        this.setState({ OfficeID: e.newValue });
    };
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    onProcessClick = (e) => {
        window.$('#processModel').modal('show');
    };
    onProcessTaskClick = (e) => {
        if (this.state.TaskID == 1 || this.state.TaskID == 3) {
            /* bootbox.confirm({
                title: "Process import?",
                message: "Do you want to process task now? This cannot be undone.",
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancel'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> Confirm'
                    }
                },
                callback: function (result) {
                    if (result) {
                        this.executeProcessTaskClick();
                    }
                }.bind(this)
            }); */
            let title = "Process import?";
            let body = "Do you want to process task now? This cannot be undone.";
            let callback = this.executeProcessTaskClick;
            this.showPopupAndDeleteOnConfirmation(title, body, callback);
        }
        else {
            this.executeProcessTaskClick();
        }
    };
    executeProcessTaskClick = (e) => {
        this.setState({ ButtonStatus: { disabled: true, processing_save: true, processing_delete: false } });
        /* var data = new FormData();
        data.append('ID', this.state.ImportID);
        data.append('OfficeID', this.state.OfficeID);
        data.append('TaskID', this.state.TaskID);
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/import/processImportTaskAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
                window.$('#processModel').modal('hide');
                toast.info(data.message, 'Success', 'info');
                if (this.state.TaskID == 1 || this.state.TaskID == 3) {
                    window.location.reload();
                }
            }
            else {
                this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
                toast.error(data.message, 'Fail', 'danger');
            }
        }.bind(this);
        xhr.send(data); */
        apiAgent.Import.processImportTaskAjax(this.state.ImportID, this.state.OfficeID, this.state.TaskID)
      .then((response) => {
        this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
                window.$('#processModel').modal('hide');
                toast.info(response.message, 'Success', 'info');
                if (this.state.TaskID == 1 || this.state.TaskID == 3) {
                    window.location.reload();
                }
      })
      .catch((error) => {
        this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
                toast.error(error.message, 'Fail', 'danger');
      })
    };
    
    render() {
        function formatWebRefImportProperty(data) {

            if (data.status == 1) {
                return "<span><i class='fa fa-check-square' style='color: green'></i>&nbsp;" + data.webref + "</span>";
            }
            else if (data.status == 2) {
                return "<span><i class='fa fa-check-circle' style='color: green'></i>&nbsp;" + data.webref + "</span>";
            }
            else if (data.status == 4) {
                return "<span><i class='fa fa-exclamation-triangle' style='color: red'></i>&nbsp;" + data.webref + "</span>";
            }
            else if (data.status == 3) {
                return "<span><i class='fa fa-clock-o' style='color: orange'></i>&nbsp;" + data.webref + "</span>";
            }
            else if (data.status == -1) {
                return "<span><i class='fa fa-lock' style='color: black'></i>&nbsp;" + data.webref + "</span>";
            }
            else if (data.status == -2) {
                return "<span><i class='fa fa-diamond' style='color: black'></i>&nbsp;" + data.webref + "</span>";
            }
            else {
                return "<span>" + data.status + "&nbsp;" + data.webref + "</span>";
            }
        }

        var columns = [
            { data: 'importStatusWebRef', title: "WebRef" },
            { data: 'cityOrTown', title: "Town" },
            { data: 'districtOrSuburb', title: "Suburb" },
            { data: 'street', title: "Street" },
            { data: 'complex', title: "Complex" },
            { data: 'price', title: "Price" },
            { data: 'propertyType', title: "PropertyType" },
            { data: 'saleType', title: "SaleType" },
            { data: 'status', title: "Status" },
            { data: 'mandate', title: "Mandate" }
        ];

        var columnDefs = [
            {
                "render": function (data, type, row) {
                    return formatWebRefImportProperty(JSON.parse(data));
                },
                "targets": 0
            }
        ];

        return (
            <div className="col-md-12">
                <div className="panel panel-default panel-table oms-box">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-6">
                            <ConfirmationModalPopup {...this.state.confirmationModalProps} />
                                <div className="form-inline form-group">
                                    <label><i className="fa fa-filter" aria-hidden="true"></i>&nbsp;</label>
                                    <select style={{ marginRight: 10 }} className="form-control" onChange={this.onViewDropDownChange} value={this.state.view}>
                                        <option value="0">All</option>
                                        <option value="3">Warning</option>
                                        <option value="4">Error</option>
                                        <option value="2">Success</option>
                                        <option value="-1">Processed</option>
                                        <option value="-2">Processed(Photos)</option>
                                        <option value="10">All (Active)</option>
                                        <option value="13">Warning (Active)</option>
                                        <option value="14">Error (Active)</option>
                                        <option value="12">Success (Active)</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="pull-right">
                                    <div className="form-inline">
                                        <div className="form-inline">
                                            <OMSButtonStyleL type="button" ui_icon="fa-wrench" ui_type="save" ui_text="Process" ui_processing_text="" processing={false} disabled={false} onClick={this.onProcessClick} />
                                            &nbsp;<OMSButtonStyleL type="button" ui_icon="fa-trash-o" ui_type="cancel" ui_text="delete import" ui_processing_text="deleting.." processing={this.state.ButtonStatus.processing_delete} disabled={this.state.ButtonStatus.disabled} onClick={this.onDeleteImportClick} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body table-responsive">
                        {/* <DataTableCompDynamic id="importPropertiesTable" Url="/Import/PropertiesAjax" columns={columns} columnDefs={columnDefs} serverSide={false} onRowClick={this.handleRowClick} ExtraParams={this.state.ExtraParams} /> */}
                        <DataTableComp
                        id="importPropertiesTable"
                        data={this.state.importPropertiesData}
                        columns={columns}
                        columnDefs={columnDefs}
                        //order={[[0, "asc"]]}
                        onRowClick={this.handleRowClick}
                        />
                    </div>
                </div>

                <div id="processModel" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title">Process Import?</h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="Office">Office</label>
                                        <OMSDropdown id="OfficeID" options={this.props.officeDD} valueField="value" labelField="text" value={this.state.OfficeID} onChange={this.handleOfficeIDChange} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="TaskID">Task</label>
                                        <select id="TaskID" name="TaskID" value={this.state.TaskID} onChange={this.handleChange} className="form-control">
                                            <option value="1">1. Process Listing</option>
                                            <option value="2">2. Validate Listing</option>
                                            <option value="3">3. Process Photos</option>
                                            <option value="4">4. Validate Photos</option>
                                            <option value="5">5. Process Listing (Archieved)</option>
                                            <option value="6">6. Process Photos (Archieved)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <OMSButtonStyleL type="button" style={{ marginRight: 10 }} ui_icon="fa-wrench" ui_type="save" ui_text="Run Task" ui_processing_text="Processing.." processing={this.state.ButtonStatus.processing_save} disabled={this.state.ButtonStatus.disabled} onClick={this.onProcessTaskClick} />
                                <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ImportPropertiesGrid);