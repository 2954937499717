import React, { useEffect, useState } from "react";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { ReferralComponent } from "../ReferralComponent";

function Referral(props: any) {
  const [assigned, setAssigned] = useState(props.contact!.assigned);
  //const { contact, assignedName, setAssignedName } = props;
  const { contact } = props;

  const getAssignedName = (AssignedID: any) => {
    var AgentDD = props.contact!.agentDD!;
    for (var i = 0; i < AgentDD.length; i++) {
      if (AgentDD[i].value == AssignedID) {
        return AgentDD[i].text;
      }
    }
    return "";
  };
  const [assignedName, setAssignedName] = useState(getAssignedName(props.contact!.assigned));

  const handleAssignedDDChange = (e: any) => {
    setAssigned(e.newValue);
    setAssignedName(e.newText);
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="form-group form-inline">
          <label htmlFor="Assigned" style={{ marginRight: 10 }}>
            {contact!.userID === assigned ? "Assigned to" : "Referred to"}
          </label>
          <OMSDropdown
            id="Assigned"
            name="Assigned"
            options={props.contact!.agentDD!}
            valueField="value"
            labelField="text"
            value={assigned}
            onChange={handleAssignedDDChange}
          />
        </div>
      </div>
      <div className="col-md-12">
        <ReferralComponent
          actions={contact!.actions}
          super_access={contact!.super_access}
          //doSave={this.doRequirementSave}
          assigned={assigned}
          assignedName={assignedName}
          officeID={contact!.officeID}
          contactID={contact!.contactID}
          propertyTypes={contact!.propertyTypes}
          currencyDisplay={contact!.currencyDisplay}
          userID={contact!.userID}
        />
      </div>
    </div>
  );
}

export default Referral;
