import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import { IntegrationModel } from "../../../app/models/IntegrationModel";
import IntegrationBox from "./IntegrationBox";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function SaveIntegrationPage() {
  const [integration, setIntegration] = useState<IntegrationModel>();
  const [isLoading, setIsLoading] = useState(true);
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let officeId: string = "";
    let portalID = 0;
    if (params.has("OfficeID")) {
      const id = params.get("OfficeID");
      officeId = id !== null ? id : "";
    }
    if (params.has("PortalID")) {  
        const id = params.get("PortalID");
        if(id)
        {
            portalID = parseInt(id);
        }else
        {
            portalID = 0;
        }
    }

    setIsLoading(true);
    apiAgent.Setting.saveIntegration(officeId, portalID)
      .then((response: IntegrationModel) => {
        let oIntegration: IntegrationModel = response;
        setIntegration(oIntegration);

        //console.log("SaveIntegration", oIntegration);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save Integration";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && integration) {
    return (
      <>
        <HeaderWrapper />

        <div className="save-Integration-Page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Integrations", path: "/settings/Integrations" },
              { text: integration.name }
            ]}
          />
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <IntegrationBox data={integration} actions={integration.actions} />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
