import React, { useEffect, useState } from "react";
import ConfirmationModalPopup from "../app/common/common-components/ModalPopups/ConfirmationModalPopup";

function TestComponent() {
  const [test, setTest] = useState("test");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setTest("test2");
  }, []);

  const openModalPopup = () => {
    //console.log("openModalPopup, showModal: ", showModal);
    setShowModal(true);
  };

  const isConfirmed = (result: boolean) => {
    if (result === true) {
      //console.log("confirmModalPopup, showModal: ", showModal);
      setTest("Modal Popup confirmed");
      setShowModal(false);
    } else {
      //console.log("closeModalPopup, showModal: ", showModal);
      setShowModal(false);
    }
  };

  return (
    <div>
      {test}
      <button onClick={openModalPopup}>Click</button>

      <ConfirmationModalPopup
        showModal={showModal}
        isConfirmed={isConfirmed}
        title="Test Component Confirmation Dialogue"
        body="This is a test of the BaseConfirmationModal component."
      />
    </div>
  );
}

export default TestComponent;
