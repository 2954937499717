import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import { DataTableLoading } from "./OMSDatatables";
//import bootbox from "bootbox";
import apiAgent from "../../api/apiAgent";
import ConfirmationModalPopup from "./ModalPopups/ConfirmationModalPopup";


export class OMSDLTree extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    librarytype: PropTypes.number,
    allowAction: PropTypes.bool.isRequired,
    onSelectionChange: PropTypes.func,
  };

  state = {
    data: [],
    loading: false,
    processing: false,
    error_message: "",
    folderid: "",
    foldername: "",
    folderparentid: "",
    selected: "",
    deleteFileID: "",
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  

  componentDidMount() {
    this.loadFSFoldersFromServer();

  }

  loadFSFoldersFromServer = () => {
    this.setState({
      processing: false,
      loading: true,
      data: [],
      error_message: "",
    });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Library/FSFoldersAjax?View=' + this.props.librarytype, true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            this.setState({ data: data, error_message: '', selected: '', loading: false });
        }.bind(this);
        xhr.send(); */
    apiAgent.Library.fsFoldersAjax(this.props.librarytype)
      .then((response) => {
        //console.log("after fsFoldersAjax",response);
        this.setState({
          data: response,
          error_message: "",
          selected: "",
          loading: false,
        });
      })
      .catch((error) => toast.error(error.response.data));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.librarytype != this.props.librarytype) this.loadFSFoldersFromServer();

    if (this.props.allowAction) {
      window.$(".treeview li").contextMenu({
        menuSelector: "#contextMenu",
        menuSelected: function (invokedOn, selectedMenu) {
          if (!invokedOn.is("li")) invokedOn = window.$(invokedOn.closest("li"));
          var data = invokedOn.data();
          if (data !== undefined) {
            if (selectedMenu.text() == "Rename") {
              if (data.fileid != "") {
                var data1 = this.state.data;
                var index = data1.findIndex((I) => I.fileID == data.fileid);
                if (index > -1) {
                  this.setState({
                    folderid: data1[index].fileID,
                    foldername: data1[index].name,
                    folderparentid: data1[index].parentID,
                  });
                  window.$("#saveFSFolderDialog").modal("show");
                }
              }
            } else if (selectedMenu.text() == "Delete") {
              if (data.fileID != "") {
                // bootbox.confirm({
                //   title: "Delete folder?",
                //   message: " Are you sure you want to delete this folder? This will delete all subfolders and files and cannot be undone.",
                //   buttons: {
                //     cancel: {
                //       label: '<i class="fa fa-times"></i> Cancel',
                //     },
                //     confirm: {
                //       label: '<i class="fa fa-check"></i> Confirm',
                //     },
                //   },
                //   callback: function (result) {
                //     if (result) {
                //       this.executeDeleteFSFolderContextMenu(data.fileID);
                //     }
                //   }.bind(this),
                // });
                //console.log("before delete, data=",data);
                this.onDeleteFSFolder(data.fileid);
              }
            } else {
              this.setState({
                folderid: "",
                foldername: "",
                folderparentid: data.fileid,
              });
              window.$("#saveFSFolderDialog").modal("show");
            }
          }
        }.bind(this),
      });
    }
  
  }

  handleExpandCollapse = (id, expanded) => {
    var data = this.state.data;
    var index = data.findIndex((I) => I.fileID == id);
    if (index > -1) {
      data[index].expanded = expanded;
      this.setState({ data: data });
    }
  };

  handleSelect = (id) => {
    this.setState({ selected: id });
    if (this.props.onSelectionChange !== undefined) this.props.onSelectionChange(id);
  };

  getChildHtml = (data) => {
    //console.log("getChildHtml", data);
    var childItems = this.state.data.filter(function (i) {
      if (data == null) return i.parentID == "_blank";
      return i.parentID == data.fileID;
    });
    //console.log("getChildHtml", childItems);
    return (
      <ul className={data == null ? "treeview" : ""}>
        {childItems.map((item) => (
          <li key={item.fileID} data-fileid={item.fileID} data-name={item.name}>
            {item.hasChild == false ? null : item.expanded ? (
              <i className="fa fa-minus-square-o" aria-hidden="true" onClick={() => this.handleExpandCollapse(item.fileID, false)}></i>
            ) : (
              <i className="fa fa-plus-square-o" aria-hidden="true" onClick={() => this.handleExpandCollapse(item.fileID, true)}></i>
            )}
            <span className={this.state.selected == item.fileID ? "treenode_selected" : "treenode"} onClick={() => this.handleSelect(item.fileID)}>
              <i className="fa fa-folder" aria-hidden="true"></i>
              {item.name}
            </span>
            {item.hasChild && item.expanded ? this.getChildHtml(item) : null}
          </li>
        ))}
      </ul>
    );
  };

  onCreateFSFolder = (e) => {
    this.setState({
      folderid: "",
      foldername: "",
      folderparentid: this.state.selected,
    });
    window.$("#saveFSFolderDialog").modal("show");
  };

  onRenameFSFolder = (e) => {
    //console.log("Inside onRenameFSFolder, e=",e);
    var data = this.state.data;
    var index = data.findIndex((I) => I.fileID == this.state.selected);
    if (index > -1) {
      this.setState({
        folderid: data[index].fileID,
        foldername: data[index].name,
        folderparentid: data[index].parentID,
      });
      window.$("#saveFSFolderDialog").modal("show");
    }
  };

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  onDeleteFSFolder = (fileID) => {
    //console.log("Inside onDeleteFSFolder, fileID=",fileID);
    this.setState({ deleteFileID: fileID });
    // bootbox.confirm({
    //   title: "Delete folder?",
    //   message: "Are you sure you want to delete this folder? This will delete all subfolders and files and cannot be undone.",
    //   buttons: {
    //     cancel: {
    //       label: '<i class="fa fa-times"></i> Cancel',
    //     },
    //     confirm: {
    //       label: '<i class="fa fa-check"></i> Confirm',
    //     },
    //   },
    //   callback: function (result) {
    //     if (result) {
    //       this.executeDeleteFSFolder();
    //     }
    //   }.bind(this),
    // });
    let title = "Delete folder?";
    let body = "Are you sure you want to delete this folder? This will delete all subfolders and files and cannot be undone.";
    let callback = this.executeDeleteFSFolder;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  executeDeleteFSFolder = (e) => {
    var data = new FormData();
    data.append("fileID", this.state.deleteFileID);
    data.append("type", this.props.librarytype);
    this.setState({ processing: true, error_message: "" });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Library/FSDeleteFolderAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                this.handleSelect('');
                this.loadFSFoldersFromServer();
            }
            else {
                this.setState({ processing: false, error_message: data.message });
            }
        }.bind(this);
        xhr.send(data); */
    var formData=Object.fromEntries(data);
    apiAgent.Library.fsDeleteFolderAjax(formData)
      .then((response) => {
        this.handleSelect("");
        this.loadFSFoldersFromServer();
      })
      .catch((error) =>
        this.setState({
         // processing: false,
          error_message: error.response.message,
        })
      )
      .finally(() => {
        this.setState({ deleteFileID: false,
          processing: false });
      });
  };

  executeDeleteFSFolderContextMenu = (fileid) => {
    var data = new FormData();
    data.append("fileID", fileid);
    data.append("type", this.props.librarytype);
    this.setState({ processing: true, error_message: "" });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Library/FSDeleteFolderAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                this.handleSelect('');
                this.loadFSFoldersFromServer();
            }
            else {
                this.setState({ processing: false, error_message: data.message });
            }
        }.bind(this);
        xhr.send(data); */
    apiAgent.Library.fsDeleteFolderAjax(data)
      .then((response) => {
        this.handleSelect("");
        this.loadFSFoldersFromServer();
      })
      .catch((error) =>
        this.setState({
          processing: false,
          error_message: error.response.message,
        })
      );
  };

  handleModelSubmit = (e) => {
    e.preventDefault();
    if (this.state.foldername != "") {
      var data = new FormData();

      data.append("fileID", this.state.folderid);
      data.append("name", this.state.foldername);
      data.append("parentID", this.state.folderparentid);
      data.append("type", this.props.librarytype);
      this.setState({ processing: true, error_message: "" });

      var formData=Object.fromEntries(data.entries());
      apiAgent.Library.fsSaveFolderAjax(formData)
        .then((response) => {
          window.$("#saveFSFolderDialog").modal("hide");
          this.loadFSFoldersFromServer();
        })
        .catch((error) =>
          this.setState({
            processing: false,
            error_message: error.response.message,
          })
        );
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div className="treeview-container">

        {this.state.loading ? <DataTableLoading loading={this.state.loading} /> : this.getChildHtml(null)}
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div className="modal fade" id="saveFSFolderDialog" tabIndex={-1} role="dialog" aria-labelledby="saveFSFolderDialogLabel" aria-hidden="true">
          <form id="frmSaveModel" onSubmit={this.handleModelSubmit}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    ×
                  </button>
                  <h4 className="modal-title" id="saveFSFolderDialogLabel">
                    Save Folder
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label htmlFor="foldername">Name</label>
                      <input
                        type="text"
                        id="foldername"
                        name="foldername"
                        value={this.state.foldername}
                        onChange={this.handleChange}
                        className="form-control"
                        data-validation="required"
                        data-validation-error-msg="Please enter folder name"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
