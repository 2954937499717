import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

export class OMSCalendar extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    data: PropTypes.array,
  };

  constructor(props) {
    super(props);
    var selected = new Date();
    this.state = { selected_date: selected, display_ranges: [] };
  }

  componentDidMount() {
    this.loadCalendar(this.state.selected_date);
  }

  loadCalendar = (selected_date) => {
    var dateNow = new Date();
    if (selected_date != null) {
      dateNow = selected_date;
    }
    var month = dateNow.getMonth();
    var day = dateNow.getDate();
    var year = dateNow.getFullYear();
    var startDate = new Date(year, month, 1); //new Date((month + 1) + ' 1 ,' + year);
    var febDays = 28;

    //Determing if February (28,or 29)
    if (month == 1) {
      if ((year % 100 != 0 && year % 4 == 0) || year % 400 == 0) {
        febDays = 29;
      }
    }

    //var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    //var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thrusday", "Friday", "Saturday"];
    var dayPerMonth = ["31", "" + febDays + "", "31", "30", "31", "30", "31", "31", "30", "31", "30", "31"];

    var displayRanges = [];
    displayRanges.length = 42;

    var weekdays = startDate.getDay();
    var numOfDays = dayPerMonth[month];

    var index = weekdays;
    for (var i = 0; i < numOfDays; i++) {
      displayRanges[index + i] = parseInt(i + 1, 10);
    }

    var displayRangesInWeek = [];
    for (var i = 0; i < 42; i = i + 7) {
      if (displayRanges[i] == null && displayRanges[i + 6] == null) {
      } else {
        displayRangesInWeek.push([
          displayRanges[i],
          displayRanges[i + 1],
          displayRanges[i + 2],
          displayRanges[i + 3],
          displayRanges[i + 4],
          displayRanges[i + 5],
          displayRanges[i + 6],
        ]);
      }
    }
    this.setState({
      selected_date: dateNow,
      display_ranges: displayRangesInWeek,
    });
  };

  componentWillReceiveProps(nextProps) {}

  onMonthChange = (e) => {
    var selected = this.state.selected_date;
    if (e == 1) {
      if (selected.getMonth() == 11) {
        selected = new Date(selected.getFullYear() + 1, 0, 1);
      } else {
        selected = new Date(selected.getFullYear(), selected.getMonth() + 1, 1);
      }
    } else {
      if (selected.getMonth() == 0) {
        selected = new Date(selected.getFullYear() - 1, 11, 1);
      } else {
        selected = new Date(selected.getFullYear(), selected.getMonth() - 1, 1);
      }
    }
    this.loadCalendar(selected);
    if (this.props.onChange !== undefined) this.props.onChange(selected);
  };

  onDayChange = (e) => {
    var selected = this.state.selected_date;
    selected = new Date(selected.getFullYear(), selected.getMonth(), e);
    this.setState({ selected_date: selected });
    if (this.props.onChange !== undefined) this.props.onChange(selected);
  };

  renderItemOnly = (item) => {
    if (item == null) return <td className="unavailable"></td>;
    else {
      var dateNow = new Date();

      var dateSelected = this.state.selected_date;
      var cssClass = "available";
      if (dateSelected.getDate() == item) cssClass += " selected";
      else if (dateNow.getMonth() == dateSelected.getMonth() && dateNow.getMonth() == dateSelected.getMonth() && dateNow.getDate() == item)
        cssClass += " today";

      const isItemInData = this.props.data.some((subArray) =>
        Array.isArray(subArray) ? subArray.includes(item) : subArray === item
    );

    if (isItemInData)
        return (
          <td onClick={() => this.onDayChange(item)} className={cssClass}>
            <div className="diary-selected-container">
              {item}
              <div className="diary-selected-circle"></div>
            </div>
          </td>
        );
      else
        return (
          <td onClick={() => this.onDayChange(item)} className={cssClass}>
            {item}
          </td>
        );

      //if (this.props.data.indexOf(item) >= 0)
      //    cssClass += " contact-selected";
      //else if (dateNow.getMonth() == dateSelected.getMonth() && dateNow.getMonth() == dateSelected.getMonth() && dateNow.getDate() == item)
      //    cssClass += " today";
    }
  };

  render() {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return (
      <div>
        <table className="table table-bordered table-style table-responsive table-calendar">
          <thead>
            <tr>
              <th colSpan="2">
                <a onClick={() => this.onMonthChange(-1)}>
                  <span className="fa fa-arrow-left"></span>
                </a>
              </th>
              <th colSpan="3"> {monthNames[this.state.selected_date.getMonth()] + " - " + this.state.selected_date.getFullYear()}</th>
              <th colSpan="2">
                <a onClick={() => this.onMonthChange(1)}>
                  <span className="fa fa-arrow-right"></span>
                </a>
              </th>
            </tr>
            <tr>
              <th>S</th>
              <th>M</th>
              <th>T</th>
              <th>W</th>
              <th>T</th>
              <th>F</th>
              <th>S</th>
            </tr>
          </thead>
          <tbody>
            {this.state.display_ranges.map((item, index) => (
              <tr key={index}>
                {this.renderItemOnly(item[0])}
                {this.renderItemOnly(item[1])}
                {this.renderItemOnly(item[2])}
                {this.renderItemOnly(item[3])}
                {this.renderItemOnly(item[4])}
                {this.renderItemOnly(item[5])}
                {this.renderItemOnly(item[6])}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
