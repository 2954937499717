import React from "react";
import apiAgent from "../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { DataTableLoading } from "../../app/common/common-components/OMSDatatables";
import { OMSFavourite } from "../../app/common/common-components/OMSFavourite";
import { OMSDLTree } from "../../app/common/common-components/OMSDLTree";
import { OMSDLUpload } from "../../app/common/common-components/OMSDLUpload";
import bootbox from "bootbox";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";

export class FileLibraryBox extends React.Component {
  state = {
    data: [],
    total_size: "",
    view: 1,
    search: "",
    search_current: "",
    loading: false,
    selected: "",
    selectAllCheck: false,
    processing_delete: false,
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
    deleteData: {},
  };

  componentDidMount() {
    this.loadFSFilesFromServer(1, "", "");
  }

  loadFSFilesFromServer = (view, selected, search) => {
    //console.log("inside loadFSFilesFromServer");
    this.setState({ loading: true, data: [] });

    apiAgent.Library.fsFilesAjax(selected, search, view)
      .then((response) => {
        this.setState({
          data: response.data,
          total_size: response.total_size,
          view: view,
          selected: selected,
          selectAllCheck: false,
          search_current: search,
          loading: false,
        });
      })
      .catch((error) => {
        //toast.error(error.response.data))
      });
  };

  componentDidUpdate(prevProps, prevState) {}

  onDownloadFile = (fileid, fileName) => {
    apiAgent.Library.fsDownloadFileAjax(fileid)
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = downloadUrl;
        // const disposition = response.headers['content-disposition'];
        // const parts = disposition.split(';');
        // const filenamePart = parts.find(part => part.trim().startsWith('filename='));
        // const filenameWithExtension = filenamePart.split('=')[1].trim();
        // const extensionIndex = filenameWithExtension.lastIndexOf(".");
        // a.download = filenameWithExtension.substring(0, extensionIndex);
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Failed to download file:", error);
      });
  };

  onLibraryTypeChange = (e) => {
    //console.log("inside onLibraryTypeChange,view=", e.newValue);
    this.loadFSFilesFromServer(e.newValue, "", this.state.search_current);
  };
  onRenameFSFolder;
  onSearchChange = (e) => {
    if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(
      function () {
        if (this.state.search != this.state.search_current) {
          this.loadFSFilesFromServer(this.state.view, this.state.selected, this.state.search);
        }
      }.bind(this),
      1000
    );
    this.setState({ search: e.target.value });
  };

  onCreateFSFolder = (e) => {
    this.DLTree.onCreateFSFolder(e);
  };

  onRenameFSFolder = (e) => {
    //console.log("onRenameFSFolder");
    this.DLTree.onRenameFSFolder(e);
  };

  onDeleteFSFolder = (e) => {
    this.DLTree.onDeleteFSFolder(e);
  };

  onTreeSelectionChange = (selected) => {
    this.loadFSFilesFromServer(this.state.view, selected, this.state.search_current);
  };

  onFileUploadComplete = (e) => {
    //console.log("inside onFileUploadComplete");
    this.loadFSFilesFromServer(this.state.view, this.state.selected, this.state.search_current);
  };

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  onDeleteClick = (e) => {
    //console.log("inside onDeleteClick");
    var data = new FormData();
    data.append("type", this.state.view);
    var i = 0;
    let fileIDs = [];
    this.state.data.forEach(function (element) {
      if (element.selected) {
        fileIDs.push(element.fileID);
        i++;
      }
    }, this);

    data.append("fileIDs", JSON.stringify(fileIDs));

    //console.log();

    if (i > 0) {
      this.setState({ deleteData: data });
      // bootbox.confirm({
      //   title: "Delete files?",
      //   message: "Do you want to delete files now? This cannot be undone.",
      //   buttons: {
      //     cancel: {
      //       label: '<i class="fa fa-times"></i> Cancel',
      //     },
      //     confirm: {
      //       label: '<i class="fa fa-check"></i> Confirm',
      //     },
      //   },
      //   callback: function (result) {
      //     if (result) {
      //       this.executeDeleteClick(data);
      //     }
      //   }.bind(this),
      // });
      let title = "Delete files?";
      let body = "Do you want to delete files now? This cannot be undone.";
      let callback = this.executeDeleteClick;
      this.showPopupAndDeleteOnConfirmation(title, body, callback);
    }
  };

  //data parmeter needs to be checked
  executeDeleteClick = () => {
    this.setState({ processing_delete: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Library/FSDeleteFileAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                this.setState({ processing_delete: false });
                this.loadFSFilesFromServer(this.state.view, this.state.selected, this.state.search_current);
            }
            else {
                // do something.
            }
        }.bind(this);
        xhr.send(data); */

    var data = this.state.deleteData;
    var formData = Object.fromEntries(data);
    //console.log("inside executeDeleteClick, formData=", formData);
    apiAgent.Library.fsDeleteFileAjax(data)
      .then((response) => {
        this.setState({ processing_delete: false });
        this.loadFSFilesFromServer(this.state.view, this.state.selected, this.state.search_current);
      })
      .catch((error) => {
        //toast.error(error.response.data))
      });
  };

  onCheckAllChange = (e) => {
    //console.log("inside onCheckAllChange,this.state.data=", this.state.data);
    var selectAll = e.target.checked;
    var data = this.state.data;

    data.forEach(function (element) {
      element.selected = selectAll;
    }, this);
    this.setState({ data: data, selectAllCheck: selectAll });
  };

  onCheckChange = (fileid) => {
    var data = this.state.data;

    var selectAll = true;
    data.forEach(function (element) {
      if (element.fileID == fileid) {
        element.selected = !element.selected;
      }
      if (!element.selected) selectAll = false;
    }, this);
    this.setState({ data: data, selectAllCheck: selectAll });
  };

  render() {
    let showActionButtons = false;
    if (this.state.view == 1 && this.props.actions.indexOf("user-crud") >= 0) showActionButtons = true;
    else if (this.state.view == 2 && this.props.actions.indexOf("office-crud") >= 0) showActionButtons = true;
    else if (this.state.view == 3 && this.props.actions.indexOf("group-crud") >= 0) showActionButtons = true;
    else if (this.state.view == 0) showActionButtons = true;
    return (
      <div className="col-md-12">
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="libraryBoxLeftMenu">
                  <span>
                    <i className="fa fa-filter" aria-hidden="true"></i>
                  </span>
                  <OMSDropdown
                    id="LibraryTypeDD"
                    options={this.props.libraryDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.view}
                    onChange={this.onLibraryTypeChange}
                  />
                  {/*(showActionButtons) ?
                                        <div className="library_menu" style={{ display: 'inline-block' }}>
                                            <ul id="options">
                                                <li><a href="#" onClick={this.onCreateFSFolder}><img src="../Assets/images/folder-plus.png" style={{ width: 30, height: 30 }} /></a></li>
                                                <li><a href="#" onClick={this.onRenameFSFolder}><img src="../Assets/images/folder-rename.png" style={{ width: 30, height: 30 }} /></a></li>
                                                <li><a href="#" onClick={this.onDeleteFSFolder}><img src="../Assets/images/folder-delete.png" style={{ width: 30, height: 30 }} /></a></li>
                                            </ul>
                                        </div> : null */}
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="libraryBoxRightMenu">
                  <div className="has-feedback" style={{ marginRight: 10, display: "block", width: "100%" }}>
                    <input type="text" className="form-control" placeholder="Search" onChange={this.onSearchChange} />
                    <span
                      className={this.state.loading ? "fa fa-spin fa-spinner form-control-feedback" : "fa fa-search form-control-feedback"}
                      style={{ marginTop: 5, maxHeight: 20 }}
                    />
                  </div>
                  {showActionButtons ? (
                    <div style={{ display: "inline-block" }}>
                      <OMSDLUpload
                        id="fileUpload"
                        name="fileUpload"
                        librarytype={this.state.view}
                        parentid={this.state.selected}
                        onUploadComplete={this.onFileUploadComplete}
                        allowMultiple={true}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body table-responsive">
            <div className="row">
              <div className="col-md-3 mb-1">
                <OMSDLTree
                  id="DLTree"
                  allowAction={showActionButtons}
                  name="DLTree"
                  librarytype={this.state.view}
                  onSelectionChange={this.onTreeSelectionChange}
                  ref={(instance) => {
                    this.DLTree = instance;
                  }}
                />
              </div>
              <div className="col-md-9">
                <table id="myTable" className="table responsive-table-extended">
                  <thead>
                    <tr>
                      {showActionButtons ? (
                        <th style={{ width: "5%", minWidth: "15px" }}>
                          <input type="checkbox" checked={this.state.selectAllCheck} onChange={this.onCheckAllChange} />
                        </th>
                      ) : null}
                      <th style={{ width: showActionButtons ? "40%" : "45%" }} content="Filename">
                        Filename
                      </th>
                      <th style={{ width: "45%" }} content="Description">
                        Description
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          width: "10%",
                          minWidth: "25px",
                        }}
                        content="Size"
                      >
                        Size
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? (
                      <tr>
                        <td colSpan={showActionButtons ? "4" : "3"}>
                          <DataTableLoading loading={this.state.loading} />
                        </td>
                      </tr>
                    ) : (
                      this.state.data.map((item) => {
                        return [
                          <tr>
                            {showActionButtons ? (
                              <td data-title="">
                                <input type="checkbox" checked={item.selected} onChange={() => this.onCheckChange(item.fileID)} />
                              </td>
                            ) : null}
                            <td data-title="Filename">
                              <div className="library_grid_title">
                                <OMSFavourite favourite={item.favourite} /> &nbsp;
                                <a title="click to download this file" onClick={() => this.onDownloadFile(item.fileID, item.name)}>
                                  {" "}
                                  <i title={item.ext} className={"fa " + item.icon} aria-hidden="true"></i>
                                  &nbsp;{item.title}
                                </a>
                              </div>
                            </td>
                            <td data-title="Description">
                              <div
                                className="library_grid_description"
                                data-toggle="collapse"
                                data-target={"#" + item.fileID}
                                title="Expand/collapse full description"
                              >
                                <span>{item.description}</span>
                              </div>
                            </td>
                            <td data-title="Size">
                              <span className="label label-default">{item.size}</span>
                            </td>
                          </tr>,
                          <tr id={item.description != "" ? item.fileID : "cool"} className="collapse">
                            <td colSpan={showActionButtons ? "4" : "3"}>{item.description}</td>
                          </tr>,
                        ];
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-right">
                  <span>
                    Total usage: <span className="label label-default">{this.state.total_size}</span>
                  </span>
                  {showActionButtons ? (
                    <OMSButtonStyleL
                      style={{ marginLeft: 10 }}
                      type="button"
                      ui_icon="fa-trash-o"
                      ui_type="delete"
                      ui_text="Delete files"
                      ui_processing_text="Deleting.."
                      processing={this.state.processing_delete}
                      disabled={this.state.processing_delete}
                      onClick={this.onDeleteClick}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
