import React from "react";
import PropTypes from "prop-types";

const ReportFilters = ({ filters, handleFilterOptionClick }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <label>Select criteria to filter on</label>
        </div>
      </div>
      {filters.map((filterRow, rowIndex) => (
        <div className="row" style={{ marginTop: 10 }} key={rowIndex}>
          {filterRow.map((filter, colIndex) => (
            <div className="col-md-3 col-sm-6 col-xs-6" key={colIndex}>
              <span className="multiselect-color" onClick={() => handleFilterOptionClick(filter.name)}>
                <span className="color-preview">
                  {filter.value ? <i className="fa fa-check" aria-hidden="true" style={{ color: "black" }}></i> : null}
                </span>
                <span className="color-label">{filter.label}</span>
              </span>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

ReportFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  handleFilterOptionClick: PropTypes.func.isRequired,
};

export default ReportFilters;
