import React from "react";
import { toast } from "react-toastify";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../app/api/apiAgent";
import { withRouter } from 'react-router-dom';

class ImportGrid extends React.Component {
  state = {
    loading: false,
    source: "1",
    sourcetype: "1",
    propdata_listingfile: null,
    p24_listingfile: null,
    p24_imagefile: null,
    p24_agentfile: null,
    p24_simplifiedfile: null,
    officeid: "",
    importData: [],
  };

  loadImportFromServer = () => {
    this.setState({ loading: true });
    apiAgent.Import.getImportData()
      .then((response) => {
        //console.log("import data", this.state.importData);
        this.setState({
          importData: response.data,
        });
        //console.log("import Data", response.data);
      })
      .catch((error) => {
        //toast.error(error.response.data))
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.loadImportFromServer();
  }

  handleRowClick = (data) => {
    this.props.history.push('/Import/Properties?ImportID=' + data.importID);
  };

  onNewImportClick = (e) => {
    this.setState(
      {
        source: "1",
        sourcetype: "1",
        propdata_listingfile: null,
        p24_listingfile: null,
        p24_imagefile: null,
        p24_agentfile: null,
        p24_simplifiedfile: null,
        officeid: "",
      },
      function () {
        this.setState({ processing: false });
        window.$("#importFile").val("");
        window.$("#importModal").modal("show");
      }
    );
  };

  handleSourceChange = (e) => {
    if (e.target.value == "1") this.setState({ source: "1", sourcetype: "1" });
    else this.setState({ source: "2", sourcetype: "6" });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleOfficeIDChange = (e) => {
    this.setState({ officeid: e.newValue });
  };

  onChooseFileClick = (type) => {
    this.setState({ upload_type: type }, function () {
      document.getElementById("importFile").click();
    });
  };

  onUploadFileChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    //console.log("inside on upload file in import grid, files:",files);
    if (files[0] !== undefined) {
      if (files[0].name.match(/\.(csv)$/)) {
        //$('#importModal').modal('show');
        if (this.state.upload_type == "1") {
          this.setState({ propdata_listingfile: files[0] });
        } else if (this.state.upload_type == "2") {
          this.setState({ p24_listingfile: files[0] });
        } else if (this.state.upload_type == "3") {
          this.setState({ p24_imagefile: files[0] });
        } else if (this.state.upload_type == "4") {
          this.setState({ p24_agentfile: files[0] });
        } else if (this.state.upload_type == "5") {
          this.setState({ p24_simplifiedfile: files[0] });
        }
        window.$("#importFile").val("");
      } else {
        toast.info("Please upload valid .csv file");
      }
    }
  };

  onUploadImportClick = (e) => {
    if (this.state.source == "1") {
      if (this.state.propdata_listingfile == null) {
        toast.info("Please select listing file(.csv)");
        return;
      }
    } else if (this.state.source == "2") {
      if (this.state.p24_listingfile == null) {
        toast.info("Please select listing file(.csv)");
        return;
      }

      if (this.state.p24_imagefile == null) {
        toast.info("Please select listing image file(.csv)");
        return;
      }

      if (this.state.p24_agentfile == null) {
        toast.info("Please select agent file(.csv)");
        return;
      }

      if (this.state.p24_simplifiedfile == null) {
        toast.info("Please select simplified file(.csv)");
        return;
      }

      if (this.state.officeid == null) {
        toast.info("Please select office");
        return;
      }
    }

    var data = new FormData();
    data.append("Source", this.state.source);
    data.append("SourceType", this.state.sourcetype);
    data.append("OfficeID", this.state.officeid);
    if (this.state.source == "1") {
      data.append("PropDataFile", this.state.propdata_listingfile);
    } else {
      data.append("P24ListingFile", this.state.p24_listingfile);
      data.append("P24ListingImageFile", this.state.p24_imagefile);
      data.append("P24AgentFile", this.state.p24_agentfile);
      data.append("P24SimplifiedFile", this.state.p24_simplifiedfile);
    }

    this.setState({ processing: true });

    /* var xhr = new XMLHttpRequest();
    xhr.open("post", "/Import/ImportFileAjax", true);
    xhr.onload = function () {
      window.$("#importModal").modal("hide");
      var data = JSON.parse(xhr.responseText);
      if (data.status == "success") {
        this.setState({ processing: false });
        window.location.reload();
      } else {
        toast.info(data.message);
      }
    }.bind(this);
    xhr.send(data); */

    apiAgent.Import.importFileAjax(data)
            .then((response) => {
              if(response.status == "success"){
                window.$("#importModal").modal("hide");
                this.setState({ processing: false });
                window.location.reload();
              }
              else{
                this.setState({ processing: false });
                toast.error(response.message);
              }
            })
            .catch((error) => {
                this.setState({ processing: false });
                toast.error(error.message);
            })
  };
  
  render() {
    function formatImportStatus(status) {
      if (status == 2) {
        return "<span><i class='fa fa-check-circle' style='color: green'></i></span>";
      } else if (status == 4) {
        return "<span><i class='fa fa-exclamation-triangle' style='color: red'></i></span>";
      } else if (status == 3) {
        return "<span><i class='fa fa-clock-o' style='color: orange'></i></span>";
      } else if (status == -1) {
        return "<span><i class='fa fa-lock' style='color: black'></i></span>";
      } else if (status == -2) {
        return "<span><i class='fa fa-diamond' style='color: black'></i></span>";
      } else {
        return "<span><i class='fa fa-minus-circle' style='color: red'></i></span>";
      }
    };

    var columns = [
      { data: "status", title: "Status" },
      { data: "user", title: "User" },
      { data: "importSource", title: "Source" },
      { data: "uploadStatus", title: "Int. Log" },
      { data: "uploadedDate", title: "UploadedDate" },
      { data: "processedDate", title: "ProcessedDate" },
    ];

    var columnDefs = [
      {
        render: function (data, type, row) {
          return formatImportStatus(data);
        },
        targets: 0,
      },
    ];

    return (
      <div className="col-md-12">
        <div className="panel panel-default panel-table oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline form-group">
                  <label>
                    <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pull-right">
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="create"
                    ui_text="new import"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onNewImportClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body table-responsive">
            {/* <DataTableCompDynamic id="importTable" Url="/Import/IndexAjax" columns={columns} columnDefs={columnDefs} serverSide={false} onRowClick={this.handleRowClick} ExtraParams="" /> */}
            <DataTableComp
              id="importTable"
              data={this.state.importData}
              columns={columns}
              columnDefs={columnDefs}
              //order={[[0, "desc"]]}
              onRowClick={this.handleRowClick}
            />
            <DataTableLoading loading={this.state.loading} />
          </div>
        </div>
        <input id="importFile" type="file" accept=".csv" style={{ display: "none" }} onChange={this.onUploadFileChange} />
        <div id="importModal" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title">Upload a import file?</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="source">Source</label>
                    <select id="source" name="source" value={this.state.source} onChange={this.handleSourceChange} className="form-control">
                      <option value="1">PropData</option>
                      <option value="2">P24</option>
                    </select>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="sourcetype">Source Type</label>
                    <select id="sourcetype" name="sourcetype" value={this.state.sourcetype} onChange={this.handleChange} className="form-control">
                      {this.state.source == "1" ? <option value="1">Residential</option> : null}
                      {this.state.source == "1" ? <option value="4">Commercial</option> : null}
                      {this.state.source == "1" ? <option value="5">Residential(Inactive)</option> : null}
                      {this.state.source == "2" ? <option value="6">Listing</option> : null}
                    </select>
                  </div>
                  {this.state.source == 1 ? (
                    <div className="form-group col-md-12">
                      <label htmlFor="propdata_listingfile">Upload listing file(.csv)</label> <br />
                      {this.state.propdata_listingfile != null ? (
                        <span style={{ color: "green" }}>
                          <i className="fa fa-check-circle"></i>&nbsp;
                        </span>
                      ) : null}
                      <a style={{ cursor: "pointer" }} onClick={() => this.onChooseFileClick(1)}>
                        Choose File
                      </a>
                    </div>
                  ) : (
                    <div>
                      <div className="form-group col-md-12">
                        <label htmlFor="OfficeID">Office</label>
                        <OMSDropdown
                          id="OfficeID"
                          options={this.props.officeDD}
                          valueField="value"
                          labelField="text"
                          value={this.state.officeid}
                          onChange={this.handleOfficeIDChange}
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="p24_listingfile">Upload listing file(.csv)</label> <br />
                        {this.state.p24_listingfile != null ? (
                          <span style={{ color: "green" }}>
                            <i className="fa fa-check-circle"></i>&nbsp;
                          </span>
                        ) : null}
                        <a style={{ cursor: "pointer" }} onClick={() => this.onChooseFileClick(2)}>
                          Choose File
                        </a>
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="p24_imagefile">Upload listing image file(.csv)</label> <br />
                        {this.state.p24_imagefile != null ? (
                          <span style={{ color: "green" }}>
                            <i className="fa fa-check-circle"></i>&nbsp;
                          </span>
                        ) : null}
                        <a style={{ cursor: "pointer" }} onClick={() => this.onChooseFileClick(3)}>
                          Choose File
                        </a>
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="p24_agentfile">Upload listing agent file(.csv)</label> <br />
                        {this.state.p24_agentfile != null ? (
                          <span style={{ color: "green" }}>
                            <i className="fa fa-check-circle"></i>&nbsp;
                          </span>
                        ) : null}
                        <a style={{ cursor: "pointer" }} onClick={() => this.onChooseFileClick(4)}>
                          Choose File
                        </a>
                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="p24_simplifiedfile">Upload simplified file(.csv)</label> <br />
                        {this.state.p24_simplifiedfile != null ? (
                          <span style={{ color: "green" }}>
                            <i className="fa fa-check-circle"></i>&nbsp;
                          </span>
                        ) : null}
                        <a style={{ cursor: "pointer" }} onClick={() => this.onChooseFileClick(5)}>
                          Choose File
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <OMSButtonStyleL
                  type="button"
                  style={{ marginRight: 10 }}
                  ui_icon="fa-upload"
                  ui_type="save"
                  ui_text="Import"
                  ui_processing_text="Processing.."
                  processing={this.state.processing}
                  disabled={this.state.processing}
                  onClick={this.onUploadImportClick}
                />
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ImportGrid);