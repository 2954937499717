import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
import { useEffect, useState } from "react";
import { LibraryResponseModel } from "../../app/models/LibraryResponseModel";
import { NoticeboardModel } from "../../app/models/NoticeboardModel";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { FileLibraryBox } from "./FileLibraryBox";
import ContentsGrid from "./ContentsGrid";
import TinyMCEEditor from "../../app/common/common-components/TinyMCEEditor";
import { useHistory } from 'react-router-dom';

interface Props {
  data?: LibraryResponseModel;
}

function LibraryBox(props: Props) {
  const getViewDefault = () => {
    if (props.data!.isNews) return 2;
    else if (props.data!.isNoticeboard && props.data!.content_access) return 3;
    else return 1;
  };

  const [view, setView] = useState(getViewDefault());
  const [officeID, setOfficeID] = useState("");
  const [officeSyncID, setOfficeSyncID] = useState("");
  const [content, setContent] = useState("");
  const [contentFile, setContentFile] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [processing_save, setprocessing_save] = useState(false);
  const [noticeBoardContent, setNoticeBoardContent] = useState("");

  let history = useHistory();

  useEffect(() => {
    //console.log("useEffect LibraryBox", props.data);
    //  tinymce.init({
    //         selector: 'textarea.editor',
    //         height: 500,
    //         theme: 'modern',
    //         plugins: 'code print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools contextmenu colorpicker textpattern help',
    //         toolbar1: 'code fullpage formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
    //         image_advtab: true,
    //         extended_valid_elements: "script[language|type|async|src|charset]",
    //         images_upload_handler: function (blobInfo: any, success: any, failure: any) {

    //             var data = new FormData();
    //             data.append('file', blobInfo.blob(), blobInfo.filename());

    //             var xhr = new XMLHttpRequest();
    //             xhr.open('post', '/Library/UploadLibraryImageAjax', true);
    //             xhr.onload = function () {
    //                 if (xhr.status != 200) {
    //                     failure('HTTP Error: ' + xhr.status);
    //                     return;
    //                 }
    //                 var json = JSON.parse(xhr.responseText);
    //                 if (!json || typeof json.location != 'string') {
    //                     failure('Invalid JSON: ' + xhr.responseText);
    //                     return;
    //                 }
    //                 success(json.location);
    //             }
    //             xhr.send(data);

    //         }
    //     });

    if (view == 3) onChangeView(3);
  }, []);

  const handleUploadFile = (blobInfo: any, progress: any) => {
    return new Promise((resolve, reject) => {
      

      let data = new FormData();
      data.append("fileBase64String", blobInfo.base64());
      data.append("fileName", blobInfo.filename());

      let formData = Object.fromEntries(data.entries());

      apiAgent.Library.uploadLibraryImageAjax(formData)
        .then((response) => {
          
          if (response.success) {
            resolve(response.location);
          } else {
            reject("Image upload failed");
          }
          
        })
        .catch((error) => {
          console.log("upload error", error);
          reject("Image upload failed");
        });    
    });
  };



  const onChangeView = (view: any) => {
    setView(view);
    if (view == 3) {
      apiAgent.Library.getNoticeboardAjax()
        .then((response) => {
          //console.log("getNoticeboardAjax", response);
          //tinyMCE.activeEditor.setContent(response.content);
          setNoticeBoardContent(response.content);
          setOfficeID(response.officeID);
          setOfficeSyncID(response.officeSyncID);
          setContent("");
          setContentFile(response.contentFile);
          setLastUpdated(response.lastUpdated);
          setIsLoaded(true);
        })
        .catch((error) => {
          //toast.error(error.response.data))
        });
    }
  };

  const doSave = () => {
    //e.preventDefault();

    var data = new FormData();

    data.append("Content", noticeBoardContent);
    data.append("ContentFile", contentFile);
    data.append("OfficeID", officeID);
    data.append("OfficeSyncID", officeSyncID);
    const formDataObj = Object.fromEntries(data.entries());
    //console.log("doSave", formDataObj);

    //this.setState({ processing_save: true });
    setprocessing_save(true);
    //data.
    apiAgent.Library.SaveNoticeboardAjax(formDataObj)
      .then((response) => {
        setprocessing_save(false);
        setIsLoaded(true);
        toast.info(response.message);
        //window.location.href = "/Home/Index";
        history.push("/Home/Index");
      })
      .catch((error) => {
        setprocessing_save(false);
        toast.info("Fail");
      });

  };

  const doOldSave = (values: NoticeboardModel) => {
    // values.content = tinyMCE.activeEditor.getContent();
    //console.log("doSave", values);
    setprocessing_save(true);

    apiAgent.Library.SaveNoticeboardAjax(values)
      .then((response) => {
        setprocessing_save(false);
        setIsLoaded(true);
        toast.info(response.message);
        //window.location.href = "/Home/Index";
        history.push("/Home/Index");
      })
      .catch((error) => {
        setprocessing_save(false);
        toast.info("Fail");
      });
  };

  const handleNoticeboardContentChange = (content: string) => {
    //console.log("handleNoticeboardContentChange", content);
    setNoticeBoardContent(content);
  };

  return (
    <div>
      <br />
      <div className="row">
        <div className="col-md-2 hidden-xs hidden-sm">
          <span style={{ cursor: "pointer", display: "inline-block", margin: 5 }} onClick={() => onChangeView("1")}>
            <i className="fa fa-file-text-o" style={{ marginRight: 8 }}></i>
            {view == 1 ? <b>File library</b> : <span>File library</span>}
          </span>
          <br />
          <span style={{ cursor: "pointer", display: "inline-block", margin: 5 }} onClick={() => onChangeView("2")}>
            <i className="fa fa-file-word-o" style={{ marginRight: 8 }}></i>
            {view == 2 ? <b>Website news</b> : <span>Website news</span>}
          </span>
          <br />
          {props.data!.content_access ? (
            <span style={{ cursor: "pointer", display: "inline-block", margin: 5 }} onClick={() => onChangeView("3")}>
              <i className="fa fa-bullhorn" style={{ marginRight: 8 }}></i>
              {view == 3 ? <b>Notice board</b> : <span>Notice board</span>}
            </span>
          ) : null}
        </div>
        <div className="col-sm-12 hidden-md hidden-lg" style={{ marginBottom: 10 }}>
          <span
            style={{
              cursor: "pointer",
              display: "inline-block",
              marginRight: 10,
            }}
            onClick={() => onChangeView("1")}
          >
            <i className="fa fa-file-text-o" style={{ marginRight: 5 }}></i>
            {view == 1 ? <b>File library</b> : <span>File library</span>}
          </span>
          <span
            style={{
              cursor: "pointer",
              display: "inline-block",
              marginRight: 10,
            }}
            onClick={() => onChangeView("2")}
          >
            <i className="fa fa-file-word-o" style={{ marginRight: 5 }}></i>
            {view == 2 ? <b>Website news</b> : <span>Website news</span>}
          </span>
          {props.data!.content_access ? (
            <span style={{ cursor: "pointer", display: "inline-block" }} onClick={() => onChangeView("3")}>
              <i className="fa fa-bullhorn" style={{ marginRight: 5 }}></i>
              {view == 3 ? <b>Notice board</b> : <span>Notice board</span>}
            </span>
          ) : null}
        </div>
        <div className="col-md-10 col-sm-12">
          <div style={{ display: view == 1 ? "" : "none" }}>
            <FileLibraryBox actions={props.data!.actions} libraryDD={props.data!.libraryDD} />
          </div>
          {view == 2 && <ContentsGrid content_access={props.data!.content_access} ContentCategories={props.data!.contentCategories} />}
          <div style={{ display: view == 3 ? "" : "none" }}>
            <div className="col-md-12">
              <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-md-12">
                      <span>
                        <i className="fa fa-bullhorn" aria-hidden="true"></i>
                        &nbsp;
                      </span>
                      <label>Notice board &nbsp;</label>
                      <span>{lastUpdated ? "- last updated " + lastUpdated : null}</span>
                    </div>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        {/* <textarea rows={5} className="form-control editor" id="Content" name="Content" /> */}
                        {view == 3 && (
                          <TinyMCEEditor value={noticeBoardContent} onEditorChange={handleNoticeboardContentChange} handleUpload={handleUploadFile} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="pull-right">
                        <OMSButtonStyleL
                          type="button"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Save"
                          ui_processing_text="Saving.."
                          processing={processing_save}
                          onClick={doSave}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LibraryBox;
