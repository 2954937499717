import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { ContentModel } from "../../app/models/ContentModel";
import { ConfirmationModalProps } from "../../app/models/ModalControlProps/ConfirmationModalProps";
import { useStore } from "../../app/stores/store";
import HeaderWrapper from "../header/HeaderWrapper";
import ContentBox from "./ContentBox";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";

export default observer(function SaveContentPage() {
  let varConfirmModalProps: ConfirmationModalProps = {
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  };

  const [content, setContent] = useState<ContentModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>(varConfirmModalProps);
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let contentId: string = "";
    let officeId: string = "";
    if (params.has("ContentID")) {
      const id = params.get("ContentID");
      contentId = id !== null ? id : "";
    }
    if (params.has("OfficeID")) {
      const id2 = params.get("OfficeID");
      officeId = id2 !== null ? id2 : "";
    }

    setIsLoading(true);
    apiAgent.Library.saveContent(contentId, officeId)
      .then((response: ContentModel) => {
        let oContent: ContentModel = response;
        setContent(oContent);

        //console.log("content", oContent);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save Content";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && content) {
    return (
      <>
        <HeaderWrapper />

        <div className="save-content-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Library", path: "/library" },
              { text: !content.contentID ? "Create" : "Save"}
            ]}
          />
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <ContentBox
                data={content}
                content_access={content.content_access}
                ContentTags={content.contentTags}
                ContentCategories={content.contentCategories}
                setConfirmationModalProps={setConfirmationModalProps}
              />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
