import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiAgent from "../../../app/api/apiAgent";

//import LoadingComponent from "../../../app/layout/LoadingComponent";

import { CountryModel } from "../../../app/models/CountryModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import CountryBox from "./CountryBox";
// import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function SaveCountry() {
  let varConfirmModalProps: ConfirmationModalProps = {
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  };

  const [country, setCountry] = useState<CountryModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>(varConfirmModalProps);
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let countryName: string = "";
    if (params.has("name")) {
      const name = params.get("name");
      countryName = name !== null ? name : "";
    }

    setIsLoading(true);
    apiAgent.Setting.saveCountry(countryName)
      .then((response: CountryModel) => {
        let oCountry: CountryModel = response;
        setCountry(oCountry);

        //console.log("country", oCountry);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save Country";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && country) {
    return (
      <>
        <HeaderWrapper />

        <div className="save-country container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Countries", path: "/settings/countries" },
              { text: !country.name ? "Create" : country.name}
            ]}
          />
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              {/* <ConfirmationModalPopup {...confirmationModalProps} />                */}

              {/* <CountryBox data={country} iscreate ={!country.name ? true : false}/> */}

              <CountryBox data={country} iscreate={!country.name ? true : false} setConfirmationModalProps={setConfirmationModalProps} />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
