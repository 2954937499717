import React, { useState } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import bootbox from "bootbox";
import { ReportModel } from "../../../app/models/ReportModel";
import { Field, Formik } from "formik";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import { useHistory } from 'react-router-dom';

interface Props {
  data?: ReportModel;
  setConfirmationModalProps: (confirmationModalProps: ConfirmationModalProps) => void;
}

function ReportBox(props: Props) {
  const [reportID, setReportID] = useState(props.data!.reportID);
  const [groupID, setGroupID] = useState(props.data!.groupID);
  const [officeID, setOfficeID] = useState(props.data!.officeID);
  const [isGeneric, setIsGeneric] = useState(props.data!.isGeneric);
  const [reportName, setReportName] = useState(props.data!.reportName);
  const [reportLocation, setReportLocation] = useState(props.data!.reportLocation);
  const [reportDescription, setReportDescription] = useState(props.data!.reportDescription);
  const [categories, setCategories] = useState(props.data!.categories);
  const [reportFile, setReportFile] = useState(props.data!.reportFile);
  const [buttonStatus, setButtonStatus] = useState({
    disabled: false,
    processing_save: false,
    processing_delete: false,
  });

  let history = useHistory();

  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>({
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  });

  const openCloseDeleteModal = (isOpen: boolean) => {
    let varConfirmationModalProps = confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const showPopupAndDeleteOnConfirmation = (title: string, body: string, callback: Function) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed: boolean) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const handleStatusChanged = (e: any) => {
    setIsGeneric(!isGeneric);
  };

  const handleOfficeIdChange = (e: any) => {
    setOfficeID(e.newValue);
  };

  const handleGroupIdhange = (e: any) => {
    setGroupID(e.newValue);
  };

  const onCancelClick = (e: any) => {
    history.push("/Admin/Reports");
  };

  const executeDeleteClick = (e: any) => {
    setButtonStatus({
      disabled: true,
      processing_save: false,
      processing_delete: true,
    });

    apiAgent.Admin.deleteReportAjax(reportID)
      .then((response) => {
        history.push("/Admin/Reports");
      })
      .catch((error) => {
        toast.info(error.message);
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const onDeleteClick = (e: any) => {
    /* bootbox.confirm({
            title: "Delete report?",
            message: "Do you want to delete report now? This cannot be undone.",
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancel'
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Confirm'
                }
            },
            callback: function (result: any) {
                if (result) {
                    executeDeleteClick(result);
                }
            }
        }); */
    let title = "Delete report?";
    let body = "Do you want to delete report now? This cannot be undone.";
    let callback = executeDeleteClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const doValidate = (values: ReportModel) => {
    let { reportName, reportDescription, reportID, categories, isGeneric, reportFile } = values;
    var failed = false;

    if (!reportName.trim()) {
      toast.info("Please enter report display name");
      failed = true;
    }

    if (!reportDescription.trim()) {
      toast.info("Please enter report description");
      failed = true;
    }

    if (reportID == "" && reportFile == null) {
      toast.info("Please choose report file");
      failed = true;
    }

    if (!isGeneric) {
      if (officeID == "" && groupID == "") {
        toast.info("Office or group selection required for non generic report");
        failed = true;
      }
    }

    return !failed;
  };

  const handleSubmit = (values: ReportModel) => {
    /* var fileUpload = window.$("#reportFile").get(0);
    var files = fileUpload.files; */
    //console.log("inside Report Box, handleSubmit", values);
    if (!doValidate(values)) return;

    values.officeID = officeID;
    values.groupID = groupID;
    values.isGeneric = isGeneric;

    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    var data = new FormData();
    data.append('ReportID',values.reportID);
    data.append('ReportName',values.reportName.trim());
    data.append('ReportDescription',values.reportDescription.trim());
    data.append('ReportLocation',values.reportLocation.trim());
    data.append('GroupID',values.groupID);
    data.append('OfficeID',values.officeID);
    data.append('IsGeneric',values.isGeneric.toString());

    if (values.reportFile != null)
        data.append('ReportFile', values.reportFile);


    for (var i = 0; i < values.categories.length; i++) {
        data.append('Categories[' + i + ']', values.categories[i]);
    }


    apiAgent.Admin.saveReportAjax(data)
      .then((response) => {
        history.push("/Admin/Reports");
      })
      .catch((error) => {
        toast.info(error.message);
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const OnCategoryClick = (name: any) => {
    var Categories = categories;
    var index = Categories.indexOf(name);
    if (index != -1) Categories.splice(index, 1);
    else Categories.push(name);

    setCategories(Categories);
  };

  const onChooseFileClick = () => {
    document.getElementById("reportFile")!.click();
  };


  return (
    <Formik
      initialValues={{
        reportID: props.data!.reportID,
        groupID: props.data!.groupID,
        officeID: props.data!.officeID,
        isGeneric: props.data!.isGeneric,
        reportName: props.data!.reportName,
        reportLocation: props.data!.reportLocation,
        categories: props.data!.categories,
        reportDescription: props.data!.reportDescription,
        officeDD: props.data!.officeDD,
        groupDD: props.data!.groupDD,
        reportFile: props.data!.reportFile,
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, errors, values,setFieldValue }) => (
        <form className="SaveReportForm" onSubmit={handleSubmit}>
          <ConfirmationModalPopup {...confirmationModalProps} />
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <label>{reportID != "" ? reportName : "CREATE"}</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="form-group col-md-12">
                  <label htmlFor="propdata_listingfile">
                    Report File <small>(Upload report file(.trdp))</small>
                  </label>{" "}
                  <br />
                  {values.reportFile != null || reportID != "" ? (
                    <span style={{ color: "green" }}>
                      <i className="fa fa-check-circle"></i>&nbsp;
                    </span>
                  ) : null}
                  <a style={{ cursor: "pointer" }} onClick={() => onChooseFileClick()}>
                    Choose File
                  </a>
                  <input id="reportFile" type="file" accept=".trdp" style={{ display: "none" }} onChange={(e)=>setFieldValue("reportFile",e.target.files![0])} />
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="ReportName">
                    Report DisplayName
                  </label>
                  <div className="controls">
                    <Field type="text" name="reportName" className="form-control required-control" placeholder="Report Name" />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="ReportName">
                    Report Description
                  </label>
                  <div className="controls">
                    <Field type="text" name="reportDescription" className="form-control required-control" placeholder="Report Description" />
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="Categories">
                    Categories
                  </label>
                  <div className="controls">
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="users" checked={categories.indexOf("users") != -1} onClick={() => OnCategoryClick("users")} /> Users
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="office" checked={categories.indexOf("office") != -1} onClick={() => OnCategoryClick("office")} /> Office
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="listings" checked={categories.indexOf("listings") != -1} onClick={() => OnCategoryClick("listings")} />{" "}
                        Listings
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="contacts" checked={categories.indexOf("contacts") != -1} onClick={() => OnCategoryClick("contacts")} />{" "}
                        Contacts
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="deals" checked={categories.indexOf("deals") != -1} onClick={() => OnCategoryClick("deals")} />{" "}
                        Deals
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label htmlFor="IsGeneric">IsGeneric (available for all)</label>
                  <div className="controls">
                    <label className="switch">
                      <Field type="checkbox" name="isGeneric" checked={isGeneric} onChange={handleStatusChanged} />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="form-group col-md-12" style={{ display: isGeneric ? "none" : "block" }}>
                  <label htmlFor="GroupID">Group</label>
                  <div className="controls">
                    <OMSDropdown
                      id="groupID"
                      options={props.data!.groupDD}
                      valueField="value"
                      labelField="text"
                      value={groupID}
                      onChange={handleGroupIdhange}
                    />
                  </div>
                </div>
                <div
                  className="form-group col-md-12"
                  style={{
                    display: isGeneric || groupID != "" ? "none" : "block",
                  }}
                >
                  <label htmlFor="OfficeID">Office</label>
                  <div className="controls">
                    <OMSDropdown
                      id="officeID"
                      options={props.data!.officeDD}
                      valueField="value"
                      labelField="text"
                      value={officeID}
                      onChange={handleOfficeIdChange}
                    />
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-left">
                      {reportID != "" ? (
                        <div className="dropup">
                          <button className="btn btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                            More..
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu" style={{ width: "auto" }}>
                            <li>
                              {" "}
                              <OMSButtonStyleL
                                type="button"
                                ui_icon="fa-trash-o"
                                ui_type="delete"
                                ui_text="Delete report"
                                ui_processing_text="Deleting.."
                                processing={buttonStatus.processing_delete}
                                disabled={buttonStatus.disabled}
                                onClick={onDeleteClick}
                              />
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={buttonStatus.disabled}
                        onClick={onCancelClick}
                      />
                      <OMSButtonStyleL
                        type="submit"
                        ui_icon="fa-check"
                        ui_type="save"
                        ui_text="Save"
                        ui_processing_text="Saving.."
                        processing={buttonStatus.processing_save}
                        disabled={buttonStatus.disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default ReportBox;
