import { Link } from "react-router-dom";

function OMSActionButtonUrl(props) {
  if (props.progress) {
    return (
      <a className="omsactionbutton omsactionbutton_hovered">
        <span className={"fa " + props.ui_icon + " fa-5x"}></span>
        <br />
        <label>{props.ui_header}</label>
        <p>{props.ui_description}</p>
        <p>{"Loading......"}</p>
      </a>
    );
  }
  return (
    <Link to={props.url} className="omsactionbutton">
      <span className={"fa " + props.ui_icon + " fa-5x"}></span>
      <br />
      <label>{props.ui_header}</label>
      <p>{props.ui_description}</p>
    </Link>
  );
}

export default OMSActionButtonUrl;
