import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EventTypeAutocomplete, OMSDropdown } from "../OMSDropdown";
import { OMSButtonStyleL2 } from "../OMSButton";
import { OMSDLUpload } from "../OMSDLUpload";
import { OMSLeadModel } from "../OMSInboxComponents/OMSLeadModel";
import { OMSActivityTimeline } from "../OMSActivityTimeline";
import { OMSCalendar } from "../OMSCalendar";
import { DataTableLoading } from "../OMSDatatables";

import PropTypes from "prop-types";
import apiAgent from "../../../api/apiAgent";
import DatePicker from "react-datepicker";
import $ from "jquery";
import bootbox from "bootbox";
const moment = require("moment");

export class ActivityFilter extends React.Component {
  static propTypes = {
    DiaryActionTags: PropTypes.array,
    DNType: PropTypes.number,
    ID: PropTypes.string,
    selectEvents: PropTypes.func,
  };

  state = {
    tags: [],
    value: "",
    showDropdown: false,
    initialTags: this.props.DiaryActionTags,
    filterOptions: this.props.DiaryActionTags,
    selectedTags: [],
  };

  onInputChange = (value) => {
    var allOptions = [];
    if (this.state.selectedTags) {
      var selected = this.state.selectedTags.map((tag) => tag.name.toLowerCase());
      allOptions = this.state.initialTags.filter((tag) => selected.indexOf(tag.name.toLowerCase()) === -1);
    } else {
      allOptions = this.state.initialTags;
    }
    var filterOpt = value ? allOptions.filter((tag) => tag.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) : allOptions;
    this.setState({ ...this.state, value, filterOptions: filterOpt });
  };

  selectTag = (tagData) => {
    var selectedTags = [...this.state.selectedTags, tagData];
    var selectedTagNames = selectedTags.map((tag) => tag.name.toLowerCase());
    var filterOptions = this.state.initialTags.filter((tag) => selectedTagNames.indexOf(tag.name.toLowerCase()) === -1);
    this.props.selectEvents(selectedTags);
    this.setState({ ...this.state, selectedTags, value: "", filterOptions });
  };

  unselectTag = (tagData) => {
    var selectedTags = this.state.selectedTags.filter((tag) => tag.name.toLowerCase() !== tagData.name.toLowerCase());
    var selectedTagNames = selectedTags.map((tag) => tag.name.toLowerCase());
    var filterOptions = this.state.initialTags.filter((tag) => selectedTagNames.indexOf(tag.name.toLowerCase()) === -1);
    this.props.selectEvents(selectedTags);
    this.setState({
      ...this.state,
      selectedTags,
      filterOptions,
    });
  };

  render() {
    return (
      <div className="filter-root">
        <div className="filter-wrapper">
          {this.state.selectedTags.map((tag) => (
            <div className="tag-chip">
              <div className="tag-name">{tag.name}</div>
              <div onClick={() => this.unselectTag(tag)}>&#10005;</div>
            </div>
          ))}
          <input
            type="text"
            className="filter-input"
            placeholder="Filter events..."
            value={this.state.value}
            onChange={(e) => this.onInputChange(e.target.value)}
            onFocus={() => this.setState({ ...this.state, showDropdown: true })}
            onBlur={() => this.setState({ ...this.state, showDropdown: false })}
          />
        </div>
        <div className="filter-dropdown" style={{ display: this.state.showDropdown ? "block" : "none" }}>
          <ul className="filter-dd-menu">
            {this.state.filterOptions
              .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0))
              .map((tag, index) => (
                <li onMouseDown={() => this.selectTag(tag)} key={`${tag.name}|${index}`}>
                  {tag.name}
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}
