import React from "react";
import PropTypes from "prop-types";
const moment = require("moment");

export class OMSPinnedNoteItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onActionFunc: PropTypes.func,
    ReadOnly: PropTypes.bool,
    togglePinNote: PropTypes.func.isRequired,
    setDragging: PropTypes.func,
    setDraggedOver: PropTypes.func,
    onDrop: PropTypes.func,
    draggingId: PropTypes.string,
  };

  state = { isDescExpanded: false };

  onItemClick = (diaryID) => {
    this.props.onActionFunc(diaryID, false);
  };

  getDescription = (description) => {
    if (this.state.isDescExpanded) {
      return description;
    } else {
      return description.length > 100 ? description.slice(0, 100) + "..." : description;
    }
  };

  toggleReadOnlyDesc = () => {
    this.setState({
      ...this.state,
      isDescExpanded: !this.state.isDescExpanded,
    });
  };

  unpinNote = () => {
    var changedItem = this.props.item;
    changedItem.pinned = false;
    this.props.togglePinNote(changedItem);
  };

  render() {
    const { diaryTitle, description, diaryID, startDate, endDate } = this.props.item;

    const mStartDate = moment(startDate, "DD-MM-YYYY hh:mm A").format("DD MMM YYYY");
    const mEndDate = endDate ? moment(endDate, "DD-MM-YYYY hh:mm A").format("DD MMM YYYY") : "";

    return (
      <div
        key={diaryID}
        className="pinnedRoot drag"
        draggable
        onDrag={() => this.props.setDragging(diaryID)}
        onDragOver={(e) => this.props.setDraggedOver(e, diaryID)}
        onDrop={() => this.props.onDrop()}
      >
        <div className="d-flex" style={{ justifyContent: "space-between", position: "relative" }}>
          <div className="pin-button" onClick={() => this.unpinNote()}>
            <div className="pin-btn-tooltip">Unpin Note</div>
            <i className="fa fa-thumb-tack" aria-hidden="true" style={{ fontSize: 14 }}></i>
          </div>
          <div style={{ flex: 1 }}>
            {!this.props.ReadOnly && this.props.onActionFunc ? (
              <a onClick={() => this.onItemClick(diaryID)} style={{ cursor: "pointer", color: "#000000" }}>
                <strong>{diaryTitle}</strong>
              </a>
            ) : (
              <strong>{diaryTitle}</strong>
            )}
            <br />
            <div className="pinned-note-date">
              {mStartDate}
              {mEndDate ? ` - ${mEndDate}` : ""}
            </div>
            <p>
              <span>{this.getDescription(description)}&nbsp;&nbsp;</span>
              {description.length > 100 && (
                <a onClick={() => this.toggleReadOnlyDesc()} style={{ cursor: "pointer" }}>
                  <u>{this.state.isDescExpanded ? "Read Less" : "Read More"}</u>
                </a>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
