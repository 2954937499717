import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import apiAgent from "../../api/apiAgent";
import { OMSDropdown } from "./OMSDropdown";
import { OMSNumericBox } from "./OMSComponent";

export class OMSSaveContact extends React.Component {
  static propTypes = {
    ID: PropTypes.string.isRequired,
    OfficeDefaultTags: PropTypes.array.isRequired,
    UserDefinedTags: PropTypes.array.isRequired,
    ContactSourceTypes: PropTypes.array.isRequired,
    DialingCode: PropTypes.string,
    DialingCodeDD: PropTypes.array.isRequired,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    ID: "",
    OfficeDefaultTags: [],
    UserDefinedTags: [],
    ContactSourceTypes: [],
    DialingCode: "",
    DialingCodeDD: [],
  };

  componentDidMount() {}

  getOfficeDefaultTags = (selectedItems) => {
    var items = [];
    var selectedItemsL = selectedItems.map((I) => I.toLowerCase());
    for (var i = 0; i < this.props.OfficeDefaultTags.length; i++) {
      if (selectedItemsL.indexOf(this.props.OfficeDefaultTags[i].name.toLowerCase()) > -1)
        items.push({
          name: this.props.OfficeDefaultTags[i].name,
          color: this.props.OfficeDefaultTags[i].color,
          selected: true,
        });
      else
        items.push({
          name: this.props.OfficeDefaultTags[i].name,
          color: this.props.OfficeDefaultTags[i].color,
          selected: false,
        });
    }
    return items;
  };

  getUserDefinedTags = (selectedItems) => {
    var items = [];
    var selectedItemsL = selectedItems.map((I) => I.toLowerCase());
    for (var i = 0; i < this.props.UserDefinedTags.length; i++) {
      if (selectedItemsL.indexOf(this.props.UserDefinedTags[i].name.toLowerCase()) > -1)
        items.push({
          name: this.props.UserDefinedTags[i].name,
          color: this.props.UserDefinedTags[i].color,
          selected: true,
        });
      else
        items.push({
          name: this.props.UserDefinedTags[i].name,
          color: this.props.UserDefinedTags[i].color,
          selected: false,
        });
    }
    return items;
  };

  handleTagsClick = (name) => {
    var selectedTags = this.state.Tags;
    var index = selectedTags.indexOf(name);
    if (index < 0) {
      selectedTags.push(name);
    } else {
      selectedTags.splice(index, 1);
    }
    this.setState({
      Tags: selectedTags,
      OfficeDefaultTags: this.getOfficeDefaultTags(selectedTags),
      UserDefinedTags: this.getUserDefinedTags(selectedTags),
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleContactTypeChange = (e) => {
    var contactType = 1;
    if (e.target.value == "on" && e.target.id == "rbtnCompany") {
      contactType = 2;
    }

    this.setState({
      ContactType: contactType,
    });
  };

  handleSharingChanged = (e) => {
    var sharing = this.state.Sharing == 1 ? 2 : 1;
    this.setState({
      Sharing: sharing,
    });
  };

  handleDialingCodeChange = (e) => {
    this.setState({ PhoneDialingCode: e.newValue });
  };

  handleSourceDDChange = (e) => {
    this.setState({
      Source: e.newValue,
    });
  };

  handlePhoneChange = (name, value) => {
    this.setState({ Phone: value });
  };

  onOpenContact = (contactName, ContactEmail, ContactNo) => {
    var FirstName = "";
    var LastName = "";

    if (contactName === undefined || contactName == null || contactName == "") contactName = "";
    else {
      var names = contactName.split(" ");
      if (names.length == 1) FirstName = names[0];
      else {
        LastName = names[names.length - 1];
        FirstName = contactName.replace(LastName, "");
      }
    }

    if (ContactEmail === undefined || ContactEmail == null) ContactEmail = "";

    if (ContactNo === undefined || ContactNo == null) ContactNo = "";

    this.setState({
      contactID: "",
      userID: this.props.userID,
      Tags: [],
      ContactType: 1,
      NationalIDNumber: "",
      CompanyName: "",
      FirstName: FirstName,
      LastName: LastName,
      EmailAddress: ContactEmail,
      Sharing: 1,
      Source: this.props.ContactSourceTypes[0]?.name,
      PhoneDialingCode: this.props.DialingCode ? this.props.DialingCode : "",
      Phone: ContactNo,
      OfficeDefaultTags: this.getOfficeDefaultTags([]),
      UserDefinedTags: this.getUserDefinedTags([]),
      processing: true,
    });
    window.$("#saveContactModal-" + this.props.ID).modal("show");
  };

  getFormData = () => {
    var data = new FormData();
    data.append("contactID", this.state.contactID);
    data.append("userID", this.state.userID);
    data.append("ContactType", this.state.ContactType);
    data.append("CompanyName", this.state.CompanyName);
    data.append("FirstName", this.state.FirstName);
    data.append("LastName", this.state.LastName);
    data.append("EmailAddress", this.state.EmailAddress);
    data.append("Sharing", this.state.Sharing);
    data.append("Source", this.state.Source);
    data.append("PhoneDialingCode", this.state.PhoneDialingCode);
    data.append("Phone", this.state.Phone);
    data.append("NationalIDNumber", this.state.NationalIDNumber);

    // for (var i = 0; i < this.state.Tags.length; i++) {
    //   data.append("Tags[" + i + "]", this.state.Tags[i]);
    // }
    //if(this.state.)
    data.append("Tags", this.state.Tags);

    if (this.props.leadID && this.props.leadUserID) {
      data.append("leadID", this.props.leadID);
      data.append("leadUserID", this.props.leadUserID);
      data.append("leadTitle", this.props.leadTitle);
      data.append("strLeadMarkRead", this.props.LeadMarkRead);
    }

    return data;
  };

  checkFormData = (e) => {
    if (this.state.ContactType == 2) {
      if (!this.state.CompanyName.trim()) {
        toast.info("Please enter company name");
        return false;
      }
    } else {
      if (!this.state.FirstName.trim()) {
        toast.info("Please enter first name");
        return false;
      }

      if (!this.state.LastName.trim()) {
        toast.info("Please enter last name");
        return false;
      }
    }

    if (this.state.Tags.length == 0) {
      toast.info("Please select contact type");
      return false;
    }

    return true;
  };

  convertPascalToCamel=(obj)=> {
    const camelObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const camelKey = key.charAt(0).toLowerCase() + key.slice(1);
        camelObj[camelKey] = obj[key];
      }
    }
    return camelObj;
  };
  

  handleSuccessClick = (e) => {
    if (this.checkFormData()) {
      var data = this.getFormData();
      const formDataObj = Object.fromEntries(data.entries());
      formDataObj.Tags=formDataObj.Tags.split(',');
      const camelCasingFormObj = this.convertPascalToCamel(formDataObj);
      this.setState({ processing: true });
      /*  var xhr = new XMLHttpRequest();
            xhr.open('post', '/Contacts/SaveContactComponentAjax', true);
            xhr.onload = function () {
                this.setState({ processing: false });
                var data = JSON.parse(xhr.responseText);
                if (data.status == "success") {
                    var contactID = data.message;
                    var contactName = "";
                    var ContactTag = "";

                    if (this.state.ContactType == 2)
                        contactName = this.state.CompanyName;
                    else
                        contactName = this.state.FirstName + " " + this.state.LastName;

                    if (this.state.Tags.length > 0)
                        ContactTag = this.state.Tags[0];

                        window.$('#saveContactModal-' + this.props.ID).modal('hide');
                        window.$.toaster("Contact added successfully", 'Success', 'success');

                    if (this.props.onSuccess !== undefined)
                        this.props.onSuccess({ contactID: contactID, contactName: contactName, ContactTag: ContactTag });
                }
                else {
                    window.$.toaster(data.message, 'Fail', 'danger');
                }
            }.bind(this);
            xhr.send(data); */

      //console.log("inside handleSuccessClick, before api call, data: ", formDataObj);
      apiAgent.Contact.saveContactComponentAjax(camelCasingFormObj)
        .then((response) => {
          var contactID = response.message;
          var contactName = "";
          var ContactTag = "";

          if (this.state.ContactType == 2) contactName = this.state.CompanyName;
          else contactName = this.state.FirstName + " " + this.state.LastName;

          if (this.state.Tags.length > 0) ContactTag = this.state.Tags[0];

          window.$("#saveContactModal-" + this.props.ID).modal("hide");
          toast.info("Contact added successfully");

          if (this.props.onSuccess !== undefined)
            this.props.onSuccess({
              contactID: contactID,
              contactName: contactName,
              ContactTag: ContactTag,
            });
        })
        .catch((error) =>{ 
          console.log("inside handleSuccessClick, error: ", error);  
          toast.error(error.response.data)
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
  };

  state = {
    contactID: "",
    userID: this.props.userID,
    Tags: [],
    ContactType: 1,
    CompanyName: "",
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    NationalIDNumber: "",
    Sharing: 1,
    Source: this.props.ContactSourceTypes[0]?.name,
    PhoneDialingCode: this.props.DialingCode ? this.props.DialingCode : "",
    Phone: "",
    OfficeDefaultTags: this.getOfficeDefaultTags([]),
    UserDefinedTags: this.getUserDefinedTags([]),
    processing: true,
  };

  render() {
    var self = this;
    return (
      <div>
        <div className="modal fade" id={"saveContactModal-" + this.props.ID} tabIndex={-1} role="dialog" data-backdrop="static" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title">Save Contact</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="form-inline" style={{ marginBottom: 10 }}>
                        <label className="radio-inline">
                          <input
                            type="radio"
                            id="rbtnIndividual"
                            name="rbtnIndividual"
                            checked={this.state.ContactType === 1}
                            onChange={this.handleContactTypeChange}
                          />
                          Individual
                        </label>
                        <label className="radio-inline">
                          <input
                            type="radio"
                            id="rbtnCompany"
                            name="rbtnCompany"
                            checked={this.state.ContactType === 2}
                            onChange={this.handleContactTypeChange}
                          />
                          Company
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="Sharing"
                        name="Sharing"
                        value={this.state.Sharing}
                        checked={this.state.Sharing == 2}
                        onChange={this.handleSharingChanged}
                      />{" "}
                      Shared with office
                    </div>
                    <div className="form-group">
                      <label htmlFor="Source">Source</label>
                      <OMSDropdown
                        id="Source"
                        name="Source"
                        options={this.props.ContactSourceTypes}
                        valueField="name"
                        labelField="name"
                        value={this.state.Source}
                        onChange={this.handleSourceDDChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="FirstName">First name</label>
                      <input
                        id="FirstName"
                        name="FirstName"
                        type="text"
                        className={this.state.ContactType === 1 ? "form-control required-control" : "form-control"}
                        value={this.state.FirstName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="LastName">Last name</label>
                      <input
                        id="LastName"
                        name="LastName"
                        type="text"
                        className={this.state.ContactType === 1 ? "form-control required-control" : "form-control"}
                        value={this.state.LastName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="CompanyName">Company name</label>
                      <input
                        id="CompanyName"
                        name="CompanyName"
                        type="text"
                        className={this.state.ContactType === 2 ? "form-control required-control" : "form-control"}
                        value={this.state.CompanyName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="NationalIDNumber">National Identification Number</label>
                      <input
                        id="NationalIDNumber"
                        name="NationalIDNumber"
                        type="text"
                        className="form-control"
                        value={this.state.NationalIDNumber}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group cellphones">
                      <label htmlFor="email">Mobile number</label>
                      <br />
                      <div className="dialingcode" style={{ width: "30%" }}>
                        <OMSDropdown
                          id="PhoneDialingCode"
                          name="PhoneDialingCode"
                          options={this.props.DialingCodeDD}
                          valueField="value"
                          labelField="text"
                          value={this.state.PhoneDialingCode}
                          onChange={this.handleDialingCodeChange}
                        />
                      </div>
                      <div className="phonenumber" style={{ width: "70%" }}>
                        <OMSNumericBox id="Phone" name="Phone" maxLength={10} value={this.state.Phone} onChange={this.handlePhoneChange} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="EmailAddress">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="EmailAddress"
                        name="EmailAddress"
                        value={this.state.EmailAddress}
                        onChange={this.handleChange}
                      />{" "}
                      {/* data-validation="email" data-validation-error-msg="Please enter valid email address" */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="ContactType">Contact type</label>
                      <div>
                        {this.state.OfficeDefaultTags.map(function (item) {
                          return item.selected ? (
                            <span
                              key={item.name}
                              className="btn btn-tag-click"
                              style={{ backgroundColor: item.color }}
                              onClick={() => self.handleTagsClick(item.name)}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {item.name}
                            </span>
                          ) : (
                            <span
                              key={item.name}
                              className="btn btn-tag-click"
                              style={{ backgroundColor: "#ccc", color: "#333" }}
                              onClick={() => self.handleTagsClick(item.name)}
                            >
                              {item.name}
                            </span>
                          );
                        })}
                      </div>
                      <div style={{ marginTop: 10 }}>
                        {this.state.UserDefinedTags.map(function (item) {
                          return item.selected ? (
                            <span
                              key={item.name}
                              className="btn btn-my-tag-click"
                              style={{ backgroundColor: item.color }}
                              onClick={() => self.handleTagsClick(item.name)}
                            >
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {item.name}
                            </span>
                          ) : (
                            <span
                              key={item.name}
                              className="btn btn-my-tag-click"
                              style={{ backgroundColor: "#ccc", color: "#333" }}
                              onClick={() => self.handleTagsClick(item.name)}
                            >
                              {item.name}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-4 col-sm-12 pull-right">
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={this.handleSuccessClick}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}