import React from "react";
import PropTypes from "prop-types";
import { OMSButtonStyleL } from "../../../../app/common/common-components/OMSButton";

const BrochureList = ({ brochures, handleGenerateBrochureDynamicClick, processing }) => {
  return (
    <div className="brochure-list">
      {brochures.map((data, index) => (
        <div key={data.brochureID + "-" + index} className={`brochure-item ${!data.isGeneric ? "custom-brochure" : ""}`}>
          <div
            className="brochure-preview"
            style={{
              backgroundImage: data.brochurePreviewUrl
                ? "url(" + data.brochurePreviewUrl + ")"
                : "url(https://etbrochure.s3.eu-west-1.amazonaws.com/preview/default.jpg)",
            }}
          ></div>
          <div className="brochure-title">
            <OMSButtonStyleL
              type="button"
              ui_icon={data.exportType == "PNG" ? "fa-file-image-o" : "fa-file-pdf-o"}
              ui_type="cancel"
              ui_text={data.brochureName}
              ui_processing_text="Generating.."
              processing={processing}
              disabled={processing}
              onClick={() => handleGenerateBrochureDynamicClick(data.brochureID, data.fileType == 1, data.exportType)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

BrochureList.propTypes = {
  brochures: PropTypes.array.isRequired,
  handleGenerateBrochureDynamicClick: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default BrochureList;
