import React, { useEffect } from "react";
import { RequirementComponent } from "../RequirementComponent";

function Qualify(props: any) {
  useEffect(() => {
    //console.log("Qualify useEffect", props.contact);
  }, []);
  return (
    <RequirementComponent
      actions={props.contact!.actions!}
      super_access={props.contact!.super_access}
      //doSave={doRequirementSave}
      assigned={props.assigned}
      assignedName={props.assignedName}
      officeID={props.contact!.officeID}
      contactID={props.contact!.contactID}
      propertyTypes={props.contact!.propertyTypes!}
      currencyDisplay={props.contact!.currencyDisplay!}
      userID={props.contact!.userID}
    />

    // <RequirementComponent
    //   actions={props.contact!.actions!}
    //   super_access={props.contact!.super_access}
    //   Assigned={props.assigned}
    //   AssignedName={props.assignedName}
    //   OfficeID={props.contact!.officeID}
    //   contactID={props.contact!.contactID}
    //   PropertyTypes={props.contact!.propertyTypes!}
    //   CurrencyDisplay={props.contact!.currencyDisplay!}
    // />
  );
}

export default Qualify;
