import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ConfirmationModalProps } from "../../../models/ModalControlProps/ConfirmationModalProps";
import ModalPopup from "./ModalPopup";

const ConfirmationModalPopup = (props: ConfirmationModalProps) => {
  const { showModal, isConfirmed, title, body } = props;

  const closeModalPopup = () => {
    isConfirmed(false);
  };

  const confirmModalPopup = () => {
    isConfirmed(true);
  };

  return <ModalPopup showModal={showModal} closeModalPopup={closeModalPopup} confirmModalPopup={confirmModalPopup} title={title} body={body} />;
};

export default ConfirmationModalPopup;
