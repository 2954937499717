import React from "react";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableComp } from "../../../app/common/common-components/OMSDatatables";
import { DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../app/api/apiAgent";
import { withRouter } from 'react-router-dom';

class GroupsGrid extends React.Component {
  state = {
    data: [],
    groupsData: [],
    view: 1,
    loading: false,
    ExtraParams: JSON.stringify({ View: 1 }),
  };

  loadGroupsFromServer = (view) => {
    this.setState({ loading: true });
    apiAgent.Setting.getGroupsData(view)
      .then((response) => {
        //console.log("offices data", this.state.groupsData);
        this.setState({
          groupsData: response.data,
        });
        //console.log("groups Data", response.data);
      })
      .catch((error) => {
        //toast.error(error.response.data))
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.loadGroupsFromServer(1);
  }

  onCreateClick = (e) => {
    this.props.history.push('/Settings/SaveGroup');
  };

  handleRowClick = (data) => {
    this.props.history.push('/Settings/SaveGroup?ID=' + data.groupID);
  };

  onViewDropDownChange = (e) => {
    this.setState({
      view: e.target.value,
      ExtraParams: JSON.stringify({ View: e.target.value }),
    });
    this.loadGroupsFromServer(e.target.value);
  };

  render() {
    function formatDateTime(val) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      if (isNaN(val)) return "";
      else {
        var valS = val.toString();
        return valS.substring(6, 8) + " " + months[parseInt(valS.substring(4, 6)) - 1] + " " + valS.substring(0, 4);
      }
    }

    const columns = [
      { data: "syncID", title: "SyncID" },
      { data: "name", title: "Name" },
      { data: "status", title: "Status" },
      { data: "activeOffices", title: "Active offices" },
      { data: "createdF", title: "Created" },
      { data: "type", title: "Type" },
    ];

    const columnDefs = [
      {
        render: function (data, type, row) {
          return formatDateTime(data);
        },
        targets: 4,
      },
    ];

    return (
      <div className="col-md-12">
        <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6 col-sm-5 col-xs-12">
                <div className="form-inline form-group boxLeftMenu">
                  <span>
                    <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                  </span>
                  <select className="form-control" onChange={this.onViewDropDownChange} value={this.state.view}>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 col-sm-7 col-xs-12">
                <div className="pull-right boxRightMenu">
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="create"
                    ui_text="add group"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCreateClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body table-responsive">
            {/* <DataTableCompDynamic id="groupTable" Url="/Settings/GroupsAjax" columns={columns} columnDefs={[]} order={[[ 0, "desc" ]]} serverSide={false} onRowClick={this.handleRowClick} ExtraParams={this.state.ExtraParams} /> */}
            <DataTableComp
              id="groupTable"
              data={this.state.groupsData}
              columns={columns}
              columnDefs={[]}
              order={[[0, "desc"]]}
              onRowClick={this.handleRowClick}
            />
            <DataTableLoading loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(GroupsGrid);
