import React from "react";
import PropTypes from "prop-types";
import { OMSNumericBox } from "../../../../app/common/common-components/OMSComponent";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";
import DatePicker from "react-datepicker";

const moment = require("moment");

export class ImportMandateStepView extends React.Component {
    static propTypes = {
        data: PropTypes.object
    };

    getStateFromData = (data) => {
        var mStartDate = moment(data.startDate, "DD-MM-yyyy");
        var mExpiryDate = moment(data.expiryDate, "DD-MM-yyyy");
        var mOccupationDate = data.occupationDate == "" ? moment() : moment(data.occupationDate, "DD-MM-yyyy");
        var mCapturedDate = moment(data.capturedDate, "DD-MM-yyyy");

        return {
            Step: 'Listing capture: Mandate',
            ImportID: data.importID,
            PropertyID: data.propertyID,
            Price: data.price,
            SaleType: data.saleType,
            SaleTypeUnits: data.saleTypeUnits,
            MandateType: data.mandateType == '' ? this.props.MandateTypes[0] : data.mandateType,
            Status: data.status,
            /* CapturedDate: data.capturedDate,
            StartDate: data.startDate,
            ExpiryDate: data.expiryDate,
            OccupationDate: data.occupationDate, */
            CapturedDate: mCapturedDate.toDate(),
            StartDate: mStartDate.toDate(),
            ExpiryDate: mExpiryDate.toDate(),
            OccupationDate: data.occupationDate === "" ? null : mOccupationDate.toDate(),
            CommType: data.commType,
            CommPerc: data.commPerc,
            CommAmount: data.commAmount,
            Tax: data.tax,
            PriceOnApplication: (parseInt(data.price) == 0)
        }
    };

    componentDidMount() {
         
        /* window.$('#StartDate').datetimepicker({
            format: 'DD-MM-YYYY',
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fa fa-arrow-left",
                next: "fa fa-arrow-right",
                today: 'fa fa-screenshot',
                clear: 'fa fa-trash',
                close: 'fa fa-remove'
            }
        }); */

        /* window.$('#StartDate').on('dp.change', function (e) {
            var dateString = window.$('#StartDate').val();
            this.setState({ StartDate: dateString });
        }.bind(this)); */

        /* window.$('#ExpiryDate').datetimepicker({
            format: 'DD-MM-YYYY',
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fa fa-arrow-left",
                next: "fa fa-arrow-right",
                today: 'fa fa-screenshot',
                clear: 'fa fa-trash',
                close: 'fa fa-remove'
            }
        }); */

       /*  window.$('#ExpiryDate').on('dp.change', function (e) {
            var dateString = window.$('#ExpiryDate').val();
            this.setState({ ExpiryDate: dateString });
        }.bind(this)); */

        /* window.$('#OccupationDate').datetimepicker({
            format: 'DD-MM-YYYY',
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fa fa-arrow-left",
                next: "fa fa-arrow-right",
                today: 'fa fa-screenshot',
                clear: 'fa fa-trash',
                close: 'fa fa-remove'
            }
        }); */

        /* window.$('#OccupationDate').on('dp.change', function (e) {
            var dateString = window.$('#OccupationDate').val();
            this.setState({ OccupationDate: dateString });
        }.bind(this)); */

        this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
    }

    doSetFormData = async (data) => {
        /* data.append('Mandate.PropertyID', this.state.PropertyID);
        data.append('Mandate.ImportID', this.state.ImportID);

        if (this.state.PriceOnApplication == true)
            data.append('Mandate.Price', "0");
        else
            data.append('Mandate.Price', this.state.Price.replace(',', ''));

        data.append('Mandate.SaleType', this.state.SaleType);
        data.append('Mandate.SaleTypeUnits', this.state.SaleTypeUnits);
        data.append('Mandate.MandateType', this.state.MandateType);
        data.append('Mandate.Status', this.state.Status);

        data.append('Mandate.CapturedDate', this.state.CapturedDate);
        data.append('Mandate.StartDate', this.state.StartDate);
        data.append('Mandate.ExpiryDate', this.state.ExpiryDate);
        data.append('Mandate.OccupationDate', this.state.OccupationDate);

        data.append('Mandate.CommType', this.state.CommType);
        data.append('Mandate.CommPerc', this.state.CommPerc);
        data.append('Mandate.CommAmount', this.state.CommAmount.replace(',', ''));
        data.append('Mandate.Tax', this.state.Tax); */
        
        
        const mandateData = {
            PropertyID: this.state.PropertyID,
            MandateID: this.state.MandateID,
            Price: this.state.PriceOnApplication ? "0" : this.state.Price.replace(",", ""),
            SaleType: this.state.SaleType,
            SaleTypeUnits: this.state.SaleTypeUnits,
            MandateType: this.state.MandateType,
            Status: this.state.Status,
            CapturedDate: this.state.CapturedDate,
            StartDate: this.state.StartDate,
            ExpiryDate: this.state.ExpiryDate,
            OccupationDate: this.state.OccupationDate,
            CommType: this.state.CommType,
            CommPerc: this.state.CommPerc,
            CommAmount: this.state.CommAmount.replace(",", ""),
            Tax: this.state.Tax,
        };
        let mandateJson = JSON.stringify(mandateData);
        //console.log("inside mandate-doSetFormData, mandateJson=", mandateJson);
        data.append('MandateJson',mandateJson);
    };

    doCheckFormData = (data) => {
        var result = { status: 'error', message: 'Fields are required.' };
        var resultSuccess = { status: 'success', message: '' };

        if (!this.state.MandateType) {
            result.message = "Please select mandate type";
            return result;
        }
        else if (!this.state.Price) {
            result.message = "Please enter price";
            return result;
        }
        else if (!this.state.StartDate) {
            result.message = "Please enter mandate start";
            return result;
        }
        else if (!this.state.ExpiryDate) {
            result.message = "Please enter mandate end";
            return result;
        }

        return resultSuccess;
    };

    doSetState = (data) => {
        this.setState(this.getStateFromData(data), function () {
            this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
        });
    };

    doGetState = () => {
        return this.state;
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleNumericChange = (name, value) => {
        /*if (name == "Price" && value != "0") {
            this.setState({
                [name]: value, PriceOnApplication: false
            });
        }
        else {
            this.setState({
                [name]: value
            });
        } */

        this.setState({
            [name]: value
        });
    };

    handleDropdownChange = (e) => {
        this.setState({ [e.name]: e.newValue });
    };

    handlePriceNumericChange = (name, value) => {
        /*if (name == "Price" && value != "0") {
            this.setState({
                [name]: value, PriceOnApplication: false
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }*/
        this.calculateMandateAmounts(value, this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
    };

    handleVATChange = (name, value) => {
        /*if (name == "Price" && value != "0") {
            this.setState({
                [name]: value, PriceOnApplication: false
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }*/
        this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, this.state.CommAmount, value);
    };

    handleCommTypeChange = (value) => {
        //this.setState({CommType: value});
        this.calculateMandateAmounts(this.state.Price, value, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
    };

    handleCommPercChange = (name, value) => {
        this.calculateMandateAmounts(this.state.Price, this.state.CommType, value, this.state.CommAmount, this.state.Tax);
    };

    handleCommAmountChange = (name, value) => {
        this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, value, this.state.Tax);
    };

    handlePriceOnApplicationChange = (e) => {
        if (e.target.checked) {
            this.calculateMandateAmounts("0", this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
        }
        else {
            this.calculateMandateAmounts(this.state.Price, this.state.CommType, this.state.CommPerc, this.state.CommAmount, this.state.Tax);
        }
    };

    calculateMandateAmounts = (Price, CommType, CommPerc, CommAmount, VAT) => {
        //var CommAmount = this.state.CommAmount;
        //var CommPerc = this.state.CommPerc;
        //var Price = this.state.Price;
        //var VAT = this.state.VAT;

        var PriceString = Price;
        var VATString = VAT;
        var CommPercString = CommPerc;
        var CommAmountString = CommAmount;

        var iPrice = 0;
        var iCommPerc = 0;
        var iCommAmount = 0;
        var iVAT = 0;

        Price = Price.replace(/,/g, '');
        CommAmount = CommAmount.replace(/,/g, '');

        if (!Number.isNaN(Price))
            iPrice = parseFloat(Price);

        if (!Number.isNaN(CommPerc))
            iCommPerc = parseFloat(CommPerc);

        if (!Number.isNaN(CommAmount))
            iCommAmount = parseFloat(CommAmount);

        if (!Number.isNaN(VAT))
            iVAT = parseFloat(VAT);

        var VATAmount = 0;
        var Gross = 0;
        var GrossAmount = 0;
        var OwnerNett = 0;
        var PriceOnApplication = false;

        if (iPrice == 0)
            PriceOnApplication = true;

        if (CommType == "1") {
            // %
            iCommAmount = (iPrice * iCommPerc) / 100;
            VATAmount = (iCommAmount * iVAT) / 100;
            Gross = iCommPerc + (iCommPerc * iVAT) / 100;
            GrossAmount = iCommAmount + VATAmount;
            OwnerNett = iPrice - GrossAmount;
            CommAmountString = iCommAmount.toFixed(2);
        }
        else {
            // Fixed
            iCommPerc = (100 * iCommAmount) / iPrice;
            VATAmount = (iCommAmount * iVAT) / 100;
            Gross = iCommPerc + (iCommPerc * iVAT) / 100;
            GrossAmount = iCommAmount + VATAmount;
            OwnerNett = iPrice - GrossAmount;
            CommPercString = iCommPerc.toFixed(2);
        }

        this.setState({ Price: PriceString, PriceOnApplication: PriceOnApplication, CommType: CommType, CommPerc: CommPercString, CommAmount: CommAmountString, Tax: VATString, VATAmount: VATAmount.toFixed(2), Gross: Gross.toFixed(2), GrossAmount: GrossAmount.toFixed(2), OwnerNett: OwnerNett.toFixed(2) });
    };

    state = this.getStateFromData(this.props.data);

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-horizontal">
                            <div className="form-group">
                                <label className="col-md-2 control-label">Listing price</label>
                                <div className="col-md-4">
                                    <OMSNumericBox id="Price" name="Price" separator="," maxLength={10} value={this.state.Price} isDecimal={true} onChange={this.handlePriceNumericChange} />
                                </div>
                                <div className="col-md-6">
                                    <span><input type="checkbox" id="PriceOnApplication" name="PriceOnApplication" defaultChecked={this.state.PriceOnApplication} onChange={this.handlePriceOnApplicationChange} />&nbsp;Price on application</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-2 control-label">Sale type</label>
                                <div className="col-md-4">
                                    <select id="SaleType" name="SaleType" className="form-control" value={this.state.SaleType} onChange={this.handleChange} >
                                        <option value="1">For sale</option>
                                        <option value="2">For rent</option>
                                        {/*<option value="3">Auction</option>*/}
                                        <option value="4">Valuation</option>
                                    </select>
                                </div>
                                {/*<div className="col-md-6">
                                    <div className="btn-group">
                                        <a href="#" className="btn btn-default">daily</a>
                                        <a href="#" className="btn btn-default">weekly</a>
                                        <a href="#" className="btn btn-success-oms">monthly</a>
                                    </div>
                                </div>*/}
                            </div>
                            <div className="form-group" style={{ display: (this.state.SaleType > 2 ? "none" : "") }}>
                                <label className="col-md-2 control-label">Sale type units</label>
                                <div className="col-md-4">
                                    <select id="SaleTypeUnits" name="SaleTypeUnits" className="form-control" value={this.state.SaleTypeUnits} onChange={this.handleChange} >
                                        {this.state.SaleType == 2 ? <option value="1">per day</option> : null}
                                        {this.state.SaleType == 2 ? <option value="2">per week</option> : null}
                                        {this.state.SaleType == 2 ? <option value="3">per month</option> : null}
                                        {this.state.SaleType == 1 ? <option value="4">total</option> : null}
                                        {this.state.SaleType == 1 ? <option value="5">per m²</option> : null}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-2 control-label">Status</label>
                                <div className="col-md-4">
                                    <select id="Status" name="Status" className="form-control" value={this.state.Status} onChange={this.handleChange} >
                                        <option value="1">Active</option>
                                        <option value="2">Expired</option>
                                        <option value="3">Withdrawn</option>
                                        <option value="4">Rented</option>
                                        <option value="5">Sold - Under Offer</option>
                                        <option value="6">Sold - Registered</option>
                                        <option value="7">Sold - Cancelled</option>
                                        <option value="8">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <label className="col-md-2 control-label">Mandate</label>
                                <div className="col-md-4">
                                    <OMSDropdown id="MandateType" name="MandateType" options={this.props.MandateTypes} valueField="" labelField="" value={this.state.MandateType} onChange={this.handleDropdownChange} />
                                    {/*<select id="MandateType" name="MandateType" className="form-control" value={this.state.MandateType} onChange={this.handleChange}>
                                        <option value="1">Open</option>
                                        <option value="2">Sole</option>
                                        <option value="3">Exclusive</option>
                                        <option value="4">MLS</option>
                                    </select>*/}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-2 control-label">Mandate start</label>
                                <div className="col-md-4">
                                    <div className="input-group">
                                        {/* <input type="text" className="form-control" id="StartDate" name="StartDate" value={this.state.StartDate} onChange={this.handleChange} /> */}
                                        <DatePicker
                                            selected={this.state.StartDate}
                                            onChange={(date) => this.setState({ StartDate: date })}
                                            dateFormat="dd-MM-yyyy"
                                            value={this.state.StartDate}
                                            name="StartDate"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            //style={{ width: 150 }}
                                            className="form-control MandateDatePicker"
                                        />
                                        <span className="input-group-addon"><i className="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-2 control-label">Mandate end</label>
                                <div className="col-md-4">
                                    <div className="input-group">
                                        {/* <input type="text" className="form-control" id="ExpiryDate" name="ExpiryDate" value={this.state.ExpiryDate} onChange={this.handleChange} /> */}
                                        <DatePicker
                                            selected={this.state.ExpiryDate}
                                            onChange={(date) => this.setState({ ExpiryDate: date })}
                                            dateFormat="dd-MM-yyyy"
                                            value={this.state.ExpiryDate}
                                            name="ExpiryDate"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            //style={{ width: 150 }}
                                            className="form-control MandateDatePicker"
                                        />
                                        <span className="input-group-addon"><i className="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-2 control-label">Occupation date</label>
                                <div className="col-md-4">
                                    <div className="input-group">
                                        {/* <input type="text" className="form-control" id="OccupationDate" name="OccupationDate" value={this.state.OccupationDate} onChange={this.handleChange} /> */}
                                        <DatePicker
                                            selected={this.state.OccupationDate}
                                            onChange={(date) => this.setState({ OccupationDate: date })}
                                            dateFormat="dd-MM-yyyy"
                                            value={this.state.OccupationDate}
                                            name="OccupationDate"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            //style={{ width: 150 }}
                                            className="form-control MandateDatePicker2"
                                        />
                                        <span className="input-group-addon"><i className="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="form-group">
                                <label className="col-md-2 control-label" style={{ paddingTop: 0 }}>Commission</label>
                                <div className="col-md-2">
                                    <label>%</label>
                                </div>
                                <div className="col-md-8">
                                    <label>Amount</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <span className="col-md-2 control-label">Nett</span>
                                <div className="col-md-2">
                                    <OMSNumericBox id="CommPerc" name="CommPerc" maxLength={6} style={{ width: 100 }} value={this.state.CommPerc} isDecimal={true} onChange={this.handleCommPercChange} readOnly={this.state.CommType == "2"} />
                                </div>
                                <div className="col-md-8 form-inline">

                                    <OMSNumericBox id="CommAmount" separator="," name="CommAmount" maxLength={10} style={{ width: 150 }} value={this.state.CommAmount} isDecimal={true} onChange={this.handleCommAmountChange} readOnly={this.state.CommType == "1"} />

                                    <div className="btn-group" style={{ marginLeft: 25 }}>
                                        <button className={this.state.CommType == "1" ? "btn btn-success-oms" : "btn btn-default"} onClick={() => this.handleCommTypeChange("1")}>%</button>
                                        <button href="#" className={this.state.CommType == "2" ? "btn btn-success-oms" : "btn btn-default"} onClick={() => this.handleCommTypeChange("2")}>Fixed</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <span className="col-md-2 control-label">VAT</span>
                                <div className="col-md-2">
                                    <OMSNumericBox id="Tax" name="Tax" className="form-control" maxLength={10} style={{ width: 100 }} value={this.state.Tax} isDecimal={true} onChange={this.handleVATChange} />
                                </div>
                                <div className="col-md-8">
                                    <OMSNumericBox id="VATAmount" separator="," name="VATAmount" maxLength={10} style={{ width: 150 }} value={this.state.VATAmount} isDecimal={true} onChange={this.handleNumericChange} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group">
                                <span className="col-md-2 control-label">Gross</span>
                                <div className="col-md-2">
                                    <OMSNumericBox id="Gross" name="Gross" className="form-control" maxLength={10} style={{ width: 100 }} value={this.state.Gross} isDecimal={true} readOnly={true} onChange={this.handleVATChange} />
                                </div>
                                <div className="col-md-8">
                                    <OMSNumericBox id="GrossAmount" separator="," name="GrossAmount" maxLength={10} style={{ width: 150 }} value={this.state.GrossAmount} isDecimal={true} onChange={this.handleNumericChange} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group">
                                <span className="col-md-2 control-label">Owner nett</span>
                                <div className="col-md-2">
                                </div>
                                <div className="col-md-8">
                                    <OMSNumericBox id="OwnerNett" separator="," name="OwnerNett" maxLength={10} style={{ width: 150 }} value={this.state.OwnerNett} isDecimal={true} onChange={this.handleNumericChange} readOnly={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}