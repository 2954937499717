import React, { useEffect, useState } from "react";
import { SyndicationSummaryModel } from "../../../../app/models/SyndicationSummaryModel";
import { DataTableLoading } from "../../../../app/common/common-components/OMSDatatables";
import { DataTableComp } from "../../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../../app/api/apiAgent";
import { SyndicationDetailModel } from "../../../../app/models/SyndicationDetailModel";
import { PortalConfigModel } from "../../../../app/models/MasterDataModel";

// Extend the Window interface to include the handleColumnClick method
declare global {
  interface Window {
    handleColumnClick?: (platform: string, columnName: string) => void;
  }
}

interface Props {
  parentGridLoading: boolean;
  officeID: string;
  handleCellClick: (platform: string, columnName: string, data: SyndicationDetailModel[]) => void; 
  portalConfigs: PortalConfigModel[]
}

const SyndicationSummaryGrid: React.FC<Props> = (props) => {
  const [syndicationSummaryData, setSyndicationSummaryData] = useState<SyndicationSummaryModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    apiAgent.Property.GetSyndicationSummaryForOffice(props.officeID)
      .then((response: any) => {
        //console.log("inside useEffect of SyndicationSummaryGrid", response);
        setSyndicationSummaryData(response);
        setIsLoading(false);
      })
      .catch((error: any) => {
        //console.log(error);
        setIsLoading(false);
      });
  }, [props.officeID]);

  const handleColumnClick = (platform: string, columnName: string) => {
    //console.log(`Clicked on platform: ${platform}, column: ${columnName}`);
    //console.log("Syndication Summary Data", syndicationSummaryData);

    if (!syndicationSummaryData.length) {
      //console.log("No data available in syndicationSummaryData");
      return;
    }

    let data: SyndicationDetailModel[] = [];
    switch(columnName) {
        case "In Process":
            data = syndicationSummaryData.find((x) => x.platform === platform)?.inProcessPropertyDetails ?? [];
            break;
        case "Error":
            data = syndicationSummaryData.find((x) => x.platform === platform)?.errorPropertyDetails ?? [];
            break;
        default:
            //console.log("Unknown columnName: ", columnName);
    }

    //console.log("Data", data);

    props.handleCellClick(platform, columnName, data);
  };

  const columns = [
    { 
      data: "platform", 
      title: "Platform",
      className: "dt-center", // Add this line
      render: (data: any, type: any, row: any) => {
        var logoUrl = "";
        const altText = row.platform;
        //console.log("inside render of platform column, props.portalConfigs=", props.portalConfigs, "row=", row);
        if(props.portalConfigs){
          const portalConfig = props.portalConfigs.find((config) => config.friendlyName === row.platform);
           logoUrl = portalConfig ? portalConfig.logoUrl : "";
        }
        return logoUrl ? `<img src="${logoUrl}" alt="${altText}" style="height: auto; max-width: 100px;" />` : altText;
      }
    },
    { 
      data: "active", 
      title: '<i class="fa fa-check-circle" style="margin-left: 5px; color: green;"></i> Active',
      className: "dt-center" // Add this line
    },
    {
      data: "inProcess",
      title: '<i class="fa fa-clock-o" style="margin-left: 15px; color: orange;"></i> In Process',
      className: "dt-center", // Add this line
      render: (data: any, type: any, row: any) => {
        return data !== 0
          ? `<a href="#" style="color: darkorange;" onclick="window.handleColumnClick('${row.platform}', 'In Process')">${data}</a>`
          : data;
      }
    },
    {
      data: "error",
      title: '<i class="fa fa-exclamation-triangle" style="margin-left: 15px; color: red;"></i> Error',
      className: "dt-center", // Add this line
      render: (data: any, type: any, row: any) => {
        return data !== 0
          ? `<a href="#" style="color: red;" onclick="window.handleColumnClick('${row.platform}', 'Error')">${data}</a>`
          : data;
      }
    },
    { data: "forSale", title: "For Sale", className: "dt-center" }, // Add this line
    { data: "toRent", title: "To Rent", className: "dt-center" }, // Add this line
  ];

  useEffect(() => {
    window.handleColumnClick = handleColumnClick;
    return () => {
      if (window.handleColumnClick) {
        delete window.handleColumnClick;
      }
    };
  }, [syndicationSummaryData]);

  return (
    <div className="panel-body table-responsive center-align">
      <DataTableComp
        id="syndicationSummaryTable"        
        data={syndicationSummaryData}
        columns={columns}
        columnDefs={[]}
        order={[[0, "desc"]]}
        showSearchBox={false}
      />
      <DataTableLoading loading={isLoading} />
    </div>
  );
};

export default SyndicationSummaryGrid;
