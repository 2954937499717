import React from "react";
import { Modal, Button, Table } from "react-bootstrap";

interface DetailModalPopupProps {
  show: boolean;
  onClose: () => void;
  title: string;
  data: { [key: string]: any } | null;
}

// Helper function to format the keys for display
const formatKey = (key: string) => {
  // Inserts a space before each uppercase letter and capitalizes the first character
  const formattedKey = key.replace(/([A-Z])/g, ' $1').trim();
  return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
};

const DetailModalPopup: React.FC<DetailModalPopupProps> = ({ show, onClose, title, data }) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {data ? (
        <Table striped bordered hover>
          <tbody>
            {Object.entries(data).map(([key, value]) => (
              <tr key={key}>
                <td>
                  <strong>{formatKey(key)}</strong>
                </td>
                <td>{String(value)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        "No details available." // Display this message if data is null or empty
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DetailModalPopup;
