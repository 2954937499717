import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EventTypeAutocomplete, OMSDropdown } from "../OMSDropdown";
import { OMSButtonStyleL2 } from "../OMSButton";
import { OMSDLUpload } from "../OMSDLUpload";
import { OMSLeadModel } from "../OMSInboxComponents/OMSLeadModel";
import { OMSActivityTimeline } from "../OMSActivityTimeline";
import { OMSCalendar } from "../OMSCalendar";
import { DataTableLoading } from "../OMSDatatables";
import PropTypes from "prop-types";
import apiAgent from "../../../api/apiAgent";
import DatePicker from "react-datepicker";
import $ from "jquery";
import bootbox from "bootbox";
import { Link } from 'react-router-dom';
const moment = require("moment");

export class OMSDiaryNoteItemDisplay extends React.Component {
  static propTypes = {
    Item: PropTypes.object,
    DNType: PropTypes.number, // 1:Contact, 2:Property, 0: Both
    IsUpcomingEvent: PropTypes.bool,
    onActionFunc: PropTypes.func,
    ReadOnly: PropTypes.bool,
  };

  onDisplayTitle = () => {
    if (this.props.IsUpcomingEvent) {
      var diaryTitle = this.props.Item.startDate;
      if (this.props.Item.endDate !== undefined && this.props.Item.endDate != "") diaryTitle = diaryTitle + "-" + this.props.Item.endDate.substring(10);
      diaryTitle += " " + this.props.Item.typeID;

      return diaryTitle;
    } else {
      var diaryTitle = this.props.Item.startDate.substring(10);
      if (this.props.Item.endDate !== undefined && this.props.Item.endDate != "") diaryTitle = diaryTitle + "-" + this.props.Item.endDate.substring(10);
      diaryTitle += " " + this.props.Item.typeID;

      return diaryTitle;
    }
  };

  onUpdateClick = (e) => {
    if (this.props.ReadOnly == false) {
      if (this.props.onActionFunc !== undefined) this.props.onActionFunc(this.props.Item.diaryID, false);
    }
  };

  onDeleteClick = (e) => {
    if (this.props.ReadOnly == false) {
      if (this.props.onActionFunc !== undefined) this.props.onActionFunc(this.props.Item.diaryID, true);
    }
  };

  render() {
    return (
      <div>
        <div className="form-group">
          <div className="calendar-icon pull-left">
            <span className="fa fa-calendar" aria-hidden="true"></span>
          </div>
          <div className="calendar-notes">
            <a style={{ cursor: "pointer", fontWeight: "bold" }} onClick={this.onUpdateClick}>
              {this.onDisplayTitle()}
            </a>
            {/*<span className="fa fa-trash-o" aria-hidden="true" style={{ cursor: 'pointer', marginLeft: 5 }} onClick={this.onDeleteClick}></span>*/}
            <br />
            <div className="description">{this.props.Item.description}</div>
            <div>
              {this.props.DNType != 1 && this.props.Item.contactID != "" ? (
                <span className="description-name">
                  {/* <a target="_blank" title={this.props.Item.contactName} href={"/Contacts/SaveContact?ID=" + this.props.Item.contactID}>
                    <i className="fa fa-user" style={{ marginTop: 5, marginRight: 8 }} aria-hidden="true"></i>
                    {this.props.Item.contactName}
              </a> */}
                  <Link to={`/Contacts/SaveContact?ID=${this.props.Item.contactID}`} title={this.props.Item.contactName} target="_blank">
                    <i className="fa fa-user" style={{ marginTop: 5, marginRight: 8 }} aria-hidden="true"></i>
                    {this.props.Item.contactName}
                  </Link>
                </span>
              ) : null}
              {this.props.DNType != 2 && this.props.Item.propertyID != "" ? (
                <span className="description-name">
                  {/* <a target="_blank" title={this.props.Item.propertyName} href={"/Property/SaveProperty?ID=" + this.props.Item.propertyID}>
                    <i className="fa fa-home" style={{ marginTop: 5, marginRight: 8 }} aria-hidden="true"></i>
                    {this.props.Item.propertyName}
              </a> */}
              <Link to={`/Property/SaveProperty?ID=${this.props.Item.propertyID}`} title={this.props.Item.propertyName} target="_blank">
                <i className="fa fa-home" style={{ marginTop: 5, marginRight: 8 }} aria-hidden="true"></i>
                {this.props.Item.propertyName}
              </Link>
                </span>
              ) : null}
              {this.props.Item.fileResults.map((f) => (
                <span key={f.ID} className="description-name">
                  {" "}
                  <a title={f.Name} href={f.Url}>
                    <i className={"fa " + f.TypeIcon} style={{ marginTop: 5, marginRight: 2 }} aria-hidden="true"></i>
                    {f.Name}&nbsp;&nbsp;
                  </a>
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}
