import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalProps } from "../../../models/ModalControlProps/ModalProps";

const ModalPopup = (props: ModalProps) => {
  const { showModal, closeModalPopup, confirmModalPopup, title, body } = props;

  return (
    <Modal show={showModal} onHide={() => {}}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{body}</Modal.Body>

      <Modal.Footer>
        <Button onClick={closeModalPopup}>Cancel</Button>
        <Button onClick={confirmModalPopup} bsStyle="primary">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPopup;
