import { makeAutoObservable, runInAction } from "mobx";
import apiAgent from "../api/apiAgent";
import { MasterDataModel } from "../models/MasterDataModel";

class MasterDataStore {
  masterData: MasterDataModel | null = null;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadMasterData = async () => {
    if (this.masterData) return; // If data is already loaded, do nothing

    this.loading = true;
    try {
      const data = await apiAgent.MasterData.getMasterData();
      runInAction(() => {
        this.masterData = data;
      });
    
    } catch (error) {
      console.error("Failed to load master data", error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  clearMasterData = () => {
    this.masterData = null; // Optional: method to clear the data if needed
  }
}

export default MasterDataStore;
