import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DealBox from "./DealBox";
import apiAgent from "../../../app/api/apiAgent";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import HeaderWrapper from "../../header/HeaderWrapper";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function DealPage() {
  const [isLoading, setisLoading] = useState(false);
  const [dealID, setdealID] = useState("");
  const [propertyID, setPropertyID] = useState(null);
  const [newDealData, setNewDealData] = useState();

  useEffect(() => {
    setisLoading(true);
    const params = new URLSearchParams(window.location.search);
    let dealId = "";
    let propertyId = "";

    if (params.has("ID")) {
      const id = params.get("ID");
      dealId = id !== null ? id : "";
      setdealID(dealId);
    }

    if (params.has("propertyID")) {
      const id = params.get("propertyID");
      propertyId = id !== null ? id : "";
      setPropertyID(propertyId);
    }

    apiAgent.Deal.saveDeal(dealId, propertyId).then((res) => {
      setisLoading(false);
      if (res.status !== undefined && res.status == "error") {
        toast.info(res.message);
      } else {
        setNewDealData(res);
      }
    });
  }, []);

  const setDashboardState = (state) => {
    localStorage.setItem("view", state);
  };

  document.title = "Base - Save Deal";

  if (isLoading) {
    return <AjaxLoader />;
  }
  return (
    <>
      <HeaderWrapper />

      <div className="save-deal container-fluid">
        {/* <div className="row">
          <ul className="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li onClick={() => setDashboardState("Deals")}>
              <a href="/deal">Deals</a>
            </li>
            <li className="active">{newDealData?.dealRef ? `#${newDealData?.dealRef} :  ${newDealData?.dealName}` : `New Deal`}</li>
          </ul>
  </div> */}
        <Breadcrumb
          breadcrumbs={[
            { text: "Home", path: "/" },
            {
              text: "Deals",
              path: "/deal",
              onclick: () => setDashboardState("Deals"),
            },
            {
              text: newDealData?.dealRef
                ? `#${newDealData?.dealRef} :  ${newDealData?.dealName}`
                : `New Deal`,
            },
          ]}
        />
        <div className="row">
          <div className="hidden-sm col-md-2"></div>
          <div id="content" className="col-md-12 col-md-8">
            {newDealData != undefined && (
              <DealBox
                dealID={dealID}
                propertyID={propertyID}
                data={newDealData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
});
