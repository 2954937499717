import React, { useEffect, useRef, useState } from "react";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import apiAgent from "../../../app/api/apiAgent";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import TaskModel from "../common/TaskModel";
import { useStore } from "../../../app/stores/store";
import { ToPriceString, ToPriceStringWithoutDecimal } from "../../../app/common/common-functions/functions";
import Chart from "../common/Chart";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";

const DealDashboard = ({ pipelineDD, workspaceDD, currencySymbol, accessType, actions, filterData, loopFilterOpen, loopFilterChanged }) => {
  const taskModelRef = useRef();
  const [dashBoardData, setDashBoardData] = useState();
  const [dashboardDealGridModel, setDashboardDealGridModel] = useState([]);
  const [dashboardAverageOfferPrice, setDashboardAverageOfferPrice] = useState();
  const [dashboardAverageNettCommission, setDashboardAverageNettCommission] = useState();
  const [upcomingAndOverdueTasks, setUpcomingAndOverdueTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assignedRolesDD, setAssignedRolesDD] = useState([
    {
      text: "",
      value: "",
    },
  ]);
  const [stageDD, setStageDD] = useState([
    {
      text: "",
      value: "",
    },
  ]);
  const [dealGraphData, setDealGraphData] = useState([]);
  const [dealCommissionGraphGraphData, setDealCommissionGraphGraphData] = useState([]);
  const defaultConfirmationModalProps = () => {
    return {
      showModal: false,
      isConfirmed: (result) => { },
      title: "",
      body: "",
    }
  }
  const [confirmationModalProps, setConfirmationModalProps] = useState(defaultConfirmationModalProps());

  const {
    userStore: { userSession },
  } = useStore();


  const onUpdateTaskModelOpen = (curTask) => {
    let stages = curTask.stageDD.map((curEle) => {
      return { text: curEle, value: curEle };
    });
    setStageDD(stages);
    if (taskModelRef.current && typeof taskModelRef.current.onModelClick === 'function') {
      taskModelRef.current.onModelClick(stages, curTask);
    } else {
      console.error('taskModelRef.current or onModelClick method is not available');
    }
  };

  const onTaskSave = (taskModel, isNewTask, preStageId, curStageId, documentModel) => {
    console.log("onTaskSave >> " + JSON.stringify(taskModel));
    console.log("isNewTask - " + isNewTask + " preStageId - " + preStageId + " curStageId - " + curStageId)
    var mTaskData = [...upcomingAndOverdueTasks];
    if (isNewTask) {
      // Do Nothing...
    }
    else {
      const mTaskIndex = mTaskData.findIndex((e) => e.taskId == taskModel.taskId);
      if (mTaskIndex != -1) {
        taskModel.stageDD = mTaskData[mTaskIndex].stageDD;
        mTaskData.splice(mTaskIndex, 1);
        mTaskData.splice(mTaskIndex, 0, taskModel);
      }
    }
    setUpcomingAndOverdueTasks(mTaskData);
  };

  const handleTaskStatusDD = (mTaskIndex, mStatus) => {

    console.log("handleTaskStatusDD");

    var mTaskRequestModel = { ...upcomingAndOverdueTasks[mTaskIndex] };
    mTaskRequestModel.status = mStatus;

    if (mStatus == 5) // Mark as Deleted 
    {
      var md = {
        showModal: true,
        isConfirmed: (result) => {
          if (result) {
            setConfirmationModalProps(defaultConfirmationModalProps());
            excuteTaskSaveAjax(mTaskIndex, mTaskRequestModel);
          }
          else {
            setConfirmationModalProps(defaultConfirmationModalProps());
          }
        },
        title: "Delete Task",
        body: "Are you sure you want to delete this Task? This cannot be undone.",
      };
      setConfirmationModalProps(md);
    }
    else {
      let commentSmgValue = getTaskMilestoneStatusDD(mStatus).find((curStatus) => {
        return curStatus.value == mStatus;
      });
      let commentSmg = userSession?.displayName + " marked this task as " + commentSmgValue.text;
      let eleComment = {
        commentID: "",
        userID: mTaskRequestModel.userID,
        comment: commentSmg,
        isStatusChanged: false,
        createdOn: displayDateAndTime(),
        updatedOn: "",
        displayName: userSession?.displayName,
        fileID: "",
        fileName: "",
        documentName: "",
        url: "",
        size: 0,
        displaySize: ""
      }
      console.log(eleComment);
      mTaskRequestModel.comments.push(eleComment);
      excuteTaskSaveAjax(mTaskIndex, mTaskRequestModel);
    }
  };

  const excuteTaskSaveAjax = (mTaskIndex, mTaskModel) => {
    var mTaskData = [...upcomingAndOverdueTasks];
    apiAgent.Deal.saveTaskAjax(mTaskModel)
      .then((res) => {

        var responseTaskModel = res.taskModel;
        var responseDoucmentModel = res.documentModel;

        console.log("Task Model");
        console.log(mTaskModel);
        console.log("responseTaskModel");
        console.log(responseTaskModel);

        if (mTaskModel.status == 5) {
          toast.info("Task deleted successfully.");
          mTaskData.splice(mTaskIndex, 1);
        }
        else {
          toast.info("Task updated successfully.");
          responseTaskModel.stageDD = mTaskModel.stageDD;
          mTaskData.splice(mTaskIndex, 1);
          mTaskData.splice(mTaskIndex, 0, responseTaskModel);
        }
        setUpcomingAndOverdueTasks(mTaskData);
      })
      .catch((err) => {
        console.log(err);
        toast.info("Something Went Wrong");
      });
  };


  const getTaskMilestoneStatusDD = (value) => {
    return [
      {
        text: "Not Started",
        value: 1,
      },
      {
        text: value == 2 ? "In Progress" : "Mark As In Progress",
        value: 2,
      },
      {
        text: value == 3 ? "Issue" : "Mark As Issue",
        value: 3,
      },
      {
        text: value == 4 ? "Completed" : "Mark As Completed",
        value: 4,
      },
      {
        text: "Delete Task",
        value: 5,
      },
    ];
  };

  const dateFormater = (inputDateStr) => {
    const parts = inputDateStr.split("-");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const inputDate = new Date(year, month - 1, day);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dayOfMonth = inputDate.getDate();
    const abbreviatedMonth = monthNames[inputDate.getMonth()];
    const fullYear = inputDate.getFullYear();
    const formattedDateStr = `${dayOfMonth} ${abbreviatedMonth} ${fullYear}`;
    return formattedDateStr;
  };
  const displayDateAndTime = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const now = new Date();

    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    let hours = now.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateAndTime = `${day} ${month} ${year} at ${hours}:${minutes} ${ampm}`;

    return formattedDateAndTime;
  };

  useEffect(() => {
    setIsLoading(true);
    if (filterData.WorkspacePipelineId) {
      apiAgent.Deal.dealDashboardInfoAjax(filterData.WorkspaceAgentId, filterData.WorkspacePipelineId ?? "").then((res) => {
        setDashBoardData(res);
        setUpcomingAndOverdueTasks(res.upcomingAndOverdueTasks);
        let roles = res.assignedRolesDD.map((curRole) => {
          return { text: curRole, value: curRole };
        });
        setAssignedRolesDD(roles);
        setDealGraphData(res.dealGraph);
        setDashboardDealGridModel(res.dashboardDealGridModel);
        setDashboardAverageOfferPrice(res.dashboardAverageOfferPrice);
        setDashboardAverageNettCommission(res.dashboardAverageNettCommission);
        setDealCommissionGraphGraphData(res.dealCommissionGraph);
        setIsLoading(false);
        //console.log(res);
      });
    }
  }, [filterData]);

  const handleWorkspaceDDChange = (wId, wName) => {
    var mFilterData = filterData.getCopy();
    mFilterData.WorkspaceAgentId = wId;
    mFilterData.WorkspaceAgentName = wName;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };

  const handlePipelineChange = (pId, pName) => {
    var mFilterData = filterData.getCopy();
    mFilterData.WorkspacePipelineId = pId;
    mFilterData.WorkspacePipeline = pName;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };


  const getRoleBg = (tag) => {
    // const tagOBJ = props.allTags?.find((curTag) => {
    //   return curTag.name == tag;
    // });
    // if (tagOBJ) {
    //   return tagOBJ.color;
    // }
    return "#4d4d4d";
  };
  if (isLoading) {
    return <AjaxLoader />;
  }
  return (
    <div className="dashboard_component">
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <div className="dropdown card-body mx-2">
              <h6 className="dropdown-toggle cursor-pointer card-subtitles" data-toggle="dropdown">
                {filterData.WorkspaceAgentName}
                <b className="caret"></b>
              </h6>
              {/* {accessType == 1 ? ( */}
                <ul className="dropdown-menu">
                  {workspaceDD?.map((curStatus, i) => {
                    return (
                      <li key={curStatus.value} onClick={() => handleWorkspaceDDChange(curStatus.Value, curStatus.Text)}>
                        <a>{curStatus.Text}</a>
                      </li>
                    );
                  })}
                </ul>
              {/* ) : null} */}
            </div>
            <div className="dropdown mx-3">
              <span className="dropdown-toggle text-light cursor-pointer" data-toggle="dropdown">
                {" "}
                view: this year <b className="caret"></b>
              </span>
              <ul className="dropdown-menu">
                <li>
                  <a href="#"> this year </a>
                </li>
              </ul>
            </div>
            <div className="dropdown">
              <span className="dropdown-toggle text-light cursor-pointer" data-toggle="dropdown">
                {" "}
                pipeline: {filterData.WorkspacePipeline}
                <b className="caret"></b>
              </span>
              <ul className="dropdown-menu">
                {pipelineDD?.map((curElem, i) => {
                  return (
                    <li key={curElem.Value} onClick={() => handlePipelineChange(curElem.Value, curElem.Text)}>
                      <a >{curElem.Text}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-7 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2">Deals</h6>
              <div className="">
                <table className="dashboard_table">
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ borderLeft: "1px dotted gray" }}>Count</th>
                      <th style={{ borderLeft: "1px dotted gray" }}>Deal value</th>
                      {filterData.WorkspaceAgentId == "C" ? (
                        <th style={{ borderLeft: "1px dotted gray" }}>Gross Commission Deal Total</th>
                      ) : (
                        <th style={{ borderLeft: "1px dotted gray" }}>Gross Commission Your Split</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardDealGridModel?.map((curElem, i) => {
                      return (
                        <tr key={i} style={{ borderTop: "1px dotted gray" }}>
                          <td>{curElem.stageName}</td>
                          <td style={{ borderLeft: "1px dotted gray" }}>{curElem.dealCount}</td>
                          <td style={{ borderLeft: "1px dotted gray" }}>{`${currencySymbol}${ToPriceStringWithoutDecimal(curElem.dealValue)}`}</td>
                          {filterData.WorkspaceAgentId == "C" ? (
                            <td style={{ borderLeft: "1px dotted gray" }}>{`${currencySymbol}${ToPriceStringWithoutDecimal(
                              curElem.grossCommissionOfDeals
                            )}`}</td>
                          ) : (
                            <td style={{ borderLeft: "1px dotted gray" }}>{`${currencySymbol}${ToPriceStringWithoutDecimal(
                              curElem.grossCommissionOfSplits
                            )}`}</td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle text-center mb-2">Average offer price</h6>
              <h2 className="card-subtitle text-center mb-2">{`${currencySymbol}${ToPriceStringWithoutDecimal(
                dashboardAverageOfferPrice?.averageOfferPrice
              )}`}</h2>
              <p className="text-center">You achieved an average {dashboardAverageOfferPrice?.achievedOfferPricePercentage}% of the asking price.</p>
              <div className="d-flex align-items-center justify-content-center">
                <h3 className="tag1_lg px-3 bg-oms1">
                  <p>{`${currencySymbol}${ToPriceStringWithoutDecimal(dashboardAverageOfferPrice?.highestOfferAmount)}`}</p>
                  <p className="m-0">highest offer</p>
                </h3>
                <h3 className="tag2_lg px-3 bg-oms">
                  <p>{`${currencySymbol}${ToPriceStringWithoutDecimal(dashboardAverageOfferPrice?.lowestOfferAmount)}`}</p>
                  <p className="m-0">lowest offer</p>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-7 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2">Gross Commission Pipeline</h6>
              <Chart data={dealCommissionGraphGraphData} dataKey={"monthName"} barKey={"commission"} currencySymbol={currencySymbol} />
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-center">Average nett commission</h6>
              <h2 className="card-subtitle text-center mb-2">{dashboardAverageNettCommission?.averageNettCommission}%</h2>
              <div className="d-flex align-items-center justify-content-center">
                <h3 className="tag1_lg px-3 bg-oms1">
                  <p>{dashboardAverageNettCommission?.highestOfferPercentage}%</p>
                  <p className="m-0">highest offer</p>
                </h3>
                <h3 className="tag2_lg px-3 bg-oms">
                  <p>{dashboardAverageNettCommission?.lowestOfferPercentage}%</p>
                  <p className="m-0">lowest offer</p>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-7 col-md-6 my-1">
          <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2">Your Upcoming & Overdue Tasks</h6>
              <div className="row">
                <div className="col-md-12">
                  <div>
                    {upcomingAndOverdueTasks?.map((curTask, i) => {
                      return (
                        <div key={i} className="d-flex align-items-center mb-2">
                          <div className={`taskdd bg${curTask.status}status`} style={{ maxWidth: "150px" }}>
                            <OMSDropdown
                              id={"status"}
                              options={getTaskMilestoneStatusDD(curTask.status)}
                              valueField="value"
                              labelField="text"
                              value={curTask.status}
                              onChange={(e) => handleTaskStatusDD(i, e.newValue)}
                            />
                          </div>
                          <span onClick={() => onUpdateTaskModelOpen(curTask)} className="sub_headers mx-2">
                            {`${curTask.taskName} expected on `}
                            <b>{curTask.stageExpected ? dateFormater(curTask.stageExpected) : ""}</b>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <TaskModel
                    modelId="dashboardTaskModel"
                    ref={taskModelRef}
                    isBreadCrumb={true}
                    isTaskEdit={true}
                    tasksStageDD={stageDD}
                    contacts={[]}
                    assignedRolesDD={assignedRolesDD}
                    onSave={onTaskSave}
                    accessType={accessType}
                    getRoleBg={getRoleBg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 my-1">
          {/* <div className="card p-30">
            <div className="card-body">
              <h6 className="card-subtitle mb-2">Top Performers</h6>
              <div className="row">
                <div className="col-md-6">
                  <span className="sub_headers">By Deals</span>
                  {dashBoardData?.byDeals?.map((curDeal, i) => {
                    return (
                      <div key={i} className="performer_container mt-1">
                        <span className="performer_name">{curDeal.name}</span>
                        <span className="performer_data">{curDeal.deal} Deals</span>
                      </div>
                    );
                  })}
                </div>
                <div className="col-md-6">
                  <span className="sub_headers">By Commission</span>
                  {dashBoardData?.byCommission?.map((curDeal, i) => {
                    return (
                      <div key={i} className="performer_container mt-1">
                        <span className="performer_name">{curDeal.name}</span>
                        <span className="performer_data">R {ToPriceString(curDeal.amount)}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ConfirmationModalPopup {...confirmationModalProps} />
    </div>
  );
};

export default DealDashboard;