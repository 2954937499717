import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { DataTableComp, DataTableLoading } from "../../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../../app/api/apiAgent";
import DetailModalPopup from "../../../../app/common/common-components/DetailModalPopup";
import PropertyReportsStore from "../../../../app/stores/propertyReportsStore";
import { ClipLoader } from "react-spinners";
import { PropertyReportModel } from "../../../../app/models/PropertyReportModel";
import { toast } from "react-toastify";
// interface PropertyReportModel {
//   propertyId: string;
//   streetAddress: string;
//   propertyType: string;
//   reportGenerationDate: string;
//   reportId: string;
//   ownerDetails: string;
//   ownerDetailsGenerationDate: string;
// }

interface Props {
  isLoading: boolean;
  propertyReportsStore: PropertyReportsStore;
}

const CenteredSpinner: React.FC = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  }}>
    <ClipLoader size={50} color={"#ffffff"} loading={true} />
  </div>
);

const PreviousReportsComponent: React.FC<Props> = observer(({ isLoading, propertyReportsStore }) => {
  const [showOwnerDetailsModal, setShowOwnerDetailsModal] = useState(false);
  const [selectedOwnerDetails, setSelectedOwnerDetails] = useState<{ [key: string]: any } | null>(null);
  const [loadingReportId, setLoadingReportId] = useState<string | null>(null);
  const dataTableRef = useRef<DataTableComp>(null);

  useEffect(() => {
    //console.log("inside PreviousReportsComponent, propertyReports: ", propertyReportsStore.propertyReports);
  }, [propertyReportsStore.propertyReports]);

  const generateFileName = (streetAddress: string): string => {
    let address = streetAddress || "Unknown Address";
    if (address.length > 35) {
      address = address.substring(0, 35);
    }
  
    const formattedAddress = address.trim().replace(/\s+/g, " ");
    const date = new Date().toLocaleDateString("en-GB", { year: "numeric", month: "short", day: "2-digit" }).replace(/ /g, " ");
    return `Property Insights - ${formattedAddress} - ${date}.pdf`;
  };


  const handleReportLinkClick = (reportId: string) => {
    setLoadingReportId(reportId);
  
    const selectedReport = propertyReportsStore.propertyReports.find((report) => report.reportId === reportId);
    if (!selectedReport) {
      toast.error("Report not found!");
      setLoadingReportId(null);
      return;
    }
  
    const fileName = generateFileName(selectedReport.streetAddress);
  
    apiAgent.PropertyReports.downloadReport(reportId)
      .then((response) => {
        const binaryData = [];
        binaryData.push(response.data);
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
  
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error inside listing report", error);
      })
      .finally(() => {
        setLoadingReportId(null);
      });
  };
    
  const handleOwnerLinkClick = (propertyId: string) => {
    const property = propertyReportsStore.propertyReports.find((report) => report.propertyId === propertyId);

    if (property && property.ownerDetails) {
      const ownerDetailsArray: Array<{ [key: string]: any }> = JSON.parse(property.ownerDetails);

      if (ownerDetailsArray.length > 0) {
        const firstDetail = ownerDetailsArray[0];
        const formattedDetail = formatKeysInObject(firstDetail);
        setSelectedOwnerDetails(formattedDetail);
        setShowOwnerDetailsModal(true);
      }
    }
  };

  const formatKeysInObject = (obj: { [key: string]: any }): { [key: string]: any } => {
    const formattedObj: { [key: string]: any } = {};
    Object.entries(obj).forEach(([key, value]) => {
      const formattedKey = key.replace(/([A-Z])/g, " $1").trim().replace(/^./, (str) => str.toUpperCase());
      formattedObj[formattedKey] = value;
    });
    return formattedObj;
  };

  const columns = [
    { data: "streetAddress", title: "Street Address", className: "dt-center" },
    { data: "propertyType", title: "Property Type", className: "dt-center" },
    {
      data: null,
      title: "Purchased Reports",
      className: "dt-center",
      render: (data: any, type: any, row: PropertyReportModel) => {
        const propertyLink = row.reportGenerationDate
          ? `<a href="#" class="report-link" data-report-id="${row.reportId}" style="text-decoration: underline;">
              Property (${new Date(row.reportGenerationDate)
                .toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" })
                .replace(/\//g, "-")})
            </a>`
          : "";
  
        const ownerLink = row.ownerDetailsGenerationDate
          ? `<a href="#" class="owner-link" data-property-id="${row.propertyId}" style="text-decoration: underline;">
              Owner (${new Date(row.ownerDetailsGenerationDate)
                .toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" })
                .replace(/\//g, "-")})
            </a>`
          : "";
  
        if (propertyLink && ownerLink) {
          return `${propertyLink}, ${ownerLink}`;
        } else {
          return propertyLink || ownerLink || "N/A";
        }
      },
    },
  ];

  useEffect(() => {
    const tableId = "#previousReportsTable";
    $(tableId).on("click", ".report-link", function () {
      const reportId = $(this).data("report-id").toString();
      handleReportLinkClick(reportId);
    });

    $(tableId).on("click", ".owner-link", function () {
      const propertyId = $(this).data("property-id").toString();
      handleOwnerLinkClick(propertyId);
    });

    return () => {
      $(tableId).off("click", ".report-link");
      $(tableId).off("click", ".owner-link");
    };
  }, [propertyReportsStore.propertyReports]);

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.reloadTableData(propertyReportsStore.propertyReports);
    }
  }, [propertyReportsStore.propertyReports]);

  return (
    <div className="panel-body table-responsive left-align">
       <DataTableComp
        ref={dataTableRef}
        id="previousReportsTable"
        data={propertyReportsStore.propertyReports}
        columns={columns}
        columnDefs={[]}
        order={[]} // Remove the default sorting
        ordering={false} // Disable sorting
        showSearchBox={false}
      />
      <DataTableLoading loading={isLoading} />

      <DetailModalPopup
        show={showOwnerDetailsModal}
        onClose={() => setShowOwnerDetailsModal(false)}
        title="Owner Details"
        data={selectedOwnerDetails}
      />

      {loadingReportId && <CenteredSpinner />}
    </div>
  );
});

export default PreviousReportsComponent;