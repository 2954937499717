import React, { useEffect, useState } from "react";
import apiAgent from "../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSImageUpload } from "../../app/common/common-components/OMSImageUpload";
import DatePicker from "react-datepicker";
import { OMSTabBox } from "../../app/common/common-components/OMSTabBox";
import { toast } from "react-toastify";
import TinyMCEEditor from "../../app/common/common-components/TinyMCEEditor";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from 'react-router-dom';
import SortableList, { SortableItem } from "react-easy-sort";

const moment = require("moment");

class ContentBox extends React.Component {
  state = {
    OfficeID: this.props.data.officeID,
    OfficeSyncID: this.props.data.officeSyncID,
    ContentID: this.props.data.contentID,
    UserID: this.props.data.userID,
    Title: this.props.data.title,
    Summary: this.props.data.summary,
    Category: this.props.data.category,
    PublishDate: this.getFormattedPublishDate(),
    Tags: this.props.data.tags ? this.props.data.tags.split(",") : [],
    Thumbnail: this.props.data.thumbnail,
    ThumbnailUrl: this.props.data.thumbnailUrl,
    ThumbnailUpload: this.props.data.thumbnailUpload,
    ContentFile: this.props.data.contentFile,
    Content: this.props.data.content,
    Gallery: this.props.data.gallery,
    Author: this.props.data.author,
    ButtonStatus: {
      disabled: false,
      processing_save: false,
      processing_delete: false,
    },
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => { },
      title: "",
      body: "",
    },
    activeTabID: "overview",
    noticeBoardContent: this.props.data.content,
    resizeTrigger: 0

  };
  handleResize = () => {
    // Force a re-render by changing the resizeTrigger state
    console.log("Window resized, reinitializing sortable...");
    this.setState((prevState) => ({
      resizeTrigger: prevState.resizeTrigger + 1
    }));
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {

    window.removeEventListener("resize", this.handleResize);
  }

  getFormattedPublishDate() {
    const date = this.props.data.publishDate;
    let format = "DD-MM-yyyy hh:mm A";
    if (date) {
      let isoDateString = moment(date, format).toISOString();
      return new Date(isoDateString);
    } else return null;

  }


  componentDidUpdate(prevProps, prevState) { }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onCancelClick = (e) => {
    this.props.history.push('/Library?m=news')
  };

  executeDeleteClick = (e) => {
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: false,
        processing_delete: true,
      },
    });

    apiAgent.Library.deleteContentAjax(this.state.ContentID, this.state.OfficeID)
      .then((response) => {
        this.props.history.push('/Library?m=news');
      })
      .catch((error) => {
        toast.info("Fail");
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      });
  };

  onDeleteClick = (e) => {
    let title = "Delete content?";
    let body = "Do you want to delete content now? This cannot be undone.";
    let callback = this.executeDeleteClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  getGalleryJsonString = () => {
    let galleryArray = [];
    for (var i = 0; i < this.state.Gallery.length; i++) {
      let galleryItem = {};

      if (this.state.Gallery[i].fileName.indexOf("NAP") != -1) {
        galleryItem.fileName = "";
        galleryItem.dataUrl = this.state.Gallery[i].dataUrl;
      } else {
        galleryItem.fileName = this.state.Gallery[i].fileName;
        galleryItem.dataUrl = "";
      }

      galleryItem.description = this.state.Gallery[i].description;
      galleryItem.order = this.state.Gallery[i].order;

      galleryArray.push(galleryItem);
    }

    let jsonString = JSON.stringify(galleryArray);
    return jsonString;
  };

  getFormData = () => {

    var data = new FormData();
    data.append("officeID", this.state.OfficeID);
    data.append("officeSyncID", this.state.OfficeSyncID);
    data.append("userID", this.state.UserID);
    data.append("contentID", this.state.ContentID);
    data.append("title", this.state.Title);
    data.append("summary", this.state.Summary);
    data.append("category", this.state.Category);
    data.append("publishDate", this.state.PublishDate.toISOString());
    data.append("tags", this.state.Tags.join(","));

    data.append("thumbnail", this.state.Thumbnail);
    data.append("thumbnailUrl", this.state.ThumbnailUrl);
    data.append("thumbnailUpload", this.state.ThumbnailUpload);

    data.append("contentFile", this.state.ContentFile);
    data.append("author", this.state.Author);
    data.append("galleryJson", this.getGalleryJsonString());


    return data;
  };

  checkFormData = (e) => {
    var isValid = true;

    if (!this.state.Title) {
      toast.info("Please enter title");
      isValid = false;
    }

    if (!this.state.Summary) {
      toast.info("Please enter summary");
      isValid = false;
    }

    if (!this.state.Category) {
      toast.info("Please select category");
      isValid = false;
    }
    return isValid;
  };

  OnCropSubmit = (d) => {
    this.setState({
      ThumbnailUpload: d,
    });
  };

  handleContentCategoryDDChange = (e) => {
    this.setState({
      Category: e.newValue,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleTagsClick = (name) => {
    var selectedTags = this.state.Tags.slice();
    var index = selectedTags.indexOf(name);
    if (index < 0) {
      selectedTags.push(name.trim());
    } else {
      selectedTags.splice(index, 1);
    }
    this.setState({
      Tags: selectedTags,
    });
  };

  doSave = (e) => {
    var isValid = this.checkFormData();
    if (isValid) {
      var data = this.getFormData();
      const formDataObj = Object.fromEntries(data.entries());
      formDataObj.content = this.state.noticeBoardContent;
      this.setState({
        ButtonStatus: {
          disabled: true,
          processing_save: true,
          processing_delete: false,
        },
      });


      apiAgent.Library.saveContentAjax(formDataObj)
        .then((response) => {
          this.props.history.push('/library?m=news');
        })
        .catch((error) => {
          console.log("error", error);
          toast.info("Fail");
          this.setState({
            ButtonStatus: {
              disabled: false,
              processing_save: false,
              processing_delete: false,
            },
          });
        });
    }
  };

  handleAddPhotosClick = (e) => {
    window.$("#uploadGalleryPhoto").click();
  };

  handleUploadFileChange = (e) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    var arrFiles = [];
    if (files !== undefined) {
      for (var i = 0; i < files.length; i++) {
        arrFiles[i] = files[i];
      }
    }
    this.setFiles(arrFiles);
  };

  setFiles = (files) => {
    if (files != undefined && files.length > 0) {
      this.setState({ Files: files }, function () {
        var reader = new FileReader();
        reader.onloadend = function (e) {
          var files = this.state.Files;
          if (e.target.readyState == FileReader.DONE) {
            this.onUploadFileReaderLoaded(files[0], e.target.result);
          }
          files = files.slice(1);
          this.setFiles(files);
        }.bind(this);
        reader.readAsDataURL(this.state.Files[0]);
      });
    } else {
      this.setState({ Files: [] });
      window.$("#uploadPhoto").val("");
    }
  };

  onUploadFileReaderLoaded = (file, dataUrl) => {
    var img = new Image();
    img.onload = function () {
      var height = img.height;
      var width = img.width;
      //1024x768
      if (width < 200 || height < 200) window.$.toaster("Photo requirements not met: photos should have a minimum width of 1024px and minimum height of 786px");
      else {
        var quality = 1;
        var fileSize = file.size / 1024; // in Kb
        if (fileSize <= 500) quality = 1;
        else if (fileSize > 500 && fileSize <= 1000) quality = 0.9;
        else if (fileSize > 1000 && fileSize <= 2000) quality = 0.85;
        else if (fileSize > 2000 && fileSize <= 4000) quality = 0.75;
        else quality = 0.7;
        var compressedDataUrl = this.clientCompress(img, quality);

        var gallery = this.state.Gallery;
        var photoItem = {
          fileName: "NAP1",
          description: "",
          order: 1,
          dataUrl: compressedDataUrl, //dataUrl
        };

        if (gallery.length > 0) {
          var maxOrder = Math.max.apply(
            Math,
            gallery.map(function (o) {
              return o.order;
            })
          );
          photoItem.order = maxOrder + 1;
          photoItem.fileName = "NAP" + photoItem.order;
        }
        gallery.push(photoItem);
        this.setState({ Gallery: gallery });
        //window.$("#js-grid").append(this.getPhotoHtml(photoItem));
        //this.doBindSortable();
      }
    }.bind(this);
    img.src = dataUrl;
  };

  clientCompress = (source_img_obj, quality) => {
    var mime_type = "image/jpeg";
    var maxWidth = 1920;
    var natW = source_img_obj.naturalWidth;
    var natH = source_img_obj.naturalHeight;

    var ratio = natH / natW;
    if (natW > maxWidth) {
      natW = maxWidth;
      natH = ratio * maxWidth;
    }

    var cvs = document.createElement("CANVAS");
    cvs.width = natW;
    cvs.height = natH;
    var ctx = cvs.getContext("2d").drawImage(source_img_obj, 0, 0, natW, natH);
    return cvs.toDataURL(mime_type, quality);
  };

  onRemoveGalleryPhoto = (fileName) => {
    console.log(" Remove : ", fileName);

    var gallery = this.state.Gallery;
    var index = -1;
    for (var i = 0; i < gallery.length; i++) {
      if (gallery[i].fileName == fileName) {
        index = i;
        break;
      }
    }

    if (index != -1) {
      gallery.splice(index, 1);
      this.setState({ Gallery: gallery });
    }
  };
  onDeleteClicked = (fileName) => {
    let title = "Delete content?";
    let body = "Do you want to delete content now? This cannot be undone.";
    let callback = () => this.onRemoveGalleryPhoto(fileName);
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  }

  onEditGalleryPhoto = (fileName) => {
    var gallery = this.state.Gallery;
    var index = -1;
    for (var i = 0; i < gallery.length; i++) {
      if (gallery[i].fileName == fileName) {
        index = i;
        break;
      }
    }

    if (index != -1) {
      window.$("#m_FileName").val(gallery[index].fileName);
      window.$("#m_Description").val(gallery[index].description);
      window.$("#PhotoUploadModal").modal("show");
    }
  };

  onSaveGalleryPhoto = () => {
    var fileName = window.$("#m_FileName").val();
    var gallery = this.state.Gallery;
    let desc = window.$("#m_Description").val();

    this.setState(prevState => ({
      Gallery: prevState.Gallery.map(photo =>
        photo.fileName === fileName
          ? { ...photo, description: desc }
          : photo
      )
    }));


    window.$("#PhotoUploadModal").modal("hide");

  };

  handleOnMainPhotoContainerClick = (e) => {
    if (e.target.tagName.toLowerCase() === "i") {
      if (e.target.dataset !== undefined && e.target.dataset.for !== undefined && e.target.dataset.id !== undefined) {
        if (e.target.dataset.for == "delete") this.onRemoveGalleryPhoto(e.target.dataset.id);
        else if (e.target.dataset.for == "edit") this.onEditGalleryPhoto(e.target.dataset.id);
      }
    }
  };

  setActiveTabId = (tabId) => {
    //console.log("tabID", tabId);
    this.setState({ activeTabID: tabId });
  };

  handleNoticeboardContentChange = (content) => {
    //console.log("handleNoticeboardContentChange", content);
    this.setState({ noticeBoardContent: content });
  };

  handleUploadFile = (blobInfo, success, failure) => {
    return new Promise((resolve, reject) => {
      let data = new FormData();
      data.append("fileBase64String", blobInfo.base64());
      data.append("fileName", blobInfo.filename());

      let formData = Object.fromEntries(data.entries());

      apiAgent.Library.uploadLibraryImageAjax(formData)
        .then((response) => {
          if (response.success) {
            resolve(response.location);
          } else {
            reject("Image upload failed");
          }
        })
        .catch((error) => {
          console.log("upload error", error);
          reject("Image upload failed");
        });

    });
  };

  array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  onSortEnd = (oldIndex, newIndex) => {

    let items = Array.from(this.state?.Gallery);

    items = this.array_move(items, oldIndex, newIndex);

    items.forEach((item, index) => {
      item.order = index + 1;
    });



    this.setState({
      Gallery: items
    });

  };
  onHandleAction = (action, photoItem) => {
    console.log(" Photo Item : ", photoItem);

    switch (action) {

      case "edit":
        this.onEditGalleryPhoto(photoItem.fileName);
        break;

      case "delete":
        this.onDeleteClicked(photoItem.fileName);
        break;


    }

  }

  render() {
    var self = this;
    var tabContent = [
      { link: "#overview", title: "Overview" },
      { link: "#contentbody", title: "Content" },
      { link: "#gallery", title: "Gallery" },
    ];
    return (
      <div>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <form className="SaveContentForm" onSubmit={this.handleSubmit}>
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <label>{this.state.ContentID ? "SAVE" : "CREATE"}</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="col-md-12">
                  <OMSTabBox id="contentTabs" value={tabContent} setActiveTabId={this.setActiveTabId}></OMSTabBox>
                  <div className="tab-content">
                    <div id="overview" className={this.state.activeTabID === "overview" ? "tab-pane fade in active" : "tab-pane fade"}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Title</label>
                            <input
                              id="Title"
                              name="Title"
                              type="text"
                              className="form-control required-control"
                              value={this.state.Title}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>Summary</label>
                            <textarea rows={5} className="form-control" id="Summary" name="Summary" value={this.state.Summary} onChange={this.handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Category</label>
                            <OMSDropdown
                              id="Category"
                              name="Category"
                              options={this.props.ContentCategories}
                              valueField="value"
                              labelField="text"
                              value={this.state.Category}
                              onChange={this.handleContentCategoryDDChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>Publish Date</label>

                            <div className="input-group">
                              <DatePicker
                                selected={this.state.PublishDate}
                                onChange={(date) => this.setState({ PublishDate: date })}
                                //selectsStart
                                timeInputLabel="Time:"
                                dateFormat="dd-MM-yyyy h:mm aa"
                                //startDate={this.state.startDate}
                                //endDate={this.state.endDate}
                                showTimeInput
                              //value={this.state.PublishDate}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Tags</label>
                            <p>
                              {this.props.ContentTags?.map(function (item) {
                                return self.state.Tags.indexOf(item.name.trim()) != -1 ? (
                                  <span
                                    key={item.name}
                                    className="btn btn-tag-click"
                                    style={{ backgroundColor: item.color }}
                                    onClick={() => self.handleTagsClick(item.name)}
                                  >
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                    {item.name}
                                  </span>
                                ) : (
                                  <span
                                    key={item.name}
                                    className="btn btn-tag-click"
                                    style={{
                                      backgroundColor: "#ccc",
                                      color: "#333",
                                    }}
                                    onClick={() => self.handleTagsClick(item.name)}
                                  >
                                    {item.name}
                                  </span>
                                );
                              })}
                              ;
                            </p>
                          </div>
                          <div className="form-group">
                            <label>Author or Source Link</label>
                            <input id="Author" name="Author" type="text" className="form-control" value={this.state.Author} onChange={this.handleChange} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Thumbnail</label>
                            <OMSImageUpload src={this.state.ThumbnailUrl} compressor={true} width={200} height={160} onCropSubmit={this.OnCropSubmit} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="contentbody" className={this.state.activeTabID === "contentbody" ? "tab-pane fade in active" : "tab-pane fade"}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <TinyMCEEditor
                              value={this.state.noticeBoardContent}
                              onEditorChange={this.handleNoticeboardContentChange}
                              handleUpload={this.handleUploadFile}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="gallery" className={this.state.activeTabID === "gallery" ? "tab-pane fade in active" : "tab-pane fade"}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <div className="row">
                                <div className="col-md-12">
                                  <a style={{ cursor: "pointer" }} className="btn btn-success-oms pull-right" onClick={this.handleAddPhotosClick}>
                                    Add photos
                                  </a>
                                  <input
                                    type="file"
                                    id="uploadGalleryPhoto"
                                    name="uploadGalleryPhoto"
                                    accept="image/gif, image/jpeg, image/png"
                                    style={{ display: "none" }}
                                    onChange={this.handleUploadFileChange}
                                    multiple={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="panel-body">
                              <div className="col-md-12">
                                <div id="js-grid" className="js-grid" onClick={this.handleOnMainPhotoContainerClick}></div>
                                <SortableList
                                  key={this.state.resizeTrigger} // Reset the sortable list when `resizeTrigger` changes
                                  onSortEnd={this.onSortEnd}
                                  className="list"
                                >
                                  <div>
                                    {
                                      this.state?.Gallery?.map((item, index) => {
                                        return (
                                          <SortableItem key={item.fileName}>

                                            <div id={item.fileName}
                                              class="media-img-box">
                                              <a
                                                class="img-thumbnail img-thumbnail-photo"
                                                style={{ overflow: "hidden" }}>
                                                <img
                                                  id={`img ${item.fileName}`}
                                                  class="img-view"
                                                  style={{
                                                    objectFit: "cover",
                                                    height: "180px",
                                                    width: "270px"
                                                  }}
                                                  draggable="false"
                                                  data-id={item.fileName}
                                                  data-degrees="0"
                                                  src={item.dataUrl}
                                                  alt=""
                                                />
                                                <div class="caption">

                                                  <label
                                                    id={`desc ${item.fileName}`}
                                                    class="photo-description"
                                                  >
                                                    {
                                                      item.description == "" ? "no description added" : item.description
                                                    }
                                                  </label>
                                                  <div class="pull-right">

                                                    <span
                                                      style={{
                                                        cursor: "pointer",
                                                        marginRight: "5px"
                                                      }}>
                                                      <i class="fa fa-edit"
                                                        data-for="edit"
                                                        onClick={() => this.onHandleAction("edit", item)}

                                                        data-id={item.fileName}
                                                        aria-hidden="true">
                                                      </i>
                                                    </span>
                                                    <span
                                                      style={{
                                                        cursor: "pointer",
                                                        marginRight: 5 + "px"
                                                      }}>
                                                      <i class="fa fa-close"
                                                        data-for="delete"
                                                        onClick={() => this.onHandleAction("delete", item)}

                                                        data-id={item.fileName}
                                                        aria-hidden="true">
                                                      </i>
                                                    </span>
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                          </SortableItem>

                                        )
                                      })
                                    }
                                  </div>
                                </SortableList>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="PhotoUploadModal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="PhotoUploadModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                                ×
                              </button>
                              <h4 className="modal-title" id="PhotoUploadModalLabel">
                                Photo description
                              </h4>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="form-group col-md-12">
                                  <label htmlFor="m_Description">Description</label>
                                  <input type="hidden" id="m_FileName" name="m_FileName" />
                                  <textarea rows={2} className="form-control" id="m_Description" name="m_Description" />
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <OMSButtonStyleL
                                type="button"
                                style={{ marginRight: 10 }}
                                ui_icon="fa-check"
                                ui_type="save"
                                ui_text="Save"
                                ui_processing_text="Saving.."
                                processing={false}
                                disabled={false}
                                onClick={this.onSaveGalleryPhoto}
                              />
                              <button type="button" className="btn btn-default" data-dismiss="modal">
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-left">
                      {this.state.ContentID != "" && this.props.content_access ? (
                        <div className="dropup">
                          <button className="btn btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                            More..
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu" style={{ width: "auto" }}>
                            <li>
                              {" "}
                              <OMSButtonStyleL
                                type="button"
                                ui_icon="fa-trash-o"
                                ui_type="delete"
                                ui_text="Delete content"
                                ui_processing_text="Deleting.."
                                processing={this.state.ButtonStatus.processing_delete}
                                disabled={this.state.ButtonStatus.disabled}
                                onClick={this.onDeleteClick}
                              />
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={this.state.ButtonStatus.disabled}
                        onClick={this.onCancelClick}
                      />

                      <OMSButtonStyleL
                        type="button"
                        ui_icon="fa-check"
                        ui_type="save"
                        ui_text="Save"
                        ui_processing_text="Saving.."
                        processing={this.state.ButtonStatus.processing_save}
                        disabled={this.state.ButtonStatus.disabled}
                        onClick={this.doSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(ContentBox);
