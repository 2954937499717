import $ from 'jquery';
/// <reference path="main.js" />
$(document).ready(function () {
    if ($(window).width() > 1200) {

        $("#search-input").focus(function () {
            $("#search-text-group").css("width", "350px");
        })

        $("#search-input").blur(function () {
            var exist = $(this).val();
            if (exist.length == 0) {
                $("#search-text-group").css("width", "220px");
            }
        });
    }
    

    //$("#search-icon-btn").click(function (e) {
    //    open_search_modal();
    //});

    //$("#btn-mobile-menu").click(function (e) {
    //    toggle_mobile_menu();
    //});

    //$("#btn-show-searchbar").click(function (e) {
    //    show_search_bar();
    //});
});

 

function toggle_mobile_menu() {
    $(".mobile-menu").toggle();
}

function show_search_bar() {
    // need to check here if search is empty or not
    var text = $('#search-input').val();
    if (text == '') {
        $('.hidden-search-bar').toggle();
        $(".custom-dropdown").toggle();
    }
    else
    {
        open_search_modal();
    }
}


function open_search_modal() {
    var text = $('#search-input').val();
    if (text != '') {
        $("#myModal").modal('show');
    }
}
//table and desktop
//$(document).ready(function () {
    //$("#search-input").keydown(function (e) {
    //    var code = e.which; // recommended to use e.which, it's normalized across browsers
    //    if (code == 13) {
    //        e.preventDefault();
    //        open_search_modal();
    //    };
    //});
//});
//for mobile
//$(document).ready(function () {
    //$("#hidden-search-input").keydown(function (e) {
    //    var code = e.which; // recommended to use e.which, it's normalized across browsers
    //    if (code == 13) {
    //        e.preventDefault();
    //        open_search_modal();
    //    };
    //});
//});


//mobile tab
//var autocollapse = function () {
//    var tabs = $('#tabs');
//    if (tabs == undefined) {
//        alert('catch');
//    }
//    else {
//        var tabsHeight = tabs.innerHeight();
//        if (tabsHeight >= 50) {
//            $('#lastTab').show();
//            while (tabsHeight > 50) {
//                var children = tabs.children('li:not(:last-child)');
//                var count = children.length;
//                $(children[count - 1]).prependTo('#collapsed');
//                tabsHeight = tabs.innerHeight();
//            }
//        }
//        else {
//            while (tabsHeight < 50 && ($('#collapsed').children('li').length > 0)) {
//                var collapsed = $('#collapsed').children('li');
//                var count = collapsed.length;
//                $(collapsed[0]).insertBefore(tabs.children('li:last-child'));
//                tabsHeight = tabs.innerHeight();
//            }
//            if (tabsHeight > 50) { // double chk height again
//                autocollapse();
//            }
//        }

//        var collapsed = $('#collapsed').children('li');
//        var count = collapsed.length;
//        if (count <= 0) {
//            $('#lastTab').hide();
//        }
//    }
//};

//$(document).ready(function () {
//    autocollapse(); // when document first loads
//    $(window).on('resize', autocollapse); // when window is resized
//    $(".dropdown-menu li").click(function () {
//        $("#tabs li").removeClass("active")
//        $("#tabs-dropdown li").removeClass("active")
//        $(this).addClass("active")
//    });
//});

//changing font awesome color on hover
//list part


//user menu part
//$(document).ready(function(){
//    if ($(window).width() > 768){

//        $(".user1").hover(function(){
//            $(this).find('.user-font1').css('color', '#92ab1c');
//        }, function(){
//            $(this).find('.user-font1').css('color', '#b4b4b4');
//        });
//        $(".user2").hover(function(){
//                $(this).find('.user-font2').css('color', '#ffffff');
//        }, function(){
//            $(this).find('.user-font2').css('color', '#4b4b4d');
//        });
//    }
//});










