import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
import bootbox from "bootbox";
import auth2 from "react-gapi-auth2";

export class UserIntegrationBox extends React.Component {
  state = {
    userID: this.props.UserIMAP.userID,
    Username: this.props.UserIMAP.username,
    Password: this.props.UserIMAP.password,
    Server: this.props.UserIMAP.server,
    Port: this.props.UserIMAP.port,
    IsEntryExists: this.props.UserIMAP.isEntryExists,
    ScanStatus: this.props.UserIMAP.scanStatus,
    ScanLastDate: this.props.UserIMAP.scanLastDate,
    loading: false,
    loadingG: false,
    GmailScanStatus: this.props.UserIMAP.gmailScanStatus,
    GmailScanLastDate: this.props.UserIMAP.gmailScanLastDate,
    hasGmailError: this.props.UserIMAP.hasGmailError,
    EmailIntegrationType: this.props.UserIMAP.emailIntegrationType,
  };

  constructor(props) {
    super(props);
  }

  getStateData = () => {
    return this.state;
  };

  handleChange = (e) => {
    //console.log("inside User Integration Box handleChange, e:", e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleEmailIntegrationType = (e) => {
    var EmailIntegrationType = 1;
    if (e.target.value == "on" && e.target.id == "rbtnIMAPIntegration") {
      EmailIntegrationType = 2;
    }

    this.setState({
      EmailIntegrationType: EmailIntegrationType,
    });
  };

  onConnectWithGmailClick = (e) => {
    if (auth2 !== undefined) {
      auth2.grantOfflineAccess().then(
        function singInCallback(authResult) {
          if (authResult["code"]) {
            this.setState({ loadingG: true });

            apiAgent.Setting.saveAuthCodeAjax(this.state.userID, authResult["code"])
              .then((response) => {
                this.setState({
                  loadingG: false,
                  hasGmailError: false,
                  GmailScanStatus: 1,
                  GmailScanLastDate: "",
                });
              })
              .catch((error) => {
                this.setState({ loadingG: false });
                toast.info("Fail");
              });
          } else {
            toast.info("Some error occured, Please try again.");
          }
        }.bind(this)
      );
    }
  };
  onDisconnectWithGmailClick = (e) => {
    bootbox.confirm({
      title: "Disconnect with Google?",
      message: "Are you sure want to disconnect with Google?",
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Cancel',
        },
        confirm: {
          label: '<i class="fa fa-check"></i> Confirm',
        },
      },
      callback: function (result) {
        if (result) {
          this.executeDisconnectWithGmailClick();
        }
      }.bind(this),
    });
  };

  executeDisconnectWithGmailClick = (e) => {
    this.setState({ loadingG: true });

    apiAgent.Setting.clearAuthCodeAjax(this.state.userID)
      .then((response) => {
        this.setState({
          loadingG: false,
          hasGmailError: false,
          GmailScanStatus: 0,
          GmailScanLastDate: "",
        });
      })
      .catch((error) => {
        this.setState({ loadingG: false });
        toast.info("Fail");
      });
  };

  handleTestSettings = (e) => {
    if (this.doValidate()) {
      this.setState({ loading: true });

      apiAgent.Setting.checkIMAPSettingAjax(this.props.UserIMAP)
        .then((response) => {
          toast.info("Your IMAP settings looks good");
        })
        .catch((error) => {
          toast.info("Unable to connect using your IMAP settings");
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  doValidate = () => {
    var RegEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    var failed = false;

    if (!this.state.Username.trim()) {
      toast.info("Please enter username");
      failed = true;
    }

    if (!this.state.Username.trim() || RegEmail.test(this.state.Username) == false) {
      toast.info("Username should be valid email address");
      failed = true;
    }

    if (!this.state.Password.trim()) {
      toast.info("Please enter password");
      failed = true;
    }

    if (!this.state.Server.trim()) {
      toast.info("Please enter server");
      failed = true;
    }

    if (!this.state.Port.trim()) {
      toast.info("Please enter port");
      failed = true;
    }

    return !failed;
  };

  handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.info("Copied the text: " + text);
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <br />
              <h5>
                <b>Calendar Integration</b>
              </h5>
              {this.props.super_access ? (
                <p>
                  Connect your Base calendar to external calendars. Events you load on your Base calendar will automatically be synchronised with your external
                  calendar. <a>Click here for more info.</a>
                </p>
              ) : (
                <p>New update available soon!</p>
              )}
            </div>
          </div>
          {this.props.super_access && (
            <div className="col-md-12">
              <span style={{ marginRight: 10 }}>
                Last scanned:&nbsp;
                {this.state.GmailScanStatus == 0 ? (
                  "Not available"
                ) : this.state.GmailScanStatus == 1 ? (
                  <i className="fa fa-check-circle" style={{ fontSize: 15, color: "green" }}></i>
                ) : (
                  <i className="fa fa-times-circle" style={{ fontSize: 15, color: "red" }}></i>
                )}
                &nbsp;{this.state.GmailScanLastDate}
              </span>
              <br />
              <br />
              {this.state.GmailScanStatus == 1 ? (
                <div>
                  {this.state.loadingG ? (
                    <span className="btn btn-default">
                      <i style={{ fontSize: 14 }} className="fa fa-spin fa-spinner"></i>
                      &nbsp;&nbsp;Disconnecting
                    </span>
                  ) : (
                    <button type="button" onClick={this.onDisconnectWithGmailClick} className="btn btn-default" id="signinButton">
                      <i style={{ fontSize: 14 }} className="fa fa-google"></i>
                      &nbsp;&nbsp;Disconnect from Google
                    </button>
                  )}
                </div>
              ) : (
                <div>
                  {this.state.loadingG ? (
                    <span className="btn btn-success-oms">
                      <i style={{ fontSize: 14 }} className="fa fa-spin fa-spinner"></i>
                      &nbsp;&nbsp;Connecting
                    </span>
                  ) : (
                    <button type="button" onClick={this.onConnectWithGmailClick} className="btn btn-success-oms">
                      <i style={{ fontSize: 14 }} className="fa fa-google"></i>
                      &nbsp;&nbsp;Connect with Google
                    </button>
                  )}
                </div>
              )}
              <br />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <h5>
                <b>Email Integration</b>
              </h5>
              <p>Automatically import all your portal listing leads to Base by setting up email forwarding from your email client.</p>
            </div>
            <div className="form-group">
              <label className="radio-inline">
                <input
                  type="radio"
                  id="rbtnEmailForwarding"
                  name="EmailIntegrationType"
                  checked={this.state.EmailIntegrationType === 1}
                  onChange={this.handleEmailIntegrationType}
                />
                Email forwarding
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  id="rbtnIMAPIntegration"
                  name="EmailIntegrationType"
                  checked={this.state.EmailIntegrationType === 2}
                  onChange={this.handleEmailIntegrationType}
                />
                IMAP integration
              </label>
            </div>
          </div>
          <div
            style={{
              display: this.state.EmailIntegrationType == 1 ? "none" : "block",
            }}
            className="col-md-12"
          >
            <div className="form-group">
              <p>This option will be phased out in 2022, please use email forwarding.</p>
            </div>
            <div className="form-group">
              <span style={{ marginRight: 10 }}>
                Last scanned:&nbsp;
                {this.state.ScanStatus == 0 ? (
                  "Not available"
                ) : this.state.ScanStatus == 1 ? (
                  <i className="fa fa-check-circle" style={{ fontSize: 15, color: "green" }}></i>
                ) : (
                  <i className="fa fa-times-circle" style={{ fontSize: 15, color: "red" }}></i>
                )}
                &nbsp;{this.state.ScanLastDate}
              </span>
              {this.state.loading ? (
                <span>
                  <i class="fa fa-spinner fa-spin"></i>&nbsp;Testing your settings
                </span>
              ) : (
                <a style={{ marginLeft: 5, cursor: "pointer" }} onClick={this.handleTestSettings}>
                  Test your settings
                </a>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="Username">Email address</label>
                <input
                  style={{ maxWidth: 300 }}
                  name="Username"
                  type="text"
                  className="form-control"
                  value={this.state.Username}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Password">Password</label>
                <input
                  style={{ maxWidth: 300 }}
                  name="Password"
                  type="password"
                  autoComplete="Off"
                  className="form-control"
                  value={this.state.Password}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Server">IMAP Server</label>
                <input style={{ maxWidth: 300 }} name="Server" type="text" className="form-control" value={this.state.Server} onChange={this.handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="Port">IMAP Port</label>
                <input style={{ maxWidth: 300 }} name="Port" type="text" className="form-control" value={this.state.Port} onChange={this.handleChange} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: this.state.EmailIntegrationType != 1 ? "none" : "block",
            }}
            className="col-md-12"
          >
            <div className="form-group">
              <p>
                Your unique and secure email forwarding address: <b>{this.state.userID}@entegral.us</b>&nbsp;
                <i style={{ cursor: "pointer" }} className="fa fa-copy" onClick={() => this.handleCopyToClipboard(this.state.userID + "@entegral.us")}></i>
                <br />
                <a href="https://help.entegral.net/en/articles/5193968-configure-email-integration" target="_blank">
                  Click here
                </a>{" "}
                for help in setting this up in your email client.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
