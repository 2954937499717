import React from 'react';
import { NavLink } from 'react-router-dom';

const Breadcrumb = ({ breadcrumbs }) => {
    return (
        <div className="row">
            <ul className="breadcrumb">
                {breadcrumbs.map((breadcrumb, index) => (
                    <li key={index}>
                        {breadcrumb.path ? (
                            breadcrumb.onClick ? (
                                <NavLink to={breadcrumb.path} onClick={breadcrumb.onClick}>{breadcrumb.text}</NavLink>)
                                : (<NavLink to={breadcrumb.path}>{breadcrumb.text}</NavLink>)
                        ) : (
                            <span className="active">{breadcrumb.text}</span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Breadcrumb;