import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
//import '../../assets/styles/base/bootstrap/bootstrap.min.css';
//import "../../app/layout/styles.css";
//import "../../assets/styles/components/_login.scss";
import { Button, Modal } from "react-bootstrap";
import { browserName, browserVersion } from "react-device-detect";
import axios from "axios";

interface Props {
  handleClose: any;
  show: boolean;
}

export default function BrowserInfoModal(props: Props) {
  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    if (props.show) {
      getData();
    }
  }, [props.show]);

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Browser Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your browser: {browserName}, version: {browserVersion} <br />
            Your public IP Address: {ip}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
