import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import $ from 'jquery';
var Bloodhound = require('typeahead.js/dist/bloodhound.js');

$.typeahead = require('typeahead.js');

export class OMSTwitterGeoAutocomplete extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    style: PropTypes.object,
    country: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    id: '',
    name: '',
    country: 'South Africa',
    value: null,
    style: null,
    onChange: undefined,
  };

  state = {
    locationid: '',
  };

  componentDidMount() {
    this.doMapTypeaheadBind();
  }

  doMapTypeaheadBind = () => {
    var AUTOCOMPLETION_URL = 'https://autocomplete.search.hereapi.com/v1/autocomplete';

    var apiKey = 'QJF2sIKVcgIx_0Au3uj74g262vyhqKaE7UwbXSC9jUk'; // Using the new API key

    var params =
      '?q=%QUERY' + // The search text which is the basis of the query
      '&apiKey=' + apiKey;

    var bestPictures = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: AUTOCOMPLETION_URL + params,
        replace: function (url, query) {
          var COUNTRY = document.getElementById('hdnGeoAutocompleteC').value;

          // Use country codes instead of country names
          if (COUNTRY === 'South Africa') COUNTRY = 'ZAF';
          else if (COUNTRY === 'Namibia') COUNTRY = 'NAM';
          else if (COUNTRY === 'Cyprus') COUNTRY = 'CYP';
          else if (COUNTRY === 'Seychelles') COUNTRY = 'SYC';
          // Add more country mappings as needed

          return url.replace('%QUERY', encodeURIComponent(query)) + '&in=countryCode:' + COUNTRY;
        },
        filter: function (response) {
          const filteredData = $.map(response.items, function (item) {
            if (item !== undefined) {
              var arr = item.title.split(',');
              arr.reverse();
              for (var j = 0; j < arr.length; j++) {
                arr[j] = arr[j].trim();
              }
              item.displayText = arr.join(', ');
            }
            return item;
          });
          return filteredData;
        },
        wildcard: '%QUERY',
        limit: 10,
      },
    });

    $('#' + this.props.id).typeahead(null, {
      name: this.props.id,
      display: 'displayText',
      source: bestPictures,
      limit: 8,
      templates: {
        suggestion: function (data) {
          var arr = data.displayText.split(',');
          return (
            '<span><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;&nbsp;<strong>' +
            arr[0] +
            '</strong><small>,' +
            arr.slice(1).join(',') +
            '</small></span>'
          );
        },
      },
    });

    $('#' + this.props.id).bind(
      'typeahead:select',
      function (ev, suggestion) {
        if (this.state.locationid !== suggestion.id) {
          this.setState({ locationid: suggestion.id });
          if (this.props.onChange !== undefined) this.props.onChange(suggestion.id);
        }
      }.bind(this)
    );
  };

  render() {
    return (
      <div className="dropdown">
        <input
          type="hidden"
          data-id={this.props.id}
          id="hdnGeoAutocompleteC"
          name="hdnGeoAutocompleteC"
          value={this.props.country}
        />
        <input
          type="text"
          id={this.props.id}
          name={this.props.name}
          autoComplete="off"
          placeholder="Please identify the listing on the map, start entering the address"
          className="form-control"
        />
      </div>
    );
  }
}
