import apiAgent from "../../../../app/api/apiAgent";

export const loadProvincesDDFromServer = (CountryName, setState, toast) => {
  setState({
    ProvinceDD: [],
    TownDD: [],
    SuburbDD: [],
    ProvinceID: "",
    TownID: "",
    SuburbID: "",
  });
  apiAgent.Setting.provincesDDAjax(CountryName)
    .then((response) => {
      setState({ ProvinceDD: response });
    })
    .catch((error) => {
      toast.info("Fail");
    });
};

export const loadTownsDDFromServer = (ProvinceID, setState, toast) => {
  setState({
    ProvinceID: ProvinceID,
    TownDD: [],
    SuburbDD: [],
    TownID: "",
    SuburbID: "",
  });
  apiAgent.Setting.townsDDAjax(ProvinceID)
    .then((response) => {
      setState({ TownDD: response });
    })
    .catch((error) => {
      toast.info("Fail");
    });
};

export const loadSuburbsDDFromServer = (TownID, setState, toast) => {
  setState({ TownID: TownID, SuburbDD: [], SuburbID: "" });
  apiAgent.Setting.suburbsDDAjax(TownID)
    .then((response) => {
      setState({ SuburbDD: response });
    })
    .catch((error) => {
      toast.info("Fail");
    });
};

export const handleModelSubmit = (data, mode, setState, loadProvincesDDFromServer, loadTownsDDFromServer, loadSuburbsDDFromServer, toast) => {
  let saveLocationMethod = apiAgent.Setting.saveProvinceAjax;
  if (mode === 2) {
    saveLocationMethod = apiAgent.Setting.saveTownAjax;
  } else if (mode === 3) {
    saveLocationMethod = apiAgent.Setting.saveSuburbAjax;
  }

  setState({
    ButtonStatus: {
      disabled: true,
      processing_save: true,
      processing_delete: false,
    },
  });

  saveLocationMethod(data)
    .then((response) => {
      window.$("#SaveModel").modal("hide");
      setState({
        ButtonStatus: {
          disabled: false,
          processing_save: false,
          processing_delete: false,
        },
      });
      if (mode === 2) loadTownsDDFromServer(data.ProvinceID, setState, toast);
      else if (mode === 3) loadSuburbsDDFromServer(data.TownID, setState, toast);
      else loadProvincesDDFromServer(data.Country, setState, toast);
    })
    .catch((error) => {
      toast.info(error.message);
      setState({
        ButtonStatus: {
          disabled: false,
          processing_save: false,
          processing_delete: false,
        },
      });
    });
};

export const executeDeleteProvinceClick = (ProvinceID, CountryName, setState, loadProvincesDDFromServer, toast) => {
  setState({
    ButtonStatus: {
      disabled: true,
      processing_save: false,
      processing_delete: true,
    },
  });

  apiAgent.Setting.deleteProvinceAjax(ProvinceID, CountryName)
    .then((response) => {
      if (response.status === "success") {
        setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
        loadProvincesDDFromServer(CountryName, setState, toast);
      } else {
        var message = response.message;
        if (message.indexOf("###") !== -1) {
          message = "Listings are attached to province, can't delete it.";
        }

        toast.error(message);
        setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      }
    })
    .catch((error) => {});
};

export const executeDeleteTownClick = (ProvinceID, TownID, setState, loadTownsDDFromServer, toast) => {
  setState({
    ButtonStatus: {
      disabled: true,
      processing_save: false,
      processing_delete: true,
    },
  });

  apiAgent.Setting.deleteTownAjax(ProvinceID, TownID)
    .then((response) => {
      setState({
        ButtonStatus: {
          disabled: false,
          processing_save: false,
          processing_delete: false,
        },
      });
      loadTownsDDFromServer(ProvinceID, setState, toast);
    })
    .catch((error) => {
      var message = error.message;
      if (message.indexOf("###") !== -1) {
        message = "Listing are attached to town, can't delete it.";
      }

      toast.error(message);
      setState({
        ButtonStatus: {
          disabled: false,
          processing_save: false,
          processing_delete: false,
        },
      });
    });
};

export const executeDeleteSuburbClick = (SuburbID, TownID, setState, loadSuburbsDDFromServer, toast) => {
  setState({
    ButtonStatus: {
      disabled: true,
      processing_save: false,
      processing_delete: true,
    },
  });
  apiAgent.Setting.deleteSuburbAjax(SuburbID, TownID)
    .then((response) => {
      setState({
        ButtonStatus: {
          disabled: false,
          processing_save: false,
          processing_delete: false,
        },
      });
      loadSuburbsDDFromServer(TownID, setState, toast);
    })
    .catch((error) => {
      var message = error.message;
      if (message.indexOf("###") !== -1) {
        message = "Listing are attached to suburb, can't delete it.";
      }
      toast.error(message);
      setState({
        ButtonStatus: {
          disabled: false,
          processing_save: false,
          processing_delete: false,
        },
      });
    });
};

export const handleChange = (e, setState) => {
  setState({
    [e.target.name]: e.target.value,
  });
};

export const handleNumericChange = (name, value, setState) => {
  setState({
    [name]: value,
  });
};

export const openCloseDeleteModal = (isOpen, confirmationModalProps, setState) => {
  let varConfirmationModalProps = confirmationModalProps;
  varConfirmationModalProps.showModal = isOpen;
  setState({ confirmationModalProps: varConfirmationModalProps });
};

export const showPopupAndDeleteOnConfirmation = (title, body, callback, confirmationModalProps, setState, openCloseDeleteModal) => {
  let varConfirmationModalProps = {
    title,
    body,
    showModal: true,
    isConfirmed: function (isConfirmed) {
      if (isConfirmed) {
        callback();
      }
      openCloseDeleteModal(false, confirmationModalProps, setState);
    }.bind(this),
  };
  setState({ confirmationModalProps: varConfirmationModalProps });
};

export const executeSaveModelClick = (GeoLat, GeoLong, geoHereMapRef) => {
  window.$("#SaveModel").modal("show");
  setTimeout(
    function () {
      geoHereMapRef.current.onLocationChanged(GeoLat, GeoLong);
    }.bind(this),
    2000
  );
};

export const getProvinceDDName = () => {
  var skillsSelect = document.getElementById("ProvinceDD");
  return skillsSelect.options[skillsSelect.selectedIndex].text;
};

export const getTownDDName = () => {
  var skillsSelect = document.getElementById("TownDD");
  return skillsSelect.options[skillsSelect.selectedIndex].text;
};

export const getModelLabel = (state) => {
  if (state.MMode === 2) {
    if (state.MTownID === "") return "Add town for " + state.MProvinceName;
    else return "Edit town for " + state.MProvinceName;
  } else if (state.MMode === 3) {
    if (state.MSuburbID === "") return "Add suburb for " + state.MTownName;
    else return "Edit suburb for " + state.MTownName;
  } else {
    if (state.MSuburbID === "") return "Add province for " + state.CountryName;
    else return "Edit province for " + state.CountryName;
  }
};

export const onGetLocationClick = (state, geoHereMapRef) => {
  var where = state.MName;
  if (state.MMode === 2) {
    where = state.MName + "," + state.MProvinceName + "," + state.CountryName;
  } else if (state.MMode === 3) {
    where = state.MName + "," + state.MTownName + "," + state.MProvinceName + "," + state.CountryName;
  } else {
    where = state.MName + "," + state.CountryName;
  }
  geoHereMapRef.current.onSearchAddressLocation(where);
};

export const onHereMapLocationChange = (lat, long, setState) => {
  setState({ MGeoLat: lat, MGeoLong: long });
};
