import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { OfficeResponseModel } from "../../../app/models/OfficeResponseModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import OfficesGrid from "./OfficesGrid";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function OfficesPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [office, setOffice] = useState<OfficeResponseModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    setIsLoading(true);
    apiAgent.Setting.getOffices()
      .then((response: OfficeResponseModel) => {
        let oOffice: OfficeResponseModel = response;
        setOffice(oOffice);
        //console.log("office", oOffice);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Offices";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && office) {
    return (
      <>
        <HeaderWrapper />

        <div className="office-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Offices" },
            ]}
          />

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              <OfficesGrid actions={office.actions} super_admin={office.super_admin} />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
