import React from "react";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { DataTableCompDynamic,DataTableComp } from "../../../app/common/common-components/OMSDatatables";
import { ReportGrid } from "../../contact/ReportGrid";
import { DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../app/api/apiAgent";
import { withRouter } from 'react-router-dom';

class UserGrid extends React.Component {
  constructor(props) {
    super(props);
    this.reportGridRef = React.createRef();
  }

  state = {
    loading: false,
    data: [],
    usersData: [],
    view: 1,
    pageView: 1,
    loading: false,
    OfficeID: this.props.OfficeID,
    ExtraParams: JSON.stringify({ View: 1, OfficeID: this.props.OfficeID }),
  };

  loadUsersFromServer = (view, officeID) => {
    this.setState({ loading: true });
    apiAgent.Setting.getUsersData(officeID, view)
      .then((response) => {
        //console.log("users data", this.state.usersData);
        this.setState({
          usersData: response.data,
        });
        //console.log("users data", response.data);
      })
      .catch((error) => {
        //toast.error(error.response.data))
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  // loadGrid = (requestParams) => {
  //   //return apiAgent.Setting.getAuditTrailData();
  //   //return apiAgent.Contact.getContactsData("", tags, sources, referralstatus, view, contactViewType)
  //   var data= apiAgent.Setting.getUsersData("",  this.state.OfficeID, this.state.view);
  //   return data;
  // };

  componentDidMount() {
    this.loadUsersFromServer(1, this.state.OfficeID);
    //console.log("inside userGrid componentDidMount", this.state.OfficeID, this.state.view);
    //this.loadGrid(this.state.OfficeID, 1);
  }

  onCreateClick = (e) => {
    this.props.history.push('/settings/saveUser');
  };
  handleRowClick = (data) => {
    this.props.history.push('/settings/saveUser/' + data.userID);
  };
  handleOfficeIDChange = (e) => {
    this.setState({
      view: this.state.view,
      OfficeID: e.newValue,
      ExtraParams: JSON.stringify({
        View: this.state.view,
        OfficeID: e.newValue,
      }),
    });
    this.loadUsersFromServer(this.state.view, e.newValue);
    //this.loadGrid(e.newValue, this.state.view);
  };
  onChangeView = (view) => {
    this.setState({
      view: view,
      OfficeID: this.state.OfficeID,
      ExtraParams: JSON.stringify({
        View: view,
        OfficeID: this.state.OfficeID,
      }),
    });
    this.loadUsersFromServer(view, this.state.OfficeID);
    //this.loadGrid(this.state.OfficeID, view);
  };
  onChangePageView = (pageView) => {
    if (pageView == 100) {
      this.setState({ pageView: pageView });
      this.reportGridRef.current.onOpenReports();
    } else {
      this.setState({ pageView: 1, view: pageView });
      this.onChangeView(pageView);
    }
  };

  render() {
    const columns = [
      { data: "syncID", title: "SyncID" },
      { data: "name", title: "Name" },
      { data: "emailAddress", title: "Email address" },
      { data: "role", title: "Role" },
      { data: "jobTitle", title: "Job title" },
      { data: "birthDate", title: "Birth date" },
      { data: "officeName", title: "Office" },
    ];

    return (
      <div className="row">
        <div className="col-sm-12 hidden-md hidden-lg" style={{ marginBottom: 10 }}>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("1")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 1 ? <b>Active</b> : <span>Active</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("2")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 2 ? <b>Inactive</b> : <span>Inactive</span>}
          </span>
          {this.props.super_admin && (
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("3")}>
              <i className="fa fa-table" style={{ marginRight: 8 }}></i>
              {this.state.pageView == 1 && this.state.view == 3 ? <b>Deleted</b> : <span>Deleted</span>}
            </span>
          )}
          {this.props.reportAccess && (
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("100")}>
              <i className="fa fa-file-pdf-o" style={{ marginRight: 8 }}></i>
              {this.state.pageView == 100 ? <b>Reports</b> : <span>Reports</span>}
            </span>
          )}
        </div>
        <div className="col-md-1 hidden-xs hidden-sm">
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("1")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 1 ? <b>Active</b> : <span>Active</span>}
          </span>
          <br />
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("2")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 2 ? <b>Inactive</b> : <span>Inactive</span>}
          </span>
          <br />
          {this.props.super_admin && (
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("3")}>
              <i className="fa fa-table" style={{ marginRight: 8 }}></i>
              {this.state.pageView == 1 && this.state.view == 3 ? <b>Deleted</b> : <span>Deleted</span>}
            </span>
          )}
          {this.props.super_admin && <br />}
          {this.props.reportAccess && (
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("100")}>
              <i className="fa fa-file-pdf-o" style={{ marginRight: 8 }}></i>
              {this.state.pageView == 100 ? <b>Reports</b> : <span>Reports</span>}
            </span>
          )}
        </div>
        <div className="col-md-11 col-sm-12" style={{ display: this.state.pageView == 1 ? "" : "none" }}>
          <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-6 col-sm-5 col-xs-12">
                  <div className="form-inline form-group boxLeftMenu">
                    <span>
                      <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                    </span>
                    <OMSDropdown
                      id="OfficeID"
                      options={this.props.officeDD}
                      valueField="value"
                      labelField="text"
                      value={this.state.OfficeID}
                      onChange={this.handleOfficeIDChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-7 col-xs-12">
                  <div className="pull-right boxRightMenu">
                    <div className="form-inline">
                      {this.props.actions.indexOf("create") != -1 && (
                        <OMSButtonStyleL
                          type="button"
                          ui_icon=""
                          ui_type="create"
                          ui_text="add user"
                          ui_processing_text=""
                          processing={false}
                          disabled={false}
                          onClick={this.onCreateClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-body table-responsive">
              {/* <DataTableCompDynamic id="userTable" Url="/Settings/UsersAjax" columns={columns} columnDefs={[]} order={[[0, "desc"]]} serverSide={false} onRowClick={this.handleRowClick} ExtraParams={this.state.ExtraParams} /> */}
               <DataTableComp
                id="userTable"
                data={this.state.usersData}
                columns={columns}
                columnDefs={[]}
                order={[[0, "desc"]]}
                onRowClick={this.handleRowClick}
                      /> 
                 {/*     
                <DataTableCompDynamic
                  id="userTable"
                  columns={columns}
                  columnDefs={[]}
                  order={[[0, "desc"]]}
                  serverSide={false}
                  makeAjaxRequest={this.loadGrid}
                  onRowClick={this.handleRowClick}
                  ExtraParams={this.state.ExtraParams}
                /> */}
              <DataTableLoading loading={this.state.loading} />
            </div>
          </div>
        </div>
        <div className="col-md-11 col-sm-12" style={{ display: this.state.pageView == 100 ? "block" : "none" }}>
          <ReportGrid ref={this.reportGridRef} ID="userReports" Category="users" />
        </div>
      </div>
    );
  }
}

export default withRouter(UserGrid);
