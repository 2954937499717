import React from "react";
import PropTypes from "prop-types";
import apiAgent from "../../app/api/apiAgent";
import { toast } from "react-toastify";

export class AuditTrailBox extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    objectID: PropTypes.string.isRequired,
    officeID: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    data: PropTypes.array,
  };

  state = {
    data: [],
    loading: false,
  };

  componentDidMount() {
    window.$("#" + this.props.id).modal("show");
    this.setState({ loading: true, showAuditTrail: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Settings/AuditTrailByIdAjax?OfficeID=' + this.props.OfficeID + '&ObjectID=' + this.props.ObjectID, true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            console.log(data);
            this.setState({ data: data, loading: false });
        }.bind(this);
        xhr.send(); */
    apiAgent.Setting.auditTrailByIdAjax(this.props.officeID, this.props.objectID)
      .then((response) => {
        //console.log("inside AuditTrailBox data from API=", response);
        this.setState({ data: response });
      })
      .catch((error) => {
        toast.error("error");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleCloseModal = () => {
    window.$("#" + this.props.id).modal("hide");
    if (this.props.onClose !== undefined) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <div className="modal fade" id={this.props.id} tabIndex={-1} role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={this.handleCloseModal} aria-hidden="true">
                ×
              </button>
              <h4 className="modal-title">
                <i className="fa fa-history"></i>&nbsp;&nbsp;Audit Trail
              </h4>
            </div>
            <div className="modal-body" style={{ maxHeight: 500, overflowY: "auto" }}>
              {this.state.loading ? (
                <p>
                  <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                  <span class="sr-only">Loading...</span>
                </p>
              ) : this.state.data && this.state.data.length > 0 ? (
                this.state.data.map(function (item) {
                  return (
                    <div key={item.key} className="panel panel-default oms-box">
                      <div className="panel-heading">
                        <div className="pull-left">
                          Event date: {item.date} updated by {item.user}
                        </div>
                        <div className="pull-right">Action type: {item.action}</div>
                        <div className="clearfix"></div>
                      </div>
                      {item.changes !== null && item.changes.length > 0 && (
                        <table id={"#" + item.key} className="table">
                          <thead>
                            <tr className="text-warning">
                              <th style={{ width: "30%" }}>Field name</th>
                              <th style={{ width: "35%" }}>Before change</th>
                              <th style={{ width: "35%" }}>After change</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.changes.map(function (itemC) {
                              return (
                                <tr key={item.key + itemC.fieldName}>
                                  <td>{itemC.fn}</td>
                                  <td>{itemC.vb}</td>
                                  <td>{itemC.va}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  );
                })
              ) : (
                <h5>No entry found.</h5>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.handleCloseModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
