import React, { useEffect, useState } from "react";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";

type Props = {
  officeDD: [];
  officeSelectionChanged: (value: string) => void;
};

const OfficesDropdown: React.FC<Props> = ({ officeDD, officeSelectionChanged }) => {
  const [OfficeID, setOfficeID] = useState<string>("");

  useEffect(() => {
    //console.log("inside Office Dropdown useEffect", officeDD);
  },[]);

  const handleOfficeIDChange = (value: any) => {
    let newOfficeID = value.newValue;
    setOfficeID(newOfficeID);
    officeSelectionChanged(newOfficeID);
  };

  return (
    <>
      <OMSDropdown id="OfficeID" options={officeDD} valueField="Value" labelField="Text" value={OfficeID} onChange={handleOfficeIDChange} />
    </>
  );
};

export default OfficesDropdown;
