import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import { OMSButtonStyleL } from "./OMSButton";
import apiAgent from "../../api/apiAgent";

export class OMSDLUpload extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    parentid: PropTypes.string,
    librarytype: PropTypes.number,
    onUploadComplete: PropTypes.func,
    hidden: PropTypes.bool,
    allowMultiple: PropTypes.bool, 
  };

  static defaultProps = {
    allowMultiple: false, 
  };

  state = {
    files: [], 
    processing: false,
    error_message: "",
  };

  componentDidMount(e) {}

  handleFileTitleChange = (e, index) => {
    const files = [...this.state.files];
    files[index].title = e.target.value;
    this.setState({ files });
  };

  handleFileDescriptionChange = (e, index) => {
    const files = [...this.state.files];
    files[index].description = e.target.value;
    this.setState({ files });
  };

  onUploadClick = (e) => {
    const htmlID = "uploadfile_" + this.props.id;
    window.$("#" + htmlID).val("");
    document.getElementById(htmlID).click();
  };

  processFile = (file) => {
    return new Promise((resolve) => {
      if (file) {
        const mSize = file.size;
        if (!isNaN(mSize) && mSize > 200000000) {
          toast.info("File is too big to upload. Max filesize allowed: 200MB");
          return resolve(null);
        }

        if (file.name.match(/.(exe|apk|bat|csh|ipa|msi|reg|vb|cs|js|jsx|jar)$/i)) {
          toast.info("Executable files are not allowed!");
          return resolve(null);
        }

        let fileicon = "";
        let imgSrc = "";

        if (file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          const reader = new FileReader();
          reader.onload = (e) => {
            imgSrc = e.target.result;
            resolve({
              fileicon: "",
              title: file.name,
              filename: file.name,
              description: "",
              file,
              imgSrc,
            });
          };
          reader.readAsDataURL(file);
        } else {
          if (file.name.match(/\.(pdf)$/)) fileicon = "fa-file-pdf-o";
          else if (file.name.match(/\.(txt)$/)) fileicon = "fa-file-text-o";
          else if (file.name.match(/\.(zip|rar)$/)) fileicon = "fa-file-zip-o";
          else if (file.name.match(/\.(csv|xls|xlsx)$/)) fileicon = "fa-file-excel-o";
          else if (file.name.match(/\.(ppt|pptx)$/)) fileicon = "fa-file-powerpoint-o";
          else if (file.name.match(/\.(doc|docx)$/)) fileicon = "fa-file-word-o";
          else fileicon = "fa-file-o";

          resolve({
            fileicon,
            title: file.name,
            filename: file.name,
            description: "",
            file,
            imgSrc: "",
          });
        }
      } else {
        resolve(null);
      }
    });
  };

  onUploadFileChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    
    if (!this.props.allowMultiple && files.length > 1) {
      toast.info("Only one file can be uploaded at a time.");
      files = [files[0]];
    }

    const filePromises = Array.from(files).map((file) => this.processFile(file));

    Promise.all(filePromises).then((processedFiles) => {
      processedFiles = processedFiles.filter((f) => f !== null);
      this.setState({
        files: processedFiles,
        processing: false,
        error_message: "",
      });

      const htmlID = "uploadmodal_" + this.props.id;

      window.$("#" + htmlID).modal("show");
      window
        .$("#" + htmlID)
        .off("shown.bs.modal")
        .on("shown.bs.modal", function () {
          window.$("input[id^='title_']").select();
        });
    });
  };

  onSaveUploadClick = (e) => {
    const { files } = this.state;
    const parentid = this.props.parentid || "";

    const uploadPromises = files.map((fileData) => {
      const data = new FormData();
      data.append("title", fileData.title);
      data.append("fileName", fileData.filename);
      data.append("description", fileData.description);
      data.append("file", fileData.file);
      data.append("parentID", parentid);
      data.append("type", this.props.librarytype);

      return apiAgent.Library.fsUploadFileAjax(data);
    });

    this.setState({ processing: true });

    Promise.all(uploadPromises)
      .then((responses) => {
        window.$("#uploadmodal_" + this.props.id).modal("hide");
        this.setState({
          files: [],
          processing: false,
          error_message: "",
        });
        if (this.props.onUploadComplete) {
          
          if (!this.props.allowMultiple) {
            this.props.onUploadComplete(responses[0].data);
          } else {
            this.props.onUploadComplete(responses.map((res) => res.data));
          }
        }
      })
      .catch((error) => this.setState({ error_message: error.response ? error.response.message : error.message }));
  };

  render() {
    const { files } = this.state;
    const isMultiple = this.props.allowMultiple;
    return (
      <div>
        {this.props.hidden ? null : (
          <button id={"btnupload_" + this.props.id} type="button" className="btn btn-primary" name={"btnupload_" + this.props.id} onClick={this.onUploadClick}>
            Upload
          </button>
        )}
        <input
          id={"uploadfile_" + this.props.id}
          type="file"
          multiple={isMultiple} // Conditionally allow multiple files
          style={{ display: "none" }}
          onChange={this.onUploadFileChange}
        />
        <div id={"uploadmodal_" + this.props.id} className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title">Upload {isMultiple ? "files" : "a file"}</h4>
              </div>
              <div
                className="modal-body"
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }} // Inline style added here
              >
                <div className="row">
                  {this.state.error_message === "" ? null : (
                    <div className="form-group col-md-12">
                      <label style={{ color: "red" }}>{this.state.error_message}</label>
                    </div>
                  )}
                  {files.map((fileData, index) => (
                    <div key={index} className="col-md-12">
                      <div className="form-group text-center">
                        {fileData.imgSrc ? (
                          <img
                            id={"imgpreview_" + this.props.id + "_" + index}
                            src={fileData.imgSrc}
                            alt="Preview"
                            style={{
                              width: 100,
                              height: 100,
                              border: "1px solid",
                            }}
                          />
                        ) : (
                          <span
                            id={"iconpreview_" + this.props.id + "_" + index}
                            style={{
                              width: 100,
                              height: 100,
                            }}
                          >
                            <i className={"fa " + fileData.fileicon} aria-hidden="true" style={{ fontSize: 60 }}></i>
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor={"title_" + index}>Title</label>
                        <input
                          type="text"
                          id={"title_" + index}
                          name={"title_" + index}
                          className="form-control"
                          value={fileData.title}
                          onChange={(e) => this.handleFileTitleChange(e, index)}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor={"description_" + index}>Description</label>
                        <textarea
                          rows={2}
                          className="form-control"
                          id={"description_" + index}
                          name={"description_" + index}
                          value={fileData.description}
                          onChange={(e) => this.handleFileDescriptionChange(e, index)}
                        />
                      </div>
                      {isMultiple && index < files.length - 1 && <hr />}
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <OMSButtonStyleL
                  type="button"
                  style={{ marginRight: 10 }}
                  ui_icon="fa-upload"
                  ui_type="save"
                  ui_text="Upload"
                  ui_processing_text="Uploading..."
                  processing={this.state.processing}
                  disabled={this.state.processing}
                  onClick={this.onSaveUploadClick}
                />
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
