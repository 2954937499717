import React from "react";
import PropTypes from "prop-types";

export class ImportOverviewStepView extends React.Component {
    static propTypes = {
        data: PropTypes.object
    };

    getStateFromData = (data) => {
        return {
            data: data
        }
    };

    componentDidMount() {

    }

    doSetState = (data) => {
        this.setState(this.getStateFromData(data));
    };

    doGetState = () => {
        return this.state;
    };

    ToString = (value) => {
        if (value === undefined || value == null)
            return "";
        else
            return value;
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    renderDiaryItems = () => {
        if (this.state.data.diary.diaryID == "")
            return "no diary items for this property";
        else
            return (
                <span><b>{this.state.data.diary.typeID}: </b>{this.state.data.diary.startDate} {this.state.data.diary.contactName}</span>
            );
    };

    renderMandate = () => {
        var SaleTypeString = "";
        if (this.state.data.mandate.saleType == 1)
            SaleTypeString = "For sale";
        else if (this.state.data.mandate.saleType == 2)
            SaleTypeString = "For rent";
        else if (this.state.data.mandate.saleType == 3)
            SaleTypeString = "Auction";
        else
            SaleTypeString = "Valuation";

        return (
            <div style={{ marginLeft: 10 }}>
                <p>{SaleTypeString}: {this.state.data.mandate.daysLeft} days left.</p>
                <h4>R {this.state.data.mandate.priceString}</h4>
            </div>
        );

    };

    renderContacts = () => {
        return (this.state.data.contacts.contacts.map((item, index) => {
            return (
                <p key={index}><b>{item.ContactType}: </b>{item.DisplayName}</p>
            )
        }));
    };

    renderFeed = () => {
        return (
            <p>
                {this.state.data.marketing.activeCount == 0 ? <span>0 Active |&nbsp;</span> : <span><i className="fa fa-check-circle" style={{ color: 'green' }}></i>&nbsp;<b>{this.state.data.marketing.activeCount} Active</b> |&nbsp;</span>}
                {this.state.data.marketing.pendingCount == 0 ? <span>0 Pending |&nbsp;</span> : <span><i className="fa fa-clock-o" style={{ color: 'orange' }}></i>&nbsp;<b>{this.state.data.marketing.pendingCount} Pending</b> |&nbsp;</span>}
                {this.state.data.marketing.errorCount == 0 ? <span>0 Errors</span> : <span><i className="fa fa-exclamation-triangle" style={{ color: 'red' }}></i>&nbsp;<b>{this.state.data.marketing.errorCount} Errors</b></span>}
            </p>
        );
        //return this.state.data.Marketing.ActiveCount + " Active | " + this.state.data.Marketing.PendingCount + " Pending | " + this.state.data.Marketing.ErrorCount + " Errors"
    };

    renderLocation = () => {

        var result = this.state.data.location.streetNumber + " " + this.state.data.location.streetName + " ";

        var areaText = this.state.data.location.districtOrSuburb;
        if (this.state.data.location.cityOrTown)
            areaText = areaText + (areaText == "" ? "" : ", ") + this.state.data.location.cityOrTown;
        if (this.state.data.location.stateOrProvince)
            areaText = areaText + (areaText == "" ? "" : ", ") + this.state.data.Location.stateOrProvince;
        if (this.state.data.location.country)
            areaText = areaText + (areaText == "" ? "" : ", ") + this.state.data.Location.country;
        return result + areaText;
    };

    renderFeatures = () => {
        var Rooms = JSON.parse(this.state.data.features.rooms);
        var External = JSON.parse(this.state.data.features.external);

        var result = (Rooms.map((item, index) => {
            return item["value"] + " " + item["field"] + " |"
        })).join(' ');

        result = result + (External.map((item, index) => {
            return item["value"] + " " + item["field"] + " |"
        })).join(' ');

        if (result.length > 0)
            result = result.substring(0, result.length - 1);
        return result;
    };

    renderPhotosCount = () => {
        if (this.state.data.media === undefined || this.state.data.media.photos === undefined)
            return "no photo";
        else {
            if (this.state.data.media.photos.length == 1)
                return "1 photo";
            else
                return this.state.data.media.photos.length + " photos";
        }
    };

    renderPhotos = () => {
        if (this.state.data.media === undefined || this.state.data.media.photos === undefined)
            return null;
        else {
            return (this.state.data.media.photos.map((item, index) => {
                return (
                    <img key={index} style={{ width: 70, marginLeft: 3, height: 65 }} className="img-thumbnail" src={item.DataUrl} alt={item.Description} />
                )
            }));
        }
    };

    state = this.getStateFromData(this.props.data);

    render() {
        return (
            <div>
                {/*<div className="row">
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-clock-o"></i><br />
                                    diary
                                                            </span>
                            </div>
                            <div className="media-body">
                                <p style={{ marginTop: 20, marginLeft: 10 }}>{this.renderDiaryItems()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-key"></i><br />
                                    mandate
                                                            </span>
                            </div>
                            <div className="media-body">
                                {this.renderMandate()}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-address-book"></i><br />
                                    contacts
                                                            </span>
                            </div>
                            <div className="media-body">
                                <div style={{ marginLeft: 10 }}>
                                    {this.renderContacts()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-share-alt"></i><br />
                                    feeds
                                                            </span>
                            </div>
                            <div className="media-body">
                                <div style={{ marginTop: 20, marginLeft: 10 }}>{this.renderFeed()}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-map-marker"></i><br />
                                    location
                                                            </span>
                            </div>
                            <div className="media-body">
                                <p style={{ marginTop: 20, marginLeft: 10 }}>{this.renderLocation()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-bed"></i><br />
                                    features
                                                            </span>
                            </div>
                            <div className="media-body">
                                <div className="ellipsis-container" style={{ marginTop: 20, marginLeft: 10 }}>
                                    <div className="ellipsis-div">{this.renderFeatures()}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span><i className="fa fa-file-text-o"></i><br />description</span>
                            </div>
                            <div className="media-body">
                                <div className="ellipsis-container" style={{ marginTop: 5, marginLeft: 10 }}>
                                    <div className="ellipsis-div h4">{this.state.data.Description.Headline}</div>
                                </div>
                                <div className="ellipsis-container" style={{ marginTop: 5, marginLeft: 10 }}>
                                    <div className="ellipsis-div">{this.state.data.Description.Description}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-camera"></i><br />
                                    photos
                                                            </span>
                            </div>
                            <div className="media-body">
                                <div style={{ float: 'left', marginTop: 20, marginLeft: 10 }}>
                                    {this.renderPhotosCount()}
                                </div>
                                <div style={{ float: 'left', marginLeft: 5 }}>
                                    {this.renderPhotos()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-print"></i><br />
                                    brochures
                                                            </span>
                            </div>
                            <div className="media-body">
                                <p style={{ marginTop: 20, marginLeft: 10 }}>Generate brochures</p>
                            </div>
                        </div>
                    </div>

                </div>*/}
            </div>
        );
    }
}