import React from "react";
import PropTypes from "prop-types";
import { OMSButtonStyleL } from "../../../../app/common/common-components/OMSButton";
import { Link } from 'react-router-dom';
import ReportFilters from "./ReportFilters";

const MatchingClientsModal = ({
  mMatchingClients,
  mProcessing,
  handleMatchingClientsRunClick,
  getReportIcon,
  handleFilterOptionClick,
  filters
}) => {
  return (
    <div className="modal fade" id="matchingReportModel" tabIndex={-1} aria-labelledby="matchingReportModelLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            <h4 className="modal-title" id="matchingReportModelLabel">Matching clients report</h4>
          </div>
          <div className="modal-body">
            <ReportFilters filters={filters} handleFilterOptionClick={handleFilterOptionClick} />
            {mMatchingClients.length == 0 ? null : (
              <div>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Sale type</th>
                          <th>Property type</th>
                          <th>Price range</th>
                          <th>Area</th>
                          <th>Floor size</th>
                          <th>Land size</th>
                          <th>Beds</th>
                          <th>Baths</th>
                          <th>Garages</th>
                          <th>Parking bays</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mMatchingClients.map((data) => (
                          <tr key={data.contactID}>
                            <td>
                              <Link to={`/Contacts/SaveContact?ID=${data.contactID}`} target="_blank">{data.name}</Link>
                            </td>
                            <td>{getReportIcon(data.saleType, "")}</td>
                            <td>{getReportIcon(data.propertyType, "")}</td>
                            <td>{getReportIcon(data.price, data.tPrice)}</td>
                            <td>{getReportIcon(data.areas, data.tAreas)}</td>
                            <td>{getReportIcon(data.floorsize, data.tFloorsize)}</td>
                            <td>{getReportIcon(data.landsize, data.tLandsize)}</td>
                            <td>{getReportIcon(data.beds, data.tBeds)}</td>
                            <td>{getReportIcon(data.baths, data.tBaths)}</td>
                            <td>{getReportIcon(data.garages, data.tGarages)}</td>
                            <td>{getReportIcon(data.parkingBays, data.tParkingBays)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <a style={{ marginRight: 20, cursor: "pointer" }} data-dismiss="modal">Close</a>
            <OMSButtonStyleL
              type="button"
              ui_icon="fa-check"
              ui_type="save"
              ui_text="Run"
              ui_processing_text="Running.."
              processing={mProcessing}
              disabled={mProcessing}
              onClick={handleMatchingClientsRunClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MatchingClientsModal.propTypes = {
  mMatchingClients: PropTypes.array.isRequired,
  mProcessing: PropTypes.bool.isRequired,
  handleMatchingClientsRunClick: PropTypes.func.isRequired,
  getReportIcon: PropTypes.func.isRequired,
  handleFilterOptionClick: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired
};

export default MatchingClientsModal;
