import React from 'react';
import PropTypes from 'prop-types';
// import './HereMapComponent.css'; // Ensure you create a CSS file for map styles if necessary

export class OMSHereMapLocation extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onGetInfo: PropTypes.func,
    loadMap: PropTypes.bool,
  };

  state = {
    lat: 0,
    long: 0,
  };

  componentDidMount() {
    if (this.props.loadMap) {
      this.loadMap();
    }
  }

  loadMap = () => {
    console.log('Loading map...');
    const H = window.H;
    const mapContainer = document.getElementById('map');

    const apiKey = 'QJF2sIKVcgIx_0Au3uj74g262vyhqKaE7UwbXSC9jUk';

    if (!this.platform) {
      const platform = new H.service.Platform({
        apikey: apiKey,
      });

      const defaultLayers = platform.createDefaultLayers();
      const geocoder = platform.getSearchService();

      const ct = { lat: -25.85804, lng: 28.18966 }; // Example coordinates (South Africa)

      const map = new H.Map(mapContainer, defaultLayers.vector.normal.map, {
        center: ct,
        zoom: 10,
        pixelRatio: window.devicePixelRatio || 1,
      });

      window.addEventListener('resize', () => map.getViewPort().resize());

      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      const ui = H.ui.UI.createDefault(map, defaultLayers);

      this.platform = platform;
      this.geocoder = geocoder;
      this.ui = ui;
      this.map = map;
      this.behavior = behavior;

      console.log('Map loaded successfully');
    } else {
      console.log('Platform already initialized');
    }
  };

  handleLocationChange = (lat, long) => {
    const reverseGeocodingParameters = {
      at: `${lat},${long}`,
      limit: 1,
    };
    this.geocoder.reverseGeocode(reverseGeocodingParameters, this.onGeocodeSuccess, this.onGeocodeError);
  };

  handleLocationIDChange = (locationid) => {
    this.geocoder.lookup({ id: locationid }, this.onGeocodeSuccess, this.onGeocodeError);
  };

  handleLocationOnlyChange = (lat, long) => {
    if (!this.platform) {
      this.loadMap();
    }
    this.addDraggableMarker(lat, long);
  };

  onGeocodeSuccess = (result) => {
    console.log('Geocode success:', result);
    let resultLocation;
    if (result.items && result.items.length > 0) {
      resultLocation = result.items[0];
    } else {
      resultLocation = result;
    }

    this.addDraggableMarker(resultLocation.position.lat, resultLocation.position.lng);
    const address = resultLocation.address;
    const selected = {
      propertyNumber: address.houseNumber || '',
      streetName: address.street || '',
      suburb: address.district || '',
      town: address.city || address.county || '',
      province: address.state || '',
      country: address.countryName || '',
      postalcode: address.postalCode || '',
      lat: resultLocation.position.lat,
      long: resultLocation.position.lng,
    };
    //console.log("inside onGeocodeSuccess, before calling onGetInfo, item=", selected);

    if (this.props.onGetInfo) this.props.onGetInfo(selected);
  };

  onGeocodeError = (error) => {
    //console.error('Geocode error:', error);
    alert("Error obtained while searching location. Please contact the administrator.");
  };

  addDraggableMarker = (lat, long) => {
    const H = window.H;

    if (!lat || lat === "0" || !long || long === "0") {
      const ct = { lat: -25.85804, lng: 28.18966 }; // Example coordinates (South Africa)
      this.map.getViewPort().resize();
      this.map.setZoom(15);
      this.map.setCenter(ct);
      return;
    }

    if (!this.marker) {
      const marker = new H.map.Marker({ lat, lng: long });
      marker.draggable = true;
      this.map.addObject(marker);
      this.marker = marker;

      this.map.getViewPort().resize();
      this.map.setZoom(15);
      this.map.setCenter({ lat, lng: long });

      this.map.addEventListener(
        'dragstart',
        (ev) => {
          const target = ev.target;
          if (target instanceof H.map.Marker) {
            this.behavior.disable();
          }
        },
        false
      );

      this.map.addEventListener(
        'dragend',
        (ev) => {
          const target = ev.target;
          if (target instanceof H.map.Marker) {
            this.behavior.enable();
            const location = target.getGeometry();
            this.map.setCenter(location);
            this.setState({ lat: location.lat, long: location.lng });
            this.handleLocationChange(location.lat, location.lng); // Trigger reverse geocoding to update input fields
            if (this.props.onChange) this.props.onChange(location.lat, location.lng);
          }
        },
        false
      );

      this.map.addEventListener(
        'drag',
        (ev) => {
          const target = ev.target;
          const pointer = ev.currentPointer;
          if (target instanceof H.map.Marker) {
            const position = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
            target.setGeometry(position); // Correct method to update marker position
          }
        },
        false
      );
    } else {
      if (this.marker instanceof H.map.Marker) {
        const point = new H.geo.Point(lat, long);
        this.marker.setGeometry(point);
        this.map.getViewPort().resize();
        this.map.setCenter({ lat, lng: long });
      }
    }

    if (this.props.onChange) this.props.onChange(lat, long);
  };

  render() {
    return <div id="map" style={this.props.style}></div>;
  }
}
