import React from "react";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import ProvinceDropdown from "./CountryAreaBoxComponents/ProvinceDropdown";
import TownDropdown from "./CountryAreaBoxComponents/TownDropdown";
import SuburbDropdown from "./CountryAreaBoxComponents/SuburbDropdown";
import SaveModelModal from "./CountryAreaBoxComponents/SaveModelModal";
import {
  loadProvincesDDFromServer,
  loadTownsDDFromServer,
  loadSuburbsDDFromServer,
  handleModelSubmit,
  executeDeleteProvinceClick,
  executeDeleteTownClick,
  executeDeleteSuburbClick,
  handleChange,
  handleNumericChange,
  openCloseDeleteModal,
  showPopupAndDeleteOnConfirmation,
  onGetLocationClick,
  onHereMapLocationChange,
  executeSaveModelClick,
  getProvinceDDName,
  getTownDDName,
  getModelLabel,
} from "./CountryAreaBoxComponents/CountryAreaBoxHelpers";
import apiAgent from "../../../app/api/apiAgent";

class CountryAreaBox extends React.Component {
  state = {
    CountryName: this.props.CountryName,
    ProvinceDD: [],
    TownDD: [],
    SuburbDD: [],
    ProvinceID: "",
    TownID: "",
    SuburbID: "",
    MProvinceID: "",
    MProvinceName: "",
    MTownID: "",
    MTownName: "",
    MSuburbID: "",
    MGeoLat: 0,
    MGeoLong: 0,
    MName: "",
    MPostalcode: "",
    MMode: 1,
    ButtonStatus: {
      disabled: false,
      processing_save: false,
      processing_delete: false,
    },
    confirmationModalProps: {},
  };

  constructor(props) {
    super(props);
    this.geoHereMapRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.CountryName !== "") {
      this.loadProvincesDDFromServer();
      window
        .$("#SaveModel")
        .off("shown.bs.modal")
        .on(
          "shown.bs.modal",
          function () {
            this.geoHereMapRef.current.resizeMap();
          }.bind(this)
        );
    }

    let varConfirmModalProps = {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    };
    this.setState({ confirmationModalProps: varConfirmModalProps });
  }

  loadProvincesDDFromServer = () => {
    loadProvincesDDFromServer(this.state.CountryName, this.setState.bind(this), toast);
  };

  loadTownsDDFromServer = (ProvinceID) => {
    loadTownsDDFromServer(ProvinceID, this.setState.bind(this), toast);
  };

  loadSuburbsDDFromServer = (TownID) => {
    loadSuburbsDDFromServer(TownID, this.setState.bind(this), toast);
  };

  handleChange = (e) => {
    handleChange(e, this.setState.bind(this));
  };

  handleNumericChange = (name, value) => {
    handleNumericChange(name, value, this.setState.bind(this));
  };

  handleProvinceDDChange = (e) => {
    this.setState({ ProvinceID: e.newValue });
    this.loadTownsDDFromServer(e.newValue);
  };

  handleTownDDChange = (e) => {
    this.setState({ TownID: e.newValue });
    this.loadSuburbsDDFromServer(e.newValue);
  };

  handleSuburbDDChange = (e) => {
    this.setState({ SuburbID: e.newValue });
  };

  openCloseDeleteModal = (isOpen) => {
    openCloseDeleteModal(isOpen, this.state.confirmationModalProps, this.setState.bind(this));
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    showPopupAndDeleteOnConfirmation(title, body, callback, this.state.confirmationModalProps, this.setState.bind(this), this.openCloseDeleteModal);
  };

  onCreateProvinceClick = () => {
    this.setState({
      MProvinceID: "",
      MMode: 1,
      MProvinceName: "",
      MTownID: "",
      MTownName: "",
      MSuburbID: "",
      MGeoLat: 0,
      MGeoLong: 0,
      MName: "",
      MPostalcode: "",
    });
    this.executeSaveModelClick(0, 0);
  };

  onUpdateProvinceClick = () => {
    apiAgent.Setting.selectProvinceAjax(this.state.ProvinceID)
      .then((response) => {
        let data = JSON.parse(response);

        this.setState({
          MProvinceID: data.ProvinceID,
          MMode: 1,
          MProvinceName: data.Name,
          MTownID: "",
          MTownName: "",
          MSuburbID: "",
          MGeoLat: data.GeoLat,
          MGeoLong: data.GeoLong,
          MName: data.Name,
          MPostalcode: "",
        });
        this.executeSaveModelClick(data.GeoLat, data.GeoLong);
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  onCreateTownClick = () => {
    var MProvinceName = getProvinceDDName();
    this.setState({
      MProvinceID: this.state.ProvinceID,
      MMode: 2,
      MProvinceName: MProvinceName,
      MTownID: "",
      MTownName: "",
      MSuburbID: "",
      MGeoLat: 0,
      MGeoLong: 0,
      MName: "",
      MPostalcode: "",
    });
    this.executeSaveModelClick(0, 0);
  };

  onUpdateTownClick = () => {
    var MProvinceName = getProvinceDDName();
    apiAgent.Setting.selectTownAjax(this.state.TownID)
      .then((response) => {
        let data = JSON.parse(response);
        this.setState({
          MProvinceID: data.ProvinceID,
          MMode: 2,
          MProvinceName: MProvinceName,
          MTownID: data.TownID,
          MTownName: data.Name,
          MSuburbID: "",
          MGeoLat: data.GeoLat,
          MGeoLong: data.GeoLong,
          MName: data.Name,
          MPostalcode: "",
        });
        this.executeSaveModelClick(data.GeoLat, data.GeoLong);
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  onCreateSuburbClick = (e) => {
    var MProvinceName = this.getProvinceDDName();
    var MTownName = this.getTownDDName();
    this.setState({
      MProvinceID: this.state.ProvinceID,
      MMode: 3,
      MProvinceName: MProvinceName,
      MTownID: this.state.TownID,
      MTownName: MTownName,
      MSuburbID: "",
      MGeoLat: 0,
      MGeoLong: 0,
      MName: "",
      MPostalcode: "",
    });
    this.executeSaveModelClick(0, 0);
  };

  onUpdateSuburbClick = () => {
    var MProvinceName = getProvinceDDName();
    var MTownName = getTownDDName();
    apiAgent.Setting.selectSuburbAjax(this.state.SuburbID)
      .then((response) => {
        let data = JSON.parse(response);
        this.setState({
          MProvinceID: this.state.ProvinceID,
          MMode: 3,
          MProvinceName: MProvinceName,
          MTownID: data.TownID,
          MTownName: MTownName,
          MSuburbID: data.SuburbID,
          MGeoLat: data.GeoLat,
          MGeoLong: data.GeoLong,
          MName: data.Name,
          MPostalcode: data.Postalcode,
        });
        this.executeSaveModelClick(data.GeoLat, data.GeoLong);
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  onDeleteProvinceClick = () => {
    let title = "Delete Province?";
    let body = "Do you want to delete province now? This cannot be undone.";
    let callback = () => {
      executeDeleteProvinceClick(
        this.state.ProvinceID,
        this.state.CountryName,
        this.setState.bind(this),
        this.loadProvincesDDFromServer.bind(this),
        toast
      );
    };
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onDeleteTownClick = () => {
    let title = "Delete town?";
    let body = "Do you want to delete town now? This cannot be undone.";
    let callback = () => {
      executeDeleteTownClick(
        this.state.ProvinceID,
        this.state.TownID,
        this.setState.bind(this),
        this.loadTownsDDFromServer.bind(this),
        toast
      );
    };
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onDeleteSuburbClick = () => {
    let title = "Delete suburb?";
    let body = "Do you want to delete suburb now? This cannot be undone.";
    let callback = () => {
      executeDeleteSuburbClick(
        this.state.SuburbID,
        this.state.TownID,
        this.setState.bind(this),
        this.loadSuburbsDDFromServer.bind(this),
        toast
      );
    };
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  executeSaveModelClick = (GeoLat, GeoLong) => {
    executeSaveModelClick(GeoLat, GeoLong, this.geoHereMapRef);
  };

  getProvinceDDName = () => {
    return getProvinceDDName();
  };

  getTownDDName = () => {
    return getTownDDName();
  };

  getModelLabel = () => {
    return getModelLabel(this.state);
  };

  onGetLocationClick = () => {
    onGetLocationClick(this.state, this.geoHereMapRef);
  };

  onHereMapLocationChange = (lat, long) => {
    onHereMapLocationChange(lat, long, this.setState.bind(this));
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ConfirmationModalPopup {...this.state.confirmationModalProps} />
            <ProvinceDropdown
              ProvinceDD={this.state.ProvinceDD}
              ProvinceID={this.state.ProvinceID}
              handleProvinceDDChange={this.handleProvinceDDChange}
              ButtonStatus={this.state.ButtonStatus}
              onCreateProvinceClick={this.onCreateProvinceClick}
              onUpdateProvinceClick={this.onUpdateProvinceClick}
              onDeleteProvinceClick={this.onDeleteProvinceClick}
            />
            <TownDropdown
              TownDD={this.state.TownDD}
              ProvinceID={this.state.ProvinceID}
              TownID={this.state.TownID}
              handleTownDDChange={this.handleTownDDChange}
              ButtonStatus={this.state.ButtonStatus}
              onCreateTownClick={this.onCreateTownClick}
              onUpdateTownClick={this.onUpdateTownClick}
              onDeleteTownClick={this.onDeleteTownClick}
            />
            <SuburbDropdown
              SuburbDD={this.state.SuburbDD}
              ProvinceID={this.state.ProvinceID}
              TownID={this.state.TownID}
              SuburbID={this.state.SuburbID}
              handleSuburbDDChange={this.handleSuburbDDChange}
              ButtonStatus={this.state.ButtonStatus}
              onCreateSuburbClick={this.onCreateSuburbClick}
              onUpdateSuburbClick={this.onUpdateSuburbClick}
              onDeleteSuburbClick={this.onDeleteSuburbClick}
            />
          </div>
        </div>
        <SaveModelModal
          geoHereMapRef={this.geoHereMapRef}
          state={this.state}
          handleChange={this.handleChange}
          handleNumericChange={this.handleNumericChange}
          onGetLocationClick={this.onGetLocationClick}
          onHereMapLocationChange={this.onHereMapLocationChange}
          handleModelSubmit={(data) => handleModelSubmit(data, this.state.MMode, this.setState.bind(this), this.loadProvincesDDFromServer.bind(this), this.loadTownsDDFromServer.bind(this), this.loadSuburbsDDFromServer.bind(this), toast)}
          getModelLabel={this.getModelLabel}
        />
      </div>
    );
  }
}

export default CountryAreaBox;
