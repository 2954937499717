import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSNumericBox } from "../../../app/common/common-components/OMSComponent";
import { OMSFavourite } from "../../../app/common/common-components/OMSFavourite";
import { OMSImageUpload } from "../../../app/common/common-components/OMSImageUpload";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";
import { OMSColorSelector } from "../../../app/common/common-components/OMSColorSelector";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import TinyMCEEditor from "../../../app/common/common-components/TinyMCEEditor";
import { OMSMultiselectDropdown } from "../../../app/common/common-components/OMSMultiselectDropdown";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from "react-router-dom";
import { clearConfigCache } from "prettier";
import CustomizeTaskTemplateSetting from "./SubComponents/CustomizeTaskTemplateSetting";
import OMSModal from "../../../app/common/common-components/OMSModal/OMSModal";
import { isEntegralSuperUser } from "../../../app/common/common-functions/functions";

class CustomizeBox extends React.Component {
  constructor(props) {
    super(props);
    this.tinyMceRef = React.createRef();
  }
  static defaultProps = {
    color_options: [
      { value: "1", color: "#333000", name: "" },
      { value: "2", color: "#92AB1C", name: "" },
      { value: "3", color: "#F7F7F7", name: "" },
      { value: "4", color: "#B4B4B4", name: "" },
      { value: "5", color: "#FFFFFF", name: "" },
      { value: "6", color: "#51473D", name: "" },
      { value: "7", color: "#B92425", name: "" },
      { value: "8", color: "#F3F9F1", name: "" },
      { value: "9", color: "#EFECDE", name: "" },
      { value: "10", color: "#0A0908", name: "" },
      { value: "11", color: "#1D3557", name: "" },
      { value: "12", color: "#457B9D", name: "" },
      { value: "13", color: "#1D3557", name: "" },
      { value: "14", color: "#F1FAEE", name: "" },
    ],
  };

  state = {
    TColor1: "",
    TColor2: "",
    TColor3: "",
    TColor4: "",
    TColor5: "",
    TColor6: "",
    TColor7: "",
    TName: "",
    SystemTheme: this.props.data.systemTheme,
    SystemLogo: this.props.data.systemLogo,
    SystemLogoUrl: this.props.data.systemLogoUrl,
    SystemLogoUpload: null,
    IsCustomSystemLogo: this.props.data.isCustomSystemLogo,
    OfficeContactTags: JSON.parse(this.props.data.officeContactTags),
    OfficePropertyTags: JSON.parse(this.props.data.officePropertyTags),
    UserContactTags: JSON.parse(this.props.data.userContactTags),
    UserPropertyTags: JSON.parse(this.props.data.userPropertyTags),
    DiaryActionTags: JSON.parse(this.props.data.diaryActionTags),
    ContactSourceTypes: JSON.parse(this.props.data.contactSourceTypes),
    OfficeAgentTags: JSON.parse(this.props.data.officeAgentTags),
    Themes: JSON.parse(this.props.data.themes),
    ContentTags: JSON.parse(this.props.data.contentTags),
    ContentCategories: JSON.parse(this.props.data.contentCategories),
    ExtraDescriptionFields: JSON.parse(this.props.data.extraDescriptionFields),
    GeneralListingTags: JSON.parse(this.props.data.generalListingTags),
    ListingSellerRequired: this.props.data.listingSellerRequired,
    IsNotifyBeforeXDaysOfExpiry: this.props.data.isNotifyBeforeXDaysOfExpiry,
    IsNotifyAfterXDays: this.props.data.isNotifyAfterXDays,
    NotifyBeforeXDaysOfExpiry: this.props.data.notifyBeforeXDaysOfExpiry,
    NotifyAfterXDays: this.props.data.notifyAfterXDays,
    ModelTags: [],
    ModelName: "",
    ModelTagTitle: "",
    ModelTagColor: "#333000",
    mFieldIndex: -1,
    mFieldTitle: "",
    mFieldTags: [],
    ButtonStatus: {
      disabled: false,
      processing_save: false,
      processing_delete: false,
    },
    BrochureMainColor: this.props.data.brochureMainColor,
    BrochureSecondaryColor: this.props.data.brochureSecondaryColor,
    activeTabID: "contacts",
    // Split
    spiltTableData: JSON.parse(this.props.data.splitTypes),
    newSplitValName: "",
    newSplitValdefaultPercentage: "",
    newSplitValdefaultNettAmount: "",
    newSplitValtaxed: false,
    splitTypeEditIDX: null,
    // Deduction
    deductionTableData: JSON.parse(this.props.data.deductionTypes),
    newDeductionValName: "",
    newDeductionValdefaultPercentage: "",
    newDeductionValdefaultNettAmount: "",
    deductionTypeEditIDX: null,
    // Pipeline
    pipelineTableData: JSON.parse(this.props.data.pipelines),
    newPipelineValName: "",
    pipelineId: "",
    newPipelineValDescription: "",
    pipelineTypeVal: 0,
    pipelineIsDefault: false,
    pipelineSlipVal: this.props.data?.commissionSlipDD[0]?.value,
    pipelineEditIdx: null,
    pipelineSlipType: this.props.data?.commissionSlipDD,
    payoutMilestoneDD: [],
    payoutMilestone: "",
    cancelMilestoneDD: [],
    cancelMilestone: "",
    pipelineType: [
      { value: 0, text: "Deal" },
      { value: 1, text: "Contact" },
    ],
    // Stages
    stagesData: JSON.parse(this.props.data.pipelines)[0]?.stages,
    stagesPipelineState: JSON.parse(this.props.data.pipelines)[0]
      ?.pipeline_name,
    stagesName: "",
    stageId: "",
    stagesDescription: "",
    stagesOrder: "",
    stagesEditIdx: null,
    // Email Templetes
    noticeBoardContent: "",
    emailTemplateId: "",
    emailTemplateName: "",
    emailTemplateDescription: "",
    emailTemplateSubject: "",
    emailTemplateAvailableVariables: [],
    emailTempleteData: JSON.parse(this.props.data.emailTemplates),
    emailTempleteIDX: null,
    emailTempletePipelineDD: [
      {
        text: "",
        value: "",
      },
    ],
    emailTempletePipelineDDValue: "",
    emailTempleteMilestoneDD: [
      {
        text: "",
        value: "",
      },
    ],
    emailTempleteMilestoneDDValue: "",
    emailTempleteSendToRolesDDValue: [],
    // Task Templetes | Deal Templetes
    taskPipelineState: JSON.parse(this.props.data.pipelines)[0]?.pipeline_name,
    taskTempleteTableData: JSON.parse(this.props.data.pipelines)[0]
      ?.task_templates,
    roleDD: this.props.data.assignedRolesDD,
    roleDDVal: [],
    mileStoneDD: [],
    taskData: [],
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => { },
      title: "",
      body: "",
    },
    isPreApproval: this.props.data.isPreApproval,
    preApprovalIcon: this.props.data.preApprovalIcon,
    preApprovalLink: this.props.data.preApprovalLink,
    preApprovalText: this.props.data.preApprovalText,
    taskDisableListMin: [],
    taskDisableListMax: [],
    pipelineData: [],
    selectedDefaultPipelines: [],
    IsETOffice: this.props.data.isETOffice,
    dealNotificationSendingEmail: this.props.data.dealNotificationSendingEmail,
    newIsVatExcluded: false,
    emailTemplateIsOpen: false,
    InternalListingTags: JSON.parse(this.props.data.internalListingTags),
    InternalDealTags: JSON.parse(this.props.data.internalDealTags),
  };

  insertTextInTinyMCE(text) {
    if (this.tinyMceRef.current) {
      this.tinyMceRef.current.insertTextAtCursorPosition(text);
    }
  }

  setActiveTabID(activeID) {
    this.setState({ activeTabID: activeID });
  }

  ETOfficeRenderDefaultPipeline() {
    if (this.state.IsETOffice == true) {
      return (
        <div className="form-group">
          <input
            type="checkbox"
            value={this.state.pipelineIsDefault}
            checked={this.state.pipelineIsDefault}
            onChange={this.isDefaultPipeline}
          />
          &nbsp;&nbsp;
          <label style={{ position: "absolute" }}>Default Pipeline</label>
        </div>
      );
    }
  }

  handleOpenDefaultPipeline() {
    apiAgent.Deal.getDefaultPipelinesAjax().then((res) => {
      this.setState({ pipelineData: res });
    });
    window.$("#DefaultPipelineModal").modal("show");
  }

  onDefaultPipelineSelect(e, pipelineId) {
    const isChecked = e.target.checked;
    let selectedPipelines = this.state.selectedDefaultPipelines || [];
    if (isChecked) {
      selectedPipelines.push(pipelineId);
    } else {
      selectedPipelines = selectedPipelines.filter((id) => id !== pipelineId);
    }
    this.setState({ selectedDefaultPipelines: selectedPipelines });
  }

  onAddDefaultPipelines() {
    window.$("#pipelineModal").modal("hide");
    let pipelineIds = this.state.selectedDefaultPipelines || [];
    // let defaultPipelineData = this.state.pipelineTableData;
    // console.log(defaultPipelineData);
    apiAgent.Deal.performImportPipelinesAjax(pipelineIds.toString()).then(
      (res) => {
        if (res.status == "success") {
          window.location.reload();
        }
      }
    );
  }

  componentDidMount() {
    // console.log(JSON.parse(this.props.data.pipelines))
    let roles = this.props.data.assignedRolesDD.map((curElem) => {
      return { text: curElem, value: curElem };
    });
    let tags = [];

    this.setState({ roleDD: roles });
    //console.log("inside componentDidMount of CustomizeBox,this.props=", this.props);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleXDaysChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleIsCustomSystemLogoChange = (e) => {
    var flag = !this.state.IsCustomSystemLogo;
    this.setState({
      IsCustomSystemLogo: flag,
    });
  };

  OnCropSubmit = (d) => {
    //console.log("inside OnCropSubmit of CustomizeBox,d=", d);
    this.setState({
      SystemLogoUpload: d,
    });
  };

  onCancelClick = (e) => {
    window.location.href = "/Settings";
  };

  handleSystemThemeChange = (e) => {
    this.setState({
      SystemTheme: e.target.value,
    });
  };

  onColorChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleOpenUpdateTheme = (theme) => {
    var themeItem = null;
    this.state.Themes.forEach(function (element) {
      if (element.name == theme) {
        themeItem = element;
      }
    }, this);

    window.$("#themeModel").modal("show");
    window
      .$("#themeModel")
      .off("shown.bs.modal")
      .on(
        "shown.bs.modal",
        function () {
          this.setState({
            TName: themeItem.name,
            TColor1: themeItem.colour1,
            TColor2: themeItem.colour2,
            TColor3: themeItem.colour3,
            TColor4: themeItem.colour4,
            TColor5: themeItem.colour5,
            TColor6: themeItem.colour6,
            TColor7: themeItem.colour7,
          });
        }.bind(this)
      );
  };

  onSaveUpdateTheme = (e) => {
    if (!this.state.TName) {
      toast.info("Please enter theme name.");
      return;
    }

    if (
      !this.state.TColor1 ||
      !this.state.TColor2 ||
      !this.state.TColor3 ||
      !this.state.TColor4 ||
      !this.state.TColor5 ||
      !this.state.TColor6 ||
      !this.state.TColor7
    ) {
      toast.info("Please enter all color codes.");
      return;
    }
    var data = new FormData();
    data.append("TName", this.state.TName);
    data.append("TColor1", this.state.TColor1);
    data.append("TColor2", this.state.TColor2);
    data.append("TColor3", this.state.TColor3);
    data.append("TColor4", this.state.TColor4);
    data.append("TColor5", this.state.TColor5);
    data.append("TColor6", this.state.TColor6);
    data.append("TColor7", this.state.TColor7);
    var themes = this.state.Themes;
    for (var i = 0; i < themes.length; i++) {
      if (themes[i].name == this.state.TName) {
        themes[i].colour1 = this.state.TColor1;
        themes[i].colour2 = this.state.TColor2;
        themes[i].colour3 = this.state.TColor3;
        themes[i].colour4 = this.state.TColor4;
        themes[i].colour5 = this.state.TColor5;
        themes[i].colour6 = this.state.TColor6;
        themes[i].colour7 = this.state.TColor7;
      }
    }
    window.$("#themeModel").modal("hide");
    this.setState({ Themes: themes });
  };

  handleOpenTagModel = (name) => {
    window.$("#tagModel").modal("show");
    var modelTags = [];
    var modelName = name;
    var modelLabelTitle = "Tags";
    if (name == "office-contact-tags") {
      modelTags = this.state.OfficeContactTags.slice();
      modelLabelTitle = "Tags: default office contact";
    } else if (name == "my-contact-tags") {
      modelTags = this.state.UserContactTags.slice();
      modelLabelTitle = "Tags: my contact";
    } else if (name == "office-property-tags") {
      modelTags = this.state.OfficePropertyTags.slice();
      modelLabelTitle = "Tags: default office property";
    } else if (name == "office-agent-tags") {
      modelTags = this.state.OfficeAgentTags.slice();
      modelLabelTitle = "Tags: default agent types";
    } else if (name == "my-property-tags") {
      modelTags = this.state.UserPropertyTags.slice();
      modelLabelTitle = "Tags: my property";
    } else if (name == "office-diary-action-tags") {
      modelTags = this.state.DiaryActionTags.slice();
      modelLabelTitle = "Tags: diary action types";
    } else if (name == "office-contact-source-types") {
      modelTags = this.state.ContactSourceTypes.slice();
      modelLabelTitle = "Tags: contact source types";
    } else if (name == "office-content-tags") {
      modelTags = this.state.ContentTags.slice();
      modelLabelTitle = "Tags: office news tags";
    } else if (name == "office-content-categories") {
      modelTags = this.state.ContentCategories.slice();
      modelLabelTitle = "Categories: Office news categories";
    }

    window.$("#tagModelLabel").html(modelLabelTitle);

    window
      .$("#tagModel")
      .off("shown.bs.modal")
      .on(
        "shown.bs.modal",
        function () {
          this.setState({
            ModelTags: modelTags,
            ModelName: name,
            ModelTagTitle: "",
            ModelTagColor: "#333000",
          });
        }.bind(this)
      );

    window
      .$("#tagModel")
      .off("hidden.bs.modal")
      .on(
        "hidden.bs.modal",
        function () {
          var name2 = this.state.ModelName;
          var modelTags2 = this.state.ModelTags.slice();
          if (name2 == "office-contact-tags")
            this.setState({
              OfficeContactTags: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
          else if (name2 == "my-contact-tags")
            this.setState({
              UserContactTags: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
          else if (name2 == "office-property-tags")
            this.setState({
              OfficePropertyTags: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
          else if (name2 == "office-agent-tags")
            this.setState({
              OfficeAgentTags: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
          else if (name2 == "my-property-tags")
            this.setState({
              UserPropertyTags: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
          else if (name2 == "office-diary-action-tags")
            this.setState({
              DiaryActionTags: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
          else if (name2 == "office-contact-source-types")
            this.setState({
              ContactSourceTypes: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
          else if (name2 == "office-content-tags")
            this.setState({
              ContentTags: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
          else if (name2 == "office-content-categories")
            this.setState({
              ContentCategories: modelTags2,
              ModelName: "",
              ModelTags: [],
              ModelTagTitle: "",
              ModelTagColor: "#333000",
            });
        }.bind(this)
      );
  };

  handleTagDelete = (index) => {
    var modelTags = this.state.ModelTags;
    modelTags.splice(index, 1);
    this.setState({ ModelTags: modelTags });
  };

  handleTagAdd = () => {
    //console.log("inside handleTagAdd, this.state.ModelTagTitle:", this.state.ModelTagTitle);
    var tagTitle = this.state.ModelTagTitle;
    if (tagTitle == "") {
      toast.info("Please enter tag title");
      return;
    }

    if (tagTitle.indexOf(",") >= 0) {
      toast.info("Comma is not allowed in tag title");
      return;
    }

    var modelTags = this.state.ModelTags;
    for (var i = 0; i < modelTags.length; i++) {
      if (modelTags[i].Name == tagTitle) {
        toast.info("Please enter different tag title than others");
        return;
      }
    }

    var tag = {
      Name: this.state.ModelTagTitle.toLowerCase()
        .split(" ")
        .map((c) => c.charAt(0).toUpperCase() + c.substring(1))
        .join(" "),
      Color: this.state.ModelTagColor,
    };
    modelTags.push(tag);
    this.setState({ ModelTags: modelTags, ModelTagTitle: "" });
  };

  // Data Submit Handler
  handleSubmit = (e) => {
    e.preventDefault();
    var data = {
      SystemTheme: this.state.SystemTheme,
      OfficeContactTags: JSON.stringify(this.state.OfficeContactTags),
      OfficePropertyTags: JSON.stringify(this.state.OfficePropertyTags),
      UserContactTags: JSON.stringify(this.state.UserContactTags),
      UserPropertyTags: JSON.stringify(this.state.UserPropertyTags),
      DiaryActionTags: JSON.stringify(this.state.DiaryActionTags),
      ContactSourceTypes: JSON.stringify(this.state.ContactSourceTypes),
      OfficeAgentTags: JSON.stringify(this.state.OfficeAgentTags),
      Themes: JSON.stringify(this.state.Themes),
      SystemLogo: this.state.SystemLogo,
      SystemLogoUrl: this.state.SystemLogoUrl,
      SystemLogoUpload: this.state.SystemLogoUpload,
      IsCustomSystemLogo: this.state.IsCustomSystemLogo,
      ContentTags: JSON.stringify(this.state.ContentTags),
      ContentCategories: JSON.stringify(this.state.ContentCategories),
      ExtraDescriptionFields: JSON.stringify(this.state.ExtraDescriptionFields),
      ListingSellerRequired: this.state.ListingSellerRequired,
      IsNotifyBeforeXDaysOfExpiry: this.state.IsNotifyBeforeXDaysOfExpiry,
      IsNotifyAfterXDays: this.state.IsNotifyAfterXDays,
      NotifyBeforeXDaysOfExpiry: this.state.NotifyBeforeXDaysOfExpiry,
      NotifyAfterXDays: this.state.NotifyAfterXDays,
      GeneralListingTags: JSON.stringify(this.state.GeneralListingTags),
      BrochureMainColor: this.state.BrochureMainColor,
      BrochureSecondaryColor: this.state.BrochureSecondaryColor,
      SplitTypes: JSON.stringify(this.state.spiltTableData),
      DeductionTypes: JSON.stringify(this.state.deductionTableData),
      Pipelines: JSON.stringify(this.state.pipelineTableData),
      EmailTemplates: JSON.stringify(this.state.emailTempleteData),
      isPreApproval: this.state.isPreApproval,
      preApprovalIcon: this.state.preApprovalIcon,
      preApprovalLink: this.state.preApprovalLink,
      preApprovalText: this.state.preApprovalText,
      dealNotificationSendingEmail: this.state.dealNotificationSendingEmail,
      InternalListingTags: JSON.stringify(this.state.InternalListingTags),
      InternalDealTags: JSON.stringify(this.state.InternalDealTags)
    };

    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: true,
        processing_delete: false,
      },
    });
    apiAgent.Setting.customizeAjax(data)
      .then((response) => {
        if (response.status == "success") {
          window.location.href = "/settings/customize";
        }
      })
      .catch((error) => {
        toast.info("Fail");
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      })
      .finally(() => {
        this.setState({ ButtonStatus: { processing_delete: false } });
      });

    if (this.props.actions.indexOf("save2") != -1) {
      apiAgent.Setting.customizeAjax2(data)
        .then((response) => {
          window.location.href = "/settings/customize";
        })
        .catch((error) => {
          toast.info("Fail");
          this.setState({
            ButtonStatus: {
              disabled: false,
              processing_save: false,
              processing_delete: false,
            },
          });
        })
        .finally(() => {
          this.setState({ ButtonStatus: { processing_delete: false } });
        });
    }
  };

  handleSubmitFieldClick = (e) => {
    var fieldTitle = window.$("#txtFieldTitle").val();
    if (fieldTitle == "") {
      toast.info("Please enter field name");
      return;
    }

    var items = this.state.ExtraDescriptionFields;
    for (var i = 0; i < items.length; i++) {
      if (items[i].name == fieldTitle) {
        toast.info("Please enter different field name than others");
        return;
      }
    }

    window.$("#txtFieldTitle").val("");
    window.$("#fieldModel").modal("hide");
    var fieldItem = { name: fieldTitle, tags: [] };
    items.push(fieldItem);

    items.sort(function (a, b) {
      var nA = a.name.toLowerCase();
      var nB = b.name.toLowerCase();
      if (nA < nB) return -1;
      else if (nA > nB) return 1;
      return 0;
    });

    this.setState({ ExtraDescriptionFields: items });
  };

  handleOpenFieldModel = (name) => {
    window.$("#txtFieldTitle").val("");
    window.$("#fieldModel").modal("show");
  };

  handleFieldDelete = (name) => {
    var items = this.state.ExtraDescriptionFields;
    var index = -1;
    for (var i = 0; i < items.length; i++) {
      if (items[i].name == name) {
        index = i;
      }
    }
    if (index != -1) {
      items.splice(index, 1);
    }
    this.setState({ ExtraDescriptionFields: items });
  };

  handleListingSellerRequiredChanged = (e) => {
    var selected = this.state.ListingSellerRequired;
    this.setState({ ListingSellerRequired: !selected });
  };

  handleNotifyRequiredChanged = (e) => {
    if (e.target.name == "IsNotifyAfterXDays") {
      this.setState({ IsNotifyAfterXDays: !this.state.IsNotifyAfterXDays });
    } else if (e.target.name == "IsPreApproval") {
      this.setState({
        isPreApproval: e.target.checked,
      });
    } else {
      this.setState({
        IsNotifyBeforeXDaysOfExpiry: !this.state.IsNotifyBeforeXDaysOfExpiry,
      });
    }
  };

  handleFieldTagsClick = (name) => {
    var items = this.state.ExtraDescriptionFields;
    var index = -1;
    for (var i = 0; i < items.length; i++) {
      if (items[i].name == name) {
        index = i;
      }
    }

    if (index != -1) {
      window.$("#txtFieldTagTitle").val("");
      window.$("#fieldTagModel").modal("show");
      this.setState({
        mFieldTitle: items[index].name,
        mFieldTags: items[index].tags,
        mFieldIndex: index,
      });
    }
  };

  handleSubmitTagFieldClick = (e) => {
    var fieldTitle = window.$("#txtFieldTagTitle").val();
    if (fieldTitle == "") {
      toast.info("Please enter tag name");
      return;
    }

    var mFieldTags = this.state.mFieldTags;
    for (var i = 0; i < mFieldTags.length; i++) {
      if (mFieldTags[i] == fieldTitle) {
        toast.info("Please enter different tag name than others");
        return;
      }
    }

    window.$("#txtFieldTagTitle").val("");
    mFieldTags.push(fieldTitle);
    mFieldTags.sort();
    var mFieldItems = this.state.ExtraDescriptionFields;
    mFieldItems[this.state.mFieldIndex].tags = mFieldTags;
    this.setState({
      mFieldTags: mFieldTags,
      ExtraDescriptionFields: mFieldItems,
    });
  };

  handleClearTagFieldClick = (e) => {
    window.$("#txtFieldTagTitle").val("");
  };

  handleFieldTagDelete = (name) => {
    var mFieldTags = this.state.mFieldTags;
    var index = mFieldTags.indexOf(name);
    if (index != -1) {
      mFieldTags.splice(index, 1);
      var mFieldItems = this.state.ExtraDescriptionFields;
      mFieldItems[this.state.mFieldIndex].tags = mFieldTags;
      this.setState({
        mFieldTags: mFieldTags,
        ExtraDescriptionFields: mFieldItems,
      });
    }
  };

  handleGeneralListingTagModel = () => {
    window.$("#txtGeneralListingTag").val("");
    window.$("#generalListingTagsModel").modal("show");
  };
  handleInternalListingTagModel = () => {
    window.$("#txtInternalListingTag").val("");
    window.$("#internalListingTagsModel").modal("show");
  };

  handleInternalDealTagModel = () => {
    window.$("#txtInternalDealTag").val("");
    window.$("#internalDealTagsModel").modal("show");
  };

  handleSubmitGeneralListingClick = (e) => {
    var tagName = window.$("#txtGeneralListingTag").val();
    if (tagName == "") {
      toast.info("Please enter tag name");
      return;
    }

    var items = this.state.GeneralListingTags;
    for (var i = 0; i < items.length; i++) {
      if (items[i] == tagName) {
        toast.info("Please enter different tag name than others");
        return;
      }
    }

    window.$("#txtGeneralListingTag").val("");
    window.$("#generalListingTagsModel").modal("hide");
    items.push(tagName);

    items.sort(function (a, b) {
      var nA = a.toLowerCase();
      var nB = b.toLowerCase();
      if (nA < nB) return -1;
      else if (nA > nB) return 1;
      return 0;
    });

    this.setState({ GeneralListingTags: items });
  };
  handleSubmitInternalListingClick = (e) => {
    var tagName = window.$("#txtInternalListingTag").val();
    if (tagName == "") {
      toast.info("Please enter tag name");
      return;
    }
    var items = this.state.InternalListingTags;
    for (var i = 0; i < items.length; i++) {
      if (items[i] == tagName) {
        toast.info("Please enter different tag name than others");
        return;
      }
    }

    window.$("#txtInternalListingTag").val("");
    window.$("#internalListingTagsModel").modal("hide");
    items.push(tagName);

    items.sort(function (a, b) {
      var nA = a.toLowerCase();
      var nB = b.toLowerCase();
      if (nA < nB) return -1;
      else if (nA > nB) return 1;
      return 0;
    });

    this.setState({ InternalListingTags: items });
  };
  handleSubmitInternalDealClick = (e) => {
    var tagName = window.$("#txtInternalDealTag").val();
    if (tagName == "") {
      toast.info("Please enter tag name");
      return;
    }


    var items = this.state.InternalDealTags;
    for (var i = 0; i < items.length; i++) {
      if (items[i] == tagName) {
        toast.info("Please enter different tag name than others");
        return;
      }
    }

    window.$("#txtInternalDealTag").val("");
    window.$("#internalDealTagsModel").modal("hide");
    items.push(tagName);

    items.sort(function (a, b) {
      var nA = a.toLowerCase();
      var nB = b.toLowerCase();
      if (nA < nB) return -1;
      else if (nA > nB) return 1;
      return 0;
    });

    this.setState({ InternalDealTags: items });
  };

  handleGeneralListingTagDelete = (name) => {
    var items = this.state.GeneralListingTags;
    var index = items.indexOf(name);
    if (index != -1) {
      items.splice(index, 1);
    }
    this.setState({ GeneralListingTags: items });
  };
  handleInternalListingTagDelete = (name) => {
    var items = this.state.InternalListingTags;
    var index = items.indexOf(name);
    if (index != -1) {
      items.splice(index, 1);
    }
    this.setState({ InternalListingTags: items });
  };
  handleInternalDealTagDelete = (name) => {

    var items = this.state.InternalDealTags;
    var index = items.indexOf(name);
    if (index != -1) {
      items.splice(index, 1);
    }
    this.setState({ InternalDealTags: items });

  };

  // Modal State Handle Functions
  onNewSplitClick = () => {
    this.setState({
      newSplitValName: "",
      newSplitValdefaultPercentage: "",
      newSplitValdefaultNettAmount: "",
      newSplitValtaxed: false,
      splitTypeEditIDX: null,
    });
    window.$("#splitModal").modal("show");
  };
  onNewDeductionClick = () => {
    this.setState({
      newDeductionValName: "",
      newDeductionValdefaultPercentage: "",
      newDeductionValdefaultNettAmount: "",
      deductionTypeEditIDX: null,
      newIsVatExcluded: false,
    });
    window.$("#deductionModal").modal("show");
  };
  onNewPipelineClick = () => {
    this.setState({
      pipelineId: "",
      newPipelineValName: "",
      newPipelineValDescription: "",
      payoutMilestone: "",
      cancelMilestone: "",
      pipelineTypeVal: 0,
      pipelineEditIdx: null,
    });
    window.$("#pipelineModal").modal("show");
  };
  onNewStageClick = () => {
    this.setState({
      stagesName: "",
      stagesDescription: "",
      stagesEditIdx: null,
    });
    window.$("#stagesModal").modal("show");
  };

  checkIfStageSavingPending = (pipelineName) => {
    let currentPipeline = this.state.pipelineTableData.find((curElem) => {
      return curElem.pipeline_name == pipelineName;
    });
    let taskTempeleteStages = currentPipeline.stages?.find((curElem) => {
      return curElem.stage_id == "";
    });

    if (taskTempeleteStages !== undefined) return false;
    else return true;
  };

  onNewEmailTempleteClick = () => {
    let pipelineDD = this.state.pipelineTableData?.map((curPipe) => {
      return { text: curPipe.pipeline_name, value: curPipe.pipeline_id };
    });
    let mileStoneDD = this.state.pipelineTableData
      ?.find((curPipe) => {
        return curPipe.pipeline_id === pipelineDD[0].value;
      })
      .stages?.map((curStage) => {
        console.log(curStage);
        return { text: curStage.stage_name, value: curStage.stage_id };
      });
    this.setState({
      emailTemplateId: "",
      emailTemplateName: "",
      emailTemplateDescription: "",
      emailTemplateAvailableVariables: [],
      emailTemplateSubject: "",
      noticeBoardContent: "",
      emailTempletePipelineDD: pipelineDD,
      emailTempletePipelineDDValue: pipelineDD[0]?.value,
      emailTempleteMilestoneDD: mileStoneDD,
      emailTempleteMilestoneDDValue: mileStoneDD[0]?.value,
      emailTempleteSendToRolesDDValue: [],
      emailTemplateIsOpen: true,
    });
    //window.$("#emailTempleteModal").modal("show");
  };

  // Input Changes Handlers
  handleInputValueChange = (e, inp) => {
    switch (inp) {
      case "spilitTaxed":
        this.setState({ newSplitValtaxed: e.target.checked });
        break;
      case "spilitName":
        this.setState({ newSplitValName: e.target.value });
        break;
      case "spilitDefaultPercentage":
        this.setState({ newSplitValdefaultPercentage: e.target.value });
        break;
      case "spilitDefaultNettAmount":
        this.setState({ newSplitValdefaultNettAmount: e.target.value });
        break;
      case "deductionName":
        this.setState({ newDeductionValName: e.target.value });
        break;
      case "deductionDefaultPercentage":
        this.setState({ newDeductionValdefaultPercentage: e.target.value });
        break;
      case "deductionDefaultNettAmount":
        this.setState({ newDeductionValdefaultNettAmount: e.target.value });
        break;
      case "pipelineName":
        this.setState({ newPipelineValName: e.target.value });
        break;
      case "pipelineStages":
        this.setState({ pipelineTypeVal: e.newValue });
        break;
      case "pipelineSlip":
        this.setState({ pipelineSlipVal: e.newValue });
        break;
      case "payoutMilestone":
        this.setState({ payoutMilestone: e.newValue });
        break;
      case "cancelMilestone":
        this.setState({ cancelMilestone: e.newValue });
        break;
      case "pipelineDescription":
        this.setState({ newPipelineValDescription: e.target.value });
        break;
      case "emailTempletename":
        this.setState({ emailTemplateName: e.target.value });
        break;
      case "emailTempletesubject":
        this.setState({ emailTemplateSubject: e.target.value });
        break;
      case "emailTempletedescription":
        this.setState({ emailTemplateDescription: e.target.value });
        break;
      case "stageName":
        this.setState({ stagesName: e.target.value });
        break;
      case "stageDescription":
        this.setState({ stagesDescription: e.target.value });
        break;
      case "isVatExcluded":
        this.setState({ newIsVatExcluded: e.target.checked });

        break;
      default:
        // Handle default case, if necessary
        break;
    }
  };

  handleChangeEmailDDs = (e, name) => {
    if (name == "emailTempletePipelineDD") {
      let mileStoneDD = this.state.pipelineTableData
        ?.find((curPipe) => {
          return curPipe.pipeline_id === e.newValue;
        })
        .stages?.map((curStage) => {
          return { text: curStage.stage_name, value: curStage.stage_id };
        });
      if (mileStoneDD.length == 0) {
        mileStoneDD.push({ text: "", value: "" });
      }

      this.setState({
        emailTempletePipelineDDValue: e.newValue,
        emailTempleteMilestoneDD: mileStoneDD,
        emailTempleteMilestoneDDValue: mileStoneDD[0].value,
      });
    }
    if (name == "emailTempleteMilestoneDD") {
      this.setState({ emailTempleteMilestoneDDValue: e.newValue });
    }
    if (name == "emailTempleteRoleDD") {
      this.setState({ emailTempleteSendToRolesDDValue: [...e] });
    }
  };

  // All Data Add Handlers
  onAddNewSplit = () => {
    let splitAmount = this.state.newSplitValdefaultNettAmount;
    if (splitAmount == null || splitAmount == "" || !splitAmount) {
      splitAmount = 0;
    }
    let element = {
      split_name: this.state.newSplitValName,
      split_percentage: this.state.newSplitValdefaultPercentage,
      split_amount: splitAmount,
      split_taxed: this.state.newSplitValtaxed,
    };
    if (
      this.state.newSplitValName !== "" &&
      this.state.newSplitValName !== null
    ) {
      this.setState({
        spiltTableData: [...this.state.spiltTableData, element],
      });
    }
    window.$("#splitModal").modal("hide");
    this.setState({
      newSplitValtaxed: false,
      newSplitValName: "",
      newSplitValdefaultPercentage: "",
      newSplitValdefaultNettAmount: "",
    });
  };
  onAddNewDeduction = () => {
    let deductionAmount = this.state.newDeductionValdefaultNettAmount;
    if (deductionAmount == null || deductionAmount == "" || !deductionAmount) {
      deductionAmount = 0;
    }
    let element = {
      deduction_name: this.state.newDeductionValName,
      deduction_percentage: this.state.newDeductionValdefaultPercentage,
      deduction_amount: deductionAmount,
      is_vat_excluded: this.state.newIsVatExcluded,
    };

    if (
      this.state.newDeductionValName !== "" &&
      this.state.newDeductionValName !== null
    ) {
      this.setState({
        deductionTableData: [...this.state.deductionTableData, element],
      });
    }
    window.$("#deductionModal").modal("hide");
    this.setState({
      newDeductionValName: "",
      newDeductionValdefaultPercentage: "",
      newDeductionValdefaultNettAmount: "",
      newIsVatExcluded: false,
    });
  };
  onAddNewPipeline = () => {
    if (
      this.state.newPipelineValName !== "" &&
      this.state.newPipelineValName !== null
    ) {
      this.setState({
        pipelineTableData: [
          ...this.state.pipelineTableData,
          {
            pipeline_name: this.state.newPipelineValName,
            pipeline_description: this.state.newPipelineValDescription,
            pipeline_type: this.state.pipelineTypeVal,
            commission_slip: this.state.pipelineSlipVal,
            stages: [],
            pipeline_id: "",
            is_default: this.state.pipelineIsDefault,
            payout_id: "",
            cancelled_id: "",
          },
        ],
      });
    }
    window.$("#pipelineModal").modal("hide");
    if (
      this.state.stagesPipelineState === "" ||
      this.state.stagesPipelineState === null ||
      !this.state.stagesPipelineState
    ) {
      this.setState({ stagesPipelineState: this.state.newPipelineValName });
    }
    this.setState({
      newPipelineValName: "",
      newPipelineValDescription: "",
      pipelineTypeVal: 0,
      pipelineSlipVal: this.props.data?.commissionSlipDD[0]?.value,
    });
  };
  onAddNewEmailTemplete = () => {
    if (
      this.state.emailTemplateName !== "" &&
      this.state.emailTemplateName !== null
    ) {
      this.setState({
        emailTempleteData: [
          ...this.state.emailTempleteData,
          {
            email_template_id: this.state.emailTemplateId,
            email_template_name: this.state.emailTemplateName,
            email_template_description: this.state.emailTemplateDescription,
            available_variables: this.state.emailTemplateAvailableVariables,
            subject: this.state.emailTemplateSubject,
            body: this.state.noticeBoardContent,
            pipeline_id: this.state.emailTempletePipelineDDValue,
            stage_id: this.state.emailTempleteMilestoneDDValue,
            send_to: this.state.emailTempleteSendToRolesDDValue,
          },
        ],
      });
    }
    // window.$("#emailTempleteModal").modal("hide");
    this.setState({
      emailTemplateId: "",
      emailTemplateName: "",
      emailTemplateDescription: "",
      emailTemplateAvailableVariables: [],
      emailTemplateSubject: "",
      noticeBoardContent: "",
    });
  };
  onAddNewStages = async () => {
    let pipeIDX = null;
    if (this.state.stagesName !== "" && this.state.stagesName !== null) {
      let currentPipeline = await this.state.pipelineTableData.find(
        (curElem, i) => {
          pipeIDX = i;
          return curElem.pipeline_name == this.state.stagesPipelineState;
        }
      );
      currentPipeline.stages = [
        ...currentPipeline.stages,
        {
          stage_id: "",
          stage_name: this.state.stagesName,
          stage_description: this.state.stagesDescription,
        },
      ];

      // currentPipeline.task_templates = this.state.taskTempleteTableData;
      let oldPipelines = this.state.pipelineTableData?.filter((curElem) => {
        return curElem.pipeline_name !== this.state.stagesPipelineState;
      });
      oldPipelines.splice(pipeIDX, 0, currentPipeline);
      this.setState({
        pipelineTableData: oldPipelines,
        stagesData: [...currentPipeline.stages],
      });
    }
    window.$("#stagesModal").modal("hide");
    this.setState({ stagesName: "", stagesDescription: "" });
  };

  // Delete Handlers

  onSplitTypeDelete = (i) => {
    let varConfirmationModalProps = {
      title: "Delete Split Type",
      body:
        "Are you sure you want to Delete this Split Type? This cannot be undone.",
      showModal: true,
      isConfirmed: (result) => {
        this.splitTypeDeleteCallback(result, i);
      },
    };

    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  splitTypeDeleteCallback = (result, i) => {
    let varConfirmationModalProps = {
      title: "",
      body: "",
      showModal: false,
      isConfirmed: () => { },
    };
    if (result) {
      let splitTypes = this.state.spiltTableData.filter((curElem, EleI) => {
        return i !== EleI;
      });
      this.setState({
        spiltTableData: splitTypes,
        confirmationModalProps: varConfirmationModalProps,
      });
    } else {
      this.setState({ confirmationModalProps: varConfirmationModalProps });
    }
  };
  onDeductionTypeDelete = (i) => {
    let varConfirmationModalProps = {
      title: "Delete Deduction Type",
      body:
        "Are you sure you want to Delete this Deduction Type? This cannot be undone.",
      showModal: true,
      isConfirmed: (result) => {
        this.deductionTypeDeleteCallback(result, i);
      },
    };

    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  deductionTypeDeleteCallback = (result, i) => {
    let varConfirmationModalProps = {
      title: "",
      body: "",
      showModal: false,
      isConfirmed: () => { },
    };
    if (result) {
      let deductionTypes = this.state.deductionTableData.filter(
        (curElem, EleI) => {
          return i !== EleI;
        }
      );
      this.setState({
        deductionTableData: deductionTypes,
        confirmationModalProps: varConfirmationModalProps,
      });
    } else {
      this.setState({ confirmationModalProps: varConfirmationModalProps });
    }
  };
  onPipelineDelete = (i, pipelineID) => {
    let varConfirmationModalProps = {
      title: "Delete Pipeline",
      body:
        "Are you sure you want to Delete this Pipeline? This cannot be undone.",
      showModal: true,
      isConfirmed: (result) => {
        this.pipelineDeleteCallBack(result, i, pipelineID);
      },
    };

    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  pipelineDeleteCallBack = (result, i, pipelineID) => {
    let varConfirmationModalProps = {
      title: "",
      body: "",
      showModal: false,
      isConfirmed: () => { },
    };
    if (result) {
      let pipelines = this.state.pipelineTableData.filter((curElem, EleI) => {
        return i !== EleI;
      });
      if (pipelineID != "") {
        apiAgent.Deal.deletePipelineAjax(pipelineID)
          .then((res) => {
            if (res.status == "success") {
              //this.setState({ pipelineTableData: pipelines, confirmationModalProps: varConfirmationModalProps });
              toast.info(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              this.setState({
                confirmationModalProps: varConfirmationModalProps,
              });
              toast.info(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.info("Something went wrong");
            this.setState({
              confirmationModalProps: varConfirmationModalProps,
            });
          });
      } else {
        this.setState({
          pipelineTableData: pipelines,
          confirmationModalProps: varConfirmationModalProps,
        });
        toast.info("Pipeline deleted successfully");
      }
    } else {
      this.setState({ confirmationModalProps: varConfirmationModalProps });
    }
  };
  onStagesDelete = (i, stage) => {
    const pipelineEle = this.state.pipelineTableData.find((curPipe, i) => {
      return curPipe.pipeline_name == this.state.stagesPipelineState;
    });
    let varConfirmationModalProps = {
      title: "Delete Milestone",
      body:
        "Are you sure you want to Delete this Milestone? This cannot be undone.",
      showModal: true,
      isConfirmed: (result) => {
        this.stagesDeleteCallback(
          result,
          i,
          stage.stage_id,
          pipelineEle.pipeline_id
        );
      },
    };

    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  stagesDeleteCallback = (result, i, stageID, pipelineID) => {
    let varConfirmationModalProps = {
      title: "",
      body: "",
      showModal: false,
      isConfirmed: () => { },
    };
    if (result) {
      if (stageID != "" && pipelineID != "") {
        apiAgent.Deal.deleteStageAjax(pipelineID, stageID)
          .then((res) => {
            var interval;
            if (res.status == "success") {
              let pipeIDX = null;
              let stages = this.state.stagesData.filter((curElem, EleI) => {
                return i !== EleI;
              });

              let currentPipeline = this.state.pipelineTableData.find(
                (curElem, i) => {
                  pipeIDX = i;
                  return (
                    curElem.pipeline_name == this.state.stagesPipelineState
                  );
                }
              );

              currentPipeline.stages = stages;

              // currentPipeline.task_templates = this.state.taskTempleteTableData;
              let oldPipelines = this.state.pipelineTableData?.filter(
                (curElem) => {
                  return (
                    curElem.pipeline_name !== this.state.stagesPipelineState
                  );
                }
              );

              oldPipelines.splice(pipeIDX, 0, currentPipeline);

              this.setState({
                pipelineTableData: oldPipelines,
                stagesData: stages,
                confirmationModalProps: varConfirmationModalProps,
              });
              toast.info("Milestone Deleted");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              this.setState({
                confirmationModalProps: varConfirmationModalProps,
              });
              toast.info(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.info("Something went wrong");
            this.setState({
              confirmationModalProps: varConfirmationModalProps,
            });
          });
      } else {
        let pipeIDX = null;
        let stages = this.state.stagesData.filter((curElem, EleI) => {
          return i !== EleI;
        });

        let currentPipeline = this.state.pipelineTableData.find(
          (curElem, i) => {
            pipeIDX = i;
            return curElem.pipeline_name == this.state.stagesPipelineState;
          }
        );

        currentPipeline.stages = stages;

        // currentPipeline.task_templates = this.state.taskTempleteTableData;
        let oldPipelines = this.state.pipelineTableData?.filter((curElem) => {
          return curElem.pipeline_name !== this.state.stagesPipelineState;
        });

        oldPipelines.splice(pipeIDX, 0, currentPipeline);

        this.setState({
          pipelineTableData: oldPipelines,
          stagesData: stages,
          confirmationModalProps: varConfirmationModalProps,
        });
      }
    } else {
      this.setState({ confirmationModalProps: varConfirmationModalProps });
    }
  };

  onEmailTempleteDelete = (i) => {
    let varConfirmationModalProps = {
      title: "Delete Email Templete",
      body:
        "Are you sure you want to Delete this Email Templete? This cannot be undone.",
      showModal: true,
      isConfirmed: (result) => {
        this.emailTempleteDeleteCallback(result, i);
      },
    };

    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };
  emailTempleteDeleteCallback = (result, i) => {
    let varConfirmationModalProps = {
      title: "",
      body: "",
      showModal: false,
      isConfirmed: () => { },
    };
    if (result) {
      let updatedEmailTempletes = [...this.state.emailTempleteData];
      updatedEmailTempletes.splice(i, 1);
      this.setState({
        emailTempleteData: updatedEmailTempletes,
        confirmationModalProps: varConfirmationModalProps,
      });
    } else {
      this.setState({ confirmationModalProps: varConfirmationModalProps });
    }
  };

  // Edit Handlers
  onEditeEmailTemplete = async () => {
    let element = {
      email_template_id: this.state.emailTemplateId,
      email_template_name: this.state.emailTemplateName,
      email_template_description: this.state.emailTemplateDescription,
      available_variables: this.state.emailTemplateAvailableVariables,
      subject: this.state.emailTemplateSubject,
      body: this.state.noticeBoardContent,
      pipeline_id: this.state.emailTempletePipelineDDValue,
      stage_id: this.state.emailTempleteMilestoneDDValue,
      send_to: this.state.emailTempleteSendToRolesDDValue,
    };
    await this.state.emailTempleteData.splice(this.state.emailTempleteIDX, 1);
    await this.state.emailTempleteData.splice(
      this.state.emailTempleteIDX,
      0,
      element
    );
    this.setState({ emailTempleteIDX: null });
    // window.$("#emailTempleteModal").modal("hide");
  };

  onSplitTypeEditClick = (elem, i) => {
    this.setState({
      newSplitValName: elem.split_name,
      newSplitValdefaultPercentage: elem.split_percentage,
      newSplitValdefaultNettAmount: elem.split_amount,
      newSplitValtaxed: elem.split_taxed,
      splitTypeEditIDX: i,
    });
    window.$("#splitModal").modal("show");
  };
  onSplitTypeEditSave = () => {
    window.$("#splitModal").modal("hide");
    let splitTypes = this.state.spiltTableData;
    let element = {
      split_name: this.state.newSplitValName,
      split_percentage: this.state.newSplitValdefaultPercentage,
      split_amount: this.state.newSplitValdefaultNettAmount,
      split_taxed: this.state.newSplitValtaxed,
    };
    if (this.state.splitTypeEditIDX !== null) {
      splitTypes.splice(this.state.splitTypeEditIDX, 1);
      splitTypes.splice(this.state.splitTypeEditIDX, 0, element);
    }

    this.setState({
      spiltTableData: splitTypes,
      newSplitValName: "",
      newSplitValdefaultPercentage: "",
      newSplitValdefaultNettAmount: "",
      newSplitValtaxed: false,
      splitTypeEditIDX: null,
    });
  };

  onDeductionTypeEditClick = (elem, i) => {
    this.setState({
      newDeductionValName: elem.deduction_name,
      newDeductionValdefaultPercentage: elem.deduction_percentage,
      newDeductionValdefaultNettAmount: elem.deduction_amount,
      deductionTypeEditIDX: i,
      newIsVatExcluded: elem.is_vat_excluded,
    });
    window.$("#deductionModal").modal("show");
  };
  onDeductionTypeEditSave = () => {
    window.$("#deductionModal").modal("hide");
    let deductionTypes = this.state.deductionTableData;

    let element = {
      deduction_name: this.state.newDeductionValName,
      deduction_percentage: this.state.newDeductionValdefaultPercentage,
      deduction_amount: this.state.newDeductionValdefaultNettAmount,
      is_vat_excluded: this.state.newIsVatExcluded,
    };
    if (this.state.deductionTypeEditIDX !== null) {
      deductionTypes.splice(this.state.deductionTypeEditIDX, 1);
      deductionTypes.splice(this.state.deductionTypeEditIDX, 0, element);
    }

    this.setState({
      deductionTableData: deductionTypes,
      newDeductionValName: "",
      newDeductionValdefaultPercentage: "",
      newDeductionValdefaultNettAmount: "",
      deductionTypeEditIDX: null,
      newIsVatExcluded: false,
    });
  };

  onStagesEditClick = (curElem, i) => {
    this.setState({
      stagesName: curElem.stage_name,
      stagesDescription: curElem.stage_description,
      stagesOrder: curElem.stage_order,
      stagesEditIdx: i,
      stageId: curElem.stage_id,
    });
    window.$("#stagesModal").modal("show");
  };
  onStagesEditSave = () => {
    window.$("#stagesModal").modal("hide");
    let pipeIDX = null;
    let newStagesData = this.state.stagesData;
    let element = {
      stage_name: this.state.stagesName,
      stage_id: this.state.stageId,
      stage_description: this.state.stagesDescription,
      stage_order: this.state.stagesOrder,
    };
    newStagesData.splice(this.state.stagesEditIdx, 1);
    newStagesData.splice(this.state.stagesEditIdx, 0, element);

    let currentPipeline = this.state.pipelineTableData.find((curElem, i) => {
      pipeIDX = i;
      return curElem.pipeline_name == this.state.stagesPipelineState;
    });
    currentPipeline.stages = newStagesData;

    let oldPipelines = this.state.pipelineTableData?.filter((curElem) => {
      return curElem.pipeline_name !== this.state.stagesPipelineState;
    });

    oldPipelines.splice(pipeIDX, 0, currentPipeline);

    this.setState({
      stagesData: newStagesData,
      pipelineTableData: oldPipelines,
      stagesName: "",
      stageId: "",
      stagesDescription: "",
      stagesEditIdx: null,
    });
  };
  onPipelineEditSave = () => {
    window.$("#pipelineModal").modal("hide");
    let pipelineData = [...this.state.pipelineTableData];
    if (this.state.pipelineEditIdx != null) {
      pipelineData[
        this.state.pipelineEditIdx
      ].pipeline_name = this.state.newPipelineValName;
      pipelineData[
        this.state.pipelineEditIdx
      ].pipeline_description = this.state.newPipelineValDescription;
      pipelineData[
        this.state.pipelineEditIdx
      ].pipeline_type = this.state.pipelineTypeVal;
      pipelineData[
        this.state.pipelineEditIdx
      ].commission_slip = this.state.pipelineSlipVal;
      pipelineData[
        this.state.pipelineEditIdx
      ].pipeline_id = this.state.pipelineId;
      pipelineData[
        this.state.pipelineEditIdx
      ].is_default = this.state.pipelineIsDefault;
      pipelineData[
        this.state.pipelineEditIdx
      ].payout_id = this.state.payoutMilestone;
      pipelineData[
        this.state.pipelineEditIdx
      ].cancelled_id = this.state.cancelMilestone;
    }
    this.setState({
      pipelineTableData: pipelineData,
      pipelineEditIdx: null,
      newPipelineValName: "",
      newPipelineValDescription: "",
      pipelineTypeVal: 0,
      pipelineId: "",
      cancelMilestonedealId: "",
      pipelineSlipVal: this.props.data?.commissionSlipDD[0]?.value,
      pipelineIsDefault: false,
      payoutMilestone: "",
      cancelMilestone: "",
    });
  };

  isDefaultPipeline = (e) => {
    this.setState({
      pipelineIsDefault: e.target.checked,
    });
  };

  // RowClick Handlers
  onPipelineRowClick = (curElem, i) => {
    window.$("#pipelineModal").modal("show");
    let payoutmilestones = curElem.stages.map((curStage) => {
      return { value: curStage.stage_id, text: curStage.stage_name };
    });
    payoutmilestones.unshift({ value: "", text: "" });
    let cancelMilestone = curElem.stages.map((curStage) => {
      return { value: curStage.stage_id, text: curStage.stage_name };
    });
    cancelMilestone.unshift({ value: "", text: "" });
    this.setState({
      pipelineEditIdx: i,
      newPipelineValName: curElem.pipeline_name,
      newPipelineValDescription: curElem.pipeline_description,
      pipelineTypeVal: curElem.pipeline_type,
      pipelineSlipVal: curElem.commission_slip,
      pipelineId: curElem.pipeline_id,
      pipelineIsDefault: curElem.is_default,
      payoutMilestoneDD: payoutmilestones, // Dropdown for "Payout"
      payoutMilestone: curElem.payout_id ? curElem.payout_id : "",
      cancelMilestone: curElem.cancelled_id ? curElem.cancelled_id : "",
      cancelMilestoneDD: cancelMilestone // Dropdown for "Cancel"
    });
  };

  onEmailTempleteRowClick = (curElem, i) => {
    let pipelineDD;
    let mileStoneDD;
    let pipelineDDValue = curElem.pipeline_id;
    let mileStoneDDValue = curElem.stage_id;
    if (
      curElem.pipeline_id != "" &&
      curElem.pipeline_id != undefined &&
      curElem.pipeline_id != null
    ) {
      pipelineDD = this.state.pipelineTableData?.map((curPipe) => {
        return { text: curPipe.pipeline_name, value: curPipe.pipeline_id };
      });
      mileStoneDD = this.state.pipelineTableData
        ?.find((curPipe) => {
          return curPipe.pipeline_id === curElem.pipeline_id;
        })
        .stages?.map((curStage) => {
          return { text: curStage.stage_name, value: curStage.stage_id };
        });
    } else {
      pipelineDD = this.state.pipelineTableData?.map((curPipe) => {
        return { text: curPipe.pipeline_name, value: curPipe.pipeline_id };
      });
      mileStoneDD = this.state.pipelineTableData
        ?.find((curPipe) => {
          return curPipe.pipeline_id === pipelineDD[0].value;
        })
        .stages?.map((curStage) => {
          console.log(curStage);
          return { text: curStage.stage_name, value: curStage.stage_id };
        });
      pipelineDDValue = pipelineDD[0].value;
      mileStoneDDValue = mileStoneDD[0].value;
    }

    this.setState({
      emailTempleteIDX: i,
      emailTemplateId: curElem.email_template_id,
      emailTemplateName: curElem.email_template_name,
      emailTemplateDescription: curElem.email_template_description,
      emailTemplateAvailableVariables: [],
      emailTemplateSubject: curElem.subject,
      noticeBoardContent: curElem.body,
      emailTempletePipelineDD: pipelineDD,
      emailTempleteMilestoneDD: mileStoneDD,
      emailTempletePipelineDDValue: pipelineDDValue,
      emailTempleteMilestoneDDValue: mileStoneDDValue,
      emailTempleteSendToRolesDDValue: [...curElem.send_to],
      emailTemplateIsOpen: true,
    });
    // window.$("#emailTempleteModal").modal("show");
  };

  onStageArrowUpClick = (i, element) => {
    let pipeIDX = null;
    if (i > 0) {
      const newData = [...this.state.stagesData];
      [newData[i - 1], newData[i]] = [newData[i], newData[i - 1]];

      let currentPipeline = this.state.pipelineTableData.find(
        (curElem, ind) => {
          pipeIDX = ind;
          return curElem.pipeline_name == this.state.stagesPipelineState;
        }
      );
      currentPipeline.stages = newData;

      let oldPipelines = this.state.pipelineTableData?.filter((curElem) => {
        return curElem.pipeline_name !== this.state.stagesPipelineState;
      });
      oldPipelines.splice(pipeIDX, 0, currentPipeline);
      this.setState({
        pipelineTableData: oldPipelines,
        stagesData: [...currentPipeline.stages],
      });
    }
  };
  onStageArrowDownClick = (i, element) => {
    let pipeIDX = null;
    if (i < this.state.stagesData.length - 1) {
      const newData = [...this.state.stagesData];
      [newData[i], newData[i + 1]] = [newData[i + 1], newData[i]];

      let currentPipeline = this.state.pipelineTableData.find(
        (curElem, ind) => {
          pipeIDX = ind;
          return curElem.pipeline_name == this.state.stagesPipelineState;
        }
      );
      currentPipeline.stages = newData;

      let oldPipelines = this.state.pipelineTableData?.filter((curElem) => {
        return curElem.pipeline_name !== this.state.stagesPipelineState;
      });
      oldPipelines.splice(pipeIDX, 0, currentPipeline);
      this.setState({
        pipelineTableData: oldPipelines,
        stagesData: [...currentPipeline.stages],
      });
    }
  };

  onTaskArrowUpClick = (curStage, curTask, curTaskIDX) => {
    let preIdx = null;
    let allTasks = this.state.taskData;
    if (curTaskIDX != 0) {
      allTasks.map((curEle, i) => {
        if (curEle.task_stage == curStage.text && i < curTaskIDX) {
          if (i == 0) {
            preIdx = 1;
          } else {
            preIdx = i;
          }
        }
      });
      console.log(preIdx);
      if (preIdx != null) {
        allTasks.splice(curTaskIDX, 1);
        allTasks.splice(preIdx - 1, 0, curTask);
        let taskOrderIDXListMin = [];
        let taskOrderIDXListMax = [];
        this.state.mileStoneDD?.map((curMileStone, idx) => {
          let stageTaskIdxs = [];
          this.state.taskData?.map((curEle, i) => {
            if (curEle.task_stage == curMileStone.value) {
              stageTaskIdxs.push(i);
            }
          });
          if (
            Math.min(...stageTaskIdxs) != -Infinity &&
            Math.min(...stageTaskIdxs) != Infinity
          ) {
            taskOrderIDXListMin.push(Math.min(...stageTaskIdxs));
          }
          if (
            Math.max(...stageTaskIdxs) != -Infinity &&
            Math.max(...stageTaskIdxs) != Infinity
          ) {
            taskOrderIDXListMax.push(Math.max(...stageTaskIdxs));
          }
        });
        this.setState({
          taskData: allTasks,
          taskDisableListMin: taskOrderIDXListMin,
          taskDisableListMax: taskOrderIDXListMax,
        });
      }
    }
  };
  onTaskArrowDownClick = (curStage, curTask, curTaskIDX) => {
    let postIdx = null;
    let allTasks = this.state.taskData;
    if (curTaskIDX != this.state.taskData.length - 1) {
      postIdx = allTasks.findIndex((curEle, i) => {
        return curEle.task_stage == curStage.text && i > curTaskIDX;
      });
      if (postIdx > 0) {
        allTasks.splice(curTaskIDX, 1);
        allTasks.splice(postIdx, 0, curTask);
        let taskOrderIDXListMin = [];
        let taskOrderIDXListMax = [];
        this.state.mileStoneDD?.map((curMileStone, idx) => {
          let stageTaskIdxs = [];
          this.state.taskData?.map((curEle, i) => {
            if (curEle.task_stage == curMileStone.value) {
              stageTaskIdxs.push(i);
            }
          });
          if (
            Math.min(...stageTaskIdxs) != -Infinity &&
            Math.min(...stageTaskIdxs) != Infinity
          ) {
            taskOrderIDXListMin.push(Math.min(...stageTaskIdxs));
          }
          if (
            Math.max(...stageTaskIdxs) != -Infinity &&
            Math.max(...stageTaskIdxs) != Infinity
          ) {
            taskOrderIDXListMax.push(Math.max(...stageTaskIdxs));
          }
        });
        this.setState({
          taskData: allTasks,
          taskDisableListMin: taskOrderIDXListMin,
          taskDisableListMax: taskOrderIDXListMax,
        });
      }
    }
  };

  // State Dropdown Handlers
  handlePipelineChangeOnStage = (e) => {
    this.setState({ stagesPipelineState: e.newValue });
    let currentPipeline = this.state.pipelineTableData.find((curElem) => {
      return curElem.pipeline_name == e.newValue;
    });
    if (currentPipeline.stages) {
      this.setState({ stagesData: [...currentPipeline.stages] });
    } else {
      this.setState({ stagesData: [] });
    }
  };
  handlePipelineChangeOnTaskTemplete = (e) => {
    this.setState({ taskPipelineState: e.newValue });
    let currentPipeline = this.state.pipelineTableData.find((curElem) => {
      return curElem.pipeline_name == e.newValue;
    });
    if (currentPipeline.task_templates) {
      this.setState({
        taskTempleteTableData: [...currentPipeline.task_templates],
      });
    } else {
      this.setState({ taskTempleteTableData: [] });
    }
  };

  getRoleBg = (tag) => {
    const tagOBJ = [
      ...this.state.OfficeContactTags,
      ...this.state.ContactSourceTypes,
      ...this.state.UserContactTags,
    ]?.find((curTag) => {
      return curTag.Name == tag;
    });
    if (tagOBJ) {
      return tagOBJ.Color;
    }
    return "#4d4d4d";
  };

  // Tiny Word editor handelers
  handleNoticeboardContentChange = (content) => {
    this.setState({ noticeBoardContent: content });
  };
  handleUploadFile = (blobInfo, success, failure) => {
    return new Promise((resolve, reject) => {
      let data = new FormData();
      data.append("fileBase64String", blobInfo.base64());
      data.append("fileName", blobInfo.filename());

      let formData = Object.fromEntries(data.entries());

      apiAgent.Library.uploadLibraryImageAjax(formData)
        .then((response) => {
          if (response.success) {
            resolve(response.location);
          } else {
            reject("Image upload failed");
          }
        })
        .catch((error) => {
          console.log("upload error", error);
          reject("Image upload failed");
        });
    });
  };

  handleCustomizeTaskTemplateSettingUpdated = (
    mSelectedPipeline,
    mIsSuccess
  ) => {
    console.log(mSelectedPipeline);
    console.log(mIsSuccess);

    if (!mIsSuccess) return;

    var index = this.state.pipelineTableData.findIndex((e) => {
      return e.pipeline_id == mSelectedPipeline.pipeline_id;
    });

    if (index != -1) {
      var mPipelineTableData = [...this.state.pipelineTableData];
      mPipelineTableData[index] = mSelectedPipeline;
      this.setState({ pipelineTableData: mPipelineTableData });
    }
  };

  onCloseEmailTemplate = () => {
    this.setState({
      emailTemplateIsOpen: false,
    });
  };
  render() {
    var self = this;
    var tabContent = [
      { link: "#contacts", title: "Contacts" },
      { link: "#listings", title: "Listings" },
      { link: "#diary", title: "Diary" },
      { link: "#colours", title: "Colours" },
      { link: "#contents", title: "Content" },
      { link: "#dashboard", title: "Dashboard" },
    ];

    if (this.props.userSession.enabledModules?.includes(1)) {
      tabContent = [
        { link: "#contacts", title: "Contacts" },
        { link: "#listings", title: "Listings" },
        { link: "#diary", title: "Diary" },
        { link: "#colours", title: "Colours" },
        { link: "#contents", title: "Content" },
        { link: "#dashboard", title: "Dashboard" },
        { link: "#commission", title: "Commission" },
        { link: "#pipelines", title: "Pipelines" },
        { link: "#milestone", title: "Milestones" },
        { link: "#tasktemplates", title: "Task Templates" },
        { link: "#emailtemplate", title: "Email Templates" },
      ];

      // if (this.props.super_access) {
      //   tabContent.push({ link: "#emailtemplate", title: "Email Templates" });
      // }

    }

    if (self.props.actions.indexOf("save2") != -1) {
      tabContent = [{ link: "#contacts", title: "Contacts" }];
    }

    var tagHtml = null;
    if (self.state.ModelTags.length != 0) {
      tagHtml = self.state.ModelTags.map(function (item, index) {
        return (
          <span
            key={item.Name}
            className="btn btn-tag-delete"
            style={{ backgroundColor: item.Color }}
          >
            {item.Name}&nbsp;
            <i
              className="fa fa-trash-o"
              onClick={() => self.handleTagDelete(index)}
              aria-hidden="true"
            ></i>
          </span>
        );
      });
    }
    return (
      <>
        <form className="SaveCustomizeForm" onSubmit={this.handleSubmit}>
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <OMSFavourite favourite={this.props.favourite} /> &nbsp;
                    <label>Customize</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="form-group col-md-12">
                  <OMSTabBox
                    id="userTabs"
                    value={tabContent}
                    setActiveTabId={this.setActiveTabID.bind(this)}
                  ></OMSTabBox>
                  <div className="tab-content">
                    <div
                      id="contacts"
                      className={
                        this.state.activeTabID == "contacts"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <div
                            className="form-group"
                            style={{
                              display:
                                self.props.actions.indexOf("save2") != -1
                                  ? "none"
                                  : "",
                            }}
                          >
                            <label>
                              Office contact types tags&nbsp;&nbsp;
                              <i
                                className="fa fa-edit"
                                onClick={() =>
                                  this.handleOpenTagModel("office-contact-tags")
                                }
                                aria-hidden="true"
                              ></i>
                            </label>
                            <p>
                              Configure the contact types that will be available
                              for all users in your office. A contact can have
                              multiple types.
                            </p>
                            <div className="input-group">
                              {this.state.OfficeContactTags.map((item) => (
                                <span
                                  key={item.Name}
                                  className="btn btn-tag-nonclick"
                                  style={{ backgroundColor: item.Color }}
                                >
                                  {item.Name}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div
                            className="form-group"
                            style={{
                              display:
                                self.props.actions.indexOf("save2") != -1
                                  ? "none"
                                  : "",
                            }}
                          >
                            <label>
                              Office contact sources&nbsp;&nbsp;
                              <i
                                className="fa fa-edit"
                                onClick={() =>
                                  this.handleOpenTagModel(
                                    "office-contact-source-types"
                                  )
                                }
                                aria-hidden="true"
                              ></i>
                            </label>
                            <p>
                              Configure the contact sources that will be
                              available for all users in your office when they
                              list contacts. Contacts can be linked to one
                              source.
                            </p>
                            <div className="input-group">
                              {this.state.ContactSourceTypes.map((item) => (
                                <span
                                  key={item.Name}
                                  className="btn btn-tag-nonclick"
                                  style={{ backgroundColor: item.Color }}
                                >
                                  {item.Name}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              My contact type tags&nbsp;&nbsp;
                              <i
                                className="fa fa-edit"
                                onClick={() =>
                                  this.handleOpenTagModel("my-contact-tags")
                                }
                                aria-hidden="true"
                              ></i>
                            </label>
                            <p>
                              You can tag your contacts using your own
                              personalized tags. Note that other users will also
                              see these tags when they open your contact.
                            </p>
                            <div className="input-group">
                              {this.state.UserContactTags.map((item) => (
                                <span
                                  key={item.Name}
                                  className="btn btn-tag-nonclick"
                                  style={{ backgroundColor: item.Color }}
                                >
                                  {item.Name}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="listings"
                      className={
                        this.state.activeTabID == "listings"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <div className="form-group">
                            <label>Listings</label>
                            <p style={{ marginTop: 4 }}>
                              <input
                                type="checkbox"
                                name=""
                                onClick={
                                  this.handleListingSellerRequiredChanged
                                }
                                defaultChecked={
                                  this.state.ListingSellerRequired
                                }
                              />
                              &nbsp;Seller details required for new mandates.
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                name=""
                                onClick={this.handleNotifyRequiredChanged}
                                defaultChecked={
                                  this.state.IsNotifyBeforeXDaysOfExpiry
                                }
                              />
                              &nbsp;Notification when mandate is within
                              <OMSNumericBox
                                id="NotifyBeforeXDaysOfExpiry"
                                name="NotifyBeforeXDaysOfExpiry"
                                style={{
                                  width: 45,
                                  height: 30,
                                  marginLeft: 5,
                                  marginRight: 5,
                                  display: "inline-block",
                                }}
                                separator=""
                                maxLength={5}
                                value={this.state.NotifyBeforeXDaysOfExpiry}
                                isDecimal={false}
                                onChange={this.handleXDaysChange}
                              />
                              days of expiry.
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                name="IsNotifyAfterXDays"
                                onClick={this.handleNotifyRequiredChanged}
                                defaultChecked={this.state.IsNotifyAfterXDays}
                              />
                              &nbsp;Notification when active mandate is
                              <OMSNumericBox
                                id="NotifyAfterXDays"
                                name="NotifyAfterXDays"
                                style={{
                                  width: 75,
                                  height: 30,
                                  marginLeft: 5,
                                  marginRight: 5,
                                  display: "inline-block",
                                }}
                                separator=""
                                maxLength={5}
                                value={this.state.NotifyAfterXDays}
                                isDecimal={false}
                                onChange={this.handleXDaysChange}
                              />
                              days old.
                            </p>
                          </div>
                          <div className="form-group">
                            <label>
                              General listing tags&nbsp;&nbsp;
                              <i
                                className="fa fa-plus-circle"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.handleGeneralListingTagModel()
                                }
                                aria-hidden="true"
                              ></i>
                            </label>
                            <p>
                              E.g. repossesed, retirement, sea view, golf estate
                              etc. These tags are also pushed through to your
                              Flex website and matched with portals that support
                              this.{" "}
                              <a
                                href="https://help.entegral.net/en/articles/5588235-how-to-use-listing-tags"
                                target="_blank"
                              >
                                Click here{" "}
                              </a>{" "}
                              for more details.
                            </p>
                            <div style={{ maxHeight: 300, overflow: "auto" }}>
                              {this.state.GeneralListingTags.map((item) => (
                                <span
                                  key={"GLT-" + item}
                                  className="btn btn-tag-nonclick"
                                  style={{
                                    backgroundColor: "gray",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    self.handleGeneralListingTagDelete(item)
                                  }
                                >
                                  {item}{" "}
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    style={{ marginLeft: 3 }}
                                  ></i>
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              Extra description fields and tags&nbsp;&nbsp;
                              <i
                                className="fa fa-plus-circle"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handleOpenFieldModel()}
                                aria-hidden="true"
                              ></i>
                            </label>
                            <p>
                              You can configure additional description fields on
                              properties with separate tags for each.
                            </p>
                            <p>
                              <b>Note:</b> Please note that removing tags will
                              also remove tags on existing listings.
                            </p>
                            <div style={{ maxHeight: 300, overflow: "auto" }}>
                              {this.state.ExtraDescriptionFields.map((item) => (
                                <div
                                  className="input-group"
                                  key={"ED-" + item.name}
                                >
                                  <span
                                    style={{
                                      marginRight: 10,
                                      fontSize: 20,
                                      verticalAlign: "middle",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      self.handleFieldDelete(item.name)
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{ paddingTop: 10 }}
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span
                                    className="btn btn-tag-nonclick"
                                    style={{
                                      backgroundColor: "gray",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      self.handleFieldTagsClick(item.name)
                                    }
                                  >
                                    {item.name}{" "}
                                    <i
                                      className="fa fa-tag"
                                      aria-hidden="true"
                                      style={{ marginLeft: 3 }}
                                    ></i>
                                    ({item.tags.length})
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                              <div className="form-group">
                                <label>
                                  Internal listing tags&nbsp;&nbsp;
                                  <i
                                    className="fa fa-plus-circle"
                                    title="Add Internal Listing Tags"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.handleInternalListingTagModel()
                                    }
                                    aria-hidden="true"
                                  ></i>

                                  &nbsp;
                                  <a href="https://help.entegral.net/en/articles/10026006-internal-listing-and-deal-tags" target="_blank">

                                    <i
                                      className="fa fa-question-circle"
                                      title="Help"
                                      style={{ cursor: "pointer" }}
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </label>

                                <div style={{ maxHeight: 300, overflow: "auto" }}>
                                  {this.state.InternalListingTags.map((item) => (
                                    <span
                                      key={"ILT-" + item}
                                      className="btn btn-tag-nonclick"
                                      style={{
                                        backgroundColor: "gray",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        self.handleInternalListingTagDelete(item)
                                      }
                                    >
                                      {item}{" "}
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        style={{ marginLeft: 3 }}
                                      ></i>
                                    </span>
                                  ))}
                                </div>
                              </div>
                              <div className="form-group">
                                <label>
                                  Internal deal tags&nbsp;&nbsp;
                                  <i
                                    className="fa fa-plus-circle"
                                    title="Add Internal Deal Tags"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.handleInternalDealTagModel()
                                    }
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;
                                  <a href="https://help.entegral.net/en/articles/10026006-internal-listing-and-deal-tags" target="_blank">
                                    <i
                                      className="fa fa-question-circle"
                                      title="Help"
                                      style={{ cursor: "pointer" }}
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </label>

                                <div style={{ maxHeight: 300, overflow: "auto" }}>
                                  {this.state.InternalDealTags?.map((item) => (
                                    <span
                                      key={"IDT-" + item}
                                      className="btn btn-tag-nonclick"
                                      style={{
                                        backgroundColor: "gray",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        self.handleInternalDealTagDelete(item)
                                      }
                                    >
                                      {item}{" "}
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                        style={{ marginLeft: 3 }}
                                      ></i>
                                    </span>
                                  ))}
                                </div>
                              </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="diary"
                      className={
                        this.state.activeTabID == "diary"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <div className="form-group">
                            <label>
                              Office diary action types&nbsp;&nbsp;
                              <i
                                className="fa fa-edit"
                                onClick={() =>
                                  this.handleOpenTagModel(
                                    "office-diary-action-tags"
                                  )
                                }
                                aria-hidden="true"
                              ></i>
                            </label>
                            <p>
                              Configure which listing, contact and general
                              actions you want to track for users. When they
                              capture new diary actions, they will able to
                              choose from this list.
                            </p>
                            <div className="input-group">
                              {this.state.DiaryActionTags.map((item) => (
                                <span
                                  key={"DA-" + item.Name}
                                  className="btn btn-tag-nonclick"
                                  style={{ backgroundColor: item.Color }}
                                >
                                  {item.Name}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="colours"
                      className={
                        this.state.activeTabID == "colours"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <label>Brochure Colours</label>
                          <br></br>
                          <label
                            htmlFor="BrochureMainColor"
                            style={{ fontWeight: 100, width: 75 }}
                          >
                            Main
                          </label>
                          <input
                            type="color"
                            id="BrochureMainColor"
                            name="BrochureMainColor"
                            value={this.state.BrochureMainColor}
                            onChange={this.handleChange}
                          />
                          <br></br>
                          <label
                            htmlFor="BrochureSecondaryColor"
                            style={{ fontWeight: 100, width: 75 }}
                          >
                            Secondary
                          </label>
                          <input
                            type="color"
                            id="BrochureSecondaryColor"
                            name="BrochureSecondaryColor"
                            value={this.state.BrochureSecondaryColor}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <br />
                          {this.state.Themes.map((item) => {
                            return (
                              <div
                                key={"Theme-" + item.name}
                                className="radio"
                                style={{ marginBottom: 20 }}
                              >
                                <div className="theme-box">
                                  <div
                                    className="child"
                                    style={{ background: item.colour1 }}
                                  ></div>
                                  <div
                                    className="child"
                                    style={{ background: item.colour2 }}
                                  ></div>
                                </div>
                                <label>
                                  <input
                                    type="radio"
                                    value={item.name}
                                    name="radioTheme"
                                    checked={
                                      this.state.SystemTheme === item.name
                                    }
                                    onChange={this.handleSystemThemeChange}
                                  />
                                  {item.name}
                                </label>
                                <label>
                                  {this.props.actions.indexOf("theme") > 0 ? (
                                    <i
                                      className="fa fa-edit"
                                      style={{
                                        marginLeft: 5,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.handleOpenUpdateTheme(item.name)
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  ) : null}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>System Logo</label>
                            <div
                              className="form-inline"
                              style={{ marginBottom: 10 }}
                            >
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  id="rbtnUseBase"
                                  name="CustomSystemLogo"
                                  checked={!this.state.IsCustomSystemLogo}
                                  onChange={this.handleIsCustomSystemLogoChange}
                                />
                                Use default base logo
                              </label>
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  id="rbtnUseCustom"
                                  name="CustomSystemLogo"
                                  checked={this.state.IsCustomSystemLogo}
                                  onChange={this.handleIsCustomSystemLogoChange}
                                />
                                Use custom logo
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                            className="form-group"
                            style={{
                              display: this.state.IsCustomSystemLogo
                                ? ""
                                : "none",
                            }}
                          >
                            <p>
                              Upload your own logo, use transparent logos, or
                              logos that match the theme for best results
                            </p>
                            <OMSImageUpload
                              src={this.state.SystemLogoUrl}
                              width={180}
                              height={90}
                              onCropSubmit={this.OnCropSubmit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="contents"
                      className={
                        this.state.activeTabID == "contents"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <div className="form-group">
                            <label>
                              Office news categories&nbsp;&nbsp;
                              <i
                                className="fa fa-edit"
                                onClick={() =>
                                  this.handleOpenTagModel(
                                    "office-content-categories"
                                  )
                                }
                                aria-hidden="true"
                              ></i>
                            </label>
                            <p>
                              Configure the news categories that will be
                              available in your content library. News can be
                              linked to one category.
                            </p>
                            <div className="input-group">
                              {this.state.ContentCategories.map((item) => (
                                <span
                                  key={"CC-" + item.Name}
                                  className="btn btn-tag-nonclick"
                                  style={{ backgroundColor: item.Color }}
                                >
                                  {item.Name}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              Office news tags&nbsp;&nbsp;
                              <i
                                className="fa fa-edit"
                                onClick={() =>
                                  this.handleOpenTagModel("office-content-tags")
                                }
                                aria-hidden="true"
                              ></i>
                            </label>
                            <p>
                              Configure the tags that will be available for the
                              news library. News can be linked to multiple tags.
                            </p>
                            <div className="input-group">
                              {this.state.ContentTags.map((item) => (
                                <span
                                  key={"CT-" + item.Name}
                                  className="btn btn-tag-nonclick"
                                  style={{ backgroundColor: item.Color }}
                                >
                                  {item.Name}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="dashboard"
                      className={
                        this.state.activeTabID == "dashboard"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <div className="form-group">
                            <label>Dashboard link</label>
                            <p style={{ marginTop: 4 }}>
                              <input
                                type="checkbox"
                                name="IsPreApproval"
                                onClick={this.handleNotifyRequiredChanged}
                                defaultChecked={this.state.isPreApproval}
                              />
                              &nbsp; Enable additional link on your dashboard
                              for all users
                            </p>
                          </div>

                          <div className="form-group d-flex mt-4">
                            <label className="form-label dIconLabel">
                              Icon : &nbsp;
                            </label>
                            <div class="">
                              <input
                                type="text"
                                value={this.state.preApprovalIcon}
                                className="text icp-auto form-control"
                                id="preApprovalIcon"
                                name="preApprovalIcon"
                                onChange={this.handleChange}
                              />
                            </div>
                            <div class="">
                              <a
                                href="https://fontawesome.com/v4/icons/#web-application"
                                target="_blank"
                                style={{ marginLeft: 10 }}
                              >
                                Choose different icon
                              </a>
                            </div>
                          </div>
                          <div className="form-group d-flex">
                            <label className="form-label dIconLabel">
                              Link : &nbsp;
                            </label>
                            <div className="">
                              <input
                                type="text"
                                value={this.state.preApprovalLink}
                                className="text form-control"
                                id="preApprovalLink"
                                name="preApprovalLink"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-group d-flex">
                            <label className="form-label dIconLabel">
                              Text : &nbsp;
                            </label>
                            <div class="">
                              <input
                                type="text"
                                value={this.state.preApprovalText}
                                className="text form-control"
                                id="preApprovalText"
                                name="preApprovalText"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="commission"
                      className={
                        this.state.activeTabID == "commission"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between">
                            <label style={{ fontSize: 15, paddingTop: 15 }}>
                              Split Types
                            </label>
                            <button
                              type="button"
                              className="tab_btn btn-success-oms"
                              onClick={this.onNewSplitClick}
                            >
                              New Split Type
                            </button>
                          </div>
                          <br />
                          <div className="split_table">
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "start" }}>Name</th>
                                  <th style={{ textAlign: "start" }}>
                                    Default Percentage
                                  </th>
                                  <th style={{ textAlign: "start" }}>
                                    Default Nett Amount
                                  </th>
                                  <th style={{ textAlign: "start" }}>Taxed</th>
                                  <th style={{ textAlign: "end" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.spiltTableData?.map(
                                  (curElem, i) => {
                                    return (
                                      <tr key={curElem.split_name + i}>
                                        <td>{curElem.split_name}</td>
                                        {curElem.split_percentage &&
                                          curElem.split_percentage > 0 ? (
                                          <td> {curElem.split_percentage}%</td>
                                        ) : (
                                          <td></td>
                                        )}
                                        {curElem.split_amount &&
                                          curElem.split_amount > 0 ? (
                                          <td> {curElem.split_amount}</td>
                                        ) : (
                                          <td></td>
                                        )}
                                        <td>
                                          {curElem.split_taxed ? "Yes" : "No"}
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                          <i
                                            class="fa fa-sharp fa-solid fa-edit"
                                            onClick={() =>
                                              this.onSplitTypeEditClick(
                                                curElem,
                                                i
                                              )
                                            }
                                          ></i>{" "}
                                          &nbsp;
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                            style={{ fontSize: "24px" }}
                                            onClick={() =>
                                              this.onSplitTypeDelete(i)
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between">
                            <label style={{ fontSize: 15, paddingTop: 15 }}>
                              Deduction Types
                            </label>
                            <button
                              type="button"
                              style={{ maxWidth: 130, whiteSpace: "nowrap" }}
                              className="tab_btn btn-success-oms"
                              onClick={this.onNewDeductionClick}
                            >
                              New Deduction Type
                            </button>
                          </div>
                          <br />
                          <div className="split_table">
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "start" }}>Name</th>
                                  <th style={{ textAlign: "start" }}>
                                    Default Percentage
                                  </th>
                                  <th style={{ textAlign: "start" }}>
                                    Default Nett Amount
                                  </th>
                                  <th style={{ textAlign: "end" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.deductionTableData?.map(
                                  (curElem, i) => {
                                    return (
                                      <tr key={curElem.deduction_name + i}>
                                        <td>{curElem.deduction_name}</td>
                                        {curElem.deduction_percentage &&
                                          curElem.deduction_percentage > 0 ? (
                                          <td>
                                            {" "}
                                            {curElem.deduction_percentage}%
                                          </td>
                                        ) : (
                                          <td></td>
                                        )}
                                        {curElem.deduction_amount &&
                                          curElem.deduction_amount > 0 ? (
                                          <td> {curElem.deduction_amount}</td>
                                        ) : (
                                          <td></td>
                                        )}
                                        <td style={{ textAlign: "end" }}>
                                          <i
                                            class="fa fa-sharp fa-solid fa-edit"
                                            onClick={() =>
                                              this.onDeductionTypeEditClick(
                                                curElem,
                                                i
                                              )
                                            }
                                          ></i>{" "}
                                          &nbsp;
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                            style={{ fontSize: "24px" }}
                                            onClick={() =>
                                              this.onDeductionTypeDelete(i)
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="pipelines"
                      className={
                        this.state.activeTabID == "pipelines"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="col-md-3"></div>
                          <button
                            type="button"
                            className="tab_btn btn-success-oms"
                            onClick={this.onNewPipelineClick}
                          >
                            New Pipeline
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="pipline_table">
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "start" }}>Name</th>
                                  <th style={{ textAlign: "start" }}>Type</th>
                                  <th style={{ textAlign: "start" }}>
                                    Description
                                  </th>
                                  <th style={{ textAlign: "end" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.pipelineTableData.map(
                                  (curElem, i) => {
                                    return (
                                      <tr key={curElem.pipeline_name + i}>
                                        <td>{curElem.pipeline_name}</td>
                                        <td>
                                          {curElem.pipeline_type == 0
                                            ? "Deal"
                                            : "Contact"}
                                        </td>
                                        <td>{curElem.pipeline_description}</td>
                                        <td style={{ textAlign: "end" }}>
                                          <i
                                            class="fa fa-sharp fa-solid fa-edit"
                                            onClick={() =>
                                              this.onPipelineRowClick(
                                                curElem,
                                                i
                                              )
                                            }
                                          ></i>
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                            style={{ fontSize: "24px" }}
                                            onClick={() =>
                                              this.onPipelineDelete(
                                                i,
                                                curElem.pipeline_id
                                              )
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {this.state.IsETOffice == false && (
                        <div style={{ margin: "15px 0px 0px 8px" }}>
                          <a
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => this.handleOpenDefaultPipeline()}
                          >
                            Import a sample sales and rental pipeline with tasks
                          </a>
                        </div>
                      )}
                    </div>
                    <div
                      id="milestone"
                      className={
                        this.state.activeTabID == "milestone"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="col-md-3">
                            <OMSDropdown
                              id="xyz"
                              options={this.state.pipelineTableData}
                              valueField="pipeline_name"
                              labelField="pipeline_name"
                              value={this.state.stagesPipelineState}
                              onChange={this.handlePipelineChangeOnStage}
                            />
                          </div>
                          <button
                            type="button"
                            className="tab_btn btn-success-oms"
                            onClick={this.onNewStageClick}
                          >
                            New Milestone
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="pipline_table">
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "start" }}>Name</th>
                                  <th style={{ textAlign: "start" }}>
                                    Description
                                  </th>
                                  <th style={{ textAlign: "end" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.stagesData?.map((curElem, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{curElem.stage_name}</td>
                                      <td>{curElem.stage_description}</td>
                                      <td className="td-icon d-flex align-items-center">
                                        <i
                                          className="fa fa-angle-up arrow_icon"
                                          aria-hidden="true"
                                          style={{
                                            color: i == 0 ? "gray" : "",
                                          }}
                                          onClick={() =>
                                            this.onStageArrowUpClick(i, curElem)
                                          }
                                        ></i>
                                        <i
                                          className="fa fa-angle-down arrow_icon"
                                          aria-hidden="true"
                                          style={{
                                            marginLeft: "8px",
                                            color:
                                              this.state.stagesData.length -
                                                1 ==
                                                i
                                                ? "gray"
                                                : "",
                                          }}
                                          onClick={() =>
                                            this.onStageArrowDownClick(
                                              i,
                                              curElem
                                            )
                                          }
                                        ></i>
                                        <i
                                          class="fa fa-sharp fa-solid fa-edit"
                                          style={{ marginLeft: "8px" }}
                                          onClick={() =>
                                            this.onStagesEditClick(curElem, i)
                                          }
                                        ></i>
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                          style={{ marginLeft: "8px" }}
                                          onClick={() =>
                                            this.onStagesDelete(i, curElem)
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CustomizeTaskTemplateSetting
                      activeTabID={this.state.activeTabID}
                      actions={this.props.actions}
                      AssignedRolesDD={this.props.data.assignedRolesDD}
                      Pipelines={this.state.pipelineTableData}
                      ComponentUpdated={
                        this.handleCustomizeTaskTemplateSettingUpdated
                      }
                    />
                    <div
                      id="emailtemplate"
                      className={
                        this.state.activeTabID == "emailtemplate"
                          ? "tab-pane fade in active"
                          : "tab-pane fade "
                      }
                    >
                      <div className="row">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="">
                            <div className="">
                              <label>Deal Email Notification &nbsp;</label>
                            </div>

                            <div className="form-group d-flex">
                              <label
                                style={{ fontWeight: "0px" }}
                                className="form-label dIconLabel"
                              >
                                Sending Email: &nbsp;
                              </label>
                              <div className="">
                                <input
                                  type="text"
                                  value={
                                    this.state.dealNotificationSendingEmail
                                  }
                                  className="text form-control"
                                  id="dealNotificationSendingEmail"
                                  name="dealNotificationSendingEmail"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="tab_btn btn-success-oms"
                            onClick={this.onNewEmailTempleteClick}
                          >
                            New Template
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="pipline_table">
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "start" }}>Name</th>
                                  <th style={{ textAlign: "start" }}>
                                    Description
                                  </th>
                                  <th style={{ textAlign: "end" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.emailTempleteData?.map(
                                  (curElem, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{curElem.email_template_name}</td>
                                        <td>
                                          {curElem.email_template_description}
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                          <i
                                            class="fa fa-sharp fa-solid fa-edit"
                                            onClick={() =>
                                              this.onEmailTempleteRowClick(
                                                curElem,
                                                i
                                              )
                                            }
                                          ></i>{" "}
                                          &nbsp;
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                            style={{ fontSize: "24px" }}
                                            onClick={() =>
                                              this.onEmailTempleteDelete(i)
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pull-left"></div>
                  </div>
                  <div className="col-md-6">
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={this.state.ButtonStatus.disabled}
                        onClick={this.onCancelClick}
                      />
                      {this.props.actions.indexOf("save") < 0 ? null : (
                        <OMSButtonStyleL
                          type="submit"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Save"
                          ui_processing_text="Saving.."
                          processing={this.state.ButtonStatus.processing_save}
                          disabled={this.state.ButtonStatus.disabled}
                        />
                      )}
                      {this.props.actions.indexOf("save2") < 0 ? null : (
                        <OMSButtonStyleL
                          type="submit"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Save"
                          ui_processing_text="Saving.."
                          processing={this.state.ButtonStatus.processing_save}
                          disabled={this.state.ButtonStatus.disabled}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="tagModel"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="tagModelLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="tagModelLabel">
                    Tags
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="col-md-6 col-sm-12 model-tag">
                          <label>Tag:</label>
                          <input
                            type="text"
                            value={this.state.ModelTagTitle}
                            className="form-control"
                            id="ModelTagTitle"
                            name="ModelTagTitle"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12 model-tag">
                          <label>Colour:</label>
                          <OMSColorSelector
                            id="ModelTagColor"
                            value={this.state.ModelTagColor}
                            onChange={this.onColorChange}
                          />
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.handleTagAdd}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      <div
                        className="form-group col-md-12"
                        style={{ marginTop: 25 }}
                      >
                        {tagHtml}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="themeModel"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="themeModelLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="themeModelLabel">
                    Theme
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label htmlFor="TName">Theme Name</label>
                      <input
                        id="TName"
                        name="TName"
                        readOnly={true}
                        type="text"
                        className="form-control"
                        value={this.state.TName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="TColor1">Menu primary background</label>
                      <input
                        id="TColor1"
                        name="TColor1"
                        type="text"
                        className="form-control"
                        value={this.state.TColor1}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="TColor2">Menu secondary background</label>
                      <input
                        id="TColor2"
                        name="TColor2"
                        type="text"
                        className="form-control"
                        value={this.state.TColor2}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="TColor4">Menu primary font</label>
                      <input
                        id="TColor4"
                        name="TColor4"
                        type="text"
                        className="form-control"
                        value={this.state.TColor4}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="TColor7">Menu secondary font</label>
                      <input
                        id="TColor7"
                        name="TColor7"
                        type="text"
                        className="form-control"
                        value={this.state.TColor7}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="TColor5">
                        Menu search box background
                      </label>
                      <input
                        id="TColor5"
                        name="TColor5"
                        type="text"
                        className="form-control"
                        value={this.state.TColor5}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="TColor3">Page background</label>
                      <input
                        id="TColor3"
                        name="TColor3"
                        type="text"
                        className="form-control"
                        value={this.state.TColor3}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label htmlFor="TColor6">Default font</label>
                      <input
                        id="TColor6"
                        name="TColor6"
                        type="text"
                        className="form-control"
                        value={this.state.TColor6}
                        onChange={this.handleChange}
                      />
                      {
                        //<OMSColorSelector options={this.props.color_options} id="TColor6" value={this.state.TColor6} onChange={this.onColorChange} />
                      }
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onSaveUpdateTheme}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="fieldModel"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="fieldModelLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="fieldModelLabel">
                    Add extra description fields
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 model-tag">
                      <label style={{ marginRight: 5 }}>Field:</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "100%", maxWidth: 220 }}
                        id="txtFieldTitle"
                        name="txtFieldTitle"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    style={{ marginRight: 10 }}
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmitFieldClick}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="fieldTagModel"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="fieldTagModelLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="fieldTagModelLabel">
                    Tags for extra description field : {this.state.mFieldTitle}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 model-tag">
                      <label style={{ marginRight: 5 }}>Tag:</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "100%", maxWidth: 220 }}
                        id="txtFieldTagTitle"
                        name="txtFieldTagTitle"
                      />
                      <button
                        style={{ marginLeft: 10 }}
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleSubmitTagFieldClick}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="btn btn-default"
                        style={{ marginLeft: 5 }}
                        onClick={this.handleClearTagFieldClick}
                      >
                        Clear
                      </button>
                    </div>
                    <div
                      className="form-group col-md-12"
                      style={{ marginTop: 15 }}
                    >
                      {this.state.mFieldTags.map((item) => (
                        <span
                          key={"FT-" + this.state.mFieldTitle + "-" + item}
                          className="btn btn-tag-delete"
                          style={{ backgroundColor: "gray" }}
                        >
                          {item}&nbsp;
                          <i
                            className="fa fa-trash-o"
                            onClick={() => self.handleFieldTagDelete(item)}
                            aria-hidden="true"
                          ></i>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="generalListingTagsModel"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="fieldModelLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="fieldModelLabel">
                    Add general listing tag
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 model-tag">
                      <label style={{ marginRight: 5 }}>Tag:</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "100%", maxWidth: 220 }}
                        id="txtGeneralListingTag"
                        name="txtGeneralListingTag"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    style={{ marginRight: 10 }}
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmitGeneralListingClick}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="splitModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="splitModal"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h4 className="modal-title text-center" id="splitModal">
                    Save Split Type
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.newSplitValName}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "spilitName")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Default Percentage</label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.newSplitValdefaultPercentage}
                          onChange={(e) =>
                            this.handleInputValueChange(
                              e,
                              "spilitDefaultPercentage"
                            )
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Default Nett Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.newSplitValdefaultNettAmount}
                          onChange={(e) =>
                            this.handleInputValueChange(
                              e,
                              "spilitDefaultNettAmount"
                            )
                          }
                        />
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          style={{ paddingTop: "5px" }}
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={this.state.newSplitValtaxed}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "spilitTaxed")
                          }
                        />
                        <label
                          className="form-check-label mx-2"
                          style={{ marginLeft: "10px" }}
                        >
                          Taxed
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        <i className="fa fa-times"></i> Cancel
                      </button>
                      {this.state.splitTypeEditIDX === null ? (
                        <button
                          type="button"
                          onClick={this.onAddNewSplit}
                          className="btn btn-success-oms"
                        >
                          <i className="fa fa-check"></i> Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={this.onSplitTypeEditSave}
                          className="btn btn-success-oms"
                        >
                          <i className="fa fa-check"></i> Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="deductionModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="deductionModal"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h4 className="modal-title text-center" id="deductionModal">
                    Save Deduction Type
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.newDeductionValName}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "deductionName")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Default Percentage</label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.newDeductionValdefaultPercentage}
                          onChange={(e) =>
                            this.handleInputValueChange(
                              e,
                              "deductionDefaultPercentage"
                            )
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Default Nett Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          value={this.state.newDeductionValdefaultNettAmount}
                          onChange={(e) =>
                            this.handleInputValueChange(
                              e,
                              "deductionDefaultNettAmount"
                            )
                          }
                        />
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="isVatExcluded"
                          className="form-check-input"
                          checked={this.state.newIsVatExcluded}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "isVatExcluded")
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="isVatExcluded"
                        >
                          &nbsp; VAT Exempt
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Cancel
                    </button>
                    {this.state.deductionTypeEditIDX === null ? (
                      <button
                        type="button"
                        onClick={this.onAddNewDeduction}
                        className="btn btn-success-oms"
                      >
                        <i className="fa fa-check"></i> Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={this.onDeductionTypeEditSave}
                        className="btn btn-success-oms"
                      >
                        <i className="fa fa-check"></i> Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="pipelineModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="pipelineModal"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h4 className="modal-title text-center" id="pipelineModal">
                    Save Pipeline
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          // readOnly={this.state.pipelineEditIdx == null ? false : true}
                          value={this.state.newPipelineValName}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "pipelineName")
                          }
                        />
                      </div>
                      {/* <div className="form-group">
                      <input
                        type="checkbox"
                        value={this.state.pipelineIsDefault}
                        checked={this.state.pipelineIsDefault}
                        onChange={this.isDefaultPipeline}
                      />&nbsp;&nbsp;
                      <label style={{position: "absolute"}}>Default Pipeline</label>
                    </div> */}

                      {this.ETOfficeRenderDefaultPipeline()}

                      <div className="form-group">
                        <label>Type</label>
                        <OMSDropdown
                          id="pipelineType"
                          options={this.state.pipelineType}
                          valueField="value"
                          labelField="text"
                          value={this.state.pipelineTypeVal}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "pipelineStages")
                          }
                          disabled={this.state.pipelineId ? true : false}
                        />
                      </div>
                      <div className="form-group">
                        <label>Description</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.newPipelineValDescription}
                          onChange={(e) =>
                            this.handleInputValueChange(
                              e,
                              "pipelineDescription"
                            )
                          }
                        />
                      </div>
                      {this.state.pipelineTypeVal == 0 && 
                        <>
                        <div className="form-group">
                        <label>Commission Slip</label>
                        <OMSDropdown
                          id="pipelineType"
                          options={this.state.pipelineSlipType}
                          valueField="value"
                          labelField="text"
                          value={this.state.pipelineSlipVal}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "pipelineSlip")
                          }
                        />
                      </div>
                      {this.state.pipelineEditIdx != null && (
                        <>
                        <div className="form-group">
                          <label>Milestone for commission payouts</label>
                          <OMSDropdown
                            id="pipelineType"
                            options={this.state.payoutMilestoneDD}
                            valueField="value"
                            labelField="text"
                            value={this.state.payoutMilestone}
                            onChange={(e) =>
                              this.handleInputValueChange(e, "payoutMilestone")
                            }
                            />
                        </div>
                        <div className="form-group">
                        <label>Milestone for cancelled deals</label>
                        <OMSDropdown
                          id="milestonecancel"
                          options={this.state.cancelMilestoneDD}
                          valueField="value"
                          labelField="text"
                          value={this.state.cancelMilestone}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "cancelMilestone")
                          }
                        />
                      </div>
                      </>
                      )}
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Cancel
                    </button>
                    {this.state.pipelineEditIdx === null ? (
                      <button
                        type="button"
                        onClick={this.onAddNewPipeline}
                        className="btn btn-success-oms"
                      >
                        <i className="fa fa-check"></i> Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={this.onPipelineEditSave}
                        className="btn btn-success-oms"
                      >
                        <i className="fa fa-check"></i> Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="stagesModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="stagesModal"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h4 className="modal-title text-center" id="stagesModal">
                    Save Milestone
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          // readOnly={this.state.stagesEditIdx === null ? false : true}
                          value={this.state.stagesName}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "stageName")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Description</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.stagesDescription}
                          onChange={(e) =>
                            this.handleInputValueChange(e, "stageDescription")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Cancel
                    </button>
                    {this.state.stagesEditIdx === null ? (
                      <button
                        type="button"
                        onClick={this.onAddNewStages}
                        className="btn btn-success-oms"
                      >
                        <i className="fa fa-check"></i> Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={this.onStagesEditSave}
                        className="btn btn-success-oms"
                      >
                        <i className="fa fa-check"></i> Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="DefaultPipelineModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="DefaultPipelineModal"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h4 className="modal-title text-center" id="pipelineModal">
                    Default Pipelines
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      {this.state.pipelineData.map((pipeline) => (
                        <div className="form-group">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              this.onDefaultPipelineSelect(
                                e,
                                pipeline.pipeline_id
                              )
                            }
                          />
                          &nbsp;&nbsp;
                          <label style={{ position: "absolute" }}>
                            {pipeline.pipeline_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => this.onAddDefaultPipelines()}
                      className="btn btn-success-oms"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-check"></i> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="internalListingTagsModel"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="fieldModelLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="fieldModelLabel">
                    Add internal listing tag
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 model-tag">
                      <label style={{ marginRight: 5 }}>Tag:</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "100%", maxWidth: 220 }}
                        id="txtInternalListingTag"
                        name="txtInternalListingTag"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    style={{ marginRight: 10 }}
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmitInternalListingClick}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="internalDealTagsModel"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="fieldModelLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="fieldModelLabel">
                    Add internal deal tag
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 model-tag">
                      <label style={{ marginRight: 5 }}>Tag:</label>
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "100%", maxWidth: 220 }}
                        id="txtInternalDealTag"
                        name="txtInternalDealTag"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    style={{ marginRight: 10 }}
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmitInternalDealClick}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        </form>
        <OMSModal
          isOpen={this.state.emailTemplateIsOpen}
          onClose={this.onCloseEmailTemplate}
          formId={this.state.emailTempleteIDX}
          title="Save Email Template"
          onAdd={this.onAddNewEmailTemplete}
          onUpdate={this.onEditeEmailTemplete}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.emailTemplateName}
                  onChange={(e) =>
                    this.handleInputValueChange(e, "emailTempletename")
                  }
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.emailTemplateDescription}
                  onChange={(e) =>
                    this.handleInputValueChange(e, "emailTempletedescription")
                  }
                />
              </div>
              <div className="form-group">
                <label>Available Variables</label>
                <div>
                  <button
                    type="button"
                    className="variable_btn"
                    onClick={() => this.insertTextInTinyMCE("{{first_name}}")}
                  >{`{{first_name}}`}</button>
                  <button
                    type="button"
                    className="variable_btn"
                    onClick={() => this.insertTextInTinyMCE("{{last_name}}")}
                  >{`{{last_name}}`}</button>
                  <button
                    type="button"
                    className="variable_btn"
                    onClick={() => this.insertTextInTinyMCE("{{deal_name}}")}
                  >{`{{deal_name}}`}</button>
                  <button
                    type="button"
                    className="variable_btn"
                    onClick={() => this.insertTextInTinyMCE("{{deal_url}}")}
                  >{`{{deal_url}}`}</button>
                  <button
                    type="button"
                    className="variable_btn"
                    onClick={() => this.insertTextInTinyMCE("{{deal_ref}}")}
                  >{`{{deal_ref}}`}</button>
                </div>
              </div>

              <div className="form-group">
                <label>Pipeline</label>
                <OMSDropdown
                  options={this.state.emailTempletePipelineDD}
                  valueField="value"
                  labelField="text"
                  value={this.state.emailTempletePipelineDDValue}
                  onChange={(e) =>
                    this.handleChangeEmailDDs(e, "emailTempletePipelineDD")
                  }
                />
              </div>
              <div className="form-group">
                <label>Milestone</label>
                <OMSDropdown
                  options={this.state.emailTempleteMilestoneDD}
                  valueField="value"
                  labelField="text"
                  value={this.state.emailTempleteMilestoneDDValue}
                  onChange={(e) =>
                    this.handleChangeEmailDDs(e, "emailTempleteMilestoneDD")
                  }
                />
              </div>
              <div className="form-group">
                <label>Send to</label>
                <div className="email-template">
                  <OMSMultiselectDropdown
                    id="roles"
                    name="Choose some options..."
                    options={this.state.roleDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.emailTempleteSendToRolesDDValue}
                    onChange={(e) =>
                      this.handleChangeEmailDDs(e, "emailTempleteRoleDD")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Subject</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.emailTemplateSubject}
                  onChange={(e) =>
                    this.handleInputValueChange(e, "emailTempletesubject")
                  }
                />
              </div>
              <div className="form-group text_editor">
                <label>Body</label>
                {!!this.state.emailTemplateIsOpen && (
                  <TinyMCEEditor
                    ref={this.tinyMceRef}
                    value={this.state.noticeBoardContent}
                    onEditorChange={this.handleNoticeboardContentChange}
                    handleUpload={this.handleUploadFile}
                  />
                )}
              </div>
            </div>
          </div>
        </OMSModal>
      </>
    );
  }
}

export default withRouter(CustomizeBox);
