import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";

const OMSTabBox = ({ id, value, setActiveTabId }) => {
  const [moreIndex, setMoreIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabsRef = useRef(null);

  const isElementVisible = (element) => {
    return window.getComputedStyle(element).display !== "none";
  };

  const updateDimensions = useCallback(() => {
    const tabs = tabsRef.current;
    if (tabs) {
      const tabsWidth = tabs.clientWidth;
      let maxChildWidth = 0;

      const children = Array.from(tabs.children);

      children.forEach((child) => {
        if (child.id !== "lastTab") {
          const childWidth = child.clientWidth;

          if (childWidth > maxChildWidth) maxChildWidth = childWidth;
        } else {
          maxChildWidth = 130;
        }
      });

      const directCount = Math.floor(tabsWidth / maxChildWidth);
      const totalCount = value.length;

      const newMoreIndex = totalCount > directCount ? directCount - 1 : -1;

      setMoreIndex(newMoreIndex);
    }
  }, [value]);

  const moreItemClick = (index) => {
    setSelectedIndex(index);
    setActiveTabId(value[index].link.replace("#", ""));
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [value, updateDimensions]);

  useEffect(() => {
    updateDimensions();
  }, []);

  const renderTabContent = (item, index) => {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <span>{item.title}</span>
        {item.showSpinner && (
          <span style={{ marginLeft: '10px', display: 'inline-flex', alignItems: 'center' }}>
            <ClipLoader
              size={12}
              color={"#808080"}
              loading={true}
            />
          </span>
        )}
        {item.notificationText && (
          <span
            style={{
              marginLeft: "5px",
              color: "red",
              fontWeight: "bold",
              fontSize: "1.0em",
            }}
          >
            {item.notificationText}
          </span>
        )}
      </span>
    );
  };

  const tabHtml = value.map((item, index) =>
    moreIndex === -1 || index < moreIndex ? (
      <li
        key={item.title}
        className={index === selectedIndex ? "active" : ""}
        onClick={() => moreItemClick(index)}
      >
        <a
          data-index={index}
          data-toggle="tab"
          href={item.link}
          aria-expanded={index === selectedIndex}
        >
          {renderTabContent(item, index)}
        </a>
      </li>
    ) : null
  );

  const moreHtml = value.map((item, index) =>
    index >= moreIndex ? (
      <li
        key={item.title}
        className={index === selectedIndex ? "active" : ""}
        onClick={() => moreItemClick(index)}
      >
        <a
          data-index={index}
          data-toggle="tab"
          href={item.link}
          aria-expanded={index === selectedIndex}
        >
          {renderTabContent(item, index)}
        </a>
      </li>
    ) : null
  );

  return (
    <ul id="tabs" className="nav nav-tabs" ref={tabsRef}>
      {tabHtml}
      {moreIndex === -1 ? null : (
        <li id="lastTab" className={moreIndex <= selectedIndex ? "active" : ""}>
          <a className="btn dropdown-toggle" data-toggle="dropdown" href="#">
            More <span className="caret"></span>
          </a>
          <ul className="dropdown-menu">{moreHtml}</ul>
        </li>
      )}
    </ul>
  );
};

OMSTabBox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      showSpinner: PropTypes.bool,
      notificationText: PropTypes.string,
    })
  ),
  setActiveTabId: PropTypes.func,
};

OMSTabBox.defaultProps = {
  id: "",
  value: [],
};

export { OMSTabBox };