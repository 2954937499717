import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { convertToISODate } from "../../../app/common/common-functions/functions";
const moment = require("moment");

export class OverviewStepView extends React.Component {
    static propTypes = {
        data: PropTypes.object
    };

    getStateFromData = (data) => {
        return {
            data: data,
            pinnedNotes: null,
            pinDisabled: '',
        }
    };

    componentDidMount() {
        this.getPinnedNotes();
    }

    doSetState = (data) => {
        this.setState(this.getStateFromData(data));
        //this.getPinnedNotes();
    };

    doGetState = () => {
        return this.state;
    };

    ToString = (value) => {
        if (value === undefined || value == null)
            return "";
        else
            return value;
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    renderDiaryItems = () => {
        if (this.state.data.diary.diaryID == "")
            return (
                <span>no future diary events for this listing. {this.state.data.diary.diaryTitle}</span>
            )
        else
            return (
                <span><b>{this.state.data.diary.typeID}: </b>{this.state.data.diary.startDate} {this.state.data.diary.contactName}</span>
            );
    };

    renderMandate = () => {
        var SaleTypeString = "";
        if (this.state.data.mandate.saleType == 1)
            SaleTypeString = "For sale";
        else if (this.state.data.mandate.saleType == 2)
            SaleTypeString = "For rent";
        else if (this.state.data.mandate.saleType == 3)
            SaleTypeString = "Auction";
        else
            SaleTypeString = "Valuation";

        return (
            <div style={{ marginLeft: 10 }}>
                <p>{SaleTypeString}: {this.state.data.mandate.daysLeft} days left.</p>
                <h4>{this.props.CurrencyDisplay} {this.state.data.mandate.priceString}</h4>
            </div>
        );
    };

    renderContacts = () => {
        //console.log("inside renderContacts, this.props.data=", this.props.data)

        return (this.state.data.contacts.contacts.map((item, index) => {
            let displayName = item.displayName;
            if(item.type=="2"&&item.companyName&&item.companyName.length>0){
                displayName += " ("+item.companyName+")";
            }
            return (
                <p key={index}><b>{item.contactType}: </b>{displayName}</p>
            )
        }));
    };

    renderFeed = () => {
        return (
            <p>
                {this.state.data.marketing.activeCount == 0 ? <span>0 Active |&nbsp;</span> : <span><i className="fa fa-check-circle" style={{ color: 'green' }}></i>&nbsp;<b>{this.state.data.marketing.activeCount} Active</b> |&nbsp;</span>}
                {this.state.data.marketing.pendingCount == 0 ? <span>0 Pending |&nbsp;</span> : <span><i className="fa fa-clock-o" style={{ color: 'orange' }}></i>&nbsp;<b>{this.state.data.marketing.pendingCount} Pending</b> |&nbsp;</span>}
                {this.state.data.marketing.errorCount == 0 ? <span>0 Errors</span> : <span><i className="fa fa-exclamation-triangle" style={{ color: 'red' }}></i>&nbsp;<b>{this.state.data.marketing.errorCount} Errors</b></span>}
            </p>
        );
        //return this.state.data.Marketing.ActiveCount + " Active | " + this.state.data.Marketing.PendingCount + " Pending | " + this.state.data.Marketing.ErrorCount + " Errors"
    };

    renderLocation = () => {

    let complexName = "";
    let streetName = "";
    var areaText = this.state.data.location.districtOrSuburb;
    if (this.state.data.location.cityOrTown) areaText = areaText + (areaText == "" ? "" : ", ") + this.state.data.location.cityOrTown;
    if (this.state.data.location.stateOrProvince) areaText = areaText + (areaText == "" ? "" : ", ") + this.state.data.location.stateOrProvince;
    if (this.state.data.location.country) areaText = areaText + (areaText == "" ? "" : ", ") + this.state.data.location.country;

    if (this.state.data.location.unitNumber && this.state.data.location.complexName) {
      complexName = this.state.data.location.unitNumber + " " + this.state.data.location.complexName + ", ";
    } else if (this.state.data.location.unitNumber) {
      complexName = this.state.data.location.unitNumber + ", ";
    } else if (this.state.data.location.complexName) {
      complexName = this.state.data.location.complexName + ", ";
    }

    if (this.state.data.location.streetNumber && this.state.data.location.streetName) {
      streetName = this.state.data.location.streetNumber + " " + this.state.data.location.streetName + ", ";
    } else if (this.state.data.location.streetNumber) {
      streetName = this.state.data.location.streetNumber + ", ";
    } else if (this.state.data.location.streetName) {
      streetName = this.state.data.location.streetName + ", ";
    }
    return complexName + streetName + areaText;
    };

    renderFeatures = () => {
        var Rooms = JSON.parse(this.state.data.features.rooms);
        var External = JSON.parse(this.state.data.features.external);

        var result = (Rooms.map((item, index) => {
            return item["value"] + " " + item["field"] + " |"
        })).join(' ');

        result = result + (External.map((item, index) => {
            return item["value"] + " " + item["field"] + " |"
        })).join(' ');

        if (result.length > 0)
            result = result.substring(0, result.length - 1);
        return result;
    };

    renderPhotosCount = () => {
        if (this.state.data.media === undefined || this.state.data.media.photos === undefined)
            return "no photo";
        else {
            if (this.state.data.media.photos.length == 1)
                return "1 photo";
            else
                return this.state.data.media.photos.length + " photos";
        }
    };

    renderPhotos = () => {
        if (this.state.data.media === undefined || this.state.data.media.photos === undefined)
            return null;
        else {
            return (this.state.data.media.photos.map((item, index) => {
                return (
                    <img key={index} style={{ width: 70, marginLeft: 3, height: 65 }} className="img-thumbnail" src={item.dataUrl} alt={item.description} />
                )
            }));
        }
    };

    getPinnedNotes = () => {
        let ID = this.props.data.propertyID;
        let DNType = 2; //1. Contact 2. Property

        // var xhr = new XMLHttpRequest();
        // xhr.open('get', `/Diary/GetPinnedNotesAjax?ID=${ID}&SDNType=${DNType}`, true);
        // xhr.onload = function () {
        //     var data = JSON.parse(xhr.responseText);
        //     this.setState({ ...this.state, pinnedNotes: data });
        // }.bind(this);
        // xhr.send();
        apiAgent.Diary.getPinnedNotesAjax(ID,"", DNType)
          .then((response) => {
            this.setState({ ...this.state, pinnedNotes: response });
          })
          .catch((error) => {
            toast.info("Fail");
          });
    };

    togglePinNote = (noteItem) => {
        this.getPinnedNotes();
    };

    unpinNote = (note) => {
        this.setState({ ...this.state, pinDisabled: note.DiaryID });
        var changedItem = note;
        changedItem.pinned = false;
        var modifiedNoteItem = JSON.parse(JSON.stringify(changedItem));

        var data = new FormData();
            

        Object.keys(modifiedNoteItem).forEach(function (key) {
            if(key === "pinned")
            {
                data.append("strPinned", modifiedNoteItem[key]);
            }
            else if(key === "startDate")
            {
                let startDateISO = convertToISODate(modifiedNoteItem[key]);
                //console.log("startDateISO", startDateISO);
                data.append("startDate", startDateISO);
                //modifiedNoteItem[key] = startDateISO; // Update the modifiedNoteItem object
            }
            else if(key === "endDate")
            {
                let endDateISO = convertToISODate(modifiedNoteItem[key]);
                data.append("endDate", endDateISO);
                //modifiedNoteItem[key] = endDateISO; // Update the modifiedNoteItem object
            }
            else
            {
                data.append(key, modifiedNoteItem[key]);
            }
    
        });

        const formDataObj = Object.fromEntries(data.entries());


        //console.log(" inside unpinNote formDataObj", formDataObj);
        apiAgent.Diary.updateDiaryAjax(formDataObj)
          .then((response) => {
            //console.log("after response, this.state.pinnedNotes=", this.state.pinnedNotes);
            var updatePinnedNotes = this.state.pinnedNotes.filter((item) => item.diaryID !== modifiedNoteItem.diaryID);
                this.setState({ ...this.state, pinnedNotes: updatePinnedNotes, pinDisabled: '' });

                if (this.props.togglePinNoteFromOverview !== undefined)
                    this.props.togglePinNoteFromOverview();
          })
          .catch((error) => {
            this.setState({ ...this.state, pinDisabled: '' });
          });
    };

    state = this.getStateFromData(this.props.data);

    render() {
        return (
            <div className="overview-root">
                <div className="row">
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-clock-o"></i><br />
                                    Diary
                                </span>
                            </div>
                            <div className="media-body">
                                {/* <div className="diary-overview-title">
                                    Upcoming Events
                                </div> */}
                                <div className="diary-overview-content">
                                    {this.renderDiaryItems()}
                                </div>
                                {this.state.pinnedNotes && (
                                    <div>
                                        {/* <div className="diary-overview-title">
                                            Pinned Notes
                                        </div> */}
                                        <div className="diary-overview-content" style={{ display: 'flex' }}>
                                            {this.state.pinnedNotes.map((note) => (
                                                <div
                                                    key={note.diaryID}
                                                    className={`d-flex pinnedRoot read-only-note ${this.state.pinDisabled === note.diaryID ? 'disabled-note' : ''}`}
                                                    style={{ justifyContent: 'space-between', position: 'relative' }}
                                                >
                                                    <div className="pin-button" onClick={() => this.state.pinDisabled !== note.diaryID ? this.unpinNote(note) : null}>
                                                        <div className="pin-btn-tooltip">
                                                            Unpin Note
                                                        </div>
                                                        <i className="fa fa-thumb-tack" aria-hidden="true" style={{ fontSize: 14 }}></i>
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <strong>{note.diaryTitle}</strong>
                                                        <div className="pinned-note-date">
                                                            {moment(note.startDate, 'DD-MM-YYYY hh:mm A').format('DD MMM YYYY')}
                                                            {note.endDate ? ` - ${moment(note.endDate, 'DD-MM-YYYY hh:mm A').format('DD MMM YYYY')}` : ''}
                                                        </div>
                                                        <p className="note-text">
                                                            {note.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {/* <p style={{ marginTop: 20, marginLeft: 10 }}>
                                    {this.renderDiaryItems()}
                                </p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-key"></i><br />
                                    Mandate
                                </span>
                            </div>
                            <div className="media-body">
                                {this.renderMandate()}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-address-book"></i><br />
                                    Contacts
                                </span>
                            </div>
                            <div className="media-body">
                                <div style={{ marginLeft: 10 }}>
                                    {this.renderContacts()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-share-alt"></i><br />
                                    Feeds
                                </span>
                            </div>
                            <div className="media-body">
                                <div style={{ marginTop: 20, marginLeft: 10 }}>{this.renderFeed()}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-map-marker"></i><br />
                                    Location
                                </span>
                            </div>
                            <div className="media-body">
                                <p style={{ marginTop: 20, marginLeft: 10 }}>{this.renderLocation()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-bed"></i><br />
                                    Features
                                </span>
                            </div>
                            <div className="media-body">
                                <div className="ellipsis-container" style={{ marginTop: 20, marginLeft: 10 }}>
                                    <div className="ellipsis-div">{this.renderFeatures()}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span><i className="fa fa-file-text-o"></i><br />Description</span>
                            </div>
                            <div className="media-body">
                                <div className="ellipsis-container" style={{ marginTop: 5, marginLeft: 10 }}>
                                    <div className="ellipsis-div h4">{this.state.data.description.headline}</div>
                                </div>
                                <div className="ellipsis-container" style={{ marginTop: 5, marginLeft: 10 }}>
                                    <div className="ellipsis-div">{this.state.data.description.description}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="media">
                            <div className="media-left">
                                <span>
                                    <i className="fa fa-camera"></i><br />
                                    Photos
                                </span>
                            </div>
                            <div className="media-body">
                                <div style={{ float: 'left', marginTop: 20, marginLeft: 10 }}>
                                    {this.renderPhotosCount()}
                                </div>
                                <div style={{ float: 'left', marginLeft: 5 }}>
                                    {this.renderPhotos()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}