import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { OMSButtonStyleL } from "../../../../app/common/common-components/OMSButton";

interface TermsModalProps {
  show: boolean;
  onAccept: () => void;
  onHide: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ show, onAccept, onHide }) => {
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  return (
    <Modal
      show={show}
      onHide={() => {}}
      backdrop="static"
      keyboard={false}
      style={{
        padding: "20px",
      }}
    >
      <Modal.Header style={{ backgroundColor: "#f8f9fa", padding: "15px" }}>
        <Modal.Title style={{ fontSize: "22px" }}>Welcome to Insights</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "16px", lineHeight: "1.6", color: "#333" }}>
        <p>
          Insights provides access to branded Property Valuation Reports and Owner contact details from within Base. More info, including pricing details, can
          be found{" "}
          <a
            href="https://help.entegral.net/en/articles/9890912-insights-overview"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "none" }}
          >
            here
          </a>
          .
        </p>

        <h5 style={{ fontSize: "18px", fontWeight: "bold", marginTop: "20px", marginBottom: "5px" }}>
          Protection of Personal Information Act (POPIA) Compliance
        </h5>
        <p>
          The contact information provided in these reports is shared in accordance with the Protection of Personal Information Act, 2013 (POPIA). By accessing
          or using this information, you agree to handle it strictly for the intended purpose and in compliance with the terms set forth in POPIA. Under no
          circumstances should the information be used for unsolicited marketing or any other purpose outside of the original intent without explicit consent.
          Ensure all data protection measures are in place to safeguard the personal information shared.
        </p>

        <div style={{ marginTop: "20px" }}>
          <label style={{ fontSize: "16px", color: "#333" }}>
            <input type="checkbox" checked={checkboxChecked} onChange={() => setCheckboxChecked(!checkboxChecked)} style={{ marginRight: "10px" }} />I agree to
            the above and the general Entegral{" "}
            <a
              href="https://www.entegral.net/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#007bff", textDecoration: "none" }}
            >
              terms and conditions
            </a>
            .
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#f8f9fa", padding: "15px", display: "flex", justifyContent: "flex-end" }}>
        <OMSButtonStyleL
          type="button"
          ui_icon=""
          ui_type="create"
          ui_text="Access Insights"
          ui_processing_text=""
          processing={false}
          disabled={!checkboxChecked}
          onClick={onAccept}
          style={{ marginRight: "10px" }} // Add spacing between buttons
        />
        <button type="button" className="btn btn-secondary" onClick={onHide}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsModal;
