import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSDiaryNoteItem } from "../../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteItem";
import { OMSDiaryNoteCalendar } from "../../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteCalendar";


function Diary(props: any) {
  const { contact, getPinnedNotes } = props;

  // console.log("inside Diary, contact=", contact);

  const noteCalenderRef = React.createRef<any>();
  const noteItemRef = React.createRef<any>();

  const togglePinNote = (noteItem: any) => {
    getPinnedNotes();
  };

  const onOpenDairyItemFunc = (item: any) => {
    //console.log("inside onOpenDairyItemFunc, item=", item);
    if (item == null) {
      // console.log("inside onOpenDairyItemFunc: if, noteCalenderRef.current", noteCalenderRef.current);
      noteItemRef.current.onOpenDairyNoteItem(null, "", noteCalenderRef.current.getSelectedDate());
    } else {
      //console.log("inside onOpenDairyItemFunc: else, item=", item);
      noteItemRef.current.onOpenDairyNoteItem(item);
    }
  };

  const onDiaryItemSubmitCompleteFunc = (e: any) => {
    noteCalenderRef.current.onDiaryItemSubmit();
  };

  return (
    <div className="row">
      <div className="col-md-12" style={{ margin: 0, padding: 0 }}>
        <OMSDiaryNoteCalendar
          htmlID="noteCalender"
          togglePinNote={togglePinNote}
          ID={contact.contactID}
          DNType={1}
          Display="H"
          ShowAdd={true}
          ReadOnly={contact!.actions!.indexOf("save") < 0}
          ref={noteCalenderRef}
          onOpenDairyItemFunc={onOpenDairyItemFunc}
          DiaryActionTags={contact.diaryActionTags}
        />
        <OMSDiaryNoteItem
          IsReadOnly={props.contact!.actions!.indexOf("save") < 0}
          htmlID="noteItem"
          ID={contact.contactID}
          DNType={1}
          ref={noteItemRef}
          onDiaryItemSubmitCompleteFunc={onDiaryItemSubmitCompleteFunc}
          DiaryActionTags={props.contact!.diaryActionTags!}
          OnShowStart={props.contact!.onShowStart!}
          OnShowEnd={props.contact!.onShowEnd!}
          UserID={contact.userID}
        />
      </div>
    </div>
  );
}

export default Diary;
