import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import CommissionSlipBox from "./CommissionSlipBox";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default function SaveCommissionSlipPage() {

  let varConfirmModalProps = {
    showModal: false,
    isConfirmed: (result) => {},
    title: "",
    body: "",
  };

  const [CommissionSlip, setCommissionSlip] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModalProps, setConfirmationModalProps] = useState(varConfirmModalProps);

  const {
    userStore: { userSession },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let commissionSlipId = "";
    if (params.has("ID")) {
      const id = params.get("ID");
      commissionSlipId = id !== null ? id : "";
    }

    setIsLoading(true);
    apiAgent.Admin.saveCommissionSlip(commissionSlipId)
      .then((response) => {
        let oCommissionSlip = response;
        setCommissionSlip(oCommissionSlip);

        console.log("Slip", oCommissionSlip);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save CommissionSlip";

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading && CommissionSlip) {
    return (
      <>
        <HeaderWrapper />
        <div className="save-report-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "CommissionSlip", path: "/Admin/CommissionSlip" },
              { text: !CommissionSlip.commissionSlipId ? "Create" : CommissionSlip.commissionSlipName}
            ]}
          />
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <CommissionSlipBox data={CommissionSlip} setConfirmationModalProps={setConfirmationModalProps} />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
