import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import zapierImg from "../../../assets/images/zapier.png";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";

export class IntegrationGrid extends React.Component {
  state = {
    data: [],
    moduleData: [],
    officeid: this.props.officeid,
    country: this.props.country,
    loading: false,
    actions: [],
    dataApis: [],
    processing_keys: false,
    PrimaryUserDD: [],
    PrimaryUserID: "",
    SnapshotEnabled: false,
    SnapshotEnabledOn: "",
    ButtonStatus: {
      disabled: false,
      processing_save: false,
      processing_delete: false,
    },
    ConfirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
    activeTabID: "integrated-portal-integrated",
  };

  componentDidMount() {
    this.loadIntegrationsFromServer();
    this.loadOfficeKeysFromServer(true);
    this.loadModules();
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.ConfirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ ConfirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          // console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    // console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ ConfirmationModalProps: varConfirmationModalProps });
  };

  handleOfficeDDChange = (e) => {
    this.setState({ officeid: e.newValue }, function () {
      this.loadIntegrationsFromServer();
      this.loadOfficeKeysFromServer(true);
      this.loadModules();
    });
  };

  loadIntegrationsFromServer = () => {
    this.setState({ loading: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Settings/IntegrationsAjax?OfficeID=' + this.state.officeid + '&Country=' + this.state.country, true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            this.setState({ data: data.models, actions: data.actions, loading: false, SnapshotEnabled: data.SnapshotEnabled, SnapshotEnabledOn: data.SnapshotEnabledOn });
        }.bind(this);
        xhr.send(); */
    apiAgent.Setting.integrationsAjax(this.state.officeid, this.state.country)
      .then((response) => {
        this.setState({
          data: response.models,
          actions: response.actions,
          loading: false,
          SnapshotEnabled: response.snapshotEnabled,
          SnapshotEnabledOn: response.snapshotEnabledOn,
        });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  loadOfficeKeysFromServer = (firsttime) => {
    this.setState({ loading: true, dataApis: [], processing_keys: false });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Settings/OfficeKeys2Ajax?OfficeID=' + this.state.officeid + '&firsttime=' + firsttime, true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (firsttime)
                this.setState({ dataApis: data.data, PrimaryUserDD: data.PrimaryUserDD, PrimaryUserID: data.PrimaryUserID, loading: false });
            else
                this.setState({ dataApis: data.data, loading: false });
        }.bind(this);
        xhr.send(); */
    apiAgent.Setting.officeKeys2Ajax(this.state.officeid, firsttime)
      .then((response) => {
        // console.log("Response in officeKeys2Ajax", response);
        if (firsttime)
          this.setState({
            dataApis: response.data,
            PrimaryUserDD: response.primaryUserDD,
            PrimaryUserID: response.primaryUserID,
            loading: false,
          });
        else this.setState({ dataApis: response.data, loading: false });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  executeDeleteOfficeKeyClick = () => {
    this.setState({ processing_keys: true });
    /* var data = new FormData();
        data.append('OfficeID', this.state.officeid);
        data.append('ApiKey', ApiKey);
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Settings/DeleteOfficeKeyAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                this.loadOfficeKeysFromServer(false);
            }
            else {
                $.toaster(data.message, 'Fail', 'danger');
                this.setState({ processing_keys: false });
            }
        }.bind(this);
        xhr.send(data); */
    apiAgent.Setting.deleteOfficeKeyAjax(this.state.officeid, this.state.ApiKey)
      .then((response) => {
        this.loadOfficeKeysFromServer(false);
      })
      .catch((error) => {
        toast.error(error.message);
        this.setState({ processing_keys: false });
      });
  };

  onDeleteOfficeKeyClick = (ApiKey) => {
    /* bootbox.confirm({
      title: "Delete office key?",
      message: "Do you want to delete office key now? This cannot be undone.",
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Cancel',
        },
        confirm: {
          label: '<i class="fa fa-check"></i> Confirm',
        },
      },
      callback: function (result) {
        if (result) {
          this.executeDeleteOfficeKeyClick(ApiKey);
        }
      }.bind(this),
    }); */
    this.setState({ ApiKey: ApiKey });
    let title = "Delete office key?";
    let body = "Do you want to delete office key now? This cannot be undone.";
    let callback = this.executeDeleteOfficeKeyClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onCreateOfficeKeyClick = (e) => {
    var ApplicationName = window.$("#txtApplicationName").val();
    if (ApplicationName == undefined || ApplicationName == null)
      ApplicationName = "";
    else ApplicationName = ApplicationName.trim();

    if (!ApplicationName) {
      toast.info("Please enter application name");
      return;
    }

    var ApplicationPrimaryUserID = window
      .$("#ddApplicationPrimaryUserDD")
      .val();
    if (
      ApplicationPrimaryUserID == undefined ||
      ApplicationPrimaryUserID == null ||
      ApplicationPrimaryUserID == ""
    ) {
      toast.info("Please select primary API user");
      return;
    }

    var IsRestrict = window.$("#chkIsRestrict").is(":checked");

    this.setState({ processing_keys: true });
    /* var data = new FormData();
        data.append('OfficeID', this.state.officeid);
        data.append('ApplicationName', ApplicationName);
        data.append('ApplicationPrimaryUserID', ApplicationPrimaryUserID);
        data.append('IsRestrict', IsRestrict);
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Settings/SaveOfficeKeyAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                $('#txtApplicationName').val('');
                $('#ddApplicationPrimaryUserDD').val($('#hdnApplicationPrimaryUserID').val());
                this.loadOfficeKeysFromServer(false);
            }
            else {
                $.toaster(data.message, 'Fail', 'danger');
                this.setState({ processing_keys: false });
            }
        }.bind(this);
        xhr.send(data); */
    apiAgent.Setting.saveOfficeKeyAjax(
      this.state.officeid,
      ApplicationName,
      ApplicationPrimaryUserID,
      IsRestrict
    )
      .then((response) => {
        window.$("#txtApplicationName").val("");
        window
          .$("#ddApplicationPrimaryUserDD")
          .val(window.$("#hdnApplicationPrimaryUserID").val());
        this.loadOfficeKeysFromServer(false);
      })
      .catch((error) => {
        toast.error(error.message);
        this.setState({ processing_keys: false });
      });
  };

  handleSnapshotChange = (e) => {
    var title1 = "Enable Snapshot";
    var msg = "Do you want to enable snapshot now?";

    if (this.state.SnapshotEnabled) {
      title1 = "Disable Snapshot";
      msg = "Do you want to disable snapshot now?";
    }

    /* bootbox.confirm({
      title: title,
      message: msg,
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Cancel',
        },
        confirm: {
          label: '<i class="fa fa-check"></i> Confirm',
        },
      },
      callback: function (result) {
        if (result) {
          this.executeSnapshotChange();
        }
      }.bind(this),
    }); */
    let title = title1;
    let body = msg;
    let callback = this.executeSnapshotChange;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  executeSnapshotChange = (e) => {
    var enable = true;
    if (this.state.SnapshotEnabled) enable = false;

    this.setState({ processing_keys: true });
    /*  var data = new FormData();
        data.append('OfficeID', this.state.officeid);
        data.append('Enable', enable);

        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Settings/UpdateSnapshotAjax', true);
        xhr.onload = function () {
            this.setState({ processing_keys: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                this.setState({ SnapshotEnabled: data.SnapshotEnabled, SnapshotEnabledOn: data.SnapshotEnabledOn });
            }
            else {
                $.toaster(data.message, 'Fail', 'danger');
            }
        }.bind(this);
        xhr.send(data); */
    apiAgent.Setting.updateSnapshotAjax(this.state.officeid, enable)
      .then((response) => {
        this.setState({
          SnapshotEnabled: response.snapshotEnabled,
          SnapshotEnabledOn: response.snapshotEnabledOn,
        });
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        this.setState({ processing_keys: false });
      });
  };

  loadModules = () => {
    this.setState({ loading: true });
    apiAgent.Setting.officeModuleAjax(this.state.officeid)
      .then((response) => {
        this.setState({
          loading: false,
          moduleData: response.models,
        });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  onRequestActivation = (module, i) => {
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: true,
        processing_delete: false,
      },
    });
    apiAgent.Setting.saveOfficeModuleRequestActivateAjax({
      officeID: module.officeID,
      moduleID: module.moduleID,
      moduleStatus: module.moduleStatus,
    })
      .then((res) => {
        this.state.moduleData[i].moduleStatus = res.moduleStatus;
        toast.info("Request for Activation completed");
      })
      .catch((err) => {
        console.log(err);
        toast.info("Failed");
      })
      .finally((arg) => {
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      });
  };
  onRequestDeactivation = (module, i) => {
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: true,
        processing_delete: false,
      },
    });
    apiAgent.Setting.saveOfficeModuleRequestDeactivateAjax({
      officeID: module.officeID,
      moduleID: module.moduleID,
      moduleStatus: module.moduleStatus,
    })
      .then((res) => {
        this.state.moduleData[i].moduleStatus = res.moduleStatus;
        toast.info("Request for Deactivation completed");
      })
      .catch((err) => {
        console.log(err);
        toast.info("Failed");
      })
      .finally((arg) => {
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      });
  };
  setActiveTabId = (tabId) => {
    //console.log("inside setActiveTabId tabID=", tabId);
    this.setState({ activeTabID: tabId });
  };
  onActivate = (module, i) => {
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: true,
        processing_delete: false,
      },
    });
    apiAgent.Setting.saveModuleActivateAjax({
      officeID: module.officeID,
      moduleID: module.moduleID,
      moduleStatus: module.moduleStatus,
    })
      .then((res) => {
        this.state.moduleData[i].moduleStatus = res.moduleStatus;
        toast.info("Activation completed");
      })
      .catch((err) => {
        console.log(err);
        toast.info("Failed");
      })
      .finally((arg) => {
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      });
  };
  onDeactivate = (module, i) => {
    this.setState({
      ButtonStatus: {
        disabled: true,
        processing_save: true,
        processing_delete: false,
      },
    });
    apiAgent.Setting.saveModuleDeactivateAjax({
      officeID: module.officeID,
      moduleID: module.moduleID,
      moduleStatus: module.moduleStatus,
    })
      .then((res) => {
        this.state.moduleData[i].moduleStatus = res.moduleStatus;
        toast.info("Deactivation completed");
      })
      .catch((err) => {
        console.log(err);
        toast.info("Failed");
      })
      .finally((arg) => {
        this.setState({
          ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false,
          },
        });
      });
  };

  render() {
    var self = this;
    var tabContent = [
      { link: "#integrated-portal-integrated", title: "Portal integrations" },
      {
        link: "#integrated-3rdParty-integrated",
        title: "3rd Party integrations",
      },
      { link: "#integrated-modules", title: "Modules" },
    ];

    var portalHtml = null;
    if (self.state.data.length == 0) {
      portalHtml = "No data found..";
    } else {
      portalHtml = self.state.data.map(function (item) {
        var StateIcon = null;
        if (item.status == 1) {
          // Disabled
          StateIcon = (
            <i
              className="fa fa-minus-circle fa-2x"
              title="disabled"
              style={{ color: "grey" }}
            ></i>
          );
        } else if (item.status == 2) {
          // Activation pending
          StateIcon = (
            <i
              className="fa fa-check-circle fa-2x"
              title="activation pending"
              style={{ color: "orange" }}
            ></i>
          );
        } else if (item.status == 3) {
          // Active
          StateIcon = (
            <i
              className="fa fa-check-circle fa-2x"
              title="active"
              style={{ color: "green" }}
            ></i>
          );
        } else if (item.status == 4) {
          // Deactivation pending
          StateIcon = (
            <i
              className="fa fa-times-circle fa-2x"
              title="deactivation pending"
              style={{ color: "orange" }}
            ></i>
          );
        }
        return (
          <div
            className="panel panel-default"
            key={item.portalID}
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            <div className="media visible-lg visible-md">
              <div className="media-left" style={{ paddingLeft: 10 }}>
                <img
                  src={item.logoUrl}
                  className="media-object"
                  alt={item.name}
                  style={{ width: 150 }}
                />
              </div>

              <div className="media-body" style={{ paddingLeft: 10 }}>
                <div style={{ float: "left", marginRight: 15 }}>
                  <h4 className="media-heading">{item.name}</h4>
                  <p>{item.url}</p>
                </div>
                <div>
                  {item.isPaid == true ? (
                    <i
                      className="fa fa-credit-card fa-2x"
                      aria-hidden="true"
                      style={{ fontSize: 30 }}
                    ></i>
                  ) : null}
                </div>
              </div>
              <div className="media-right" style={{ paddingRight: 5 }}>
                {StateIcon}
              </div>

              <div className="media-right" style={{ paddingRight: 10 }}>
                {self.state.actions.indexOf("settings") < 0 ? null : (
                  /* <a href={"/settings/saveIntegration?OfficeID=" + self.state.officeid + "&PortalID=" + item.portalID}>
                    <i className="fa fa-cog fa-2x" />
                  </a> */
                  <Link
                    to={
                      "/settings/saveIntegration?OfficeID=" +
                      self.state.officeid +
                      "&PortalID=" +
                      item.portalID
                    }
                  >
                    <i className="fa fa-cog fa-2x" />
                  </Link>
                )}
              </div>
            </div>
            <div className="media hidden-lg hidden-md">
              <img
                className="pull-left"
                src={item.logoUrl}
                alt={item.name}
                style={{ width: 150 }}
              />
              <div className="pull-left" style={{ marginLeft: 10 }}>
                <h4 className="media-heading">{item.name}</h4>
                <p>{item.url}</p>
                {item.isPaid == true ? (
                  <span style={{ paddingRight: 10 }}>
                    <i
                      className="fa fa-credit-card fa-2x"
                      aria-hidden="true"
                      style={{ fontSize: 30 }}
                    ></i>
                  </span>
                ) : null}
                <span className="media-right-1" style={{ paddingRight: 10 }}>
                  {StateIcon}
                </span>
                {self.state.actions.indexOf("settings") < 0 ? null : (
                  <span className="media-right-1">
                    {/* <a href={"/Settings/SaveIntegration?OfficeID=" + self.state.officeid + "&PortalID=" + item.portalID}>
                      <i className="fa fa-cog fa-2x" />
                </a> */}
                    <Link
                      to={
                        "/settings/saveIntegration?OfficeID=" +
                        self.state.officeid +
                        "&PortalID=" +
                        item.portalID
                      }
                    >
                      <i className="fa fa-cog fa-2x" />
                    </Link>
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      });
    }

    // console.log("Inside render, PrimaryUserDD:", self.state.PrimaryUserDD);
    // console.log("Inside render, PrimaryUserID:", self.state.PrimaryUserID);
    return (
      <div className="col-md-12">
        <ConfirmationModalPopup {...this.state.ConfirmationModalProps} />
        <div
          className="panel panel-default panel-table oms-box"
          data-intercom-target="grid-panel"
        >
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline form-group">
                  <label>
                    <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                  </label>
                  <OMSDropdown
                    id="OfficeID"
                    options={this.props.officeDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.officeid}
                    onChange={this.handleOfficeDDChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body table-responsive">
            <div className="col-md-12">
              <OMSTabBox
                id="integratedTabs"
                value={tabContent}
                setActiveTabId={this.setActiveTabId}
              ></OMSTabBox>

              <div className="tab-content">
                <div
                  id="integrated-portal-integrated"
                  className={
                    this.state.activeTabID === "integrated-portal-integrated"
                      ? "tab-pane fade in active"
                      : "tab-pane fade"
                  }
                >
                  <div className="row">
                    <div className="col-md-12">
                      {this.state.loading != true && this.props.super_admin && (
                        <span
                          style={{
                            display: "block",
                            marginTop: 5,
                            marginBottom: 10,
                            marginLeft: 5,
                          }}
                        >
                          <input
                            name="SnapshotEnabled"
                            type="checkbox"
                            checked={this.state.SnapshotEnabled}
                            onChange={this.handleSnapshotChange}
                          />
                          &nbsp;
                          {this.state.SnapshotEnabled
                            ? "Snapshot API enabled on " +
                              this.state.SnapshotEnabledOn
                            : "Enable Snapshot API"}
                        </span>
                      )}
                    </div>
                    <div className="col-md-12">
                      {this.state.loading != true ? (
                        portalHtml
                      ) : (
                        <DataTableLoading loading={this.state.loading} />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  id="integrated-3rdParty-integrated"
                  className={
                    this.state.activeTabID === "integrated-3rdParty-integrated"
                      ? "tab-pane fade in active"
                      : "tab-pane fade"
                  }
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <p style={{ marginTop: 5 }}>
                          The Base API provides data integration options to
                          external applications.{" "}
                          <a
                            href="https://api.entegral.net/SyncAPI.html#/"
                            target="_blank"
                          >
                            Click here
                          </a>{" "}
                          for documentation.
                        </p>
                      </div>
                      <div className="form-group">
                        <label>Application Name:</label>
                        <input
                          type="text"
                          style={{ maxWidth: 350 }}
                          className="form-control required-control"
                          id="txtApplicationName"
                          name="txtApplicationName"
                        />
                        <input
                          type="hidden"
                          style={{ maxWidth: 350 }}
                          value={this.state.PrimaryUserID}
                          className="form-control required-control"
                          id="hdnApplicationPrimaryUserID"
                          name="hdnApplicationPrimaryUserID"
                        />
                      </div>
                      <div className="form-group">
                        <label>Primary API User:</label>
                        <select
                          style={{ maxWidth: 350 }}
                          defaultValue={self.state.PrimaryUserID}
                          className="form-control required-control"
                          id="ddApplicationPrimaryUserDD"
                          name="ddApplicationPrimaryUserDD"
                        >
                          {self.state.PrimaryUserDD.map(function (item, index) {
                            return (
                              <option key={item.value} value={item.value}>
                                {item.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              id="chkIsRestrict"
                              name="chkIsRestrict"
                              value="chkIsRestrict"
                              defaultChecked={true}
                            />
                            Restrict to public listing data
                          </label>
                        </div>
                        <p>
                          <b>Note:</b>&nbsp; For marketing and portal
                          interfaces, we recommend to use "restrict to public
                          listing data" to prevent personal information being
                          exposed.
                        </p>
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-success-oms"
                          onClick={this.onCreateOfficeKeyClick}
                        >
                          Create Key
                        </button>
                      </div>
                      <div className="form-group">
                        <table className="table table-bordered integrated_table">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Created</th>
                              <th>API Key</th>
                              <th>Application Name</th>
                              <th>Primary User</th>
                              <th>
                                Restrict to <br /> public listing data
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loading == true ||
                            this.state.dataApis.length == 0 ? (
                              <tr>
                                <td colSpan="5">
                                  {this.state.loading ? (
                                    <DataTableLoading
                                      loading={this.state.loading}
                                    />
                                  ) : (
                                    <span>No API keys generated yet.</span>
                                  )}
                                </td>
                              </tr>
                            ) : (
                              this.state.dataApis.map(function (item, index) {
                                return (
                                  <tr key={item.apiKey}>
                                    <td data-label="">
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() =>
                                          self.onDeleteOfficeKeyClick(
                                            item.apiKey
                                          )
                                        }
                                      >
                                        Delete
                                      </a>
                                    </td>
                                    <td data-label="Created : ">
                                      <span>{item.createdOn}</span>
                                    </td>
                                    <td data-label="API Key : ">
                                      <span style={{ wordBreak: "break-all" }}>
                                        {item.apiKey}
                                      </span>
                                    </td>
                                    <td data-label="Application Name : ">
                                      <span>{item.applicationName}</span>
                                    </td>
                                    <td data-label="Primary User : ">
                                      <span>{item.primaryUserName}</span>
                                    </td>
                                    <td data-label="Restrict to public listing data : ">
                                      <span>
                                        {item.isRestrict ? "Yes" : "No"}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="form-group">
                        <div className="media">
                          <div className="media-left">
                            <img
                              src={zapierImg}
                              className="media-object"
                              style={{ width: 80 }}
                            />
                          </div>
                          <div className="media-body">
                            <p
                              className="media-heading"
                              style={{
                                fontSize: 15,
                                lineHeight: "25px",
                                marginTop: 11,
                              }}
                            >
                              <a
                                target="_blank"
                                href="https://zapier.com/apps/entegral-base/integrations/"
                              >
                                Click here to view the Base Zapier page{" "}
                              </a>{" "}
                              <br />
                              and connect Base with all your favourite apps
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="integrated-modules"
                  className={
                    this.state.activeTabID === "integrated-modules"
                      ? "tab-pane fade in active"
                      : "tab-pane fade"
                  }
                >
                  <div className="pull-container">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        {this.state.loading != true ? (
                          <>
                            {this.state.moduleData?.map((curEle, i) => {
                              return (
                                <div
                                  className="panel panel-default"
                                  style={{ padding: 20 }}
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-column">
                                      <h1>{curEle.moduleName}</h1>
                                      {/* <a href={curEle.helpUrl}>Learn More</a>  */}
                                      <Link to={curEle.helpUrl}>
                                        Learn More
                                      </Link>
                                    </div>
                                    <div className="mx-2">
                                      {this.state.actions.indexOf(
                                        "module-request-activation"
                                      ) != -1 && curEle.moduleStatus == 1 ? (
                                        <OMSButtonStyleL
                                          style={{ marginLeft: 10 }}
                                          type="button"
                                          ui_icon=""
                                          ui_type="save"
                                          ui_text="Request Activation"
                                          ui_processing_text="Saving.."
                                          processing={
                                            this.state.ButtonStatus
                                              .processing_save
                                          }
                                          disabled={
                                            this.state.ButtonStatus.disabled
                                          }
                                          onClick={() =>
                                            this.onRequestActivation(curEle, i)
                                          }
                                        />
                                      ) : null}
                                      {this.state.actions.indexOf(
                                        "module-activation"
                                      ) != -1 &&
                                      (curEle.moduleStatus == 1 ||
                                        curEle.moduleStatus == 2) ? (
                                        <OMSButtonStyleL
                                          style={{ marginLeft: 10 }}
                                          type="button"
                                          ui_icon=""
                                          ui_type="save"
                                          ui_text="Activate"
                                          ui_processing_text="Saving.."
                                          processing={
                                            this.state.ButtonStatus
                                              .processing_save
                                          }
                                          disabled={
                                            this.state.ButtonStatus.disabled
                                          }
                                          onClick={() =>
                                            this.onActivate(curEle, i)
                                          }
                                        />
                                      ) : null}
                                      {this.state.actions.indexOf(
                                        "module-request-activation"
                                      ) != -1 && curEle.moduleStatus == 3 ? (
                                        <OMSButtonStyleL
                                          style={{ marginLeft: 10 }}
                                          type="button"
                                          ui_icon=""
                                          ui_type="save"
                                          ui_text="Request Deactivation"
                                          ui_processing_text="Saving.."
                                          processing={
                                            this.state.ButtonStatus
                                              .processing_save
                                          }
                                          disabled={
                                            this.state.ButtonStatus.disabled
                                          }
                                          onClick={() =>
                                            this.onRequestDeactivation(
                                              curEle,
                                              i
                                            )
                                          }
                                        />
                                      ) : null}
                                      {this.state.actions.indexOf(
                                        "module-activation"
                                      ) != -1 &&
                                      (curEle.moduleStatus == 3 ||
                                        curEle.moduleStatus == 4) ? (
                                        <OMSButtonStyleL
                                          style={{ marginLeft: 10 }}
                                          type="button"
                                          ui_icon=""
                                          ui_type="save"
                                          ui_text="Deactivate"
                                          ui_processing_text="Saving.."
                                          processing={
                                            this.state.ButtonStatus
                                              .processing_save
                                          }
                                          disabled={
                                            this.state.ButtonStatus.disabled
                                          }
                                          onClick={() =>
                                            this.onDeactivate(curEle, i)
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <DataTableLoading loading={this.state.loading} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer"></div>
        </div>
      </div>
    );
  }
}
