import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import { withRouter } from 'react-router-dom';

class CommissionSlipGrid extends React.Component {

  loadReportsFromServer = () => {
    this.setState({ loading: true, data: [] });
    apiAgent.Admin.commissionSlipAjax()
      .then((response) => {
        console.log(response);
        this.setState({
          data: response,
          hasTableShown: false,
          loading: false,
        });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  componentDidMount() {
    this.loadReportsFromServer();
  }

  onCreateClick = (e) => {
    this.props.history.push('/Admin/SaveCommissionSlip');
  };

  handleRowClickSlip = (data) => {
    this.props.history.push('/Admin/SaveCommissionSlip?ID=' + data.commissionSlipId);
  };

  state = {
    data: [],
    loading: false,
    hasTableShown: false,
  };

  render() {
    var columns = [
      { data: "commissionSlipName", title: "CommissionSlip Name" },
      { data: "commissionSlipDescription", title: "Description" },
      { data: "isGeneric", title: "isGeneric" },
      { data: "officeName", title: "OfficeName" },
      { data: "groupName", title: "Group Name" },
    ];

    return (
      <div className="col-md-12">
        <div className="panel panel-default panel-table oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline form-group">
                  <div className="element">
                    <div className="truncate">
                      <label>
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pull-right">
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="create"
                    ui_text="add commission slip"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCreateClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="table-responsive">
              <DataTableComp
                id="commissionslipTable"
                columns={columns}
                columnDefs={[]}
                data={this.state.data}
                order={[[1, "asc"]]}
                onRowClick={this.handleRowClickSlip}
              />
            </div>
            <DataTableLoading loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CommissionSlipGrid);
