import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EventTypeAutocomplete, OMSDropdown } from "../OMSDropdown";
import { OMSButtonStyleL2 } from "../OMSButton";
import { OMSDLUpload } from "../OMSDLUpload";
import { OMSLeadModel } from "../OMSInboxComponents/OMSLeadModel";
import { OMSActivityTimeline } from "../OMSActivityTimeline";
import { OMSCalendar } from "../OMSCalendar";
import { DataTableLoading } from "../OMSDatatables";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import apiAgent from "../../../api/apiAgent";
import DatePicker from "react-datepicker";
import $ from "jquery";
import bootbox from "bootbox";
import ConfirmationModalPopup from "../../common-components/ModalPopups/ConfirmationModalPopup";

const moment = require("moment");

export class OMSDiaryNoteItem extends React.Component {
  static propTypes = {
    htmlID: PropTypes.string.isRequired,
    ID: PropTypes.string,
    UserID: PropTypes.string,
    DNType: PropTypes.number, // 1:Contact, 2:Property, 0: Both
    DiaryActionTags: PropTypes.array,
    onDiaryItemSubmitCompleteFunc: PropTypes.func,
    OnShowStart: PropTypes.string,
    OnShowEnd: PropTypes.string,
    IsReadOnly: PropTypes.bool.isRequired,

  };



  static defaultProps = {
    htmlID: "",
    ID: "",
    UserID: "",
    DNType: 0,
    DiaryActionTags: [],
    onDiaryItemSubmitCompleteFunc: undefined,
    OnShowStart: undefined,
    OnShowEnd: undefined,
    //RecurringFrequencyID: "0",
  };

  numberOfRepeatEvents = 10;

  constructor(props) {
    super(props);
    this.fileUploadControlRef = React.createRef();
    this.leadModelRef = React.createRef();
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  getDiaryActionTags = () => {
    var items = this.props.DiaryActionTags;
    items.push({ color: "#ec1562", name: "Website Enquiry" });
    if (this.props.DNType == 2 && this.props.ID != "") {
      items.push({ color: "#ec1562", name: "Auction" });
    }
    return items.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  };

  getDefaultSearchState = () => {
    return { SDNType: 0, Search: "", CurrentSearch: "", Processing: false, Data: [] };
  };

  onOpenDairyNoteItem = (item, userID, SelectedDate) => {
    if (item && item.endDate === "") item.endDate = null;

    //console.log("onOpenDairyNoteItem", item, userID, SelectedDate);
    if (userID == undefined) userID = "";

    var dateFormat = "dd-MM-yyyy";
    var timeFormat = "h:mma";

    if (item === undefined || item == null) {
      // Add new
      if (SelectedDate === undefined || SelectedDate == null) SelectedDate = new Date();

      var mFromDateSD = moment(SelectedDate);
      //var startDate8AM = mFromDateSD.hour(8).toDate();
      var startDate8AM = mFromDateSD.hour(8).minute(0).toDate();

      this.setState({
        diaryID: "",
        officeID: "",
        userID: userID,
        IsRange: false,
        Type: "", //E.g note, viewing, valuation, phone,
        startDate: startDate8AM,
        endDate: new Date().setHours(8, 0, 0, 0),

        description: "",
        files: [],
        contactID: "",
        contactName: "",
        propertyID: "",
        propertyName: "",
        LeadID: "",
        LeadUserID: "",
        LeadTitle: "",
        RecurringFrequencyID: 0,
        RecurringEventID: "",
      });
      //console.log("inside update of onOpenDairyNoteItem, startDate=", this.state.startDate);
    } else {
      //if (mToDate != null)

      let format = "DD-MM-YYYY hh:mm A";
      //let momentObj = ;
      let isoStartDateString = moment(item.startDate, format).toISOString();
      let isoEndDateString = moment(item.endDate, format).toISOString();

      //console.log("inside update of onOpenDairyNoteItem, endDate=", item.startDate);

      this.setState({
        diaryID: item.diaryID,
        userID: item.userID,
        officeID: item.officeID || item.OfficeID,
        IsRange: item.IsRange,
        Type: item.Type,

        startDate: new Date(isoStartDateString),
        endDate: item.endDate ? new Date(isoEndDateString) : new Date(),

        description: item.description,
        files: item.files,
        contactID: item.contactID,
        contactName: item.contactName,
        propertyID: item.propertyID,
        propertyName: item.propertyName,
        leadID: item.leadID,
        leadUserID: item.leadUserID,
        leadTitle: item.leadTitle,
        RecurringFrequencyID: item.recurringFrequencyID,
        RecurringEventID: item.recurringEventID,
      });
    }

    window.$("#saveDiaryModel-" + this.props.htmlID).modal("show");
  };

  componentDidMount() {
    var icons = {
      time: "fa fa-clock-o",
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
      previous: "fa fa-arrow-left",
      next: "fa fa-arrow-right",
      today: "fa fa-screenshot",
      clear: "fa fa-trash",
      close: "fa fa-remove",
    };
  }

  handleChange = (e) => {
    // Rohit: fired only on description change, rest are to be removed

    // if (e.target.name == "dnFromDate") {
    //   this.setState({ FromDate: e.target.value });
    // } else if (e.target.name == "dnToDate") {
    //   this.setState({ ToDate: e.target.value });
    // } else {
    this.setState({ [e.target.name]: e.target.value });
    //}
  };

  handleIsRange = (e) => {
    //console.log("inside handleIsRange", e);
    this.setState({
      IsRange: e,
    });
  };

  //get

  handleTypeDDChange = (e) => {
    var typeString = e.newValue.toLowerCase().replace(/\s/g, "");
    if (typeString == "onshow" || typeString == "showhouse" || typeString == "openhouse") {
      // Rohit: for these types, set the date to the next sunday and set the time to 2pm to 5pm

      if (this.state.diaryID == "") {
        var today = moment();
        if (today.isoWeekday() != 0) {
          var daystoSunday = 7 - today.isoWeekday();
          today = today.add("days", daystoSunday);
        }

        //var mFromDate = moment(item.FromDate, "dd-MM-yyyy hh:mm A");

        var todayString = today.format("DD-MM-yyyy");

        var fromDateTime = this.getCurrentTimeFormat(this.props.OnShowStart || "02:00 PM");
        var toDateTime = this.getCurrentTimeFormat(this.props.OnShowEnd || "05:00 PM");

        let strStartDate = todayString + " " + fromDateTime;
        let strEndDate = todayString + " " + toDateTime;
        var mStartDate = moment(strStartDate, "DD-MM-yyyy hh:mm A");
        var mEndDate = moment(strEndDate, "DD-MM-yyyy hh:mm A");

        let isoStartDateString = mStartDate.toISOString();
        let isoEndDateString = mEndDate.toISOString();

        let startDate = new Date(isoStartDateString);
        let endDate = new Date(isoEndDateString);

        this.setState({
          Type: e.newValue,
          IsRange: true,
          startDate: mStartDate.toDate(),
          endDate: mEndDate.toDate(),
        });
      } else {
        this.setState({
          Type: e.newValue,
        });
      }
    } else {
      this.setState({
        Type: e.newValue,
      });
    }
  };

  handleTimeDDChange = (e) => {
    this.setState({
      [e.id]: e.newValue,
    });
  };

  getCurrentTimeFormat = (input) => {
    input = input.replace(" PM", "pm").replace(" AM", "am");
    if (input.startsWith("0")) return input.substring(1);
    else return input;
  };

  onSearchClick = (SDNType) => {
    var searchComponent = this.getDefaultSearchState();
    searchComponent.SDNType = SDNType;
    this.setState({ SearchComponent: searchComponent });
    window.$("#searchModel-" + this.props.htmlID).modal("show");
  };

  onSearchComponentInputChange = (e) => {
    if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(
      function () {
        if (this.state.SearchComponent.Search != this.state.SearchComponent.CurrentSearch) {
          this.loadSearchComponentResult(this.state.SearchComponent.Search);
        }
      }.bind(this),
      1000
    );

    var searchComponent = this.state.SearchComponent;
    searchComponent.Search = e.target.value;
    this.setState({ SearchComponent: searchComponent });
  };

  onSearchComponentSelectClick = (ID, Name) => {
    if (this.state.SearchComponent.SDNType == 2)
      // property search
      this.setState({ propertyID: ID, propertyName: Name, SearchComponent: this.getDefaultSearchState() });
    else if (this.state.SearchComponent.SDNType == 1)
      // contact search
      this.setState({ contactID: ID, contactName: Name, SearchComponent: this.getDefaultSearchState() });
    window.$("#searchModel-" + this.props.htmlID).modal("hide");
  };

  loadSearchComponentResult = (Search) => {
    var searchComponent = this.state.SearchComponent;
    searchComponent.Processing = true;
    searchComponent.Data = [];
    this.setState({ SearchComponent: searchComponent });

    apiAgent.Diary.searchContactsOrPropertiesAjax(Search, this.state.SearchComponent.SDNType)
      .then((response) => {
        var searchComponent = this.state.SearchComponent;
        searchComponent.CurrentSearch = Search;
        searchComponent.Processing = false;
        searchComponent.Data = response.data;
        this.setState({ SearchComponent: searchComponent });
      })
      .catch((error) => toast.error(error.response.data));
  };

  onFileAttachClick = (e) => {
    this.fileUploadControlRef.current.onUploadClick();
  };

  onFileAttachComplete = (e) => {
    if (e !== undefined && e != null) {
      var files = this.state.files;
      files.push(e);
      this.setState({ files: files });
    }
  };

  executeFileAttachDelete = () => {
    var files = this.state.files;
    var index = -1;
    for (var i = 0; i < files.length; i++) {
      if (files[i].id == this.state.Id) index = i;
    }
    if (index > -1) {
      files.splice(index, 1);
    }
    this.setState({ files: files });
  };

  onFileAttachDelete = (ID) => {
   
    this.setState({ Id: ID });
    let title = "Delete file?";
    let body = "Do you want to delete file now? This cannot be undone.";
    let callback = this.executeFileAttachDelete;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  getFormData = () => {
    // Rohit: gets the data from form
    var data = new FormData();
    data.append("diaryID", this.state.diaryID);
    data.append("userID", this.state.userID);
    data.append("userID2", this.props.userID ?? "");
    data.append("OfficeID", this.state.officeID);
    data.append("typeID", this.state.Type);
    data.append("description", this.state.description);

    data.append("startDate", this.state.startDate.toISOString());

    //console.log("inside getFormData:", this.state.IsRange, this.state.endDate);

    if (this.state.IsRange && this.state.endDate) {
      data.append("endDate", this.state.endDate.toISOString());
    }

    if (this.props.DNType == 1) {
      data.append("contactID", this.props.ID);
      data.append("propertyID", this.state.propertyID);
    } else if (this.props.DNType == 2) {
      data.append("contactID", this.state.contactID);
      data.append("propertyID", this.props.ID);
    } else {
      data.append("contactID", this.state.contactID);
      data.append("propertyID", this.state.propertyID);
    }

    var files = this.state.files.map((I) => I.id);
    data.append("files", files.join());
    data.append("recurringFrequencyID", this.state.RecurringFrequencyID);
    data.append("recurringEventID", this.state.RecurringEventID);
    return data;
  };

  handleSubmit = (e) => {
    // Rohit: handles the form submit
    e.preventDefault();

    let startDate = this.state.startDate;
    //console.log("inside handleSubmit, this.state=", this.state);
    //console.log("inside handleSubmit:", this.state.IsRange, this.state.endDate, this.state.ToDateTime, startDate);

    if (this.state.IsRange && this.state.endDate) {
      let endDate = this.state.endDate;
      if (startDate >= endDate) {
        toast.info("The To date must be greater than the From Date.");
        return;
      }
    }


    if (this.state.Type == "") {
      toast.info("Please select Diary Type");
      return;
    }

    var data = this.getFormData();
    const formDataObj = Object.fromEntries(data.entries());

    //console.log("inside handleSubmit, formDataObj=", formDataObj);

    this.setState({ error_message: "", processing_save: true });

    apiAgent.Diary.saveDiaryAjax(formDataObj)
      .then((response) => {
        this.setState({ error_message: "", processing_save: false });
        if (this.props.onDiaryItemSubmitCompleteFunc !== undefined) this.props.onDiaryItemSubmitCompleteFunc();
        window.$("#saveDiaryModel-" + this.props.htmlID).modal("hide");
      })
      .catch((error) => this.setState({ error_message: error.response.message, processing_save: false }));
  };

  onPropertyRemoveClick = (e) => {
    this.setState({ propertyID: "", propertyName: "" });
  };

  onContactRemoveClick = (e) => {
    this.setState({ contactID: "", contactName: "" });
  };

 

  onDeleteDiaryItemExecute = () => {
    //console.log("inside onDeleteDiaryItemExecute, recurringEventID=", this.state.RecurringEventID);
    this.setState({ error_message: "", processing_save: true });

    let recurringEventID = this.state.RecurringEventID;
    if (recurringEventID && recurringEventID.length > 0) {
      apiAgent.Diary.deleteRecurringEventsAjax(recurringEventID)
        .then((response) => {
          toast.info("Recurring events deleted successfully.");
          this.setState({ error_message: "", processing_save: false });
          if (this.props.onDiaryItemSubmitCompleteFunc !== undefined) this.props.onDiaryItemSubmitCompleteFunc();
          window.$("#saveDiaryModel-" + this.props.htmlID).modal("hide");
        })
        .catch((error) => {
          toast.error("An error occurred while deleting recurring events. Please try again.");
          this.setState({ error_message: "An error occurred", processing_save: false });
        });
    } else {
      apiAgent.Diary.deleteDiaryAjax(this.state.diaryID, this.state.userID)
        .then((response) => {
          toast.info("Diary item deleted successfully.");
          this.setState({ error_message: "", processing_save: false });
          if (this.props.onDiaryItemSubmitCompleteFunc !== undefined) this.props.onDiaryItemSubmitCompleteFunc();
          window.$("#saveDiaryModel-" + this.props.htmlID).modal("hide");
        })
        .catch((error) => {
          toast.error("An error occurred while deleting the diary item. Please try again.");
          this.setState({ error_message: "An error occurred", processing_save: false });
        });
    }
};



  onDeleteDiaryItemClick = (diaryID, IsDelete) => {
    
    this.setState({ diaryId: diaryID });
    let title = "Delete diary item?";
    let body = "Do you want to delete diary item now? This cannot be undone.";
    let callback = this.onDeleteDiaryItemExecute;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onViewLeadClick = () => {
    this.leadModelRef.current.onInboxClick(this.state.leadUserID, this.state.leadID, true, this.state.leadTitle);
  };

  onFileLinkClicked = (fileid, fileName) => {
    //console.log("onFileLinkClicked:",fileid);
    apiAgent.Library.fsDownloadFileAjax(fileid)
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = downloadUrl;
        // const disposition = response.headers['content-disposition'];
        // const parts = disposition.split(';');
        // const filenamePart = parts.find(part => part.trim().startsWith('filename='));
        // const filenameWithExtension = filenamePart.split('=')[1].trim();
        // const extensionIndex = filenameWithExtension.lastIndexOf(".");
        // a.download = filenameWithExtension.substring(0, extensionIndex);
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Failed to download file:", error);
      });
  };

  renderSearchComponentResult = (e) => {
    if (this.state.SearchComponent.Processing) {
      return <div className="form-inline">Searching....</div>;
    } else if (this.state.SearchComponent.Data.length == 0) {
      return <div className="form-inline"></div>;
    } else {
      return this.state.SearchComponent.Data.map((item) => (
        <div key={"SR" + item.id} className="form-inline searchresult">
          <a onClick={() => this.onSearchComponentSelectClick(item.id, item.name)} style={{ cursor: "pointer" }}>
            <i className={"fa " + item.typeIcon}></i>&nbsp;{item.name}
          </a>
        </div>
      ));
    }
  };

  state = {
    // state variables
    diaryID: "",
    userID: "",
    NoteTag: "", //E.g note, viewing, valuation, phone,

    startDate: new Date(),
    endDate: new Date(),
    description: "",
    files: [],
    contactID: "",
    contactName: "",
    propertyID: "",
    propertyName: "",
    LeadID: "",
    LeadUserID: "",
    LeadTitle: "",
    IsRange: false,
    SearchComponent: this.getDefaultSearchState(),
    processing_save: false,
    error_message: "",
    DiaryActionTags: this.getDiaryActionTags(),
    TimeSlots: [
      "12:00am",
      "12:30am",
      "1:00am",
      "1:30am",
      "2:00am",
      "2:30am",
      "3:00am",
      "3:30am",
      "4:00am",
      "4:30am",
      "5:00am",
      "5:30am",
      "6:00am",
      "6:30am",
      "7:00am",
      "7:30am",
      "8:00am",
      "8:30am",
      "9:00am",
      "9:30am",
      "10:00am",
      "10:30am",
      "11:00am",
      "11:30am",
      "12:00pm",
      "12:30pm",
      "1:00pm",
      "1:30pm",
      "2:00pm",
      "2:30pm",
      "3:00pm",
      "3:30pm",
      "4:00pm",
      "4:30pm",
      "5:00pm",
      "5:30pm",
      "6:00pm",
      "6:30pm",
      "7:00pm",
      "7:30pm",
      "8:00pm",
      "8:30pm",
      "9:00pm",
      "9:30pm",
      "10:00pm",
      "10:30pm",
      "11:00pm",
      "11:30pm",
    ],
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  disableRepeatFrequencyFor = ["on auction", "on show"];

  render() {
    let isRepeatFrequencyDisabled = this.state.Type && this.disableRepeatFrequencyFor.includes(this.state.Type.toLowerCase());
    isRepeatFrequencyDisabled = isRepeatFrequencyDisabled || !!this.state.diaryID;

    return (
      <div>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div
          className="modal fade"
          id={"saveDiaryModel-" + this.props.htmlID}
          tabIndex={-1}
          aria-labelledby={"saveDiaryModelLabel-" + this.props.htmlID}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id={"saveDiaryModelLabel-" + this.props.htmlID}>
                  Save Diary
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    {this.state.error_message == "" ? null : (
                      <div className="form-group">
                        <label style={{ color: "red" }}>{this.state.error_message}</label>
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="Type">Type</label>
                      <EventTypeAutocomplete options={this.props.DiaryActionTags} selectedValue={this.state.Type} onChange={this.handleTypeDDChange} />
                    </div>
                  </div>
                  <div>
                    <div className="form-group col-md-6">
                      <label htmlFor="dnFromDate">From Date</label>
                      <div className="input-group">
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={(date) => this.setState({ startDate: date })}
                          selectsStart
                          timeInputLabel="Time:"
                          dateFormat="dd-MM-yyyy h:mm aa"
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          showTimeInput
                        />
                      </div>
                      <a onClick={() => this.handleIsRange(true)} style={{ cursor: "pointer", display: this.state.IsRange ? "none" : "" }}>
                        Add Range
                      </a>
                    </div>
                    <div className="form-group col-md-6" style={{ display: this.state.IsRange ? "" : "none" }}>
                      <label htmlFor="dnToDate">To date</label>
                      <div className="input-group">
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={async (date) => await this.setState({ endDate: date })}
                          selectsEnd
                          timeInputLabel="Time:"
                          dateFormat="dd-MM-yyyy h:mm aa"
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          minDate={this.state.startDate}
                          showTimeInput
                        />
                      </div>
                      <a style={{ cursor: "pointer" }} onClick={() => this.handleIsRange(false)}>
                        remove range
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="recurringFrequency">Repeat Frequency</label>
                      <select
                        id="recurringFrequency"
                        name="recurringFrequency"
                        className="form-control"
                        value={this.state.RecurringFrequencyID}
                        disabled={isRepeatFrequencyDisabled}
                        onChange={(e) => this.setState({ RecurringFrequencyID: e.target.value })}
                      >
                        <option value="0">Does not repeat</option>
                        <option value="1">Daily</option>
                        <option value="2">Weekly</option>
                        <option value="3">Monthly</option>
                        <option value="4">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="description">{this.state.Type == "auction" ? "Venue" : "Description"}</label>
                      <div className="text-area-container">
                        <textarea
                          rows={3}
                          className="form-control"
                          id="description"
                          name="description"
                          value={this.state.description}
                          onChange={this.handleChange}
                        />
                        <div className="text-area-icons">
                          {this.props.DNType == 1 || this.props.DNType == 0 ? (
                            <a onClick={() => this.onSearchClick(2)}>
                              <i className="fa fa-home"></i>
                            </a>
                          ) : null}
                          {this.props.DNType == 2 || this.props.DNType == 0 ? (
                            <a onClick={() => this.onSearchClick(1)}>
                              <i className="fa fa-user"></i>
                            </a>
                          ) : null}
                          <a onClick={this.onFileAttachClick}>
                            <i className="fa fa-link"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {this.state.contactID != "" && this.props.DNType != 1 ? (
                      <div className="form-group searchresult">
                        {/*  <a href={"/Contacts/SaveContact?ID=" + this.state.contactID} target="_blank">
                          <i className="fa fa-address-card-o"></i>&nbsp;{this.state.contactName}
                    </a> */}
                        <Link to={`/Contacts/SaveContact?ID=${this.state.contactID}`} target="_blank">
                          <i className="fa fa-address-card-o"></i>&nbsp;{this.state.contactName}
                        </Link>
                        &nbsp;&nbsp;
                        <span style={{ cursor: "pointer" }} className="fa fa-trash-o" onClick={this.onContactRemoveClick}></span>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    {this.state.propertyID != "" && this.props.DNType != 2 ? (
                      <div className="form-group searchresult">
                        {/* <a href={"/Property/SaveProperty?ID=" + this.state.propertyID} target="_blank">
                          <i className="fa fa-home"></i>&nbsp;{this.state.propertyName}
                    </a> */}
                        <Link to={`/Property/SaveProperty?ID=${this.state.propertyID}`} target="_blank">
                          <i className="fa fa-home"></i>&nbsp;{this.state.propertyName}
                        </Link>
                        &nbsp;&nbsp;
                        <span style={{ cursor: "pointer" }} className="fa fa-trash-o" onClick={this.onPropertyRemoveClick}></span>
                      </div>
                    ) : null}
                  </div>
                  {this.state.leadID ? (
                    <div className="col-md-12">
                      <div className="form-group searchresult">
                        <a onClick={this.onViewLeadClick}>
                          <i className="fa fa-globe"></i>&nbsp;{this.state.leadTitle}
                        </a>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-12">
                    {this.state.files.map((item) => (
                      <div key={"FA" + item.id} className="form-group searchresult">
                        <span>
                          <a style={{ cursor: "pointer" }} title={item.name} onClick={() => this.onFileLinkClicked(item.id, item.name)}>
                            <i className={"fa " + item.typeIcon} aria-hidden="true"></i>&nbsp;{item.name}
                          </a>
                          &nbsp;&nbsp;
                        </span>
                        <span style={{ cursor: "pointer" }} className="fa fa-trash-o" onClick={() => this.onFileAttachDelete(item.id)}></span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {this.state.diaryID ? (
                  <div className="pull-left">
                    <OMSButtonStyleL2
                      type="button"
                      ui_icon="fa-trash-o"
                      ui_type="cancel"
                      ui_text="Delete"
                      ui_processing_text="Deleting.."
                      processing={this.state.processing_save}
                      disabled={this.state.processing_save}
                      onClick={this.onDeleteDiaryItemClick}
                    />
                  </div>
                ) : null}
                <OMSButtonStyleL2
                  type="button"
                  style={{ marginRight: 10 }}
                  ui_icon="fa-check"
                  ui_type="save"
                  ui_text="Save"
                  ui_processing_text="Saving.."
                  processing={this.state.processing_save}
                  disabled={this.state.processing_save}
                  onClick={this.handleSubmit}
                />
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id={"searchModel-" + this.props.htmlID}
          tabIndex={-1}
          data-backdrop="static"
          aria-labelledby={"searchModelLabel-" + this.props.htmlID}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id={"searchModelLabel-" + this.props.htmlID}>
                  Search
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group has-feedback">
                      <label htmlFor="SearchComponent.Search">Search</label>
                      <input
                        id="SearchComponent.Search"
                        name="SearchComponent.Search"
                        type="text"
                        className="form-control"
                        value={this.state.SearchComponent.Search}
                        onChange={this.onSearchComponentInputChange}
                      />
                      <span
                        className={this.state.SearchComponent.Processing ? "fa fa-spin fa-spinner form-control-feedback" : "fa fa-search form-control-feedback"}
                        style={{ marginTop: 5, maxHeight: 20 }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">{this.renderSearchComponentResult()}</div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <OMSDLUpload
          ref={this.fileUploadControlRef}
          id={"fileupload_" + this.props.htmlID}
          name={"fileupload_" + this.props.id}
          librarytype={5}
          parentid={this.props.contactID}
          onUploadComplete={this.onFileAttachComplete}
          hidden={true}
        />
        <OMSLeadModel id={"leadview_" + this.props.htmlID} ref={this.leadModelRef} IsReadOnly={this.props.IsReadOnly} />
      </div>
    );
  }
}
