import React, { useState } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import bootbox from "bootbox";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSFavourite } from "../../../app/common/common-components/OMSFavourite";
import { OMSImageUpload } from "../../../app/common/common-components/OMSImageUpload";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";
import { GroupModel } from "../../../app/models/GroupModel";
import { Field, Formik } from "formik";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { useHistory } from 'react-router-dom';

interface Props {
  data?: GroupModel;
  iscreate: boolean;
  setConfirmationModalProps: (confirmationModalProps: ConfirmationModalProps) => void;
}

function GroupBox(props: Props) {
  const [groupID, setGroupID] = useState(props.data!.groupID);
  const [name, setName] = useState(props.data!.name);
  const [type, setType] = useState(props.data!.type);
  const [website, setWebsite] = useState(props.data!.website);
  const [country, setCountry] = useState(props.data!.country);
  const [status, setStatus] = useState(props.data!.status);
  const [logo, setLogo] = useState(props.data!.logo);
  const [logoUrl, setLogoUrl] = useState(props.data!.logoUrl);
  const [logoUpload, setLogoUpload] = useState('');
  const [buttonStatus, setButtonStatus] = useState({
    disabled: false,
    processing_save: false,
    processing_delete: false,
  });

  let history = useHistory();

  const [activeTabID, setActiveTabID] = useState("account");

  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>({
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  });

  const openCloseDeleteModal = (isOpen: boolean) => {
    let varConfirmationModalProps = confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const showPopupAndDeleteOnConfirmation = (title: string, body: string, callback: Function) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed: boolean) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    setConfirmationModalProps(varConfirmationModalProps);
  };

  var tabContent = [
    { link: "#account", title: "Group detail" },
    { link: "#settings", title: "Settings" },
  ];

  const handleStatusChanged = (e: any) => {
    var Status = status === 1 ? 2 : 1;
    //if (status == 1) Status = 2;
    setStatus(Status);
  };

  const handleCountryChange = (e: any) => {
    setCountry(e.newValue);
  };

  const OnCropSubmit = (d: any) => {
    setLogoUpload(d);
  };

  const onHistoryClick = (e: any) => {
    history.push('/Settings/AuditTrail?ModuleID=3&ObjectID=' + groupID);
  };

  const onCancelClick = (e: any) => {
    history.push("/Settings/Groups");
  };

  const executeDeleteClick = () => {
    setButtonStatus({
      disabled: true,
      processing_save: false,
      processing_delete: true,
    });

    apiAgent.Setting.deleteGroupAjax(groupID)
      .then((response) => {
        history.push("/settings/groups");
      })
      .catch((error) => {
        toast.info("Fail");
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const onDeleteClick = (e: any) => {
    /*  bootbox.confirm({
      title: "Delete group?",
      message: "Do you want to delete group now? This cannot be undone.",
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Cancel'
        },
        confirm: {
          label: '<i class="fa fa-check"></i> Confirm'
        }
      },
      callback: function (result: any) {
        if (result) {
          executeDeleteClick();
        }
      }
    }); */
    let title = "Delete group?";
    let body = "Do you want to delete group now? This cannot be undone.";
    let callback = executeDeleteClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const doValidate = (values: GroupModel) => {
    let { name, website } = values;

    var RegWebsite = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,15}(:[0-9]{1,5})?(\/.*)?$/;
    var failed = false;

    if (!name.trim()) {
      toast.info("Please enter group name");
      failed = true;
    }

    if (!website.trim() && RegWebsite.test(website) == false) {
      toast.info("Please enter valid website");
      failed = true;
    }

    return !failed;
  };

  const handleSubmit = (values: GroupModel) => {
    if (!doValidate(values)) return;

    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    values.country = country;
    values.status = status;
    values.logoUpload = logoUpload;

    apiAgent.Setting.saveGroupAjax(values)
      .then((response) => {
        history.push("/settings/groups");
      })
      .catch((error) => {
        toast.info("Fail");
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  return (
    <Formik
      initialValues={{
        groupID: props.data!.groupID,
        name: props.data!.name,
        type: props.data!.type,
        website: props.data!.website,
        country: props.data!.country,
        status: 0,
        logo: props.data!.logo,
        logoUpload: props.data!.logoUpload,
        logoUrl: props.data!.logoUrl,
        settings: props.data!.settings,
        countryDD: props.data!.countryDD,
        dialingCodeDD: props.data!.dialingCodeDD,
        favourite: props.data!.favourite,
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, errors, values }) => (
        <form className="SaveGroupForm" onSubmit={handleSubmit}>
          <ConfirmationModalPopup {...confirmationModalProps} />
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <OMSFavourite favourite={props.data!.favourite} /> &nbsp;
                    <label>{props.iscreate ? "CREATE" : name}</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="Name">
                    Group name
                  </label>
                  <div className="controls">
                    <Field type="text" name="name" className="form-control required-control" placeholder="Name" />
                  </div>
                </div>
                <div className="col-md-12">
                  <OMSTabBox id="groupTabs" value={tabContent} setActiveTabId={setActiveTabID}></OMSTabBox>
                  <div className="tab-content">
                    <div id="account" className={activeTabID === "account" ? "tab-pane fade in active" : "tab-pane fade"}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="Country">Country</label>
                            <OMSDropdown
                              id="country"
                              name="country"
                              options={props.data!.countryDD}
                              valueField="value"
                              labelField="text"
                              value={country}
                              onChange={handleCountryChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="Type">Group type</label>
                            <Field as="select" name="type" className="form-control">
                              <option value="1">Office group</option>
                              <option value="2">Multi list group</option>
                              <option value="3">Regional office group</option>
                            </Field>
                          </div>
                          <div className="form-group">
                            <label htmlFor="Website">Website</label>
                            <Field type="text" name="website" className="form-control" placeholder="Website" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <OMSImageUpload src={logoUrl} width={300} height={100} compressor={true} onCropSubmit={OnCropSubmit} />
                          </div>
                          <div className="form-group">
                            <label className="switch">
                              <Field type="checkbox" name="status" checked={status === 1} onChange={handleStatusChanged} />
                              <div className="slider round" />
                              <span
                                style={{
                                  marginLeft: 70,
                                  display: "block",
                                  width: 120,
                                  marginTop: 7,
                                }}
                              >
                                Status: {status === 1 ? "Active" : "Inactive"}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="settings" className={activeTabID === "settings" ? "tab-pane fade in active" : "tab-pane fade"}>
                      <div className="row">
                        <div className="col-md-6"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-left">
                      {props.iscreate == false ? (
                        <div className="dropup">
                          <button className="btn btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                            More..
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu" style={{ width: "auto" }}>
                            <li>
                              <OMSButtonStyleL
                                style={{ marginRight: 10 }}
                                type="button"
                                ui_icon="fa-history"
                                ui_type="cancel"
                                ui_text="History"
                                ui_processing_text=""
                                processing={false}
                                disabled={buttonStatus.disabled}
                                onClick={onHistoryClick}
                              />
                            </li>
                            <li>
                              {" "}
                              <OMSButtonStyleL
                                type="button"
                                ui_icon="fa-trash-o"
                                ui_type="delete"
                                ui_text="Delete group"
                                ui_processing_text="Deleting.."
                                processing={buttonStatus.processing_delete}
                                disabled={buttonStatus.disabled}
                                onClick={onDeleteClick}
                              />
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={buttonStatus.disabled}
                        onClick={onCancelClick}
                      />
                      <OMSButtonStyleL
                        type="submit"
                        ui_icon="fa-check"
                        ui_type="save"
                        ui_text="Save"
                        ui_processing_text="Saving.."
                        processing={buttonStatus.processing_save}
                        disabled={buttonStatus.disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
export default GroupBox;
