import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import { P24AreaMapResponseModel } from "../../../app/models/P24AreaMapResponseModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import P24AreaMapBox from "./P24AreaMapBox";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function P24AreaMapPage() {
  let varConfirmModalProps: ConfirmationModalProps = {
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  };

  const [isLoading, setIsLoading] = useState(true);
  const [p24AreaMap, setP24AreaMap] = useState<P24AreaMapResponseModel>();
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>(varConfirmModalProps);
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    setIsLoading(true);
    apiAgent.Admin.getP24AreaMap()
      .then((response: any) => {
        
        let oP24AreaMap: any = response;
        setP24AreaMap(oP24AreaMap);
        //console.log("P24AreaMap", oP24AreaMap.p24MappingList);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - p24 area Mapping";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && p24AreaMap) {
    return (
      <>
        <HeaderWrapper />

        <div className="container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "P24 Area Mapping" },
            ]}
          />

          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <P24AreaMapBox
                CountryDD={p24AreaMap.countryDD}
                //P24Areas={p24AreaMap.P24Areas}
                P24MappingList={p24AreaMap.p24MappingList}
                setConfirmationModalProps={setConfirmationModalProps}
              />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
