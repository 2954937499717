import React from "react";
import bootbox from "bootbox";
import apiAgent from "../../app/api/apiAgent";
import { toast } from "react-toastify";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { OMSNumericBox } from "../../app/common/common-components/OMSComponent";
import { OMSTwitterContactAutocomplete } from "../../app/common/common-components/OMSAutocomplete";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";

export class ReferralComponent extends React.Component {
  state = {
    contactID: this.props.contactID,
    OfficeID: this.props.officeID,
    rReferredID: "",
    rReferredFrom: "",
    rReferredFromName: "",
    rReferredTo: "",
    rReferredToName: "",
    rReferredFromType: 1,
    rReferredToType: 1,
    rReferralCommision: 0,
    rReferralCommisionUnit: 0,
    rNote: "",
    rStatus: 0,
    rProcessing: false,
    rReferrals: [],
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
    rReferralsID: "",
  };

  componentDidMount() {
    if (this.props.contactID != "") {
      this.refreshReferralsGrid();
    }
    //console.log("inside componentDidMount", this.state.rReferrals);
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNumericChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onPropertyItemClick = (propertyID) => {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = "/Property/SaveProperty?ID=" + propertyID;
    a.click();
  };

  refreshReferralsGrid = () => {
    this.setState({ rProcessing: true });
    /* var xhr = new XMLHttpRequest();
      xhr.open('get', '/Contacts/ReferralAjax?contactID=' + this.props.contactID, true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        this.setState({ rReferrals: data, rProcessing: false });
      }.bind(this);
      xhr.send(); */
    apiAgent.Contact.referralAjax(this.props.contactID)
      .then((response) => {
        //console.log("inside refreshReferralsGrid", response);
        this.setState({ rReferrals: response, rProcessing: false });
      })
      .catch((error) => toast.info("Fail"));
  };

  handleAddReferralClick = (e) => {
    //console.log("inside handleAddReferralClick",this.props);
    this.setState({
      rReferralID: "",
      rReferredFrom: this.props.assigned,
      rReferredFromName: this.props.assignedName,
      rReferredFromType: 1,
      rReferredToType: 1,
      rReferredTo: "",
      rReferredToName: "",
      rReferralCommision: 0,
      rReferralCommisionUnit: 0,
      rNote: "",
      rStatus: 0,
    });
    window.$("#addReferralModel").modal("show");
  };

  handleReferralCommisionUnitClick = (value) => {
    this.setState({ rReferralCommisionUnit: value });
  };

  onReferredFromChange = (e) => {
    this.setState({
      rReferredFrom: e.id,
      rReferredFromName: e.displayName,
      rReferredFromType: e.type,
    });
  };

  onReferredToChange = (e) => {
    //console.log("inside onReferredToChange",e);
    this.setState({
      rReferredTo: e.id,
      rReferredToName: e.displayName,
      rReferredToType: e.type,
    });
  };

  removeReferredFrom = (e) => {
    this.setState({
      rReferredFrom: "",
      rReferredFromName: "",
      rReferredFromType: 0,
    });
  };

  removeReferredTo = (e) => {
    this.setState({ rReferredTo: "", rReferredToName: "", rReferredToType: 0 });
  };

  getFormDataReferral = (model) => {
    var data = new FormData();

    data.append("contactID", model.contactID);
    data.append("referralID", model.referralID);
    data.append("officeID", model.officeID);
    data.append("note", model.note);
    data.append("referredFrom", model.referredFrom);
    data.append("referredTo", model.referredTo);
    data.append("referralCommision", model.referralCommision);
    data.append("referralCommisionUnit", model.referralCommisionUnit);
    data.append("referredFromType", model.referredFromType);
    data.append("referredToType", model.referredToType);
    data.append("status", model.status);
    data.append("referredFromName", model.referredFromName);
    data.append("referredToName", model.referredToName);
    data.append("strIsDeleted", model.isDeleted);

    return data;
  };

  doSaveReferral = (e) => {
    if (!this.state.rReferredFrom) {
      toast.info("Please select referred from");
      //toast.info("Please select referred from");
      return;
    }

    if (!this.state.rReferredTo) {
      toast.info("Please select referred to");
      return;
    }

    if (this.state.rReferredFrom == this.state.rReferredTo) {
      toast.info("Referred from and referred to should be different");
      return;
    }

    var model = {
      referralID: this.state.rReferralID,
      contactID: this.props.contactID,
      officeID: this.props.officeID,
      referredFrom: this.state.rReferredFrom,
      referredTo: this.state.rReferredTo,
      referralCommision: this.state.rReferralCommision,
      referralCommisionUnit: this.state.rReferralCommisionUnit,
      referredFromType: this.state.rReferredFromType,
      referredToType: this.state.rReferredToType,
      note: this.state.rNote,
      status: this.state.rStatus,
      isDeleted: false,
      referredFromName: this.state.rReferredFromName,
      referredToName: this.state.rReferredToName,
    };

    var data = this.getFormDataReferral(model);
    const formDataObj = Object.fromEntries(data.entries());

    this.setState({ rProcessing: true });
    /* var xhr = new XMLHttpRequest();
      xhr.open('post', '/Contacts/SaveReferral', true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        if (data.status == "success") {
          window.$('#addReferralModel').modal('hide');
          this.setState({ rProcessing: false });
          this.refreshReferralsGrid();
        }
        else {
          toast.info(data.message, 'Fail', 'danger');
          this.setState({ rProcessing: false });
        }
      }.bind(this);
      xhr.send(data); */
    //console.log("inside doSaveReferral,formDataObj=",formDataObj);
    // const json=JSON.stringify(formDataObj);
    // console.log("inside doSaveReferral, json=",json);
    apiAgent.Contact.saveReferral(formDataObj, this.props.userID)
      .then((response) => {
        window.$("#addReferralModel").modal("hide");
        this.setState({ rProcessing: false });
        this.refreshReferralsGrid();
      })
      .catch((error) => toast.info("Fail"))
      .finally(() => {
        this.setState({ rProcessing: false });
      });
  };

  updateReferral = (ID) => {
    var index = -1;
    for (var i = 0; i < this.state.rReferrals.length; i++) {
      if (this.state.rReferrals[i].referralID == ID) index = i;
    }
    if (index != -1) {
      var model = this.state.rReferrals[index];
      this.setState({
        rReferralID: model.referralID,
        rReferredFrom: model.referredFrom,
        rReferredFromName: model.referredFromName,
        rReferredFromType: model.referredFromType,
        rReferredToType: model.referredToType,
        rReferredTo: model.referredTo,
        rReferredToName: model.referredToName,
        rReferralCommision: model.referralCommision,
        rReferralCommisionUnit: model.referralCommisionUnit,
        rNote: model.note,
        rStatus: model.status,
      });
      window.$("#addReferralModel").modal("show");
    }
  };

  deleteReferral = async (ID) => {
    //console.log("inside deleteReferral, ID=", ID);
    var index = -1;
    for (var i = 0; i < this.state.rReferrals.length; i++) {
      if (this.state.rReferrals[i].referralID == ID) index = i;
    }
    if (index != -1) {
      /* bootbox.confirm({
        title: "Delete referral?",
        message: "Do you want to delete referral?",
        buttons: {
          cancel: {
            label: '<i class="fa fa-times"></i> Cancel',
          },
          confirm: {
            label: '<i class="fa fa-check"></i> Confirm',
          },
        },
        callback: function (result) {
          if (result) {
            this.executeDeleteReferral(this.state.rReferrals[index]);
          }
        }.bind(this),
      }); */
      await this.setState({ rReferralsID: this.state.rReferrals[index] });
      let title = "Delete referral?";
      let body = "Do you want to delete referral?";
      let callback = this.executeDeleteReferral;
      this.showPopupAndDeleteOnConfirmation(title, body, callback);
    }
  };

  executeDeleteReferral = () => {
    var model = this.state.rReferralsID;
    model.isDeleted = true;
    var data = this.getFormDataReferral(model);
    const formDataObj = Object.fromEntries(data.entries());
    formDataObj.isDeleted = model.isDeleted;
    this.setState({ rProcessing: true });
    /* var xhr = new XMLHttpRequest();
      xhr.open('post', '/Contacts/SaveReferral', true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        if (data.status == "success") {
          this.setState({ rProcessing: false });
          this.refreshReferralsGrid();
        }
        else {
          toast.info(data.message, 'Fail', 'danger');
          this.setState({ rProcessing: false });
        }
      }.bind(this);
      xhr.send(data); */
    apiAgent.Contact.saveReferral(formDataObj, this.props.userID)
      .then((response) => {
        this.setState({ rProcessing: false });
        this.refreshReferralsGrid();
      })
      .catch((error) => toast.info("Fail"))
      .finally(() => {
        this.setState({ rProcessing: false });
      });
  };

  getReferralText = (item) => {
    var infoText = "";
    if (item.status == 0) infoText = "New";
    else if (item.status == 1) infoText = "Active";
    else if (item.status == 2) infoText = "Closed(failure)";
    else if (item.status == 3) infoText = "Closed(rejected)";
    else infoText = "Closed(success)";

    infoText = infoText + " | referred to: " + item.referredToName;
    infoText = infoText + " | referred from: " + item.referredFromName;
    infoText = infoText + " | note: " + item.note;

    return infoText;
  };

  render() {
    var self = this;
    var CurrencyDisplay = this.props.currencyDisplay;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <ConfirmationModalPopup {...this.state.confirmationModalProps} />
            <hr />
          </div>
        </div>

        <div className="row" style={{ marginBottom: 8 }}>
          <div className="col-md-6 col-sm-12">
            <div className="form-inline form-group">
              <label htmlFor="Referrals">Referrals</label>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="pull-right">
              <div className="form-inline form-group">
                {this.props.actions.indexOf("save") < 0 ? null : (
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="create"
                    ui_text="create referral"
                    ui_processing_text="create referral..."
                    processing={false}
                    disabled={false}
                    onClick={this.handleAddReferralClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" style={{ marginTop: 8 }}>
            {this.state.rReferrals.length == 0 ? (
              <div className="form-group">
                <span>no referral records</span>
              </div>
            ) : (
              this.state.rReferrals.map(function (item, index) {
                return (
                  <div key={index} className="form-group">
                    <div className="ellipsis-container">
                      <div className="ellipsis-div">
                        <span
                          style={{ marginRight: 10, cursor: "pointer" }}
                          className={self.state.rProcessing ? "fa fa-spin fa-spinner" : "fa fa-trash"}
                          onClick={() => self.deleteReferral(item.referralID)}
                        ></span>
                        <a style={{ cursor: "pointer" }} onClick={() => self.updateReferral(item.referralID)}>
                          <b>{item.createdOn}: </b>
                          {self.getReferralText(item)}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="modal fade" id="addReferralModel" tabIndex={-1} aria-labelledby="addReferralModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="addReferralModelLabel">
                  {this.state.rReferralID == "" ? "Create Referral" : "Update Referral"}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Referred from&nbsp;&nbsp;</label>
                      <br />
                      {this.state.rReferredFrom != "" ? (
                        <div className="form-group">
                          <span style={{ marginRight: 10, cursor: "pointer" }} onClick={this.removeReferredFrom}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </span>
                          <span>
                            <i className={this.state.rReferredFromType == 1 ? "fa fa-user-circle" : "fa fa-user"} aria-hidden="true"></i>
                            &nbsp;
                            {this.state.rReferredFromName}
                          </span>
                        </div>
                      ) : null}
                      {/*this.state.rReferredFrom != '' ? <span><i className={this.state.rReferredFromType == 1 ? "fa fa-user-circle" : "fa fa-user"} aria-hidden="true"></i>&nbsp;{this.state.rReferredFromName}</span> : null*/}
                      <OMSTwitterContactAutocomplete
                        clearOnSelect={true}
                        onlyAgents={true}
                        officeid={this.props.officeID}
                        id="rReferredFrom"
                        placeholder="referred from"
                        name="rReferredFrom"
                        style={{ width: 250 }}
                        value={this.state.rReferredFrom}
                        onChange={this.onReferredFromChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Referred to&nbsp;&nbsp;</label>
                      {this.state.rReferredTo != "" ? (
                        <div className="form-group">
                          <span style={{ marginRight: 10, cursor: "pointer" }} onClick={this.removeReferredTo}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </span>
                          <span>
                            <i className={this.state.rReferredToType == 1 ? "fa fa-user-circle" : "fa fa-user"} aria-hidden="true"></i>
                            &nbsp;
                            {this.state.rReferredToName}
                          </span>
                        </div>
                      ) : null}
                      {/*this.state.rReferredTo != '' ? <span><i className={this.state.rReferredToType == 1 ? "fa fa-user-circle" : "fa fa-user"} aria-hidden="true"></i>&nbsp;{this.state.rReferredToName}</span> : null*/}
                      <OMSTwitterContactAutocomplete
                        clearOnSelect={true}
                        onlyAgents={true}
                        officeid={this.props.officeID}
                        id="rReferredTo"
                        placeholder="referred to"
                        name="rReferredTo"
                        style={{ width: 250 }}
                        value={this.state.rReferredTo}
                        onChange={this.onReferredToChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Referral commission</label>
                      <div className="input-group">
                        <OMSNumericBox
                          id="rReferralCommision"
                          separator=""
                          placeholder="referral commission"
                          name="rReferralCommision"
                          value={this.state.rReferralCommision}
                          isDecimal={true}
                          onChange={this.handleNumericChange}
                        />
                        <div className="input-group-btn">
                          <button
                            type="button"
                            style={{ width: 80, paddingLeft: 4 }}
                            className="btn btn-default dropdown-toggle form-button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {this.state.rReferralCommisionUnit == 0 ? (
                              <span style={{ marginRight: 45 }}>%</span>
                            ) : (
                              <span style={{ marginRight: 10 }}>amount</span>
                            )}
                            <span className="caret"></span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-right">
                            <li onClick={() => this.handleReferralCommisionUnitClick(0)}>
                              <a>%</a>
                            </li>
                            <li onClick={() => this.handleReferralCommisionUnitClick(1)}>
                              <a>amount</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Notes</label>
                      <textarea rows={2} className="form-control" id="rNote" name="rNote" value={this.state.rNote} onChange={this.handleChange} />
                    </div>
                    {this.state.rReferralID ? (
                      <div className="form-group">
                        <label>Status</label>
                        <select
                          id="rStatus"
                          name="rStatus"
                          style={{ marginRight: 10, maxWidth: 150 }}
                          className="form-control"
                          value={this.state.rStatus}
                          onChange={this.handleChange}
                        >
                          <option value="0">New</option>
                          <option value="1">Active</option>
                          <option value="2">Closed(failure)</option>
                          <option value="3">Closed(rejected)</option>
                          <option value="4">Closed(success)</option>
                        </select>
                      </div>
                    ) : (
                      <div className="form-group">
                        <label>Status</label>
                        <input type="text" className="form-control" value="New" readOnly="true" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <OMSButtonStyleL
                  type="button"
                  ui_icon="fa-check"
                  ui_type="save"
                  ui_text={this.state.rReferralID == "" ? "Send" : "Save"}
                  ui_processing_text="Saving.."
                  processing={this.state.rProcessing}
                  disabled={this.state.rProcessing}
                  onClick={this.doSaveReferral}
                />
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
