import React from 'react';

const PanelHeading: React.FC = () => {
    return (
        <div className="panel-heading">
        <div className="row">
          <div className="col-xs-12">
            <div className="form-inline form-group boxLeftMenu">
                <br />
                
            </div>
          </div>
        </div>
      </div>
    );
};

export default PanelHeading;