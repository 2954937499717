import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

export class OMSDropdown extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    valueField: PropTypes.string,
    labelField: PropTypes.string,
    insertSelect: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    value: "",

    valueField: "value",
    labelField: "label",
    onChange: null,
    insertSelect: false,
    disabled: false,
  };

  constructor(props, context) {
    super(props, context);
    var selected = this.getSelectedFromProps(props);

    this.state = {
      selected: selected,
    };
  }

  componentWillReceiveProps(nextProps) {
    var selected = this.getSelectedFromProps(nextProps);
    this.setState({
      selected: selected,
    });
  }

  getSelectedFromProps = (props) => {
    var selected;
    if (props.value === null && props.options.length !== 0) {
      selected = props.options[0][props.valueField];
    } else {
      selected = props.value;
    }
    return selected;
  };

  render() {
    //console.log("dropdown selected",this.state.selected);
    var self = this;
    var options = [];
    if (this.props.insertSelect) {
      options.push(
        <option key="--Select--" value="">
          --Select--
        </option>
      );
    }
    if (this.props.valueField == "") {
      options = options.concat(
        self.props.options.map(function (option) {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })
      );
    } else {
      options = options.concat(
        self.props.options.map(function (option) {
          return (
            <option key={option[self.props.valueField]} value={option[self.props.valueField]}>
              {option[self.props.labelField]}
            </option>
          );
        })
      );
    }
    return (
      <select
        id={this.props.id}
        name={this.props.id}
        style={this.props.style ? this.props.style : null}
        className={this.props.className ? this.props.className : "form-control"}
        value={this.state.selected}
        onChange={this.handleChange}
        disabled={this.props.disabled}
      >
        {options}
      </select>
    );
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      var change = {
        oldValue: this.state.selected,
        newValue: e.target.value,
        newText: e.target.options[e.target.selectedIndex].text,
        id: this.props.id,
        name: this.props.id,
      };
      this.props.onChange(change);
    }
    this.setState({ selected: e.target.value });
  };
}

export class EventTypeAutocomplete extends React.Component {
  static propTypes = {
    options: PropTypes.array,
    selectedValue: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const selectedValue = props.selectedValue;

    this.state = {
      inputValue: "",
      showDropdown: false,
      filteredTags: props.options,
    };
  }

  componentWillReceiveProps(nextProps) {
    var selected = nextProps.selectedValue;
    this.setState({
      ...this.state,
      inputValue: selected,
    });
  }

  selectTag = (tag) => {
    if (this.props.onChange) {
      var change = {
        oldValue: this.state.selectedValue,
        newValue: tag.name,
        newText: tag.name,
        id: this.props.id,
        name: this.props.id,
      };
      this.props.onChange(change);
    }
    this.setState({ ...this.state, inputValue: tag.name, showDropdown: false });
  };

  unselectTag = () => {
    this.setState({ ...this.state, inputValue: "" });
  };

  render() {
    return (
      <div className="filter-root" style={{ padding: 0 }}>
        <div className="filter-wrapper" style={{ border: "1px solid black" }}>
          <input
            type="text"
            className="filter-input"
            placeholder="Select event..."
            value={this.state.inputValue}
            onChange={(e) => {
              const filteredTags = e.target.value
                ? this.props.options.filter((tag) => tag.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1)
                : this.props.options;
              this.setState({
                ...this.state,
                inputValue: e.target.value,
                filteredTags,
              });
            }}
            onFocus={() => this.setState({ ...this.state, showDropdown: true })}
            onBlur={() => this.setState({ ...this.state, showDropdown: false })}
          />
          {this.state.inputValue && (
            <div onClick={() => this.unselectTag()} style={{ cursor: "pointer", marginRight: 10 }}>
              &#10005;
            </div>
          )}
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.setState({
                ...this.state,
                showDropdown: !this.state.showDropdown,
                filteredTags: this.props.options,
              })
            }
          >
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </div>
        </div>
        <div className="filter-dropdown" style={{ display: this.state.showDropdown ? "block" : "none" }}>
          <ul className="filter-dd-menu">
            {this.state.filteredTags
              .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0))
              .map((tag, index) => (
                <li onMouseDown={() => this.selectTag(tag)} key={`${tag.name}|${index}`}>
                  {tag.name}
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}
