import React from "react";
import bootbox from "bootbox";
import moment from "moment";
import apiAgent from "../../app/api/apiAgent";
import { toast } from "react-toastify";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { OMSNumericBox } from "../../app/common/common-components/OMSComponent";
import { OMSMultiselectDD } from "../../app/common/common-components/OMSMultiselectTagDropdown";
import { OMSTwitterAutocomplete2 } from "../../app/common/common-components/OMSAutocomplete";
import ConfirmationModalPopup from "../../app/common/common-components/ModalPopups/ConfirmationModalPopup";

export class RequirementComponent extends React.Component {
  getPropertyTypeMultiDD = () => {
    //console.log("PropertyTypes", this.props.propertyTypes);
    var propertyTypeDD = [];
    if (this.props.propertyTypes) {
      for (var i = 0; i < this.props.propertyTypes.length; i++) {
        var classification = this.props.propertyTypes[i].classification;
        propertyTypeDD.push({
          label: classification,
          values: this.props.propertyTypes[i]?.types?.map(function (item) {
            return { label: item, value: classification + "-" + item };
          }),
        });
      }
    }
    //console.log("propertyTypeDD", propertyTypeDD);
    return propertyTypeDD;
  };

  componentDidMount() {
    if (this.props.contactID != "") {
      this.refreshRequirementSave();
    }
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    // console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNumericChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleUnitChange = (name, value) => {
    this.setState({ [name]: value });
  };

  refreshRequirementSave = () => {
    //console.log("inside refreshRequirementSave", this.props.contactID);
    this.setState({ processing: true });
    /* var xhr = new XMLHttpRequest();
    xhr.open('get', '/Contacts/RequirementsAjax?contactID=' + this.props.contactID, true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      this.setState({ Requirements: data, processing: false });
    }.bind(this);
    xhr.send(); */
    apiAgent.Contact.requirementsAjax(this.props.contactID)
      .then((response) => {
        //console.log("after result from refreshRequirementSave", response);
        this.setState({ Requirements: response, processing: false });
      })
      .catch((error) => toast.info("Fail"));
  };

  getFormData = (model) => {
    var data = new FormData();

    data.append("contactID", model.contactID);
    data.append("requirementID", model.requirementID);
    data.append("note", model.note);
    data.append("saleType", model.saleType);
    data.append("priceMin", model.priceMin);
    data.append("priceMax", model.priceMax);
    data.append("landSizeMin", model.landSizeMin);
    data.append("floorSizeMin", model.floorSizeMin);
    data.append("beds", model.beds);
    data.append("baths", model.baths);
    data.append("parkingBays", model.parkingBays);
    data.append("garages", model.garages);
    data.append("landSizeUnit", model.landSizeUnit);
    data.append("floorSizeUnit", model.floorSizeUnit);
    data.append("officeID", model.officeID);
    data.append("strIsDeleted", model.isDeleted);

    // data.append("propertyTypes", model.propertyTypes);
    // data.append("selectedAreas", model.selectedAreas);

    // if(model.propertyTypes&&model.propertyTypes.length>0){
    // data.append("propertyTypes", model.propertyTypes.split(","));
    // }
    // if(model.selectedAreas&&model.selectedAreas.length>0){
    // data.append("selectedAreas", model.selectedAreas.split(","));
    // }

    let propertyTypes = [];
    let selectedAreas = [];

    for (var j = 0; j < model.propertyTypes.length; j++) {
      //data.append("propertyTypes[" + j + "]", model.propertyTypes[j]);
      propertyTypes.push(model.propertyTypes[j].value);
    }
    for (var j = 0; j < model.selectedAreas.length; j++) {
      //data.append("selectedAreas[" + j + "]", model.selectedAreas[j]);
      selectedAreas.push(model.selectedAreas[j].value);
    }
    data.append("propertyTypes", propertyTypes);
    data.append("selectedAreas", selectedAreas);

    return data;
  };

  openRequirement = (data) => {
    //console.log("inside openRequirement", data);
    if (data == null) {
      this.setState({
        contactID: this.props.contactID,
        Note: moment(new Date()).format("DD MMM YYYY"),
        RequirementID: "",
        OfficeID: this.props.officeID,
        SaleType: "",
        PropertyTypes: [],
        FloorSizeMin: "",
        LandSizeMin: "",
        FloorSizeUnit: 1,
        LandSizeUnit: 1,
        SelectedAreas: [],
        Baths: "",
        Beds: "",
        Garages: "",
        ParkingBays: "",
        PriceMax: "",
        PriceMin: "",
        IsDeleted: false,
      });
    } else {
      this.setState({
        contactID: data.contactID,
        RequirementID: data.requirementID,
        OfficeID: data.officeID,
        Note: data.note,
        SaleType: data.saleType,
        PropertyTypes: data.propertyTypes,
        LandSizeMin: data.landSizeMin,
        FloorSizeUnit: data.floorSizeUnit,
        LandSizeUnit: data.landSizeUnit,
        SelectedAreas: data.selectedAreas,
        Baths: data.baths,
        Beds: data.beds,
        FloorSizeMin: data.floorSizeMin,
        Garages: data.garages,
        ParkingBays: data.parkingBays,
        PriceMax: data.priceMax,
        PriceMin: data.priceMin,
        IsDeleted: data.isDeleted,
      });
    }
    //console.log("just before opening modal");
    window.$("#requirementModel").modal("show");
  };

  updateRequirement = (ID) => {
    //console.log("inside updateRequirement", this.state.Requirements);
    var index = -1;
    for (var i = 0; i < this.state.Requirements.length; i++) {
      if (this.state.Requirements[i].requirementID == ID) index = i;
    }
    //console.log("index", index);
    if (index != -1) {
      this.openRequirement(this.state.Requirements[index]);
    }
  };

  onPropertyTypesChange = (selected) => {
    this.setState({
      PropertyTypes: selected,
    });
  };

  areaSelectionChanged = (e) => {
    var selectedArea = e.suburb == "" ? e.town : e.town + ", " + e.suburb;
    if (this.state.SelectedAreas.indexOf(selectedArea) == -1) {
      var mSelectedAreas = this.state.SelectedAreas;
      mSelectedAreas.push(selectedArea);
      this.setState({ SelectedAreas: mSelectedAreas });
    }
  };

  areaSelectionRemoved = (e) => {
    var index = this.state.SelectedAreas.indexOf(e);
    if (index > -1) {
      var mSelectedAreas = this.state.SelectedAreas;
      mSelectedAreas.splice(index, 1);
      this.setState({ SelectedAreas: mSelectedAreas });
    }
  };

  executeDeleteRequirement = () => {
    //console.log("inside executeDeleteRequirement", this.state.DeleteRequirementID);

    var index = -1;
    for (var i = 0; i < this.state.Requirements.length; i++) {
      if (this.state.Requirements[i].requirementID == this.state.DeleteRequirementID) index = i;
    }
    //console.log("index", index);
    if (index != -1) {
      var model = this.state.Requirements[index];

      //console.log("inside executeDeleteRequirement", model);
      model.isDeleted = true;
      var data = this.getFormData(model);
      const formDataObj = Object.fromEntries(data.entries());
      formDataObj.propertyTypes = model.propertyTypes;
      formDataObj.selectedAreas = model.selectedAreas;
      
      //console.log("before saving requirement", formDataObj);

      this.setState({ processing: true });

      apiAgent.Contact.saveRequirement(formDataObj,this.props.userID)
        .then((response) => {
          this.setState({ processing: false });
          this.refreshRequirementSave();
        })
        .catch((error) => toast.info("Fail"))
        .finally(() => {
          this.setState({ processing: false });
        });
    }
  };

  deleteRequirement = async (ID) => {
    //console.log("inside deleteRequirement before setting state", ID);
    await this.setState({ DeleteRequirementID: ID });
    //console.log("inside deleteRequirement after setting state", this.state.DeleteRequirementID);
    let title = "Delete requirement?";
    let body = "Do you want to delete requirement?";
    let callback = this.executeDeleteRequirement;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  handleViewMatchListing = (e) => {
    if (this.state.Requirements.length == 0) {
      toast.info("No requirement to match with listings.");
    }

    this.setState({ match_processing: true, Matches: [] });

    apiAgent.Contact.requirementsMatchAjax(this.props.contactID)
      .then((response) => {
        this.setState({ Matches: response, match_processing: false });
        if (response.length == 0) {
          toast.info("No match found.", "Info");
        }
      })
      .catch((error) => toast.info("Fail"));
  };

  getRequirementText = (item) => {
    var infoText = "";
    if (item.saleType == 1) infoText = "For sale";
    else if (item.saleType == 2) infoText = "For rent";
    else if (item.saleType == 3) infoText = "Auction";
    else infoText = "Valuation";

    if (item.priceMin && item.priceMax) {
      infoText = infoText + " | " + item.priceMin + " to " + item.priceMax;
    } else if (item.priceMin) {
      infoText = infoText + " | min " + item.priceMin;
    } else if (item.priceMax) {
      infoText = infoText + " | max " + item.priceMax;
    }

    if (item.beds) infoText = infoText + " | " + item.beds + "+ Beds";

    if (item.baths) infoText = infoText + " | " + item.baths + "+ Baths";

    if (item.garages) infoText = infoText + " | " + item.garages + "+ Garages";

    if (item.parkingBays) infoText = infoText + " | " + item.parkingBays + "+ Parking bays";

    if (item.floorSizeMin)
      infoText = infoText + " | " + item.floorSizeMin + (item.floorSizeUnit == 1 ? "m²" : item.floorSizeUnit == 2 ? "ft²" : "ht") + "+ Floor size";

    if (item.landSizeMin)
      infoText = infoText + " | " + item.landSizeMin + (item.landSizeUnit == 1 ? "m²" : item.landSizeUnit == 2 ? "ft²" : "ht") + "+ Land size";

    /*var PropertyTypeString = "";
    for (var i = 0; i < item.propertyTypes.length; i++) {
      if (i == 0)
        PropertyTypeString = item.propertyTypes[i];
      else
        PropertyTypeString = PropertyTypeString + ", " + item.propertyTypes[i];
    }
   
    if (PropertyTypeString)
      infoText = infoText + " | " + PropertyTypeString; */

    var SelectedAreasString = "";
    if (item.selectedAreas && item.selectedAreas.length > 0) {
      for (var i = 0; i < item.selectedAreas.length; i++) {
        if (i == 0) SelectedAreasString = item.selectedAreas[i];
        else SelectedAreasString = SelectedAreasString + "; " + item.selectedAreas[i];
      }
    }

    if (SelectedAreasString) infoText = infoText + " | " + SelectedAreasString;

    return infoText;
  };

  onPropertyItemClick = (propertyID) => {
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = "/Property/SaveProperty?ID=" + propertyID;
    a.click();
  };

  doSave = (e) => {
    if (!this.state.SaleType) {
      toast.info("Please select sale type");
      return;
    }

    if (this.state.PropertyTypes.length == 0) {
      toast.info("Please select property type");
      return;
    }

    if (!this.state.Note) {
      toast.info("Please add note");
      return;
    }

    var model = {
      contactID: this.state.contactID,
      note: this.state.Note,
      requirementID: this.state.RequirementID,
      officeID: this.state.OfficeID,
      saleType: this.state.SaleType,
      propertyTypes: this.state.PropertyTypes,
      floorSizeMin: this.state.FloorSizeMin,
      landSizeMin: this.state.LandSizeMin,
      floorSizeUnit: this.state.FloorSizeUnit,
      landSizeUnit: this.state.LandSizeUnit,
      selectedAreas: this.state.SelectedAreas,
      baths: this.state.Baths,
      beds: this.state.Beds,
      garages: this.state.Garages,
      parkingBays: this.state.ParkingBays,
      priceMax: this.state.PriceMax,
      priceMin: this.state.PriceMin,
      isDeleted: this.state.IsDeleted,
      //strIsDeleted: this.state.IsDeleted ? "true" : "false",
    };

    //console.log("before converting to formdata", model);

    var data = this.getFormData(model);

    this.setState({ processing: true });

    const formDataObj = Object.fromEntries(data.entries());
    formDataObj.propertyTypes = model.propertyTypes;
    formDataObj.selectedAreas = model.selectedAreas;
    //console.log("before saving requirement", formDataObj);
    apiAgent.Contact.saveRequirement(formDataObj,this.props.userID)
      .then((response) => {
        window.$("#requirementModel").modal("hide");
        //console.log("after getting result from saveRequirement", response);
        this.setState({ processing: false });
        this.refreshRequirementSave();
      })
      .catch((error) => toast.info("Fail"))
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  state = {
    contactID: this.props.contactID,
    RequirementID: "",
    OfficeID: this.props.officeID,
    Note: "",
    SaleType: "",
    PropertyTypes: [],
    FloorSizeMin: "",
    LandSizeMin: "",
    FloorSizeUnit: 1,
    LandSizeUnit: 1,
    SelectedAreas: [],
    Baths: "",
    Beds: "",
    Garages: "",
    ParkingBays: "",
    PriceMax: "",
    PriceMin: "",
    IsDeleted: false,
    PropertyTypesMultiDD: this.getPropertyTypeMultiDD(),
    Requirements: [],
    processing: false,
    Matches: [],
    match_processing: false,
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
    DeleteRequirementID: "",
  };

  render() {
    var self = this;
    var CurrencyDisplay = this.props.currencyDisplay;
    return (
      <div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ConfirmationModalPopup {...this.state.confirmationModalProps} />
            <div className="form-inline form-group">
              <label htmlFor="Qualify">Qualify</label>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="pull-right">
              <div className="form-inline form-group">
                <OMSButtonStyleL
                  type="button"
                  ui_icon=""
                  style={{ marginLeft: 10, marginTop: 5 }}
                  ui_type="create"
                  ui_text="view listing matches"
                  ui_processing_text="matching..."
                  processing={this.state.match_processing}
                  disabled={this.state.match_processing}
                  onClick={this.handleViewMatchListing}
                />
                {this.props.actions.indexOf("save") < 0 ? null : (
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    style={{ marginLeft: 10, marginTop: 5 }}
                    ui_type="create"
                    ui_text="add requirement"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={() => this.openRequirement(null)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 8 }}>
            {this.state.Requirements.length == 0 ? (
              <div className="form-group">
                <span>no requirement records</span>
              </div>
            ) : (
              this.state.Requirements.map(function (item, index) {
                var NoteString = item.note.substring(0, 45);
                if (item.note.length > 45) NoteString = NoteString + "..";
                return (
                  <div key={index} className="form-group">
                    <div className="ellipsis-container">
                      <div className="ellipsis-div">
                        <span
                          style={{ marginRight: 10, cursor: "pointer" }}
                          className={self.state.processing ? "fa fa-spin fa-spinner" : "fa fa-trash"}
                          onClick={() => self.deleteRequirement(item.requirementID)}
                        ></span>
                        <a style={{ cursor: "pointer" }} onClick={() => self.updateRequirement(item.requirementID)}>
                          <b>{NoteString}: </b>filter: {self.getRequirementText(item)}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 20 }}>
            {this.state.Matches.length == 0 ? (
              <div className="form-group">
                <label></label>
              </div>
            ) : (
              this.state.Matches.map(function (data, index) {
                return (
                  <div key={data.propertyID} className="col-md-12">
                    <div className="media media-listing hidden-sm hidden-xs" style={{ border: "none", margin: "10px 20px 10px 5px" }}>
                      <div className="media-left" style={{ backgroundColor: "transparent", padding: 0 }}>
                        <div
                          className="search-img"
                          onClick={() => self.onPropertyItemClick(data.propertyID)}
                          style={{
                            cursor: "pointer",
                            backgroundImage: "url(" + (data.photoUrl == "" ? "/Assets/images/img-upload-default.png" : data.photoUrl) + ")",
                          }}
                        >
                          <span className="price color2" style={{ width: "auto" }}>
                            {CurrencyDisplay} {data.price}
                          </span>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="media-heading">
                          <div className="ellipsis-container">
                            <div className="ellipsis-div">
                              <h4 style={{ cursor: "pointer", margin: 0 }} onClick={() => self.onPropertyItemClick(data.propertyID)}>
                                {data.title}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="listing-info">
                          <div className="ellipsis-container">
                            <div className="ellipsis-div">
                              <b>{data.headline}</b>
                            </div>
                          </div>
                          <div className="ellipsis-container" style={{ marginTop: 5 }}>
                            <div className="ellipsis-div">{data.description}</div>
                          </div>
                        </div>
                        <div className="icon-info">
                          {data.beds && data.beds != "-" ? (
                            <span className="icon-span">
                              <i className="fa fa-bed"></i> {data.beds} beds
                            </span>
                          ) : null}
                          {data.baths && data.Baths != "-" ? (
                            <span className="icon-span">
                              <i className="fa fa-bath"></i> {data.baths} baths
                            </span>
                          ) : null}
                          {data.buildingSize && data.buildingSize != "-" ? (
                            <span className="icon-span">
                              <i className="fa fa-bank"></i> {data.buildingSize}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="media media-listing hidden-md hidden-lg"
                      style={{
                        height: "auto",
                        maxHeight: 500,
                        margin: 5,
                        border: "none",
                      }}
                    >
                      <div className="media-middle">
                        <div
                          className="search-img"
                          onClick={() => self.onPropertyItemClick(data.propertyID)}
                          style={{
                            cursor: "pointer",
                            minWidth: 200,
                            backgroundImage: "url(" + (data.photoUrl == "" ? "/Assets/images/img-upload-default.png" : data.photoUrl) + ")",
                          }}
                        >
                          <span className="price color2" style={{ width: "auto" }}>
                            {CurrencyDisplay} {data.price}
                          </span>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="media-heading">
                          <div className="ellipsis-container">
                            <div className="ellipsis-div">
                              <span
                                style={{
                                  cursor: "pointer",
                                  margin: 0,
                                  fontSize: 18,
                                  fontWeight: 500,
                                }}
                                onClick={() => self.onPropertyItemClick(data.propertyID)}
                              >
                                {data.title}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="listing-info">
                          <div className="ellipsis-container">
                            <div className="ellipsis-div">
                              <b>{data.headline}</b>
                            </div>
                          </div>
                          <div className="ellipsis-container" style={{ marginTop: 5 }}>
                            <div className="ellipsis-div">{data.description}</div>
                          </div>
                        </div>
                        <div className="icon-info">
                          {data.beds && data.beds != "-" ? (
                            <span className="icon-span">
                              <i className="fa fa-bed"></i> {data.beds}{" "}
                            </span>
                          ) : null}
                          {data.baths && data.baths != "-" ? (
                            <span className="icon-span">
                              <i className="fa fa-bath"></i> {data.baths}{" "}
                            </span>
                          ) : null}
                          {data.buildingSize && data.buildingSize != "-" ? (
                            <span className="icon-span">
                              <i className="fa fa-bank"></i> {data.buildingSize}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="modal fade" id="requirementModel" tabIndex={-1} aria-labelledby="requirementModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="requirementModelLabel">
                  Requirement : {this.state.Created}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Property</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>Sale type</small>
                    </label>
                    <select
                      id="SaleType"
                      name="SaleType"
                      style={{ marginRight: 10, maxWidth: 150 }}
                      className="form-control"
                      value={this.state.SaleType}
                      onChange={this.handleChange}
                    >
                      <option value="">Sale Type</option>
                      <option value="1">For sale</option>
                      <option value="2">For rent</option>
                      {/*<option value="3">Auction</option>*/}
                      <option value="4">Valuation</option>
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>Property types</small>
                    </label>
                    <OMSMultiselectDD
                      id="PropertyTypes"
                      style={{ maxWidth: 150 }}
                      value={this.state.PropertyTypes}
                      data={this.state.PropertyTypesMultiDD}
                      onChange={this.onPropertyTypesChange}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>Floor size min</small>
                    </label>
                    <div className="input-group">
                      <OMSNumericBox
                        id="FloorSizeMin"
                        separator=""
                        placeholder="Floor size min"
                        name="FloorSizeMin"
                        maxLength={10}
                        style={{ maxWidth: 150 }}
                        value={this.state.FloorSizeMin}
                        isDecimal={true}
                        onChange={this.handleNumericChange}
                      />
                      <div className="input-group-btn">
                        <button
                          type="button"
                          style={{ width: 35, paddingLeft: 4 }}
                          className="btn btn-default dropdown-toggle form-button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {this.state.FloorSizeUnit == 1 ? "m²" : this.state.FloorSizeUnit == 2 ? "ft²" : "ht"} <span className="caret"></span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li onClick={() => this.handleUnitChange("FloorSizeUnit", 1)}>
                            <a>m²</a>
                          </li>
                          <li onClick={() => this.handleUnitChange("FloorSizeUnit", 2)}>
                            <a>ft²</a>
                          </li>
                          <li onClick={() => this.handleUnitChange("FloorSizeUnit", 3)}>
                            <a>hectares</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>Land size min</small>
                    </label>
                    <div className="input-group">
                      <OMSNumericBox
                        id="LandSizeMin"
                        separator=""
                        placeholder="Land size min"
                        name="LandSizeMin"
                        maxLength={10}
                        style={{ maxWidth: 150 }}
                        value={this.state.LandSizeMin}
                        isDecimal={true}
                        onChange={this.handleNumericChange}
                      />
                      <div className="input-group-btn">
                        <button
                          type="button"
                          style={{ width: 35, paddingLeft: 4 }}
                          className="btn btn-default dropdown-toggle form-button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {this.state.LandSizeUnit == 1 ? "m²" : this.state.LandSizeUnit == 2 ? "ft²" : "ht"} <span className="caret"></span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li onClick={() => this.handleUnitChange("LandSizeUnit", 1)}>
                            <a>m²</a>
                          </li>
                          <li onClick={() => this.handleUnitChange("LandSizeUnit", 2)}>
                            <a>ft²</a>
                          </li>
                          <li onClick={() => this.handleUnitChange("LandSizeUnit", 3)}>
                            <a>hectares</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>Bedrooms min</small>
                    </label>
                    <OMSNumericBox
                      id="Beds"
                      separator=""
                      placeholder="Beds"
                      name="Beds"
                      maxLength={10}
                      style={{ maxWidth: 150 }}
                      value={this.state.Beds}
                      isDecimal={true}
                      onChange={this.handleNumericChange}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>Bathrooms min</small>
                    </label>
                    <OMSNumericBox
                      id="Baths"
                      separator=""
                      placeholder="Baths"
                      name="Baths"
                      maxLength={10}
                      style={{ maxWidth: 150 }}
                      value={this.state.Baths}
                      isDecimal={true}
                      onChange={this.handleNumericChange}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>Garages min</small>
                    </label>
                    <OMSNumericBox
                      id="Garages"
                      separator=""
                      placeholder="Garages"
                      name="Garages"
                      maxLength={10}
                      style={{ maxWidth: 150 }}
                      value={this.state.Garages}
                      isDecimal={true}
                      onChange={this.handleNumericChange}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>Parking bays min</small>
                    </label>
                    <OMSNumericBox
                      id="ParkingBays"
                      separator=""
                      placeholder="Parking bays"
                      name="ParkingBays"
                      maxLength={10}
                      style={{ maxWidth: 150 }}
                      value={this.state.ParkingBays}
                      isDecimal={true}
                      onChange={this.handleNumericChange}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <label>Price range</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>From</small>
                    </label>
                    <OMSNumericBox
                      id="PriceMin"
                      separator=","
                      placeholder="Price from"
                      name="PriceMin"
                      maxLength={10}
                      style={{ maxWidth: 120 }}
                      value={this.state.PriceMin}
                      isDecimal={true}
                      onChange={this.handleNumericChange}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <label>
                      <small style={{ color: "grey" }}>To</small>
                    </label>
                    <OMSNumericBox
                      id="PriceMax"
                      separator=","
                      placeholder="Price to"
                      name="PriceMax"
                      maxLength={10}
                      style={{ maxWidth: 120 }}
                      value={this.state.PriceMax}
                      isDecimal={true}
                      onChange={this.handleNumericChange}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <label>Areas</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <OMSTwitterAutocomplete2 id="SelectedAreas" name="SelectedAreas" value="" onSelect={this.areaSelectionChanged} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {this.state.SelectedAreas.length == 0 ? null : (
                      <div className="search-bar" style={{ marginTop: 10 }}>
                        <div className="search-bar__container">
                          <div className="search-bar__autocomplete">
                            <div className="search-bar__autocomplete-input">
                              {this.state.SelectedAreas.map(function (e, index) {
                                return (
                                  <div key={index} className="search-bar__autocomplete-selected">
                                    {e}{" "}
                                    <i
                                      onClick={() => self.areaSelectionRemoved(e)}
                                      style={{
                                        fontSize: 18,
                                        cursor: "pointer",
                                        margin: 2,
                                        marginRight: 10,
                                      }}
                                      className="fa fa-trash"
                                    ></i>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <label>Note</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <textarea rows={2} className="form-control" id="Note" name="Note" value={this.state.Note} onChange={this.handleChange} />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <OMSButtonStyleL
                  type="button"
                  ui_icon="fa-check"
                  ui_type="save"
                  ui_text="Save"
                  ui_processing_text="Saving.."
                  processing={this.state.processing}
                  disabled={this.state.processing}
                  onClick={this.doSave}
                />
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
