import React from "react";
import { toast } from "react-toastify";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { DataTableCompDynamic } from "../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../app/api/apiAgent";
import { withRouter } from 'react-router-dom';

class SyncIssuesBox extends React.Component {
  state = {
    OfficeID: "",
    Status: "1",
    loading: false,
    ExtraParams: JSON.stringify({ OfficeID: "", Status: "1" }),
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDropdownChange = (e) => {
    this.setState({ [e.name]: e.newValue });
  };

  onCancelClick = (e) => {
    this.props.history.push('/Settings');
  };

  handleLoadSyncIssuesClick = (e) => {
    if (!this.state.OfficeID) {
      toast.info("Please select office");
      return false;
    }

    if (!this.state.Status) {
      toast.info("Please select mandate status");
      return false;
    }

    this.setState({
      ExtraParams: JSON.stringify({
        OfficeID: this.state.OfficeID,
        Status: this.state.Status,
      }),
    });
  };

  loadGrid = (requestParams) => {
    return apiAgent.Admin.syncIssuesAjax(requestParams,this.state.OfficeID, this.state.Status);
  };

  render() {
    function propertyidwebref_format(value) {
      var jValue = value.split(",");
      return (
        "<a href='/Property/SaveProperty?ID=" +
        jValue[1] +
        "' target='_blank'><i class='fa fa-exclamation-triangle' style='color: red'></i>&nbsp;" +
        jValue[0] +
        "</a>"
      );
    }

    const columns = [
      { data: "propertyIDWebRef", title: "WebRef" },
      { data: "portal", title: "SyncPortal" },
      { data: "syncError", title: "SyncError" },
      { data: "syncUpdate", title: "SyncUpdate" },
      { data: "propertyType", title: "PropertyType" },
      { data: "status", title: "Status" },
      { data: "price", title: "Price" },
      { data: "address", title: "Address" },
    ];

    const columnDefs = [
      {
        render: function (data, type, row) {
          return propertyidwebref_format(data);
        },
        targets: 0,
      },
    ];

    return (
      <div className="col-sm-12">
        <div className="panel panel-default oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-12">
                <label>Properties - Sync Issues</label>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="OfficeID">Office</label>
                  <OMSDropdown
                    id="OfficeID"
                    name="OfficeID"
                    options={this.props.OfficeDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.OfficeID}
                    onChange={this.handleDropdownChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="Status">Mandate Status</label>
                  <select id="Status" name="Status" className="form-control" value={this.state.Status} onChange={this.handleChange}>
                    <option value="1">Active</option>
                    <option value="2">Expired</option>
                    <option value="3">Withdrawn</option>
                    <option value="4">Rented</option>
                    <option value="5">Sold - Under Offer</option>
                    <option value="6">Sold - Registered</option>
                    <option value="7">Sold - Cancelled</option>
                    <option value="8">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCancelClick}
                  />
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Get Properties with Sync Issues"
                    ui_processing_text="Processing.."
                    processing={this.state.loading}
                    disabled={this.state.loading}
                    onClick={this.handleLoadSyncIssuesClick}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ marginTop: 35 }}>
                <div className="table-responsive">
                  <DataTableCompDynamic
                    id="SyncIssuesGrid"
                    //Url="/Admin/SyncIssuesAjax"
                    columns={columns}
                    columnDefs={columnDefs}
                    makeAjaxRequest={this.loadGrid}
                    ExtraParams={this.state.ExtraParams}
                    serverSide = {false}
                  />


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SyncIssuesBox);
