import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import Header from "../header/Header";
//import "../../assets/styles/components/_saveUser.scss";
import { useStore } from "../../app/stores/store";
import HeaderWrapper from "../header/HeaderWrapper";
import { PropertyModel } from "../../app/models/PropertyModel";
import ListingBox from "./ListingBox";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";

//import "../../assets/styles/components/_saveProperty.scss";

export default observer(function SavePropertyPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [property, setProperty] = useState<PropertyModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let propertyId: string = "";
    if (params.has("ID")) {
      //const oContact:ContactModel=new ContactModel();

      const id = params.get("ID");
      propertyId = id !== null ? id : "";

      //console.log("Inside useEffect:contact id is",contactId);
    }

    setIsLoading(true);

    //console.log("inside save Property page useEffect", propertyId);
    apiAgent.Property.saveProperty(propertyId)
      .then((response: PropertyModel) => {
        let oProperty: PropertyModel = response;
        setProperty(oProperty);

        console.log("inside save property page", oProperty);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save Listing";

  if (isLoading) {
    return (
      <AjaxLoader />
    );
  }
  else if (!isLoading) {
    return (
      <>
        <HeaderWrapper />

        <div className="save-property-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Listings", path: "/Property" },
              { text: !property?.propertyID ? "Create" : property?.displayName }
            ]}
          />
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <ListingBox
                data={property}
                favourite={property?.favourite}
                OfficeContactTags={property?.officeContactTags}
                UserContactTags={property?.userContactTags}
                OwnershipTypes={property?.ownershipTypes}
                PropertyTypes={property?.propertyTypes}
                MandateTypes={property?.mandateTypes}
                AgentTypes={property?.agentTypes}
                DiaryActionTags={property?.diaryActionTags}
                actions={property?.actions}
                IsSuperAdmin={property?.isSuperAdmin}
                OnShowStart={property?.onShowStart}
                OnShowEnd={property?.onShowEnd}
                CurrencyDisplay={property?.currencyDisplay}
                ContactSourceTypes={property?.contactSourceTypes}
                DialingCodeDD={property?.dialingCodeDD}
                DialingCode={property?.dialingCode}
                Brochures={property?.brochures}
                AgentDD={property?.agentDD}
                SelectedAgentID={property?.selectedAgentID}
                ListingSellerRequired={property?.listingSellerRequired}
                ExtraDescriptionFieldsDD={property?.extraDescriptionFieldsDD}
                CountryDD={property?.countryDD}
                ExtraDescriptionTagFields={property?.extraDescriptionTagFields}
                ShowChart={property?.showChart}
                GeneralListingTags={property?.generalListingTags}
                userSession={userSession}
                ListingTags={property?.listOfListingTags}
              />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
