import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import apiAgent from "../../app/api/apiAgent";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSNumericBox } from "../../app/common/common-components/OMSComponent";
import { DataTableLoading } from "../../app/common/common-components/OMSDatatables";
import { DataTableComp } from "../../app/common/common-components/OMSDatatables";
import TelerikReportViewerComponent from "../../app/common/common-components/TelerikReportViewerComponent";
import DatePicker from "react-datepicker";
//import { TelerikReportViewer } from '@progress/telerik-react-report-viewer/dist/cjs/main';

export class ReportGrid extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
  }
  static propTypes = {
    ID: PropTypes.string.isRequired,
    Category: PropTypes.string.isRequired,
    MandateTypesRptDD: PropTypes.array,
    PropertyTypesRptDD: PropTypes.array,
    AgentsRptDD: PropTypes.array,
  };
  static defaultProps = {
    MandateTypesRptDD: [],
    PropertyTypesRptDD: [],
    AgentsRptDD: [],
  };
  state = {
    data: [],
    showingReport: false,
    mRptSaleType: "",
    mRptStatus: "",
    mRptMandate: "Mandate Type",
    mRptPropertyType: "Property Type",
    mRptAgent: "",
    mRptAgentName: "",
    mRptPriceFrom: "",
    mRptPriceTo: "",
    mRptMandateEndFrom: "",
    mRptMandateEndTo: "",
    mRptMandateStartFrom: "",
    mRptMandateStartTo: "",
    SaleType: "",
    Status: "1",
    Mandate: "Mandate Type",
    PropertyType: "Property Type",
    Agent: "",
    PriceFrom: "",
    PriceTo: "",
    AgentName: "",
    FilterText: "Active",
    MandateEndFrom: "",
    MandateEndTo: "",
    MandateStartFrom: "",
    MandateStartTo: "",
    hasTableShown: false,
    reportValues: {},
  };

  formatDate = (fullDateString) => {
    const date = new Date(fullDateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
  };

  getFilterText = () => {
    var filterText = "";

    if (this.props.Category != "listings") return "";

    if (this.state.SaleType != "") {
      var saleType = "";
      if (this.state.SaleType == "1") saleType = "For sale";
      else if (this.state.SaleType == "2") saleType = "For rent";
      else if (this.state.SaleType == "3") saleType = "Auction";
      else if (this.state.SaleType == "4") saleType = "Valuation";

      filterText = saleType + " | ";
    }

    if (this.state.Status != "") {
      var status = "";

      if (this.state.Status == "1") status = "Active";
      else if (this.state.Status == "2") status = "Expired";
      else if (this.state.Status == "3") status = "Withdrawn";
      else if (this.state.Status == "4") status = "Rented";
      else if (this.state.Status == "5") status = "Sold - Under Offer";
      else if (this.state.Status == "6") status = "Sold - Registered";
      else if (this.state.Status == "7") status = "Sold - Cancelled";
      else if (this.state.Status == "8") status = "Inactive";

      filterText = filterText + status + " | ";
    }

    if (this.state.Mandate != "Mandate Type") filterText = filterText + this.state.Mandate + " | ";

    if (this.state.PropertyType != "Property Type") filterText = filterText + this.state.PropertyType + " | ";

    if (this.state.Agent != "") filterText = filterText + this.state.AgentName + " | ";

    if (this.state.PriceFrom != "" && this.state.PriceFrom != "0" && this.state.PriceTo != "" && this.state.PriceTo != "0")
      filterText = filterText + this.state.PriceFrom + " to " + this.state.PriceTo + " | ";
    else if (this.state.PriceFrom != "" && this.state.PriceFrom != "0") filterText = filterText + "min: " + this.state.PriceFrom + " | ";
    else if (this.state.PriceTo != "" && this.state.PriceTo != "0") filterText = filterText + "max: " + this.state.PriceTo + " | ";

    /* if (this.state.MandateStartFrom != "" && this.state.MandateStartTo != "")
      filterText = filterText + "Mandate start between " + this.state.MandateStartFrom + " to " + this.state.MandateStartTo + " | ";
    else if (this.state.MandateStartFrom != "") filterText = filterText + "Mandate start from " + this.state.MandateStartFrom + " | ";
    else if (this.state.MandateStartTo != "") filterText = filterText + "Mandate start upto " + this.state.MandateStartTo + " | ";

    if (this.state.MandateEndFrom != "" && this.state.MandateEndTo != "")
      filterText = filterText + "Mandate expire between " + this.state.MandateEndFrom + " to " + this.state.MandateEndTo + " | ";
    else if (this.state.MandateEndFrom != "") filterText = filterText + "Mandate expire from " + this.state.MandateEndFrom + " | ";
    else if (this.state.MandateEndTo != "") filterText = filterText + "Mandate expire upto " + this.state.MandateEndTo + " | "; */

    const formattedMandateStartFrom = this.state.MandateStartFrom ? this.formatDate(this.state.MandateStartFrom) : "";
    const formattedMandateStartTo = this.state.MandateStartTo ? this.formatDate(this.state.MandateStartTo) : "";
    const formattedMandateEndFrom = this.state.MandateEndFrom ? this.formatDate(this.state.MandateEndFrom) : "";
    const formattedMandateEndTo = this.state.MandateEndTo ? this.formatDate(this.state.MandateEndTo) : "";

    if (this.state.MandateStartFrom != "" && this.state.MandateStartTo != "")
      filterText = filterText + "Mandate start between " + formattedMandateStartFrom + " to " + formattedMandateStartTo + " | ";
    else if (this.state.MandateStartFrom != "") filterText = filterText + "Mandate start from " + formattedMandateStartFrom + " | ";
    else if (this.state.MandateStartTo != "") filterText = filterText + "Mandate start upto " + formattedMandateStartTo + " | ";

    if (this.state.MandateEndFrom != "" && this.state.MandateEndTo != "")
      filterText = filterText + "Mandate expire between " + formattedMandateEndFrom + " to " + formattedMandateEndTo + " | ";
    else if (this.state.MandateEndFrom != "") filterText = filterText + "Mandate expire from " + formattedMandateEndFrom + " | ";
    else if (this.state.MandateEndTo != "") filterText = filterText + "Mandate expire upto " + formattedMandateEndTo + " | ";

    if (filterText != "") filterText = filterText.slice(0, filterText.length - 3);

    return filterText;
  };
  loadReportsFromServer = () => {
    this.setState({ loading: true, data: [] });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/ReportViewer/ReportsForControlAjax?Category=' + this.props.Category, true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            this.setState({ data: data, loading: false });
        }.bind(this);
        xhr.send(); */
    apiAgent.ReportViewer.reportsForControlAjax(this.props.Category)
      .then((response) => {
        this.setState({ data: response, loading: false });
        //console.log("Report Grid Data", response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  componentDidMount() {
    this.loadReportsFromServer();

    /* window.$('.MandateDatePicker').datetimepicker({
            format: 'DD-MM-YYYY',
            useCurrent: false,
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fa fa-arrow-left",
                next: "fa fa-arrow-right",
                today: 'fa fa-screenshot',
                clear: 'fa fa-trash',
                close: 'fa fa-remove'
            }
        }); */

    /* window.$(".MandateDatePicker").on(
      "dp.change",
      function (e) {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
        if (e.currentTarget.name == "mRptMandateStartFrom") {
          window.$("#mRptMandateStartTo").data("DateTimePicker").minDate(e.date);
        } else if (e.currentTarget.name == "mRptMandateStartTo") {
          window.$("#mRptMandateStartFrom").data("DateTimePicker").maxDate(e.date);
        } else if (e.currentTarget.name == "mRptMandateEndFrom") {
          window.$("#mRptMandateEndTo").data("DateTimePicker").minDate(e.date);
        } else if (e.currentTarget.name == "mRptMandateEndTo") {
          window.$("#mRptMandateEndFrom").data("DateTimePicker").maxDate(e.date);
        }
      }.bind(this)
    ); */
  }
  handleRowClick = (data) => {
    //console.log("inside handleRowClick", data);
    this.onOpenReportViewer(data.reportID, data.reportName);
  };
  onOpenReportViewer = (ID, ReportName) => {
    //console.log("inside onOpenReportViewer", ID, ReportName);
    this.setState({
      showingReport: true,
      reportID: ID,
      reportName: ReportName,
    });

    //var URL = '/ReportViewer/ViewReport?ID=' + ID;
    if (this.props.Category == "listings") {
      var PriceFrom = "";
      if (this.state.PriceFrom != "" && this.state.PriceFrom != "0") PriceFrom = this.state.PriceFrom;

      var PriceTo = "";
      if (this.state.PriceTo != "" && this.state.PriceTo != "0") PriceTo = this.state.PriceTo;

      var Mandate = "";
      if (this.state.Mandate != "Mandate Type") Mandate = this.state.Mandate;

      var PropertyType = "";
      if (this.state.PropertyType != "Property Type") PropertyType = this.state.PropertyType;

      /* 
            URL += '&SaleType=' + this.state.SaleType + '&Status=' + this.state.Status;
            URL = URL + '&Mandate=' + Mandate + '&PropertyType=' + PropertyType + '&Agent=' + this.state.Agent;
            URL = URL + '&PriceFrom=' + PriceFrom + '&PriceTo=' + PriceTo
            URL = URL + '&MandateStartFrom=' + this.state.MandateStartFrom + '&MandateStartTo=' + this.state.MandateStartTo
            URL = URL + '&MandateEndFrom=' + this.state.MandateEndFrom + '&MandateEndTo=' + this.state.MandateEndTo */

      const formattedMandateStartFrom = this.state.MandateStartFrom ? this.formatDate(this.state.MandateStartFrom) : "";
      const formattedMandateStartTo = this.state.MandateStartTo ? this.formatDate(this.state.MandateStartTo) : "";
      const formattedMandateEndFrom = this.state.MandateEndFrom ? this.formatDate(this.state.MandateEndFrom) : "";
      const formattedMandateEndTo = this.state.MandateEndTo ? this.formatDate(this.state.MandateEndTo) : "";

      apiAgent.ReportViewer.viewReport(
        ID,
        this.state.SaleType,
        this.state.Status,
        Mandate,
        PropertyType,
        this.state.Agent,
        PriceFrom,
        PriceTo,
        /* this.state.MandateStartFrom,
        this.state.MandateStartTo,
        this.state.MandateEndFrom,
        this.state.MandateEndTo */
        formattedMandateStartFrom,
        formattedMandateStartTo,
        formattedMandateEndFrom,
        formattedMandateEndTo
      )
        .then((response) => {
          //console.log("ReportViewer Response", response);
          this.setState({ reportValues: response });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ showingReport: true });
        });
    } else {
      apiAgent.ReportViewer.viewReport(ID)
        .then((response) => {
          //console.log("ReportViewer Response", response);
          this.setState({ reportValues: response });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ showingReport: true });
        });
    }

    /*  var xhr = new XMLHttpRequest();
        xhr.open('get', URL, true);
        xhr.onload = function () {
            window.$("#reportViewer").empty();
            window.$("#reportViewer").html(xhr.responseText);
        };
        xhr.send(); */
  };
  onOpenReports = (e) => {
    this.setState({
      showingReport: false,
      reportName: "",
      hasTableShown: true,
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleNumericDropdownChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  handleDropdownChange = (e) => {
    if (e.name == "mRptAgent") {
      var mRptAgentName = window.$("#mRptAgent option:selected").text();
      this.setState({ [e.name]: e.newValue, mRptAgentName: mRptAgentName });
    } else {
      this.setState({ [e.name]: e.newValue });
    }
  };
  onOpenFilterClick = (e) => {
    //console.log("inside onOpenFilterClick, this.state: ", this.state);
    this.setState({
      mRptSaleType: this.state.SaleType,
      mRptStatus: this.state.Status,
      mRptMandate: this.state.Mandate,
      mRptPropertyType: this.state.PropertyType,
      mRptAgent: this.state.Agent,
      mRptAgentName: this.state.AgentName,
      mRptPriceFrom: this.state.PriceFrom,
      mRptPriceTo: this.state.PriceTo,
      mRptMandateEndFrom: this.state.MandateEndFrom,
      mRptMandateEndTo: this.state.MandateEndTo,
      mRptMandateStartFrom: this.state.MandateStartFrom,
      mRptMandateStartTo: this.state.MandateStartTo,
    });
    window.$("#reportFilterModel").modal("show");
  };
  onApplyFilterClick = (e) => {
    //console.log("inside onApplyFilterClick, this.state: ", this.state);
    window.$("#reportFilterModel").modal("hide");
    this.setState(
      {
        SaleType: this.state.mRptSaleType,
        Status: this.state.mRptStatus,
        Mandate: this.state.mRptMandate,
        PropertyType: this.state.mRptPropertyType,
        Agent: this.state.mRptAgent,
        AgentName: this.state.mRptAgentName,
        PriceFrom: this.state.mRptPriceFrom,
        PriceTo: this.state.mRptPriceTo,
        MandateEndFrom: this.state.mRptMandateEndFrom,
        MandateEndTo: this.state.mRptMandateEndTo,
        MandateStartFrom: this.state.mRptMandateStartFrom,
        MandateStartTo: this.state.mRptMandateStartTo,
      },
      function () {
        this.setState({ FilterText: this.getFilterText() });
        if (this.state.showingReport) this.onOpenReportViewer(this.state.reportID, this.state.reportName);
      }
    );
  };
  onResetFilterClick = (e) => {
    window.$("#reportFilterModel").modal("hide");
    this.setState(
      {
        FilterText: "",
        SaleType: "",
        Status: "",
        Mandate: "Mandate Type",
        PropertyType: "Property Type",
        Agent: "",
        PriceFrom: "",
        PriceTo: "",
        AgentName: "",
        mRptSaleType: "",
        mRptStatus: "",
        mRptMandate: "Mandate Type",
        mRptPropertyType: "Property Type",
        mRptAgent: "",
        mRptPriceFrom: "",
        mRptPriceTo: "",
        mRptAgentName: "",
        MandateEndFrom: "",
        mRptMandateEndFrom: "",
        MandateEndTo: "",
        mRptMandateEndTo: "",
        MandateStartFrom: "",
        mRptMandateStartFrom: "",
        MandateStartTo: "",
        mRptMandateStartTo: "",
        //reportValues: {},
      },
      function () {
        if (this.state.showingReport) this.onOpenReportViewer(this.state.reportID, this.state.reportName);
      }
    );
  };

  render() {
    var columns = [
      { data: "reportName", title: "ReportName" },
      { data: "reportDescription", title: "Description" },
    ];
    return (
      <div>
        <div className="col-md-12 col-sm-12">
          <div
            style={{ display: this.state.showingReport ? "none" : "block" }}
            className="panel panel-default panel-table oms-box"
            data-intercom-target="grid-panel"
          >
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="form-inline form-group">
                    {this.props.Category == "listings" ? (
                      <div className="element">
                        <div className="truncate">
                          <label>
                            <i className="fa fa-filter" aria-hidden="true"></i>
                            &nbsp;
                          </label>
                          <a href="javascript:void(0);" className="filter-text" onClick={this.onOpenFilterClick}>
                            {this.state.FilterText == "" ? (
                              "Select report filter"
                            ) : (
                              <span>
                                <b>Report filter: </b>
                                {this.state.FilterText}
                              </span>
                            )}
                          </a>
                        </div>
                      </div>
                    ) : (
                      <h4>Reports</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-body table-responsive">
              <DataTableComp
                id="reportTable"
                hasTableShown={this.state.hasTableShown}
                columns={columns}
                columnDefs={[]}
                data={this.state.data}
                order={[[1, "asc"]]}
                onRowClick={this.handleRowClick}
              />
              <DataTableLoading loading={this.state.loading} />
            </div>
          </div>
          <div style={{ display: this.state.showingReport ? "block" : "none" }}>
            {this.props.Category == "listings" ? (
              <div id="reportViewerMenu" style={{ marginTop: -55, marginBottom: 10, marginLeft: 15 }}>
                <h4>{this.state.reportName}</h4>
                <a href="javascript:void(0);" className="filter-text" onClick={this.onOpenFilterClick}>
                  <i className="fa fa-filter" aria-hidden="true" style={{ fontSize: 15 }}></i>
                  &nbsp;
                  {this.state.FilterText == "" ? (
                    "Select report filter"
                  ) : (
                    <span>
                      <b>Report filter: </b>
                      {this.state.FilterText}
                    </span>
                  )}
                </a>
                <br></br>
                <br></br>
              </div>
            ) : null}
            <div id="reportViewer" className="listing-reports" style={{ marginTop: -50, marginLeft: 10 }}>
              <TelerikReportViewerComponent reportValues={this.state.reportValues} />
            </div>
          </div>
        </div>
        {this.props.Category == "listings" ? (
          <div className="modal fade" id="reportFilterModel" tabIndex={-1} aria-labelledby="filterModelLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    ×
                  </button>
                  <h4 className="modal-title" id="reportFilterModelLabel">
                    Report filter
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                          <select
                            id="mRptSaleType"
                            name="mRptSaleType"
                            style={{ marginRight: 10 }}
                            className="form-control"
                            value={this.state.mRptSaleType}
                            onChange={this.handleChange}
                          >
                            <option value="">Sale Type</option>
                            <option value="1">For sale</option>
                            <option value="2">For rent</option>
                            {/*<option value="3">Auction</option>*/}
                            <option value="4">Valuation</option>
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                          <select
                            id="mRptStatus"
                            name="mRptStatus"
                            style={{ marginRight: 10 }}
                            className="form-control"
                            value={this.state.mRptStatus}
                            onChange={this.handleChange}
                          >
                            <option value="">Status</option>
                            <option value="1">Active</option>
                            <option value="2">Expired</option>
                            <option value="3">Withdrawn</option>
                            <option value="4">Rented</option>
                            <option value="5">Sold - Under Offer</option>
                            <option value="6">Sold - Registered</option>
                            <option value="7">Sold - Cancelled</option>
                            <option value="8">Inactive</option>
                          </select>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                          <OMSDropdown
                            id="mRptMandate"
                            name="mRptMandate"
                            style={{ marginRight: 10 }}
                            options={this.props.MandateTypesRptDD}
                            valueField=""
                            labelField=""
                            value={this.state.mRptMandate}
                            onChange={this.handleDropdownChange}
                          />
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="form-group">
                        <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                          <OMSDropdown
                            id="mRptPropertyType"
                            name="mRptPropertyType"
                            style={{ marginRight: 10 }}
                            options={this.props.PropertyTypesRptDD}
                            valueField=""
                            labelField=""
                            value={this.state.mRptPropertyType}
                            onChange={this.handleDropdownChange}
                          />
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                          <OMSDropdown
                            id="mRptAgent"
                            name="mRptAgent"
                            style={{ marginRight: 10 }}
                            options={this.props.AgentsRptDD}
                            valueField="Value"
                            labelField="Text"
                            value={this.state.mRptAgent}
                            onChange={this.handleDropdownChange}
                          />
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10"></div>
                      </div>
                      <div className="hidden-xs hidden-sm">
                        <br />
                        <br />
                      </div>
                      <div className="form-group">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <label>Mandate start between</label>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                              {/* <input
                                type="text"
                                placeholder="Mandate start from"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker"
                                id="mRptMandateStartFrom"
                                name="mRptMandateStartFrom"
                                value={this.state.mRptMandateStartFrom}
                                onChange={this.handleChange}
                              /> */}
                              <DatePicker
                                selected={this.state.mRptMandateStartFrom}
                                //change date format to dd-MM-yyyy
                                onChange={(date) => {
                                  //console.log("inside datePicker onChange Event", date);
                                  this.setState({ mRptMandateStartFrom: date });
                                }}
                                dateFormat="dd-MM-yyyy"
                                value={this.state.mRptMandateStartFrom}
                                placeholderText="Mandate start from"
                                name="mRptMandateStartFrom"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker2"
                              />
                            </div>
                            <div className="col-md-8 col-sm-6 col-xs-12 marginBottom10">
                              {/* <input
                                type="text"
                                placeholder="Mandate start to"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker"
                                id="mRptMandateStartTo"
                                name="mRptMandateStartTo"
                                value={this.state.mRptMandateStartTo}
                                onChange={this.handleChange}
                              /> */}
                              <DatePicker
                                selected={this.state.mRptMandateStartTo}
                                onChange={(date) => this.setState({ mRptMandateStartTo: date })}
                                dateFormat="dd-MM-yyyy"
                                value={this.state.mRptMandateStartTo}
                                placeholderText="Mandate start to"
                                name="mRptMandateStartTo"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                //style={{ width: 150 }}
                                className="form-control MandateDatePicker2"
                              />
                            </div>
                          </div>
                          <div className="hidden-xs hidden-sm">
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <label>Mandate expire between</label>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                              {/* <input
                                type="text"
                                placeholder="Mandate end from"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker"
                                id="mRptMandateEndFrom"
                                name="mRptMandateEndFrom"
                                value={this.state.mRptMandateEndFrom}
                                onChange={this.handleChange}
                            /> */}
                              <DatePicker
                                selected={this.state.mRptMandateEndFrom}
                                onChange={(date) => this.setState({ mRptMandateEndFrom: date })}
                                dateFormat="dd-MM-yyyy"
                                value={this.state.mRptMandateEndFrom}
                                placeholderText="Mandate end from"
                                name="mRptMandateEndFrom"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker2"
                              />
                            </div>
                            <div className="col-md-8 col-sm-6 col-xs-12 marginBottom10">
                              {/* <input
                                type="text"
                                placeholder="Mandate end to"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker"
                                id="mRptMandateEndTo"
                                name="mRptMandateEndTo"
                                value={this.state.mRptMandateEndTo}
                                onChange={this.handleChange}
                          /> */}
                              <DatePicker
                                selected={this.state.mRptMandateEndTo}
                                onChange={(date) => this.setState({ mRptMandateEndTo: date })}
                                dateFormat="dd-MM-yyyy"
                                value={this.state.mRptMandateEndTo}
                                placeholderText="Mandate end to"
                                name="mRptMandateEndTo"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ width: 150 }}
                                className="form-control MandateDatePicker2"
                              />
                            </div>
                          </div>
                          <div className="hidden-xs hidden-sm">
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <label>Price range</label>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                              <OMSNumericBox
                                id="mRptPriceFrom"
                                placeholder="Price from"
                                separator=","
                                name="mRptPriceFrom"
                                maxLength={10}
                                style={{ width: 150 }}
                                value={this.state.mRptPriceFrom}
                                isDecimal={false}
                                onChange={this.handleNumericDropdownChange}
                              />
                            </div>
                            <div className="col-md-8 col-sm-6 col-xs-12 marginBottom10">
                              <OMSNumericBox
                                id="mRptPriceTo"
                                separator=","
                                placeholder="Price to"
                                name="mRptPriceTo"
                                maxLength={10}
                                style={{ width: 150 }}
                                value={this.state.mRptPriceTo}
                                isDecimal={false}
                                onChange={this.handleNumericDropdownChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <a href="javascript:void(0);" style={{ marginRight: 5 }} onClick={this.onResetFilterClick}>
                    Reset
                  </a>
                  <button type="button" className="btn btn-primary" onClick={this.onApplyFilterClick}>
                    Apply
                  </button>
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
