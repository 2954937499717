import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { ResetFormValues, ResetModel } from "../../app/models/ResetModel";
import { User, UserFormValues } from "../../app/models/User";
import { useStore } from "../../app/stores/store";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";

interface Props {
  token: string;
}

function ResetTwoFABox({ token }: Props) {


  const [user, setUser] = useState<ResetModel>();


  const defaultUrl = "/";

  const [isLoading, setIsLoading] = useState(true);


 

  useEffect(() => {
    if (token) {
      setIsLoading(true);

     
      apiAgent.Account.resetTwoFAAjax(token)
        .then((response: ResetModel) => {
          let oUser: ResetModel = response;
          setUser(oUser);
          setIsLoading(false);
          
        })
        .catch((error: any) => {
          console.log(error);
        });


    }
  }, [token]);

  

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading) {
    return (
      <>
        <div className="row loginbox">
          <div className="col-lg-12 text-center">
            <span className="signIntext">Two Factor Authentication Disabled</span>
            <br />
            <br />
          </div>
          <div id="content">
            
            <div className="col-sm-12">
              <br />
              <label style={{ marginLeft: -10 }}>
                {user!.displayName}
                <small style={{ fontWeight: "normal" }}> ({user!.emailAddress})</small>
              </label>
              <br />
              <br />
            </div>

            <div>
              <div className="col-sm-12">
                <p style={{ marginLeft: 10 }}>Your Two Factor Authentication has been disabled. You can now sign in with your username and password. </p>
                <br />
                <p style={{ marginLeft: 10 }}>Please re-enable your two factor authentication by going to My Profile page after signing in.</p>
                <br />
              </div>
              <div className="col-sm-12">
                <Link to={defaultUrl} className="btn submitButton">
                  Sign In
                </Link>
                <br />
                <br />
              </div>
            </div>

            
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

export default ResetTwoFABox;
