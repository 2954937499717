import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useStore } from "../../../app/stores/store";
import { ToPriceString, ToPriceStringWithoutDecimal } from "../../../app/common/common-functions/functions";

export default function Chart(props) {
  const { userStore } = useStore();
  return (
    <ResponsiveContainer width='100%' minHeight={300}>
      <BarChart
        width={800}
        height={300}
        data={props.data}
        margin={{
          top: 5,
          right: 25,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={props.dataKey} />
        <YAxis tickFormatter={(e) => props.currencySymbol + ToPriceStringWithoutDecimal(e)}/>
        <Tooltip formatter={(e) => props.currencySymbol + ToPriceString(e)} />
        <Bar dataKey={props.barKey} fill={userStore?.userSession?.themeColors?.color2 ? userStore?.userSession?.themeColors?.color2 : "#92ab1c"} />
      </BarChart>
    </ResponsiveContainer>
  );
}
