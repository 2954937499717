import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { OMSNumericBox } from "../../../../app/common/common-components/OMSComponent";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";
import { ToPriceString } from "../../../../app/common/common-functions/functions";
import paidStamp from "../../../../assets/images/paid.svg";
import ConfirmationModalPopup from "../../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import TelerikReportViewerComponent from "../../../../app/common/common-components/TelerikReportViewerComponent";
import apiAgent from "../../../../app/api/apiAgent";
import { toast } from "react-toastify";
import { FromJavascriptDateToDateInputStandardString } from "../../../../app/common/common-functions/functions";
import { ButtonGroup } from "react-bootstrap";
import OMSButtonDropDown from "../../../../app/common/common-components/OMSButtonDropDown/OMSButtonDropDown";
const DealFinanceStepView = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getData() {
      var entity = {
        purchasePrice: purchasePrice,
        askingPrice: askingPrice,
        priceValues: priceValues,
        nettCommissionAmount: nettCommissionAmount,
        nettCommissionPercentage: nettCommissionPercentage,
        nettCommissionType: nettCommissionType,
        isAddVAT: isAddVAT,
        deductions: commissionDeductionData,
        splits: [],
        dealType: dealType,
        duration: duration,
        durationType: durationType,
      };

      commissionSplitData.forEach((s) => {
        var es = {
          commissionSplitID: s.commissionSplitID,
          userID: s.userID,
          userName: " ",
          splitType: s.splitType,
          type: parseInt(s.type),
          inputValueType: parseInt(s.inputValueType),
          percentage: s.percentage,
          amount: s.amount,
          isAddVAT: s.isAddVAT,
          isMarkPaid: s.isMarkPaid,
          paidOn: " ",
          splitDeduction: s.splitDeduction,
          reference: s.reference
        };
        entity.splits.push(es);
      });

      return entity;
    },
  }));

  useEffect(() => {
    var model = props.Finance;

    if (model !== undefined) {
      setPurchasePrice(model.purchasePrice);
      setAskingPrice(model.askingPrice);
      setPriceValues(model.priceValues);
      setNettCommissionAmount(model.nettCommissionAmount);
      setNettCommissionPercentage(model.nettCommissionPercentage);
      setNettCommissionType(model.nettCommissionType);
      setIsAddVAT(model.isAddVAT);
      setCommissionDeductionData(model.deductions);
      setCommissionSplitData(model.splits);
      setDealType(model.dealType);
      setDuration(model.duration);
      setDurationType(model.durationType);
      setLeaseValue(model.purchasePrice * model.duration);
    }
  }, [props.Finance]);

  useEffect(() => {
    let ddValues = props?.contacts?.map((curContact) => {
      return {
        value: curContact.id,
        text: curContact.displayName + ": " + curContact.contactType,
      };
    });
    setcontactsDD(ddValues);
  }, [props]);

  const inputValueTypeDD = [
    {
      text: "Percentage",
      value: "0",
    },
    {
      text: "Amount",
      value: "1",
    },
  ];
  const leasePeriodUnitDD = [
    {
      text: "Months",
      value: "2",
    },
    {
      text: "Days",
      value: "0",
    },
    {
      text: "Weeks",
      value: "1",
    },

    {
      text: "Years",
      value: "3",
    },
  ];

  const getDefaultCommissionSplitModel = () => {
    return {
      commissionSplitID: "",
      userID: props.contacts[0]?.id,
      userName: props.contacts[0]?.displayName,
      dealId: props.DealID,
      splitType: "",
      type: 0,
      inputValueType: "",
      isAddVAT: false,
      isMarkPaid: false,
      paidOn: "",
      percentage: 0,
      amount: 0,
      splitDeduction: [],
      reference: ""
    };
  };

  const getDefaultCommissionDeductionModel = (modelType, index) => {
    return {
      deductionId: "",
      deductionType: "",
      inputValueType: "",
      percentage: 0,
      amount: 0,
      modelType: modelType,
      index: index,
      isVatExcluded: false,
    };
  };

  const [confirmationModalProps, setConfirmationModalProps] = useState({
    showModal: false,
    isConfirmed: (result) => { },
    title: "",
    body: "",
  });
  const [
    isNewPriceValueSelectionOpen,
    setIsNewPriceValueSelectionOpen,
  ] = useState(false);
  const [
    commissionDeductionModelData,
    setCommissionDeductionModelData,
  ] = useState(getDefaultCommissionDeductionModel("deductionModel", -1));
  const [commissionSplitModelData, setCommissionSplitModelData] = useState(
    getDefaultCommissionSplitModel()
  );
  const [splitIndex, setSplitIndex] = useState(null);
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [askingPrice, setAskingPrice] = useState(0);
  const [priceValues, setPriceValues] = useState([]);
  const [nettCommissionAmount, setNettCommissionAmount] = useState(0);
  const [nettCommissionPercentage, setNettCommissionPercentage] = useState(0);
  const [nettCommissionType, setNettCommissionType] = useState(1);
  const [isAddVAT, setIsAddVAT] = useState(false);
  const [commissionDeductionData, setCommissionDeductionData] = useState([]);
  const [commissionSplitData, setCommissionSplitData] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [contactsDD, setcontactsDD] = useState([]);
  const [paidIndex, setPaidIndex] = useState(-1);
  const [paidCommissionDate, setPaidDate] = useState("");
  const [dealType, setDealType] = useState(0);
  const [duration, setDuration] = useState(0);
  const [durationType, setDurationType] = useState(0);
  const [leaseValue, setLeaseValue] = useState(0);

  const saveCommissionSplitInDB = (model) => {
    apiAgent.Deal.saveCommissionSplitAjax(model)
      .then((res) => {
        toast.info("Commission Split Updated");
        if (typeof res === "object" && res !== null) {
          var splitData = res;
          var existSplitIndex = commissionSplitData.findIndex(
            (item) => item.commissionSplitID === model.commissionSplitID
          );
          if (existSplitIndex !== -1) {
            // Update existing item
            commissionSplitData[existSplitIndex] = splitData;
            setCommissionSplitData([...commissionSplitData]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.info("Something went wrong, try again");
      });
  };

  const saveCommissionSplitInDBInsert = (model, commissionSplitModelData) => {
    apiAgent.Deal.saveCommissionSplitAjax(model)
      .then((res) => {
        commissionSplitModelData.commissionSplitID = res.commissionSplitID;
        var newCommissionSplitData = [
          ...commissionSplitData,
          commissionSplitModelData,
        ];
        setCommissionSplitData(newCommissionSplitData);
        toast.info("Commission Split Updated");
      })
      .catch((err) => {
        console.log(err);
        toast.info("Something went wrong, try again");
      });
  };

  const handlePurchasePriceChange = (name, value) => {
    var Price = getPriceValue(value);

    setLeaseValue(duration * Price);
    setPurchasePrice(Price);
    calculateNettCommission(
      nettCommissionType,
      nettCommissionPercentage,
      nettCommissionAmount,
      dealType === 1 ? duration * Price : Price
    );
  };

  const handleLeaseValueChange = (name, value) => {
    setDuration(value);
    setLeaseValue(value * purchasePrice);
    calculateNettCommission(
      nettCommissionType,
      nettCommissionPercentage,
      nettCommissionAmount,
      value * purchasePrice
    );
  };

  const handleAskingPriceChange = (name, value) => {
    setAskingPrice(getPriceValue(value));
  };

  const showIsNewPriceValueSelectionOpen = () => {
    setIsNewPriceValueSelectionOpen(true);
  };

  const handleAddNewPriceValue = (priceType) => {
    setPriceValues([...priceValues, { priceType: priceType, priceValue: 0 }]);
    setIsNewPriceValueSelectionOpen(false);
  };

  const handleRemovePriceValue = (index) => {
    setPriceValues(priceValues.filter((ele, i) => i !== index));
  };

  const handlePriceValueChange = (name, value) => {
    var index = parseInt(name.replace("priceValue_", ""));
    priceValues[index].priceValue = getPriceValue(value);
    setPriceValues(priceValues);
  };

  const handleNettCommissionTypeChange = (value) => {
    setNettCommissionType(value);
    calculateNettCommission(
      value,
      nettCommissionPercentage,
      nettCommissionAmount,
      dealType === 1 ? duration * purchasePrice : purchasePrice
    );
  };

  const handleNettCommissionChange = (name, value) => {
    var CommAmount = getPriceValue(value);
    setNettCommissionAmount(CommAmount);
    calculateNettCommission(
      nettCommissionType,
      nettCommissionPercentage,
      CommAmount,
      dealType === 1 ? leaseValue : purchasePrice
    );
  };

  const handleNettCommissionPerChange = (name, value) => {
    var CommPerc = getPriceValue(value);
    setNettCommissionPercentage(CommPerc);
    calculateNettCommission(
      nettCommissionType,
      CommPerc,
      nettCommissionAmount,
      dealType === 1 ? leaseValue : purchasePrice
    );
  };

  const calculateNettCommission = (CommType, CommPerc, CommAmount, Price) => {
    if (CommType == 1) {
      // %
      var iCommAmount = (Price * CommPerc) / 100;
      setNettCommissionAmount(parseFloat(iCommAmount.toFixed(2)));
    } else {
      // Fixed
      var iCommPerc = 0;
      if (Price != 0) iCommPerc = (100 * CommAmount) / Price;
      setNettCommissionPercentage(iCommPerc);
    }
  };

  const handleIsAddVATChange = () => {
    var pIsAddVAT = !isAddVAT;
    setIsAddVAT(pIsAddVAT);
    //calculateGrossCommission(purchasePrice, askingPrice, nettCommission, pIsAddVAT);
  };

  const onNewDeductionModel = (modelType, index, item, isNew = true) => {
    window.$("#commission_deduction_model").modal("show");
    if (isNew) {
      if (
        props.deductionTypesDD[0]?.deductionPercentage === undefined ||
        props.deductionTypesDD[0]?.deductionPercentage == null ||
        props.deductionTypesDD[0]?.deductionPercentage == 0
      ) {
        // Amount
        setCommissionDeductionModelData({
          deductionId: "",
          deductionType: props.deductionTypesDD[0]?.value,
          inputValueType: "1",
          percentage: 0,
          amount: props.deductionTypesDD[0]?.deductionAmount,
          modelType: modelType,
          index: index,
          isVatExcluded: props.deductionTypesDD[0]?.isVatExcluded,
        });
      } else {
        // Percentage
        setCommissionDeductionModelData({
          deductionId: "",
          deductionType: props.deductionTypesDD[0]?.value,
          inputValueType: "0",
          percentage: props.deductionTypesDD[0]?.deductionPercentage,
          amount: 0,
          modelType: modelType,
          index: index,
          isVatExcluded: props.deductionTypesDD[0]?.isVatExcluded,
        });
      }
    } else {
      if (
        item?.percentage === undefined ||
        item?.percentage == null ||
        item?.percentage == 0
      ) {
        // Amount
        setCommissionDeductionModelData({
          deductionId: item.deductionId,
          deductionType: item.deductionType,
          inputValueType: "1",
          percentage: 0,
          amount: item.amount,
          modelType: modelType,
          index: index,
          isVatExcluded: item.isVatExcluded,
        });
      } else {
        // Percentage
        setCommissionDeductionModelData({
          deductionId: item.deductionId,
          deductionType: item.deductionType,
          inputValueType: "0",
          percentage: item.percentage,
          amount: 0,
          modelType: modelType,
          index: index,
          isVatExcluded: item.isVatExcluded,
        });
      }
    }
  };

  const handleInputChange = (e, name) => {
    if (name === "leasePeriodUnit") {
      setDurationType(e.newValue);
    }
  };
  const handleNewDeductionModelInputChange = (e, name) => {
    if (name === "deductionType") {
      var defaultDeductionType = props.deductionTypesDD.find((curType) => {
        return curType.deductionName == e.newValue;
      });

      if (defaultDeductionType) {
        if (
          defaultDeductionType.deductionPercentage === undefined ||
          defaultDeductionType.deductionPercentage == null ||
          defaultDeductionType.deductionPercentage == 0
        ) {
          // Amount
          setCommissionDeductionModelData({
            ...commissionDeductionModelData,
            inputValueType: "1",
            deductionType: e.newValue,
            amount: defaultDeductionType.deductionAmount,
            isVatExcluded: defaultDeductionType.isVatExcluded,
          });
        } else {
          // Percentage
          setCommissionDeductionModelData({
            ...commissionDeductionModelData,
            inputValueType: "0",
            deductionType: e.newValue,
            percentage: defaultDeductionType.deductionPercentage,
            isVatExcluded: defaultDeductionType.isVatExcluded,
          });
        }
      } else {
        setCommissionDeductionModelData({
          ...commissionDeductionModelData,
          deductionType: e.newValue,
        });
      }
    } else if (name === "inputValueType") {
      setCommissionDeductionModelData({
        ...commissionDeductionModelData,
        inputValueType: e.newValue,
      });
    } else if (name === "amount") {
      setCommissionDeductionModelData({
        ...commissionDeductionModelData,
        amount: e.target.value,
      });
    } else if (name === "percentage") {
      setCommissionDeductionModelData({
        ...commissionDeductionModelData,
        percentage: e.target.value,
      });
    } else if (name === "isVatExcluded") {
      setCommissionDeductionModelData({
        ...commissionDeductionModelData,
        isVatExcluded: e.target.checked,
      });
    }
  };

  const doCheckComissionDeductionData = () => {
    let result = true;
    if (
      !commissionDeductionModelData.deductionType ||
      commissionDeductionModelData.deductionType == ""
    ) {
      result = false;
      toast.error("Deduction Type is required");
    }

    if (
      !commissionDeductionModelData.inputValueType ||
      commissionDeductionModelData.inputValueType == ""
    ) {
      result = false;
      toast.error("Input value type is required");
    } else if (commissionDeductionModelData.inputValueType == "0") {
      if (
        !commissionDeductionModelData.percentage ||
        commissionDeductionModelData.percentage == 0
      ) {
        result = false;
        toast.error("Percentage is required");
      }
    } else if (commissionDeductionModelData.inputValueType == "1") {
      if (
        !commissionDeductionModelData.amount ||
        commissionDeductionModelData.amount == 0
      ) {
        result = false;
        toast.error("Amout is required");
      }
    }
    return result;
  };

  const handleNewDeductionModelSave = () => {
    if (doCheckComissionDeductionData()) {
      window.$("#commission_deduction_model").modal("hide");

      var modelType = commissionDeductionModelData.modelType;
      var index = commissionDeductionModelData.index;
      delete commissionDeductionModelData.modelType;
      delete commissionDeductionModelData.index;

      // var defaultDeductionType = props.deductionTypesDD.find((curType) => {
      //   return (
      //     curType.deductionName == commissionDeductionModelData.deductionType
      //   );
      // });

      if (modelType == "deductionModel") {
        commissionDeductionModelData.inputValueType = parseInt(
          commissionDeductionModelData.inputValueType
        );
        if (commissionDeductionModelData.inputValueType == 0) {
          // percentage
          commissionDeductionModelData.percentage = parseFloat(
            commissionDeductionModelData.percentage
          );
          commissionDeductionModelData.amount =
            (commissionDeductionModelData.percentage * nettCommissionAmount) /
            100;
        } else {
          commissionDeductionModelData.amount = parseFloat(
            commissionDeductionModelData.amount
          );
          commissionDeductionModelData.percentage = 0;
        }

        var newCommissionDeductionData = [
          ...commissionDeductionData,
          commissionDeductionModelData,
        ];
        setCommissionDeductionData(newCommissionDeductionData);
      } else {
        var splitDeductionSelected = commissionSplitData[index];
        commissionDeductionModelData.deductionId =
          commissionDeductionModelData.deductionId;
        commissionDeductionModelData.inputValueType = parseInt(
          commissionDeductionModelData.inputValueType
        );

        const splitDeductionExistingIndex = splitDeductionSelected.splitDeduction.findIndex(
          (item) =>
            item.deductionId === commissionDeductionModelData.deductionId
        );

        if (commissionDeductionModelData.inputValueType == 0) {
          // percentage
          commissionDeductionModelData.percentage = parseFloat(
            commissionDeductionModelData.percentage
          );
          commissionDeductionModelData.amount =
            (commissionDeductionModelData.percentage *
              splitDeductionSelected.amount) /
            100;
        } else {
          commissionDeductionModelData.amount = parseFloat(
            commissionDeductionModelData.amount
          );
          commissionDeductionModelData.percentage = 0;
        }

        if (splitDeductionExistingIndex !== -1) {
          // Update existing item
          splitDeductionSelected.splitDeduction[
            splitDeductionExistingIndex
          ] = commissionDeductionModelData;
        } else {
          // Add new item to the array
          splitDeductionSelected.splitDeduction = [
            ...splitDeductionSelected.splitDeduction,
            commissionDeductionModelData,
          ];
        }

        saveCommissionSplitInDB(commissionSplitData[index]);
        setCommissionSplitData([...commissionSplitData]);
      }
    }
  };

  const onNewSplitModel = (mode, data, idx) => {
    if (mode === "EDIT") {
      setCommissionSplitModelData({
        commissionSplitID: data.commissionSplitID,
        userID: data.userID,
        userName: data.userName,
        dealId: props.DealID,
        splitType: data.splitType,
        type: data.type,
        inputValueType: `${data.inputValueType}`,
        isAddVAT: data.isAddVAT,
        isMarkPaid: data.isMarkPaid,
        paidOn: data.paidOn,
        percentage: data.percentage,
        amount: data.amount,
        splitDeduction: data.splitDeduction,
        reference: data.reference
      });
      setSplitIndex(idx);
    } else {
      var defaultSplitType = props.splitTypesDD.find((curType, i) => {
        return i == 0;
      });
      if (defaultSplitType) {
        if (
          defaultSplitType.splitPercentage === undefined ||
          defaultSplitType.splitPercentage == null ||
          defaultSplitType.splitPercentage == 0
        ) {
          // Amount
          setCommissionSplitModelData({
            inputValueType: "1",
            splitType: defaultSplitType.value,
            amount: defaultSplitType.splitAmount,
            commissionSplitID: "",
            userID: props.contacts[0]?.id,
            userName: props.contacts[0]?.displayName,
            type: 0,
            isAddVAT: false,
            isMarkPaid: false,
            paidOn: "",
            dealId: props.DealID,
            percentage: 0,
            splitDeduction: [],
            reference: ""
          });
        } else {
          // Percentage
          setCommissionSplitModelData({
            inputValueType: "0",
            splitType: defaultSplitType.value,
            percentage: defaultSplitType.splitPercentage,
            commissionSplitID: "",
            userID: props.contacts[0]?.id,
            userName: props.contacts[0]?.displayName,
            type: 0,
            isAddVAT: false,
            isMarkPaid: false,
            paidOn: "",
            amount: 0,
            splitDeduction: [],
            dealId: props.DealID,
            reference: ""
          });
        }
      }
      setSplitIndex(null);
    }
    window.$("#split_model").modal("show");
  };

  const handleNewSplitModelInputChange = (e, name) => {
    if (name === "splitType") {
      if (splitIndex == null) {
        var defaultSplitType = props.splitTypesDD.find((curType) => {
          return curType.splitName == e.newValue;
        });
        if (defaultSplitType) {
          if (
            defaultSplitType.splitPercentage === undefined ||
            defaultSplitType.splitPercentage == null ||
            defaultSplitType.splitPercentage == 0
          ) {
            // Amount
            setCommissionSplitModelData({
              ...commissionSplitModelData,
              inputValueType: "1",
              splitType: e.newValue,
              amount: defaultSplitType.splitAmount,
            });
          }
          else {
            // Percentage
            setCommissionSplitModelData({
              ...commissionSplitModelData,
              inputValueType: "0",
              splitType: e.newValue,
              percentage: defaultSplitType.splitPercentage,
            });
          }
        } else {
          setCommissionSplitModelData({
            ...commissionSplitModelData,
            splitType: e.newValue,
          });
        }
      } else {
        setCommissionSplitModelData({
          ...commissionSplitModelData,
          splitType: e.newValue,
        });
      }
    } else if (name === "inputValueType") {
      setCommissionSplitModelData({
        ...commissionSplitModelData,
        inputValueType: e.newValue,
      });
    } else if (name === "userID") {
      var userID = e.newValue;
      var userName = "";
      var contactType = 0;
      var found = props.contacts.find((ele, i) => ele.id === userID);
      if (found) userName = found.displayName;
      if (found) contactType = found.type;
      setCommissionSplitModelData({
        ...commissionSplitModelData,
        userID: userID,
        userName: userName,
        type: contactType,
      });
    } else if (name === "amount") {
      setCommissionSplitModelData({
        ...commissionSplitModelData,
        amount: e.target.value,
      });
    } else if (name === "percentage") {
      setCommissionSplitModelData({
        ...commissionSplitModelData,
        percentage: e.target.value,
      });
    }
    else if (name === "reference") {
      setCommissionSplitModelData({
        ...commissionSplitModelData,
        reference: e.target.value,
      });
    };
  }
  const doCheckComissionSplitData = () => {
    let result = true;
    if (
      !commissionSplitModelData.splitType ||
      commissionSplitModelData.splitType == ""
    ) {
      result = false;
      toast.error("Split Type is required");
    }
    if (
      !commissionSplitModelData.userID ||
      commissionSplitModelData.userID == ""
    ) {
      result = false;
      toast.error("Contact is required");
    }

    if (
      !commissionSplitModelData.inputValueType ||
      commissionSplitModelData.inputValueType == ""
    ) {
      result = false;
      toast.error("Input value type is required");
    } else if (commissionSplitModelData.inputValueType == "0") {
      if (
        !commissionSplitModelData.percentage ||
        commissionSplitModelData.percentage == 0
      ) {
        result = false;
        toast.error("Percentage is required");
      }
    } else if (commissionSplitModelData.inputValueType == "1") {
      if (
        !commissionSplitModelData.amount ||
        commissionSplitModelData.amount == 0
      ) {
        result = false;
        toast.error("Amout is required");
      }
    }
    return result;
  };

  const handleNewSplitModelSave = () => {
    if (doCheckComissionSplitData()) {
      if (splitIndex === null) {
        window.$("#split_model").modal("hide");
        commissionSplitModelData.inputValueType = parseInt(
          commissionSplitModelData.inputValueType
        );
        if (commissionSplitModelData.inputValueType == 0) {
          // percentage
          commissionSplitModelData.percentage = parseFloat(
            commissionSplitModelData.percentage
          );
          commissionSplitModelData.amount =
            (commissionSplitModelData.percentage * nettCommissionAmount) / 100;
        } else {
          commissionSplitModelData.percentage = 0;
          commissionSplitModelData.amount = parseFloat(
            commissionSplitModelData.amount
          );
        }
        const model = {
          commissionSplitID: commissionSplitModelData.commissionSplitID,
          userID: commissionSplitModelData.userID,
          type: commissionSplitModelData.type,
          userName: commissionSplitModelData.userName,
          splitType: commissionSplitModelData.splitType,
          inputValueType: commissionSplitModelData.inputValueType,
          percentage: commissionSplitModelData.percentage,
          amount: commissionSplitModelData.amount,
          isAddVAT: commissionSplitModelData.isAddVAT,
          isMarkPaid: commissionSplitModelData.isMarkPaid,
          paidOn: commissionSplitModelData.paidOn,
          splitDeduction: commissionSplitModelData.splitDeduction,
          dealId: props.DealID,
          reference: commissionSplitModelData.reference
        };
        saveCommissionSplitInDBInsert(model, commissionSplitModelData);
      } else {
        if (
          commissionSplitModelData.splitType &&
          commissionSplitModelData.inputValueType !== "" &&
          commissionSplitModelData.userID
        ) {
          window.$("#split_model").modal("hide");
          commissionSplitModelData.inputValueType = parseInt(
            commissionSplitModelData.inputValueType
          );
          if (commissionSplitModelData.inputValueType == 0) {
            // percentage
            commissionSplitModelData.percentage = parseFloat(
              commissionSplitModelData.percentage
            );
            commissionSplitModelData.amount =
              (commissionSplitModelData.percentage * nettCommissionAmount) /
              100;
          } else {
            commissionSplitModelData.percentage = 0;
            commissionSplitModelData.amount = parseFloat(
              commissionSplitModelData.amount
            );
          }
          commissionSplitData.splice(splitIndex, 1);
          commissionSplitData.splice(splitIndex, 0, commissionSplitModelData);
          setCommissionSplitData([...commissionSplitData]);
        }
      }
    }
  };

  const handleSplitVATAddedChange = (index) => {
    commissionSplitData[index].isAddVAT = !commissionSplitData[index].isAddVAT;
    commissionSplitData[index].vatAmount = commissionSplitData[index].isAddVAT
      ? (commissionSplitData[index].amount -
        commissionSplitData[index].totalDeduction) *
      0.15
      : 0;
    setCommissionSplitData([...commissionSplitData]);
    saveCommissionSplitInDB(commissionSplitData[index]);
  };

  const handleSplitPaidDateModelSave = () => {
    if (paidCommissionDate) {
      var paidItem = { ...commissionSplitData[paidIndex] };
      paidItem.isMarkPaid = true;
      paidItem.paidOn = paidCommissionDate;

      var splitDate = paidCommissionDate.split("-");

      commissionSplitData[paidIndex].isMarkPaid = true;
      commissionSplitData[paidIndex].paidOn =
        splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
      setCommissionSplitData([...commissionSplitData]);
      saveCommissionSplitInDB(paidItem);

      window.$("#splitPaidDate_model").modal("hide");
    } else {
      alert("Please select a date before saving.");
    }
  };

  const handlePaidDateChange = (e) => {
    setPaidDate(e.target.value);
  };

  const handleSplitMarkPaidChange = (index, isPaid, item) => {
    if (isPaid) {
      var date = FromJavascriptDateToDateInputStandardString(new Date());

      setPaidDate(date);
      //setPaidItem(item);
      setPaidIndex(index);
      window.$("#splitPaidDate_model").modal("show");
    } else {
      var md = {
        showModal: true,
        isConfirmed: (result) => {
          handleSplitMarkPaidChangeCallback(result, index, item);
        },
        title: "Reverse Payment",
        body: "Are you sure you want to reverse this payment?",
      };
      setConfirmationModalProps(md);
    }
  };

  const handleSplitMarkPaidChangeCallback = (result, index, model) => {
    if (result == false) {
      setConfirmationModalProps({
        showModal: false,
        isConfirmed: (result) => { },
        title: "",
        body: "",
      });
    } else if (result == true) {
      setConfirmationModalProps({
        showModal: false,
        isConfirmed: (result) => { },
        title: "",
        body: "",
      });
      commissionSplitData[index].isMarkPaid = false;
      commissionSplitData[index].paidOn = "";
      setCommissionSplitData([...commissionSplitData]);
      saveCommissionSplitInDB(model);
    }
  };

  const onSplitDelete = (idx) => {
    var md = {
      showModal: true,
      isConfirmed: (result) => {
        splitDeleteCallBack(result, idx);
      },
      title: "Delete Split",
      body:
        "Are you sure you want to delete this split? This cannot be undone.",
    };
    setConfirmationModalProps(md);
  };
  const splitDeleteCallBack = (result, idx) => {
    if (result == false) {
      setConfirmationModalProps({
        showModal: false,
        isConfirmed: (result) => { },
        title: "",
        body: "",
      });
    } else if (result == true) {
      setConfirmationModalProps({
        showModal: false,
        isConfirmed: (result) => { },
        title: "",
        body: "",
      });
      commissionSplitData.splice(idx, 1);
      setCommissionSplitData([...commissionSplitData]);
    }
  };

  const getGrossCommission = () => {
    return nettCommissionAmount + (isAddVAT ? nettCommissionAmount * 0.15 : 0);
  };

  const getGrossCommissionPercentage = () => {
    let price = dealType === 1 ? leaseValue : purchasePrice;
    if (purchasePrice <= 0) return "0";
    else return ((getGrossCommission() / price) * 100).toFixed(2);
  };

  const getAskingPriceAchievedPercentage = () => {
    if (askingPrice <= 0) return "0";
    else return ((purchasePrice / askingPrice) * 100).toFixed(2);
  };

  const getTotalDeduction = () => {
    var sum = 0;
    commissionDeductionData.forEach((d) => {
      if (d.inputValueType == "0")
        // percentage
        sum += (d.percentage * nettCommissionAmount) / 100;
      else sum += d.amount;
    });
    return sum;
  };

  const getBalance = () => {
    return nettCommissionAmount - getTotalDeduction();
  };

  const getPriceValue = (value) => {
    if (value && value != "0") {
      value = value.replace(/,/g, "");
      return !Number.isNaN(value) && value != "" ? parseFloat(value) : 0;
    }
    return 0;
  };

  const getSplitItemAmount = (item) => {
    return item.inputValueType == 0
      ? (item.percentage * getBalance()) / 100
      : item.amount;
  };

  const getSplitDeductionItemAmount = (splitItem, deductionItem) => {
    return deductionItem.inputValueType == 0
      ? (deductionItem.percentage * getSplitItemAmount(splitItem)) / 100
      : deductionItem.amount;
  };

  const getSplitItemTotalDeductions = (item) => {
    var sum = 0;
    item.splitDeduction.forEach((d) => {
      sum += getSplitDeductionItemAmount(item, d);
    });
    return sum;
  };

  const getSplitVATAmount = (item) => {
    return item.isAddVAT
      ? (getSplitItemAmount(item) - getSplitItemTotalDeductions(item)) * 0.15
      : 0;
  };

  const getSplitVATExcludeAmount = (item) => {
    const vatIncludeSum = item.splitDeduction
      .filter((sdItem) => !sdItem.isVatExcluded)
      .reduce((sum, vatIncludeItem) => sum + vatIncludeItem.amount, 0);

    return item.isAddVAT
      ? (getSplitItemAmount(item) - vatIncludeSum) * 0.15
      : 0;
  };

  const openCommissionSlip = (commissionSplitID, userID) => {
    apiAgent.ReportViewer.viewCommissionSlip(props.DealID, userID)
      .then((response) => {
        setReportData(response);
        window.$("#commisson_slip_model").modal("show");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => { });
  };

  const deletePreDeduction = (i) => {
    commissionDeductionData.splice(i, 1);
    setCommissionDeductionData([...commissionDeductionData]);
  };

  const deleteSplitDeduction = (i, j) => {
    commissionSplitData[i].splitDeduction.splice(j, 1);
    setCommissionSplitData([...commissionSplitData]);
    saveCommissionSplitInDB(commissionSplitData[i]);
  };
  const dropdownNewValue = [
    "Bond amount",
    "Deposit",
    "Proceeds for sale",
    "Cash",
  ];

  return (
    <>
      <h4 className="header_text">
        {dealType === 1 ? "Rent breakdown" : "Offer breakdown"}
      </h4>
      <div className="row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="label_text">
              {dealType === 1 ? "Rent" : "Offer Price"}
            </label>
            <OMSNumericBox
              id="purchasePrice"
              name="purchasePrice"
              separator=","
              maxLength={12}
              maxDecimalPoints={2}
              value={purchasePrice}
              isDecimal={true}
              onChange={handlePurchasePriceChange}
              readOnly={props.accessType != 1 ? true : false}
            />
          </div>
          {dealType === 1 && (
            <div className="form-group">
              <label className="label_text">Lease Period</label>
              <div className="d-flex">
                <OMSNumericBox
                  id="leasePeriod"
                  name="leasePeriod"
                  maxLength={6}
                  value={duration}
                  onChange={handleLeaseValueChange}
                  readOnly={props.accessType != 1 ? true : false}
                />
                <OMSDropdown
                  id={"leasePeriod"}
                  options={leasePeriodUnitDD}
                  valueField="value"
                  className="form-control ml-1"
                  labelField="text"
                  value={durationType}
                  onChange={(e) => handleInputChange(e, "leasePeriodUnit")}
                />
              </div>
            </div>
          )}
          <div className="form-group">
            <label className="label_text">
              {dealType === 1 ? "Advertised rent" : "Asking price"}
            </label>
            <OMSNumericBox
              id="askingPrice"
              name="askingPrice"
              separator=","
              maxLength={12}
              maxDecimalPoints={2}
              value={askingPrice}
              isDecimal={true}
              onChange={handleAskingPriceChange}
              readOnly={props.accessType != 1 ? true : false}
            />
          </div>

          {priceValues.map((item, index) => (
            <div key={index} className="form-group">
              <label className="label_text">{item.priceType}</label>
              <div className="d-flex align-items-center">
                <OMSNumericBox
                  id={"priceValue_" + index}
                  name={"priceValue_" + index}
                  separator=","
                  maxLength={12}
                  maxDecimalPoints={2}
                  value={item.priceValue}
                  isDecimal={true}
                  onChange={handlePriceValueChange}
                  readOnly={props.accessType != 1 ? true : false}
                />
                <i
                  className="fa fa-solid fa-trash "
                  style={{ marginLeft: "8px", cursor: "pointer" }}
                  onClick={() => handleRemovePriceValue(index)}
                ></i>
              </div>
            </div>
          ))}

          {props.accessType == 1 && (
            <OMSButtonDropDown
              btnText="New Value"
              dropdownList={dropdownNewValue}
              onClickDropdown={handleAddNewPriceValue}
              buttonClassName={"model_link"}
            ></OMSButtonDropDown>
          )}
        </div>
        <div className="col-md-7">
          {dealType === 1 && (
            <div className="form-group">
              <label className="label_text">Total Lease Value</label>
              <OMSNumericBox
                id="leaseValue"
                name="leaseValue"
                separator=","
                maxLength={12}
                maxDecimalPoints={2}
                value={leaseValue}
                isDecimal={true}
                readOnly={true}
              />
            </div>
          )}

          <div className="form-group">
            <label className="label_text">Nett commission</label>
            <div className="d-flex-nett-commision">
              <div className="input-group" style={{ marginRight: 5 }}>
                <OMSNumericBox
                  id="nettCommissionPercentage"
                  name="nettCommissionPercentage"
                  separator=""
                  maxLength={6}
                  className="form-control nett-commission-per"
                  maxDecimalPoints={2}
                  value={nettCommissionPercentage}
                  isDecimal={true}
                  onChange={handleNettCommissionPerChange}
                  readOnly={
                    props.accessType == 1 ? nettCommissionType == 2 : true
                  }
                />

                <span className="input-group-addon" style={{ padding: 7 }}>
                  %
                </span>
              </div>
              <div className="nettCommission">
                <OMSNumericBox
                  id="nettCommission"
                  name="nettCommission"
                  separator=","
                  maxLength={12}
                  maxDecimalPoints={2}
                  value={nettCommissionAmount}
                  isDecimal={true}
                  onChange={handleNettCommissionChange}
                  readOnly={
                    props.accessType == 1 ? nettCommissionType == 1 : true
                  }
                />
              </div>

              <div
                className="btn-group nettCommission-type-per"
                role="group"
                style={{ marginLeft: "4px" }}
              >
                <button
                  className={
                    nettCommissionType == 1
                      ? "btn btn-success-oms"
                      : "btn btn-default"
                  }
                  onClick={() => handleNettCommissionTypeChange(1)}
                  type="button"
                >
                  %
                </button>

                <button
                  className={
                    nettCommissionType == 2
                      ? "btn btn-success-oms"
                      : "btn btn-default"
                  }
                  onClick={() => handleNettCommissionTypeChange(2)}
                  type="button"
                >
                  Fixed
                </button>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="d-flex ">
              <input
                type="checkbox"
                className=""
                checked={isAddVAT}
                onChange={handleIsAddVATChange}
                disabled={props.accessType != 1 ? true : false}
              />
              <label className="mx-2 label_text" style={{ paddingTop: "7px" }}>
                add VAT @ 15%{" "}
                {`(${props.currency}${isAddVAT ? ToPriceString(nettCommissionAmount * 0.15) : "0"
                  })`}
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="label_text">Gross commission</label>
            <div className="d-flex">
              <div className="input-group" style={{ marginRight: 5 }}>
                <input
                  type="text"
                  className="form-control"
                  value={getGrossCommissionPercentage()}
                  style={{ maxWidth: "210px" }}
                  readOnly
                />
                <span className="input-group-addon" style={{ padding: 7 }}>
                  %
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                value={ToPriceString(getGrossCommission())}
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <h5>
              {getAskingPriceAchievedPercentage()}% of{" "}
              {dealType === 1 ? "advertised rent" : "asking price"} was achieved
            </h5>
            <h5>{`The owner nett amount is ${props.currency}${ToPriceString(
              dealType === 1
                ? leaseValue - getGrossCommission()
                : purchasePrice - getGrossCommission()
            )}`}</h5>
          </div>
          <div className="pull-right">
            {/*<button className="btn btn-success-oms">
              <i className="fa fa-check"></i> Save
                </button>*/}
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: 5 }}>
        <div className="col-md-12">
          <h4 className="header_text">Pre-commission deductions</h4>
          <div className="pipline_table">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>Name</th>
                  <th style={{ textAlign: "center", width: "25%" }}>%</th>
                  <th style={{ textAlign: "right", width: "50%" }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {commissionDeductionData?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.deductionType}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.percentage == 0 ? "" : item.percentage}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {`${props.currency}${ToPriceString(
                          item.inputValueType == 0
                            ? (nettCommissionAmount * item.percentage) / 100
                            : item.amount
                        )}`}
                        {props.accessType == 1 && (
                          <i
                            className="fa fa-solid fa-trash"
                            style={{
                              cursor: "pointer",
                              marginLeft: 5,
                              width: 20,
                            }}
                            onClick={() => deletePreDeduction(i)}
                          ></i>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {props.accessType == 1 && (
            <div
              className="pull-right mt-2"
              style={{ textAlign: "end", marginRight: 28 }}
            >
              <a
                className="model_link"
                onClick={() => onNewDeductionModel("deductionModel", -1)}
              >
                New Deduction
              </a>
              <div className="mt-2">
                <label className="mx-2">Total Deductions:</label>{" "}
                <label className="">{`${props.currency}${ToPriceString(
                  getTotalDeduction()
                )}`}</label>
              </div>
              <div>
                <label className="mx-2">Balance:</label>{" "}
                <label className="">{`${props.currency}${ToPriceString(
                  getBalance()
                )}`}</label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row ">
        <div className="col-md-12">
          <div className="d-flex justify-content-between mt-2">
            <h4 className="header_text">Commission splits</h4>
            {props.accessType == 1 && (
              <a
                className="model_link"
                onClick={() => onNewSplitModel("New")}
                style={{ marginRight: 28 }}
              >
                New Split
              </a>
            )}
          </div>
          <div className="splits mt-1" style={{ marginBottom: 20 }}>
            {commissionSplitData?.map((item, i) => {
              if (
                props.accessType == 1 ||
                item.userID == props.userSession.userId
              ) {
                return (
                  <div
                    key={item.commissionSplitID}
                    className="split_card bgcolor1 mx-2 mt-1"
                    style={{ width: "400px", padding: "20px" }}
                  >
                    {props.accessType == 1 && (
                      <div className="edit_icon">
                        {item.isMarkPaid ? (
                          ""
                        ) : (
                          <i
                            className="fa fa-edit mx-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => onNewSplitModel("EDIT", item, i)}
                          ></i>
                        )}
                        <i
                          className="fa fa-trash"
                          style={{ cursor: "pointer" }}
                          onClick={() => onSplitDelete(i)}
                        ></i>
                      </div>
                    )}
                    {item.isMarkPaid && (
                      <div className="paid_stamp">
                        <img src={paidStamp} alt="Paid"></img>
                      </div>
                    )}

                    <div className="split_info">
                      <div className="">
                        <label>{item.userName}</label>
                      </div>
                      <span>{item.splitType} {item.reference && ` | ${item.reference}`} </span>
                      <span
                        className="btn btn-success-oms"
                        style={{ width: "fit-content", marginTop: "5px" }}
                      >
                        {`${props.currency}${ToPriceString(
                          getSplitItemAmount(item)
                        )} ${item.inputValueType == 0
                          ? "(" + item.percentage + "%)"
                          : ""
                          }`}
                      </span>
                    </div>
                    <div className="details">
                      <div className="d-flex justify-content-start">
                        <label>Commission deductions</label>
                      </div>
                      <div className="d-flex justify-content-start">
                        <table style={{ width: "100%", marginBottom: 5 }}>
                          <tbody>
                            {item.splitDeduction?.map((itemD, j) => {
                              return (
                                <tr key={j}>
                                  <td>
                                    {props.accessType == 1 &&
                                      !item.isMarkPaid ? (
                                      <span
                                        className={
                                          props.accessType == 1 &&
                                            !item.isMarkPaid
                                            ? "spnDeductionType"
                                            : ""
                                        }
                                        onClick={() =>
                                          onNewDeductionModel(
                                            "splitDeductionModel",
                                            i,
                                            itemD,
                                            false
                                          )
                                        }
                                      >
                                        {itemD.deductionType}
                                      </span>
                                    ) : (
                                      <span>{itemD.deductionType}</span>
                                    )}

                                    {itemD.isVatExcluded ? (
                                      <b>&nbsp;(VAT Exempt)</b>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {itemD.percentage == 0
                                      ? ""
                                      : `${itemD.percentage} %`}{" "}
                                  </td>
                                  <td style={{ textAlign: "right" }}>{`${props.currency
                                    }${ToPriceString(
                                      getSplitDeductionItemAmount(item, itemD)
                                    )}`}</td>
                                  {!item.isMarkPaid && (
                                    <td style={{ textAlign: "right" }}>
                                      {props.accessType == 1 && (
                                        <i
                                          className="fa fa-trash"
                                          style={{ fontSize: "18px" }}
                                          onClick={() =>
                                            deleteSplitDeduction(i, j)
                                          }
                                        ></i>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      <div>
                        <div
                          className={
                            item.isMarkPaid
                              ? ""
                              : "d-flex justify-content-end mb-1"
                          }
                        >
                          {item.isMarkPaid ? (
                            ""
                          ) : props.accessType == 1 ? (
                            <a
                              className="model_link"
                              onClick={() =>
                                onNewDeductionModel("splitDeductionModel", i)
                              }
                            >
                              New Deduction
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="border_line"></div>
                      </div>
                      <div className={`d-flex justify-content-between mt-1`}>
                        <span>Balance</span>
                        <span>{`${props.currency}${ToPriceString(
                          getSplitItemAmount(item) -
                          getSplitItemTotalDeductions(item)
                        )}`}</span>
                      </div>
                      <div className={`d-flex justify-content-between mt-1`}>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            checked={item.isAddVAT}
                            value={item.isAddVAT}
                            disabled={props.accessType != 1 || item.isMarkPaid}
                            onClick={() => handleSplitVATAddedChange(i)}
                          />{" "}
                          <span className="pt-1 mx-1">VAT @ 15%</span>
                        </div>
                        <span>{`${props.currency}${ToPriceString(
                          getSplitVATExcludeAmount(item)
                        )}`}</span>
                      </div>
                      <div className={`d-flex justify-content-between mt-1`}>
                        <label>Balance payable</label>
                        <label>{`${props.currency}${ToPriceString(
                          getSplitItemAmount(item) -
                          getSplitItemTotalDeductions(item) +
                          getSplitVATExcludeAmount(item)
                        )}`}</label>
                      </div>
                    </div>
                    <div className="flex-commission-container mt-2">
                      {/* {item.isMarkPaid && ( */}
                      <div class="flex-item">
                        <span
                          style={{ fontSize: 12, cursor: "pointer" }}
                          onClick={() =>
                            openCommissionSlip(
                              item.commissionSplitID,
                              item.userID
                            )
                          }
                        >
                          <i
                            className="fa fa-envelope"
                            style={{ fontSize: 12 }}
                          ></i>
                          &nbsp;<strong>Commission Slip</strong>
                        </span>
                        <span style={{ fontSize: 12, marginLeft: 5 }}>
                          {item.isMarkPaid
                            ? `(Paid on ${item.paidOn})`
                            : `(Not paid)`}
                        </span>
                      </div>
                      {/* )} */}
                      {props.accessType == 1 && (
                        <div className="flex-item">
                          {item.isMarkPaid ? (
                            <a
                              className="model_link"
                              onClick={() =>
                                handleSplitMarkPaidChange(i, false, item)
                              }
                            >
                              Reverse
                            </a>
                          ) : (
                            <a
                              className="model_link"
                              onClick={() =>
                                handleSplitMarkPaidChange(i, true, item)
                              }
                            >
                              Mark paid
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="splitPaidDate_model"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="split_model"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="">
                Commission Split Payout
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Paid Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="CommissionPaidDate"
                      value={paidCommissionDate}
                      onChange={handlePaidDateChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  <i className="fa fa-times"></i> Cancel
                </button>
                <button
                  className="btn btn-success-oms"
                  onClick={handleSplitPaidDateModelSave}
                >
                  <i className="fa fa-check"></i> Mark Paid
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="commission_deduction_model"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="commison_deduction_model"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="">
                {commissionDeductionModelData.deductionId === ""
                  ? "New "
                  : "Update "}
                Commission Deduction
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Type</label>
                    <OMSDropdown
                      id={"type"}
                      options={props.deductionTypesDD}
                      valueField="value"
                      labelField="text"
                      disabled={
                        commissionDeductionModelData.deductionId === ""
                          ? false
                          : true
                      }
                      value={commissionDeductionModelData.deductionType}
                      onChange={(e) =>
                        handleNewDeductionModelInputChange(e, "deductionType")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Input value as</label>
                    <OMSDropdown
                      id={"valueAs"}
                      options={inputValueTypeDD}
                      valueField="value"
                      labelField="text"
                      value={commissionDeductionModelData.inputValueType}
                      onChange={(e) =>
                        handleNewDeductionModelInputChange(e, "inputValueType")
                      }
                    />
                  </div>
                  {commissionDeductionModelData.inputValueType && (
                    <>
                      {commissionDeductionModelData.inputValueType == "1" ? (
                        <div className="form-group">
                          <label>Amount</label>
                          <input
                            type="number"
                            className="form-control"
                            value={commissionDeductionModelData.amount}
                            onChange={(e) =>
                              handleNewDeductionModelInputChange(e, "amount")
                            }
                          />
                        </div>
                      ) : (
                        <div className="form-group">
                          <label>Percentage</label>
                          <input
                            type="number"
                            className="form-control"
                            value={commissionDeductionModelData.percentage}
                            onChange={(e) =>
                              handleNewDeductionModelInputChange(
                                e,
                                "percentage"
                              )
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="isVatExclude"
                      className="form-check-input"
                      checked={commissionDeductionModelData.isVatExcluded}
                      onChange={(e) =>
                        handleNewDeductionModelInputChange(e, "isVatExcluded")
                      }
                    />
                    <label className="form-check-label" htmlFor="isVatExclude">
                      &nbsp; VAT Exempt
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  <i className="fa fa-times"></i> Cancel
                </button>
                <button
                  className="btn btn-success-oms"
                  onClick={() => handleNewDeductionModelSave()}
                >
                  <i className="fa fa-check"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="split_model"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="split_model"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="">
                New Commission Split
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Contact &nbsp;
                      <i
                        class="fa fa-info-circle"
                        style={{ cursor: "pointer" }}
                        title="If you don't find any contacts in dropdown , Please add deal contact to add split."
                      ></i>
                    </label>
                    <OMSDropdown
                      id={"type"}
                      options={contactsDD}
                      valueField="value"
                      labelField="text"
                      value={commissionSplitModelData.userID}
                      onChange={(e) =>
                        handleNewSplitModelInputChange(e, "userID")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Type</label>
                    <OMSDropdown
                      id={"type"}
                      options={props.splitTypesDD}
                      valueField="value"
                      labelField="text"
                      value={commissionSplitModelData.splitType}
                      onChange={(e) =>
                        handleNewSplitModelInputChange(e, "splitType")
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Input value as</label>
                    <OMSDropdown
                      id={"valueAs"}
                      options={inputValueTypeDD}
                      valueField="value"
                      labelField="text"
                      value={commissionSplitModelData.inputValueType}
                      onChange={(e) =>
                        handleNewSplitModelInputChange(e, "inputValueType")
                      }
                    />
                  </div>
                  {commissionSplitModelData.inputValueType !== "" && (
                    <>
                      {commissionSplitModelData.inputValueType == "1" ? (
                        <div className="form-group">
                          <label>Amount</label>
                          <input
                            type="number"
                            className="form-control"
                            value={commissionSplitModelData.amount}
                            onChange={(e) =>
                              handleNewSplitModelInputChange(e, "amount")
                            }
                          />
                        </div>
                      ) : (
                        <div className="form-group">
                          <label>Percentage</label>
                          <input
                            type="number"
                            className="form-control"
                            value={commissionSplitModelData.percentage}
                            onChange={(e) =>
                              handleNewSplitModelInputChange(e, "percentage")
                            }
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label>Reference</label>
                        <input
                          type="text"
                          className="form-control"
                          value={commissionSplitModelData.reference}
                          onChange={(e) =>
                            handleNewSplitModelInputChange(e, "reference")
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  <i className="fa fa-times"></i> Cancel
                </button>
                <button
                  className="btn btn-success-oms"
                  onClick={() => handleNewSplitModelSave()}
                >
                  <i className="fa fa-check"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="commisson_slip_model"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="commisson_slip_model"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="">
                Commission Slip
              </h4>
            </div>
            <div className="modal-body">
              <div
                style={{
                  minHeight: "500px",
                }}
              >
                {reportData != null ? (
                  <TelerikReportViewerComponent reportValues={reportData} />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  <i className="fa fa-times"></i> Cancel
                </button>
                {/* <button className="btn btn-success-oms">
                  <i className="fa fa-check"></i> Save
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModalPopup {...confirmationModalProps} />
    </>
  );
});

export default DealFinanceStepView;
