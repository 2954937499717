import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import Header from "./Header";
import apiAgent from "../../app/api/apiAgent";
import { HeaderModel } from "../../app/models/HeaderModel";

export default observer(function HeaderWrapper() {
  const {
    userStore: { userSession, logout, logoutAs, isLoggedIn },
  } = useStore();
  const [user, setUser] = useState<HeaderModel>();

  const adminRoles = ["entegralsuperuser", "groupadministrator", "officeadministrator"];

  useEffect(() => {
    //console.log("inside HeaderWrapper useEffect, userSession=", userSession);
    // apiAgent.Home.getHeaderDetails()
    //   .then((response: HeaderModel) => {
    //     let oUser: HeaderModel = response;
    //     setUser(oUser);

    //     console.log("user", oUser);
    //   })
    //   .catch((error: any) => {
    //     console.log(error);
    //   })
  }, []);

  return (
    <>
      <Header
        displayname={userSession?.displayName}
        super_access={userSession?.role == "EntegralSuperUser"}
        IsReadOnly={userSession?.role == "StandardUserViewOnly"}            
        systemlogo={userSession?.systemLogo}
        userid={userSession?.userId}
        loginas={userSession?.isLoginAs}
        logout={logout}
        logoutAs={logoutAs}        
        OnShowStart={userSession?.headerValues?.onShowStart}
        OnShowEnd={userSession?.headerValues?.onShowEnd}
        DiaryActionTags={userSession?.headerValues?.diaryActionTags}
        Modules={userSession?.enabledModules}
        IsAdmin={userSession?.role ? adminRoles.includes(userSession.role.toLowerCase()) : false}
      />
    </>
  );
});
