import React, { useEffect } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSSendMessage } from "../../../app/common/common-components/OMSSendMessage";

function Message(props: any) {
  const [sGridData, setsGridData] = React.useState<any[]>([]);
  const mContact = props.contact!.mContact;
  let userID = props.contact!.userID;
  let contactID = props.contact!.contactID;
  let contactName = props.contact!.firstName + " " + props.contact!.lastName;
  const sendMessageRef = React.createRef<any>();

  useEffect(() => {
    //console.log("MessageTab useEffect", props);
    refreshMessageGrid(null);
  }, []);

  const onOpenSendSMSClickByType = (type: any, parentMessageID: any) => {
    if (props.contact!.contactType !== "1") contactName = props.contact!.companyName;
    sendMessageRef.current.onOpen(userID, type, parentMessageID, contactID, contactName);
  };

  const onOpenSendSMSClick = (e: any) => {
    onOpenSendSMSClickByType(1, "");
  };

  const refreshMessageGrid = (e: any) => {
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Contacts/GetContactMsgGridAjax?contactID=' + this.state.contactID, true);
        xhr.onload = function () {
          var data = JSON.parse(xhr.responseText);
          this.setState({ sGridData: data });
        }.bind(this);
        xhr.send(); */
    apiAgent.Contact.getContactMsgGridAjax(contactID)
      .then((response: any) => {
        setsGridData(response);
      })
      .catch((error: any) => toast.info("Fail"));
  };

  const onSendMessageSuccess = (e: any) => {
    refreshMessageGrid(e);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {props.contact!.allowedDialingCodes.indexOf(mContact.phone_dialing_code) != -1 ? (
            <div className="form-group">
              {props.contact!.actions!.indexOf("save") < 0 ? null : (
                <button type="button" style={{ marginRight: 10, marginTop: 5 }} className="btn btn-success-oms" onClick={onOpenSendSMSClick}>
                  New SMS
                </button>
              )}
              {/*<OMSButtonStyleL style={{ marginRight: 10, marginTop: 5 }} type="button" ui_icon="" ui_type="save" ui_text="New Whatsapp" ui_processing_text="" processing={false} disabled={false} onClick={this.onOpenSendWhatsappClick} />*/}
              {/*<OMSButtonStyleL style={{ marginRight: 10, marginTop:5 }} type="button" ui_icon="" ui_type="save" ui_text="New Email" ui_processing_text="" processing={false} disabled={false} onClick={this.onOpenSendSMSClick} /> */}
            </div>
          ) : (
            <div className="form-group">
              <span>Note: Send SMS function for contact's number available soon</span>
            </div>
          )}
        </div>
      </div>
      <div className="row" style={{ marginTop: 20 }}>
        <div style={{ maxHeight: 450, overflowY: "auto" }}>
          {sGridData.map(function (item: any) {
            return (
              <div
                className="col-md-12"
                style={{
                  padding: "15px 30px 8px 10px",
                  cursor: "pointer",
                  borderBottom: "1px solid #e6e6e6",
                }}
                key={item.MessageID}
                onClick={() => onOpenSendSMSClickByType(item.type, item.parentMessageID)}
              >
                <div className="row">
                  <div className="col-md-6 col-sm-8 col-xs-12">
                    <div className="ellipsis-container">
                      <div className="ellipsis-div">
                        <i
                          className={item.type == 1 ? "fa fa-commenting" : item.type == 2 ? "fa fa-whatsapp" : "fa fa-envelope"}
                          aria-hidden="true"
                          style={{ marginRight: 8 }}
                        ></i>
                        {item.isNew ? <span className="label label-danger">New</span> : null}
                        <span style={{ marginLeft: 2 }}>{item.message}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-4 hidden-xs">
                    <span>
                      {item.route != "Sent" ? (
                        <i className="fa fa-reply" aria-hidden="true" style={{ marginRight: 10 }}></i>
                      ) : (
                        <i className="fa fa-xyz" aria-hidden="true" style={{ marginRight: 30 }}></i>
                      )}
                      {item.dateOn}
                    </span>
                  </div>
                  <div className="col-xs-12 hidden-sm hidden-md hidden-lg">
                    <span className="pull-right" style={{ marginTop: 5 }}>
                      {item.route != "Sent" ? (
                        <i className="fa fa-reply" aria-hidden="true" style={{ marginRight: 10 }}></i>
                      ) : (
                        <i className="fa fa-xyz" aria-hidden="true" style={{ marginRight: 30 }}></i>
                      )}
                      {item.dateOn}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <OMSSendMessage ref={sendMessageRef} ID="CSND" onSuccess={onSendMessageSuccess} />
    </>
  );
}

export default Message;
