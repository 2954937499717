import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { DataTableCompDynamic, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import { TextValueCL } from "../../../app/models/UserModel";

interface AuditTrailGridProps {
  propUserDD: TextValueCL[];
  propOfficeDD: TextValueCL[];
  propOfficeID: string;
  propUserID: string;
  propModuleID: number;
  propObjectID: string;
  propObjectName: string;
}

export const AuditTrailGrid: React.FC<AuditTrailGridProps> = ({
  propUserDD,
  propOfficeDD,
  propOfficeID,
  propUserID,
  propModuleID,
  propObjectID,
  propObjectName,
}) => {
  //const [loading, setLoading] = useState(false);
  const [jsonLoading, setJsonLoading] = useState(false);
  const [jsonData, setJsonData] = useState("");
  const [userId, setUserId] = useState("");
  const [extraParams, setExtraParams] = useState(JSON.stringify({ propOfficeID, UserID: "", propModuleID, propObjectID }));
  const [moduleId, setModuleId] = useState(propModuleID);
  const [objectId, setObjectId] = useState(propObjectID);
  const [officeId, setOfficeId] = useState(propOfficeID);
  const [userDD, setUserDD] = useState(propUserDD);

  const loadGrid = (requestParams: any) => {
    return apiAgent.Setting.getAuditTrailData(requestParams, officeId, userId, objectId, moduleId);
  };

  const syntaxHighlightJson = (obj: any) => {
    let json = JSON.stringify(obj, null, 2);
    json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
          if (match.indexOf("#") > -1) {
            if (match[2] == "0") cls = cls + " item_added";
            else if (match[2] == "1") cls = cls + " item_updated";
            else if (match[2] == "2") cls = cls + " item_deleted";
            match = '"' + match.substr(3, match.length - 3);
          }
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    });
  };

  const CompareJSON = (obj1: any, obj2: any) => {
    const ret: any = {};

    // if object is in obj2 but not in obj1 means Added
    for (const i in obj2) {
      if (!obj1.hasOwnProperty(i)) ret["#0" + i] = obj2[i]; // added
      else if (obj2[i] !== obj1[i]) {
        ret["#1" + i] = obj2[i]; // updated
      } else ret[i] = obj2[i];
    }

    for (const i in obj1) {
      if (!obj2.hasOwnProperty(i)) ret["#2" + i] = obj2[i]; // deleted
    }
    return ret;
  };

  const syntaxHighlightJsonCompare = (obj1: any, obj2: any) => {
    const obj = CompareJSON(obj1, obj2);
    return syntaxHighlightJson(obj);
  };

  const loadDataJson = (data: any) => {
    var data_ref = data.DataRef;
    if (data_ref !== undefined && data_ref != "") {
      var moduleID = data.propModuleID;
      var objectID = data.propObjectID;
      var isShowDiff = data.IsShowDiff;

      setJsonLoading(true);
      setJsonData("");
      window.$("#myJsonModel").modal("show");

      apiAgent.Setting.auditTrailResultAjax(data_ref)
        .then((response) => {
          var obj1 = null;
          var syntaxJson = "";
          if (response.result !== undefined && response.result != "") {
            var obj1 = JSON.parse(response.result);
            if (response.result2 !== undefined && response.result2 != "") {
              var obj2 = JSON.parse(response.result2);
              syntaxJson = syntaxHighlightJsonCompare(obj1, obj2);
            } else {
              syntaxJson = syntaxHighlightJson(obj1);
            }
          }

          setJsonLoading(false);
          setJsonData(syntaxJson);
        })
        .catch((error) => {
          toast.info("Fail");
        });

      if (isShowDiff) {
        apiAgent.Setting.auditTrailResultAjax(data_ref, moduleID, objectID)
          .then((response) => {
            var obj1 = null;
            var syntaxJson = "";
            if (response.result !== undefined && response.result != "") {
              obj1 = JSON.parse(response.result);
              if (response.result2 !== undefined && response.result2 != "") {
                var obj2 = JSON.parse(response.result2);
                syntaxJson = syntaxHighlightJsonCompare(obj1, obj2);
              } else {
                syntaxJson = syntaxHighlightJson(obj1);
              }
            }

            setJsonLoading(false);
            setJsonData(syntaxJson);
          })
          .catch((error) => {
            toast.info("Fail");
          });
      }
    }
  };

  const handleRowClick = (data: any) => {
    loadDataJson(data);
  };

  const handleUserDropdownChange = async (e: any) => {
    await setUserId(e.newValue);
    let varUserId = e.newValue;
    setExtraParams(getExtraParams(officeId, varUserId, moduleId, objectId));
  };

  const getExtraParams = (propOfficeID: any, userID: any, propModuleID: any, propObjectID: any) => {
    return JSON.stringify({
      propOfficeID: propOfficeID,
      userID: userID,
      //propUserID: userID,
      propObjectID: propObjectID,
      propModuleID: propModuleID
    });
  };

  const handleDropdownOfficeChange = async (e: any) => {
    await setOfficeId(e.newValue);
    var propOfficeID = e.newValue;

    apiAgent.Setting.getUsersDDAjax(propOfficeID)
      .then((response) => {
        setUserDD(response);
        setUserId("");

        setExtraParams(getExtraParams(propOfficeID, "", moduleId, objectId));
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  const onRemoveHistoryClick = async (e: any) => {
    await setModuleId(0);
    await setObjectId("");
    setExtraParams(getExtraParams(officeId, userId, 0, ""));
    //console.log("onRemoveHistoryClick", moduleId);
    //console.log("onRemoveHistoryClick", objectId);
  };

  var columns = [
    { data: "date", title: "Date" },
    { data: "user", title: "User" },
    { data: "module", title: "Module" },
    { data: "action", title: "Action" },
    { data: "description", title: "Description" },
    { data: "office", title: "Office" },
  ];

  return (
    <div className="col-md-12">
      <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
        <div className="panel-heading">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="form-inline form-group boxLeftMenu">
                <span>
                  <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                </span>
                <OMSDropdown id="OfficeDD" options={propOfficeDD} valueField="value" labelField="text" value={officeId} onChange={handleDropdownOfficeChange} />
                <OMSDropdown
                  id="UserDD"
                  style={{ marginLeft: 10 }}
                  options={userDD}
                  valueField="value"
                  labelField="text"
                  value={userId}
                  onChange={handleUserDropdownChange}
                />
                {moduleId != 0 && (
                  <span className="form-control" style={{ marginLeft: 10 }}>
                    <i className="fa fa-history"></i>&nbsp;{propObjectName}
                    &nbsp;
                    <i className="fa fa-remove" style={{ color: "#555555", cursor: "pointer" }} onClick={onRemoveHistoryClick}></i>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="panel-body table-responsive">
          <DataTableCompDynamic
            id="contentTable"
            ordering={true}
            columns={columns}
            columnDefs={[]}
            onRowClick={handleRowClick}
            makeAjaxRequest={loadGrid}
            ExtraParams={extraParams}
          />
        </div>
      </div>

      <div className="modal fade" id="myJsonModel" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title text-center" id="myModalLabel">
                Audit Trail Log File
              </h4>
            </div>
            <div className="modal-body">
              <DataTableLoading loading={jsonLoading} />
              {jsonLoading != true && (
                <div>
                  <pre dangerouslySetInnerHTML={{ __html: jsonData }}></pre>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
