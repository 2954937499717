import { Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSNumericBox } from "../../../app/common/common-components/OMSComponent";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { AdminRequestModel } from "../../../app/models/AdminRequestModel";
import { TextValueCL } from "../../../app/models/UserModel";
import { useHistory } from 'react-router-dom';

interface Props {
  officeDD: TextValueCL[];
}

function AgentCreditBox(props: Props) {
  const [officeID, setOfficeID] = useState("");
  const [userID, setUserID] = useState("");
  const [credit, setCredit] = useState(0);
  const [userDD, setUserDD] = useState([]);
  const [processing, setProcessing] = useState(false);

  let history = useHistory();

  const fillUserDD = (officeID: any) => {
    setUserDD([]);
    setUserID("");
    if (officeID) {
      /* var data = new FormData();
      var xhr = new XMLHttpRequest();
      xhr.open('get', '/Admin/GetUsersDDAjax?OfficeID=' + OfficeID, true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        this.setState({ UserDD: data, userID: '' });
      }.bind(this);
      xhr.send(data); */
      apiAgent.Admin.getUsersDDAjax(officeID)
        .then((response) => {
          setUserDD(response);
          setUserID("");
        })
        .catch((error) => {
          toast.info("Fail");
        });
    }
  };

  const handleOfficeIDChange = (e: any) => {
    //console.log("officeID", e.newValue);
    setOfficeID(e.newValue);
    fillUserDD(e.newValue);
  };

  const handleUserIDChange = (e: any) => {
    //console.log("userID", e.newValue);
    setUserID(e.newValue);
  };

  const handleCreditChange = (name: any, value: any) => {
    //console.log("credit", value);
    setCredit(value);
  };

  const onCancelClick = (e: any) => {
    history.push("/Settings");
  };

  const onPerformClick = (e: any) => {
    //console.log("onPerformClick, userID:", userID,  "OfficeID: ",officeID,"Credit: ",credit);
    var isValidated = true;
    

    if (!officeID) {
      toast.info("Please select office");
      isValidated = false;
    }

    if (!userID) {
      toast.info("Please select user");
      isValidated = false;
    }

    if (isValidated) {
      setProcessing(false);
      let values:AdminRequestModel = {
        userID: userID,
        officeID: officeID,
        credit: credit,
      }
      

      apiAgent.Admin.creditAjax(values)
        .then((response) => {
          toast.info(response.message);
          setProcessing(false);
        })
        .catch((error) => {
          toast.info("Fail");
        });
    }
  };

  /* const executePerformClick = (values: AdminRequestModel) => {
    setProcessing(true);
    var validationResult = onPerformClick(values);
    if (validationResult.status == "error") {
      toast.info(validationResult.message);
    }
    else {
      console.log("officeID", officeID);
      console.log("userID", userID);
      console.log("Credit", credit);
      console.log("data", values);
      apiAgent.Admin.creditAjax(values)
        .then((response) => {
          toast.info(response.message);
          setProcessing(false);
        })
        .catch((error) => {
          toast.info('Fail');
        })
    }
    /* var data = new FormData();
    data.append('userID', this.state.userID);
    data.append('officeID', this.state.OfficeID);
    data.append('credit', this.state.Credit);
    var xhr = new XMLHttpRequest();
    xhr.open('post', '/Admin/CreditAjax', true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      $.toaster(data.message, 'Result', 'info');
      this.setState({ processing: false });
    }.bind(this);
    xhr.send(data); */

  return (
    <div>
      <div className="col-sm-12">
        <div className="panel panel-default oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-12">
                <label>Agent Credit</label>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="OfficeID">Office</label>
                <OMSDropdown
                  id="OfficeID"
                  name="officeID"
                  options={props.officeDD}
                  valueField="value"
                  labelField="text"
                  value={officeID}
                  onChange={handleOfficeIDChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="userID">User(Agent)</label>
                <OMSDropdown id="userID" name="userID" options={userDD} valueField="value" labelField="text" value={userID} onChange={handleUserIDChange} />
              </div>
              <div className="form-group">
                <label htmlFor="Credit">Credit(to add)</label>
                <OMSNumericBox id="Credit" name="credit" separator="" maxLength={10} value={credit} isDecimal={false} onChange={handleCreditChange} />
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={onCancelClick}
                  />
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Perform"
                    ui_processing_text="Processing.."
                    processing={processing}
                    disabled={processing}
                    onClick={onPerformClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentCreditBox;
