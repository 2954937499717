// import { Editor } from "@tinymce/tinymce-react";
import React, { Suspense } from "react";
import LoadingComponent from "../../layout/LoadingComponent";

const Editor = React.lazy(() => import("@tinymce/tinymce-react").then((module) => ({ default: module.Editor })));

class TinyMCEEditor extends React.Component {
  editorRef = React.createRef();

  handleEditorChange = (content, editor) => {
    // console.log("Content was updated:", content);
    this.props.onEditorChange(content);
  };

  insertTextAtCursorPosition = (text) => {
    const editor = this.editorRef.current.editor;
    if (editor) {
      const selection = editor.selection;
      if (selection) {
        selection.setContent(text);
      }
    }
  };

  render() {
    return (
      <Suspense fallback={<LoadingComponent />}>
        <Editor
          apiKey="3h1vx9scjhrzi4n9pq9utvrmts2hs4dezbdxh62zuax5nfee"
          value={this.props.value}
          init={{
            height: 600,
            //theme: "modern",
            plugins: [
              "code",
              //"print",
              "preview",
              //"fullpage",
              "searchreplace",
              "autolink",
              "directionality",
              "visualblocks",
              "visualchars",
              "fullscreen",
              "image",
              "link",
              "media",
              "template",
              "codesample",
              "table",
              "charmap",
              //"hr",
              "pagebreak",
              "nonbreaking",
              "anchor",
              //"toc",
              "insertdatetime",
              "advlist",
              "lists",
              //"textcolor",
              "wordcount",
              //"imagetools",
              //"contextmenu",
              //"colorpicker",
              //"textpattern",
              "help",
            ],
            toolbar:
              "code fullpage formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
            image_advtab: true,
            extended_valid_elements: "script[language|type|async|src|charset]",
            images_upload_handler: this.props.handleUpload,
          }}
          onEditorChange={this.handleEditorChange}
          ref={this.editorRef}
        />
      </Suspense>
    );
  }
}

export default TinyMCEEditor;
