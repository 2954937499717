import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { ResetFormValues, ResetModel } from "../../app/models/ResetModel";
import { User, UserFormValues } from "../../app/models/User";
import { useStore } from "../../app/stores/store";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";

interface Props {
  token: string;
}

function ResetBox({ token }: Props) {
  //   console.log("Inside child reset", token);
  //   let displayName = "Test Name";
  //   let emailAddress = "Test Email";

  const [user, setUser] = useState<ResetModel>();

  //const isSuccess = false;
  const defaultUrl = "/";

  const [processing, setProcessing] = useState(false);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);
  const [progressbar_class, setProgressbar_class] = useState("");
  const [progressbar_width, setProgressbar_width] = useState("");
  const [progressbar_text, setProgressbar_text] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const { userStore } = useStore();

  useEffect(() => {
    if (token) {
      setIsLoading(true);

      apiAgent.Account.getUserByResetToken(token)
        .then((response: ResetModel) => {
          let oUser: ResetModel = response;
          setUser(oUser);
          setIsLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [token]);

  const checkPasswordStrength = (e: any) => {
    var password = e.target.value;
    var passed = -1;
    if (password) {
      var regex = new Array();
      regex.push("[A-Z]"); //Uppercase Alphabet.
      regex.push("[a-z]"); //Lowercase Alphabet.
      regex.push("[0-9]"); //Digit.
      regex.push("[$@$!%*#?&]"); //Special Character.

      passed = 0;
      //Validate for each Regular Expression.
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          passed++;
        }
      }

      //Validate for length of Password.
      if (passed > 2 && password.length > 6) {
        passed++;
      }

      if (password.length < 6) {
        if (passed > 2) passed--;
        else passed = 0;
      }
    }

    var progressbar_class = "";
    var progressbar_width = "";
    var progressbar_text = "";
    var password_isstrong = false;
    switch (passed) {
      case -1:
        progressbar_class = "bg-danger";
        progressbar_width = "0%";
        progressbar_text = "";
        break;
      case 0:
      case 1:
        progressbar_class = "bg-danger";
        progressbar_width = "25%";
        progressbar_text = "Weak";
        break;
      case 2:
        progressbar_class = "bg-warning";
        progressbar_width = "50%";
        progressbar_text = "Medium";
        break;
      case 3:
      case 4:
        progressbar_class = "bg-info";
        progressbar_width = "75%";
        progressbar_text = "Strong";
        password_isstrong = true;
        break;
      case 5:
        progressbar_class = "bg-success";
        progressbar_width = "100%";
        progressbar_text = "Very Strong";
        password_isstrong = true;
        break;
    }
    setIsPasswordStrong(password_isstrong);
    setProgressbar_class(progressbar_class);
    setProgressbar_width(progressbar_width);
    setProgressbar_text(progressbar_text);
  };

  const handleSubmit = (values: ResetFormValues) => {
    let { password, confirmPassword } = values;
    let isValidated = true;
    password = password.trim();
    confirmPassword = confirmPassword.trim();
    if (password === "") {
      toast.info("Please enter password");
      isValidated = false;
    }

    if (!isPasswordStrong) {
      toast.info("Please increase password strength. Use a combination of lowercase, uppercase, numerical and special characters");
      isValidated = false;
    }
    if (confirmPassword === "" || password !== confirmPassword) {
      toast.info("Password and confirm password should match.");
      isValidated = false;
    }
    if (isValidated) {
      setProcessing(true);
      values.key = token;
      apiAgent.Account.resetPasswordAjax(values)
        .then((response) => {
          toast.success(response);
          setIsSuccess(true);
        })
        .catch((error: any) => toast.error(error.response))
        .finally(() => setProcessing(false));
    }
  };

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading) {
    return (
      <>
        <div className="row loginbox">
          <div className="col-lg-12 text-center">
            <span className="signIntext">Password Reset</span>
            <br />
            <br />
          </div>
          <div id="content">
            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
                error: null,
              }}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, isSubmitting, errors }) => (
                <Form className="LoginForm" onSubmit={handleSubmit}>
                  <div className="col-sm-12">
                    <br />
                    <label style={{ marginLeft: -10 }}>
                      {user!.displayName}
                      <small style={{ fontWeight: "normal" }}> ({user!.emailAddress})</small>
                    </label>
                    <br />
                    <br />
                  </div>
                  {isSuccess ? (
                    <div>
                      <div className="col-sm-12">
                        <p style={{ marginLeft: 10 }}>Your password has been changed. You can now sign in with your new password.</p>
                        <br />
                      </div>
                      <div className="col-sm-12">
                        <Link to={defaultUrl} className="btn submitButton">
                          Sign In
                        </Link>
                        <br />
                        <br />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className="col-sm-12">
                          <Field type="password" name="password" className="form-control" placeholder="New Password" onKeyUp={checkPasswordStrength} />

                          <div className="progress" style={{ marginTop: 5 }}>
                            <div
                              className={`progress-bar ${progressbar_class}`}
                              role="progressbar"
                              aria-valuenow={100}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{ width: progressbar_width }}
                            >
                              {progressbar_text}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <Field type="password" name="confirmPassword" autoComplete="off" className="form-control" placeholder="Confirm Password" />
                        </div>

                        <div className="col-sm-12">
                          {processing === true ? (
                            <div className="btn submitButton">
                              <span className="fa fa-spin fa-spinner" /> Processing..
                            </div>
                          ) : (
                            <button type="submit" className="btn submitButton">
                              Submit
                            </button>
                          )}
                        </div>

                        <div className="col-sm-12">
                          <div className="text-center">
                            <br />

                            <Link to={defaultUrl}>Sign In</Link>
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
      </>
    );
  }
}

export default ResetBox;
