import React from "react";
import { OMSNumericBox } from "../../../../app/common/common-components/OMSComponent";
import { OMSGeoMap } from "../../../../app/common/common-components/OMSGeoLocation";

class SaveModelModal extends React.Component {
  componentDidMount() {
    window.$('#SaveModel').on('shown.bs.modal', () => {
      this.props.geoHereMapRef.current.resizeMap();
    });
  }

  handleSubmit = () => {
    const {
      state,
      handleModelSubmit
    } = this.props;

    handleModelSubmit({
      name: state.MName,
      geoLat: state.MGeoLat,
      geoLong: state.MGeoLong,
      postalCode: state.MPostalcode,
      mode: state.MMode,
      provinceID: state.MProvinceID,
      townID: state.MTownID,
      suburbID: state.MSuburbID,
      country: state.CountryName,
    });
  }

  render() {
    const {
      geoHereMapRef,
      state,
      handleChange,
      handleNumericChange,
      onGetLocationClick,
      onHereMapLocationChange,
      getModelLabel,
    } = this.props;

    return (
      <div className="modal fade" id="SaveModel" tabIndex={-1} role="dialog" aria-labelledby="SaveModelLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
              <h4 className="modal-title" id="SaveModelLabel">
                {getModelLabel()}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="MName">Name</label>
                  <input type="text" name="MName" className="form-control required-control" value={state.MName} onChange={handleChange} />
                  <div style={{ marginTop: 5 }}>
                    <button type="button" className="btn btn-default" onClick={onGetLocationClick}>
                      Get Location
                    </button>
                  </div>
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label htmlFor="MGeoLat">Geolocation Latitude</label>
                  <OMSNumericBox
                    isDecimal={true}
                    id="MGeoLat"
                    name="MGeoLat"
                    maxLength={20}
                    style={{ width: "150px" }}
                    value={state.MGeoLat}
                    placeholder="latitude"
                    onChange={handleNumericChange}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label htmlFor="MGeoLong">Geolocation Longitude</label>
                  <OMSNumericBox
                    isDecimal={true}
                    id="MGeoLong"
                    name="MGeoLong"
                    maxLength={20}
                    style={{ width: "150px" }}
                    value={state.MGeoLong}
                    placeholder="longitude"
                    onChange={handleNumericChange}
                  />
                </div>
                {state.MMode !== 3 ? null : (
                  <div className="form-group col-md-12 col-sm-12">
                    <label htmlFor="MPostalcode">Postal code</label>
                    <input type="text" name="MPostalcode" className="form-control text-100" value={state.MPostalcode} onChange={handleChange} />
                  </div>
                )}
                <br />
                <div className="col-md-12">
                  <OMSGeoMap ref={geoHereMapRef} onGeoMapChange={onHereMapLocationChange} />
                </div>
                <br />
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>
                <button type="button" onClick={this.handleSubmit} className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SaveModelModal;
