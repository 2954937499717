import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import apiAgent from "../../api/apiAgent";
import { OMSButtonStyleL } from "./OMSButton";
import { OMSPurchaseCredit } from "./OMSPurchaseCredit";
import { Link } from 'react-router-dom';

export class OMSSendMessage extends React.Component {
  static propTypes = {
    ID: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
  };

  state = {
    sParentMessageID: "",
    sMessageText: "",
    sMessageView: 0, //0:New SMS, 1: View SMS
    sData: [],
    sSending: false,
    sType: 1,
    sContactID: "",
    sContactName: "",
    uCredit: 0,
    sUserID: "",
    uInboxCount: 0,
  };

  constructor(props) {
    super(props);
    this.purchaseCreditRef = React.createRef();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onOpen = (userID, Type, ParentMessageID, contactID, contactName) => {
    //console.log("inside onOpen of OMSSendMessage", userID, Type, ParentMessageID, contactID, contactName);
    var MessageView = 0;
    if (ParentMessageID) MessageView = 1;
    this.setState(
      {
        sUserID: userID,
        sMessageText: "",
        sMessageView: MessageView,
        sSending: false,
        sType: Type,
        sParentMessageID: ParentMessageID,
        sData: [],
        sContactID: contactID,
        sContactName: contactName,
        uCredit: 0,
      },
      function () {
        this.getMessageData();
        window.$("#sendSMSModel-" + this.props.ID).modal("show");
      }.bind(this)
    );
  };

  getMessageData = () => {
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Contacts/GetContactMsgAllAjax?contactID=' + this.state.sContactID + '&ParentMessageID=' + this.state.sParentMessageID + '&Type=' + this.state.sType, true);
        xhr.onload = function () {
            var result = JSON.parse(xhr.responseText);
            if (result.contactID)
                this.setState({ sData: result.data, uCredit: result.credit, sContactID: result.contactID, sContactName: result.contactName, uInboxCount: result.InboxCount });
            else
                this.setState({ sData: result.data, uCredit: result.credit, uInboxCount: result.InboxCount });
        }.bind(this);
        xhr.send(); */
    apiAgent.Contact.getContactMsgAllAjax(this.state.sContactID, this.state.sType, this.state.sParentMessageID)
      .then((response) => {
        //console.log("inside getMessageData of OMSSendMessage", response);
        if (response.contactID) {
          //console.log("inside getMessage when contactID is present");
          this.setState({
            sData: response.data,
            uCredit: response.credit,
            sContactID: response.contactID,
            sContactName: response.contactName,
            uInboxCount: response.inboxCount,
          });
        } else {
          //console.log("inside getMessage when contactID is not present");
          this.setState({
            sData: response.data,
            uCredit: response.credit,
            uInboxCount: response.inboxCount,
          });
        }
      })
      .catch((error) => toast.error(error.response.data));
  };

  onSendSMSClick = (e) => {
    if (!this.state.sMessageText) {
      toast.info("Message field can't be empty");
      return;
    }

    if (this.state.uCredit <= 0) {
      toast.info("You don't have enough message credit. Please purchase.");
      return;
    }

    var data = new FormData();
    data.append("contactID", this.state.sContactID);
    data.append("userID", "");
    data.append("message", this.state.sMessageText);
    data.append("officeID", "");
    data.append("parentMessageID", this.state.sParentMessageID);
    data.append("type", this.state.sType);

    this.setState({ sSending: true });
    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Contacts/SendSMSAjax', true);
        xhr.onload = function () {
            this.setState({ sSending: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                window.$('#sendSMSModel-' + this.props.ID).modal('hide');
                if (this.props.onSuccess)
                    this.props.onSuccess();
            }
            else {
                window.$.toaster(data.message, 'Fail', 'danger');
            }
        }.bind(this);
        xhr.send(data); */
    const formDataObj = Object.fromEntries(data.entries());
    apiAgent.Contact.sendSMSAjax(formDataObj)
      .then((response) => {
        window.$("#sendSMSModel-" + this.props.ID).modal("hide");
        if (this.props.onSuccess) this.props.onSuccess();
      })
      .catch((error) => toast.error(error.response.data))
      .finally(() => {
        this.setState({ sSending: false });
      });
  };

  onMarkAsReadClick = (e) => {
    //var data = new FormData();
    //data.append("contactID", this.state.sContactID);
    //data.append("parentMessageID", this.state.sParentMessageID);
    //data.append("type", this.state.sType);

    /* var xhr = new XMLHttpRequest();
        xhr.open('post', '/Contacts/ContactMsgMarkReadAjax', true);
        xhr.onload = function () {
            var result = JSON.parse(xhr.responseText);
            if (result.InboxCount && result.InboxCount != 0) {
                var sData = this.state.sData;
                for (var i = 0; i < sData.length; i++) {
                    sData[i].IsNew = false;
                }
                this.setState({ uInboxCount: result.InboxCount, sData: sData });
            }
        }.bind(this);
        xhr.send(data); */
    apiAgent.Contact.contactMsgMarkReadAjax(this.state.sContactID, this.state.sType, this.state.sParentMessageID)
      .then((response) => {
        if (response.inboxCount && response.inboxCount != 0) {
          var sData = this.state.sData;
          for (var i = 0; i < sData.length; i++) {
            sData[i].IsNew = false;
          }
          this.setState({ uInboxCount: response.inboxCount, sData: sData });
        }
      })
      .catch((error) => toast.error(error.response.data));
  };

  onPurchaseClick = (e) => {
    this.purchaseCreditRef.current.onOpen(this.state.sUserID);
  };

  onCloseClick = (e) => {
    window.$("#sendSMSModel-" + this.props.ID).modal("hide");
    if (this.state.uInboxCount > 0) {
      if (this.props.onSuccess) this.props.onSuccess();
    }
  };

  render() {
    var self = this;
    return (
      <div>
        <div className="modal fade" id={"sendSMSModel-" + this.props.ID} tabIndex={-1} aria-labelledby="sendSMSModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.onCloseClick} aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id="sendSMSModelLabel">
                  <i className={this.state.sType == 1 ? "fa fa-commenting" : "fa fa-whatsapp"}></i>
                  &nbsp;Send {this.state.sType == 1 ? "SMS" : "Whatsapp"} message to{" "}
                  {/* <a href={"/Contacts/SaveContact?ID=" + this.state.sContactID} target="_black">
                    {this.state.sContactName}
    </a> */}
          <Link to={`/Contacts/SaveContact?ID=${this.state.sContactID}`} target="_blank">
            {this.state.sContactName}
          </Link>
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        id="sMessageText"
                        name="sMessageText"
                        maxLength={this.state.sType == 1 ? "160" : "1000"}
                        value={this.state.sMessageText}
                        placeholder="Type in your message"
                        rows="5"
                        onChange={this.handleChange}
                      ></textarea>
                      {this.state.sType == 1 ? (
                        <h6 className="pull-left" id="count_message">
                          {160 - this.state.sMessageText.length} characters left
                        </h6>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <div className="pull-right">
                        {this.state.uInboxCount == 0 ? null : (
                          <OMSButtonStyleL
                            style={{ marginTop: 0, marginRight: 10 }}
                            type="button"
                            ui_icon=""
                            ui_type="save"
                            ui_text=" Mark Read "
                            ui_processing_text="Marking"
                            processing={this.state.sSending}
                            disabled={this.state.sSending}
                            onClick={this.onMarkAsReadClick}
                          />
                        )}
                        <OMSButtonStyleL
                          style={{ marginTop: 0 }}
                          type="button"
                          ui_icon=""
                          ui_type="save"
                          ui_text=" Send "
                          ui_processing_text="Sending"
                          processing={this.state.sSending}
                          disabled={this.state.sSending}
                          onClick={this.onSendSMSClick}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 col-xs-12">
                    {this.state.sMessageView == 1 ? (
                      <div className="form-group" style={{ overflowY: "auto" }}>
                        {this.state.sData.map(function (item) {
                          return (
                            <div style={{ marginTop: 20, fontSize: 13 }} key={item.messageID}>
                              <p>
                                <span className="label label-danger" style={{ marginRight: 5, fontSize: 13 }}>
                                  {item.dateOn}
                                </span>
                                <span>
                                  <small>{item.route}</small>
                                </span>
                              </p>
                              <p style={{ fontSize: 13 }}>
                                {" "}
                                {item.isNew ? <span className="label label-danger">New</span> : null}
                                {item.message}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <hr></hr>
                    <span id="credit_message">
                      <i className="fa fa-info-circle" style={{ fontSize: 16 }} aria-hidden="true"></i> Message credit left: <b>{this.state.uCredit}</b>
                    </span>
                    <a href="#" style={{ fontSize: 16, marginLeft: 10 }} onClick={this.onPurchaseClick}>
                      purchase
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OMSPurchaseCredit ref={this.purchaseCreditRef} ID="SendMessageUC" />
      </div>
    );
  }
}
