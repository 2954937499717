import { useStore } from "../../../../app/stores/store";
import HeaderWrapper from "../../../header/HeaderWrapper";
import Breadcrumb from "../../../../app/common/common-components/Breadcrumb";
import OfficeInsightsSummary from "./OfficeInsightsSummary";


export default function OfficeInsightsPage() {
  const {
    userStore: { userSession },
  } = useStore();

  document.title = "Base - Office Insights Usage";

  return (
    <>
      <HeaderWrapper />
      <div className="container-fluid">
        <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Office Insights Usage" },
            ]}
          />
        <div className="row">
          <div className="hidden-sm col-md-2"></div>
          <div id="content" className="col-sm-12 col-md-12">            
            <OfficeInsightsSummary />
          </div>
          <div className="hidden-sm col-md-2"></div>
        </div>
      </div>
    </>
  );
}
