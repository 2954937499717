import React, { useEffect } from "react";
import OfficesDropdown from "./OfficesDropdown";

type Props = {
  OfficeDD: [];
  officeSelectionChanged: (value: string) => void;
};

const GridPanelHeading: React.FC<Props> = (props) => {
  useEffect(() => {
    //console.log("Inside Grid Panel Heading useEffect, officeDD=", props.OfficeDD);
  }, [props.OfficeDD]);

  return (
    <div className="panel-heading">
      <div className="row">
        <div className="col-xs-12">
          <div className="form-inline form-group boxLeftMenu">
            {props.OfficeDD && props.OfficeDD.length > 1 && (
              <>
                <span>
                  <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                </span>
                <OfficesDropdown officeDD={props.OfficeDD} officeSelectionChanged={props.officeSelectionChanged} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridPanelHeading;
