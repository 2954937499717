import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSMultiselectDropdown } from "../../../app/common/common-components/OMSMultiselectDropdown";
import { useStore } from "../../../app/stores/store";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import moment from "moment";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";


const TaskModel = forwardRef((props, ref) => {
  const defaultTaskModelData = () => {
    return {
      taskId: "",
      taskName: "",
      dealId: "",
      dealName: "",
      stageId: "",
      stageName: "",
      existingStageId: "",
      status: 1,
      statusUpdatedOn: "",
      stageExpected: "",
      taskCompletedDate: "",
      description: "",
      taskDueDate: "",
      comments: [],
      assignedRoles: [],
      stageDD: [],
      taskIndex: 0,
      emailNotifiedRoles: [],
    };
  };

  const defaultConfirmationModalProps = () => {
    return {
      showModal: false,
      isConfirmed: (result) => { },
      title: "",
      body: "",
    };
  };

  useImperativeHandle(ref, () => ({
    onModelClick(stage, curTask) {
      const roles = Array.isArray(curTask?.assignedRoles)
        ? curTask?.assignedRoles?.map((curRole) => curRole?.assignedRole)
        : [];

      var mTaskModel = defaultTaskModelData();

      mTaskModel.taskId = curTask?.taskId;
      mTaskModel.dealId = curTask?.dealId;
      mTaskModel.dealName = curTask?.dealName;
      mTaskModel.taskName = curTask?.taskName;
      mTaskModel.stageId = curTask?.stageId ?? stage?.value;
      mTaskModel.stageName = curTask?.stageName ?? stage?.text;
      mTaskModel.existingStageId = curTask?.stageId ?? stage?.value;
      mTaskModel.statusUpdatedOn = curTask?.statusUpdatedOn;
      mTaskModel.stageExpected = curTask?.stageExpected;
      mTaskModel.taskCompletedDate = curTask?.taskCompletedDate;
      mTaskModel.status = curTask?.status;
      mTaskModel.description = curTask?.description;
      mTaskModel.taskDueDate = curTask?.taskDueDate;
      mTaskModel.comments = curTask?.comments;
      mTaskModel.assignedRoles = [...roles];
      mTaskModel.stageDD = curTask?.stageDD;
      mTaskModel.taskIndex = curTask?.taskIndex;
      mTaskModel.emailNotifiedRoles = curTask?.emailNotifiedRoles;


      setTaskModelData(mTaskModel);
      setFile(null);
      setComment("");
      setCommentIndex(-1);

      setButtonStatus({
        disabled: false,
        processing_save: false,
        processing_delete: false,
      });
      window.$(`#${props.modelId}`).modal("show");
    },
    onModelHide() {
      setTaskModelData(defaultTaskModelData());
      window.$(`#${props.modelId}`).modal("hide");
    },
  }));

  const fileInputRef = useRef(null);
  const [taskModelData, setTaskModelData] = useState(defaultTaskModelData());
  const [buttonStatus, setButtonStatus] = useState({
    disabled: false,
    processing_save: false,
    processing_delete: false,
  });

  const [commentIndex, setCommentIndex] = useState(-1);
  const [comment, setComment] = useState("");
  const [file, setFile] = useState(null);
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps()
  );
  const [isChanged, setIsChanged] = useState(false);

  const {
    userStore: { userSession },
  } = useStore();

  useEffect(() => {
    //console.log("useEffect >> ");
    //console.log(taskModelData);
  }, [taskModelData]);

  const taskModelDataChangeHandle = (name, e) => {
    if (name == "TaskName") {
      setTaskModelData({ ...taskModelData, taskName: e.target.value });
      setIsChanged(true);
    } else if (name == "Desciption") {
      setTaskModelData({ ...taskModelData, description: e.target.value });
      setIsChanged(true);
    } else if (name == "Stage") {
      setTaskModelData({
        ...taskModelData,
        stageId: e.newValue,
        stageName: e.newText,
      });
      setIsChanged(true);
    } else if (name == "AssignedRoles") {
      setTaskModelData({ ...taskModelData, assignedRoles: e });
      setIsChanged(true);
    } else if (name == "DueDate") {
      setTaskModelData({ ...taskModelData, taskDueDate: e.target.value });
      setIsChanged(true);
    }
  };

  // Save Handles
  const onTaskSave = () => {
    if (
      taskModelData.taskName.trim().length == 0 ||
      taskModelData.taskName == "" ||
      !taskModelData.taskName
    )
      return toast.warn("TaskName is required");
    if (file != null)
      return toast.warn(
        "There is a file in the comment section. You need to save the comment to save that file."
      );

    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    excuteTaskSaveAjax(taskModelData);
  };

  // Task Status DD Handle
  const handleStatusDD = (e) => {
    var statusVal = e.newValue;
    if (statusVal == 5) {
      // Mark as Deleted
      var md = {
        showModal: true,
        isConfirmed: (result) => {
          if (result) {
            setConfirmationModalProps(defaultConfirmationModalProps());
            var modelData = { ...taskModelData, status: statusVal };
            setTaskModelData(modelData);
            if (taskModelData.taskId) {
              excuteTaskSaveAjax(modelData, false);
            }
          } else {
            setConfirmationModalProps(defaultConfirmationModalProps());
          }
        },
        title: "Delete Task",
        body:
          "Are you sure you want to delete this Task? This cannot be undone.",
      };
      setConfirmationModalProps(md);
    } else {
      var modelData = { ...taskModelData, status: statusVal };
      if (taskModelData.taskId) {
        let commentSmgValue = getTaskMilestoneStatusDD(statusVal).find(
          (curStatus) => {
            return curStatus.value == statusVal;
          }
        );
        let commentSmg =
          userSession?.displayName +
          " marked this task as " +
          commentSmgValue.text;
        let eleComment = getCommentObject(commentSmg, null);
        eleComment.isStatusChanged = true;
        modelData.comments.push(eleComment);
        setTaskModelData(modelData);
        excuteTaskSaveAjax(modelData, true);
      } else {
        setTaskModelData(modelData, true);
      }
    }
  };

  const excuteTaskSaveAjax = (modelData, isKeepOpen) => {
    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    var requestModel = { ...modelData };
    requestModel.stageDD = [];
    requestModel.assignedRoles = requestModel.assignedRoles?.map((curEle) => {
      let agentName = [];
      props.contacts?.map((curContact) => {
        if (curContact.contactType == curEle) {
          agentName.push({
            text: curContact.displayName,
            value: curContact.id,
          });
        }
      });
      return { assignedRole: curEle, name: agentName };
    });

    var existingStageId = requestModel.existingStageId;
    var currentStageId = requestModel.stageId;

    apiAgent.Deal.saveTaskAjax(requestModel)
      .then((res) => {
        var responseTaskModel = res.taskModel;
        var responseDoucmentModel = res.documentModel;

        var isNewTask = false;
        if (!requestModel.taskId) {
          isNewTask = true;
          requestModel.taskId = responseTaskModel.taskId;
          requestModel.existingStageId = responseTaskModel.stageId;
          requestModel.assignedRoles = requestModel.assignedRoles?.map(
            (curRole) => {
              return curRole.assignedRole;
            }
          );
          setTaskModelData(requestModel);
          // Handle Event For Milestone Change  
          
          if(props.onChangeDealTaskMilestone){
            props.onChangeDealTaskMilestone(res.dealStage);
          }
          toast.info("Task created successfully.");
          
        } else {
          if (requestModel.status == 5)
            toast.info("Task deleted successfully.");
          else {
            // Handle Event For Milestone Change            
            if(props.onChangeDealTaskMilestone){
              props.onChangeDealTaskMilestone(res.dealStage);
            }
            toast.info("Task updated successfully.")
          };
        }

        if (!isKeepOpen) {
          window.$(`#${props.modelId}`).modal("hide");
          setIsChanged(false);
        }

        // ON SAVE
        props.onSave(
          requestModel.status == 5 ? requestModel : responseTaskModel,
          isNewTask,
          existingStageId,
          currentStageId,
          responseDoucmentModel
        );

        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.info("Something Went Wrong");
      });
  };

  const convertDateTimeFormate = (dateStr) => {
    if (!dateStr) {
      return "";
    }
    // Split the date string into day, month, and year
    const [day, month, year] = dateStr.split('-').map(Number);

    // Validate the date parts
    if (isNaN(day) || isNaN(month) || isNaN(year) || day < 1 || month < 1 || month > 12 || day > 31) {
      return '';
    }

    // Create a Date object (Note: month is 0-based in JavaScript Date)
    const date = new Date(year, month - 1, day);

    // Check if the date is valid
    if (date.getDate() !== day || date.getMonth() !== month - 1 || date.getFullYear() !== year) {
      return '';
    }

    // Define month and day names
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const dayNames = [
      'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ];

    // Get the day and month names
    const dayName = dayNames[date.getDay()];
    const monthName = monthNames[date.getMonth()];

    // Format the date
    return `${dayName} ${day} ${monthName.substring(0, 3)}`;
  };

  const onFileUploadClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    //console.log("Initial : ", taskModelData.assignedRoles);

  }, [])
  const getCommentObject = (comment, fileData) => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString("en-GB").replace(/\//g, "-");
    return {
      commentID: "",
      userID: props.userID,
      comment: comment,
      isStatusChanged: false,
      createdOn: formattedDate,
      updatedOn: "",
      displayName: userSession?.displayName,
      fileID: fileData == null ? "" : fileData.fileID,
      fileName: fileData == null ? "" : fileData.fileName,
      documentName: fileData == null ? "" : fileData.documentName,
      url: "",
      size: fileData == null ? 0 : fileData.size,
      displaySize: "",
    };
  };

  const onCommentRemove = (index) => {
    let comments = taskModelData.comments.filter((curCom, curIdx) => {
      return index != curIdx;
    });
    var modelData = { ...taskModelData, comments: comments };
    setTaskModelData(modelData);
    if (taskModelData.taskId) excuteTaskSaveAjax(modelData, true);
  };

  const onCommentSaveClick = async () => {
    if (!comment) return toast.warn("Please enter a comment");

    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    let comments = [...taskModelData.comments];
    if (commentIndex < 0) {
      if (file) {
        const fileData = await fileUploadHandle();
        let eleComment = getCommentObject(comment, fileData);
        //console.log(eleComment);
        comments.push(eleComment);
      } else {
        let eleComment = getCommentObject(comment, null);
        comments.push(eleComment);
        //console.log(eleComment);
      }
    } else {
      let selectedComment = comments[commentIndex];
      //console.log(selectedComment);
      selectedComment.comment = comment;
    }

    var modelData = { ...taskModelData, comments: comments };
    setTaskModelData(modelData);
    setComment("");
    setCommentIndex(-1);
    setFile(null);
    excuteTaskSaveAjax(modelData, true);
  };

  // File Upload Handles
  const fileChangeHandle = (e) => {
    setFile(e.target.files[0]);
    e.target.value = "";
  };

  const fileUploadHandle = async () => {
    //console.log("fileUploadHandle");
    //console.log(file);
    let fileData;
    var data = new FormData();
    data.append("file", file);
    await apiAgent.Deal.saveDealDocumentAjax(props.syncId, data).then((res) => {
      //console.log(res);
      fileData = res;
      //console.log("Inside saveDealDocumentAjax");
      //console.log(fileData);
    });
    setFile(null);
    //console.log("Outside saveDealDocumentAjax");
    //console.log(fileData);
    return { ...fileData, size: file.size };
  };

  const onDownloadFile = (fileName) => {
    apiAgent.Deal.downloadDealDocumentAjax(fileName, props.syncId)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Failed to download file:", error);
      });
  };

  // Other Functions
  const displayDateAndTime = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const now = new Date();

    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    let hours = now.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateAndTime = `${day} ${month} ${year} at ${hours}:${minutes} ${ampm}`;

    return formattedDateAndTime;
  };

  const getTaskMilestoneStatusDD = (value) => {
    var mStatusDD = [
      {
        text: "Not Started",
        value: 1,
      },
      {
        text: value == 2 ? "In Progress" : "Mark As In Progress",
        value: 2,
      },
      {
        text: value == 3 ? "Issue" : "Mark As Issue",
        value: 3,
      },
      {
        text: value == 4 ? "Completed" : "Mark As Completed",
        value: 4,
      },
    ];
    if (taskModelData.taskId) {
      mStatusDD.push({
        text: "Delete Task",
        value: 5,
      });
    }
    return mStatusDD;
  };

  const OnDealBreadcrumbClick = (e) => {
    //console.log("OnDealBreadcrumbClick");
    window.$(`#${props.modelId}`).modal("hide");
  };

  const onTaskModelHide = (e) => {
    //console.log("Close Model");
    window.$(`#${props.modelId}`).modal("hide");
  };

  const emailTagHandler = (e) => {
    if (e.target.checked) {
      setIsChanged(true);
      setTaskModelData({
        ...taskModelData,
        emailNotifiedRoles: [
          ...taskModelData.emailNotifiedRoles,
          e.target.value,
        ],
      });
    } else {
      let tags = taskModelData.emailNotifiedRoles.filter((tag) => {
        return tag != e.target.value;
      });
      setIsChanged(true);
      setTaskModelData({ ...taskModelData, emailNotifiedRoles: tags });
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id={`${props.modelId}`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={`${props.modelId}`}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title text-center" id="">
                Save Task
              </h4>
            </div>
            <div className="modal-body">
              {props.isBreadCrumb === true && (
                <Breadcrumb
                  breadcrumbs={[
                    {
                      text: taskModelData.dealName,
                      onClick: OnDealBreadcrumbClick,
                      path: `/Deal/saveDeal?ID=${taskModelData?.dealId}`,
                      style: { color: "black", fontWeight: "bold" },
                    },
                    {
                      text: taskModelData.taskName,
                      style: {
                        borderBottom: "1px solid black",
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                      },
                    },
                  ]}
                />
              )}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Task Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={taskModelData.taskName}
                      onChange={(e) => taskModelDataChangeHandle("TaskName", e)}
                      disabled={props.accessType == 1 ? false : true}
                    />
                  </div>
                  <div className="task_inp_container justify-content-between">
                    <div className="form-group" style={{ minWidth: "48%" }}>
                      <label>Stage</label>
                      <OMSDropdown
                        id={"stage"}
                        options={props.tasksStageDD}
                        valueField="value"
                        labelField="text"
                        value={taskModelData.stageId}
                        onChange={(e) => taskModelDataChangeHandle("Stage", e)}
                        disabled={props.accessType == 1 ? false : true}
                      />
                    </div>
                    <div
                      className={`form-group bg${taskModelData.status}status`}
                      style={{ minWidth: "48%" }}
                    >
                      <label>Status</label>
                      <OMSDropdown
                        id={"status"}
                        options={getTaskMilestoneStatusDD(taskModelData.status)}
                        valueField="value"
                        labelField="text"
                        value={taskModelData.status}
                        onChange={(e) => handleStatusDD(e)}
                        disabled={props.accessType == 1 ? false : true}
                      />
                    </div>
                  </div>
                  <div className="task_inp_container justify-content-between">
                    <div className="form-group" style={{ minWidth: "48%" }}>
                      <label>Assigned Roles </label>
                      {props?.accessType == 1 ? (
                        <OMSMultiselectDropdown
                          id="roles"
                          name="Choose some options..."
                          options={props?.assignedRolesDD || []}
                          valueField="value"
                          labelField="text"
                          value={taskModelData?.assignedRoles}
                          onChange={(e) =>
                            taskModelDataChangeHandle("AssignedRoles", e)
                          }
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          value={taskModelData?.assignedRoles.map(
                            (curEle, i) => {
                              if (i == 0) {
                                return `${curEle}`;
                              }
                              return `, ${curEle}`;
                            }
                          )}
                          disabled
                        />
                      )}
                    </div>
                    <div className="form-group" style={{ minWidth: "48%" }}>
                      <label>Due Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={taskModelData.taskDueDate}
                        onChange={(e) =>
                          taskModelDataChangeHandle("DueDate", e)
                        }
                        disabled={props.accessType == 1 ? false : true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      rows="2"
                      value={taskModelData.description}
                      onChange={(e) =>
                        taskModelDataChangeHandle("Desciption", e)
                      }
                      disabled={props.accessType == 1 ? false : true}
                    ></textarea>
                  </div>
                  {taskModelData.taskId && (
                    <div>
                      <label>Comments</label>
                      <div className="comment_box mt-1" style={{ maxHeight: 300, overflowY: "auto" }}>
                        <ul>
                          {taskModelData?.comments?.map((curComent, i) => {
                            if (curComent.isStatusChanged === false) {
                              return (
                                <li
                                  key={i}
                                  className="comment_item align-items-start"
                                >
                                  <div className="com_info px-2">
                                    <span>
                                      <b>
                                        {" "}
                                        {convertDateTimeFormate(
                                          curComent.createdOn
                                        )}{" "}
                                        : {userSession?.displayName}{" "}
                                      </b>
                                    </span>
                                  </div>
                                  <div className="com_data bgcomment">
                                    <div className="d-flex flex-column">
                                      <span>{curComent.comment}</span>
                                      {curComent.documentName && (
                                        <div className="mt-1">
                                          <span
                                            className="tag2 bg-oms"
                                            title="open/download file"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              onDownloadFile(curComent.fileName)
                                            }
                                          >
                                            {curComent.documentName}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="dropdown">
                                      <a
                                        className="btn btn-secondary dropdown-toggle"
                                        href="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-h"
                                          style={{
                                            color: "black",
                                            fontSize: "17px",
                                          }}
                                        ></i>
                                      </a>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                        style={{ right: 0, float: "right", left: "auto", minWidth: "90px", margin: "-11px 0px 0px" }}
                                      >
                                        <div className="com_actions">
                                          <a
                                            className="model_link mx-1"
                                            onClick={() => {
                                              setComment(curComent.comment);
                                              setCommentIndex(i);
                                            }}
                                          >
                                            Edit
                                          </a>
                                          <a
                                            className="model_link"
                                            onClick={() => onCommentRemove(i)}
                                          >
                                            Delete
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="com_actions">
                                  <a className="model_link mx-1" onClick={() => {
                                    setComment(curComent.comment);
                                    setCommentIndex(i);
                                  }}>
                                    Edit
                                  </a>
                                  <a className="model_link" onClick={() => onCommentRemove(i)}>
                                    Delete
                                  </a>
                                </div> */}
                                </li>
                              );
                            } else if (curComent.isStatusChanged === true) {
                              return (
                                <li
                                  key={i}
                                  className="comment_item align-items-start"
                                >
                                  <div className="com_info p-2">
                                    <span>
                                      <b>
                                        {" "}
                                        {convertDateTimeFormate(
                                          curComent.createdOn
                                        )}{" "}
                                        : {userSession?.displayName}{" "}
                                      </b>
                                    </span>
                                  </div>
                                  <div className="com_data bgstatus">
                                    <span>{curComent.comment}</span>
                                  </div>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  {taskModelData.taskId && (
                    <div className="form-group commetns_area mt-1">
                      <textarea
                        className="form-control"
                        rows="3"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.txt,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx"
                        style={{ display: "none" }}
                        onChange={(e) => fileChangeHandle(e)}
                        multiple={false}
                        ref={fileInputRef}
                      />
                      {file == null ? null : (
                        <p className="tag2 bg-oms" style={{ width: "auto" }}>
                          {file.name}
                        </p>
                      )}
                      <span>
                        <i
                          className="fa fa-paperclip"
                          title="attach file"
                          style={{ fontSize: "22px" }}
                          onClick={() => onFileUploadClick()}
                        ></i>
                        <i
                          className="fa fa-envelope-o"
                          title="add comment"
                          onClick={() => onCommentSaveClick()}
                        ></i>
                      </span>
                    </div>
                  )}
                  {props.superAdmin && (
                    <div className="form-group">
                      <label>Notify on new comment</label>
                      <div className="d-flex flex-wrap">
                        {taskModelData.assignedRoles?.map((curTag, ind) => {
                          return (
                            <div
                              className="d-flex align-items-center mb-1 mr-1"
                              style={{ width: "auto" }}
                              key={ind}
                            >
                              {/* <input
                                type="checkbox"
                                className="my-0 mr-1"
                                value={curTag}
                                checked={taskModelData.emailNotifiedRoles.includes(curTag)}
                                onChange={(e) => emailTagHandler(e)}
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                              <span
                                className={`task_email_tag d-flex align-items-center`}
                                style={{
                                  background:
                                    props.getRoleBg(curTag) || "#4d4d4d",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="my-0 mr-1"
                                  value={curTag}
                                  checked={taskModelData.emailNotifiedRoles.includes(
                                    curTag
                                  )}
                                  onChange={(e) => emailTagHandler(e)}
                                  style={{ width: "20px", height: "20px" }}
                                />
                                {curTag}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => onTaskModelHide()}
                >
                  <i className="fa fa-times"></i> Cancel
                </button>
                {buttonStatus.disabled || !isChanged ? (
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Save"
                    ui_processing_text="Saving.."
                    processing={buttonStatus.processing_save}
                    disabled={buttonStatus.disabled || !isChanged}
                  />
                ) : (
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Save"
                    ui_processing_text="Saving.."
                    processing={buttonStatus.processing_save}
                    disabled={buttonStatus.disabled || !isChanged}
                    onClick={() => onTaskSave()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModalPopup {...confirmationModalProps} />
    </>
  );
});

export default TaskModel;