import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { ContactModel } from "../../app/models/ContactModel";
import { useStore } from "../../app/stores/store";
import Header from "../header/Header";
import HeaderWrapper from "../header/HeaderWrapper";

//import "../../assets/styles/components/_saveContact.scss";
import ContactBox from "./ContactBox";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";


export default observer(function SaveContactPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState<ContactModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let contactId: string = "";
    if (params.has("ID")) {
      //const oContact:ContactModel=new ContactModel();

      const id = params.get("ID");
      contactId = id !== null ? id : "";

      //console.log("Inside useEffect:contact id is",contactId);
    }

    setIsLoading(true);
    apiAgent.Contact.saveContact(contactId, "", "", "")
      .then((response: any) => {
        //console.log("getting contact object", response);
        let oContact: ContactModel = response;
        setContact(oContact);
        //console.log("contact", oContact);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save Contact";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading) {
    return (
      <>
        {/* <Header /> */}
        <HeaderWrapper />
        <div className="save-contact container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Contacts", path: "/contacts" },
              { text: contact?.displayName}
            ]}
          />

          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-md-12 col-md-8">
              <ContactBox contact={contact} />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
