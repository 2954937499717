import React from 'react';

export class OMSGeoLocationMapTool extends React.Component {
  state = {
    ID: '',
    Address: '',
    LatLongString: '',
    LocationLat: null,
    LocationLong: null,
  };

  componentDidMount() {
    window.$('#getLocationModel').off('shown.bs.modal').on('shown.bs.modal', function () {
      if (this.map === undefined) {
        this.loadMap();
        setTimeout(function () {
          if (this.state.Address === '') {
            this.addDraggableMarker(this.state.LocationLat, this.state.LocationLong);
          } else {
            this.searchAddressLocation();
          }
        }.bind(this), 2000);
      } else {
        if (this.state.Address === '') {
          this.addDraggableMarker(this.state.LocationLat, this.state.LocationLong);
        } else {
          this.searchAddressLocation();
        }
      }
    }.bind(this));
  }

  loadMap = () => {
    const H = window.H;
    var mapContainer = document.getElementById('map');

    //const apiKey = 'QJF2sIKVcgIx_0Au3uj74g262vyhqKaE7UwbXSC9jUk'; // Using the provided API key
    var apiKey = 'QJF2sIKVcgIx_0Au3uj74g262vyhqKaE7UwbXSC9jUk';

    if (this.platform === undefined) {
      var platform = new H.service.Platform({
        apikey: apiKey,
      });

      var defaultLayers = platform.createDefaultLayers();
      var geocoder = platform.getGeocodingService();

      var map = new H.Map(mapContainer, defaultLayers.vector.normal.map, {
        center: { lat: 52.5160, lng: 13.3779 },
        zoom: 3,
      });

      window.addEventListener('resize', () => map.getViewPort().resize());

      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      var ui = H.ui.UI.createDefault(map, defaultLayers);

      this.platform = platform;
      this.geocoder = geocoder;
      this.ui = ui;
      this.map = map;
      this.behavior = behavior;
    }
  };

  onLocationSubmitClick = (e) => {
    e.preventDefault();
    window.$('#getLocationModel').modal('hide');

    if (this.props.onLocationSubmit !== undefined) {
      var LatLongString = this.state.LocationLat + ',' + this.state.LocationLong;
      if (LatLongString === this.state.LatLongString) {
        console.log('skip same data');
      } else {
        this.props.onLocationSubmit(this.state.ID, this.state.LocationLat, this.state.LocationLong);
      }
    }
    return false;
  };

  OpenGeoLocation = (ID, LatLongString, Address) => {
    if (LatLongString !== '') {
      var locArray = LatLongString.split(',');
      this.setState({ LocationLat: locArray[0], LocationLong: locArray[1], LatLongString: LatLongString, Address: '', ID: ID }, function () {
        window.$('#getLocationModel').modal('show');
      });
    } else {
      this.setState({ LocationLat: 0, LocationLong: 0, LatLongString: '0,0', Address: Address, ID: ID }, function () {
        window.$('#getLocationModel').modal('show');
      });
    }
  };

  onSearchLocationClick = (e) => {
    e.preventDefault();
    this.searchAddressLocation();
  };

  searchAddressLocation = () => {
    if (this.map === undefined) return;

    if (this.geocoder === undefined) return;

    this.geocoder.geocode(
      { searchtext: this.state.Address },
      this.onGeocodeSuccess,
      this.onGeocodeError
    );
  };

  onGeocodeSuccess = (result) => {
    if (result?.Response?.View?.[0]?.Result?.[0]) {
      var resultLocation = result.Response.View[0].Result[0];
      this.addDraggableMarker(resultLocation.Location.DisplayPosition.Latitude, resultLocation.Location.DisplayPosition.Longitude);
    }
  };

  onGeocodeError = (error) => {
    alert("Error obtained while searching location. Please contact the administrator.");
  };

  addDraggableMarker = (lat, long) => {
    const H = window.H;
    if (this.marker === undefined) {
      var marker = new H.map.Marker({ lat: lat, lng: long });
      marker.draggable = true;
      this.map.addObject(marker);
      this.marker = marker;

      this.map.setZoom(15);
      this.map.setCenter({ lat: lat, lng: long });

      this.map.addEventListener('dragstart', function (ev) {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
          this.behavior.disable();
        }
      }.bind(this), false);

      this.map.addEventListener('dragend', function (ev) {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
          this.behavior.enable();
          var location = target.getPosition();
          this.map.setCenter(location);
          this.setState({ LocationLat: location.lat.toFixed(5), LocationLong: location.lng.toFixed(5) });
        }
      }.bind(this), false);

      this.map.addEventListener('drag', function (ev) {
        var target = ev.target,
          pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          target.setPosition(this.map.screenToGeo(pointer.viewportX, pointer.viewportY));
        }
      }.bind(this), false);
    } else {
      if (this.marker instanceof H.map.Marker) {
        var point = new H.geo.Point(lat, long);
        this.marker.setGeometry(point);
        this.map.setCenter({ lat: lat, lng: long });
      }
    }

    this.setState({ LocationLat: lat, LocationLong: long });
  };

  render() {
    return (
      <div className="form-group">
        <div className="modal fade" id="getLocationModel" tabIndex={-1} role="dialog" aria-labelledby="getLocationModelLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                <h4 className="modal-title" id="getLocationModelLabel">Geo location</h4>
              </div>
              <div className="modal-body">
                <br />
                <div id="map" style={{ height: '350px' }}>
                </div>
                <br />
              </div>
              <div className="modal-footer">
                <div className="col-md-12">
                  <div className="form-group form-inline pull-left">
                    <span htmlFor="SearchAddress">Marker position (lat,long):<label>{this.state.LocationLat},{this.state.LocationLong}</label></span>
                  </div>
                </div>
                <div className="col-md-12">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary" onClick={this.onLocationSubmitClick}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
