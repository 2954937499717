import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";
import { OMSMultiselectDropdown } from "../../../../app/common/common-components/OMSMultiselectDropdown";
import ConfirmationModalPopup from "../../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";

const CustomizeTaskTemplateSetting = (props) => {

  const defaultConfirmationModalProps = () => {
    return {
      showModal: false,
      isConfirmed: (result) => { },
      title: "",
      body: "",
    }
  }

  const [confirmationModalPopup, setConfirmationModalPopup] = useState(defaultConfirmationModalProps())

  const [pipelines, setPipelines] = useState([]);
  const [selectedPipelineId, setSelectedPipelineId] = useState("");
  const [selectedPipeline, setSelectedPipeline] = useState(null);

  const [milestoneDD, setMilestoneDD] = useState([]);
  const [assignedRolesDD, setAssignedRolesDD] = useState([]);

  const [taskTemplateIndex, setTaskTemplateIndex] = useState(-1);
  const [taskTemplateId, setTaskTemplateId] = useState("");
  const [taskTemplateName, setTaskTemplateName] = useState("");
  const [taskTemplateDesc, setTaskTemplateDesc] = useState("");
  const [taskTemplateStagesWithTasks, setTaskTemplateStagesWithTasks] = useState([]);
  const [taskTemplateNotifiedPersons, setTaskTemplateNotifiedPersons] = useState([]);
  const [taskTemplateNotifiedPersonsOptions, setTaskTemplateNotifiedPersonsOptions] = useState([]);

  const [editTaskIndex, setEditTaskIndex] = useState(-1);
  const [editTaskExistingStageId, setEditTaskExistingStageId] = useState("");
  const [editTaskStageId, setEditTaskStageId] = useState("");
  const [editTaskStageName, setEditTaskStageName] = useState("");
  const [editTaskName, setEditTaskName] = useState("");
  const [editTaskRoles, setEditTaskRoles] = useState([]);

  const [isNewTask, setIsNewTask] = useState(false);
  const [newTaskStageId, setNewTaskStageId] = useState("");
  const [newTaskStageName, setNewTaskStageName] = useState("");
  const [newTaskName, setNewTaskName] = useState("");
  const [newTaskRoles, setNewTaskRoles] = useState([]);

  useEffect(() => {

    var assignedRolesDD = props.AssignedRolesDD;
    if (assignedRolesDD !== undefined && assignedRolesDD != null) {
      let AssignedRolesDD = assignedRolesDD?.map((curElem) => {
        return { text: curElem, value: curElem };
      });
      setAssignedRolesDD(AssignedRolesDD);
    }
    else
      setAssignedRolesDD([]);

    var mPipelines = props.Pipelines;
    if (mPipelines === undefined || mPipelines == null)
      mPipelines = [];

    setPipelines(mPipelines);

    if (selectedPipelineId == "") {
      if (mPipelines.length > 0) {
        var mSelectedPipeline = mPipelines[0];
        setSelectedPipeline(mSelectedPipeline);
        setSelectedPipelineId(mSelectedPipeline.pipeline_id);
      }
      else {
        setSelectedPipeline(null);
        setSelectedPipelineId("");
      }
    }
    else {
      var mSelectedPipeline = mPipelines.find((curElem) => {
        return curElem.pipeline_id == selectedPipelineId;
      });
      setSelectedPipeline(mSelectedPipeline);
      setSelectedPipelineId(mSelectedPipeline.pipeline_id);
    }

  }, [props]);

  useEffect(() => {
    let tags = [];

    taskTemplateStagesWithTasks?.map((s) => {
      s.tasks?.map((c) => {
        c.task_roles?.map((curRole) => {
          tags.push(curRole);
        });
      });
    });


    let filteredTags = [...new Set(tags)];
    setTaskTemplateNotifiedPersonsOptions(filteredTags);




  }, [taskTemplateStagesWithTasks]);

  useEffect(() => {

    if (selectedPipeline !== undefined && selectedPipeline != null) {
      let milestoneDD = selectedPipeline.stages?.map((curElem) => {
        return { text: curElem.stage_name, value: curElem.stage_id };
      });
      milestoneDD.splice(0, 0, { text: "No Milestone", value: "No Milestone" });
      setMilestoneDD(milestoneDD);
    }
    else {
      setMilestoneDD([]);
    }

  }, [selectedPipeline]);

  const handleSelectPipelineIdChange = (e) => {
    var mSelectedPipelineId = e.newValue;
    var mSelectedPipeline = pipelines.find((curElem) => {
      return curElem.pipeline_id == mSelectedPipelineId;
    });
    setSelectedPipeline(mSelectedPipeline);
    setSelectedPipelineId(mSelectedPipelineId);
  }

  const onNewTaskTempleteClick = () => {
    let isGoAhead = checkIfStageSavingPending();
    if (!isGoAhead) {
      toast.info("Looks like you have added a milestone for this pipeline. Please save before proceeding with the task template creation.");
      return;
    }

    setEditTaskIndex(-1);
    setTaskTemplateId("");
    setTaskTemplateName("");
    setTaskTemplateDesc("");
    setTaskTemplateNotifiedPersons([]);
    setTaskTemplateNotifiedPersonsOptions([]);

    var stagesWithTasks = [];
    if (selectedPipeline !== undefined && selectedPipeline != null) {
      for (var i = 0; i < selectedPipeline.stages.length; i++) {
        var stageWithTasks = {
          stage_id: selectedPipeline.stages[i].stage_id,
          task_stage: selectedPipeline.stages[i].stage_name,
          task_stage_expected_days: '',
          tasks: []
        }
        stagesWithTasks.push(stageWithTasks);
      }
      stagesWithTasks.push({ stage_id: "", task_stage: "No Milestone", task_stage_expected_days: "", tasks: [] });
    }
    setTaskTemplateStagesWithTasks(stagesWithTasks);
    window.$("#taskTemplateSaveModal").modal("show");
  }

  const checkIfStageSavingPending = () => {
    let taskTempeleteStages = selectedPipeline.stages?.find((curElem) => {
      return curElem.stage_id == "";
    });

    if (taskTempeleteStages !== undefined) return false;
    else return true;
  };

  const onTaskTempleteRowClick = (templeteData, templateIndex) => {
    let isGoAhead = checkIfStageSavingPending();
    if (!isGoAhead) {
      toast.info("Looks like you have added a milestone for this pipeline. Please save before proceeding with the task template updating.");
      return;
    }

    setTaskTemplateIndex(templateIndex);
    setTaskTemplateId(templeteData.template_id);
    setTaskTemplateName(templeteData.template_name);
    setTaskTemplateDesc(templeteData.template_description);
    setTaskTemplateNotifiedPersons(templeteData.notified_persons);
    setTaskTemplateNotifiedPersonsOptions([]);
    var stagesWithTasks = [];
    for (var i = 0; i < templeteData.stages.length; i++) {
      var stageWithTasks = {
        stage_id: templeteData.stages[i].stage_id,
        task_stage: templeteData.stages[i].task_stage,
        task_stage_expected_days: templeteData.stages[i].task_stage_expected_days
      }

      stageWithTasks.tasks = templeteData.tasks?.filter((ele) => {
        return ele.stage_id == templeteData.stages[i].stage_id;
      });

      stagesWithTasks.push(stageWithTasks);
    }
    setTaskTemplateStagesWithTasks(stagesWithTasks);
    window.$("#taskTemplateSaveModal").modal("show");
  };

  const onTaskTempleteDelete = (index) => {
    let varConfirmationModalProps = {
      title: "Delete Task Templete",
      body: "Are you sure you want to Delete this Task Templete? This cannot be undone.",
      showModal: true,
      isConfirmed: (result) => {
        setConfirmationModalPopup({
          title: "",
          body: "",
          showModal: false,
          isConfirmed: () => { },
        })
        if (result)
          taskTempleteDeleteCallback(index);
      },
    };
    setConfirmationModalPopup(varConfirmationModalProps);
  }

  const taskTempleteDeleteCallback = (index) => {

    let mSelectedPipeline = { ...selectedPipeline };
    mSelectedPipeline.task_templates.splice(index, 1);
    setSelectedPipeline(mSelectedPipeline);

    if (props.ComponentUpdated !== undefined) {
      props.ComponentUpdated(mSelectedPipeline, true);
    }

  };

  const saveTaskTemplateSaveModel = () => {
    let mStages = taskTemplateStagesWithTasks?.map((c) => {
      return { stage_id: c.stage_id, task_stage: c.task_stage, task_stage_expected_days: c.task_stage_expected_days };
    });

    var mTasks = [];

    taskTemplateStagesWithTasks?.map((s) => {
      s.tasks?.map((c) => {
        mTasks.push({ stage_id: c.stage_id, task_stage: c.task_stage, task_order: c.task_order, task_name: c.task_name, task_roles: c.task_roles });
      });
    });

    var templateData =
    {
      "template_id": taskTemplateId,
      "template_name": taskTemplateName,
      "template_description": taskTemplateDesc,
      "notified_persons": taskTemplateNotifiedPersons,
      "stages": mStages,
      "tasks": mTasks
    }

    var mSelectedPipeline = { ...selectedPipeline }
    if (taskTemplateIndex == -1) {
      mSelectedPipeline.task_templates.push(templateData);
    }
    else {
      mSelectedPipeline.task_templates[taskTemplateIndex] = templateData;
    }

    setSelectedPipeline(mSelectedPipeline);
    window.$("#taskTemplateSaveModal").modal("hide");

    if (props.ComponentUpdated !== undefined) {
      props.ComponentUpdated(mSelectedPipeline, true);
    }
  };

  const saveTaskTemplateCloseModel = () => {
    window.$("#taskTemplateSaveModal").modal("hide");
  }

  const getRoleBg = (tag) => {
    /*const tagOBJ = [...this.state.OfficeContactTags, ...this.state.ContactSourceTypes, ...this.state.UserContactTags]?.find((curTag) => {
      return curTag.Name == tag;
    });
    if (tagOBJ) {
      return tagOBJ.Color;
    }*/
    return "#4d4d4d";
  };

  const expectedDaysInputChange = (e, stateItem, index) => {
    var mTaskTemplateStagesWithTasks = [...taskTemplateStagesWithTasks];
    mTaskTemplateStagesWithTasks[index].task_stage_expected_days = parseInt(e.target.value);
    setTaskTemplateStagesWithTasks(mTaskTemplateStagesWithTasks);
  };

  const taskTemplateNotifiedPersonsChanged = (e) => {
    if (e.target.checked) {
      setTaskTemplateNotifiedPersons([...taskTemplateNotifiedPersons, e.target.value])
    } else {
      let mTaskTemplateNotifiedPersons = taskTemplateNotifiedPersons.filter((tag) => {
        return tag != e.target.value;
      });
      setTaskTemplateNotifiedPersons(mTaskTemplateNotifiedPersons);
    }
  };


  const onTaskArrowUpClick = (stageIndex, taskItem, index) => {
    var mStages = [...taskTemplateStagesWithTasks];
    var mTasks = mStages[stageIndex].tasks;
    mTasks.splice(index, 1);
    mTasks.splice(index - 1, 0, taskItem);
    setTaskTemplateStagesWithTasks(mStages);
  }

  const onTaskArrowDownClick = (stageIndex, taskItem, index) => {
    var mStages = [...taskTemplateStagesWithTasks];
    var mTasks = mStages[stageIndex].tasks;
    mTasks.splice(index, 1);
    mTasks.splice(index + 1, 0, taskItem);
    setTaskTemplateStagesWithTasks(mStages);
  }

  const onTaskEditClick = (stageIndex, taskItem, index) => {
    if (editTaskIndex < 0) {
      setEditTaskExistingStageId(taskItem.stage_id);
      setEditTaskStageId(taskItem.stage_id);
      setEditTaskName(taskItem.task_name);
      setEditTaskRoles([...taskItem.task_roles]);
      setEditTaskStageName(taskItem.task_stage);
      setEditTaskIndex(index);
    }
  }

  const onTaskEditCancelClick = () => {
    setEditTaskExistingStageId("");
    setEditTaskStageId("");
    setEditTaskName("");
    setEditTaskRoles([]);
    setEditTaskStageName("");
    setEditTaskIndex(-1);
  }

  const onTaskEditSaveClick = () => {
    var mTaskTemplateStagesWithTasks = [...taskTemplateStagesWithTasks];
    if (editTaskExistingStageId != editTaskStageId) {
      const stageIndex = mTaskTemplateStagesWithTasks.findIndex((ele) => ele.stage_id == editTaskExistingStageId);
      if (stageIndex != -1) {
        mTaskTemplateStagesWithTasks[stageIndex].tasks.splice(editTaskIndex, 1);

        var mTask = {
          stage_id: editTaskStageId,
          task_stage: editTaskStageName,
          task_name: editTaskName,
          task_roles: [...editTaskRoles]
        }

        const stageIndexNew = mTaskTemplateStagesWithTasks.findIndex((ele) => ele.stage_id == editTaskStageId);
        if (stageIndexNew != -1) {
          mTaskTemplateStagesWithTasks[stageIndexNew].tasks.push(mTask);
        }

        setTaskTemplateStagesWithTasks(mTaskTemplateStagesWithTasks);
        onTaskEditCancelClick();

      }
    }
    else {
      const stageIndex = mTaskTemplateStagesWithTasks.findIndex((ele) => ele.stage_id == editTaskStageId);
      if (stageIndex != -1) {
        var mTask = mTaskTemplateStagesWithTasks[stageIndex].tasks[editTaskIndex];
        mTask.stage_id = editTaskStageId;
        mTask.task_stage = editTaskStageName;
        mTask.task_name = editTaskName;
        mTask.task_roles = [...editTaskRoles];
        setTaskTemplateStagesWithTasks(mTaskTemplateStagesWithTasks);
        onTaskEditCancelClick();
      }
    }


  }

  const onTaskEditDeleteClick = (stageIndex, taskIndex) => {
    let varConfirmationModalProps = {
      title: "Delete Task",
      body: "Are you sure you want to delete this task? This cannot be undone.",
      showModal: true,
      isConfirmed: (result) => {
        setConfirmationModalPopup(defaultConfirmationModalProps());
        if(result){
          var mTaskTemplateStagesWithTasks = [...taskTemplateStagesWithTasks];
          mTaskTemplateStagesWithTasks[stageIndex].tasks.splice(taskIndex, 1);
          setTaskTemplateStagesWithTasks(mTaskTemplateStagesWithTasks);
        }
      },
    };
    setConfirmationModalPopup(varConfirmationModalProps);
  }

  const newTaskClick = () => {
    if (!isNewTask) {
      setIsNewTask(true);
      setNewTaskName("");
      setNewTaskRoles([]);
      setNewTaskStageId("");
      setNewTaskStageName("");
    }
  }

  const onTaskNewSaveClick = () => {
    var mTaskTemplateStagesWithTasks = [...taskTemplateStagesWithTasks];
    const stageIndex = mTaskTemplateStagesWithTasks.findIndex((ele) => ele.stage_id == newTaskStageId);
    if (stageIndex != -1) {
      mTaskTemplateStagesWithTasks[stageIndex].tasks.push({ stage_id: newTaskStageId, task_stage: newTaskStageName, task_name: newTaskName, task_order: 0, task_roles: newTaskRoles });
      setTaskTemplateStagesWithTasks(mTaskTemplateStagesWithTasks);
      onTaskNewCancelClick();
    }
  }

  const onTaskNewCancelClick = () => {
    setIsNewTask(false);
    setNewTaskName("");
    setNewTaskRoles([]);
    setNewTaskStageId("");
    setNewTaskStageName("");
  }




  return (
    <div id="tasktemplates" className={props.activeTabID == "tasktemplates" ? "tab-pane fade in active" : "tab-pane fade "}>
      <div className="row">
        <div className="col-md-12 d-flex justify-content-between align-items-center">
          <div className="col-md-3">
            <OMSDropdown
              id="selectPipelineId"
              options={pipelines}
              valueField="pipeline_id"
              labelField="pipeline_name"
              value={selectedPipelineId}
              onChange={handleSelectPipelineIdChange}
            />
          </div>
          {selectedPipelineId != "" &&
            <button type="button" className="tab_btn btn-success-oms" onClick={onNewTaskTempleteClick}>
              New Template
            </button>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="pipline_table">
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "start" }}>Name</th>
                  <th style={{ textAlign: "start" }}>Description</th>
                  <th style={{ textAlign: "end" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedPipeline?.task_templates?.map((curElem, i) => {
                  return (
                    <tr key={i}>
                      <td>{curElem.template_name}</td>
                      <td>{curElem.template_description}</td>
                      <td style={{ textAlign: "end" }}>
                        <i class="fa fa-sharp fa-solid fa-edit" onClick={() => onTaskTempleteRowClick(curElem, i)} title="Edit"></i> &nbsp;
                        <i
                          className="fa fa-trash"
                          onClick={() => onTaskTempleteDelete(i)}
                          aria-hidden="true"
                          style={{ fontSize: "24px" }}
                          title="Delete"
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="modal fade" id="taskTemplateSaveModal" role="dialog" aria-labelledby="taskTemplateSaveModalLabel">
        <div className="modal-dialog modal-lg" role="document" style={{ maxWidth: 825 }}>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={() => saveTaskTemplateCloseModel()} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title text-center">Save Task Template</h4>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <p>
                    <strong>Basic Info</strong>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={taskTemplateName}
                      onChange={(e) => setTaskTemplateName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      value={taskTemplateDesc}
                      onChange={(e) => setTaskTemplateDesc(e.target.value)}
                    />
                  </div>
                  {props.actions.indexOf("theme") > -1 && (
                    <div className="form-group ">
                      <label>Enable task email notifications by default</label>
                      <div className="d-flex flex-wrap">
                        {taskTemplateNotifiedPersonsOptions?.map((c) => {
                          return (
                            <div className="d-flex align-items-center mb-2 mr-1" key={c}>
                              <input
                                type="checkbox"
                                className="my-0 mr-1"
                                value={c}
                                checked={taskTemplateNotifiedPersons.includes(c)}
                                onChange={(e) => taskTemplateNotifiedPersonsChanged(e)}
                                style={{ width: "20px", height: "20px" }}
                              />
                              <span className={`task_email_tag `} style={{ background: getRoleBg(c) }}>
                                {c}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row task-row">
                <div className="col-md-12">
                  <p className="my-2">
                    <strong>Tasks</strong>
                  </p>
                  {taskTemplateStagesWithTasks?.map((stageItem, stageIndex) => {
                    if (stageItem.task_stage == "No Milestone" && stageItem.tasks.length == 0) {
                      return;
                    }
                    return (
                      <div key={stageIndex} title={stageItem.task_stage}>
                        <div className="d-flex justify-content-between">
                          <p className="">
                            <strong>{stageItem.task_stage == "No Milestone" ? "No Milestone" : `Milestone: ${stageItem.task_stage}`}</strong>
                          </p>
                          {stageItem.task_stage != "No Milestone" && (
                            <p className="">
                              Expected after{" "}
                              <input
                                type="text"
                                className="small_input"
                                placeholder=""
                                value={stageItem.task_stage_expected_days}
                                onChange={(e) => expectedDaysInputChange(e, stageItem, stageIndex)}
                              />{" "}
                              days
                            </p>
                          )}
                        </div>
                        {stageItem.tasks?.map((taskItem, taskIndex) => {
                          if (editTaskIndex == taskIndex && editTaskExistingStageId == stageItem.stage_id) {
                            return (
                              <div className="mb-3 d-flex align-items-center" key={"T" + taskIndex}>
                                <div className="form-group col-md-4" style={{ marginRight: "7px" }}>
                                  <label>Task Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Task Name"
                                    style={{ minWidth: "225px" }}
                                    value={editTaskName}
                                    onChange={(e) => setEditTaskName(e.target.value)}
                                  />
                                </div>
                                <div className="form-group roles_dd col-md-3" style={{ marginRight: "7px" }}>
                                  <label>Assigned Roles</label>
                                  <OMSMultiselectDropdown
                                    id="roles"
                                    name="Choose some options..."
                                    options={assignedRolesDD}
                                    valueField="value"
                                    labelField="text"
                                    value={editTaskRoles}
                                    onChange={(e) => setEditTaskRoles(e)}
                                  />
                                </div>
                                <div className="form-group mileston col-md-3" style={{}}>
                                  <label>Milestone</label>
                                  <OMSDropdown
                                    id="milestoneOfTask"
                                    options={milestoneDD}
                                    valueField="value"
                                    labelField="text"
                                    value={editTaskStageId}
                                    onChange={(e) => { setEditTaskStageId(e.newValue); setEditTaskStageName(e.newText) }}
                                  />
                                </div>
                                <div className="mileston col-md-2" style={{ marginLeft: "15px" }}>
                                  <i class="fa fa-sharp fa-solid fa-save" onClick={() => onTaskEditSaveClick()} title="Save"></i> &nbsp;
                                  <i
                                    className="fa fa-window-close"
                                    aria-hidden="true"
                                    style={{ fontSize: "24px" }}
                                    onClick={() => onTaskEditCancelClick()}
                                    title="Cancel"
                                  ></i>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div key={taskIndex} className="mb-3 d-flex justify-content-between">
                                <div>
                                  <span>{taskItem.task_name} </span> &nbsp;{" "}
                                  {taskItem.task_roles?.map((role, roleIdx) => {
                                    return (
                                      <span className={`role_tag`} style={{ background: getRoleBg(role) || "" }} key={roleIdx}>
                                        {role}
                                      </span>
                                    );
                                  })}
                                </div>
                                <div>
                                  <i
                                    className="fa fa-angle-up arrow_icon"
                                    aria-hidden="true"
                                    style={{ color: taskIndex == 0 ? "gray" : "" }}
                                    onClick={() => onTaskArrowUpClick(stageIndex, taskItem, taskIndex)}
                                  ></i>
                                  <i
                                    className="fa fa-angle-down arrow_icon"
                                    aria-hidden="true"
                                    style={{ marginLeft: "8px", marginRight: "8px", color: (taskIndex == stageItem.tasks?.length - 1) ? "gray" : "" }}
                                    onClick={() => onTaskArrowDownClick(stageIndex, taskItem, taskIndex)}
                                  ></i>
                                  <i class="fa fa-sharp fa-solid fa-edit" style={{ marginLeft: 7, cursor: "pointer" }} onClick={() => onTaskEditClick(stageItem, taskItem, taskIndex)} title="Edit"></i>{" "}
                                  &nbsp;
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                    style={{ fontSize: "24px", cursor: "pointer" }}
                                    onClick={() => onTaskEditDeleteClick(stageIndex, taskIndex)}
                                    title="Delete"
                                  ></i>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })
                  }

                  {
                    isNewTask ? (
                      <div className="mb-3 d-flex align-items-center">
                        <div className="form-group col-md-4" style={{ marginRight: "7px" }}>
                          <label>Task Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Task Name"
                            style={{ minWidth: "225px" }}
                            value={newTaskName}
                            onChange={(e) => setNewTaskName(e.target.value)}
                          />
                        </div>
                        <div className="form-group roles_dd col-md-3" style={{ marginLeft: "7px" }}>
                          <label>Assigned Roles</label>
                          <OMSMultiselectDropdown
                            id="roles"
                            name="Choose some options..."
                            options={assignedRolesDD}
                            valueField="value"
                            labelField="text"
                            value={newTaskRoles}
                            onChange={(e) => setNewTaskRoles(e)}
                          />
                        </div>
                        <div className="form-group mileston col-md-3">
                          <label>Milestone</label>
                          <OMSDropdown
                            id="milestoneOfTask"
                            options={milestoneDD}
                            valueField="value"
                            labelField="text"
                            value={newTaskStageId}
                            onChange={(e) => { setNewTaskStageId(e.newValue); setNewTaskStageName(e.newText) }}
                          />
                        </div>
                        <div className="form-group mileston col-md-2" style={{ marginLeft: 15, paddingTop: 10 }}>
                          <i class="fa fa-sharp fa-solid fa-save" onClick={() => onTaskNewSaveClick()} title="Save"></i> &nbsp;
                          <i className="fa fa-window-close" aria-hidden="true" style={{ fontSize: "24px" }} onClick={() => onTaskNewCancelClick()} title="Cancel"></i>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  <div className="d-flex justify-content-between">
                    <p className=""></p>
                    <p className="new_task_link" onClick={() => newTaskClick()}>
                      New Task
                    </p>
                  </div>
                </div>
              </div>

            </div>

            <div className="modal-footer">
              <div className="col-md-12">
                <button type="button" className="btn btn-default" onClick={() => saveTaskTemplateCloseModel()}>
                  <i className="fa fa-times"></i> Cancel
                </button>
                <button type="button" onClick={() => saveTaskTemplateSaveModel()} className="btn btn-success-oms">
                  <i className="fa fa-check"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModalPopup {...confirmationModalPopup} />
    </div>
  );
};

export default CustomizeTaskTemplateSetting;