import moment from "moment";
import React, { useEffect } from "react";
import { OMSContactBoxView } from "../../../app/common/common-components/OMSSocialBox";
import { Link } from 'react-router-dom';

function ContactOverview(props: any) {
  const { diary, pinnedNotes, unpinNote, pinDisabled, leadSource, contact, userDefinedTags, officeDefaultTags, status, mContact } = props;
  useEffect(() => {
    //console.log("inside contact overview useEffect, contact=", contact);
  }, []);

  const getDisplayName = () => {
    if (contact.contactType === 1 && contact.companyName &&contact.companyName !== "") {
      return contact.displayName + " (" + contact.companyName + ")";
    }
    else {
      return contact.displayName;
    }
  }

  let displayName = getDisplayName();


  
  return (
    <div className="row">
      {/* Diary */}
      <div className="col-md-12">
        <div className="media">
          <div className="media-left">
            <span>
              <i className="fa fa-clock-o"></i>
              <br />
              Diary
            </span>
          </div>
          <div className="media-body">
            <p style={{ marginTop: 20, marginLeft: 10 }}>
            {props.renderDiaryItems()}
            </p>
            {pinnedNotes && (
              <div>
                <div className="diary-overview-content" style={{ display: "flex" }}>
                  {pinnedNotes.map((note: any) => (
                    <div
                      key={note.diaryID}
                      className={`d-flex pinnedRoot read-only-note ${pinDisabled === note.diaryID ? "disabled-note" : ""}`}
                      style={{
                        justifyContent: "space-between",
                        position: "relative",
                      }}
                    >
                      <div className="pin-button" onClick={() => (pinDisabled !== note.diaryID ? unpinNote(note) : null)}>
                        <div className="pin-btn-tooltip">Unpin Note</div>
                        <i className="fa fa-thumb-tack" aria-hidden="true" style={{ fontSize: 14 }}></i>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <strong>{note.diaryTitle}</strong>
                        <div className="pinned-note-date">
                          {moment(note.startDate, "DD-MM-YYYY hh:mm A").format("DD MMM YYYY")}
                          {note.endDate ? ` - ${moment(note.endDate, "DD-MM-YYYY hh:mm A").format("DD MMM YYYY")}` : ""}
                        </div>
                        <p className="note-text">{note.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Personal */}
      <div className="col-md-12">
        <div className="media">
          <div className="media-left">
            <span>
              <i className="fa fa-user-circle"></i>
              <br />
              Personal
            </span>
          </div>
          <div className="media-body">
            <div style={{ marginTop: 5, marginLeft: 10 }}>
              <label style={{ marginRight: 10 }}>{displayName}</label>
              <label style={{ marginRight: 5 }} className="label label-default">
                {leadSource}
              </label>
              <i style={{ fontSize: 15, marginRight: 2 }} className={status === 1 ? "fa fa-check-square-o" : "fa fa-square-o"}></i>
              <span style={{ fontSize: 16 }}>{status === 1 ? "active" : "inactive"}</span>
            </div>
            <div className="ellipsis-container" style={{ marginTop: 2, marginLeft: 10 }}>
              <div className="ellipsis-div">
                {officeDefaultTags.map(function (item: any) {
                  return item.linked ? (
                    <span
                      key={item.name}
                      className="btn btn-my-tag-nonclick my-tag"
                      style={{
                        backgroundColor: item.color,
                        marginRight: 3,
                      }}
                    >
                      {item.name} on{" "}
                     {/*  <a
                        style={{
                          color: "#FFF",
                          textDecoration: "underline",
                        }}
                        href={"/Property/SaveProperty?ID=" + item.linkedobj.PropertyID}
                        target="_blank"
                      >
                        {item.linkedobj.DisplayName}
                      </a> */}
                      <Link to={`/Property/SaveProperty?ID=${item.linkedobj.PropertyID}`} target="_blank"  style={{
                          color: "#FFF",
                          textDecoration: "underline",
                        }}>
                          {item.linkedobj.DisplayName}
                      </Link>
                    </span>
                  ) : item.selected ? (
                    <span
                      key={item.name}
                      className="btn btn-my-tag-nonclick my-tag"
                      style={{
                        backgroundColor: item.color,
                        marginRight: 3,
                      }}
                    >
                      {item.name}
                    </span>
                  ) : null;
                })}
                &nbsp;
                {userDefinedTags.map(function (item: any) {
                  return item.selected ? (
                    <span
                      key={item.name}
                      className="btn btn-my-tag-nonclick my-tag"
                      style={{
                        backgroundColor: item.color,
                        marginRight: 3,
                      }}
                    >
                      {item.name}
                    </span>
                  ) : null;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contacts */}
      <div className="col-md-12">
        <div className="media">
          <div className="media-left">
            <span>
              <i className="fa fa-phone"></i>
              <br />
              Contacts
            </span>
          </div>
          <div className="media-body">
            <div style={{ marginTop: 15, marginLeft: 10 }}>
              <OMSContactBoxView id="contactBoxItem" name="contactBoxItem" email={props.contact.emailAddress} value={mContact}></OMSContactBoxView>
            </div>
          </div>
        </div>
      </div>
      {/* Address */}
      <div className="col-md-12">
        <div className="media">
          <div className="media-left">
            <span>
              <i className="fa fa-map-marker"></i>
              <br />
              Address
            </span>
          </div>
          <div className="media-body">
            <div className="ellipsis-container" style={{ marginTop: 20, marginLeft: 10 }}>
              <div className="ellipsis-div">{mContact.residential_address}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactOverview;
