import React from "react";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableComp } from "../../../app/common/common-components/OMSDatatables";
import { ReportGrid } from "../../contact/ReportGrid";
import { DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../app/api/apiAgent";
import { withRouter } from 'react-router-dom';

class OfficesGrid extends React.Component {
  constructor(props) {
    super(props);
    this.reportGridRef = React.createRef();
  }

  state = {
    officesData: [],
    data: [],
    pageView: 1,
    view: 1,
    loading: false,
    ExtraParams: JSON.stringify({ View: 1 }),
  };

  loadOfficesFromServer = (view) => {
    this.setState({ loading: true });
    apiAgent.Setting.getOfficesData(view)
      .then((response) => {
        //console.log("offices data", this.state.officesData);
        this.setState({
          officesData: response.data,
        });
        //console.log("offices Data", response.data);
      })
      .catch((error) => {
        //toast.error(error.response.data))
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.loadOfficesFromServer(1);
  }

  onCreateClick = (e) => {
    this.props.history.push('/Settings/SaveOffice');
  };

  handleRowClick = (data) => {
    this.props.history.push('/Settings/SaveOffice?ID=' + data.officeID);
  };

  onChangePageView = (pageView) => {
    if (pageView == 100) {
      this.reportGridRef.current.onOpenReports();
      this.setState({ pageView: pageView });
    } else {
      this.setState({
        ExtraParams: JSON.stringify({ View: pageView }),
        pageView: 1,
        view: pageView,
      });
      this.loadOfficesFromServer(pageView);
    }
  };

  render() {
    function formatDateTime(val) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      if (isNaN(val)) return "";
      else {
        var valS = val.toString();
        return valS.substring(6, 8) + " " + months[parseInt(valS.substring(4, 6)) - 1] + " " + valS.substring(0, 4);
      }
    }

    var columns = [
      { data: "syncID", title: "SyncID" },
      { data: "name", title: "Name" },
      { data: "emailAddress", title: "Email address" },
      { data: "country", title: "Country" },
      { data: "status", title: "Status" },
      { data: "activeUsers", title: "Active users" },
      { data: "createdF", title: "Created" },
    ];

    var columnDefs = [
      {
        render: function (data, type, row) {
          return formatDateTime(data);
        },
        targets: 6,
      },
    ];

    return (
      <div className="row">
        <div className="col-sm-12 hidden-md hidden-lg" style={{ marginBottom: 10 }}>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("1")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 1 ? <b>Active</b> : <span>Active</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("2")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 2 ? <b>Inactive</b> : <span>Inactive</span>}
          </span>
          {this.props.super_admin && (
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("100")}>
              <i className="fa fa-file-pdf-o" style={{ marginRight: 8 }}></i>
              {this.state.pageView == 100 ? <b>Reports</b> : <span>Reports</span>}
            </span>
          )}
        </div>
        <div className="col-md-1 hidden-xs hidden-sm">
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("1")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 1 ? <b>Active</b> : <span>Active</span>}
          </span>
          <br />
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("2")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 2 ? <b>Inactive</b> : <span>Inactive</span>}
          </span>
          <br />
          {this.props.super_admin && (
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("100")}>
              <i className="fa fa-file-pdf-o" style={{ marginRight: 8 }}></i>
              {this.state.pageView == 100 ? <b>Reports</b> : <span>Reports</span>}
            </span>
          )}
        </div>
        <div className="col-md-11 col-sm-12" style={{ display: this.state.pageView == 1 ? "" : "none" }}>
          <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="pull-right">
                    <div className="form-inline">
                      {this.props.actions.indexOf("create") != -1 && (
                        <OMSButtonStyleL
                          type="button"
                          ui_icon=""
                          ui_type="create"
                          ui_text="add office"
                          ui_processing_text=""
                          processing={false}
                          disabled={false}
                          onClick={this.onCreateClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-body table-responsive">
              {/* <DataTableCompDynamic id="officeTable" Url="/Settings/OfficesAjax" columns={columns} columnDefs={columnDefs} order={[[ 0, "desc" ]]} serverSide={false} onRowClick={this.handleRowClick} ExtraParams={this.state.ExtraParams} /> */}
              <DataTableComp
                id="officeTable"
                data={this.state.officesData}
                columns={columns}
                columnDefs={columnDefs}
                order={[[0, "desc"]]}
                onRowClick={this.handleRowClick}
              />
              <DataTableLoading loading={this.state.loading} />
            </div>
          </div>
        </div>
        {this.props.super_admin && (
          <div className="col-md-11 col-sm-12" style={{ display: this.state.pageView == 100 ? "" : "none" }}>
            <ReportGrid ref={this.reportGridRef} ID="userReports" Category="office" />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(OfficesGrid);
