import React, { useEffect, useState } from "react";
import { SyndicationDetailModel } from "../../../../app/models/SyndicationDetailModel";
import { DataTableComp } from "../../../../app/common/common-components/OMSDatatables";
import moment from "moment";
import { PortalConfigModel } from "../../../../app/models/MasterDataModel";
import { webref_format } from "../../../../app/common/common-functions/functions";

interface Props {
  data: SyndicationDetailModel[];
  detailGridLoading: boolean;
  portalConfigs: PortalConfigModel[];
  status: string;
}

const SyndicationDetailGrid: React.FC<Props> = (props) => {
  const [modifiedData, setModifiedData] = useState<SyndicationDetailModel[]>([]);

  useEffect(() => {
    // console.log("Syndication Detail Data", props.data);
    // console.log("Current status:", props.status);

    // Modify the data for "In Process" status
    const newData = props.data.map(item => {
      if (props.status === "In Process") {
        return {
          ...item,
          message: `In Process since ${moment(item.timestamp).format("DD-MM-YYYY HH:mm:ss")}`
        };
      }
      return item;
    });
    setModifiedData(newData);
  }, [props.data, props.status]);

  const columns = [
    {
      data: "webRef",
      title: "Web Ref",
      render: (data: any, type: any, row: any) => {
        const value = JSON.stringify({ webref: data, syncstatus: 0 });
        return webref_format(value, row.propertyID);
      },
    },
    {
      data: "timestamp",
      title: "Date & Time",
      render: (data: any) => {
        return moment(data).format("MMMM Do YYYY, h:mm:ss A");
      },
    },
    {
      data: "message",
      title: "Description"
    },
  ];

  const columnDefs = [
    { targets: 0, width: "10%" },
    { targets: 1, width: "25%" },
    { targets: 2, width: "65%" },
  ];

  return (
    <div className="panel-body table-responsive center-align">
      <DataTableComp 
        id="syndicationDetailTable" 
        data={modifiedData} 
        columns={columns} 
        columnDefs={columnDefs} 
        order={[[0, "desc"]]} 
        showSearchBox={false}
      />
      {/* <DataTableLoading loading={props.detailGridLoading} /> */}
    </div>
  );
};

export default SyndicationDetailGrid;
