import { useEffect } from "react";

export const useClickButtonOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Check if the event path contains a button element
      const path = event.composedPath ? event.composedPath() : event.path;
      if (!path) {
        return;
      }

      let isButton = path.some((element) => {
        if (element.className !== undefined) {
          if (element.className.includes("dropdown-list")) {
            return true;
          }
        } else {
          return false;
        }
      });

      if (isButton) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target)) {
        handler(event);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener, { passive: false });

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
