import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import { withRouter } from 'react-router-dom';
import { ToPriceString } from "../../../app/common/common-functions/functions";

class OfficesDealGrid extends React.Component {

  today = new Date();
  currentMonth = this.today.getMonth();
  currentYear = this.today.getFullYear();

  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  loadReportsFromServer = (selectedMonth, year) => {

    this.setState({ loading: true, data: [] });
    apiAgent.Admin.officeDealGridInfoAjax(selectedMonth, year)
      .then((response) => {
        this.setState({
          data: response,
          hasTableShown: false,
          loading: false,
        });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };
  updateMonths = () => {
    const updateMonths =
      [
        this.monthNames[this.currentMonth],
        ...this.monthNames.slice(0, this.currentMonth).reverse(),
        ...this.monthNames.slice(this.currentMonth + 1).reverse()
      ]

    this.setState({ months: updateMonths });
    this.setState({ selectedMonth: this.currentMonth + 1 });
  }

  onHandleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    let currentMontOfYear = this.currentMonth + 1 > selectedMonth ? this.currentYear : (this.currentMonth + 1) == selectedMonth ? this.currentYear : this.currentYear - 1;

    this.setState({ selectedMonth });
    this.loadReportsFromServer(selectedMonth, currentMontOfYear);
  }

  componentDidMount() {
    this.updateMonths();
    this.loadReportsFromServer(this.currentMonth + 1, this.currentYear);
  }

  state = {
    data: [],
    loading: false,
    hasTableShown: false,
    months: [],
    selectedMonth: 0,
  };


  render() {
    var columns = [
      // { data: "officeID", title: "Office ID" },
      { data: "officeName", title: "Office Name" },
      { data: "dealCount", title: "Deal Count" },
      { data: "dealValue", title: "Deal Value" },
      { data: "monthWiseDealCount", title: "Month Wise Deal Count" }
    ];

    var columnDefs = [
      {
        render: function (data, type, row) {
          return ToPriceString(data);
        },
        targets: 2,
      },
    ];

    return (
      <div className="col-md-12">
        <div className="panel panel-default panel-table oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline form-group">
                  <div className="element">
                    <div className="truncate">
                      <label>
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </label>
                      &nbsp;
                      <select
                        id="dealCountMonths"
                        className="form-control"
                        onChange={this.onHandleMonthChange}
                        value={this.state.selectedMonth}
                      >
                        {
                          this.state.months.map((month, index) => {
                            const monthValue = this.monthNames.indexOf(month);
                            const isCurrentMonth = monthValue === (this.currentMonth);
                            const isCurrentMontOfYear = this.currentMonth > monthValue

                            return (
                              <>
                                <option key={index} value={this.monthNames.indexOf(month) + 1} >
                                  {
                                    isCurrentMontOfYear ? month + " " + this.currentYear : isCurrentMonth ? month + " " + this.currentYear : month + " " + (this.currentYear - 1)

                                  }
                                </option>
                              </>
                            )
                          })
                        }

                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="table-responsive">
              <DataTableComp
                id="commissionslipTable"
                columns={columns}
                columnDefs={columnDefs}
                data={this.state.data}
                order={[[1, "asc"]]}
              />
            </div>
            <DataTableLoading loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OfficesDealGrid);
