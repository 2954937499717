import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { withRouter } from 'react-router-dom';

class P24MatchBox extends React.Component {
  state = {
    BaseRef: "",
    P24Ref: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onCancelClick = (e) => {
    this.props.history.push('/Settings');
  };

  onPerformClick = (e) => {
    this.setState({ processing: true });
    /* var data = new FormData();
      data.append('BaseRef', this.state.BaseRef);
      data.append('P24Ref', this.state.P24Ref);
      var xhr = new XMLHttpRequest();
      xhr.open('post', '/Admin/P24MatchAjax', true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        $.toaster(data.message, 'Result', 'info');      
        this.setState({ processing: false });
      }.bind(this);
      xhr.send(data); */
    apiAgent.Admin.p24MatchAjax(this.state.BaseRef, this.state.P24Ref)
      .then((response) => {
        toast.info(response.message);
      })
      .catch((error) => {
        toast.info("Fail");
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  render() {
    return (
      <div className="col-sm-12">
        <div className="panel panel-default oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-12">
                <label>P24 Ref Mapping</label>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="BaseRef">Base ref</label>
                <input id="BaseRef" name="BaseRef" type="text" className="form-control" value={this.state.BaseRef} onChange={this.handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="P24Ref">P24 ref</label>
                <input id="P24Ref" name="P24Ref" type="text" className="form-control" value={this.state.P24Ref} onChange={this.handleChange} />
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCancelClick}
                  />
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Perform"
                    ui_processing_text="Processing.."
                    processing={this.state.processing}
                    disabled={this.state.processing}
                    onClick={this.onPerformClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(P24MatchBox);
