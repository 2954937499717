import { useEffect, useRef, useState } from "react";
import OMSDropDownCard from "./OMSDropDownCard";

const OMSButtonDropDown = ({
  btnText,
  dropdownList,
  onClickDropdown,
  buttonClassName,
}) => {
  const [open, setOpen] = useState(false);
  const refButtonDropdown = useRef(null);

  useEffect(() => {
    const handleButtonClick = (e) => {
      if (
        refButtonDropdown.current &&
        !refButtonDropdown.current.contains(e.target)
      ) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    document.addEventListener("touchStart", handleButtonClick);
    document.addEventListener("click", handleButtonClick);
    return () => {
      document.removeEventListener("touchStart", handleButtonClick);
      document.removeEventListener("click", handleButtonClick);
    };
  }, []);
  // const handleToggleDropdown = (isToggle) => {
  //   setOpen(isToggle);
  // };

  return (
    <>
      <div className="relative m-4 btn-right">
        <a
          ref={refButtonDropdown}
          className={buttonClassName}
          onClick={() => {
            setOpen((isOpen) => !isOpen);
          }}
          onTouchStart={() => {
            setOpen(true);
          }}
        >
          {btnText}
        </a>
        {open && (
          <OMSDropDownCard
            data={dropdownList}
            onClick={onClickDropdown}
            // toggleDropdown={handleToggleDropdown}
          ></OMSDropDownCard>
        )}
      </div>
    </>
  );
};

export default OMSButtonDropDown;
