import React from "react";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import apiAgent from "../../../app/api/apiAgent";
import { withRouter } from 'react-router-dom';

class CountriesGrid extends React.Component {
  state = {
    data: [],
    countryData: [],
    loading: false,
  };

  loadCountryFromServer = () => {
    this.setState({ loading: true });
    apiAgent.Setting.getCountryData()
      .then((response) => {
        //console.log("country data", this.state.countryData);
        this.setState({
          countryData: response.data,
        });
       // console.log("country Data", response.data);
      })
      .catch((error) => {
        //toast.error(error.response.data))
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    this.loadCountryFromServer();
  }

  onCreateClick = (e) => {
    this.props.history.push('/Settings/SaveCountry');
  };

  handleRowClick = (data) => {
    this.props.history.push('/Settings/SaveCountry?name=' + data.name);
  };

  render() {
    const columns = [
      { data: "name", title: "Name" },
      { data: "abbreviation", title: "Abbreviation" },
      { data: "currencyDisplay", title: "Currency display" },
      { data: "currencyCode", title: "Currency code" },
      { data: "currencyName", title: "Currency name" },
      { data: "dialingCode", title: "Dialing code" },
    ];

    return (
      <div className="col-md-12">
        <div className="panel panel-default panel-table oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="pull-right boxRightMenu">
                  <OMSButtonStyleL
                    type="button"
                    ui_icon=""
                    ui_type="create"
                    ui_text="add country"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCreateClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body table-responsive">
            {/* <DataTableCompDynamic id="countryTable" Url="/Settings/CountriesAjax" columns={columns} columnDefs={[]} serverSide={false} onRowClick={this.handleRowClick} ExtraParams="" /> */}
            <DataTableComp id="countryTable" data={this.state.countryData} columns={columns} columnDefs={[]} onRowClick={this.handleRowClick} />
            <DataTableLoading loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CountriesGrid);
