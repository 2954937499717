import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import { ImportPropertiesResponseModel } from "../../../app/models/ImportPropertiesResponseModel";
import ImportPropertiesGrid from "./ImportPropertiesGrid";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function ImportPropertiesPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [importPropertiesModel, setImportPropertiesModel] = useState<ImportPropertiesResponseModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); 
    let importID: string = "";
    if (params.has("ImportID")) {
      const id = params.get("ImportID");
      importID = id !== null ? id : "";
    }
    setIsLoading(true);
    apiAgent.Import.getImportProperties(importID)
      .then((response: ImportPropertiesResponseModel) => {
        let oImportProperties: ImportPropertiesResponseModel = response;
        setImportPropertiesModel(oImportProperties);
        //console.log("importPropertiesObject", oImportProperties);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Import Properties";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && importPropertiesModel) {
    return (
      <>
        <HeaderWrapper />

        <div className="container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Import", path: "/import/index" },
              { text: "Properties" },
            ]}
          />

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              <ImportPropertiesGrid ImportID={importPropertiesModel.importID} officeDD={importPropertiesModel.officeDD} />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
