import React from "react";
import PropTypes from "prop-types";
import { OMSDiaryNoteItem } from "../../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteItem";
import { OMSDiaryNoteCalendar } from "../../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteCalendar";

export class DiaryStepView extends React.Component {
    static propTypes = {
        PropertyID: PropTypes.string,
        UserID: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.noteCalenderRef = React.createRef();
        this.noteItemRef = React.createRef();
    };

    doSetState = (data) => {

    };

    doGetState = () => {
        return {};
    };

    onOpenDairyItemFunc = (item) => {
        if (item == null)
            this.noteItemRef.current.onOpenDairyNoteItem(null, "", this.noteCalenderRef.current.getSelectedDate());
        else
            this.noteItemRef.current.onOpenDairyNoteItem(item);
    };

    onDiaryItemSubmitCompleteFunc = (e) => {
        this.noteCalenderRef.current.onDiaryItemSubmit();
    };

    togglePinNote = (noteItem) => {
        if (this.props.togglePinNote !== undefined) {
            //console.log('DiaryStepView - ' + noteItem.DiaryID);
            this.props.togglePinNote(noteItem);
        }
    };


    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12" style={{ margin: 0, padding: 0 }} >
                        <OMSDiaryNoteCalendar
                            htmlID="noteCalender"
                            ID={this.props.PropertyID}
                            DNType={2}
                            Display="H"
                            ref={this.noteCalenderRef}
                            ShowAdd={true}
                            ReadOnly={(this.props.actions.indexOf('save') < 0)}
                            onOpenDairyItemFunc={this.onOpenDairyItemFunc}
                            DiaryActionTags={this.props.DiaryActionTags}
                            togglePinNote={this.togglePinNote}
                            super_access={this.props.super_access}
                        />
                        <OMSDiaryNoteItem htmlID="noteItem" IsReadOnly={(this.props.actions.indexOf('save') < 0)} UserID={this.props.UserID} ID={this.props.PropertyID} DNType={2} ref={this.noteItemRef} onDiaryItemSubmitCompleteFunc={this.onDiaryItemSubmitCompleteFunc} DiaryActionTags={this.props.DiaryActionTags} OnShowStart={this.props.OnShowStart} OnShowEnd={this.props.OnShowEnd} />
                    </div>
                </div>
            </div>
        );
    }
}