import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { ContactsResponseModel } from "../../app/models/ContactsResponseModel";
import { useStore } from "../../app/stores/store";
import HeaderWrapper from "../header/HeaderWrapper";
//import { ContactGrid } from "./OldContactGrid";
import ContactGrid from "./ContactGrid";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";

export default observer(function ContactPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState<ContactsResponseModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    setIsLoading(true);
    apiAgent.Contact.getContacts()
      .then((response: ContactsResponseModel) => {
        let oContact: ContactsResponseModel = response;
        setContact(oContact);
        //console.log("contact", oContact);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Contacts";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else
  if (!isLoading) {
    return (
      <>
        {/* <Header /> */}
        <HeaderWrapper />
        <div className="contact-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Contacts" },
            ]}
          />

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              {/* <ContactGrid contact={contact} /> */}
              <ContactGrid contact={contact} />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
