import React from "react";
import apiAgent from "../../app/api/apiAgent";
import { DataTableLoading } from "../../app/common/common-components/OMSDatatables";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { DataTableCompDynamic } from "../../app/common/common-components/OMSDatatables";
import { withRouter } from 'react-router-dom';

class ContentsGrid extends React.Component {
  //

  state = {
    category: "",
    ExtraParams: JSON.stringify({ Category: "" }),
  };

  onCreateClick = (e) => {
    this.props.history.push('/Library/SaveContent');
  };

  loadGrid = (requestParams) => {
    //console.log("inside loadGrid, requestParams=", requestParams);
    let data = apiAgent.Library.getContentsData(requestParams, this.state.category);
    //console.log("inside loadGrid, data=", data);
    return data;
  };

  handleContentCategoryDDChange = (e) => {
    this.setState({ category: e.newValue, ExtraParams: JSON.stringify({ Category: e.newValue }) });
  };

  handleRowClick = (data) => {
    this.props.history.push('/Library/SaveContent?ContentID=' + data.contentID + '&OfficeID=' + data.officeID);
  };

  componentDidMount() {
    //this.loadContentFromServer(this.state.category);
    //console.log("inside componentDidMount, this.props=", this.props);
  }

  render() {
    var columns = [
      { data: "title", title: "Title" },
      { data: "category", title: "Category" },
      { data: "created", title: "Created" },
      { data: "tags", title: "Tags" },
    ];

    return (
      <div className="col-md-12">
        <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-6">
                <div className="form-inline form-group boxLeftMenu">
                  <span>
                    <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;
                  </span>
                  <OMSDropdown
                    id="Category"
                    name="Category"
                    options={this.props.ContentCategories}
                    valueField="value"
                    labelField="text"
                    value={this.state.category}
                    onChange={this.handleContentCategoryDDChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="pull-right boxRightMenu">
                  <div className="form-inline">
                    {this.props.content_access ? (
                      <OMSButtonStyleL
                        type="button"
                        ui_icon=""
                        ui_type="create"
                        ui_text="add news"
                        ui_processing_text=""
                        processing={false}
                        disabled={false}
                        onClick={this.onCreateClick}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-body table-responsive">
            <DataTableCompDynamic
              id="contentTable"
              //Url="/Library/ContentsAjax"
              columns={columns}
              columnDefs={[]}
              ordering={false}
              onRowClick={this.handleRowClick}
              makeAjaxRequest={this.loadGrid}
              ExtraParams={this.state.ExtraParams}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ContentsGrid);
