import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import CountriesGrid from "./CountriesGrid";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function CountriesPage() {
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  document.title = "Base - Countries";

  return (
    <>
      <HeaderWrapper />

      <div className="container-fluid">
        <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Countries" },
            ]}
          />

        <div className="row">
          <div id="content" className="col-sm-12 col-md-12">
            <CountriesGrid />
          </div>
        </div>
      </div>
    </>
  );
});
