import { observer } from "mobx-react";
import HeaderWrapper from "../../header/HeaderWrapper";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";
import { Link } from "react-router-dom";

export default observer(function ServerErrorPage() {
  const {
    userStore: { userSession, logout, isLoggedIn },
    commonStore: { error },
  } = useStore();

  const [errorMsg, setErrorMsg] = useState(null);

  document.title = "Base - Server Error";

  useEffect(() => {
    console.log("Server Page Error : ", error);
    const errorMessage = error?.message ?? error?.toString() ?? "Unknown error";

    setErrorMsg(errorMessage.split("\n")[0]);
    const redirectIfNotLoggedIn = async () => {
      if (!isLoggedIn) {
        return <Redirect to="/login" />;
      }
    };
    redirectIfNotLoggedIn();
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <HeaderWrapper />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumbs={[{ text: "Home", path: "/" }, { text: "Server Error" }]}
        />
        <div className="row">
          <div id="content" className="col-md-12">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="text-center">
                <h1 style={{ fontSize: "95px" }}>Server Error</h1>
                <br />
                <br />
                <h2>Uh-oh! Something Went Wrong</h2>
                <br />
                <h4>
                  We're experiencing some technical difficulties. Our team is
                  working on it.
                </h4>
                <br />
                <h4 style={{ color: "red", fontWeight: "bold" }}>
                  {errorMsg ? errorMsg : null}
                </h4>
                <br />
                <br />
                {/*  <a href="/" className="btn btn-success-oms">Take Me Home</a> */}
                <Link to={"/"} className="btn btn-success-oms">
                  Take Me Home
                </Link>
                <br />
                <br />
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    </>
  );
});
