import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";

export class OMSSocialMediaBox extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.object,
    style: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    id: "",
    name: "",
    value: null,
    onChange: null,
    style: null,
  };

  state = {
    Contact: {
      facebook: this.props.value.facebook,
      twitter: this.props.value.twitter,
      linkedin: this.props.value.linkedin,
      instagram: this.props.value.instagram,
      privyseal: this.props.value.privyseal,
    },
  };

  handleLinkClick = (e) => {
    var value = window.$("#socailModelInput").val();
    if (value != "") {
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      if (!regex.test(value)) {
        var name = window.$("#socailModelInput").data("name");
        var url = "";
        if (name == "facebook") {
          url = "https://www.facebook.com/";
        } else if (name == "twitter") {
          url = "https://twitter.com/";
        } else if (name == "linkedin") {
          url = "https://www.linkedin.com/in/";
        } else if (name == "instagram") {
          url = "https://www.instagram.com/";
        } else if (name == "privyseal") {
          url = "https://www.privyseal.com/";
        }
        window.open(url + value);
      } else {
        window.open(value);
      }
    }
  };

  handleSaveClick = (e) => {
    window.$("#socialModel").modal("hide");
    var value = window.$("#socailModelInput").val();
    var name = window.$("#socailModelInput").data("name");
    var Contact = this.state.Contact;
    Contact[name] = value;
    this.setState({
      Contact: Contact,
    });
    if (this.props.onChange) this.props.onChange(name, value);
  };

  handleClick = (name) => {
    var value = "";
    var label = "";
    if (name == "facebook") {
      value = this.state.Contact.facebook;
      label = "Facebook handle";
    } else if (name == "twitter") {
      value = this.state.Contact.twitter;
      label = "Twitter handle";
    } else if (name == "linkedin") {
      value = this.state.Contact.linkedin;
      label = "Linkedin handle";
    } else if (name == "instagram") {
      value = this.state.Contact.instagram;
      label = "Instagram handle";
    } else if (name == "privyseal") {
      value = this.state.Contact.privyseal;
      label = "Privyseal handle";
    }

    window.$("#socailModelInput").val(value);
    window.$("#socailModelInput").data("name", name);
    window.$("#socailModelLabel").text(label);
    window.$("#socialModel").modal("show");
  };

  render() {
    return (
      <div>
        <span
          id="facebook"
          title="facebook"
          name="facebook"
          onClick={() => this.handleClick("facebook")}
        >
          <i
            className={
              "fa fa-facebook-square oms-social-handle" +
              (this.state.Contact.facebook == ""
                ? ""
                : " oms-social-handle-active")
            }
          ></i>
        </span>
        <span
          id="twitter"
          title="twitter"
          name="twitter"
          onClick={() => this.handleClick("twitter")}
        >
          <i
            className={
              "fa fa-twitter-square oms-social-handle" +
              (this.state.Contact.twitter == ""
                ? ""
                : " oms-social-handle-active")
            }
          ></i>
        </span>
        <span
          id="linkedin"
          title="linkedin"
          name="linkedin"
          onClick={() => this.handleClick("linkedin")}
        >
          <i
            className={
              "fa fa-linkedin-square oms-social-handle" +
              (this.state.Contact.linkedin == ""
                ? ""
                : " oms-social-handle-active")
            }
          ></i>
        </span>
        <span
          id="instagram"
          title="instagram"
          name="instagram"
          onClick={() => this.handleClick("instagram")}
        >
          <i
            className={
              "fa fa-instagram oms-social-handle" +
              (this.state.Contact.instagram == ""
                ? ""
                : " oms-social-handle-active")
            }
          ></i>
        </span>
        {this.state.Contact.privyseal !== undefined ? (
          <span
            id="privyseal"
            title="privyseal"
            name="privyseal"
            onClick={() => this.handleClick("privyseal")}
          >
            <i
              className={
                "fa fa-certificate oms-social-handle" +
                (this.state.Contact.privyseal == ""
                  ? ""
                  : " oms-social-handle-active")
              }
            ></i>
          </span>
        ) : null}
        <div id="socialModel" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
                <h4 className="modal-title">
                  <label id="socailModelLabel"></label>
                </h4>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="socailModelInput"
                    name="socailModelInput"
                  />
                  <button
                    type="button"
                    className="btn btn-default"
                    style={{ marginLeft: 10 }}
                    onClick={this.handleLinkClick}
                  >
                    <i className="fa fa-link"></i>
                  </button>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.handleSaveClick}
                >
                  <i className="fa fa-check"></i>Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  <i className="fa fa-times"></i>Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class OMSContactBoxView extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.object,
    style: PropTypes.object,
  };

  static defaultProps = {
    id: "",
    name: "",
    value: null,
    style: null,
  };

  state = {
    Contact: {
      facebook: this.props.value.facebook,
      twitter: this.props.value.twitter,
      linkedin: this.props.value.linkedin,
      instagram: this.props.value.instagram,
    },
  };

  OpenSocialLink = (name, value) => {
    if (value != "") {
      var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      if (!regex.test(value)) {
        var url = "";
        if (name == "facebook") {
          url = "https://www.facebook.com/";
        } else if (name == "twitter") {
          url = "https://twitter.com/";
        } else if (name == "linkedin") {
          url = "https://www.linkedin.com/in/";
        } else if (name == "instagram") {
          url = "https://www.instagram.com/";
        }
        window.open(url + value);
      } else {
        window.open(value);
      }
    }
  };

  OpenEmailClick = (email) => {
    window.open("mailto:" + email);
  };

  OpenCallClick = (phone) => {
    window.open("tel:" + phone);
  };
  OpenWhatsApp = (phone) => {
    const url = `https://wa.me/${phone}`;
    window.open(url, "_blank", "noreferrer");
  };

  render() {
    return (
      <div className="d-flex">
        {this.props.value.phone == "" ? null : (
          <span
            id="phone"
            name="phone"
            data-toggle="tooltip"
            data-placement="bottom"
            title={
              this.props.value.phone_dialing_code + " " + this.props.value.phone
            }
            onClick={() =>
              this.OpenCallClick(
                this.props.value.phone_dialing_code + this.props.value.phone
              )
            }
          >
            <i
              className={
                "fa fa-phone-square oms-social-handle" +
                (this.props.value.phone == ""
                  ? ""
                  : " oms-social-handle-active")
              }
            ></i>
          </span>
        )}
        {this.props.email == "" ? null : (
          <span
            id="email"
            name="email"
            data-toggle="tooltip"
            data-placement="bottom"
            title={this.props.email}
            onClick={() => this.OpenEmailClick(this.props.email)}
          >
            <i
              className={
                "fa fa-envelope-square oms-social-handle" +
                (this.props.email == "" ? "" : " oms-social-handle-active")
              }
            ></i>
          </span>
        )}
        {this.props.value.facebook == "" ? null : (
          <span
            id="facebook"
            name="facebook"
            onClick={() =>
              this.OpenSocialLink("facebook", this.props.value.facebook)
            }
          >
            <i
              className={
                "fa fa-facebook-square oms-social-handle" +
                (this.props.value.facebook == ""
                  ? ""
                  : " oms-social-handle-active")
              }
            ></i>
          </span>
        )}
        {this.props.value.twitter == "" ? null : (
          <span
            id="twitter"
            name="twitter"
            onClick={() =>
              this.OpenSocialLink("twitter", this.props.value.twitter)
            }
          >
            <i
              className={
                "fa fa-twitter-square oms-social-handle" +
                (this.props.value.twitter == ""
                  ? ""
                  : " oms-social-handle-active")
              }
            ></i>
          </span>
        )}
        {this.props.value.linkedin == "" ? null : (
          <span
            id="linkedin"
            name="linkedin"
            onClick={() =>
              this.OpenSocialLink("linkedin", this.props.value.linkedin)
            }
          >
            <i
              className={
                "fa fa-linkedin-square oms-social-handle" +
                (this.props.value.linkedin == ""
                  ? ""
                  : " oms-social-handle-active")
              }
            ></i>
          </span>
        )}
        {this.props.value.instagram == "" ? null : (
          <span
            id="instagram"
            name="instagram"
            onClick={() =>
              this.OpenSocialLink("instagram", this.props.value.instagram)
            }
          >
            <i
              className={
                "fa fa-instagram oms-social-handle" +
                (this.props.value.instagram == ""
                  ? ""
                  : " oms-social-handle-active")
              }
            ></i>
          </span>
        )}
        {this.props.value.phone == "" ? null : (
          <span
            id="whatsapp"
            className="d-flex align-items-center"
            name="whatsapp"
            data-toggle="tooltip"
            data-placement="bottom"
            title={
              this.props.value.phone_dialing_code + " " + this.props.value.phone
            }
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.OpenWhatsApp(
                this.props.value.phone_dialing_code + this.props.value.phone
              )
            }
          >
            <i
              className="fa fa-whatsapp"
              style={{
                fontSize: "30px",
                color: "#92ab1c"
              }}
            ></i>
            <strong style={{ margin: "0px", paddingLeft: "5px", color: "#92ab1c" }}>
              Chat on WhatsApp
            </strong>
          </span>
        )}
      </div>
    );
  }
}
