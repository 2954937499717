import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { ImportResponseModel } from "../../../app/models/ImportResponseModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import ImportGrid from "./ImportGrid";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function ImportPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [importModel, setImportModel] = useState<ImportResponseModel>();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    setIsLoading(true);
    apiAgent.Import.getImport()
      .then((response: ImportResponseModel) => {
        let oImport: ImportResponseModel = response;
        setImportModel(oImport);
        //console.log("importObject", oImport);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Import";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && importModel) {
    return (
      <>
        <HeaderWrapper />

        <div className="container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Import" },
            ]}
          />

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              <ImportGrid officeDD={importModel.officeDD} />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
