import React from "react";
import { OMSMultiselectTagDropdown2 } from "../../app/common/common-components/OMSMultiselectTagDropdown";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableCompDynamic } from "../../app/common/common-components/OMSDatatables";
import { OMSLeadActionModel } from "../../app/common/common-components/OMSInboxComponents/OMSLeadActionModel";
import { ReportGrid } from "./ReportGrid";
import apiAgent from "../../app/api/apiAgent";
import { RefFindNode } from "@fluentui/react-component-ref";
import { DataTableLoading } from "../../app/common/common-components/OMSDatatables";
import { withRouter } from 'react-router-dom';

class ContactGrid extends React.Component {
  constructor(props) {
    super(props);
    this.reportGridRef = React.createRef();
    this.leadBoxRef = React.createRef();
  }

  loadContactsFromServer = (view, tags, sources, referralstatus, contactViewType) => {
    //console.log("inside loadContactsFromServer", view, tags, sources, referralstatus, contactViewType);
    this.setState({
      view: view,
      tags: tags,
      sources: sources,
      ReferralStatus: referralstatus,
      mContactViewType: contactViewType,
      ExtraParams: JSON.stringify({ View: view, ReferralStatus: referralstatus, Tags: tags.join(), Sources: sources.join(), ContactViewType: contactViewType }),
    });
  };

  loadGrid = (requestParams) => {
    //return apiAgent.Setting.getAuditTrailData();
    //return apiAgent.Contact.getContactsData("", tags, sources, referralstatus, view, contactViewType)
    //console.log("inside loadGrid,this.state.ReferralStatus=", this.state.ReferralStatus);
    const contactsData = apiAgent.Contact.getContactsData("", this.state.tags, this.state.sources, this.state.ReferralStatus, this.state.view, this.state.mContactViewType);
    //console.log("inside loadGrid, contactsData=");
    contactsData.then(data => {
      console.log(data);
    });
    return contactsData;
  };

  loadLeadsGrid = (requestParams) => {
    //Url="/Inbox/ContactInboxAjax"
    //return apiAgent.Contact.getReferralContactsData("", this.state.tags, this.state.sources, this.state.referralstatus, this.state.view, this.state.contactViewType);
    return apiAgent.Contact.contactInboxAjax(this.state.mLeads, this.state.view, this.state.mLeadType, this.state.mLeadActionType);
  };

  loadLeadsFromServer = (view, leads, leadtype, leadactiontype) => {
    this.setState({
      hasTableShown2: true,
      view: view,
      mLeads: leads,
      mLeadType: leadtype,
      mLeadActionType: leadactiontype,
      ExtraParams2: JSON.stringify({ View: view, Leads: leads, LeadType: leadtype, LeadActionType: leadactiontype }),
    });
  };

  handleTagsChange = (tags, sources) => {
    //console.log("inside handleTagsChange");
    this.loadContactsFromServer(1, tags, sources, this.state.ReferralStatus, this.state.mContactViewType);
  };

  componentDidMount() {
    //console.log("inside componentDidMount of ContactGrid, props=",this.props);
    this.loadContactsFromServer(1, this.state.tags, this.state.sources, this.state.ReferralStatus, this.state.mContactViewType);
  }

  onCreateClick = (e) => {
    this.props.history.push('/Contacts/SaveContact');
  };

  onHandleReferralStatusChange = (e) => {
    //console.log("inside  onHandleReferralStatusChange", e.target.value);
    this.loadContactsFromServer(this.state.view, this.state.tags, this.state.sources, e.target.value, this.state.mContactViewType);
  };

  onChangePageView = (pageView) => {
    if (pageView == 100) {
      this.reportGridRef.onOpenReports();
      this.setState({ pageView: pageView });
    } else {
      this.setState({ pageView: 1 });
      this.onChangeView(pageView);
    }
  };

  onChangeView = (view) => {
    //console.log("inside onChangeView", view);
    if (view != 4) {
      this.loadContactsFromServer(view, this.state.tags, this.state.sources, this.state.ReferralStatus, this.state.mContactViewType);
    } else {
      this.loadLeadsFromServer(view, this.state.mLeads, this.state.mLeadType, this.state.mLeadActionType);
    }
  };

  handleLeadsChange = (e) => {
    var mLeads = e.newValue;
    //console.log("inside handleLeadsChange, view=", this.state.view);
    this.loadLeadsFromServer(this.state.view, mLeads, this.state.mLeadType, this.state.mLeadActionType);
  };

  handleLeadTypeChange = (e) => {
    var mLeadType = e.target.value;
    //console.log("inside handleLeadTypeChange, view=", this.state.view);
    this.loadLeadsFromServer(this.state.view, this.state.mLeads, mLeadType, this.state.mLeadActionType);
  };

  handleContactViewTypeChange = (e) => {
    //console.log("inside handleContactViewTypeChange");
    var mContactViewType = e.target.value;
    this.loadContactsFromServer(this.state.view, this.state.tags, this.state.sources, this.state.ReferralStatus, mContactViewType);
  };

  handleLeadActionTypeChange = (e) => {
    var mLeadActionType = 0;
    if (e.target.value == "on" && (e.target.id == "rbtnLeadActionTypeConverted" || e.target.id == "rbtnLeadActionTypeConverted1")) {
      mLeadActionType = 1;
    }
    //console.log("inside handleLeadActionTypeChange, view=", this.state.view);
    this.loadLeadsFromServer(this.state.view, this.state.mLeads, this.state.mLeadType, mLeadActionType);
  };

  RefreshLeadItems = () => {
    //console.log("inside RefreshLeadItems, view=", this.state.view);
    this.loadLeadsFromServer(this.state.view, this.state.mLeads, this.state.mLeadType, this.state.mLeadActionType);
  };

  handleRowClick = (data) => {
    this.props.history.push('/Contacts/SaveContact?ID=' + data.contactID);
  };

  handleRowClickReferal = (data) => {

    this.leadBoxRef.current.onInboxClick(data.userID, data.itemID, data.isRead, data.title);
  };

  onExportClick = () => {
    apiAgent.Contact.exportContactAjax()
      .then((response) => {
        var binaryData = [];
        //console.log(response);
        binaryData.push(response);
        window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }));
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/octet-stream" }));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `contacts_export.csv`);

        // Append to html link element page
        document.body.appendChild(link);
        //console.log("link inside export", link);
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })

      .catch((error) => {
        console.log("error inside export", error);
      })
      .finally(() => {});
  };

  state = {
    loading: false,
    data2: [],
    hasTableShown2: false,
    view: 1,
    pageView: 1,
    tags: [],
    sources: [],
    ReferralStatus: "R0",
    mLeads: 0,
    mLeadType: 2,
    mLeadActionType: 0,
    mContactViewType: 0,
    ExtraParams: JSON.stringify({ View: 1, ReferralStatus: "R0", Tags: "", Sources: "", ContactViewType: 0 }),
    ExtraParams2: JSON.stringify({ View: 0 }),
  };

  tag_format = (value) => {
    var officeContactTags = this.props.contact.officeContactTags;
    var userContactTags = this.props.contact.userContactTags;

    if (value == undefined || value == "" || officeContactTags == undefined || userContactTags == undefined) return "";

    var columnHtml = "";
    var splitValue = value.split(",");
    for (var i = 0; i < splitValue.length; i++) {
      var btnHtml = "";
      for (var j = 0; j < officeContactTags.length; j++) {
        if (officeContactTags[j].name == splitValue[i]) {
          btnHtml = '<span class="btn btn-tag-grid-label" style="background-color:' + officeContactTags[j].color + ';">' + splitValue[i] + "</span>";
          j = officeContactTags.length;
        }
      }

      if (btnHtml == "") {
        for (var k = 0; k < userContactTags.length; k++) {
          if (userContactTags[k].name == splitValue[i]) {
            btnHtml = '<span class="btn btn-my-tag-grid-label" style="background-color:' + userContactTags[k].color + ';">' + splitValue[i] + "</span>";
            k = userContactTags.length;
          }
        }
      }

      if (btnHtml != "") {
        columnHtml = columnHtml + btnHtml;
      }
    }
    return columnHtml;
  };

  source_format = (value) => {
    var contactSourceTypes = this.props.contact.contactSourceTypes;

    if (value == undefined || value == "" || contactSourceTypes == undefined) return "";

    for (var j = 0; j < contactSourceTypes.length; j++) {
      if (contactSourceTypes[j].name == value) {
        return '<span class="btn btn-tag-grid-label" style="background-color:' + contactSourceTypes[j].color + ';">' + value + "</span>";
      }
    }
    return "";
  };

  render() {
    //console.log("inside render",this);
    const columns = [
      { data: "name", title: "Name" },
      { data: "companyName", title: "Company"},
      { data: "phoneNumber", title: "Number" },
      { data: "emailAddress", title: "Email address" },
      { data: "source", title: "Source" },
      { data: "tagsString", title: "Tags", formatter: "tag_format" },
      { data: "lastActivity", title: "Activity" },
    ];

    const columnDefs = [
      {
        render: function (data, type, row) {
          //console.log("inside render before calling source_format",this);
          return this.source_format(data);
        }.bind(this),
        targets: 4,
      },
      {
        render: function (data, type, row) {
          return this.tag_format(data);
        }.bind(this),
        targets: 5,
      },
    ];

    const columns2 = [
      { data: "date", title: "Date" },
      { data: "referer", title: "Referer" },
      { data: "type", title: "Type" },
      { data: 'agent', title: 'Agent' },
      { data: "contactName", title: "Contact Name" },
      { data: "extPropertyRef", title: "Ext Property Ref" },
      { data: "propertyRef", title: "Property Ref" },
      { data: "actioned", title: "Actioned" },
    ];

    return (
      <div className="row">
        <div className="col-sm-12 hidden-md hidden-lg" style={{ marginBottom: 10 }}>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("1")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 1 ? <b>Active</b> : <span>Active</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("2")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 2 ? <b>Inactive</b> : <span>Inactive</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("4")}>
            <i className="fa fa-hand-stop-o" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 4 ? <b>Leads</b> : <span>Leads</span>}
          </span>
          <span
            className="dashboard-menu"
            style={{
              cursor: "pointer",
              display: this.props.contact.super_admin ? "" : "none",
            }}
            onClick={() => this.onChangePageView("3")}
          >
            <i className="fa fa-handshake-o" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 3 ? <b>Referrals</b> : <span>Referrals</span>}
          </span>
          <span
            className="dashboard-menu"
            style={{
              cursor: "pointer",
              display: this.props.contact.show_admin_leads ? "" : "none",
            }}
            onClick={() => this.onExportClick()}
          >
            <i className="fa fa-download" style={{ marginRight: 8 }}></i>
            <span>Export</span>
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer", display: "none" }} onClick={() => this.onChangePageView("100")}>
            <i className="fa fa-file-pdf-o" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 100 ? <b>Reports</b> : <span>Reports</span>}
          </span>
        </div>
        <div className="col-md-1 hidden-xs hidden-sm">
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("1")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 1 ? <b>Active</b> : <span>Active</span>}
          </span>
          <br />
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("2")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 2 ? <b>Inactive</b> : <span>Inactive</span>}
          </span>
          <br />
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("4")}>
            <i className="fa fa-hand-stop-o" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 1 && this.state.view == 4 ? <b>Leads</b> : <span>Leads</span>}
          </span>
          <br />
          {this.props.contact.super_admin && (
            <>
              <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangePageView("3")}>
                <i className="fa fa-handshake-o" style={{ marginRight: 8 }}></i>
                {this.state.pageView == 1 && this.state.view == 3 ? <b>Referrals</b> : <span>Referrals</span>}
              </span>
              <br />
            </>
          )}
          {this.props.contact.show_admin_leads && (
            <>
              <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onExportClick()}>
                <i className="fa fa-download" style={{ marginRight: 8 }}></i> Export
              </span>
              <br />
            </>
          )}
          <span className="dashboard-menu" style={{ cursor: "pointer", display: "none" }} onClick={() => this.onChangePageView("100")}>
            <i className="fa fa-file-pdf-o" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 100 ? <b>Reports</b> : <span>Reports</span>}
          </span>
        </div>
        <div className="col-md-11 col-sm-12" style={{ display: this.state.pageView == 1 ? "" : "none" }}>
          <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-inline">
                    <div className="form-group">
                      <span>
                        <i className="fa fa-filter" aria-hidden="true"></i>
                        &nbsp;
                      </span>
                      <select
                        id="mContactViewType"
                        name="mContactViewType"
                        style={{
                          display: this.state.view == 1 || this.state.view == 2 ? "inline-block" : "none",
                          marginLeft: 10,
                          marginRight: 5,
                          width: "auto",
                        }}
                        value={this.state.mContactViewType}
                        onChange={this.handleContactViewTypeChange}
                        className="form-control"
                      >
                        <option value="0">My Contacts</option>
                        <option value="1">All Contacts</option>
                      </select>
                      <div
                        className="groupDD"
                        style={{
                          display: this.state.view != 4 ? "inline-block" : "none",
                        }}
                      >
                        <OMSMultiselectTagDropdown2
                          id="tag-source"
                          name="tag-source"
                          array1={this.props.contact.officeContactTags}
                          array2={this.props.contact.userContactTags}
                          array3={this.props.contact.contactSourceTypes}
                          value={this.state.tags}
                          svalue={this.state.sources}
                          onChange={this.handleTagsChange}
                        />
                      </div>
                      &nbsp;
                      <select
                        id="ReferralStatus"
                        style={{
                          display: this.state.view == 3 ? "inline-block" : "none",
                          width: 145,
                        }}
                        className="form-control"
                        onChange={this.onHandleReferralStatusChange}
                        value={this.state.ReferralStatus}
                      >
                        <optgroup label="Received">
                          <option value="R0">Open</option>
                          <option value="R1">Closed (failure)</option>
                          <option value="R2">Closed (success)</option>
                        </optgroup>
                        <optgroup label="Sent">
                          <option value="S0">Open</option>
                          <option value="S1">Closed (failure)</option>
                          <option value="S2">Closed (success)</option>
                        </optgroup>
                      </select>
                      <OMSDropdown
                        id="mLeads"
                        name="mLeads"
                        style={{
                          display: this.state.view == 4 ? "inline-block" : "none",
                          width: 135,
                        }}
                        options={this.props.contact.usersDD}
                        valueField="value"
                        labelField="text"
                        value={this.state.mLeads}
                        onChange={this.handleLeadsChange}
                      />
                      <select
                        id="mLeadType"
                        name="mLeadType"
                        style={{
                          display: this.state.view == 4 ? "inline-block" : "none",
                          marginLeft: 10,
                          width: "auto",
                        }}
                        value={this.state.mLeadType}
                        onChange={this.handleLeadTypeChange}
                        className="form-control"
                      >
                        <option value="0">Inbox (Unread)</option>
                        <option value="1">Inbox (Read)</option>
                        <option value="2">Inbox (All)</option>
                      </select>
                      <div className="hidden-xs" style={{ display: "inline-block" }}>
                        <div
                          className="form-group"
                          style={{
                            display: this.state.view == 4 ? "" : "none",
                            marginLeft: 10,
                          }}
                        >
                          <input
                            type="radio"
                            id="rbtnLeadActionTypeNew"
                            name="rbtnLeadActionType"
                            checked={this.state.mLeadActionType === 0}
                            onChange={this.handleLeadActionTypeChange}
                          />
                          &nbsp;New
                          <input
                            type="radio"
                            id="rbtnLeadActionTypeConverted"
                            name="rbtnLeadActionType"
                            style={{ marginLeft: 10 }}
                            checked={this.state.mLeadActionType === 1}
                            onChange={this.handleLeadActionTypeChange}
                          />
                          &nbsp;Converted
                        </div>
                      </div>
                      <div className="visible-xs">
                        <div
                          className="form-group"
                          style={{
                            display: this.state.view == 4 ? "" : "none",
                            marginTop: 15,
                          }}
                        >
                          <input
                            type="radio"
                            id="rbtnLeadActionTypeNew1"
                            name="rbtnLeadActionType1"
                            checked={this.state.mLeadActionType === 0}
                            onChange={this.handleLeadActionTypeChange}
                          />
                          &nbsp;New
                          <input
                            type="radio"
                            id="rbtnLeadActionTypeConverted1"
                            name="rbtnLeadActionType1"
                            style={{ marginLeft: 10 }}
                            checked={this.state.mLeadActionType === 1}
                            onChange={this.handleLeadActionTypeChange}
                          />
                          &nbsp;Converted
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="pull-right hidden-xs">
                    <div className="form-inline">
                      {this.props.contact.actions.indexOf("create") != -1 && (
                        <OMSButtonStyleL
                          type="button"
                          ui_icon=""
                          ui_type="create"
                          ui_text="add contact"
                          ui_processing_text=""
                          processing={false}
                          disabled={false}
                          onClick={this.onCreateClick}
                        />
                      )}
                    </div>
                  </div>
                  <div className="visible-xs">
                    <div className="form-inline" style={{ marginTop: 25 }}>
                      {this.props.contact.actions.indexOf("create") != -1 && (
                        <OMSButtonStyleL
                          type="button"
                          style={{
                            width: 140,
                            display: "inline-block",
                            marginTop: -5,
                          }}
                          ui_icon=""
                          ui_type="create"
                          ui_text="add contact"
                          ui_processing_text=""
                          processing={false}
                          disabled={false}
                          onClick={this.onCreateClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-body">
              <div className="table-responsive" style={{ display: this.state.view != 4 ? "block" : "none" }}>
                {/* <DataTableCompDynamic id="contactTable" Url="/Contacts/IndexAjax" columns={columns} columnDefs={columnDefs} order={[[0, "asc"]]} serverSide={false} onRowClick={this.handleRowClick} ExtraParams={this.state.ExtraParams} />*/}

                <DataTableCompDynamic
                  id="contactTable"
                  //Url="/Contacts/IndexAjax"
                  columns={columns}
                  columnDefs={columnDefs}
                  order={[[0, "asc"]]}
                  serverSide={false}
                  makeAjaxRequest={this.loadGrid}
                  onRowClick={this.handleRowClick}
                  ExtraParams={this.state.ExtraParams}
                />

                <DataTableLoading loading={this.state.loading} />
              </div>
              <div className="table-responsive" style={{ display: this.state.view == 4 ? "block" : "none" }}>
                <DataTableCompDynamic
                  id="referalTable"
                  //Url="/Inbox/ContactInboxAjax"
                  hasTableShown={this.state.hasTableShown2}
                  columns={columns2}
                  columnDefs={[]}
                  ordering={false}
                  serverSide={false}
                  makeAjaxRequest={this.loadLeadsGrid}
                  onRowClick={this.handleRowClickReferal}
                  ExtraParams={this.state.ExtraParams2}
                />
                <DataTableLoading loading={this.state.loading} />
              </div>
              <div id="lead_container">
                <OMSLeadActionModel
                  IsReadOnly={(this.props.contact.actions.indexOf("create") < 0)}
                  OfficeContactTags={this.props.contact.officeContactTags}
                  UserContactTags={this.props.contact.userContactTags}
                  ContactSourceTypes={this.props.contact.contactSourceTypes}
                  DialingCode={this.props.contact.dialingCode}
                  DialingCodeDD={this.props.contact.dialingCodeDD}
                  ref={this.leadBoxRef}
                  ID="myLeadBox"
                  UserID=""
                  View={1}
                  RefreshFun={this.RefreshLeadItems}
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.contact.super_admin ? <div className="col-md-11 col-sm-12" style={{ display: this.state.pageView == 100 ? "" : "none" }}></div> : null}
      </div>
    );
  }
}

export default withRouter(ContactGrid);
