import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import { Modal, Button, Table } from "react-bootstrap";

const HistoricDeals: React.FC = () => {
  const { dealStore } = useStore();
  const { deals, fetchDeals, isLoading, hasDeals } = dealStore;
  const [columns, setColumns] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const dataTableRef = useRef<DataTableComp>(null);

  useEffect(() => {
    console.log("inside useEffect 1");
    if (!hasDeals) {
      fetchDeals();
    }
  }, [fetchDeals, hasDeals]);

  useEffect(() => {
    if (deals.length > 0) {
      console.log("inside useEffect 2");
      const cols = Object.keys(deals[0]).map((key, index) => ({
        data: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
        defaultContent: "", // Handle missing data
        visible: index < 8,
      }));
      setColumns(cols);
    }
  }, [deals]);

  useEffect(() => {
    console.log("inside useEffect 3");
    if (dataTableRef.current && columns.length > 0) {
      dataTableRef.current.reloadTableData(deals);
    }
  }, [deals, columns]);

  const handleRowClick = (rowData: any) => {
    setSelectedRowData(rowData);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRowData(null);
  };

  const renderRowData = (data: any) => (
    <Table striped bordered hover>
      <tbody>
        {Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            <td>
              <strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong>
            </td>
            <td>{String(value)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

//   const onRefreshDataClick = () => {
//     console.log("inside onRefreshDataClick");
//     fetchDeals();
//   };

  return (
    <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
      <div className="panel-heading" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div className="form-inline form-group boxLeftMenu">
          {/* Add other elements if needed */}
        </div>
        {/* <Button onClick={onRefreshDataClick}>Refresh Data</Button> */}
        <br />
      </div>
      <div className="panel-body table-responsive left-align" style={{ overflowX: "auto" }}>
        {!isLoading && columns.length > 0 && (
          <DataTableComp
            ref={dataTableRef}
            id="historicDealsTable"
            data={deals}
            columns={columns}
            columnDefs={[]}
            order={[[0, "desc"]]}
            showSearchBox={true}
            responsive={true}
            onRowClick={handleRowClick}
            sortable={false}
          />
        )}
        <DataTableLoading loading={isLoading} />
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Deal Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedRowData && renderRowData(selectedRowData)}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default observer(HistoricDeals);
