import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import bootbox from "bootbox";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSNumericBox } from "../../../app/common/common-components/OMSComponent";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSFavourite } from "../../../app/common/common-components/OMSFavourite";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";
import  CountryAreaBox  from "./CountryAreaBox";
import { CountryModel } from "../../../app/models/CountryModel";
import $ from "jquery";
import { Field, Formik } from "formik";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { useHistory } from 'react-router-dom';

interface Props {
  data?: CountryModel;
  iscreate: boolean;
  setConfirmationModalProps: (confirmationModalProps: ConfirmationModalProps) => void;
}

function CountryBox(props: Props) {
  const [name, setName] = useState(props.data!.name);
  const [abbreviation, setAbbreviation] = useState(props.data!.abbreviation);
  const [currencyCode, setCurrencyCode] = useState(props.data!.currencyCode);
  const [currencyName, setCurrencyName] = useState(props.data!.currencyName);
  const [currencyDisplay, setCurrencyDisplay] = useState(props.data!.currencyDisplay);
  const [dialingCode, setDialingCode] = useState(props.data!.dialingCode);
  const [propertyTypes, setPropertyTypes] = useState<any>(JSON.parse(props.data!.propertyTypes));
  const [ownershipTypes, setOwnershipTypes] = useState<any>(JSON.parse(props.data!.ownershipTypes));
  const [mandateTypes, setMandateTypes] = useState<any>(JSON.parse(props.data!.mandateTypes));
  const [agentTypes, setAgentTypes] = useState<any>(JSON.parse(props.data!.agentTypes));
  const [salesTaxPercentage, setSalesTaxPercentage] = useState(props.data!.salesTaxPercentage);
  const [sMSRate, setSMSRate] = useState(props.data!.smsRate);
  const [whatsappRate, setWhatsappRate] = useState(props.data!.whatsappRate);
  const [emailRate, setEmailRate] = useState(props.data!.emailRate);
  const [sMSRateReceive, setSMSRateReceive] = useState(props.data!.smsRateReceive);
  const [whatsappRateReceive, setWhatsappRateReceive] = useState(props.data!.whatsappRateReceive);
  const [emailRateReceive, setEmailRateReceive] = useState(props.data!.emailRateReceive);
  const [timezone, setTimezone] = useState(props.data!.timezone);
  const [sMSEnabled, setSMSEnabled] = useState(props.data!.smsEnabled);
  const [classificationDD, setClassificationDD] = useState<{}[]>([]);
  const [classification, setClassification] = useState("");
  const [ownershipDD, setOwnershipDD] = useState<{}[]>([]);
  const [mandateTypesDD, setMandateTypesDD] = useState<{}[]>([]);
  const [agentTypesDD, setAgentTypesDD] = useState<{}[]>([]);
  const [typeDD, setTypeDD] = useState<{}[]>([]);
  const [type, setType] = useState("");
  const [grade, setGrade] = useState("");
  const [gradeDD, setGradeDD] = useState<{}[]>([]);
  const [ownership, setOwnership] = useState("");
  const [mandateType, setMandateType] = useState("");
  const [agentType, setAgentType] = useState("");
  const [mFor, setmFor] = useState(0);
  const [mName, setmName] = useState("");
  const [mName2, setmName2] = useState("");
  const [buttonStatus, setButtonStatus] = useState({
    disabled: false,
    processing_save: false,
    processing_delete: false,
  });

  let history = useHistory();

  const [activeTabID, setActiveTabID] = useState("currencydialing");

  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>({
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  });

  const openCloseDeleteModal = (isOpen: boolean) => {
    let varConfirmationModalProps = confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const showPopupAndDeleteOnConfirmation = (title: string, body: string, callback: Function) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed: boolean) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    setConfirmationModalProps(varConfirmationModalProps);
  };

  let tabContent = [{ link: "#currencydialing", title: "Currency & dialing" }];

  if (props.data!.name !== "") {
    tabContent = [
      { link: "#currencydialing", title: "Currency & dialing" },
      { link: "#areas", title: "Areas" },
      { link: "#propertytypes", title: "Property types" },
      { link: "#ownershiptypes", title: "Custom types" },
      { link: "#settings", title: "Settings" },
    ];
  }

  const loadOwnershipDD = (ownershipTypes: any) => {
    let ownershipDD = [];
    ownershipDD.push({ text: "- Select -", value: "" });
    ownershipDD = ownershipDD.concat(
      ownershipTypes.map(function (item: any) {
        return { text: item, value: item };
      })
    );
    setOwnership("");
    setOwnershipDD(ownershipDD);
  };

  const loadAgentTypesDD = (agentTypes: any) => {
    let agentTypesDD = [];
    agentTypesDD.push({ text: "- Select -", value: "" });
    agentTypesDD = agentTypesDD.concat(
      agentTypes.map(function (item: any) {
        return { text: item, value: item };
      })
    );
    setAgentType("");
    setAgentTypesDD(agentTypesDD);
  };

  const loadMandateTypesDD = (mandateTypes: any) => {
    let mandateTypesDD = [];
    mandateTypesDD.push({ text: "- Select -", value: "" });
    mandateTypesDD = mandateTypesDD.concat(
      mandateTypes.map(function (item: any) {
        return { text: item, value: item };
      })
    );
    setMandateType("");
    setMandateTypesDD(mandateTypesDD);
  };

  const loadClassificationDD = (propertyTypes: any) => {
    let classificationDD = [];
    classificationDD.push({ text: "- Select -", value: "" });
    classificationDD = classificationDD.concat(
      propertyTypes.map(function (item: any) {
        return { text: item.classification, value: item.classification };
      })
    );
    setClassification("");
    setClassificationDD(classificationDD);
    setPropertyTypes(propertyTypes);
    setType("");
    setTypeDD([]);
  };

  useEffect(() => {
    //console.log("inside useEffect: ownershipTypes", ownershipTypes);
    loadClassificationDD(propertyTypes);
    loadOwnershipDD(ownershipTypes);
    loadMandateTypesDD(mandateTypes);
    loadAgentTypesDD(agentTypes);
  }, []);

  const loadTypeDD = (classification: any, propertyTypes: any) => {
    let typeDD = [];
    typeDD.push({ text: "- Select -", value: "" });
    for (let i = 0; i < propertyTypes.length; i++) {
      if (propertyTypes[i].classification === classification) {
        typeDD = typeDD.concat(
          propertyTypes[i].types.map(function (item: any) {
            return { text: item, value: item };
          })
        );
      }
    }

    let gradeDD = [];
    gradeDD.push({ text: "- Select -", value: "" });

    for (let i = 0; i < propertyTypes.length; i++) {
      if (propertyTypes[i].classification === classification) {
        gradeDD = gradeDD.concat(
          propertyTypes[i].grades.map(function (item: any) {
            return { text: item.Value, value: item.Key };
          })
        );
      }
    }

    setType("");
    setGrade("");
    setGradeDD(gradeDD);
    setTypeDD(typeDD);
    setClassification(classification);
    setPropertyTypes(propertyTypes);
  };

  const handlemNameChange = (e: any) => {
    setmName(e.target.value);
  };

  const handlemName2Change = (e: any) => {
    setmName2(e.newValue);
  };

  const handleSMSRateChange = (name: any, value: any) => {
    setSMSRate(value);
  };

  const handleWhatsappRateChange = (name: any, value: any) => {
    setWhatsappRate(value);
  };

  const handleEmailRateChange = (name: any, value: any) => {
    setEmailRate(value);
  };

  const handleSMSRateReceiveChange = (name: any, value: any) => {
    setSMSRateReceive(value);
  };

  const handleWhatsappRateReceiveChange = (name: any, value: any) => {
    setWhatsappRateReceive(value);
  };

  const handleEmailRateReceiveChange = (name: any, value: any) => {
    setEmailRateReceive(value);
  };

  const handleSalesTaxPercentageChange = (name: any, value: any) => {
    setSalesTaxPercentage(value);
  };

  const handleTimeZoneChange = (e: any) => {
    //console.log("handleTimeZoneChange", e.target.value);
    setTimezone(e.target.value);
  };

  const handleClassificationDDChange = (e: any) => {
    loadTypeDD(e.newValue, propertyTypes);
  };

  const handleTypeDDChange = (e: any) => {
    setType(e.newValue);
  };

  const handleGradeDDChange = (e: any) => {
    setGrade(e.newValue);
  };

  const handleOwnershipChange = (e: any) => {
    setOwnership(e.newValue);
  };

  const handleMandateTypeChange = (e: any) => {
    setMandateType(e.newValue);
  };

  const handleAgentTypeChange = (e: any) => {
    setAgentType(e.newValue);
  };

  const executeDeleteOwnershipClick = () => {
    let index = -1;
    let OwnershipTypes = ownershipTypes;
    for (let i = 0; i < OwnershipTypes.length; i++) {
      if (OwnershipTypes[i] === ownership) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      OwnershipTypes.splice(index, 1);
      setOwnershipTypes(OwnershipTypes);
      loadOwnershipDD(ownershipTypes);
    }
  };

  const executeDeleteMandateTypeClick = () => {
    let index = -1;
    let MandateTypes = mandateTypes;
    for (let i = 0; i < MandateTypes.length; i++) {
      if (MandateTypes[i] === mandateType) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      MandateTypes.splice(index, 1);
      setMandateTypes(MandateTypes);
      loadMandateTypesDD(mandateTypes);
    }
  };

  const executeDeleteAgentTypeClick = () => {
    let index = -1;
    let AgentTypes = agentTypes;
    for (let i = 0; i < AgentTypes.length; i++) {
      if (AgentTypes[i] === agentType) {
        index = i;
        break;
      }
    }
    if (index !== -1) {
      AgentTypes.splice(index, 1);
      setAgentTypes(AgentTypes);
      loadAgentTypesDD(agentTypes);
    }
  };

  const executeDeleteClassificationClick = () => {
    let index = -1;
    let PropertyTypes = propertyTypes;

    for (let i = 0; i < PropertyTypes.length; i++) {
      if (PropertyTypes[i].classification === classification) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      PropertyTypes.splice(index, 1);
      loadClassificationDD(PropertyTypes);
    }
  };

  const executeDeleteTypeClick = () => {
    //console.log("inside executeDeleteTypeClick");
    let parentIndex = -1;
    let index = -1;
    let PropertyTypes = propertyTypes;

    for (let i = 0; i < PropertyTypes.length; i++) {
      if (PropertyTypes[i].classification === classification) {
        parentIndex = i;
        for (let j = 0; j < PropertyTypes[i].types.length; j++) {
          if (PropertyTypes[i].types[j] === type) {
            index = j;
            break;
          }
        }
        break;
      }
    }

    if (index !== -1 && parentIndex !== -1) {
      propertyTypes[parentIndex].types.splice(index, 1);
      loadTypeDD(classification, propertyTypes);
    }
  };

  const executeDeleteGradeClick = () => {
    let parentIndex = -1;
    let index = -1;
    let PropertyTypes = propertyTypes;
    for (let i = 0; i < PropertyTypes.length; i++) {
      if (PropertyTypes[i].classification === classification) {
        parentIndex = i;
        for (let j = 0; j < PropertyTypes[i].grades.length; j++) {
          if (PropertyTypes[i].grades[j]["Key"] === grade) {
            index = j;
            break;
          }
        }
        break;
      }
    }

    if (index !== -1 && parentIndex !== -1) {
      propertyTypes[parentIndex].grades.splice(index, 1);
      loadTypeDD(classification, propertyTypes);
    }
  };

  const executeDeleteClick = () => {
    setButtonStatus({
      disabled: true,
      processing_save: false,
      processing_delete: true,
    });

    apiAgent.Setting.deleteCountryAjax(name)
      .then((response) => {
        history.push("/settings/countries");
      })
      .catch((error) => {
        toast.info("Fail");
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const onDeleteOwnershipClick = (e: any) => {
    let title = "Delete ownership?";
    let body = "Do you want to delete ownership now? This cannot be undone.";
    let callback = executeDeleteOwnershipClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const onDeleteClick = (e: any) => {
    // console.log("inside onDeleteClick");
    let title = "Delete country?";
    let body = "Do you want to delete country now? This cannot be undone.";
    let callback = executeDeleteClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
    // bootbox.confirm({
    //   title: "Delete country?",
    //   message: "Do you want to delete country now? This cannot be undone.",
    //   buttons: {
    //     cancel: {
    //       label: '<i class="fa fa-times"></i> Cancel',
    //     },
    //     confirm: {
    //       label: '<i class="fa fa-check"></i> Confirm',
    //     },
    //   },
    //   callback: function (result: any) {
    //     if (result) {
    //       executeDeleteClick(result);
    //     }
    //   },
    // });
  };

  const onDeleteMandateTypeClick = (e: any) => {
    let title = "Delete mandate type?";
    let body = "Do you want to delete mandate type now? This cannot be undone.";
    let callback = executeDeleteMandateTypeClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const onDeleteAgentTypeClick = (e: any) => {
    let title = "Delete agent type?";
    let body = "Do you want to delete agent type now? This cannot be undone.";
    let callback = executeDeleteAgentTypeClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const onDeleteClassificationClick = (e: any) => {
    let title = "Delete classification?";
    let body = "Do you want to delete classification now? This cannot be undone.";
    let callback = executeDeleteClassificationClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const onDeleteTypeClick = (e: any) => {
    let title = "Delete type?";
    let body = "Do you want to delete type now? This cannot be undone.";
    let callback = executeDeleteTypeClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const onDeleteGradeClick = (e: any) => {
    let title = "Delete grade?";
    let body = "Do you want to delete grade now? This cannot be undone.";
    let callback = executeDeleteGradeClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const onCreateOwnershipClick = (e: any) => {
    setmFor(-1);
    setmName("");
    window.$("#SavePropertyTypeModel").modal("show");
  };

  const onCreateMandateTypeClick = (e: any) => {
    setmFor(-2);
    setmName("");
    window.$("#SavePropertyTypeModel").modal("show");
  };

  const onCreateAgentTypeClick = (e: any) => {
    setmFor(-3);
    setmName("");
    window.$("#SavePropertyTypeModel").modal("show");
  };

  const onCreateClassificationClick = (e: any) => {
    setmFor(0);
    setmName("");
    window.$("#SavePropertyTypeModel").modal("show");
  };

  const onCreateTypeClick = (e: any) => {
    setmFor(1);
    setmName("");
    window.$("#SavePropertyTypeModel").modal("show");
  };

  const onCreateGradeClick = (e: any) => {
    setmFor(5);
    setmName("");
    setmName2("");
    window.$("#SavePropertyTypeModel").modal("show");
  };

  const onSavePropertyTypeModelClick = (e: any) => {
    if (!mName.trim()) {
      toast.info("Please enter name");
      return;
    }

    if (mFor === -3) {
      // save agent type
      let index = -1;
      let AgentTypes = agentTypes;
      for (let i = 0; i < AgentTypes.length; i++) {
        if (AgentTypes[i] === mName) {
          index = i;
          break;
        }
      }

      if (index !== -1) {
        toast.info("Agent type with same name already exists");
        return;
      }
      AgentTypes.push(mName);
      setAgentTypes(AgentTypes);
      loadAgentTypesDD(AgentTypes);
      window.$("#SavePropertyTypeModel").modal("hide");
    } else if (mFor === -2) {
      // save mandate type
      let index = -1;
      let MandateTypes = mandateTypes;
      for (let i = 0; i < MandateTypes.length; i++) {
        if (MandateTypes[i] === mName) {
          index = i;
          break;
        }
      }

      if (index !== -1) {
        toast.error("Mandate type with same name already exists");
        return;
      }
      //MandateTypes=MandateTypes.concat(MandateTypes,mName);
      MandateTypes.push(mName);
      setMandateTypes(MandateTypes);
      loadMandateTypesDD(MandateTypes);
      //this.loadMandateTypeDD(MandateTypes);
      window.$("#SavePropertyTypeModel").modal("hide");
    } else if (mFor === -1) {
      // save ownership
      let index = -1;
      //console.log("inside save ownership, ownershipTypes", ownershipTypes);
      let OwnershipTypes = ownershipTypes;
      for (let i = 0; i < OwnershipTypes.length; i++) {
        if (OwnershipTypes[i] === mName) {
          index = i;
          break;
        }
      }

      if (index !== -1) {
        toast.error("Ownershop with same name already exists");
        return;
      }
      OwnershipTypes.push(mName);
      setOwnershipTypes(OwnershipTypes);
      loadOwnershipDD(OwnershipTypes);
      window.$("#SavePropertyTypeModel").modal("hide");
    } else if (mFor === 0) {
      //console.log("inside save classification..", e, propertyTypes);
      // save classification..
      let index = -1;
      let PropertyTypes = propertyTypes;
      for (let i = 0; i < PropertyTypes.length; i++) {
        if (PropertyTypes[i].classification === mName) {
          index = i;
          break;
        }
      }

      if (index !== -1) {
        toast.error("Classification with same name already exists");
        return;
      }
      PropertyTypes.push({ classification: mName, types: [] });
      //console.log("inside save classification..Property Types", PropertyTypes);
      //setPropertyTypes(PropertyTypes);
      loadClassificationDD(PropertyTypes);
      window.$("#SavePropertyTypeModel").modal("hide");
    } else if (mFor === 5) {
      let displayText = mName;
      let valueText = displayText;

      if (mName2) valueText = mName2;

      // save grade
      let parentIndex = -1;
      let index = -1;
      let PropertyTypes = propertyTypes;
      for (let i = 0; i < PropertyTypes.length; i++) {
        if (PropertyTypes[i].classification === classification) {
          parentIndex = i;
          for (let j = 0; j < PropertyTypes[i].grades.length; j++) {
            if (PropertyTypes[i].grades[j]["Key"] === valueText) {
              index = i;
              break;
            }
          }
          break;
        }
      }

      if (index !== -1) {
        toast.error("Grade with same value(name) already exists");
        return;
      }

      let item: any = {};
      item.Key = valueText;
      item.Value = displayText;

      PropertyTypes[parentIndex].grades.push(item);
      loadTypeDD(classification, PropertyTypes);
      window.$("#SavePropertyTypeModel").modal("hide");
    } else {
      // save type
      let parentIndex = -1;
      let index = -1;
      let PropertyTypes = propertyTypes;
      for (let i = 0; i < PropertyTypes.length; i++) {
        if (PropertyTypes[i].classification === classification) {
          parentIndex = i;
          for (let j = 0; j < PropertyTypes[i].types.length; j++) {
            if (PropertyTypes[i].types[i] === mName) {
              index = i;
              break;
            }
          }
          break;
        }
      }

      if (index !== -1) {
        toast.error("Type with same name already exists");
        return;
      }

      PropertyTypes[parentIndex].types.push(mName);
      loadTypeDD(classification, PropertyTypes);
      window.$("#SavePropertyTypeModel").modal("hide");
    }
  };

  const onCancelClick = (e: any) => {
    history.push("/Settings/Countries");
  };

  const doValidate = (values: CountryModel) => {
    let { name, abbreviation, currencyCode, currencyName, currencyDisplay, dialingCode } = values;
    let failed = false;

    if (!name.trim()) {
      toast.info("Please enter country name");
      failed = true;
    }

    if (!abbreviation.trim()) {
      toast.info("Please enter abbreviation");
      failed = true;
    }

    if (!currencyCode.trim()) {
      toast.info("Please enter currency code");
      failed = true;
    }

    if (!currencyName.trim()) {
      toast.info("Please enter currency name");
      failed = true;
    }

    if (!currencyDisplay.trim()) {
      toast.info("Please enter currency display");
      failed = true;
    }

    if (!dialingCode.trim()) {
      toast.info("Please enter dialing code");
      failed = true;
    }

    return !failed;
  };

  const handleSubmit = (values: CountryModel) => {
    //console.log("Inside handleSubmit Property Types from state", JSON.stringify(propertyTypes));
    //console.log("Inside handleSubmit Property Types from values", values.propertyTypes);
    values.propertyTypes = JSON.stringify(propertyTypes);
    values.ownershipTypes = JSON.stringify(ownershipTypes);
    values.mandateTypes = JSON.stringify(mandateTypes);
    values.agentTypes = JSON.stringify(agentTypes);
    values.salesTaxPercentage = salesTaxPercentage;
    //console.log("Inside handlesubmit, timezone: ", timezone);
    //debugger;
    values.timezone = timezone;

    values.smsEnabled = sMSEnabled;

    values.smsRate = sMSRate;
    values.whatsappRate = whatsappRate;
    values.emailRate = emailRate;
    values.smsRateReceive = sMSRateReceive;
    values.whatsappRateReceive = whatsappRateReceive;
    values.emailRateReceive = emailRateReceive;

    if (!doValidate(values)) return;

    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    apiAgent.Setting.saveCountryAjax(values)
      .then((response) => {
        history.push("/settings/countries");
      })
      .catch((error) => {
        toast.info("Fail");
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const handleCheckboxChanged = (e: any) => {
    setSMSEnabled(!sMSEnabled);
  };

  return (
    <div>
      <Formik
        initialValues={{
          name: props.data!.name,
          abbreviation: props.data!.abbreviation,
          currencyCode: props.data!.currencyCode,
          currencyName: props.data!.currencyName,
          currencyDisplay: props.data!.currencyDisplay,
          dialingCode: props.data!.dialingCode,
          templateSettings: props.data!.templateSettings,
          ownershipTypes: props.data!.ownershipTypes,
          mandateTypes: props.data!.mandateTypes,
          propertyTypes: props.data!.propertyTypes,
          agentTypes: props.data!.agentTypes,
          salesTaxPercentage: props.data!.salesTaxPercentage,
          timezone: props.data!.timezone,
          smsRate: props.data!.smsRate,
          whatsappRate: props.data!.whatsappRate,
          emailRate: props.data!.emailRate,
          smsRateReceive: props.data!.smsRateReceive,
          whatsappRateReceive: props.data!.whatsappRateReceive,
          emailRateReceive: props.data!.emailRateReceive,
          smsEnabled: props.data!.smsEnabled,
          favourite: props.data!.favourite,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting, errors, values }) => (
          <form className="SaveCountryForm" onSubmit={handleSubmit}>
            <ConfirmationModalPopup {...confirmationModalProps} />
            <div className="col-sm-12">
              <div className="panel panel-default oms-box">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-md-12">
                      <OMSFavourite favourite={props.data!.favourite} /> &nbsp;
                      <label>{props.data!.name !== "" ? name : "CREATE"}</label>
                    </div>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="form-group col-md-12">
                    <label className="control-label" htmlFor="CountryName">
                      Country name
                    </label>
                    <div className="controls">
                      {props.iscreate ? (
                        <Field type="text" name="name" className="form-control required-control text-350" placeholder="Name" />
                      ) : (
                        <label className="form-control text-350">{name}</label>
                      )}
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="Abbreviation">Abbreviation</label>
                    <Field type="text" name="abbreviation" className="form-control required-control text-100" placeholder="Abbreviation" />
                  </div>
                  <div className="col-md-12">
                    <OMSTabBox id="countryTabs" value={tabContent} setActiveTabId={setActiveTabID}></OMSTabBox>
                    <div className="tab-content">
                      <div id="currencydialing" className={activeTabID === "currencydialing" ? "tab-pane fade in active" : "tab-pane fade"}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="CurrencyCode">Currency code</label>
                              <Field type="text" name="currencyCode" className="form-control required-control text-100" placeholder="Currency Code" />
                            </div>
                            <div className="form-group">
                              <label htmlFor="CurrencyName">Currency name</label>
                              <Field type="text" name="currencyName" className="form-control required-control text-350" placeholder="Currency Name" />
                            </div>
                            <div className="form-group">
                              <label htmlFor="CurrencyDisplay">Currency display</label>
                              <Field type="text" name="currencyDisplay" className="form-control required-control text-100" placeholder="Currency Display" />
                            </div>
                            <div className="form-group">
                              <label htmlFor="DialingCode">Dialing code</label>
                              <Field type="text" name="dialingCode" className="form-control required-control text-100" placeholder="DialingCode" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="areas" className={activeTabID === "areas" ? "tab-pane fade in active" : "tab-pane fade"}>
                        <CountryAreaBox CountryName={props.data!.name} setConfirmationModalProps={props.setConfirmationModalProps} />
                      </div>
                      <div id="propertytypes" className={activeTabID === "propertytypes" ? "tab-pane fade in active" : "tab-pane fade"}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="ClassificationDD">Classification</label>
                              <OMSDropdown
                                id="ClassificationDD"
                                name="classificationDD"
                                options={classificationDD}
                                valueField="value"
                                labelField="text"
                                value={classification}
                                onChange={handleClassificationDDChange}
                              />
                              <div style={{ marginTop: 5 }}>
                                <OMSButtonStyleL
                                  type="button"
                                  style={{ marginRight: 10 }}
                                  ui_icon="fa-plus"
                                  ui_type="delete"
                                  ui_text="Add"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={buttonStatus.disabled}
                                  onClick={onCreateClassificationClick}
                                />
                                {classification === "" ? null : (
                                  <OMSButtonStyleL
                                    type="button"
                                    ui_icon="fa-trash-o"
                                    ui_type="delete"
                                    ui_text="Delete"
                                    ui_processing_text="Deleting.."
                                    processing={buttonStatus.processing_delete}
                                    disabled={buttonStatus.disabled}
                                    onClick={onDeleteClassificationClick}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="form-group">
                              <label htmlFor="TypeDD">Type</label>
                              <OMSDropdown
                                id="TypeDD"
                                name="typeDD"
                                options={typeDD}
                                valueField="value"
                                labelField="text"
                                value={type}
                                onChange={handleTypeDDChange}
                              />
                              {classification === "" ? null : (
                                <div style={{ marginTop: 5 }}>
                                  <OMSButtonStyleL
                                    type="button"
                                    style={{ marginRight: 10 }}
                                    ui_icon="fa-plus"
                                    ui_type="delete"
                                    ui_text="Add"
                                    ui_processing_text=""
                                    processing={false}
                                    disabled={buttonStatus.disabled}
                                    onClick={onCreateTypeClick}
                                  />
                                  {type === "" ? null : (
                                    <OMSButtonStyleL
                                      type="button"
                                      ui_icon="fa-trash-o"
                                      ui_type="delete"
                                      ui_text="Delete"
                                      ui_processing_text="Deleting.."
                                      processing={buttonStatus.processing_delete}
                                      disabled={buttonStatus.disabled}
                                      onClick={onDeleteTypeClick}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="GradesDD">Grades</label>
                              <OMSDropdown
                                id="GradeDD"
                                name="gradeDD"
                                options={gradeDD}
                                valueField="value"
                                labelField="text"
                                value={grade}
                                onChange={handleGradeDDChange}
                              />
                              {classification === "" ? null : (
                                <div style={{ marginTop: 5 }}>
                                  <OMSButtonStyleL
                                    type="button"
                                    style={{ marginRight: 10 }}
                                    ui_icon="fa-plus"
                                    ui_type="delete"
                                    ui_text="Add"
                                    ui_processing_text=""
                                    processing={false}
                                    disabled={buttonStatus.disabled}
                                    onClick={onCreateGradeClick}
                                  />
                                  {grade === "" ? null : (
                                    <OMSButtonStyleL
                                      type="button"
                                      ui_icon="fa-trash-o"
                                      ui_type="delete"
                                      ui_text="Delete"
                                      ui_processing_text="Deleting.."
                                      processing={buttonStatus.processing_delete}
                                      disabled={buttonStatus.disabled}
                                      onClick={onDeleteGradeClick}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="ownershiptypes" className={activeTabID === "ownershiptypes" ? "tab-pane fade in active" : "tab-pane fade"}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="Ownership">Ownership</label>
                              <OMSDropdown
                                id="Ownership"
                                name="ownership"
                                options={ownershipDD}
                                valueField="value"
                                labelField="text"
                                value={ownership}
                                onChange={handleOwnershipChange}
                              />
                              <div style={{ marginTop: 5 }}>
                                <OMSButtonStyleL
                                  type="button"
                                  style={{ marginRight: 10 }}
                                  ui_icon="fa-plus"
                                  ui_type="delete"
                                  ui_text="Add"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={buttonStatus.disabled}
                                  onClick={onCreateOwnershipClick}
                                />
                                {ownership === "" ? null : (
                                  <OMSButtonStyleL
                                    type="button"
                                    ui_icon="fa-trash-o"
                                    ui_type="delete"
                                    ui_text="Delete"
                                    ui_processing_text="Deleting.."
                                    processing={buttonStatus.processing_delete}
                                    disabled={buttonStatus.disabled}
                                    onClick={onDeleteOwnershipClick}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="MandateType">MandateType</label>
                              <OMSDropdown
                                id="MandateType"
                                name="mandateType"
                                options={mandateTypesDD}
                                valueField="value"
                                labelField="text"
                                value={mandateType}
                                onChange={handleMandateTypeChange}
                              />
                              <div style={{ marginTop: 5 }}>
                                <OMSButtonStyleL
                                  type="button"
                                  style={{ marginRight: 10 }}
                                  ui_icon="fa-plus"
                                  ui_type="delete"
                                  ui_text="Add"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={buttonStatus.disabled}
                                  onClick={onCreateMandateTypeClick}
                                />
                                {mandateType === "" ? null : (
                                  <OMSButtonStyleL
                                    type="button"
                                    ui_icon="fa-trash-o"
                                    ui_type="delete"
                                    ui_text="Delete"
                                    ui_processing_text="Deleting.."
                                    processing={buttonStatus.processing_delete}
                                    disabled={buttonStatus.disabled}
                                    onClick={onDeleteMandateTypeClick}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <label htmlFor="AgentType">AgentType</label>
                              <OMSDropdown
                                id="AgentType"
                                name="agentType"
                                options={agentTypesDD}
                                valueField="value"
                                labelField="text"
                                value={agentType}
                                onChange={handleAgentTypeChange}
                              />
                              <div style={{ marginTop: 5 }}>
                                <OMSButtonStyleL
                                  type="button"
                                  style={{ marginRight: 10 }}
                                  ui_icon="fa-plus"
                                  ui_type="delete"
                                  ui_text="Add"
                                  ui_processing_text=""
                                  processing={false}
                                  disabled={buttonStatus.disabled}
                                  onClick={onCreateAgentTypeClick}
                                />
                                {agentType === "" ? null : (
                                  <OMSButtonStyleL
                                    type="button"
                                    ui_icon="fa-trash-o"
                                    ui_type="delete"
                                    ui_text="Delete"
                                    ui_processing_text="Deleting.."
                                    processing={buttonStatus.processing_delete}
                                    disabled={buttonStatus.disabled}
                                    onClick={onDeleteAgentTypeClick}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="settings" className={activeTabID === "settings" ? "tab-pane fade in active" : "tab-pane fade"}>
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="SalesTaxPercentage">Default sales tax(%)</label>
                              <OMSNumericBox
                                id="SalesTaxPercentage"
                                name="salesTaxPercentage"
                                maxLength={10}
                                style={{ width: 100 }}
                                value={salesTaxPercentage}
                                isDecimal={true}
                                onChange={handleSalesTaxPercentageChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="Timezone">Time zone</label>
                              <Field as="select" value={timezone} name="timezone" className="form-control" onChange={handleTimeZoneChange}>
                                <option value="Dateline Standard Time">(UTC-12:00) Dateline Standard Time</option>
                                <option value="UTC-11">(UTC-11:00) UTC-11</option>
                                <option value="Aleutian Standard Time">(UTC-10:00) Aleutian Standard Time</option>
                                <option value="Hawaiian Standard Time">(UTC-10:00) Hawaiian Standard Time</option>
                                <option value="Marquesas Standard Time">(UTC-09:30) Marquesas Standard Time</option>
                                <option value="Alaskan Standard Time">(UTC-09:00) Alaskan Standard Time</option>
                                <option value="UTC-09">(UTC-09:00) UTC-09</option>
                                <option value="Pacific Standard Time (Mexico)">(UTC-08:00) Pacific Standard Time (Mexico)</option>
                                <option value="UTC-08">(UTC-08:00) UTC-08</option>
                                <option value="Pacific Standard Time">(UTC-08:00) Pacific Standard Time</option>
                                <option value="US Mountain Standard Time">(UTC-07:00) US Mountain Standard Time</option>
                                <option value="Mountain Standard Time (Mexico)">(UTC-07:00) Mountain Standard Time (Mexico)</option>
                                <option value="Mountain Standard Time">(UTC-07:00) Mountain Standard Time</option>
                                <option value="Central America Standard Time">(UTC-06:00) Central America Standard Time</option>
                                <option value="Central Standard Time">(UTC-06:00) Central Standard Time</option>
                                <option value="Easter Island Standard Time">(UTC-06:00) Easter Island Standard Time</option>
                                <option value="Central Standard Time (Mexico)">(UTC-06:00) Central Standard Time (Mexico)</option>
                                <option value="Canada Central Standard Time">(UTC-06:00) Canada Central Standard Time</option>
                                <option value="SA Pacific Standard Time">(UTC-05:00) SA Pacific Standard Time</option>
                                <option value="Eastern Standard Time (Mexico)">(UTC-05:00) Eastern Standard Time (Mexico)</option>
                                <option value="Eastern Standard Time">(UTC-05:00) Eastern Standard Time</option>
                                <option value="Haiti Standard Time">(UTC-05:00) Haiti Standard Time</option>
                                <option value="Cuba Standard Time">(UTC-05:00) Cuba Standard Time</option>
                                <option value="US Eastern Standard Time">(UTC-05:00) US Eastern Standard Time</option>
                                <option value="Paraguay Standard Time">(UTC-04:00) Paraguay Standard Time</option>
                                <option value="Atlantic Standard Time">(UTC-04:00) Atlantic Standard Time</option>
                                <option value="Venezuela Standard Time">(UTC-04:00) Venezuela Standard Time</option>
                                <option value="Central Brazilian Standard Time">(UTC-04:00) Central Brazilian Standard Time</option>
                                <option value="SA Western Standard Time">(UTC-04:00) SA Western Standard Time</option>
                                <option value="Pacific SA Standard Time">(UTC-04:00) Pacific SA Standard Time</option>
                                <option value="Turks And Caicos Standard Time">(UTC-04:00) Turks and Caicos Standard Time</option>
                                <option value="Newfoundland Standard Time">(UTC-03:30) Newfoundland Standard Time</option>
                                <option value="Tocantins Standard Time">(UTC-03:00) Tocantins Standard Time</option>
                                <option value="E. South America Standard Time">(UTC-03:00) E. South America Standard Time</option>
                                <option value="SA Eastern Standard Time">(UTC-03:00) SA Eastern Standard Time</option>
                                <option value="Argentina Standard Time">(UTC-03:00) Argentina Standard Time</option>
                                <option value="Greenland Standard Time">(UTC-03:00) Greenland Standard Time</option>
                                <option value="Montevideo Standard Time">(UTC-03:00) Montevideo Standard Time</option>
                                <option value="Magallanes Standard Time">(UTC-03:00) Magallanes Standard Time</option>
                                <option value="Saint Pierre Standard Time">(UTC-03:00) Saint Pierre Standard Time</option>
                                <option value="Bahia Standard Time">(UTC-03:00) Bahia Standard Time</option>
                                <option value="UTC-02">(UTC-02:00) UTC-02</option>
                                <option value="Mid-Atlantic Standard Time">(UTC-02:00) Mid-Atlantic Standard Time</option>
                                <option value="Azores Standard Time">(UTC-01:00) Azores Standard Time</option>
                                <option value="Cape Verde Standard Time">(UTC-01:00) Cabo Verde Standard Time</option>
                                <option value="UTC">(UTC) CoordiCoordinated Universal Time</option>
                                <option value="Morocco Standard Time">(UTC+00:00) Morocco Standard Time</option>
                                <option value="GMT Standard Time">(UTC+00:00) GMT Standard Time</option>
                                <option value="Greenwich Standard Time">(UTC+00:00) Greenwich Standard Time</option>
                                <option value="W. Europe Standard Time">(UTC+01:00) W. Europe Standard Time</option>
                                <option value="Central Europe Standard Time">(UTC+01:00) Central Europe Standard Time</option>
                                <option value="Romance Standard Time">(UTC+01:00) Romance Standard Time</option>
                                <option value="Central European Standard Time">(UTC+01:00) Central European Standard Time</option>
                                <option value="W. Central Africa Standard Time">(UTC+01:00) W. Central Africa Standard Time</option>
                                <option value="Namibia Standard Time">(UTC+01:00) Namibia Standard Time</option>
                                <option value="Jordan Standard Time">(UTC+02:00) Jordan Standard Time</option>
                                <option value="GTB Standard Time">(UTC+02:00) GTB Standard Time</option>
                                <option value="Middle East Standard Time">(UTC+02:00) Middle East Standard Time</option>
                                <option value="Egypt Standard Time">(UTC+02:00) Egypt Standard Time</option>
                                <option value="E. Europe Standard Time">(UTC+02:00) E. Europe Standard Time</option>
                                <option value="Syria Standard Time">(UTC+02:00) Syria Standard Time</option>
                                <option value="West Bank Standard Time">(UTC+02:00) West Bank Gaza Standard Time</option>
                                <option value="South Africa Standard Time">(UTC+02:00) South Africa Standard Time</option>
                                <option value="FLE Standard Time">(UTC+02:00) FLE Standard Time</option>
                                <option value="Israel Standard Time">(UTC+02:00) Jerusalem Standard Time</option>
                                <option value="Kaliningrad Standard Time">(UTC+02:00) Russia TZ 1 Standard Time</option>
                                <option value="Libya Standard Time">(UTC+02:00) Libya Standard Time</option>
                                <option value="Arabic Standard Time">(UTC+03:00) Arabic Standard Time</option>
                                <option value="Turkey Standard Time">(UTC+03:00) Turkey Standard Time</option>
                                <option value="Arab Standard Time">(UTC+03:00) Arab Standard Time</option>
                                <option value="Belarus Standard Time">(UTC+03:00) Belarus Standard Time</option>
                                <option value="Russian Standard Time">(UTC+03:00) Russia TZ 2 Standard Time</option>
                                <option value="E. Africa Standard Time">(UTC+03:00) E. Africa Standard Time</option>
                                <option value="Iran Standard Time">(UTC+03:30) Iran Standard Time</option>
                                <option value="Arabian Standard Time">(UTC+04:00) Arabian Standard Time</option>
                                <option value="Astrakhan Standard Time">(UTC+04:00) Astrakhan Standard Time</option>
                                <option value="Azerbaijan Standard Time">(UTC+04:00) Azerbaijan Standard Time</option>
                                <option value="Russia Time Zone 3">(UTC+04:00) Russia TZ 3 Standard Time</option>
                                <option value="Mauritius Standard Time">(UTC+04:00) Mauritius Standard Time</option>
                                <option value="Saratov Standard Time">(UTC+04:00) Saratov Standard Time</option>
                                <option value="Georgian Standard Time">(UTC+04:00) Georgian Standard Time</option>
                                <option value="Caucasus Standard Time">(UTC+04:00) Caucasus Standard Time</option>
                                <option value="Afghanistan Standard Time">(UTC+04:30) Afghanistan Standard Time</option>
                                <option value="West Asia Standard Time">(UTC+05:00) West Asia Standard Time</option>
                                <option value="Ekaterinburg Standard Time">(UTC+05:00) Russia TZ 4 Standard Time</option>
                                <option value="Pakistan Standard Time">(UTC+05:00) Pakistan Standard Time</option>
                                <option value="India Standard Time">(UTC+05:30) India Standard Time</option>
                                <option value="Sri Lanka Standard Time">(UTC+05:30) Sri Lanka Standard Time</option>
                                <option value="Nepal Standard Time">(UTC+05:45) Nepal Standard Time</option>
                                <option value="Central Asia Standard Time">(UTC+06:00) Central Asia Standard Time</option>
                                <option value="Bangladesh Standard Time">(UTC+06:00) Bangladesh Standard Time</option>
                                <option value="Omsk Standard Time">(UTC+06:00) Omsk Standard Time</option>
                                <option value="Myanmar Standard Time">(UTC+06:30) Myanmar Standard Time</option>
                                <option value="SE Asia Standard Time">(UTC+07:00) SE Asia Standard Time</option>
                                <option value="Altai Standard Time">(UTC+07:00) Altai Standard Time</option>
                                <option value="W. Mongolia Standard Time">(UTC+07:00) W. Mongolia Standard Time</option>
                                <option value="North Asia Standard Time">(UTC+07:00) Russia TZ 6 Standard Time</option>
                                <option value="N. Central Asia Standard Time">(UTC+07:00) Novosibirsk Standard Time</option>
                                <option value="Tomsk Standard Time">(UTC+07:00) Tomsk Standard Time</option>
                                <option value="China Standard Time">(UTC+08:00) China Standard Time</option>
                                <option value="North Asia East Standard Time">(UTC+08:00) Russia TZ 7 Standard Time</option>
                                <option value="Singapore Standard Time">(UTC+08:00) Malay Peninsula Standard Time</option>
                                <option value="W. Australia Standard Time">(UTC+08:00) W. Australia Standard Time</option>
                                <option value="Taipei Standard Time">(UTC+08:00) Taipei Standard Time</option>
                                <option value="Ulaanbaatar Standard Time">(UTC+08:00) Ulaanbaatar Standard Time</option>
                                <option value="North Korea Standard Time">(UTC+08:30) North Korea Standard Time</option>
                                <option value="Aus Central W. Standard Time">(UTC+08:45) Aus Central W. Standard Time</option>
                                <option value="Transbaikal Standard Time">(UTC+09:00) Transbaikal Standard Time</option>
                                <option value="Tokyo Standard Time">(UTC+09:00) Tokyo Standard Time</option>
                                <option value="Korea Standard Time">(UTC+09:00) Korea Standard Time</option>
                                <option value="Yakutsk Standard Time">(UTC+09:00) Russia TZ 8 Standard Time</option>
                                <option value="Cen. Australia Standard Time">(UTC+09:30) Cen. Australia Standard Time</option>
                                <option value="AUS Central Standard Time">(UTC+09:30) AUS Central Standard Time</option>
                                <option value="E. Australia Standard Time">(UTC+10:00) E. Australia Standard Time</option>
                                <option value="AUS Eastern Standard Time">(UTC+10:00) AUS Eastern Standard Time</option>
                                <option value="West Pacific Standard Time">(UTC+10:00) West Pacific Standard Time</option>
                                <option value="Tasmania Standard Time">(UTC+10:00) Tasmania Standard Time</option>
                                <option value="Vladivostok Standard Time">(UTC+10:00) Russia TZ 9 Standard Time</option>
                                <option value="Lord Howe Standard Time">(UTC+10:30) Lord Howe Standard Time</option>
                                <option value="Bougainville Standard Time">(UTC+11:00) Bougainville Standard Time</option>
                                <option value="Russia Time Zone 10">(UTC+11:00) Russia TZ 10 Standard Time</option>
                                <option value="Magadan Standard Time">(UTC+11:00) Magadan Standard Time</option>
                                <option value="Norfolk Standard Time">(UTC+11:00) Norfolk Standard Time</option>
                                <option value="Sakhalin Standard Time">(UTC+11:00) Sakhalin Standard Time</option>
                                <option value="Central Pacific Standard Time">(UTC+11:00) Central Pacific Standard Time</option>
                                <option value="Russia Time Zone 11">(UTC+12:00) Russia TZ 11 Standard Time</option>
                                <option value="New Zealand Standard Time">(UTC+12:00) New Zealand Standard Time</option>
                                <option value="UTC+12">(UTC+12:00) UTC+12</option>
                                <option value="Fiji Standard Time">(UTC+12:00) Fiji Standard Time</option>
                                <option value="Kamchatka Standard Time">(UTC+12:00) Kamchatka Standard Time</option>
                                <option value="Chatham Islands Standard Time">(UTC+12:45) Chatham Islands Standard Time</option>
                                <option value="UTC+13">(UTC+13:00) UTC+13</option>
                                <option value="Tonga Standard Time">(UTC+13:00) Tonga Standard Time</option>
                                <option value="Samoa Standard Time">(UTC+13:00) Samoa Standard Time</option>
                                <option value="Line Islands Standard Time">(UTC+14:00) Line Islands Standard Time</option>
                              </Field>
                            </div>
                            <div className="form-group">
                              <span>
                                <Field type="checkbox" name="smsEnabled" checked={sMSEnabled} onChange={handleCheckboxChanged} /> SMS enabled (Can send/receive
                                sms)
                              </span>
                            </div>
                            <div className="form-group">
                              <label htmlFor="SMSRate">SMS rate(credit)</label>
                              <OMSNumericBox
                                id="SMSRate"
                                name="smsRate"
                                maxLength={10}
                                style={{ width: 100 }}
                                value={sMSRate}
                                isDecimal={true}
                                onChange={handleSMSRateChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="WhatsappRate">Whatsapp rate(credit)</label>
                              <OMSNumericBox
                                id="WhatsappRate"
                                name="whatsappRate"
                                maxLength={10}
                                style={{ width: 100 }}
                                value={whatsappRate}
                                isDecimal={true}
                                onChange={handleWhatsappRateChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="EmailRate">Email rate(credit)</label>
                              <OMSNumericBox
                                id="EmailRate"
                                name="emailRate"
                                maxLength={10}
                                style={{ width: 100 }}
                                value={emailRate}
                                isDecimal={true}
                                onChange={handleEmailRateChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="SMSRateReceive">SMS receive rate(credit)</label>
                              <OMSNumericBox
                                id="SMSRateReceive"
                                name="smsRateReceive"
                                maxLength={10}
                                style={{ width: 100 }}
                                value={sMSRateReceive}
                                isDecimal={true}
                                onChange={handleSMSRateReceiveChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="WhatsappRateReceive">Whatsapp receive rate(credit)</label>
                              <OMSNumericBox
                                id="WhatsappRateReceive"
                                name="whatsappRateReceive"
                                maxLength={10}
                                style={{ width: 100 }}
                                value={whatsappRateReceive}
                                isDecimal={true}
                                onChange={handleWhatsappRateReceiveChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="EmailRate">Email receive rate(credit)</label>
                              <OMSNumericBox
                                id="EmailRateReceive"
                                name="emailRateReceive"
                                maxLength={10}
                                style={{ width: 100 }}
                                value={emailRateReceive}
                                isDecimal={true}
                                onChange={handleEmailRateReceiveChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="pull-left">
                        {props.iscreate ? null : (
                          <OMSButtonStyleL
                            type="button"
                            ui_icon="fa-trash-o"
                            ui_type="delete"
                            ui_text="Delete country"
                            ui_processing_text="Deleting.."
                            processing={buttonStatus.processing_delete}
                            disabled={buttonStatus.disabled}
                            onClick={onDeleteClick}

                            // type="button"
                            // ui_icon="fa-trash-o"
                            // ui_type="delete"
                            // ui_text="Delete"
                            // ui_processing_text="Deleting.."
                            // processing={buttonStatus.processing_delete}
                            // disabled={buttonStatus.disabled}
                            // onClick={onDeleteClassificationClick}
                          />
                        )}
                      </div>
                      <div className="pull-right">
                        <OMSButtonStyleL
                          style={{ marginRight: 10 }}
                          type="button"
                          ui_icon="fa-times"
                          ui_type="cancel"
                          ui_text="Cancel"
                          ui_processing_text=""
                          processing={false}
                          disabled={buttonStatus.disabled}
                          onClick={onCancelClick}
                        />
                        <OMSButtonStyleL
                          type="submit"
                          ui_icon="fa-check"
                          ui_type="save"
                          ui_text="Save"
                          ui_processing_text="Saving.."
                          processing={buttonStatus.processing_save}
                          disabled={buttonStatus.disabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <div className="modal fade" id="SavePropertyTypeModel" tabIndex={-1} role="dialog" aria-labelledby="SavePropertyTypeModelLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
              <h4 className="modal-title" id="SavePropertyTypeModelLabel">
                {mFor === -1 ? "Add ownership" : mFor === 0 ? "Add classification" : "Add type"}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="mName">Name</label>
                  <input type="text" name="mName" id="mName" className="form-control required-control" value={mName} onChange={handlemNameChange} />
                </div>
                {mFor === 5 ? (
                  <div className="form-group col-md-12">
                    <label htmlFor="mName2">Value(Default to Name)</label>
                    <input type="text" name="mName2" id="mName2" className="form-control" value={mName2} onChange={handlemName2Change} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-12">
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>
                <button type="button" onClick={onSavePropertyTypeModelClick} className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CountryBox;
