import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from 'react-router-dom';

class BulkSyncBox extends React.Component {
  state = {
    OfficeID: "",
    PortalID: "0",
    TaskID: "1",
    processing: false,
    ConfirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.ConfirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ ConfirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ ConfirmationModalProps: varConfirmationModalProps });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDropdownChange = (e) => {
    this.setState({ [e.name]: e.newValue });
  };

  onCancelClick = (e) => {
    this.props.history.push('/Settings');
  };

  onPerformClick = (e) => {
    if (this.state.TaskID == "7") {
      this.executePerformClick();
    } else {
      /* bootbox.confirm({
        title: "Bulk sync update?",
        message: "Do you want to process bulk sync update. now? This cannot be undone.",
        buttons: {
          cancel: {
            label: '<i class="fa fa-times"></i> Cancel',
          },
          confirm: {
            label: '<i class="fa fa-check"></i> Confirm',
          },
        },
        callback: function (result) {
          if (result) {
            this.executePerformClick();
          }
        }.bind(this),
      }); */
      let title = "Bulk sync update?";
      let body = "Do you want to process bulk sync update. now? This cannot be undone.";
      let callback = this.executePerformClick;
      this.showPopupAndDeleteOnConfirmation(title, body, callback);
    }
  };

  executePerformClick = (e) => {
    this.setState({ processing: true });
    /* var data = new FormData();
      data.append('PortalID', this.state.PortalID);
      data.append('OfficeID', this.state.OfficeID);
      data.append('TaskID', this.state.TaskID);
      var xhr = new XMLHttpRequest();
      xhr.open('post', '/Admin/BulkSyncAjax', true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        $.toaster(data.message, 'Result', 'info');
        //bootbox.alert(data.message);
        this.setState({ processing: false });
      }.bind(this);
      xhr.send(data); */
    apiAgent.Admin.bulkSyncAjax(this.state.OfficeID, this.state.PortalID, this.state.TaskID)
      .then((response) => {
        toast.info(response.message);
      })
      .catch((error) => {
        toast.info("Fail");
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  render() {
    return (
      <div className="col-sm-12">
      <ConfirmationModalPopup {...this.state.ConfirmationModalProps} />
        <div className="panel panel-default oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-12">
                <label>Bulk Sync</label>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="OfficeID">Office</label>
                <OMSDropdown
                  id="OfficeID"
                  name="OfficeID"
                  options={this.props.officeDD}
                  valueField="value"
                  labelField="text"
                  value={this.state.OfficeID}
                  onChange={this.handleDropdownChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="PortalID">Portal</label>
                <OMSDropdown
                  id="PortalID"
                  name="PortalID"
                  options={this.props.portalDD}
                  valueField="value"
                  labelField="text"
                  value={this.state.PortalID}
                  onChange={this.handleDropdownChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="TaskID">Task</label>
                <select id="TaskID" name="TaskID" value={this.state.TaskID} onChange={this.handleChange} className="form-control">
                  <option value="1">1. All Listing</option>
                  <option value="2">2. All Listing (Selected)</option>
                  <option value="3">3. Pending Listing (Selected)</option>
                  <option value="4">4. Error Listing (Selected)</option>
                  <option value="5">5. All Imported Listing </option>
                  <option value="6">6. All Imported Listing (Selected)</option>
                  <option value="7">7. Listing Sync Status</option>
                  <option value="8">8. Error Listing All(will sync all portal, no matter selected)</option>
                </select>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCancelClick}
                  />
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Perform"
                    ui_processing_text="Processing.."
                    processing={this.state.processing}
                    disabled={this.state.processing}
                    onClick={this.onPerformClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BulkSyncBox);
