import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { BrochureModel } from "../../../app/models/BrochureModel";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import BrochureBox from "./BrochureBox";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function SaveBrochurePage() {
  let varConfirmModalProps: ConfirmationModalProps = {
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  };

  const [brochure, setBrochure] = useState<BrochureModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>(varConfirmModalProps);
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let brochureId: string = "";
    if (params.has("ID")) {
      const id = params.get("ID");
      brochureId = id !== null ? id : "";
    }

    setIsLoading(true);
    apiAgent.Admin.saveBrochure(brochureId)
      .then((response: BrochureModel) => {
        let oBrochure: BrochureModel = response;
        setBrochure(oBrochure);

        //console.log("Brochure", oBrochure);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save Brochure";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && brochure) {
    return (
      <>
        <HeaderWrapper />

        <div className="save-brochure-page container-fluid">
            <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Reports", path: "/admin/reports" },
              { text: !brochure.brochureID ? "Create" : brochure.brochureName}
            ]}
          />
          
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <BrochureBox data={brochure} setConfirmationModalProps={setConfirmationModalProps} />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
