import { useEffect, useState } from "react";
import "./OMSModal.scss";
const OMSModal = ({
  isOpen,
  onClose,
  formId,
  title,
  children,
  onAdd,
  onUpdate,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  useEffect(() => {
    setIsOpenModal(isOpen);
  }, [isOpen]);

  // useEffect(() => {
  //   const handleEscEvent = (event) => {
  //     if (event.key == "Escape") {
  //       setIsOpenModal(false);
  //       onClose();
  //     }
  //   };
  //   window.addEventListener("keydown", handleEscEvent);
  //   return () => {
  //     window.removeEventListener("keydown", handleEscEvent);
  //   };
  // }, []);
  const handleModalClose = () => {
    onClose();
    setIsOpenModal(false);
  };
  const handleOnClickAdd = () => {
    onAdd();
    setIsOpenModal(false);
    onClose();
  };

  const handleOnClickUpdate = () => {
    onUpdate();
    setIsOpenModal(false);
    onClose();
  };
  return (
    <>
      {isOpenModal ? (
        <div class="overlay">
          <div class="popup">
            <div class="popup-header">
              <h4>{title}</h4>
              <div className="close-button">
                <i
                  class="fa fa-times fa-3x close-btn"
                  onClick={handleModalClose}
                ></i>
              </div>
            </div>
            <div class="popup-content">{children}</div>
            <div className="popup-footer">
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-default cancel-btn"
                    onClick={handleModalClose}
                  >
                    <i className="fa fa-times"></i> Cancel
                  </button>

                  {formId == null ? (
                    <button
                      type="button"
                      className="btn btn-success-oms save-btn"
                      onClick={handleOnClickAdd}
                    >
                      <i className="fa fa-check"></i> Save
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success-oms save-btn"
                      onClick={handleOnClickUpdate}
                    >
                      <i className="fa fa-check"></i> Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OMSModal;
