import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

const SideMenu = ({sidebarStateVal, setSidebarStateVal, pipelines, userSession, setReportGrid }) => {
  const [sidebarState, setSidebarState] = useState(false);
  const menuItemClick = (stateVal) => {
   if (stateVal === "Reports") {
      setSidebarStateVal(stateVal);
      setReportGrid(true);
    } else {
      setSidebarStateVal(stateVal);
    }
  };

  return (
    <>
      <div className="col-md-12 hidden-md hidden-lg">
        <div style={{ padding: "20px 0 0 0" ,flexWrap:"wrap"}} className="d-flex">
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Dashboard")}>
            <i className="fa fa-tachometer" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Dashboard" ? <b>Dashboard</b> : <span>Dashboard</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Pipelines")}>
            <i className="fa fa-list-alt" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Pipelines" ? <b>Pipelines</b> : <span>Pipelines</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Deals")}>
            <i className="fa fa-list-alt" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Deals" ? <b>Deals</b> : <span>Deals</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Tasks")}>
            <i className="fa fa-list-alt" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Tasks" ? <b>Tasks</b> : <span>Tasks</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Reports")}>
            <i className="fa fa-bar-chart" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Reports" ? <b>Reports</b> : <span>Reports</span>}
          </span>
          {userSession.hasImportedDeals && (
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Imported")}>
            <i className="fa fa-list-alt" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Imported" ? <b>Imported</b> : <span>Imported</span>}
          </span>
          )}
        </div>
      </div>
      <div className="col-lg-1 col-md-2 hidden-xs hidden-sm">
        <div style={{ padding: "20px 0 0 0" }} className="">
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Dashboard")}>
            <i className="fa fa-tachometer" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Dashboard" ? <b>Dashboard</b> : <span>Dashboard</span>}
          </span>
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Pipelines")}>
            <i className="fa fa-list-alt" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Pipelines" ? <b>Pipelines</b> : <span>Pipelines</span>}
          </span>
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Deals")}>
            <i className="fa fa-list-alt" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Deals" ? <b>Deals</b> : <span>Deals</span>}
          </span>
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Tasks")}>
            <i className="fa fa-list-alt" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Tasks" ? <b>Tasks</b> : <span>Tasks</span>}
          </span>
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Reports")}>
            <i className="fa fa-bar-chart" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Reports" ? <b>Reports</b> : <span>Reports</span>}
          </span>
          {userSession.hasImportedDeals && (
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => menuItemClick("Imported")}>
            <i className="fa fa-list-alt" style={{ marginRight: 8 }}></i>
            {sidebarStateVal == "Imported" ? <b>Imported</b> : <span>Imported</span>}
          </span>
          )}
        </div>
      </div>
    </>
  );
};

export default SideMenu;
