import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { DataTableComp, DataTableLoading } from "../../../app/common/common-components/OMSDatatables";
import { OMSGeoLocationMapTool } from "./OMSGeoLocationMapTool";
import { withRouter } from 'react-router-dom';

class MapToolBox extends React.Component {
  state = {
    data: [],
    loading: false,
  };

  constructor(props) {
    super(props);

    this.geoLocationRef = React.createRef();
  }

  loadDataFromServer = () => {
    this.setState({ loading: true, data: [] });

    apiAgent.Admin.mapAreaToolAjax()
      .then((response) => {
        this.setState({ loading: false, data: response });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  componentDidMount() {
    this.loadDataFromServer();
  }

  onCancelClick = (e) => {
    this.props.history.push('/settings');
  };

  onLocationSubmit = (id, lat, lng) => {
    apiAgent.Admin.saveMapAreaAjax(id, lat, lng)
      .then((response) => {
        this.loadDataFromServer();
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  handleRowClick = (data) => {
    var SuburbID = data.pk_suburb_id;
    var Province = data.sprovince_name;
    var Town = data.stown_name;
    var Suburb = data.ssuburb_name;
    var Suburb_Latlng = data.suburbLatlng;
    var Address = Suburb + ", " + Town + ", " + Province;
    this.geoLocationRef.current.OpenGeoLocation(SuburbID, Suburb_Latlng, Address);
  };

  render() {
    var columns = [
      { data: "sprovince_name", title: "Province" },
      { data: "stown_name", title: "Town" },
      { data: "ssuburb_name", title: "Suburb" },
      { data: "suburbLatlng", title: "Suburb_Latlng" },
      { data: "propCount", title: "Prop Count" },
      { data: "igeocheck", title: "Mapped" },
    ];

    return (
      <div className="col-sm-12">
        <div className="panel panel-default oms-box">
          <div className="panel-heading">
            <div className="row">
              <div className="col-md-12">
                <label>Map Area Tool</label>
              </div>
            </div>
          </div>
          <div className="panel-body table-responsive">
            <DataTableComp id="mapToolTable" columns={columns} columnDefs={[]} order={[[1, "asc"]]} data={this.state.data} onRowClick={this.handleRowClick} />
            <DataTableLoading loading={this.state.loading} />
                      <OMSGeoLocationMapTool id="geoLocation" ref={this.geoLocationRef} onLocationSubmit={this.onLocationSubmit} />
          </div>
          <div className="panel-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Cancel"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onCancelClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MapToolBox);
