import { config } from "dotenv-cra";
import "bootstrap/dist/css/bootstrap-theme.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../layout/datatables.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../layout/typeahead.css";

import "../../assets/styles/styles.scss";
import "../../assets/styles/sample.scss";
import "../../assets/styles/custom.scss";
import "../../assets/styles/shared.scss";

import "../../app/layout/styles.css";
import "../../app/layout/listing.css";
import "../../assets/styles/bootstrap-colorselector.min.css";

import "../../assets/styles/components/_saveOffice.scss";
import "../../assets/styles/components/_settings.scss";
import "../../assets/styles/components/_usersPage.scss";
import "../../assets/styles/components/_dashboardPage.scss";
import "../../assets/styles/components/_contactPage.scss";
import "../../assets/styles/components/_saveProperty.scss";
import "../../assets/styles/components/_auditTrailPage.scss";
import "../../assets/styles/components/_saveCountry.scss";
import "../../assets/styles/components/_customizePage.scss";
import "../../assets/styles/components/_saveGroup.scss";
import "../../assets/styles/components/_integrationsPage.scss";
import "../../assets/styles/components/_property.scss";
import "../../assets/styles/components/_saveUser.scss";
import "../../assets/styles/components/_saveReport.scss";
import "../../assets/styles/components/_saveBrochurePage.scss";
import "../../assets/styles/components/_omsToBase.scss";
import "../../assets/styles/components/_mapAreaTool.scss";
import "../../assets/styles/components/_clearCache.scss";
import "../../assets/styles/components/_saveContact.scss";
import "../../assets/styles/components/_contactPage.scss";
import "../../assets/styles/components/_saveContentPage.scss";
import "../../assets/styles/components/_libraryPage.scss";
import "../../assets/styles/components/_login.scss";
import "../../assets/styles/components/_officesPage.scss";
import "../../assets/styles/components/_saveImportPropertyPage.scss";
import "../../assets/styles/components/_saveIntegration.scss";
import "../../assets/styles/components/_listingReports.scss";
import "../../assets/styles/components/_saveDeal.scss";

import "jquery/src/jquery";
import "bootstrap/dist/js/bootstrap.min.js";

import "bootbox/bootbox";
import "datatables.net-bs/js/dataTables.bootstrap.min.js";
import "../../js/custom.js";
import "../../js/bootstrap-colorselector.min.js";

import "datatables.net-responsive";
import "tippy.js/dist/tippy.css";

import _ from "lodash";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import HomePage from "../../features/home/HomePage";
import LoginPage from "../../features/login/LoginPage";
import ResetPasswordPage from "../../features/login/ResetPasswordPage";
import SettingsPage from "../../features/settings/SettingsPage";
import { useStore } from "../stores/store";
import SaveUserPage from "../../features/profile/SaveUserPage";
import Header from "../../features/header/Header";
import ContactPage from "../../features/contact/ContactPage";
import OfficesPage from "../../features/settings/Offices/OfficesPage";
import SaveOfficePage from "../../features/settings/Offices/SaveOfficePage";
import GroupsPage from "../../features/settings/Groups/GroupsPage";
import UsersPage from "../../features/settings/Users/UsersPage";
import SaveGroupPage from "../../features/settings/Groups/SaveGroupPage";
import CountriesPage from "../../features/settings/Countries/CountriesPage";
import SaveCountry from "../../features/settings/Countries/SaveCountry";
import CustomizePage from "../../features/settings/Customize/CustomizePage";
import AuditTrailPage from "../../features/settings/AuditTrail/AuditTrailPage";
import IntegrationsPage from "../../features/settings/Integrations/IntegrationsPage";
import ImportPage from "../../features/settings/Import/ImportPage";
import BulkSyncPage from "../../features/admin/BulkSync/BulkSyncPage";
import ClearCachePage from "../../features/admin/ClearCache/ClearCachePage";
import P24MatchPage from "../../features/admin/P24RefMapping/P24MatchPage";
import P24AreaMapPage from "../../features/admin/P24AreaMapping/P24AreaMapPage";
import AreaTransferPage from "../../features/admin/AreaTransfer/AreaTransferPage";
import UserTransferPage from "../../features/admin/UserTransfer/UserTransferPage";
import ReportsPage from "../../features/admin/Reports/ReportsPage";
import SaveBrochurePage from "../../features/admin/Reports/SaveBrochurePage";
import MapAreaToolPage from "../../features/admin/MapArea/MapAreaToolPage";
import CreditPage from "../../features/admin/AgentMsgCredit/CreditPage";
import SyncIssuesPage from "../../features/admin/SyncIssues/SyncIssuesPage";
import OMSToBasePage from "../../features/admin/OMSToBase/OMSToBasePage";
import SaveReportPage from "../../features/admin/Reports/SaveReportPage";
import PropertyPage from "../../features/property/PropertyPage";
import LibraryPage from "../../features/library/LibraryPage";
import { textSpanContainsPosition } from "typescript";
import TestComponent from "../../features/TestComponent";
import SaveContactPage from "../../features/contact/SaveContactPage";

// import SaveContentPage from "../../features/library/SaveContentPage";

//import "react-datepicker/dist/react-datepicker.css";
import "../../assets/styles/react-datepicker/react-datepicker-custom.css";
//import "../../assets/styles/components/_property.scss";
//import "../../assets/styles/colorpicker/pick-a-color-1.2.3.min.scss";
//import "../../assets/styles/components/_property.scss";
//import "../../assets/styles/colorpicker/pick-a-color-1.2.3.min.scss";
import SaveContentPage from "../../features/library/SaveContentPage";
import SavePropertyPage from "../../features/property/SavePropertyPage";
import DashboardPage from "../../features/home/DashboardPage";
import AjaxLoader from "../common/common-components/AjaxLoader";
import FlexPropertySyncDBox from "../../features/settings/FlexSyncDBox/FlexPropertySyncDBox";
import FlexAgentSyncDBox from "../../features/settings/FlexSyncDBox/FlexAgentSyncDBox";
import { UserSession } from "../models/User";
import { ThemeColorsModel } from "../models/ThemeColorsModel";
import SaveIntegrationsPage from "../../features/settings/Integrations/SaveIntegrationsPage";
import ImportPropertiesPage from "../../features/settings/Import/ImportPropertiesPage";
import SaveProperty from "../../features/settings/Import/SaveProperty";
import LoopPage from "../../features/deal/LoopPage";
import DealPage from "../../features/deal/dealPages/DealPage";
import CommissionSlipPage from "../../features/settings/CommissionSlip/CommissionSlipPage";
import SaveCommissionSlipPage from "../../features/settings/CommissionSlip/SaveCommissionSlipPage";
import AuthCodePage from "../../features/login/AuthCodePage";
import ResetTwoFAPage from "../../features/login/ResetTwoFAPage";
import OfficeDealGridPage from "../../features/settings/Offices/OfficeDealGridPage";
import NotFound from "../../features/home/error/NotFound";
import ServerErrorPage from "../../features/home/error/ServerErrorPage";
import UnauthorizedPage from "../../features/home/error/UnauthorizedPage";
import ErrorPage from "../../features/home/error/ErrorPage";
import OfficeInsightsPage from "../../features/settings/Offices/OfficeInsightsUsage/OfficeInsightsPage";

function App() {
  const { commonStore, userStore, masterDataStore } = useStore();

  const setThemeColors = (themeColors: ThemeColorsModel) => {
    document.documentElement.style.setProperty(`--color1`, themeColors.color1);
    document.documentElement.style.setProperty(`--color2`, themeColors.color2);
    document.documentElement.style.setProperty(`--color3`, themeColors.color3);
    document.documentElement.style.setProperty(`--color4`, themeColors.color4);
    document.documentElement.style.setProperty(`--color5`, themeColors.color5);
    document.documentElement.style.setProperty(`--color6`, themeColors.color6);
    document.documentElement.style.setProperty(`--color7`, themeColors.color7);
    document.documentElement.style.setProperty(
      `--color1tint`,
      themeColors.color1Tint
    );
    document.documentElement.style.setProperty(
      `--color2tint`,
      themeColors.color2Tint
    );
    document.documentElement.style.setProperty(
      `--color6tint`,
      themeColors.color6Tint
    );
  };

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUserSession().finally(() => {
        commonStore.setAppLoaded();
      });
      masterDataStore.loadMasterData(); // Load master data when app initializes
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore, masterDataStore]);

  if (!commonStore.appLoaded) return <AjaxLoader />;

  const userSession = userStore.userSession;

  if (userSession !== null) {
    setThemeColors(userSession.themeColors);
  }

  return (
    <>
      <ToastContainer position="top-right" theme="colored" hideProgressBar />
      <Switch>
        <Route exact path="/home" component={DashboardPage}></Route>
        <Route exact path="/" component={DashboardPage}></Route>
        <Route exact path="/home/index" component={DashboardPage}></Route>
        <Route path="/login" component={LoginPage}></Route>
        <Route path="/authcode" component={AuthCodePage}></Route>
        <Route path="/reset/:token" component={ResetPasswordPage}></Route>
        <Route path="/resetTwoFA/:token" component={ResetTwoFAPage}></Route>
        <Route exact path="/settings" component={SettingsPage}></Route>
        <Route path="/settings/saveuser/:userId" component={SaveUserPage}></Route>
        <Route path="/contacts/saveContact" component={SaveContactPage}></Route>
        <Route exact path="/contacts" component={ContactPage}></Route>
        <Route exact path="/settings/users" component={UsersPage}></Route>
        <Route exact path="/settings/saveuser" component={SaveUserPage}></Route>
        <Route exact path="/settings/offices" component={OfficesPage}></Route>
        <Route path="/settings/saveoffice" component={SaveOfficePage}></Route>
        <Route exact path="/settings/groups" component={GroupsPage}></Route>
        <Route path="/settings/saveGroup" component={SaveGroupPage}></Route>
        <Route exact path="/settings/countries" component={CountriesPage}></Route>
        <Route path="/settings/saveCountry" component={SaveCountry}></Route>
        <Route exact path="/settings/customize" component={CustomizePage}></Route>
        <Route
          exact
          path="/settings/auditTrail"
          component={AuditTrailPage}
        ></Route>
        <Route
          exact
          path="/settings/integrations"
          component={IntegrationsPage}
        ></Route>
        <Route exact path="/import/index" component={ImportPage}></Route>
        <Route exact path="/admin/bulkSync" component={BulkSyncPage}></Route>
        <Route
          exact
          path="/admin/clearCache"
          component={ClearCachePage}
        ></Route>
        <Route exact path="/admin/p24Match" component={P24MatchPage}></Route>
        <Route
          exact
          path="/admin/p24AreaMap"
          component={P24AreaMapPage}
        ></Route>
        <Route
          exact
          path="/admin/areaTransfer"
          component={AreaTransferPage}
        ></Route>
        <Route
          exact
          path="/admin/userTransfer"
          component={UserTransferPage}
        ></Route>
        <Route exact path="/admin/reports" component={ReportsPage}></Route>
        <Route path="/admin/saveBrochure" component={SaveBrochurePage}></Route>
        <Route path="/admin/saveReport" component={SaveReportPage}></Route>
        <Route
          exact
          path="/admin/mapAreaTool"
          component={MapAreaToolPage}
        ></Route>
        <Route exact path="/admin/credit" component={CreditPage}></Route>
        <Route
          exact
          path="/admin/syncIssues"
          component={SyncIssuesPage}
        ></Route>
        <Route exact path="/Property" component={PropertyPage}></Route>
        <Route exact path="/library" component={LibraryPage}></Route>
        <Route
          exact
          path="/library/saveContent"
          component={SaveContentPage}
        ></Route>
        <Route
          exact
          path="/Property/SaveProperty"
          component={SavePropertyPage}
        ></Route>
        <Route
          exact
          path="/Admin/FlexPropertySyncD"
          component={FlexPropertySyncDBox}
        ></Route>
        <Route
          exact
          path="/Admin/FlexAgentSyncD"
          component={FlexAgentSyncDBox}
        ></Route>
        <Route
          path="/settings/saveIntegration"
          component={SaveIntegrationsPage}
        ></Route>
        <Route
          path="/import/properties"
          component={ImportPropertiesPage}
        ></Route>
        <Route path="/import/saveProperty" component={SaveProperty}></Route>
        <Route path="/deal/saveDeal" component={DealPage}></Route>
        <Route exact path="/deal" component={LoopPage}></Route>
        <Route
          exact
          path="/Admin/CommissionSlip"
          component={CommissionSlipPage}
        ></Route>
        <Route
          exact
          path="/Admin/SaveCommissionSlip"
          component={SaveCommissionSlipPage}
        ></Route>
        <Route
          exact
          path="/Admin/OfficeDealGrid"
          component={OfficeDealGridPage}
        ></Route>
        <Route
          exact
          path="/Admin/InsightsUsage"
          component={OfficeInsightsPage}
        ></Route>
        <Route path="/testPage" component={TestComponent}></Route>
        <Route exact path="/Home/Unauthorized" component={UnauthorizedPage}></Route>
        <Route exact path="/not-found" component={NotFound}></Route>
        <Route exact path="/server-error" component={ServerErrorPage}></Route>
        <Route exact path="/uncatch-error" component={ErrorPage}></Route>
        <Route component={NotFound}></Route>
      </Switch>
    </>
  );
}
export default observer(App);
