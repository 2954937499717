import React, { useState } from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { Field, Formik } from "formik";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { useHistory } from 'react-router-dom';

function CommissionSlipBox(props) {
  const [commissionSlipId, setcommissionSlipId] = useState(props.data?.commissionSlipId);
  const [groupID, setGroupID] = useState(props.data?.groupID);
  const [officeID, setOfficeID] = useState(props.data?.officeID);
  const [isGeneric, setIsGeneric] = useState(props.data?.isGeneric);
  const [commissionSlipName, setcommissionSlipName] = useState(props.data?.commissionSlipName);
  const [commissionSlipDescription, setcommissionSlipDescription] = useState(props.data?.commissionSlipDescription);
  const [buttonStatus, setButtonStatus] = useState({
    disabled: false,
    processing_save: false,
    processing_delete: false,
  });

  let history = useHistory();

  const [confirmationModalProps, setConfirmationModalProps] = useState({
    showModal: false,
    isConfirmed: (result) => {},
    title: "",
    body: "",
  });

  const openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          console.log("confirmed");
          callback();
        }
        openCloseDeleteModal(false);
      },
    };
    console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const handleStatusChanged = (e) => {
    setIsGeneric(!isGeneric);
  };

  const handleOfficeIdChange = (e) => {
    setOfficeID(e.newValue);
  };

  const handleGroupIdhange = (e) => {
    setGroupID(e.newValue);
  };

  const onCancelClick = (e) => {
    history.push("/Admin/CommissionSlip");
  };

  const executeDeleteClick = (e) => {
    setButtonStatus({
      disabled: true,
      processing_save: false,
      processing_delete: true,
    });

    apiAgent.Admin.deleteCommissionSlipAjax(commissionSlipId)
      .then((response) => {
        history.push("/Admin/CommissionSlip");
      })
      .catch((error) => {
        toast.info(error.message);
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const onDeleteClick = (e) => {
    let title = "Delete commission Slip?";
    let body = "Do you want to delete commission Slip now? This cannot be undone.";
    let callback = executeDeleteClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  const doValidate = (values) => {
    let { commissionSlipName, commissionSlipDescription, commissionSlipId, categories, isGeneric, commissionSlipFile } = values;
    var failed = false;

    if (!commissionSlipName.trim()) {
      toast.info("Please enter commission Slip name");
      failed = true;
    }

    if (!commissionSlipDescription.trim()) {
      toast.info("Please enter commission Slip description");
      failed = true;
    }

    if (commissionSlipId == "" && commissionSlipFile == null) {
      toast.info("Please choose commission Slip file");
      failed = true;
    }

    if (!isGeneric) {
      if (officeID == "" && groupID == "") {
        toast.info("Office or group selection required for non generic report");
        failed = true;
      }
    }

    return !failed;
  };

  const handleSubmit = (values) => {
    console.log("inside Report Box, handleSubmit", values);
    if (!doValidate(values)) return;

    values.officeID = officeID;
    values.groupID = groupID;
    values.isGeneric = isGeneric;

    setButtonStatus({
      disabled: true,
      processing_save: true,
      processing_delete: false,
    });

    var data = new FormData();
    data.append("CommissionSlipId", values.commissionSlipId);
    data.append("CommissionSlipName", values.commissionSlipName.trim());
    data.append("CommissionSlipDescription", values.commissionSlipDescription.trim());
    data.append("CommissionSlipLocation", values.commissionSlipLocation.trim());
    data.append("GroupID", values.groupID);
    data.append("OfficeID", values.officeID);
    data.append("IsGeneric", values.isGeneric.toString());

    if (values.commissionSlipFile != null) data.append("commissionSlipFile", values.commissionSlipFile);

    apiAgent.Admin.saveCommissionSlipAjax(data)
      .then((response) => {
        history.push("/Admin/CommissionSlip");
      })
      .catch((error) => {
        toast.info(error.message);
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };

  const onChooseFileClick = () => {
    document.getElementById("commissionSlipFile")?.click();
  };

  return (
    <Formik
      initialValues={{
        commissionSlipId: props.data?.commissionSlipId,
        groupID: props.data?.groupID,
        officeID: props.data?.officeID,
        isGeneric: props.data?.isGeneric,
        commissionSlipName: props.data?.commissionSlipName,
        commissionSlipDescription: props.data?.commissionSlipDescription,
        officeDD: props.data?.officeDD,
        groupDD: props.data?.groupDD,
        commissionSlipFile: props.data?.commissionSlipFile,
        commissionSlipLocation: props.data?.commissionSlipLocation,
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, errors, values, setFieldValue }) => (
        <form className="SaveReportForm" onSubmit={handleSubmit}>
          <ConfirmationModalPopup {...confirmationModalProps} />
          <div className="col-sm-12">
            <div className="panel panel-default oms-box">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-12">
                    <label>{commissionSlipId != "" ? commissionSlipName : "CREATE"}</label>
                  </div>
                </div>
              </div>
              <div className="panel-body">
                <div className="form-group col-md-12">
                  <label htmlFor="propdata_listingfile">
                    commission Slip File <small>(Upload report file(.trdp))</small>
                  </label>{" "}
                  <br />
                  {values.commissionSlipFile != null || commissionSlipId != "" ? (
                    <span style={{ color: "green" }}>
                      <i className="fa fa-check-circle"></i>&nbsp;
                    </span>
                  ) : null}
                  <a style={{ cursor: "pointer" }} onClick={() => onChooseFileClick()}>
                    Choose File
                  </a>
                  <input
                    id="commissionSlipFile"
                    type="file"
                    accept=".trdp"
                    style={{ display: "none" }}
                    onChange={(e) => setFieldValue("commissionSlipFile", e.target.files[0])}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="commissionSlipName">
                    Commission Slip Name
                  </label>
                  <div className="controls">
                    <Field type="text" name="commissionSlipName" className="form-control required-control" placeholder="Commission Slip Name" />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label className="control-label" htmlFor="commissionSlipName">
                    Commission Slip Description
                  </label>
                  <div className="controls">
                    <Field type="text" name="commissionSlipDescription" className="form-control required-control" placeholder="commission Slip Description" />
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="IsGeneric">IsGeneric (available for all)</label>
                  <div className="controls">
                    <label className="switch">
                      <Field type="checkbox" name="isGeneric" checked={isGeneric} onChange={handleStatusChanged} />
                      <div className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="form-group col-md-12" style={{ display: isGeneric ? "none" : "block" }}>
                  <label htmlFor="groupID">Group</label>
                  <div className="controls">
                    <OMSDropdown
                      id="groupID"
                      options={props.data?.groupDD}
                      valueField="value"
                      labelField="text"
                      value={groupID}
                      onChange={handleGroupIdhange}
                    />
                  </div>
                </div>
                <div
                  className="form-group col-md-12"
                  style={{
                    display: isGeneric || groupID  ? "none" : "block",
                  }}
                >
                  <label htmlFor="OfficeID">Office</label>
                  <div className="controls">
                    <OMSDropdown
                      id="officeID"
                      options={props.data?.officeDD}
                      valueField="value"
                      labelField="text"
                      value={officeID}
                      onChange={handleOfficeIdChange}
                    />
                  </div>
                </div>
              </div>
              <div className="panel-footer">
                <div className="row">
                  <div className="col-md-12">
                    <div className="pull-left">
                      {commissionSlipId != "" ? (
                        <div className="dropup">
                          <button className="btn btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                            More..
                            <span className="caret" />
                          </button>
                          <ul className="dropdown-menu" style={{ width: "auto" }}>
                            <li>
                              {" "}
                              <OMSButtonStyleL
                                type="button"
                                ui_icon="fa-trash-o"
                                ui_type="delete"
                                ui_text="Delete commission Slip"
                                ui_processing_text="Deleting.."
                                processing={buttonStatus.processing_delete}
                                disabled={buttonStatus.disabled}
                                onClick={onDeleteClick}
                              />
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <div className="pull-right">
                      <OMSButtonStyleL
                        style={{ marginRight: 10 }}
                        type="button"
                        ui_icon="fa-times"
                        ui_type="cancel"
                        ui_text="Cancel"
                        ui_processing_text=""
                        processing={false}
                        disabled={buttonStatus.disabled}
                        onClick={onCancelClick}
                      />
                      <OMSButtonStyleL
                        type="submit"
                        ui_icon="fa-check"
                        ui_type="save"
                        ui_text="Save"
                        ui_processing_text="Saving.."
                        processing={buttonStatus.processing_save}
                        disabled={buttonStatus.disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default CommissionSlipBox;
