import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import bootbox from "bootbox";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from 'react-router-dom';

class AreaTransferBox extends React.Component {
  state = {
    ProvinceDD: [],
    FromTownDD: [],
    FromSuburbDD: [],
    ToTownDD: [],
    ToSuburbDD: [],
    FromProvinceID: "",
    ToProvinceID: "",
    FromTownID: "",
    FromSuburbID: "",
    ToTownID: "",
    ToSuburbID: "",
    CountryName: "South Africa",
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  componentDidMount() {
    this.loadProvincesDDFromServer(this.state.CountryName);
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  loadProvincesDDFromServer = (countryName) => {
    this.setState({
      ProvinceDD: [],
      CountryName: "",
      FromTownDD: [],
      FromSuburbDD: [],
      FromProvinceID: "",
      FromTownID: "",
      FromSuburbID: "",
      ToProvinceID: "",
      ToTownDD: [],
      ToSuburbDD: [],
      ToTownID: "",
      ToSuburbID: "",
    });
    /* var xhr = new XMLHttpRequest();
      xhr.open('get', '/Settings/ProvincesDDAjax?Country=' + countryName, true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        this.setState({ ProvinceDD: data, CountryName: countryName });
      }.bind(this);
      xhr.send(); */
    apiAgent.Setting.provincesDDAjax(countryName)
      .then((response) => {
        this.setState({ ProvinceDD: response, CountryName: countryName });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  loadTownsDDFromServer = (ProvinceID, Name) => {
    this.setState({
      BaseProvinceID: ProvinceID,
      BaseTownDD: [],
      BaseSuburbDD: [],
      BaseTownID: "",
      BaseSuburbID: "",
    });
    /* var xhr = new XMLHttpRequest();
      xhr.open('get', '/Settings/TownsDDAjax?ProvinceID=' + ProvinceID, true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        if (Name == "FromProvinceID") {
          this.setState({ FromProvinceID: ProvinceID, FromTownDD: data, FromTownID: '', FromSuburbDD: [], FromSuburbID: '' });
        } else {
          this.setState({ ToProvinceID: ProvinceID, ToTownDD: data, ToTownID: '', ToSuburbDD: [], ToSuburbID: '' });
        }
      }.bind(this);
      xhr.send(); */
    apiAgent.Setting.townsDDAjax(ProvinceID)
      .then((response) => {
        if (Name == "FromProvinceID") {
          this.setState({
            FromProvinceID: ProvinceID,
            FromTownDD: response,
            FromTownID: "",
            FromSuburbDD: [],
            FromSuburbID: "",
          });
        } else {
          this.setState({
            ToProvinceID: ProvinceID,
            ToTownDD: response,
            ToTownID: "",
            ToSuburbDD: [],
            ToSuburbID: "",
          });
        }
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  loadSuburbsDDFromServer = (TownID, Name) => {
    this.setState({ BaseTownID: TownID, BaseSuburbDD: [], BaseSuburbID: "" });
    /* var xhr = new XMLHttpRequest();
      xhr.open('get', '/Settings/SuburbsDDAjax?TownID=' + TownID, true);
      xhr.onload = function () {
        var data = JSON.parse(xhr.responseText);
        if (Name == "FromTownID") {
          this.setState({ FromTownID: TownID, FromSuburbDD: data, FromSuburbID: '' });
        }
        else {
          this.setState({ ToTownID: TownID, ToSuburbDD: data, ToSuburbID: '' });
        }
  
      }.bind(this);
      xhr.send(); */
    apiAgent.Setting.suburbsDDAjax(TownID)
      .then((response) => {
        if (Name == "FromTownID") {
          this.setState({
            FromTownID: TownID,
            FromSuburbDD: response,
            FromSuburbID: "",
          });
        } else {
          this.setState({
            ToTownID: TownID,
            ToSuburbDD: response,
            ToSuburbID: "",
          });
        }
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  handleCountryDDChange = (e) => {
    this.loadProvincesDDFromServer(e.newValue);
  };

  handleDDChange = (e) => {
    if (e.name.indexOf("ProvinceID") != -1) this.loadTownsDDFromServer(e.newValue, e.name);
    else if (e.name.indexOf("TownID") != -1) this.loadSuburbsDDFromServer(e.newValue, e.name);
    else this.setState({ [e.name]: e.newValue });
  };

  onCancelClick = (e) => {
    this.props.history.push('/Settings');
  };

  onClearClick = (e) => {
    this.loadProvincesDDFromServer();
  };

  executeSaveClick = () => {
    this.setState({ processing: true });
    /* var data = new FormData();
      data.append('FromProvinceID', this.state.FromProvinceID);
      data.append('FromTownID', this.state.FromTownID);
      data.append('FromSuburbID', this.state.FromSuburbID);
      data.append('ToProvinceID', this.state.ToProvinceID);
      data.append('ToTownID', this.state.ToTownID);
      data.append('ToSuburbID', this.state.ToSuburbID);
      var xhr = new XMLHttpRequest();
      xhr.open('post', '/Admin/AreaTransferAjax', true);
      xhr.onload = function () {
        this.setState({ processing: false });
        var data = JSON.parse(xhr.responseText);
        if (data.status == "success") {
          $.toaster(data.message, 'Success', 'info');
        }
        else
          $.toaster(data.message, 'Fail', 'danger');
      }.bind(this);
      xhr.send(data); */
    apiAgent.Admin.areaTransferAjax(
      this.state.FromProvinceID,
      this.state.FromTownID,
      this.state.FromSuburbID,
      this.state.ToProvinceID,
      this.state.ToTownID,
      this.state.ToSuburbID
    )
      .then((response) => {
        toast.info(response.message);
      })
      .catch((error) => {
        toast.info("Fail");
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  onSaveClick = (e) => {
    if (
      this.state.FromProvinceID == "" ||
      this.state.FromTownID == "" ||
      this.state.FromSuburbID == "" ||
      this.state.ToProvinceID == "" ||
      this.state.ToTownID == "" ||
      this.state.ToSuburbID == ""
    ) {
      toast.info("Please select areas properly");
      return;
    }

    if (
      this.state.FromProvinceID == this.state.ToProvinceID &&
      this.state.FromTownID == this.state.ToTownID &&
      this.state.FromSuburbID == this.state.ToSuburbID
    ) {
      toast.info("Selected areas should not be same");
      return;
    }

    /* bootbox.confirm({
        title: "Transfer area?",
        message: "Do you want to transfer area. now? This cannot be undone.",
        buttons: {
          cancel: {
            label: '<i class="fa fa-times"></i> Cancel'
          },
          confirm: {
            label: '<i class="fa fa-check"></i> Confirm'
          }
        },
        callback: function (result) {
          if (result) {
            this.executeSaveClick();
          }
        }.bind(this)
      }); */
    let title = "Transfer area?";
    let body = "Do you want to transfer area. now? This cannot be undone.";
    let callback = this.executeSaveClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  render() {
    return (
      <div>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div className="col-sm-12">
          <div className="panel panel-default oms-box">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-12">
                  <label>Area Transfer</label>
                </div>
              </div>
            </div>
            <div className="panel-body">
              <div className="col-md-12">
                <div className="form-group form-inline">
                  <label htmlFor="CountryName" style={{ width: 80 }}>
                    Country
                  </label>
                  <OMSDropdown
                    id="CountryName"
                    options={this.props.CountryDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.CountryName}
                    onChange={this.handleCountryDDChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>From Areas</label>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="FromProvinceID" style={{ width: 80 }}>
                    Province
                  </label>
                  <OMSDropdown
                    id="FromProvinceID"
                    options={this.state.ProvinceDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.FromProvinceID}
                    onChange={this.handleDDChange}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="FromTownID" style={{ width: 80 }}>
                    Town
                  </label>
                  <OMSDropdown
                    id="FromTownID"
                    options={this.state.FromTownDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.FromTownID}
                    onChange={this.handleDDChange}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="FromSuburbID" style={{ width: 80 }}>
                    Suburb
                  </label>
                  <OMSDropdown
                    id="FromSuburbID"
                    options={this.state.FromSuburbDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.FromSuburbID}
                    onChange={this.handleDDChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>To Areas</label>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="ToProvinceID" style={{ width: 80 }}>
                    Province
                  </label>
                  <OMSDropdown
                    id="ToProvinceID"
                    options={this.state.ProvinceDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.ToProvinceID}
                    onChange={this.handleDDChange}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="ToTownID" style={{ width: 80 }}>
                    Town
                  </label>
                  <OMSDropdown
                    id="ToTownID"
                    options={this.state.ToTownDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.ToTownID}
                    onChange={this.handleDDChange}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="ToSuburbID" style={{ width: 80 }}>
                    Suburb
                  </label>
                  <OMSDropdown
                    id="ToSuburbID"
                    options={this.state.ToSuburbDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.ToSuburbID}
                    onChange={this.handleDDChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Save"
                    ui_processing_text="Saving.."
                    processing={this.state.processing}
                    disabled={this.state.processing}
                    onClick={this.onSaveClick}
                  />
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Clear"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onClearClick}
                  />
                </div>
              </div>
            </div>
            <div className="panel-footer">
              <div className="row">
                <div className="col-md-12">
                  <div className="pull-right">
                    <OMSButtonStyleL
                      style={{ marginRight: 10 }}
                      type="button"
                      ui_icon="fa-times"
                      ui_type="cancel"
                      ui_text="Cancel"
                      ui_processing_text=""
                      processing={false}
                      disabled={false}
                      onClick={this.onCancelClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AreaTransferBox);
