import { observer } from "mobx-react";
import HeaderWrapper from "../../header/HeaderWrapper";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { Redirect } from "react-router";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";
import { Link } from "react-router-dom";

export default observer(function NotFound() {
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  document.title = "Base - Unauthorized";

  useEffect(() => {
    const redirectIfNotLoggedIn = async () => {
      if (!isLoggedIn) {
        return <Redirect to="/login" />;
      }
    };
    redirectIfNotLoggedIn();
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <HeaderWrapper />
      <div className="container-fluid">
        <Breadcrumb
          breadcrumbs={[{ text: "Home", path: "/" }, { text: "Not Found" }]}
        />
        <div className="row">
          <div id="content" className="col-md-12">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="text-center">
                <h1 style={{ fontSize: "95px" }}>Not Found</h1>
                <br />
                <br />
                <h2>Oops! Page Not Found</h2>
                <br />
                <h4>
                  We're sorry, but the page you're looking for doesn't exist. It
                  might have been moved or deleted.
                </h4>
                <br />
                <br />
                {/*  <a href="/" className="btn btn-success-oms">Take Me Home</a> */}
                <Link to={"/"} className="btn btn-success-oms">
                  Take Me Home
                </Link>
                <br />
                <br />
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    </>
  );
});
