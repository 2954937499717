import React, { useEffect, useState } from "react";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Progressbar = (props) => {
  return (
    <>
      <CircularProgressbarWithChildren
        value={props.greenCounts + props.redCounts}
        styles={buildStyles({
          pathColor: "#92ab1c",
          trailColor: "black",
          strokeLinecap: "butt",
        })}
        strokeWidth={12}
      >
        <CircularProgressbar
          value={props.redCounts}
          styles={buildStyles({
            pathColor: props.greenCounts == 100 ? "#92ab1c" : "#ed1d24",
            trailColor: "transparent",
            strokeLinecap: "butt",
          })}
          strokeWidth={12}
        />
      </CircularProgressbarWithChildren>
    </>
  );
};

export default Progressbar;
