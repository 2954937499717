import React from "react";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";

export class MediaLinkComponent extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            etLinkID: '',
            etOrder: 0,
            etLink: '',
            etDescription: '',
            Links: this.props.data,
            confirmationModalProps: {
                showModal: false,
                isConfirmed: (result) => { },
                title: "",
                body: "",
            },
        };
    }
    openCloseDeleteModal = (isOpen) => {
        let varConfirmationModalProps = this.state.confirmationModalProps;
        varConfirmationModalProps.showModal = isOpen;
        this.setState({ confirmationModalProps: varConfirmationModalProps });
    };

    showPopupAndDeleteOnConfirmation = (title, body, callback) => {
        let varConfirmationModalProps = {
            title,
            body,
            showModal: true,
            isConfirmed: (isConfirmed) => {
                if (isConfirmed) {
                    callback();
                }
                this.openCloseDeleteModal(false);
            },
        };
        //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
        this.setState({ confirmationModalProps: varConfirmationModalProps });
    };

    handleChange = (e) => {
        if (e.target.name == "etLink" && this.state.etLinkID == "") {
            var isSaved = false;
            var Link = e.target.value;
            var videoid = this.youtube_parser(Link);
            if (videoid) {
                var GoogleKeyID = "AIzaSyDfmo0RoVnrj43ZAq4tMXkHST7SZDNgLsg";
                var URL = "https://www.googleapis.com/youtube/v3/videos?id=" + videoid + "&key=" + GoogleKeyID + "&part=snippet";
                var xhr = new XMLHttpRequest();
                xhr.open('get', URL, true);
                xhr.onload = function () {
                    var data = JSON.parse(xhr.responseText);
                    var title = data.items[0].snippet.title;
                    if (title) {
                        isSaved = true;
                        this.setState({ etLink: Link, etDescription: title });
                    }
                }.bind(this);
                xhr.send();
            }
            if (!isSaved) {
                this.setState({
                    [e.target.name]: e.target.value,
                });
            }
        }
        else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }




    };

    youtube_parser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    };

    handleAddExternalLinkClick = () => {
        this.setState({ etLinkID: '', etLink: '', etDescription: '', etOrder: 0 });
        window.$('#ELModal').modal('show');
    };

    handleUpdateExternalLinkClick = (etLinkID) => {
        var index = -1;
        var links = this.state.Links;
        var linksLength = this.state.Links ? this.state.Links.length : 0;

        for (var i = 0; i < linksLength; i++) {
            if (links[i].linkID == etLinkID) {
                index = i;
            }
        }

        if (index == -1)
            return;

        this.setState({ etLinkID: links[index].linkID, etLink: links[index].link, etDescription: links[index].description, etOrder: links[index].order });
        window.$('#ELModal').modal('show');
    };

    onSaveExternalLinkClick = () => {

        var linkItem = { order: this.state.etOrder, linkID: this.state.etLinkID, link: this.state.etLink.trim(), description: this.state.etDescription };
        var RegWebsite = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,15}(:[0-9]{1,5})?(\/.*)?$/;

        if (!this.state.etLink.trim() || RegWebsite.test(this.state.etLink) == false) {
            toast.info('Please enter valid link');
            return;
        }

        if (linkItem.link.startsWith('http') == false)
            linkItem.link = 'http://' + linkItem.link;


        if (!this.state.etDescription.trim()) {
            toast.info('Please enter link description');
            return;
        }

        var links = Array.isArray(this.state.Links) ? this.state.Links.slice(0) : [];
        var linksLength = this.state.Links ? this.state.Links.length : 0;
        if (linkItem.linkID == "") {
            linkItem.linkID = "NAP0";
            var maxOrder = 0;
            if (linksLength > 0) {
                maxOrder = Math.max.apply(Math, links.map(function (o) { return o.order; }));
                linkItem.order = maxOrder + 1;
                linkItem.linkID = "NAP" + linkItem.order;
            }
            links.push(linkItem);
        }
        else {
            for (var i = 0; i < linksLength; i++) {
                if (links[i].linkID == linkItem.linkID) {
                    links[i] = linkItem;
                    break;
                }
            }
        }
        this.setState({ Links: links, expanded: true });
        window.$('#ELModal').modal('hide');
        if (this.props.onChange !== undefined)
            this.props.onChange(links);
    };

    executeDeleteExternalLinkClick = () => {
        var index = -1;
        var links = this.state.Links;
        var linksLength = this.state.Links ? this.state.Links.length : 0;

        for (var i = 0; i < linksLength; i++) {
            if (links[i].linkID == this.state.etLinkID) {
                index = i;
            }
        }

        if (index != -1) {
            links.splice(index, 1);
            this.setState({ Links: links, expanded: true });
            window.$('#ELModal').modal('hide');

            if (this.props.onChange !== undefined)
                this.props.onChange(links);
        }
    };

    onDeleteExternalLinkClick = () => {
        // bootbox.confirm({
        //     title: "Delete external links?",
        //     message: "Do you want to delete external links now?",
        //     buttons: {
        //         cancel: {
        //             label: '<i class="fa fa-times"></i> Cancel'
        //         },
        //         confirm: {
        //             label: '<i class="fa fa-check"></i> Confirm'
        //         }
        //     },
        //     callback: function (result) {
        //         if (result) {
        //             this.executeDeleteExternalLinkClick();
        //         }
        //     }.bind(this)
        // });
        let title = "Delete external links?";
        let body = "Do you want to delete external links now?";
        let callback = this.executeDeleteExternalLinkClick;
        this.showPopupAndDeleteOnConfirmation(title, body, callback);
    };

    onChangeExpand = (e) => {
        var expanded = !this.state.expanded;
        this.setState({ expanded: expanded });
    };

    doSetState = (data) => {
        this.setState({ Links: data });
    };

    render() {
        var self = this;
        var linksLength = this.state.Links ? this.state.Links.length : 0;
        return (
            <div>
                <ConfirmationModalPopup {...this.state.confirmationModalProps} />
                {linksLength == 0 ? null :
                    <div className="panel panel-default">
                        <div className="panel-heading" style={{ cursor: 'pointer' }} onClick={this.onChangeExpand}>
                            <div className="row">
                                <div className="col-md-12">
                                    <a style={{ cursor: 'pointer', display: 'inline-block', paddingTop: 5 }}><i className={this.state.expanded ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i></a>
                                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>External links ({linksLength})</span>
                                </div>
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="col-md-12">
                                {this.state.expanded ?
                                    <div>
                                        {
                                            this.state.Links.map(function (data, index) {
                                                return (
                                                    <div key={data.linkID} style={{ marginTop: 10 }} className="col-md-12">
                                                        <a title="open link" style={{ cursor: 'pointer', marginRight: 10 }} href={data.link} target="_blank"><i className="fa fa-link"></i></a>
                                                        <a title="update" style={{ cursor: 'pointer' }} onClick={() => self.handleUpdateExternalLinkClick(data.linkID)} >{data.description}</a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                }
                <div id="ELModal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="ELModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 className="modal-title" id="ELModalLabel">Add external website links including virtual tours and youtube video</h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="etLink">Link</label>
                                        <input type="text" className="form-control" id="etLink" name="etLink" value={this.state.etLink} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="etDescription">Description</label>
                                        <input type="text" className="form-control" id="etDescription" name="etDescription" value={this.state.etDescription} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="pull-left">
                                    {this.state.etLinkID != "" ?
                                        <a style={{ cursor: 'pointer', display: 'inline-block', paddingTop: 5, marginRight: 25 }} onClick={this.onDeleteExternalLinkClick}>Delete</a>
                                        : null}
                                    <a target="_blank" href="https://entegral.net/tours" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#333', display: 'inline-block', paddingTop: 5 }}>Free self service tours</a>
                                </div>
                                <a data-dismiss="modal" style={{ cursor: 'pointer' }}>Cancel</a>
                                <button type="button" style={{ marginLeft: 10 }} className="btn btn-success-oms" onClick={this.onSaveExternalLinkClick}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}