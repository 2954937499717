import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import apiAgent from "../../app/api/apiAgent";
//import LoadingComponent from "../../app/layout/LoadingComponent";
import { LibraryResponseModel } from "../../app/models/LibraryResponseModel";
import { useStore } from "../../app/stores/store";
import HeaderWrapper from "../header/HeaderWrapper";
import LibraryBox from "./LibraryBox";
import AjaxLoader from "../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../app/common/common-components/Breadcrumb";


export default observer(function LibraryPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [library, setLibrary] = useState();
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let libraryName = "";
    if (params.has("m")) {
      const name = params.get("m");
      libraryName = name !== null ? name : "";
    }

    setIsLoading(true);
    apiAgent.Library.getLibrary(libraryName)
      .then((response) => {
        let oLibrary = response;
        setLibrary(oLibrary);
        //console.log("Library", oLibrary);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
    (function ($, window) {

      window.$.fn.contextMenu = function (settings) {

          return this.each(function () {

              // Open context menu
              window.$(this).on("contextmenu", function (e) {
                  // return native menu if pressing control
                  if (e.ctrlKey) return;

                  var $invokedOn = window.$(e.target);
                  if (!$invokedOn.is('li'))
                      $invokedOn = $($invokedOn.closest('li'));
                  if ($invokedOn === undefined)
                      return;
                  var data = $invokedOn.data();
                  if (data === undefined)
                      return;
                  
                  if (data.fileid == '') {
                      $('#liRename').addClass('disabled');
                      $('#liDelete').addClass('disabled');
                  }
                  else {
                      $('#liRename').removeClass('disabled');
                      $('#liDelete').removeClass('disabled');
                  }

                  //open menu
                  var $menu = $(settings.menuSelector)
                      .data("invokedOn", $invokedOn)
                      .show()
                      .css({
                          position: "absolute",
                          left: getMenuPosition(e.clientX, 'width', 'scrollLeft'),
                          top: getMenuPosition(e.clientY, 'height', 'scrollTop')
                      })
                      .off('click')
                      .on('click', 'a', function (e) {
                          $menu.hide();
                          var $invokedOn = $menu.data("invokedOn");
                          var $selectedMenu = $(e.target);
                          settings.menuSelected.call(this, $invokedOn, $selectedMenu);
                      });

                  return false;
              });

              //make sure menu closes on any click
              $('body').click(function () {
                  $(settings.menuSelector).hide();
              });
          });

          function getMenuPosition(mouse, direction, scrollDir) {
              var win = $(window)[direction](),
                  scroll = $(window)[scrollDir](),
                  menu = $(settings.menuSelector)[direction](),
                  position = mouse + scroll;

              // opening menu would pass the side of the page
              if (mouse + menu > win && menu < mouse)
                  position -= menu;

              return position;
          }

      };
  })(jQuery, window);
    `;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };    
  }, []);

  document.title = "Base - Library";

  if (isLoading) {
    return <AjaxLoader />;
  } else if (!isLoading && library) {
    return (
      <div>
        <HeaderWrapper />
        <div className="library-page container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Library" },
            ]}
          />

          <div className="row">
            <div id="content" className="col-sm-12 col-md-12">
              <LibraryBox data={library} />
            </div>
          </div>
          <ul id="contextMenu" className="dropdown-menu" role="menu" style={{ display: "none" }}>
            <li id="liRename">
              <a tabIndex={-1} href="#">
                Rename
              </a>
            </li>
            <li id="liDelete">
              <a tabIndex={-1} href="#">
                Delete
              </a>
            </li>
            <li className="divider"></li>
            <li>
              <a tabIndex={-1} href="#">
                New Folder
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
});
