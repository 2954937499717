import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiAgent from "../../../app/api/apiAgent";
//import LoadingComponent from "../../../app/layout/LoadingComponent";
import { GroupModel } from "../../../app/models/GroupModel";
import { ConfirmationModalProps } from "../../../app/models/ModalControlProps/ConfirmationModalProps";
import { OfficeModel } from "../../../app/models/OfficeModel";
import { UserModel } from "../../../app/models/UserModel";
import { useStore } from "../../../app/stores/store";
import HeaderWrapper from "../../header/HeaderWrapper";
import GroupBox from "./GroupBox";
import AjaxLoader from "../../../app/common/common-components/AjaxLoader";
import Breadcrumb from "../../../app/common/common-components/Breadcrumb";

export default observer(function SaveGroupPage() {
  let varConfirmModalProps: ConfirmationModalProps = {
    showModal: false,
    isConfirmed: (result: boolean) => {},
    title: "",
    body: "",
  };

  const [group, setGroup] = useState<GroupModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>(varConfirmModalProps);
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search); // id=123
    let groupId: string = "";
    if (params.has("ID")) {
      const id = params.get("ID");
      groupId = id !== null ? id : "";
    }

    setIsLoading(true);
    apiAgent.Setting.saveGroup(groupId)
      .then((response: GroupModel) => {
        let oGroup: GroupModel = response;
        setGroup(oGroup);

        //console.log("group", oGroup);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  document.title = "Base - Save Group";

  if (isLoading) {
    return (
    <AjaxLoader />
    );
  } 
  else if (!isLoading && group) {
    return (
      <>
        <HeaderWrapper />

        <div className="save-group container-fluid">
          <Breadcrumb
            breadcrumbs={[
              { text: "Home", path: "/" },
              { text: "Settings", path: "/settings" },
              { text: "Groups", path: "/settings/groups" },
              { text: !group.groupID ? "Create" : group.name}
            ]}
          />
          <div className="row">
            <div className="hidden-sm col-md-2"></div>
            <div id="content" className="col-sm-12 col-md-8">
              <GroupBox data={group} iscreate={!group.groupID ? true : false} setConfirmationModalProps={setConfirmationModalProps} />
            </div>
            <div className="hidden-sm col-md-2"></div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
});
