import { createContext, useContext } from "react";
import CommonStore from "./commonStore";

import UserStore from "./userStore";
import MasterDataStore from "./MasterDataStore";
import DealStore from "./dealStore";

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  masterDataStore: MasterDataStore;
  dealStore:DealStore
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  masterDataStore: new MasterDataStore(),
  dealStore: new DealStore()
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
