import React, { useState } from "react";
import BrowserInfoModal from "./BrowserInfoModal";

export default function AuthFooter() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <>
      <footer className="footer">
        {}
        <p>
          ©{currentYear}{" "}
          <a href="http://www.entegral.net/" rel="noreferrer" target="_blank">
            Entegral
          </a>{" "}
          |{" "}
          <a href="https://www.entegral.net/terms-and-conditions/" rel="noreferrer" title="Terms of Service" target="_blank">
            Terms of Service
          </a>{" "}
          |{" "}
          <a href="https://www.entegral.net/privacy-policy/" rel="noreferrer" title="Privacy Policy" target="_blank">
            Privacy Policy
          </a>{" "}
          |{" "}
          <a href="#" rel="noreferrer" title="Browser Info" onClick={handleShow}>
            Browser Info
          </a>
          <BrowserInfoModal show={show} handleClose={handleClose} />
        </p>
      </footer>
    </>
  );
}
