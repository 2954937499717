import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";
import bootbox from "bootbox";

export class OMSSocialBox extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    ui_description: PropTypes.string.isRequired,
    ui_icon: PropTypes.string.isRequired,
  };

  static defaultProps = {
    id: "",
    name: "",
    value: "",
    onChange: null,
    style: null,
    ui_icon: "",
    ui_description: "",
  };

  state = {
    value: this.props.value,
  };

  handleClick = (e) => {
    bootbox.prompt({
      title: this.props.ui_description,
      value: this.state.value,
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Cancel',
        },
        confirm: {
          label: '<i class="fa fa-check"></i> Save',
        },
      },
      callback: function (result) {
        if (result != null) {
          this.setState({ value: result });
          if (this.props.onChange) this.props.onChange(this.props.name, result);
        }
      }.bind(this),
    });
  };

  render() {
    return (
      <span id={this.props.id} name={this.props.name} style={this.props.style} onClick={this.handleClick}>
        <i className={"fa " + this.props.ui_icon + " oms-social-handle" + (this.state.value == "" ? "" : " oms-social-handle-active")}></i>
      </span>
    );
  }
}

export class OMSNumericBox extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    maxLength: PropTypes.number.isRequired,
    maxDecimalPoints: PropTypes.number,
    value: PropTypes.any,
    style: PropTypes.object,
    isDecimal: PropTypes.bool,
    separator: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    value: "",
    maxLength: 10,
    maxDecimalPoints: 5,
    id: "",
    name: "",
    onChange: null,
    style: null,
    isDecimal: false,
    placeholder: "",
    separator: "",
  };

  constructor(props, context) {
    super(props, context);
    var value = this.convertToValue(props.value);

    this.state = {
      value: value,
    };
  }

  componentWillReceiveProps(nextProps) {
    // console.log("componentWillReceiveProps",nextProps);
    var value = this.convertToValue(nextProps.value);
    this.setState({ value: value });
  }

  convertToValue = (val) => {
    var value = "";
    if (this.props.separator && this.props.separator != "") value = this.convertToSeparator(val);
    else if (this.props.isDecimal) value = this.convertToDecimal(val);
    else value = this.convertToInteger(val);

    return value;
  };

  convertToDecimal = (value) => {
    if (value === undefined || value == null) {
      return "";
    }
    value = value.toString();
    var allowedDot = true;
    var numberText = "";
    var numbers = "0123456789";
    for (var i = 0; i < value.length; i++) {
      if (numbers.indexOf(value[i]) > -1) {
        numberText = numberText + value[i];
      } else if (allowedDot && ".".indexOf(value[i]) == 0) {
        allowedDot = false;
        numberText = numberText + value[i];
      } else if (i == 0 && value[i] == "-") {
        numberText = numberText + value[i];
      }
    }

    var index = numberText.indexOf('.');
    if(index != -1){
      return numberText.substring(0,index) + '.' + numberText.substring(index+1, index + 1 + this.props.maxDecimalPoints);
    }

    return numberText;
  };

  convertToInteger = (value) => {
    if (value === undefined || value == null) {
      return "";
    }
    value = value.toString();
    var numberText = "";
    var numbers = "0123456789";
    for (var i = 0; i < value.length; i++) {
      if (numbers.indexOf(value[i]) > -1) {
        numberText = numberText + value[i];
      }
    }
    return numberText;
  };

  convertToSeparator = (value) => {
    if (value === undefined || value == null) {
      return "";
    }
    value = value.toString().replace(",", "");
    var allowedDot = this.props.isDecimal ? true : false;
    var numberText = "";
    var numbers = "0123456789";
    for (var i = 0; i < value.length; i++) {
      if (numbers.indexOf(value[i]) > -1) {
        numberText = numberText + value[i];
      } else if (allowedDot && ".".indexOf(value[i]) == 0) {
        allowedDot = false;
        numberText = numberText + value[i];
      } else if (i == 0 && value[i] == "-") {
        numberText = numberText + value[i];
      }
    }

    var arr = numberText.split(".");
    numberText = arr[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    if (arr.length > 1) numberText = numberText + "." + arr[1];


    var index = numberText.indexOf('.');
    if(index != -1){
      return numberText.substring(0,index) + '.' + numberText.substring(index+1, index + 1 + this.props.maxDecimalPoints);
    }

    return numberText;
  };

  handleChange = (e) => {
    var value = this.convertToValue(e.target.value);
    if (value != this.state.value) {
      if (this.props.onChange) this.props.onChange(this.props.name, value);
    }
    this.setState({ value: value });
    // console.log("inside handle change of OMS Numeric",this.state.value);
  };

  render() {
    return (
      <input
        type="text"
        className={this.props.className === undefined ? "form-control" : this.props.className}
        id={this.props.id}
        name={this.props.name}
        placeholder={this.props.placeholder}
        title={this.props.placeholder}
        style={this.props.style}
        value={this.state.value}
        maxLength={this.props.maxLength}
        onChange={this.handleChange}
        readOnly={this.props.readOnly === undefined ? false : this.props.readOnly}
      />
    );
  }
}

export class DateTimePickerComp extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    format: PropTypes.string,
    placeholder: PropTypes.string,
    useCurrent: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };
  static defaultProps = {
    id: "datetimepicker",
    format: "DD-MM-YYYY",
    useCurrent: false,
    placeholder: "",
    value: "",
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.$("#" + this.props.id).datetimepicker({
      format: this.props.format,
      useCurrent: this.props.useCurrent,
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "fa fa-arrow-left",
        next: "fa fa-arrow-right",
        today: "fa fa-screenshot",
        clear: "fa fa-trash",
        close: "fa fa-remove",
      },
    });

    window.$("#" + this.props.id).on(
      "dp.change",
      function (e) {
        var val = e.currentTarget.value;
        if (this.props.onChange !== undefined) this.props.onChange(val);
      }.bind(this)
    );
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.value !== this.value.length) {
      this.setValue(nextProps.value);
    }
    return false;
  }
  setValue = (value) => {
    //console.log(value);
  };
  handleChange = (e) => {
    var val = e.target.value;
    if (this.props.value !== val) {
      this.props.onChange(val);
    }
  };
  render() {
    return (
      <input
        id={this.props.id}
        type="text"
        placeholder={this.props.placeholder}
        style={{ width: 150 }}
        className="form-control"
        value={this.props.value}
        onChange={this.handleChange}
      />
    );
  }
}
