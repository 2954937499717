import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import GridPanelHeading from "./SyndicationChildComponents/GridPanelHeading";
import SyndicationSummaryGrid from "./SyndicationChildComponents/SyndicationSummaryGrid";
import { SyndicationDetailModel } from "../../../app/models/SyndicationDetailModel";
import SyndicationDetailGrid from "./SyndicationChildComponents/SyndicationDetailGrid";
import { useStore } from "../../../app/stores/store";

interface SyndicationComponentProps {
  OfficeDD: [];
}

const SyndicationComponent: React.FC<SyndicationComponentProps> = (props) => {
  const { masterDataStore } = useStore();
  
  let portalConfigs = masterDataStore.masterData?.portalConfigMasterData;
  const [officeID, setOfficeID] = useState<string>("");
  const [syndicationDetailData, setSyndicationDetailData] = useState<SyndicationDetailModel[]>([]);
  const [platform, setPlatform] = useState<string>("");
  const [statusType, setStatusType] = useState<string>("");

  useEffect(() => {
    if (!masterDataStore.masterData) {
      masterDataStore.loadMasterData();
    }
  }, [masterDataStore]);

  useEffect(() => {
    if (masterDataStore.masterData) {
      //console.log("Inside New Syndication Component useEffect, masterData=", masterDataStore.masterData.portalConfigMasterData);
    }
  }, [masterDataStore.masterData]);

  const handleCellClick = (platform: string, columnName: string, data: SyndicationDetailModel[]) => {
    setPlatform(platform);
    setStatusType(columnName);
    setSyndicationDetailData(data);
  };

  const officeSelectionChanged = (value: string) => {
    setOfficeID(value);
    setSyndicationDetailData([]);
  };

  const getPortalLogoUrl = (platform: string): string | undefined => {
    const portalConfig = portalConfigs?.find((config) => config.friendlyName === platform);
    return portalConfig?.logoUrl;
  };

  const getStatusIcon = (statusType: string): JSX.Element | null => {
    const iconStyle = { height: "auto", maxWidth: "120px", marginRight: "10px", borderRadius: "10px" };
    switch (statusType) {
      case "In Process":
        return <i className="fa fa-clock-o" style={{ ...iconStyle, color: "orange" }} />;
      case "Error":
        return <i className="fa fa-exclamation-triangle" style={{ ...iconStyle, color: "red" }} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel">
        <GridPanelHeading OfficeDD={props.OfficeDD} officeSelectionChanged={officeSelectionChanged} />
        <SyndicationSummaryGrid parentGridLoading={false} officeID={officeID} handleCellClick={handleCellClick} portalConfigs={portalConfigs!} />
      </div>
      {syndicationDetailData.length > 0 && (
        <div style={{ marginTop: "30px" }}>
          <h3 style={{ marginBottom: "20px", paddingLeft: "15px", display: "flex", alignItems: "center" }}>
            {getPortalLogoUrl(platform) && (
              <img src={getPortalLogoUrl(platform)} alt={platform} style={{ height: "auto", maxWidth: "120px", marginRight: "10px", borderRadius: "10px" }} />
            )}
             {getStatusIcon(statusType)} {statusType}
          </h3>
          <div className="panel panel-default panel-table oms-box" data-intercom-target="grid-panel" style={{ padding: "15px" }}>
            <SyndicationDetailGrid data={syndicationDetailData} detailGridLoading={false} portalConfigs={portalConfigs!} status={statusType}/>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(SyndicationComponent);
