import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { toast } from "react-toastify";
import { MediaFileComponent } from "./MediaFileComponent";
import { MediaLinkComponent } from "./MediaLinkComponent";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import Compressor from "compressorjs";
import SortableList, { SortableItem } from "react-easy-sort";

export const MediaStepView = forwardRef(({ id, super_access, data, officeSyncID, doPhotoUploadProgressChange }, ref) => {

  const doCheckFormData = (data) => {
    var result = { status: "error", message: "Fields are required." };
    var resultSuccess = { status: "success", message: "" };
    return resultSuccess;
  }
  const doSetFormData = async (data) => {

    await uploadFilesAndPhotos();
    const mediaData = {
      MandateID: state.MandateID,
      PropertyID: state.PropertyID,
      Links: state.Links.map((linkItem, i) => {
        return {
          LinkID: linkItem.linkID.indexOf("NAP") != -1 ? "" : linkItem.linkID,
          Link: linkItem.link,
          Description: linkItem.description,
          Order: linkItem.order,
        }
      }),
      // Photos: state.Photos,
      Photos: state.Photos.map(photo => ({
        ...photo,
        dataUrl: "",
      })),
      Files: state.Files.map((fileItem, i) => {
        let fileID = fileItem.fileID;
        let index = fileID.indexOf("NAP");
        return {
          FileID: fileItem.fileID,
          FileName: fileItem.fileName,
          Description: fileItem.description,
          Order: fileItem.order,
          StrIsNewFile: fileItem.isNewFile,
          FileIcon: "",
          Size: fileItem.file ? fileItem.file.size : fileItem.size,
        };
      })
    };
    let mediaJson = JSON.stringify(mediaData);
    data.append("MediaJson", mediaJson);
  }

  useImperativeHandle(ref, () => {
    return {
      doCheckFormData,
      doSetFormData
    };
  });

  const [confirmationModalProps, setConfirmationModalProps] = useState({
    showModal: false,
    isConfirmed: (result) => { },
    title: "",
    body: "",
  });

  const linkComponentRef = useRef();
  const fileComponentRef = useRef();
  const [state, setState] = useState({});
  const [imageDescription, setImageDescription] = useState({});
  const [viewImage, setViewImage] = useState({});
  const [resizeTrigger, setResizeTrigger] = useState(0); // Used to force re-render
  const [uploadCount, setUploadCount] = useState(0);
  const [saveUploadCount, setSaveUploadCount] = useState(0);
  const [compressPhotos, setCompressPhotos] = useState([]);
  const [currentFilesLength, setCurrentFilesLength] = useState(0);
  var arrPhotosUpload = [];

  useEffect(() => {
    setState(getStateFromData(data));
  }, [data]);


  useEffect(() => {
    if (uploadCount > 0) {
      if (uploadCount === saveUploadCount) {
        doPhotoUploadProgressChange(false);
      }
      else {
        doPhotoUploadProgressChange(true);
      }
    }
  }, [uploadCount, saveUploadCount])

  const uploadFilesAndPhotos = async () => {
    let newFiles = state.Files.filter((file) => file.fileID.indexOf("NAP") !== -1);
    let oldFiles = state.Files.filter((file) => file.fileID.indexOf("NAP") === -1);

    newFiles.forEach((file) => {
      file.isNewFile = "true";
    });
    oldFiles.forEach((file) => {
      file.isNewFile = "false";
    });

    
    const modifyFileName = (file) => {
      if (file.fileName.includes(",") || file.fileName.includes("+") || file.fileName.includes("&")) {
        file.fileName = file.fileName.replace(/[,+&]/g, "-");
      }
    };
    newFiles.forEach(modifyFileName);
    oldFiles.forEach(modifyFileName);

    const photos = state.Photos;
    const photoNames = photos.map((photo) => photo.fileName);
    const fileNames = newFiles.map((file) => file.fileName);
    let presignedUrls = [];

    try {
      if (fileNames.length !== 0) {
        presignedUrls = await apiAgent.S3Helper.getPresignedUrls(fileNames, officeSyncID, true);
        for (const file of newFiles) {
          if (file) {
            const jsonString = presignedUrls[file.fileName];
            const jsonObject = JSON.parse(jsonString);
            file.fileName = jsonObject.objectKey;
            file.fileID = jsonObject.fileID;
            const url = jsonObject.url;

            
            await uploadFile(file, url);
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        Files: [...oldFiles, ...newFiles],
      }));
    } catch (error) {
      console.error("inside uploadFilesAndPhotos, error=", error);
    }
};


  const uploadFile = async (file, url) => {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "x-amz-acl": "public-read"
      },
      body: file.file,
    };

    try {
      const response = await fetch(url, options);
      return response;
    } catch (error) {
      console.error("Error", error);
    }
  };

  const doSort = (photos) => {
    // Update the order field based on the new position in the array
    const updatedItems = photos?.map((item, index) => ({
      ...item,
      order: index + 1 // Assuming you want to use the index as the order value
    }));
    setState({ ...state, Photos: updatedItems });
  }
  const handleAddFilesClick = (e) => {
    fileComponentRef.current.handleAddFilesClick(e);
  }
  const handleAddExternalLinkClick = (e) => {
    linkComponentRef.current.handleAddExternalLinkClick(e);
  }
  const handleAddPhotosClick = (e) => {
    window.$("#uploadPhoto").click();
  }
  const onFileComponentChange = (files, fileCollection) => {
    setState((prev) => ({ ...prev, Files: files }));
  }
  const getStateFromData = (data) => {
    return {
      Step: "Listing capture: Media",
      PropertyID: data.propertyID,
      Photos: data.photos.map(photo => ({
        ...photo,
        rotate: photo.rotate === "" ? 0 : photo.rotate
      })),
      Files: data.files,
      Links: data.links,
      photo_expanded: true,
      ViewZoom: 100,

    };
  };
  const onLinkComponentChange = (Link) => {
    setState((prev) => ({ ...prev, Links: Link }));
  }
  const onChangeExpand = () => {
    setState((prev) => ({
      ...prev, photo_expanded: !prev.photo_expanded
    }))
  }

  const handleUploadPhotoChange = (e) => {

    let files

    setCompressPhotos([]);
    setCurrentFilesLength(0);

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    var filesLength = files ? files.length : 0;
    setUploadCount((prev) => prev + filesLength);
    var arrPhotos = [];

    if (files !== undefined) {
      for (const [index, item] of Array.from(files).entries()) {
        if (!item.name.match(/.(jpg|jpeg|png|gif|bmp|svg)$/i))
          toast.info("Only jpg/jpeg, png, gif, bmp and svg images are allowed!");
        else arrPhotos.push({ item, order: index })

      }
      console.log("handleUploadPhotoChange ::: ", arrPhotos);

      setState((prev) => ({
        ...prev, PhotoUpload: arrPhotos
      }));
    }
  }
  const reorderImages = (arr) => {
    var sortedImages = [...arr].sort((a, b) => a.order - b.order)
    return sortedImages;
  }
  const onUploadFileReaderLoaded2 = (file, order) => {

    if (isBlobFile(file)) {

      setCompressPhotos((prev) => [
        ...prev,
        { file: convertBlobToFile(file, file.name), order: order }
      ])

    }
    else {

      setCompressPhotos((prev) => [
        ...prev,
        { file: file.item, order: order }
      ])

    }

    console.log("Upload Reader :: ", file, "Order ::: ", order);

  };


  const isBlobFile = (file) => {
    return file instanceof Blob;
  }
  const convertBlobToFile = (blob, fileName) => {

    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: blob.lastModified,
    });
    return file;
  }

  useEffect(() => {
    var arrPhotos = [];
    if (currentFilesLength && compressPhotos.length > 0) {
      if (currentFilesLength == compressPhotos.length) {

        arrPhotos = reorderImages(compressPhotos);
        console.log("Use Effect :: ", arrPhotos);

        onReaderFiles(arrPhotos);
      }
    }

  }, [compressPhotos, currentFilesLength])

  const onReaderFiles = async (arrCopressPhotos) => {
    for (const fileElement of arrCopressPhotos) {
      await readFileAsDataURL(fileElement.file);
    }


  }

  const readFileAsDataURL = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        if (e.target.readyState === FileReader.DONE) {
          resolve(e.target.result);
          var photos = state.Photos;
          let photoItem = {
            photoID: "NAP1",
            fileName: "",
            latLong: "",
            aiFileds: "",
            description: "",
            order: 1,
            dataUrl: e.target.result,
          };
          if (state?.Photos) {
            const maxOrder = photos.length > 0
              ? Math.max(...photos.map(o => o.order))
              : 0;
            photoItem.order = maxOrder + 1;
            photoItem.photoID = "NAP" + photoItem.order;
          }
          photos.push(photoItem);

          try {
            TryToUploadDynamicPhotoUpload(photoItem);
          } catch (error) {
            console.error("Error uploading photo:", error);
          }
          setState((prev) => ({
            ...prev, Photos: photos, photo_expanded: true
          }));

        }
      };
      reader.readAsDataURL(file);
    });
  };
  const TryToUploadDynamicPhotoUpload = async (photoItem) => {
    var data = new FormData();
    data.append("fileName", photoItem.fileName);
    data.append("fileBase64String", photoItem.dataUrl);
    const formDataObj = Object.fromEntries(data.entries());
    apiAgent.Property.SavePropertyPhotoSpeedUpAjax(formDataObj)
      .then((response) => {
        var photos = state.Photos;
        for (const [index, item] of Array.from(photos).entries()) {
          if (item.photoID == photoItem.photoID) {
            const updatePhotos = {
              ...item,
              photoID2: item.photoID,
              photoID: response.photoID,
              fileName: response.fileName,
              aiFileds: "speedup"
            }
            photos[index] = updatePhotos;
            break;
          }
        }
        setState((prev) => ({
          ...prev,
          Photos: photos
        }));
        setSaveUploadCount((prev) => prev + 1);
      })
      .catch((error) => {
        setSaveUploadCount((prev) => prev + 1);
        toast.info("Fail");
      });
  };

  useEffect(() => {
    const processFiles = async () => {

      const files = state.PhotoUpload;

      const filesLength = files ? files.length : 0;
      setCurrentFilesLength(filesLength);
      if (files && filesLength > 0) {
        for (const fileItem of files) {
          const file = fileItem;
          const fileSize = file.item.size / 1024; // in Kb
          let quality = 1;
          if (fileSize <= 500) quality = 1;
          else if (fileSize > 500 && fileSize <= 850) quality = 0.85;
          else if (fileSize > 850 && fileSize <= 1000) quality = 0.8;
          else if (fileSize > 1000 && fileSize <= 1500) quality = 0.75;
          else if (fileSize > 1500 && fileSize <= 2000) quality = 0.7;
          else if (fileSize > 2000 && fileSize <= 2750) quality = 0.65;
          else if (fileSize > 2750 && fileSize <= 3250) quality = 0.6;
          else if (fileSize > 3250 && fileSize <= 4250) quality = 0.55;
          else if (fileSize > 4250 && fileSize <= 5850) quality = 0.5;
          else if (fileSize > 5850 && fileSize <= 6500) quality = 0.45;
          else if (fileSize > 6500 && fileSize <= 9800) quality = 0.4;
          else quality = 0.38;

          if (quality === 1) {
            await onUploadFileReaderLoaded2(file, file.order);  // Awaiting the file processing
          } else {
            new Compressor(file.item, {
              quality: quality,
              maxWidth: 1920,
              maxHeight: 1080,
              convertSize: 2500000,
              success: async (result) => {
                await onUploadFileReaderLoaded2(result, file.order);  // Awaiting the compressed file processing
              },
              error: async (err) => {
                console.error(err);
                await onUploadFileReaderLoaded2(file, file.order);  // Fallback to original file if error
              },
            });
          }
        }
      } else {
        window.$("#uploadPhoto").val("");
      }


    };
    processFiles();  // Execute the async file processing function
  }, [state.PhotoUpload]);

  const handleZoomClick = (action) => {
    switch (action) {
      case -1:
        setState((prev) => ({ ...prev, ViewZoom: prev.ViewZoom - 10 }))
        break;
      case 1:
        setState((prev) => ({ ...prev, ViewZoom: prev.ViewZoom + 10 }))
        break;
      default:
        break;
    }
  }
  const handleChange = (e, action) => {
    switch (action) {
      case "description":
        setImageDescription((prev) => ({ ...prev, photoDescription: e.target.value }));
        break;
      default:
        break;
    }

  }
  const onSaveDescriptionClick = () => {
    setState(prevState => ({
      ...prevState,
      Photos: prevState.Photos.map(photo =>
        photo.photoID === imageDescription.photoId
          ? { ...photo, description: imageDescription.photoDescription }
          : photo
      ),
    }));
    window.$("#PhotoUploadModal").modal("hide");
  }

  const rotateImage = (photoID, rotateDegrees) => {
    setState((prevState => {
      const updatedPhotos = prevState.Photos.map(photo => {
        if (photo.photoID === photoID) {
          const newRotate = (photo.rotate + rotateDegrees) % 360;
          return { ...photo, rotate: newRotate }
        }
        return photo;

      });
      return {
        ...prevState,
        Photos: updatedPhotos
      }
    }))

  }
  const onEditImageClick = (photoItem) => {
    if (photoItem != null) {
      setImageDescription({ photoId: photoItem.photoID, photoDescription: photoItem.description });
      window.$("#PhotoUploadModal").modal("show");
    }
  }
  const onViewImageClick = (photoItem) => {
    if (photoItem) {
      const modifiedImageUrl = photoItem?.dataUrl?.replace('/t_', '/f_');
      setViewImage((prevState) => ({
        ...prevState,
        photoId: photoItem.photoId,
        dataUrl: modifiedImageUrl,
        rotate: photoItem.rotate
      }));
      window.$("#ImageModal1").modal("show");
    }
  }
  const openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    setConfirmationModalProps({ confirmationModalProps: varConfirmationModalProps });
  };
  const onDeletePhoto = (photoId) => {
    setState((prevState) => {
      const updatedPhotos = prevState.Photos
        .filter(photo => photo.photoID !== photoId);
      doSort(updatedPhotos);
      openCloseDeleteModal(false);
      return {
        ...prevState,
        Photos: updatedPhotos,
      };
    });
  };
  const onDeleteImageClick = (photoItem) => {
    if (photoItem) {
      let title = "Delete photo?";
      let body = "Do you want to delete photo now? This cannot be undone.";
      let varConfirmationModalProps = {
        title,
        body,
        showModal: true,
        isConfirmed: (isConfirmed) => {
          if (isConfirmed) {
            onDeletePhoto(photoItem.photoID);
          }
          openCloseDeleteModal(false);
        },
      };
      setConfirmationModalProps(varConfirmationModalProps);
    }
  }
  const onHandleAction = (action, photoItem) => {
    switch (action) {

      case "edit":
        onEditImageClick(photoItem);
        break;
      case "view":
        onViewImageClick(photoItem);
        break;
      case "delete":
        onDeleteImageClick(photoItem);
        break;
      case "rotate-right":
        rotateImage(photoItem.photoID, 90);
        break;
      case "rotate-left":
        rotateImage(photoItem.photoID, -90);
        break;
    }
  }
  const array_move = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };
  const onSortEnd = (oldIndex, newIndex) => {
    let items = Array.from(state?.Photos);
    items = array_move(items, oldIndex, newIndex);
    items.forEach((item, index) => {
      item.order = index + 1;
    });
    setState((prev) => ({
      ...prev,
      Photos: items
    }));
  };

  const getDimensions = (viewZoom) => {
    const sizes = {
      100: { height: "180px", width: "270px" },
      90: { height: "162px", width: "243px" },
      80: { height: "144px", width: "216px" },
      70: { height: "126px", width: "189px" },
      60: { height: "108px", width: "162px" },
      50: { height: "90px", width: "135px" },
      40: { height: "72px", width: "108px" },
      30: { height: "54px", width: "81px" },
    };

    return sizes[viewZoom] || { height: "auto", width: "auto" }; // Fallback to auto for undefined values
  };

  const getCaptionStyle = (viewZoom) => {
    switch (viewZoom) {
      case 100:
        return { display: "block" }
      default:
        return { display: "none" }
    }
  };
  // Function to handle screen resizing and reinitialize the sortable list
  const handleResize = () => {
    // Force a re-render by changing the resizeTrigger state
    setResizeTrigger(prev => prev + 1);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <ConfirmationModalPopup {...confirmationModalProps} />
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group">
            <span>Media files are made available on websites.</span>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group pull-right">
            <button
              style={{
                cursor: "pointer",
                marginRight: 10,
                marginTop: 5
              }}
              className="btn btn-success-oms"
              onClick={handleAddFilesClick}
            >
              Add files
            </button>
            <button
              style={{
                cursor: "pointer",
                marginRight: 10,
                marginTop: 5
              }}
              className="btn btn-success-oms"
              onClick={(e) => handleAddExternalLinkClick(e)}
            >
              Add external links
            </button>
            <button
              style={{
                cursor: "pointer",
                marginTop: 5
              }}
              className="btn btn-success-oms"
              onClick={handleAddPhotosClick}>
              Add photos
            </button>
          </div>
        </div>
      </div>
      <div className="row">

        <MediaFileComponent
          ref={fileComponentRef}
          data={data.files}
          propertyID={data.propertyID}
          onChange={onFileComponentChange}
          officeSyncID={officeSyncID}
        />



        <MediaLinkComponent
          ref={linkComponentRef}
          data={data.links}
          onChange={onLinkComponentChange} />



        <div className="panel panel-default">
          <div className="panel-heading"
            style={{
              cursor: "pointer"
            }}
            onClick={onChangeExpand}>
            <div className="row">
              <div className="col-md-12">
                <a
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    paddingTop: 5
                  }}>
                  <i
                    className={state.photo_expanded ? "fa fa-chevron-up" : "fa fa-chevron-down"}>

                  </i>
                </a>
                <span
                  style={{
                    marginLeft: 10,
                    fontWeight: "bold"
                  }}>Photos ({state.Photos ? state.Photos.length : 0})
                </span>
              </div>
            </div>
          </div>
          <div className="panel-body">
            <input
              type="file"
              id="uploadPhoto"
              name="uploadPhoto"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleUploadPhotoChange(e)}
              multiple={true}
            />
            <div style={{
              display: state.photo_expanded ? "" : "none"
            }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="pull-right"
                    style={{
                      marginBottom: 10
                    }}>
                    {state.ViewZoom == 30 ? (
                      <span style={{ color: "grey" }}>
                        <i className="fa fa-minus-circle"></i>
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer", color: "#337ab7" }}
                        onClick={() => handleZoomClick(-1)}>
                        <i className="fa fa-minus-circle"></i>
                      </span>
                    )}
                    <span
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        display: "inline-block"
                      }}>
                      <b>{state.ViewZoom}%</b>
                    </span>
                    {state.ViewZoom == 100 ? (
                      <span style={{ color: "grey" }}>
                        <i className="fa fa-plus-circle"></i>
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer", color: "#337ab7" }}
                        onClick={() => handleZoomClick(1)}>
                        <i className="fa fa-plus-circle"></i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <SortableList
                    key={resizeTrigger} // Reset the sortable list when `resizeTrigger` changes
                    onSortEnd={onSortEnd}
                    className="list"
                  >
                    {state?.Photos?.map((item) => (
                      <SortableItem key={item.photoID}>
                        <div
                          className="dragging-items"
                        >

                          <div id={item.photoID} className={`media-img-box media-img-box-${state.ViewZoom}`} >
                            <a className="img-thumbnail img-thumbnail-photo" style={{ overflow: "hidden" }}>
                              <img
                                id={`img${item.photoID}`}
                                className={`img-view rotate${item.rotate}`}
                                data-id={`${item.photoID}`}
                                onClick={(e) => onHandleAction("view", item)}
                                data-degrees="0"
                                style={{
                                  ...getDimensions(state.ViewZoom),
                                  objectFit: "cover"
                                }}
                                draggable="false"
                                src={`${item.dataUrl}`}
                                alt=""

                              />
                              <div className="caption" style={{ ...getCaptionStyle(state.ViewZoom) }}>
                                <label id={`desc${item.photoID}`} className="photo-description">
                                  {super_access && <span id={`spanOrder${item.photoID}`}>{item.order + ")"} </span>}
                                  {item.description === "" ? "no description added" : item.description}
                                </label>
                                <div className="photo-action pull-right">
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "5px"
                                    }}
                                  >
                                    <i
                                      onClick={(e) => onHandleAction("rotate-right", item)}
                                      className="fa fa-rotate-right"
                                      data-for="rotate"
                                      data-clockwise="false"
                                      data-id={item.photoID}
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "5px"
                                    }}
                                  >
                                    <i
                                      onClick={(e) => onHandleAction("rotate-left", item)}
                                      className="fa fa-rotate-left"
                                      data-for="rotate"
                                      data-clockwise="true"
                                      data-id={item.photoID}
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "5px"
                                    }}
                                  >
                                    <i
                                      onClick={() => onHandleAction("edit", item)}
                                      className="fa fa-edit"
                                      data-for="edit"
                                      data-id={item.photoID}
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "5px"
                                    }}
                                  >
                                    <i
                                      onClick={() => onHandleAction("delete", item)}
                                      className="fa fa-close"
                                      data-for="delete"
                                      data-id={item.photoID}
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </SortableItem>
                    ))}
                  </SortableList>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="PhotoUploadModal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="PhotoUploadModalLabel">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
                <h4 className="modal-title" id="PhotoUploadModalLabel">
                  Photo description
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="m_Description">Description</label>
                    <textarea
                      rows={2}
                      className="form-control"
                      id="m_Description"
                      name="m_Description"
                      value={imageDescription.photoDescription}
                      onChange={(e) => handleChange(e, "description")}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <OMSButtonStyleL
                  type="button"
                  style={{ marginRight: 10 }}
                  ui_icon="fa-check"
                  ui_type="save"
                  ui_text="Save"
                  ui_processing_text="Saving.."
                  processing={false}
                  disabled={false}
                  onClick={onSaveDescriptionClick}
                />
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="ImageModal1" className="modal fade" tabIndex={-1} role="dialog">
          <div className="modal-dialog" style={{ width: "80%", maxWidth: "860px", maxHeight: "660px" }}>
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">&times;</span>
                  <span className="sr-only">Close</span>
                </button>
                <img
                  id={`imgPre${viewImage.photoId}`}
                  class={`img-preview-view rotate${viewImage.rotate}`}
                  data-id={`${viewImage.photoId}`}
                  data-degrees="0"
                  style={{ width: "100%" }}
                  src={`${viewImage.dataUrl}`
                  }
                  alt=""
                />

              </div>
            </div>
          </div>
        </div>
        <div id="ImageModal2" className="modal fade" tabIndex={-1} role="dialog" aria-hidden="true">
          <div id="ImageModal2Dialog" className="modal-dialog" style={{ width: "80%", height: "80%", maxWidth: "550px", maxHeight: "550px" }}>
            <div className="modal-content">
              <div id="ImageModal2Body" className="modal-body">
                <button type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">&times;</span>
                  <span className="sr-only">Close</span>
                </button>
                <img src="" id="imgPre2" className="imgPre2" style={{ width: "100%", height: "100%", maxWidth: "512px", maxHeight: "512px" }} />
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
});
