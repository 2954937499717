import React, { useEffect, useState } from "react";
import { LoginModel } from "../../app/models/LoginModel";

import BrowserInfoModal from "./BrowserInfoModal";
import LoginBox from "./LoginBox";
import { Redirect, useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react";
import AuthFooter from "./AuthFooter";
import ResetBox from "./ResetBox";
import logo from "../../assets/images/base-login.png";
import ResetTwoFABox from "./ResetTwoFABox";

interface ParamTypes {
  token: string;
}

export default observer(function ResetTwoFAPage() {

  const { token } = useParams<ParamTypes>();
 



  document.title = "Base - Reset Two Factor Authentication";

  return (
    <>
      <div className="login container">
        <div className="row oms-login-box">
          <div className="col-lg-4 col-md-3 col-sm-2"></div>
          <div className="col-lg-4 col-md-6 col-sm-8">
            <div className="logo">
            <img src={logo} alt="Base" />
            </div>
            <ResetTwoFABox token={token}></ResetTwoFABox>
            <br />
            <br />
            <AuthFooter />
          </div>
          <div className="col-lg-4 col-md-3 col-sm-2"></div>
        </div>
      </div>
    </>
  );
});
