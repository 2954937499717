import React from "react";
import { toast } from "react-toastify";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import apiAgent from "../../../app/api/apiAgent";
import { ImportOverviewStepView } from "./SavePropertyTabs/ImportOverviewStepView";
import { ImportLocationStepView } from "./SavePropertyTabs/ImportLocationStepView";
import { ImportFeaturesStepView } from "./SavePropertyTabs/ImportFeaturesStepView";
import { ImportDescriptionStepView } from "./SavePropertyTabs/ImportDescriptionStepView";
import { ImportMandateStepView } from "./SavePropertyTabs/ImportMandateStepView";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from 'react-router-dom';

class ImportPropertyBox extends React.Component {
    state = {
        PropertyID: this.props.data.propertyID,
        ImportID: this.props.data.importID,
        IsUpdate: true,
        Step: this.props.data.step,
        CompletedStep: this.props.data.step,
        Photos: this.props.data.media.photos,
        json_data: '',
        message: '',
        ButtonStatus: {
            disabled: false,
            processing_save: false,
            processing_delete: false
        },
        ConfirmationModalProps: {
            showModal: false,
            isConfirmed: (result) => {},
            title: "",
            body: "",
          },
    };

    constructor(props) {
        super(props);
        
        this.locationStepRef = React.createRef();
        this.featuresStepRef = React.createRef();
        this.descriptionStepRef = React.createRef();
        this.mandateStepRef = React.createRef();
        //this.mediaStepRef = React.createRef();
        //this.contactsStepRef = React.createRef();
        //this.marketingStepRef = React.createRef();
    };

    componentDidMount() {
        this.setState({ json_data: this.syntaxHighlightJson(JSON.parse(this.props.data.remainingJson)) });
    }

    syntaxHighlightJson(obj) {
        var json = JSON.stringify(obj, null, 2);
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                    if (match.indexOf('#') > -1) {
                        if (match[2] == "0")
                            cls = cls + " item_added";
                        else if (match[2] == "1")
                            cls = cls + " item_updated";
                        else if (match[2] == "2")
                            cls = cls + " item_deleted";
                        match = '"' + match.substr(3, match.length - 3);
                    }
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }

    openCloseDeleteModal = (isOpen) => {
        let varConfirmationModalProps = this.state.ConfirmationModalProps;
        varConfirmationModalProps.showModal = isOpen;
        this.setState({ ConfirmationModalProps: varConfirmationModalProps });
      };
    
      showPopupAndDeleteOnConfirmation = (title, body, callback) => {
        let varConfirmationModalProps = {
          title,
          body,
          showModal: true,
          isConfirmed: (isConfirmed) => {
            if (isConfirmed) {
              //console.log("confirmed");
              callback();
            }
            this.openCloseDeleteModal(false);
          },
        };
        //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
        this.setState({ ConfirmationModalProps: varConfirmationModalProps });
      };    

    handleSubmit = (e) => {
        e.preventDefault();
    };

    onCancelClick = (e) => {
        this.props.history.push(`/Import/Properties?ImportID=${this.props.data.importID}`);
    };

    getFormData = (e) => {
        var data = new FormData();

        var Step = this.state.Step;

        if (Step < this.state.CompletedStep)
            Step = this.state.CompletedStep;

        if (this.state.IsUpdate)
            Step = 8;

        data.append('PropertyID', this.state.PropertyID);
        data.append('ImportID', this.state.ImportID);
        data.append('Step', Step);

        this.locationStepRef.current.doSetFormData(data);
        this.featuresStepRef.current.doSetFormData(data);
        this.descriptionStepRef.current.doSetFormData(data);
        this.mandateStepRef.current.doSetFormData(data);
        //console.log("inside Import properties box, getformdata",data);

        return data;
    };

    doSave = () => {
        var validate = this.doValidateStep(this.state.Step);
        if (validate.status == 'success') {
            this.setState({ ButtonStatus: { disabled: true, processing_save: true, processing_delete: false } });
            var data = this.getFormData();
            let formData = Object.fromEntries(data.entries());
            //console.log("inside Import properties box, dosave",formData);
            /* var xhr = new XMLHttpRequest();
            xhr.open('post', '/Import/SavePropertyAjax', true);
            xhr.onload = function () {
                var result = JSON.parse(xhr.responseText);
                if (result.status == "success") {
                    this.onCancelClick();
                }
                else {
                    $.toaster(result.message, 'Fail', 'danger');
                    this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
                }
            }.bind(this);
            xhr.send(data); */
            apiAgent.Import.savePropertyAjax(data)
            .then((response) => {
                this.onCancelClick();
            })
            .catch((error) => {
                toast.error(error.message, 'Fail', 'danger');
                this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
            });
        }
        else
            toast.error(validate.message, 'Fail', 'danger');
    };

    doChangeStepClick = (Step) => {
        var CurrentStep = this.state.Step;
        var validate = this.doValidateStep(CurrentStep);
        if (validate.status == 'success')
            this.doChangeStepClick2(Step);
        else {
            toast.error(validate.message, 'Fail', 'danger');
        }
    };

    doChangeStepClick2 = (Step) => {
        var CurrentStep = this.state.Step;
        var CompletedStep = this.state.CompletedStep;
        if (CurrentStep > CompletedStep)
            CompletedStep = CurrentStep;
        this.setState({ Step: Step, CompletedStep: CompletedStep });

    };

    doValidateStep = (Step) => {
        if (Step == 1) {
            return this.locationStepRef.current.doCheckFormData();
        }
        else if (Step == 2) {
            return this.featuresStepRef.current.doCheckFormData();
        }
        else if (Step == 3) {
            return this.descriptionStepRef.current.doCheckFormData();
        }
        else if (Step == 5) {
            return this.mandateStepRef.current.doCheckFormData();
        }
        else {
            return { status: 'success', message: '' };
        }
    };

    renderStepName = () => {
        if (this.state.IsUpdate)
            return this.props.data.displayName;
        else {
            if (this.state.Step == 1)
                return "Listing capture: Location";
            else if (this.state.Step == 2)
                return "Listing capture: Features";
            else if (this.state.Step == 3)
                return "Listing capture: Description";
            else if (this.state.Step == 4)
                return "Listing capture: Media";
            else if (this.state.Step == 5)
                return "Listing capture: Mandate";
            else if (this.state.Step == 6)
                return "Listing capture: Contacts";
            else if (this.state.Step == 7)
                return "Listing capture: Marketing";
            else
                return "Listing capture";
        }
    };

    executeDeleteClick = (e) => {
        this.setState({ ButtonStatus: { disabled: true, processing_save: false, processing_delete: true } });
        /* var data = new FormData();
        data.append('ID', this.state.PropertyID);
        data.append('ImportID', this.state.ImportID);
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Import/DeletePropertyAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                location.href = "/Import/Properties?ID=" + this.state.ImportID;
            }
            else {
                this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
                $.toaster(data.message, 'Fail', 'danger');
            }
        }.bind(this);
        xhr.send(data); */
        apiAgent.Import.deletePropertyAjax(this.state.PropertyID, this.state.ImportID)
            .then((response) => {
                this.props.history.push(`/import/properties?ID=${this.state.ImportID}`);
            })
            .catch((error) => {
                this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
                toast.error(error.message, 'Fail', 'danger');
            });
    };

    onDeleteClick = (e) => {
        /* bootbox.confirm({
            title: "Delete property?",
            message: "Do you want to delete property now? This cannot be undone.",
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Cancel'
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Confirm'
                }
            },
            callback: function (result) {
                if (result) {
                    this.executeDeleteClick();
                }
            }.bind(this)
        }); */
        let title = "Delete property?";
        let body = "Do you want to delete property now? This cannot be undone.";
        let callback = this.executeDeleteClick;
        this.showPopupAndDeleteOnConfirmation(title, body, callback);
    };

    render() {
        return (
            <div>
            <ConfirmationModalPopup {...this.state.ConfirmationModalProps} />
            <form className="SavePropertyForm" onSubmit={this.handleSubmit}>
                <div className="col-sm-12">
                    <div className={this.state.IsUpdate ? "panel panel-default oms-box color2 color2-border-right" : "panel panel-default oms-box"}>
                        <div className="wrap">
                            <div className="left">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.renderStepName()}
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-body" style={{ backgroundColor: 'white', minHeight: 600 }}>
                                    <div className="col-md-12">
                                        <div id="overview" style={{ display: (this.state.Step == 0 ? '' : 'none') }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {(this.props.data.errors.length == 0) ? null :
                                                        <div className="form-group">
                                                            <label>Errors</label>
                                                            <pre>
                                                                {this.props.data.errors.map((item, index) => {
                                                                    return (
                                                                        <span style={{ color: 'red' }} key={index}>
                                                                            {item}  <br />
                                                                        </span>
                                                                    )
                                                                })}
                                                            </pre>
                                                        </div>
                                                    }
                                                    {(this.props.data.warnings.length == 0) ? null :
                                                        <div className="form-group">
                                                            <label>Warnings</label>
                                                            <pre>
                                                                {this.props.data.warnings.map((item, index) => {
                                                                    return (
                                                                        <span style={{ color: 'blue' }} key={index}>
                                                                            {item} <br />
                                                                        </span>

                                                                    )
                                                                })}
                                                            </pre>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <ImportOverviewStepView data={this.props.data} />
                                        </div>
                                        <div id="location" style={{ display: (this.state.Step == 1 ? '' : 'none') }}>
                                            <ImportLocationStepView officeDD={this.props.officeDD} ref={this.locationStepRef} id="locationStep" data={this.props.data.location} loadMap={this.state.Step == 1} />
                                        </div>
                                        <div id="features" style={{ display: (this.state.Step == 2 ? '' : 'none') }}>
                                            <ImportFeaturesStepView ref={this.featuresStepRef} id="featuresStep" data={this.props.data.features} OwnershipTypes={this.props.OwnershipTypes} PropertyTypes={this.props.PropertyTypes} />
                                        </div>
                                        <div id="description" style={{ display: (this.state.Step == 3 ? '' : 'none') }}>
                                            <ImportDescriptionStepView ref={this.descriptionStepRef} id="descriptionStep" data={this.props.data.description} />
                                        </div>
                                        <div id="mandate" style={{ display: (this.state.Step == 5 ? '' : 'none') }}>
                                            <ImportMandateStepView ref={this.mandateStepRef} id="mandateStep" data={this.props.data.mandate} MandateTypes={this.props.MandateTypes} />
                                        </div>
                                        <div id="photos" style={{ display: (this.state.Step == 4 ? '' : 'none') }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div id="js-grid" className="js-grid">
                                                        {this.state.Photos.map((item, index) => {
                                                            return (
                                                                <div key={index} className="media-img-box">
                                                                    <a className="img-thumbnail">
                                                                        <img src={item} alt="" />
                                                                    </a>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div id="remaining" style={{ display: (this.state.Step == 8 ? '' : 'none') }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div>
                                                        <pre dangerouslySetInnerHTML={{ __html: this.state.json_data }}></pre>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="contacts" style={{ display: (this.state.Step == 6 ? '' : 'none') }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {(this.props.data.contact.agentItems.length == 0) ? null :
                                                        <div className="form-group">
                                                            <label>Agents</label>
                                                            <br />
                                                            <br />
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>type</th>
                                                                        <th>name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.props.data.contact.agentItems.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td style={{ color: 'blue' }}>listing agent</td>
                                                                                <td>{item}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                    {(this.props.data.contact.contactItems.length == 0) ? null :
                                                        <div className="form-group">
                                                            <label>Contacts</label>
                                                            <br />
                                                            <br />
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>type</th>
                                                                        <th>name</th>
                                                                        <th>cell</th>
                                                                        <th>email</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.props.data.contact.contactItems.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td style={{ color: 'blue' }}>{item.ContactType}</td>
                                                                                <td>{item.Title + ' ' + item.FirstName + ' ' + item.LastName}</td>
                                                                                <td>{item.Cell == "" ? null : item.Cell} {item.HomeTel == "" ? null : item.HomeTel} {item.WorkTel == "" ? null : item.WorkTel}</td>
                                                                                <td>{item.Email}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <ImportOverviewStepView data={this.props.data} />
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="pull-left">
                                                {this.props.data.importStatus < 0 ? null : <OMSButtonStyleL type="button" ui_icon="fa-trash-o" ui_type="delete" ui_text="Delete property" ui_processing_text="Deleting.." processing={this.state.ButtonStatus.processing_delete} disabled={this.state.ButtonStatus.disabled} onClick={this.onDeleteClick} />}
                                            </div>
                                            <div className="pull-right">
                                                <OMSButtonStyleL style={{ marginRight: 10 }} type="button" ui_icon="fa-times" ui_type="cancel" ui_text="Cancel" ui_processing_text="" processing={false} disabled={this.state.ButtonStatus.disabled} onClick={this.onCancelClick} />
                                                {this.props.data.importStatus < 0 ? null : <OMSButtonStyleL style={{ marginRight: 10 }} type="button" ui_icon="fa-check" ui_type="save" ui_text="Save" ui_processing_text="Saving.." processing={this.state.ButtonStatus.processing_save} disabled={this.state.ButtonStatus.disabled} onClick={() => this.doSave()} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right color2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <a className={this.state.Step == 0 ? "btn text-center property-menu-selected" : "btn text-center property-menu"} onClick={() => this.doChangeStepClick(0)}>
                                            <span><i className="fa fa-tachometer fa-2x"></i><br />Overview</span>
                                        </a>
                                        <a className={this.state.Step == 1 ? "btn text-center property-menu-selected" : "btn text-center property-menu"} onClick={() => this.doChangeStepClick(1)}>
                                            <span><i className="fa fa-map-marker fa-2x"></i><br />Location</span>
                                        </a>
                                        <a className={this.state.Step == 2 ? "btn text-center property-menu-selected" : "btn text-center property-menu"} onClick={() => this.doChangeStepClick(2)}>
                                            <span><i className="fa fa-bed fa-2x"></i><br />Features</span>
                                        </a>
                                        <a className={this.state.Step == 3 ? "btn text-center property-menu-selected" : "btn text-center property-menu"} onClick={() => this.doChangeStepClick(3)}>
                                            <span><i className="fa fa-file-text-o fa-2x"></i><br />Desc</span>
                                        </a>
                                        <a className={this.state.Step == 5 ? "btn text-center property-menu-selected" : "btn text-center property-menu"} onClick={() => this.doChangeStepClick(5)}>
                                            <span><i className="fa fa-key fa-2x"></i><br />Mandate</span>
                                        </a>
                                        <a className={this.state.Step == 6 ? "btn text-center property-menu-selected" : "btn text-center property-menu"} onClick={() => this.doChangeStepClick(6)}>
                                            <span><i className="fa fa-address-book fa-2x"></i><br />Contact</span>
                                        </a>
                                        <a className={this.state.Step == 4 ? "btn text-center property-menu-selected" : "btn text-center property-menu"} onClick={() => this.doChangeStepClick(4)}>
                                            <span><i className="fa fa-camera fa-2x"></i><br />Photos</span>
                                        </a>
                                        <a className={this.state.Step == 8 ? "btn text-center property-menu-selected" : "btn text-center property-menu"} onClick={() => this.doChangeStepClick(8)}>
                                            <span><i className="fa fa-file-excel-o fa-2x"></i><br />Info</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </div>            
        );
    }
}

export default withRouter(ImportPropertyBox);