import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import apiAgent from "../../api/apiAgent";

export class OMSFavourite extends React.Component {
  static propTypes = {
    favourite: PropTypes.object.isRequired,
  };

  state = {
    IsLoading: false,
    ID: this.props.favourite.id,
    Module: this.props.favourite.module,
    DataRef: this.props.favourite.dataRef,
  };

  onSetClick = (e) => {
    this.setState({ IsLoading: true });

    //console.log(this.props.favourite);
    apiAgent.Home.setFavouriteAjax(this.props.favourite)
      .then((response) => {
        toast.info("Added to favourites");
        window.setTimeout(function () {}, 2000);
        //console.log("response", response);
        this.setState({ ID: response.id });
      })
      .catch((error) => {
        toast.error("Added to favourites");
      })
      .finally(() => {
        this.setState({ IsLoading: false });
      });
  };

  onResetClick = (e) => {
    this.setState({ IsLoading: true });

    apiAgent.Home.resetFavouriteAjax(this.props.favourite)
      .then((response) => {
        toast.info("Removed from favourites");
        window.setTimeout(function () {}, 2000);
        this.setState({ ID: "" });
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        this.setState({ IsLoading: false });
      });
  };

  render() {
    return this.state.IsLoading ? (
      <span className="fa fa-spin fa-spinner"></span>
    ) : this.state.ID != "#" ? (
      <span
        className="fa fa-star-o"
        onClick={this.onSetClick}
        style={{ cursor: "pointer" }}
        data-placement="bottom"
        data-toggle="tooltip"
        data-original-title="Add to favourites"
      ></span>
    ) : (
      <span
        className="fa fa-star"
        onClick={this.onResetClick}
        style={{ cursor: "pointer" }}
        data-placement="bottom"
        data-toggle="tooltip"
        data-original-title="Remove from favourites"
      ></span>
    );
  }
}
