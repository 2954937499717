import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { OMSDropdown } from "../../../../app/common/common-components/OMSDropdown";
import {
  formatDate,
  isEntegralSuperUser,
  ToPriceString,
} from "../../../../app/common/common-functions/functions";
import Stepper from "react-stepper-horizontal";
import { useStore } from "../../../../app/stores/store";

const DealOverviewStepView = forwardRef((props, ref) => {
  const [taskCounts, setTaskCounts] = useState({
    Completed: 0,
    InProgress: 0,
    NotStarted: 0,
    Issue: 0,
    Overdue: 0,
  });
  const {
    userStore: { userSession, logout, isLoggedIn },
  } = useStore();
  const [isModifyParam, setIsModifyParam] = useState(false);


  const getGrossCommission = () => {
    return (
      props.newDealData?.finance?.nettCommissionAmount +
      (props.newDealData?.finance?.isAddVAT
        ? props.newDealData?.finance?.nettCommissionAmount * 0.15
        : 0)
    );
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let Modify = "";
    if (params.has("isModify") && params.get("ID") == "") {
      const modify = params.get("isModify");
      Modify = modify !== null ? modify : "";
      setIsModifyParam(String(Modify).toLowerCase() === "true");
    }
  }, [window.location.search]);

  useEffect(() => {
    var mCompleted = 0,
      mInProgress = 0,
      mNotStarted = 0,
      mIssue = 0,
      mOverdue = 0;
    props.data?.forEach((element) => {
      if (element.stageTasks && element.stageTasks.length > 0)
        element.stageTasks?.forEach((ele) => {
          if (ele.isOverdue) {
            mOverdue++;
          }

          if (ele.status <= 1) {
            mNotStarted++; //Not Started
          } else if (ele.status == 2) {
            mInProgress++; //In Progress
          } else if (ele.status == 3) {
            mIssue++; //Issue
          } else if (ele.status == 4) {
            mCompleted++; //Completed
          }
        });
    });

    setTaskCounts({
      Completed: mCompleted,
      InProgress: mInProgress,
      NotStarted: mNotStarted,
      Issue: mIssue,
      Overdue: mOverdue,
    });

  }, [props]);



  return (
    <>
      {props?.newDealData?.dealID && !isModifyParam ? (
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="media">
              <div className="media-left">
                <span>
                  <i className="fa fa-home"></i>
                  <br />
                  Listing
                </span>
              </div>
              <div className="media-body">
                <div className="" style={{ marginTop: 10, marginLeft: 10 }}>
                  <div
                    className="color-oms"
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    onClick={() => props.goToProperty()}
                  >{`#${props.newDealData?.dealRef}`}</div>
                  <div className="ellipsis-div">{`${props.newDealData?.propertyAddress}`}</div>
                </div>

              </div>
            </div>
            <div className="media">
              <div className="media-left">
                <span>
                  <i className="fa fa-bullseye"></i>
                  <br />
                  Milestone
                </span>
              </div>
              <div className="media-body">
                <div
                  className="ellipsis-container"
                  style={{ marginTop: 10, marginLeft: 0 }}
                >
                  <div class="scroll-container">
                    <div>
                      <Stepper
                        steps={
                          props.newDealData?.stages?.length != 0
                            ? props.newDealData?.stages
                              ?.filter((curStage) => {
                                return curStage.stageName != "No Milestone";
                              })
                              .map((curStage) => {
                                return {
                                  title: (
                                    <div>
                                      <div>{curStage.stageName}</div>
                                      <div>
                                        {formatDate(curStage.stageExpected)}
                                      </div>
                                    </div>
                                  ),
                                };
                              })
                            : []
                        }
                        activeStep={
                          props.newDealData?.stages?.findIndex((curStage) => {
                            return (
                              curStage.stageName == props.newDealData?.dealStage
                            );
                          }) != -1
                            ? props.newDealData?.stages?.findIndex(
                              (curStage) => {
                                return (
                                  curStage.stageName ==
                                  props.newDealData?.dealStage
                                );
                              }
                            )
                            : 0
                        }
                        activeColor={"#92ab1c"}
                        completeColor={"#000"}
                        defaultColor={"#000"}
                        activeTitleColor={"#000"}
                        completeTitleColor={"#000"}
                        defaultTitleColor={"#000"}
                        size={20}
                        circleFontSize={0}
                        barStyle={"solid"}
                        defaultBorderStyle={"solid"}
                        completeBorderStyle={"solid"}
                        activeBorderStyle={"solid"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Time */}

            <div className="media">
              <div className="media-left">
                <span>
                  <i className="fa fa-clock-o"></i>
                  <br />
                  Tasks
                </span>
              </div>
              <div className="media-body">
                {props.newDealData?.dealNote !== "" && (
                  <div className="note-container">
                    <div className="task-note_data bg-note mt-3 ml-1">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props.newDealData?.dealNote,
                        }}
                      ></div>
                    </div>
                  </div>
                )}
                <div
                  className="vertical-ellipsis-container"
                  style={{ marginTop: 10, marginLeft: 10 }}
                >
                  <div className="d-flex ">
                    <span className="" style={{ textOverflow: "clip" }}>
                      <b style={{ whiteSpace: "nowrap", marginLeft: "4px" }}>
                        {taskCounts.Completed} Completed |{" "}
                      </b>
                    </span>
                    <span className="" style={{ textOverflow: "clip" }}>
                      <b style={{ whiteSpace: "nowrap", marginLeft: "4px" }}>
                        {taskCounts.InProgress} In Progress |{" "}
                      </b>
                    </span>
                    <span className="" style={{ textOverflow: "clip" }}>
                      <b style={{ whiteSpace: "nowrap", marginLeft: "4px" }}>
                        {taskCounts.NotStarted} Not Started |{" "}
                      </b>
                    </span>
                    <span className="" style={{ textOverflow: "clip" }}>
                      <b style={{ whiteSpace: "nowrap", marginLeft: "4px" }}>
                        {taskCounts.Issue} Issue
                      </b>
                    </span>
                  </div>
                  <div className="d-flex" style={{ marginTop: 10 }}>
                    <span className="" style={{ textOverflow: "clip" }}>
                      <i
                        className="fa fa-exclamation-triangle"
                        style={{
                          marginTop: 5,
                          marginRight: 8,
                          fontSize: 14,
                          color: "red",
                        }}
                        aria-hidden="true"
                      ></i>
                      <b style={{ whiteSpace: "nowrap", marginLeft: "2px" }}>
                        {taskCounts.Overdue == 0
                          ? "No tasks overdue"
                          : taskCounts.Overdue + " tasks overdue"}
                      </b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="media">
              <div className="media-left">
                <span>
                  <i className="fa fa-address-book"></i>
                  <br />
                  Contacts
                </span>
              </div>
              <div className="media-body">
                <div className="" style={{ marginTop: 10, marginLeft: 10 }}>
                  {props.contacts?.map((curContact, i) => {
                    return (
                      <div key={i} className="my-2">
                        <b>{`${curContact.contactType}:`}</b>
                        <span>{curContact.displayName}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="media">
              <div className="media-left">
                <span>
                  <i className="fa fa-pie-chart"></i>
                  <br />
                  Finance
                </span>
              </div>
              <div className="media-body">
                <div className="" style={{ marginTop: 10, marginLeft: 10 }}>
                  <div className="">
                    <b>{`${props.currency}${ToPriceString(
                      props.newDealData?.finance?.askingPrice
                    )}`}</b>
                  </div>
                  <div className="">
                    <b>
                      <h3 style={{ margin: "5px 0px" }}>{`${props.currency
                        }${getGrossCommission()} GC`}</h3>
                    </b>
                  </div>
                  <div className="split_overview_card_container">
                    {props.newDealData?.finance?.splits?.map((curSplit, i) => {
                      if (curSplit.userID == props.userSession.userId) {
                        return (
                          <div
                            key={i}
                            className={`split_overview_card my-1 mr-2`}
                          >
                            <span className="p-1">{curSplit.userName}</span>
                            <div className="btn btn-success-oms">{`${props.currency
                              }${ToPriceString(curSplit.amount)}(${curSplit.percentage
                              }%)`}</div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Enter a name for this deal</label>
              <input
                name="deal_name"
                type="text"
                className="form-control"
                value={props?.dealModelData.dealname}
                //disabled={props.newDealData?.dealID ? true : false}
                onChange={(e) => props?.inputHandler(e, "dealName")}
              />
            </div>
            <div className="form-group">
              <label>Start / Sale date</label>
              <input
                name="deal_date"
                type="date"
                className="form-control"
                value={props?.dealModelData.dealDate}
                // disabled={props?.newDealData?.dealID ? true : false}
                onChange={(e) => props?.inputHandler(e, "dealDate")}
              />
            </div>
            <div className="form-group">
              <label>Select a Pipeline</label>
              <OMSDropdown
                id="pipeline"
                options={props?.dealPipelineDD}
                valueField="value"
                labelField="text"
                value={props?.dealModelData.pipelineId}
                disabled={props?.newDealData?.dealID ? true : false}
                onChange={(e) => props?.inputHandler(e, "dealPipeline")}
              />
            </div>
            <div className="form-group">
              <label>Select a template (optional)</label>
              <OMSDropdown
                id="templete"
                options={props?.dealTEmpleteDD}
                valueField="templateId"
                labelField="template"
                disabled={props?.newDealData?.dealID ? true : false}
                value={props?.dealModelData.dealTempleteId}
                onChange={(e) => props?.inputHandler(e, "dealTemplete")}
              />
            </div>

            <div className="form-group">
              <label>Update listing status</label>
              <select
                id="mandateStatus"
                name="mandateStatus"
                className="form-control"
                disabled={props?.newDealData?.dealID ? true : false}
                value={props?.dealModelData.mandateStatus}
                onChange={(e) => props?.inputHandler(e, "mandateStatus")}
              >
                <option value="0">No update</option>
                <option value="1">Active</option>
                <option value="2">Expired</option>
                <option value="3">Withdrawn</option>
                <option value="4">Rented</option>
                <option value="5">Sold - Under Offer</option>
                <option value="6">Sold - Registered</option>
                <option value="7">Sold - Cancelled</option>
                <option value="8">Inactive</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </>
  );
})

export default DealOverviewStepView;