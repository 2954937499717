import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
const moment = require("moment");

export class OMSActivityDisplayItem extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    isUpcomingEvent: PropTypes.bool,
    onActionFunc: PropTypes.func,
    ReadOnly: PropTypes.bool,
    DNType: PropTypes.number,
    togglePinNote: PropTypes.func.isRequired,
  };

  state = { isDescExpanded: false };

  onTitleClick = (diaryID) => {
    //console.log("onTitleClick", diaryID);
    this.props.onActionFunc(diaryID, false);
  };

  getDescription = (description) => {
    if (this.state.isDescExpanded) {
      return description;
    } else {
      return description.length > 100 ? description.slice(0, 100) + "..." : description;
    }
  };

  pinUnpinNote = () => {
    var changedItem = { ...this.props.item, pinned: !this.props.item.pinned };
    this.props.togglePinNote(changedItem);
  };

  render() {
    const { startDate, diaryTitle, description, contactID, contactName, propertyID, propertyName, diaryID, pinned, typeID } = this.props.item;

    const mStartDate = moment(startDate, "DD-MM-YYYY hh:mm A");

    return (
      <div className="col-md-12 ol-sm-12 d-flex list-item-wrapper" style={{ position: "relative" }} key={diaryID}>
        <div className={`unpinned-note ${typeID.toLowerCase() === "note" ? "unpinned-hover" : "hide-pin-area"}`}>
          {typeID.toLowerCase() === "note" && <div className="pin-btn-tooltip">{pinned ? "Unpin" : "Pin"} Note</div>}
          {typeID.toLowerCase() === "note" && (
            <i
              className="fa fa-thumb-tack"
              aria-hidden="true"
              style={{
                fontSize: 14,
                transform: `rotate(${!pinned ? "90deg" : "0deg"})`,
                cursor: "pointer",
              }}
              onClick={() => this.pinUnpinNote()}
            />
          )}
        </div>
        <div className={`item-date ${this.props.isUpcomingEvent ? "upcoming-date" : ""}`}>{mStartDate.format("DD MMM YYYY")}</div>
        <div className="d-flex-column item-details">
          {!this.props.ReadOnly && this.props.onActionFunc ? (
            <a style={{cursor: "pointer", fontWeight: "bold"}} onClick={() => this.onTitleClick(diaryID)}>
              {diaryTitle}
            </a>
          ) : (
            <div style={{ fontWeight: "bold" }}>{diaryTitle}</div>
          )}
          <div className="description">
            <span>{this.getDescription(description)}</span>
            {description.length > 100 && (
              <a
                onClick={() =>
                  this.setState({
                    ...this.state,
                    isDescExpanded: !this.state.isDescExpanded,
                  })
                }
                style={{ cursor: "pointer" }}
              >
                <u>{this.state.isDescExpanded ? "Read Less" : "Read More"}</u>
              </a>
            )}
          </div>
        </div>
        <div className="item-footer">
          {this.props.DNType != 1 && contactID != "" && (
            <span className="description-name">
              {/* <a target="_blank" title={contactName} href={"/Contacts/SaveContact?ID=" + contactID}>
                <i className="fa fa-user" style={{ marginTop: 5, marginRight: 8, fontSize: 14 }} aria-hidden="true"></i>
                {contactName}
          </a> */}
          <Link to={`/Contacts/SaveContact?ID=${contactID}`} title={contactName} target="_blank">
            <i className="fa fa-user" style={{ marginTop: 5, marginRight: 8, fontSize: 14 }} aria-hidden="true"></i>
            {contactName}
          </Link>
            </span>
          )}
          {this.props.DNType != 2 && propertyID != "" && (
            <span className="description-name">
              {/* <a target="_blank" title={propertyName} href={"/Property/SaveProperty?ID=" + propertyID}>
                <i className="fa fa-home" style={{ marginTop: 5, marginRight: 8, fontSize: 14 }} aria-hidden="true"></i>
                {propertyName}
          </a> */}
          <Link to={`/Property/SaveProperty?ID=${propertyID}`} title={propertyName} target="_blank">
            <i className="fa fa-home" style={{ marginTop: 5, marginRight: 8, fontSize: 14 }} aria-hidden="true"></i>
            {propertyName}
          </Link>
            </span>
          )}
        </div>
      </div>
    );
  }
}
