import React, { useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { ContactModel } from "../../../app/models/ContactModel";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { ButtonStatus } from "../ContactBox";

interface FooterProps {
  contact: ContactModel;
  setIsShowAuditTrail: (isShowAuditTrail: boolean) => void;
  step: number;
  doChangeStepClick: (step: number) => void;
  doSave: (e: any) => void;
  onCancelClick: (e: any) => void;
  buttonStatus: ButtonStatus;
  setButtonStatus: (buttonStatus: ButtonStatus) => void;
  handleIsDraft: (isDraft: boolean) => void;
}

function SaveContactFooter(props: FooterProps) {
  const { contact, setIsShowAuditTrail, step, doChangeStepClick, doSave, onCancelClick,buttonStatus,setButtonStatus,handleIsDraft } = props;
  let contactID = contact!.contactID;
  let userID = contact!.userID;

  const history = useHistory();

  const [confirmationModalProps, setConfirmationModalProps] = useState({ showModal: false, isConfirmed: (result: boolean) => {}, title: "", body: "" });

  // const [buttonStatus, setButtonStatus] = useState({
  //   disabled: false,
  //   processing_save: false,
  //   processing_delete: false,
  // });

  const openCloseDeleteModal = (isOpen: boolean) => {
    let varConfirmationModalProps = confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    //this.setState({ confirmationModalProps: varConfirmationModalProps });
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const showPopupAndDeleteOnConfirmation = (title: string, body: string, callback: () => void) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed: boolean) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        openCloseDeleteModal(false);
      },
    };
    // console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    setConfirmationModalProps(varConfirmationModalProps);
  };

  const doSaveDraft = (isDraft: any) => {
    handleIsDraft(isDraft);
  }

  const handleAuditTrailShow = () => {
    setIsShowAuditTrail(true);
  };
  const onDeleteClick = (e: any) => {
    let title = "Delete contact?";
    let body = "Do you want to delete contact now? This cannot be undone.";
    let callback = executeDeleteClick;
    showPopupAndDeleteOnConfirmation(title, body, callback);
  };
  const executeDeleteClick = () => {
    setButtonStatus({
      disabled: true,
      processing_save: false,
      processing_delete: true,
    });
    apiAgent.Contact.deleteContactAjax(contactID, userID)
      .then((response: any) => {
        history.push("/contacts");
      })
      .catch((error: any) => toast.info("Fail"))
      .finally(() => {
        setButtonStatus({
          disabled: false,
          processing_save: false,
          processing_delete: false,
        });
      });
  };
  return (
    <div className="row">
      <ConfirmationModalPopup {...confirmationModalProps} />
      <div className="col-md-12">
        {contactID != "" ? (
          <div className="pull-left">
            {props.contact!.actions!.indexOf("save") < 0 ? null : (
              <div className="dropup">
                <button className="btn btnCtrl btn-outline dropdown-toggle" type="button" data-toggle="dropdown">
                  More..
                  <span className="caret" />
                </button>
                <ul className="dropdown-menu">
                  <li key="history">
                    <OMSButtonStyleL
                      style={{ marginRight: 10 }}
                      type="button"
                      ui_icon="fa-history"
                      ui_type="cancel"
                      ui_text="History"
                      ui_processing_text=""
                      processing={false}
                      disabled={buttonStatus.disabled}
                      onClick={handleAuditTrailShow}
                    />
                  </li>
                  {/*<li key="history"><OMSButtonStyleL style={{ marginRight: 10 }} type="button" ui_icon="fa-history" ui_type="cancel" ui_text="History" ui_processing_text="" processing={false} disabled={this.state.ButtonStatus.disabled} onClick={this.onHistoryClick} /></li>*/}
                  <li key="delete">
                    <OMSButtonStyleL
                      style={{ marginRight: 10 }}
                      type="button"
                      ui_icon="fa-trash-o"
                      ui_type="cancel"
                      ui_text="Delete contact"
                      ui_processing_text="Deleting.."
                      processing={buttonStatus.processing_delete}
                      disabled={buttonStatus.disabled}
                      onClick={onDeleteClick}
                    />
                  </li>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <div className="pull-left">
            <label style={{ padding: "6px 12px" }}>Step {step} of 2 </label>
          </div>
        )}

        <div className="pull-right">
          {contactID != "" ? (
            <div>
              <OMSButtonStyleL
                style={{ marginRight: 10 }}
                type="button"
                ui_icon="fa-times"
                ui_class="btnCtrl"
                ui_type="cancel"
                ui_text="Cancel"
                ui_processing_text=""
                processing={false}
                disabled={buttonStatus.disabled}
                onClick={onCancelClick}
              />
              {props.contact!.actions!.indexOf("save") < 0 ? null : (
              <div className="pull-right">
                <OMSButtonStyleL
                  //type="button"
                  type="submit"
                  style={{ marginRight: 10 }}
                  onClick={() => doSaveDraft(true)}
                  ui_icon="fa-check"
                  ui_class="btnCtrl"
                  ui_type="save"
                  ui_text="Save"
                  ui_processing_text="Saving.."
                  processing={buttonStatus.processing_save}
                  disabled={buttonStatus.disabled}
                />
                <OMSButtonStyleL
                  type="submit"
                  ui_icon="fa-check"
                  ui_class="btnCtrl"
                  ui_type="save"
                  ui_text="Save & Close"
                  ui_processing_text="Saving.."
                  processing={buttonStatus.processing_save}
                  disabled={buttonStatus.disabled}
                  onClick={() => doSaveDraft(false)}
                />
              </div>
              )}
            </div>
          ) : (
            <div>
              <OMSButtonStyleL
                style={{ marginRight: 10 }}
                type="button"
                ui_class="btnCtrl"
                ui_icon="fa-times"
                ui_type="cancel"
                ui_text="Cancel"
                ui_processing_text=""
                processing={false}
                disabled={buttonStatus.disabled}
                onClick={onCancelClick}
              />
              {step <= 1 ? null : (
                <OMSButtonStyleL
                  type="button"
                  ui_class="btnCtrl"
                  onClick={() => doChangeStepClick(step - 1)}
                  ui_icon="fa-arrow-left"
                  ui_type="save"
                  ui_text="Previous"
                  ui_processing_text="Saving.."
                  style={{ marginRight: 5 }}
                  processing={buttonStatus.processing_save}
                  disabled={buttonStatus.disabled}
                />
              )}
              {step == 2 ? null : (
                <OMSButtonStyleL
                  type="button"
                  onClick={() => doChangeStepClick(step + 1)}
                  ui_icon="fa-arrow-right"
                  ui_type="save"
                  ui_text="Next"
                  ui_processing_text="Saving.."
                  style={{ marginRight: 10 }}
                  processing={buttonStatus.processing_save}
                  disabled={buttonStatus.disabled}
                />
              )}
              <OMSButtonStyleL
                type="submit"
                onClick={doSave}
                ui_class="btnCtrl"
                ui_icon="fa-check"
                ui_type="save"
                ui_text="Save"
                ui_processing_text="Saving.."
                processing={buttonStatus.processing_save}
                disabled={buttonStatus.disabled}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SaveContactFooter;
