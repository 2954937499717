import React from "react";
import $ from "jquery";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSNumericBox } from "../../app/common/common-components/OMSComponent";
import { ReportGrid } from "../contact/ReportGrid";
import DataTableCompDynamicP from "../../app/common/common-components/OMSDatatables";
import { OMSTwitterAutocomplete2 } from "../../app/common/common-components/OMSAutocomplete";
import { OMSMultiselectPTDropdown } from "../../app/common/common-components/OMSMultiselectTagDropdown";
import apiAgent from "../../app/api/apiAgent";
import DatePicker from "react-datepicker";
import { OMSListingsInteractiveMapping } from "../../app/common/common-components/OMSListingsInteractiveMapping";
import { withRouter } from "react-router-dom";
import { FromDateStringToJavascriptDate, FromJavascriptDateToDateString, isEntegralSuperUser } from "../../app/common/common-functions/functions.js";
import SyndicationComponent from "./PropertyGridPageControls/SyndicationComponent";
import PropertyReportsComponent from "./PropertyGridPageControls/PropertyReportsComponent";
import TermsModal from "./PropertyGridPageControls/PropertyReportsChildComponents/TermsModal";
import { OMSMultiselectDropdown } from "../../app/common/common-components/OMSMultiselectDropdown";

class PropertyGrid extends React.Component {
  constructor(props) {
    super(props);

    this.reportGridRef = React.createRef();
  }
  getDefaultInternalTags = (tags) => {
    var items = [];
    for (const tagElement of tags) {
      var item = {
        text: tagElement,
        value: tagElement
      }
      items.push(item);
    }
    return items
  }
  componentDidMount() {
    console.log("inside componentDidMount, userSession=", this.props.userSession.role);
    this.setState({
      internalTags: this.getDefaultInternalTags(this.props.InternalListingTags)
    });

    // Safely parse termsAcceptedModules, default to an empty array if parsing fails
    let termsAcceptedModules = [];

    try {
      if (this.props.userSession.termsAcceptedModules) {
        termsAcceptedModules = JSON.parse(this.props.userSession.termsAcceptedModules);
      }
    } catch (error) {
      //console.error("Error parsing termsAcceptedModules:", error);
    }

    // Check if "insights" is included in the parsed array
    const isInsightsAccepted = termsAcceptedModules.includes("insights");
    //console.log("Is 'insights' accepted:", isInsightsAccepted);

    // If terms are not accepted and pageView is 500 (Insights view), show the terms modal
    if (this.state.pageView === 500 && !isInsightsAccepted) {
      //console.log("Displaying the Terms Modal because 'insights' is not accepted.");
      this.setState({ showTermsModal: true });
    }

    // Initialize other conditions like dashboardSectionClicked
    let agentId = "";
    let agentName = "";

    if (this.props.dashboardSectionClicked) {
      switch (this.props.dashboardSectionClicked) {
        case "syndication":
          this.onChangeView("2");
          break;

        case "forSale":
          this.onResetFilterClick();
          let tempViewData = this.state.ViewData;
          tempViewData.SaleType = "1";
          tempViewData.Status = "1";

          if (!this.props.userSession.isAdmin) {
            agentId = this.props.userSession.userId;
            agentName = this.state.Agents.filter((agent) => agent.Value === this.props.userSession.userId)[0].Text;
          }

          this.setState({ mSaleType: "1", mStatus: "1", mAgent: agentId, mAgentName: agentName, ViewData: tempViewData }, () => {
            this.onApplyFilterClick();
          });
          break;

        case "forRent":
          this.onResetFilterClick();
          tempViewData = this.state.ViewData;
          tempViewData.SaleType = "2";
          tempViewData.Status = "1";

          if (!this.props.userSession.isAdmin) {
            agentId = this.props.userSession.userId;
            agentName = this.state.Agents.filter((agent) => agent.Value === this.props.userSession.userId)[0].Text;
          }

          this.setState({ mSaleType: "2", mStatus: "1", mAgent: agentId, mAgentName: agentName, ViewData: tempViewData }, () => {
            this.onApplyFilterClick();
          });
          break;

        default:
          // No matching case
          break;
      }
    }
  }

  handleAcceptTerms = () => {

    this.setState({ showTermsModal: false, termsAccepted: true });


    const updatedTermsAcceptedModules = JSON.parse(this.props.userSession.termsAcceptedModules || "[]");
    if (!updatedTermsAcceptedModules.includes("insights")) {
      updatedTermsAcceptedModules.push("insights");
    }


    this.props.userSession.termsAcceptedModules = JSON.stringify(updatedTermsAcceptedModules);


    apiAgent.Setting.addTermsAcceptedModuleForUser("insights")
      .then((response) => {
        //console.log("response from addTermsAcceptedModuleForUser", response);
      })
      .catch((error) => {
        // Log any error that occurs
        console.error("Error saving terms acceptance:", error);
      });

    // Navigate to Insights view and ensure the menu is highlighted
    this.setState({ pageView: 500, view: 5 });
  };



  //EntegralSuperUser
  loadGrid = (requestParams) => {
    return apiAgent.Property.getPropertyGridData(
      requestParams,
      this.state.view,
      this.state.ViewData.SaleType,
      this.state.ViewData.Status,
      this.state.ViewData.Mandate,
      this.state.ViewData.PropertyType,
      this.state.ViewData.Agent,
      this.state.ViewData.PriceFrom,
      this.state.ViewData.PriceTo,
      this.state.ViewData.OfficeID,
      this.state.ViewData.NeedUpdate,
      this.state.ViewData.Syndication_Active,
      this.state.ViewData.Syndication_Error,
      this.state.ViewData.Syndication_Pending,
      this.state.ViewData.MandateStartFrom,
      this.state.ViewData.MandateStartTo,
      this.state.ViewData.MandateEndFrom,
      this.state.ViewData.MandateEndTo,
      this.state.ViewData.Complex,
      this.state.ViewData.Street,
      this.state.ViewData.Areas,
      this.state.ViewData.OwnListings,
      this.state.ViewData.ExternalListings,
      this.state.ViewData.InternalListingTags
    )
      .then((response) => {
        //console.log("inside load grid, response=", response); // Log the response from the API
        this.setState({ Data: response });
        return response.gridData;
      })
      .catch((error) => {
        //console.error("Error fetching property grid data:", error); // Log any error that occurs
      });
  };

  getMandateTypeDD = () => {
    var mandateTypeDD = this.props.MandateTypes;
    if (mandateTypeDD === undefined) mandateTypeDD = [];
    mandateTypeDD.splice(0, 0, "Mandate Type");
    return mandateTypeDD;
  };

  getPropertyTypeDD = () => {
    var propertyTypeDD = [];
    propertyTypeDD.push("Property Type");
    //console.log("inside getPropertyTypeDD, this.props.PropertyTypes: ", this.props.PropertyTypes);
    for (var i = 0; i < this.props.PropertyTypes?.length; i++) {
      for (var j = 0; j < this.props.PropertyTypes[i].Types.length; j++) {
        if (propertyTypeDD.indexOf(this.props.PropertyTypes[i].Types[j]) < 0) propertyTypeDD.push(this.props.PropertyTypes[i].Types[j]);
      }
    }
    return propertyTypeDD;
  };

  handleChange = (e) => {
    //console.log("inside handleChange", e, e.target.name, e.target.value);
    if (e.target.name === "mOwnListings" || e.target.name === "mExternalListings") {
      this.setState(
        {
          [e.target.name]: e.target.checked,
        },
        () => {
          //console.log("after change, this.state.mOwnListings:",this.state.mOwnListings+"this.state.mExternalListings:",this.state.mExternalListings); // Log the updated state
        }
      );
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleNumericDropdownChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleDropdownChange = (e) => {
    //console.log("inside handleDropdownChange, e.name: ", e.name);
    if (e.name == "mAgent") {
      var mAgentName = window.$("#mAgent option:selected").text();
      this.setState({ [e.name]: e.newValue, mAgentName: mAgentName });
    } else if (e.name == "mOfficeID") {
      //console.log("inside handleDropdownChange, e.newValue: ", e.newValue);
      var mOfficeID = e.newValue;
      var mOfficeName = window.$("#mOfficeID option:selected").text();
      //console.log("inside handleDropdownChange, mOfficeName: ", mOfficeName);
      this.onOfficeIDAgentsChanged(mOfficeID, mOfficeName);
    } else if (e.name == "mMandate" && e.newValue == "Mandate Type") {
      //console.log(e);
      this.setState({ [e.name]: "" });
    } else {
      this.setState({ [e.name]: e.newValue });
    }
  };

  handlePropertyTypesChange = (selected) => {
    this.setState({ mPropertyTypes: selected });
  };

  handleInternalTagChange = (selected) => {
    this.setState({
      mInternalTags: selected
    })
  }

  handleSyndicationChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  onCreateClick = (e) => {
    this.props.history.push("/Property/SaveProperty");
  };

  handleRowClick = (data) => {
    this.props.history.push("/Property/SaveProperty?ID=" + data.propertyID);
  };

  onOpenFilterClick = (e) => {
    var mViewData = this.state.ViewData;
    var mPropertyTypes = [];
    if (mViewData.PropertyType && mViewData.PropertyType != "Property Type") mPropertyTypes = mViewData.PropertyType.split(",");
    this.setState({
      mSaleType: mViewData.SaleType,
      mStatus: mViewData.Status,
      mMandate: mViewData.Mandate,
      mPropertyTypes: mPropertyTypes,
      mAgent: mViewData.Agent,
      mAgentName: mViewData.AgentName,
      mPriceFrom: mViewData.PriceFrom,
      mPriceTo: mViewData.PriceTo,
      mOfficeID: mViewData.OfficeID,
      mOfficeName: mViewData.OfficeName,
      mSyndication_Active: mViewData.Syndication_Active,
      mSyndication_Error: mViewData.Syndication_Error,
      mSyndication_Pending: mViewData.Syndication_Pending,
      mMandateStartFrom: mViewData.MandateStartFrom,
      mMandateStartTo: mViewData.MandateStartTo,
      mMandateEndFrom: mViewData.MandateEndFrom,
      mMandateEndTo: mViewData.MandateEndTo,
      mMandateStartFromDT: FromDateStringToJavascriptDate(mViewData.MandateStartFrom),
      mMandateStartToDT: FromDateStringToJavascriptDate(mViewData.MandateStartTo),
      mMandateEndFromDT: FromDateStringToJavascriptDate(mViewData.MandateEndFrom),
      mMandateEndToDT: FromDateStringToJavascriptDate(mViewData.MandateEndTo),
      mComplex: mViewData.Complex,
      mStreet: mViewData.Street,
      mAreas: [...mViewData.Areas],
      mOwnListings: mViewData.OwnListings,
      mExternalListings: mViewData.ExternalListings,
      mInternalTags: [...mViewData.InternalListingTags]
    });
    window.$("#filterModel").modal("show");
  };

  onApplyFilterClick = (e) => {
    window.$("#filterModel").modal("hide");
    //console.log("inside onApplyFilterClick, this.state: ", this.state);

    var isOfficeChanged = false;
    if (this.state.ViewData.OfficeID != this.state.mOfficeID) isOfficeChanged = true;

    var filterData = this.state.ViewData;
    //console.log("inside onApplyFilterClick, filterData: ", filterData);

    filterData.SaleType = this.state.mSaleType;
    if (filterData.SaleType == "-1") {
      filterData.Status = "";
      filterData.Mandate = "";
    } else {
      filterData.Status = this.state.mStatus;
      filterData.Mandate = this.state.mMandate;
    }

    filterData.PropertyType = this.state.mPropertyTypes.join(",");
    filterData.Agent = this.state.mAgent;
    filterData.AgentName = this.state.mAgentName;
    filterData.PriceFrom = this.state.mPriceFrom;
    filterData.PriceTo = this.state.mPriceTo;
    filterData.OfficeID = this.state.mOfficeID;
    filterData.OfficeName = this.state.mOfficeName;
    filterData.Syndication_Active = this.state.mSyndication_Active;
    filterData.Syndication_Error = this.state.mSyndication_Error;
    filterData.Syndication_Pending = this.state.mSyndication_Pending;
    filterData.MandateStartFrom = this.state.mMandateStartFrom;
    filterData.MandateStartTo = this.state.mMandateStartTo;
    filterData.MandateEndFrom = this.state.mMandateEndFrom;
    filterData.MandateEndTo = this.state.mMandateEndTo;
    filterData.Complex = this.state.mComplex;
    filterData.Street = this.state.mStreet;
    filterData.NeedUpdate = true;
    filterData.Areas = this.state.mAreas;

    //console.log("inside onApplyFilterClick, this.state.mOwnListings: ", this.state.mOwnListings);
    filterData.OwnListings = this.state.mOwnListings;
    filterData.ExternalListings = this.state.mExternalListings;
    filterData.InternalListingTags = this.state.mInternalTags;
    //console.log("inside onApplyFilterClick, filterData: ", filterData);
    if (isOfficeChanged) {
      this.onGetHeaderColumnIndexAjax(this.state.mOfficeID, filterData);
    } else {
      this.setState(
        {
          ViewData: filterData,
          ExtraParams: this.getExtraParams(filterData, this.state.view),
        },
        function () {
          if (this.state.view == 3) this.loadInteractiveMap();
        }.bind(this)
      );
    }
  };

  onResetFilterClick = (e) => {
    window.$("#filterModel").modal("hide");

    var filterData = this.getDefaultViewData(null);
    filterData.NeedUpdate = true;

    var isOfficeChanged = false;
    if (this.state.ViewData.OfficeID != this.state.mOfficeID) isOfficeChanged = true;

    if (isOfficeChanged) {
      this.setState({
        mSaleType: "",
        mStatus: "",
        //mMandate: "Mandate Type",
        mMandate: "",
        mPropertyTypes: [],
        mAgent: "",
        mPriceFrom: "",
        mPriceTo: "",
        mAgentName: "",
        mOfficeID: "",
        mOfficeName: "",
        mMandateEndFrom: "",
        mMandateEndTo: "",
        mMandateStartFrom: "",
        mMandateStartTo: "",
        mStreet: "",
        mComplex: "",
        mAreas: [],
        mOwnListings: true,
        mExternalListings: true,
        mInternalTags: []
      });
      this.onGetHeaderColumnIndexAjax(this.state.mOfficeID, filterData);
    } else {
      this.setState({
        ViewData: filterData,
        ExtraParams: this.getExtraParams(filterData, this.state.view),
        mSaleType: "",
        mStatus: "",
        //mMandate: "Mandate Type",
        mMandate: "",
        mPropertyTypes: [],
        mAgent: "",
        mPriceFrom: "",
        mPriceTo: "",
        mAgentName: "",
        mOfficeID: "",
        mOfficeName: "",
        mMandateEndFrom: "",
        mMandateEndTo: "",
        mMandateStartFrom: "",
        mMandateStartTo: "",
        mStreet: "",
        mComplex: "",
        mAreas: [],
        mOwnListings: true,
        mExternalListings: true,
        mInternalTags: []
      });
    }
  };

  onChangeView = (view) => {
    if (view == 100) {
      this.reportGridRef.current.onOpenReports();
      this.setState({ pageView: view, view: view });
    } else if (view == 3) {
      this.setState({ pageView: 2, view: view }, function () {
        this.loadInteractiveMap();
      });
    } else if (view == 2) {
      this.setState({ pageView: 200, view: view });
    } else if (view == 5) {

      const termsAcceptedModules = JSON.parse(this.props.userSession.termsAcceptedModules || "[]");
      const isInsightsAccepted = termsAcceptedModules.includes("insights");

      if (!isInsightsAccepted) {

        this.setState({ showTermsModal: true });
      } else {

        this.setState({ pageView: 500, view: view });
      }
    } else {
      this.setState({
        pageView: 1,
        view: view,
        ViewData: this.state.ViewData,
        ExtraParams: this.getExtraParams(this.state.ViewData, view),
      });
    }
  };

  onGetHeaderColumnIndexAjax = (OfficeID, mFilterData) => {
    apiAgent.Property.getHeaderColumnIndexAjax(OfficeID)
      .then((response) => {
        this.setState(
          {
            Headers: JSON.stringify(response),
            ViewData: mFilterData,
            ExtraParams: this.getExtraParams(mFilterData, this.state.view),
          },
          function () {
            if (this.state.view == 3) this.loadInteractiveMap();
          }
        );
      })
      .catch((error) => {
        //toast.error(error.response.data))
      });
  };

  onOfficeIDAgentsChanged = (mOfficeID, mOfficeName) => {
    if (this.state.mOfficeID != mOfficeID) {
      //console.log("before inside onOfficeIDAgentsChanged state", this.state);
      apiAgent.Property.getAgentsDDAjax(mOfficeID)
        .then((response) => {
          //console.log("response", JSON.parse(JSON.stringify(response)));
          this.setState({
            mAgent: "",
            mAgentName: "",

            Agents: JSON.parse(JSON.stringify(response)),
            mOfficeID: mOfficeID,
            mOfficeName: mOfficeName,
          });
        })
        .catch((error) => {
          //toast.error(error.response.data))
        });
    } else {
      this.setState({ mOfficeID: mOfficeID, mOfficeName: mOfficeName });
    }
  };

  loadInteractiveMap = () => {
    const oExtraParams = JSON.parse(this.state.ExtraParams);
    //console.log("load InteractiveMap here..,this.state.ExtraParams", oExtraParams);

    this.setState({ loading: true, dataMap: [] });

    apiAgent.Property.getIndexAjax(
      oExtraParams.View,
      oExtraParams.PageView,
      oExtraParams.Search,
      oExtraParams.SaleType,
      oExtraParams.Status,
      oExtraParams.Mandate,
      oExtraParams.PropertyType,
      oExtraParams.Agent,
      oExtraParams.PriceFrom,
      oExtraParams.PriceTo,
      oExtraParams.OfficeID,
      oExtraParams.NeedUpdate,
      oExtraParams.Syndication_Active,
      oExtraParams.Syndication_Error,
      oExtraParams.Syndication_Pending,
      oExtraParams.MandateStartFrom,
      oExtraParams.MandateStartTo,
      oExtraParams.MandateEndFrom,
      oExtraParams.MandateEndTo,
      oExtraParams.Complex,
      oExtraParams.Street,
      oExtraParams.Areas,
      oExtraParams.OwnListings,
      oExtraParams.ExternalListings
    )
      .then((response) => {
        //console.log("inside loadInteractiveMap, response=", response);
        this.setState({
          //dataMap: response!==null?response[0]:null ,
          dataMap: response,
          loadMap: true,
          refreshMap: true,
          loading: false,
        });
      })
      .catch((error) => {
        //toast.error(error.response.data))
      });
  };

  getExtraParams = (data, view) => {
    var PriceFrom = "";
    if (data.PriceFrom != "" && data.PriceFrom != "0") PriceFrom = data.PriceFrom;

    var PriceTo = "";
    if (data.PriceTo != "" && data.PriceTo != "0") PriceTo = data.PriceTo;

    var Mandate = "";
    if (data.Mandate != "Mandate Type") Mandate = data.Mandate;

    var PropertyType = "";
    if (data.PropertyType != "Property Type") PropertyType = data.PropertyType;

    /* var SaleType = "";
    if (data.SaleType != "Sale Type") SaleType = data.SaleType; */

    var requestJson = {
      View: view,
      SaleType: data.SaleType,
      //SaleType: SaleType,
      Status: data.Status,
      Mandate: Mandate,
      PropertyType: PropertyType,
      PriceFrom: PriceFrom,
      PriceTo: PriceTo,
      Agent: data.Agent,
      OfficeID: data.OfficeID,
      Syndication_Active: data.Syndication_Active,
      Syndication_Error: data.Syndication_Error,
      Syndication_Pending: data.Syndication_Pending,
      MandateStartFrom: data.MandateStartFrom,
      MandateStartTo: data.MandateStartTo,
      MandateEndFrom: data.MandateEndFrom,
      MandateEndTo: data.MandateEndTo,
      Complex: data.Complex,
      Street: data.Street,
      NeedUpdate: data.NeedUpdate,
      Areas: data.Areas,
      OwnListings: data.OwnListings,
      ExternalListings: data.ExternalListings,
      InternalListingTags: data.InternalListingTags
    };

    return JSON.stringify(requestJson);
  };

  getDefaultViewData = (data) => {
    var SaleType = "";
    var Status = "";
    //var Mandate = "Mandate Type";
    var Mandate = "";
    var PropertyType = "";
    var Agent = "";
    var PriceFrom = "";
    var PriceTo = "";
    var AgentName = "";
    var OfficeID = "";
    var OfficeName = "";
    var MandateStartFrom = "";
    var MandateStartTo = "";
    var MandateEndFrom = "";
    var MandateEndTo = "";
    var Syndication_Active = false;
    var Syndication_Error = false;
    var Syndication_Pending = false;
    var Areas = [];
    var OwnListings = true;
    var ExternalListings = true;
    var InternalListingTags = [];
    var Complex = "";
    var Street = "";

    if (data !== undefined && data != null) {
      if (data.OfficeID !== undefined && data.OfficeID != null && data.OfficeID != "") {
        OfficeID = data.OfficeID;
        for (var i = 0; i < this.props.OfficeDD.length; i++) {
          if (this.props.OfficeDD[i].Value == OfficeID) {
            OfficeName = this.props.OfficeDD[i].Text;
          }
        }
      }

      if (data.Agent !== undefined && data.Agent != null && data.Agent != "") {
        Agent = data.Agent;
        for (var i = 0; i < this.props.Agents.length; i++) {
          if (this.props.Agents[i].Value == Agent) {
            AgentName = this.props.Agents[i].Text;
          }
        }
      }
      if (data.ListingTags != undefined) {
        InternalListingTags = data.ListingTags;
      }

      //if (data.Mandate !== undefined && data.Mandate != null) Mandate = data.Mandate == "" ? "Mandate Type" : data.Mandate;
      if (data.Mandate !== undefined && data.Mandate != null) Mandate = data.Mandate;

      if (data.PriceFrom !== undefined && data.PriceFrom != null && data.PriceFrom != 0) PriceFrom = data.PriceFrom.toString();

      if (data.PriceTo !== undefined && data.PriceTo != null && data.PriceTo != 0) PriceTo = data.PriceTo.toString();

      if (data.PropertyType !== undefined && data.PropertyType != null) PropertyType = data.PropertyType == "" ? "" : data.PropertyType;

      if (data.SaleType !== undefined && data.SaleType != null && data.SaleType != 0) SaleType = data.SaleType.toString();

      if (data.Status !== undefined && data.Status != null && data.Status != 0) Status = data.Status.toString();

      if (data.MandateEndFrom !== undefined && data.MandateEndFrom != null) MandateEndFrom = data.MandateEndFrom;

      if (data.MandateEndTo !== undefined && data.MandateEndTo != null) MandateEndTo = data.MandateEndTo;

      if (data.MandateStartFrom !== undefined && data.MandateStartFrom != null) MandateStartFrom = data.MandateStartFrom;

      if (data.MandateStartTo !== undefined && data.MandateStartTo != null) MandateStartTo = data.MandateStartTo;

      if (data.Syndication_Active !== undefined && data.Syndication_Active != null) Syndication_Active = data.Syndication_Active;

      if (data.Syndication_Error !== undefined && data.Syndication_Error != null) Syndication_Error = data.Syndication_Error;

      if (data.Syndication_Pending !== undefined && data.Syndication_Pending != null) Syndication_Pending = data.Syndication_Pending;

      if (data.Areas !== undefined && data.Areas != null && data.Areas != "") Areas = data.Areas;

      if (data.OwnListings !== undefined && data.OwnListings != null) OwnListings = data.OwnListings;
      else OwnListings = true;
      if (data.ExternalListings !== undefined && data.ExternalListings != null) ExternalListings = data.ExternalListings;
      else ExternalListings = true;

      if (data.Complex !== undefined && data.Complex != null && data.Complex != "") Complex = data.Complex.toString();
      if (data.Street !== undefined && data.Street != null && data.Street != "") Street = data.Street.toString();
    }
    return {
      NeedUpdate: false,
      SaleType,
      Status,
      Mandate,
      PropertyType,
      Agent,
      PriceFrom,
      PriceTo,
      AgentName,
      OfficeID,
      OfficeName,
      MandateStartFrom,
      MandateStartTo,
      MandateEndFrom,
      MandateEndTo,
      Complex,
      Street,
      Syndication_Active,
      Syndication_Error,
      Syndication_Pending,
      Areas,
      OwnListings,
      ExternalListings,
      InternalListingTags
    };
  };

  formatDate = (fullDateString) => {
    const date = new Date(fullDateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
  };

  getDisplayText = (data) => {
    var filterText = "";
    if (data.SaleType) {
      var saleType = "";

      if (data.SaleType == "-1") saleType = "Drafts";
      else if (data.SaleType == "1") saleType = "For sale";
      else if (data.SaleType == "2") saleType = "For rent";
      else if (data.SaleType == "3") saleType = "Auction";
      else if (data.SaleType == "4") saleType = "Valuation";

      filterText = saleType + " | ";
    }

    if (data.Status) {
      var status = "";

      if (data.Status == "1") status = "Active";
      else if (data.Status == "2") status = "Expired";
      else if (data.Status == "3") status = "Withdrawn";
      else if (data.Status == "4") status = "Rented";
      else if (data.Status == "5") status = "Sold - Under Offer";
      else if (data.Status == "6") status = "Sold - Registered";
      else if (data.Status == "7") status = "Sold - Cancelled";
      else if (data.Status == "8") status = "Inactive";
      else if (data.Status == "-8") status = "Deleted";

      filterText = filterText + status + " | ";
    }

    if (data.Mandate != "Mandate Type" && data.Mandate != "") filterText = filterText + data.Mandate + " | ";

    if (data.PropertyType != "Property Type" && data.PropertyType != "") filterText = filterText + data.PropertyType + " | ";

    if (data.Agent) filterText = filterText + data.AgentName + " | ";

    if (data.OfficeID) filterText = filterText + data.OfficeName + " | ";

    if (data.InternalListingTags && data.InternalListingTags.length > 0) {
      if (data.InternalListingTags.length >= 1 && data.InternalListingTags.length <= 2) {
        filterText = filterText + data.InternalListingTags.toString() + " | ";
      }
      else {
        filterText = filterText + "Listing Tag(s)" + " | ";
      }

    }
    if (data.Complex) filterText = filterText + data.Complex + " | ";

    if (data.Street) filterText = filterText + data.Street + " | ";

    if (data.PriceFrom != "" && data.PriceFrom != "0" && data.PriceTo != "" && data.PriceTo != "0")
      filterText = filterText + data.PriceFrom + " to " + data.PriceTo + " | ";
    else if (data.PriceFrom != "" && data.PriceFrom != "0") filterText = filterText + "min: " + data.PriceFrom + " | ";
    else if (data.PriceTo != "" && data.PriceTo != "0") filterText = filterText + "max: " + data.PriceTo + " | ";

    if (data.Syndication_Active || data.Syndication_Error || data.Syndication_Pending) {
      var syndText = "";
      if (data.Syndication_Active) syndText = syndText + "Active,";
      if (data.Syndication_Error) syndText = syndText + "Error,";
      if (data.Syndication_Pending) syndText = syndText + "Pending,";

      filterText = filterText + "Syndication(" + syndText.slice(0, syndText.length - 1) + ") | ";
    }

    const formattedMandateStartFrom = data.MandateStartFrom ? data.MandateStartFrom : "";
    const formattedMandateStartTo = data.MandateStartTo ? data.MandateStartTo : "";
    const formattedMandateEndFrom = data.MandateEndFrom ? data.MandateEndFrom : "";
    const formattedMandateEndTo = data.MandateEndTo ? data.MandateEndTo : "";

    if (data.MandateStartFrom != "" && data.MandateStartTo != "")
      filterText = filterText + "Mandate start between " + formattedMandateStartFrom + " to " + formattedMandateStartTo + " | ";
    else if (data.MandateStartFrom != "") filterText = filterText + "Mandate start from " + formattedMandateStartFrom + " | ";
    else if (data.MandateStartTo != "") filterText = filterText + "Mandate start upto " + formattedMandateStartTo + " | ";

    if (data.MandateEndFrom != "" && data.MandateEndTo != "")
      filterText = filterText + "Mandate expire between " + formattedMandateEndFrom + " to " + formattedMandateEndTo + " | ";
    else if (data.MandateEndFrom != "") filterText = filterText + "Mandate expire from " + formattedMandateEndFrom + " | ";
    else if (data.MandateEndTo != "") filterText = filterText + "Mandate expire upto " + formattedMandateEndTo + " | ";

    if (data.Areas.length > 0) {
      if (data.Areas.length == 1) filterText = filterText + data.Areas[0] + " | ";
      else filterText = filterText + "Area(s) | ";
    }

    if (data.OwnListings && !data.ExternalListings) filterText = filterText + "Own Listings | ";
    else if (data.ExternalListings && !data.OwnListings) filterText = filterText + "External Listings | ";

    if (filterText != "") filterText = filterText.slice(0, filterText.length - 3);

    return filterText;
  };

  areaSelectionChanged = (e) => {
    var selectedArea = e.suburb == "" ? e.town : e.town + ", " + e.suburb;
    if (this.state.mAreas.indexOf(selectedArea) == -1) {
      var pSelectedAreas = this.state.mAreas;
      pSelectedAreas.push(selectedArea);
      this.setState({ mAreas: pSelectedAreas });
    }
  };

  areaSelectionRemoved = (e) => {
    var index = this.state.mAreas.indexOf(e);
    if (index > -1) {
      var pSelectedAreas = this.state.mAreas;
      pSelectedAreas.splice(index, 1);
      this.setState({ mAreas: pSelectedAreas });
    }
  };

  state = {
    pageView: 1,
    view: 1,
    loading: false,
    Data: {},
    ViewData: this.getDefaultViewData(this.props.FilterCache),
    ExtraParams: this.getExtraParams(this.getDefaultViewData(this.props.FilterCache), 1),
    Headers: JSON.stringify(this.props.Headers),
    Agents: this.props.Agents,
    mSaleType: "",
    mStatus: "1",
    //mMandate: "Mandate Type",
    mMandate: "",
    mPropertyTypes: [],
    mAgent: "",
    mAgentName: "",
    mPriceFrom: "",
    mPriceTo: "",
    mOfficeID: "",
    mOfficeName: "",
    mSyndication_Active: false,
    mSyndication_Error: false,
    mSyndication_Pending: false,
    mMandateStartFrom: "",
    mMandateStartTo: "",
    mMandateEndFrom: "",
    mMandateEndTo: "",
    mMandateStartFromDT: null,
    mMandateStartToDT: null,
    mMandateEndFromDT: null,
    mMandateEndToDT: null,
    mComplex: "",
    mStreet: "",
    mAreas: [],
    MandateTypesDD: this.getMandateTypeDD(),
    PropertyTypesDD: this.getPropertyTypeDD(),
    loadMap: false,
    refreshMap: false,
    dataMap: [],
    showTermsModal: false,
    termsAccepted: false,
    internalTags: [],
    mInternalTags: []
  };

  render() {
    var self = this;
    var filterText = this.getDisplayText(this.state.ViewData);

    //var specialOffices = ["RE/MAX Randgro", "LuxLiv Property Group", "1 Property", "RE/MAX LIVING", "CENTURY 21 Wildlife Properties"];
    //var specialOffices = ["RE/MAX LIVING"];

    var entegralOfficeName = process.env.REACT_APP_ROOT_URL === "https://baseapi.entegral.net" ? "Entegral Technologies (Pty) Ltd" : "Entegral";

    let showPropertyReports = false; // Default to false

    // Check if the conditions for South Africa and office name match
    //console.log("this.props.userSession.country", this.props.userSession.country);
    if (this.props.userSession.country === "South Africa"
      //&& 
      // (this.props.userSession.officeName === entegralOfficeName || 
      //  (process.env.REACT_APP_ROOT_URL === "https://baseapi.entegral.net" 
      //  //&& specialOffices.includes(this.props.userSession.officeName)
      //  ))
    ) {
      showPropertyReports = true;
    }

    // console.log("Office name:", this.props.userSession.officeName);
    // console.log("showPropertyReports:", showPropertyReports);


    return (
      <div className="row">
        <div className="col-md-12 hidden-md hidden-lg" style={{ marginBottom: 10 }}>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("1")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.view == 1 ? <b>All</b> : <span>All</span>}
          </span>

          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("2")}>
            <i className="fa fa-plug" style={{ marginRight: 8 }}></i>
            {this.state.view == 2 ? <b>Syndication</b> : <span>Syndication</span>}
          </span>

          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("3")}>
            <i className="fa fa-map-o" style={{ marginRight: 8 }}></i>
            {this.state.view == 3 ? <b>Map</b> : <span>Map</span>}
          </span>
          <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("100")}>
            <i className="fa fa-file-pdf-o" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 100 ? <b>Reports</b> : <span>Reports</span>}
          </span>
          {showPropertyReports && (
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("5")}>
              <i className="fa fa-lightbulb-o" style={{ marginRight: 8 }}></i>
              {this.state.view == 5 ? <b>Insights</b> : <span>Insights</span>}
            </span>
          )}
        </div>
        <div className="col-lg-1 col-md-2 hidden-xs hidden-sm" style={{ padding: 0 }}>
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("1")}>
            <i className="fa fa-table" style={{ marginRight: 8 }}></i>
            {this.state.view == 1 ? <b>All</b> : <span>All</span>}
          </span>
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("2")}>
            <i className="fa fa-plug" style={{ marginRight: 8 }}></i>
            {this.state.view == 2 ? <b>Syndication</b> : <span>Syndication</span>}
          </span>
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("3")}>
            <i className="fa fa-map-o" style={{ marginRight: 8 }}></i>
            {this.state.view == 3 ? <b>Map</b> : <span>Map</span>}
          </span>
          <span className="dashboard-menu mt-1 d-flex" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("100")}>
            <i className="fa fa-file-pdf-o" style={{ marginRight: 8 }}></i>
            {this.state.pageView == 100 ? <b>Reports</b> : <span>Reports</span>}
          </span>
          {showPropertyReports && (
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("5")}>
              <i className="fa fa-lightbulb-o" style={{ marginRight: 8 }}></i>
              {this.state.view == 5 ? <b>Insights</b> : <span>Insights</span>}
            </span>
          )}
        </div>
        <div className="col-lg-11 col-md-10" style={{ display: this.state.pageView < 100 ? "" : "none" }}>
          <div className="panel panel-default panel-table oms-box">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="form-inline form-group">
                    <div className="element">
                      <div className="truncate">
                        <label>
                          <i className="fa fa-filter" aria-hidden="true"></i>
                          &nbsp;
                        </label>
                        <a href="#" className="filter-text" onClick={this.onOpenFilterClick}>
                          {filterText == "" ? (
                            "Select filter"
                          ) : (
                            <span>
                              <b>Filter: </b>
                              {filterText}
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="pull-right boxRightMenu">
                    <div className="form-inline">
                      {this.props.actions.indexOf("create") < 0 ? null : (
                        <OMSButtonStyleL
                          type="button"
                          ui_icon=""
                          ui_type="create"
                          ui_text="add listing"
                          ui_processing_text=""
                          processing={false}
                          disabled={false}
                          onClick={this.onCreateClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel-body">
              <div style={{ display: this.state.pageView == 1 ? "block" : "none" }}>
                <DataTableCompDynamicP
                  id="propertiesTable"
                  HColumns={this.state.Headers}
                  onRowClick={this.handleRowClick}
                  makeAjaxRequest={this.loadGrid}
                  ExtraParams={this.state.ExtraParams}
                />
              </div>

              <div id="interactive_map" style={{ display: this.state.pageView != 1 ? "" : "none" }}>
                <OMSListingsInteractiveMapping
                  loadMap={this.state.loadMap}
                  refreshMap={this.state.refreshMap}
                  data={this.state.dataMap}
                  Currency={this.props.CurrencyDisplay}
                />
              </div>
            </div>
          </div>
          <div className="modal fade" id="filterModel" tabIndex={-1} aria-labelledby="filterModelLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    ×
                  </button>
                  <h4 className="modal-title" id="filterModelLabel">
                    Filter
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="form-group">
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <select
                          id="mSaleType"
                          name="mSaleType"
                          style={{ marginRight: 10 }}
                          className="form-control"
                          value={this.state.mSaleType}
                          onChange={this.handleChange}
                        >
                          <option value="">Sale Type</option>
                          <option value="-1">Drafts</option>
                          <option value="1">For sale</option>
                          <option value="2">For rent</option>
                          {/*<option value="3">Auction</option>*/}
                          <option value="4">Valuation</option>
                        </select>
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <select
                          id="mStatus"
                          name="mStatus"
                          style={{ marginRight: 10 }}
                          className="form-control"
                          value={this.state.mStatus}
                          onChange={this.handleChange}
                        >
                          <option value="">Status</option>
                          <option value="1">Active</option>
                          <option value="2">Expired</option>
                          <option value="3">Withdrawn</option>
                          <option value="4">Rented</option>
                          <option value="5">Sold - Under Offer</option>
                          <option value="6">Sold - Registered</option>
                          <option value="7">Sold - Cancelled</option>
                          <option value="8">Inactive</option>
                          {this.props.super_admin && <option value="-8">Deleted</option>}
                        </select>
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <OMSDropdown
                          id="mMandate"
                          name="mMandate"
                          style={{ marginRight: 10 }}
                          options={this.state.MandateTypesDD}
                          valueField=""
                          labelField=""
                          value={this.state.mMandate}
                          onChange={this.handleDropdownChange}
                        />
                      </div>
                    </div>
                    <div className="hidden-xs hidden-sm">
                      <br />
                      <br />
                    </div>
                    <div className="form-group">
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <OMSMultiselectPTDropdown
                          id="mPropertyTypes"
                          selected={this.state.mPropertyTypes}
                          data={this.props.PropertyTypes}
                          onChange={this.handlePropertyTypesChange}
                          labelField="Property Type(s)"
                          style={{ textAlign: "left", width: "100%" }}
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <OMSDropdown
                          id="mAgent"
                          name="mAgent"
                          style={{ marginRight: 10 }}
                          options={this.state.Agents}
                          valueField="Value"
                          labelField="Text"
                          value={this.state.mAgent}
                          onChange={this.handleDropdownChange}
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        {this.props.ShowOfficeDD ? (
                          <OMSDropdown
                            id="mOfficeID"
                            name="mOfficeID"
                            options={this.props.OfficeDD}
                            style={{ marginRight: 10 }}
                            valueField="Value"
                            labelField="Text"
                            value={this.state.mOfficeID}
                            onChange={this.handleDropdownChange}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="hidden-xs hidden-sm">
                      <br />
                      <br />
                    </div>
                    <div className="form-group">
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <input
                          type="text"
                          className="form-control"
                          id="mComplex"
                          name="mComplex"
                          placeholder="Search Complex"
                          title="Seach Complex"
                          onChange={this.handleChange}
                          value={this.state.mComplex}
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <input
                          type="text"
                          className="form-control"
                          id="mStreet"
                          name="mStreet"
                          placeholder="Search Street"
                          title="Search Street"
                          onChange={this.handleChange}
                          value={this.state.mStreet}
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                        <div className="checkbox" style={{ marginBottom: 5, marginTop: -3 }}>
                          <label>
                            <input
                              id="mOwnListings"
                              name="mOwnListings"
                              type="checkbox"
                              //defaultChecked={this.state.IsMakeLocationAvailable}
                              checked={this.state.mOwnListings}
                              onChange={this.handleChange}
                            //value={this.state.mOwnListings}
                            />
                            Own Listings
                          </label>
                        </div>
                        <div className="checkbox" style={{ marginBottom: 5 }}>
                          <label>
                            <input
                              id="mExternalListings"
                              name="mExternalListings"
                              type="checkbox"
                              checked={this.state.mExternalListings}
                              onChange={this.handleChange}
                            //value={this.state.mExternalListings}
                            />
                            External Listings
                          </label>
                        </div>
                      </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 marginBottom10">
                          <OMSMultiselectDropdown
                            id="mInternalListingTags"
                            value={this.state.mInternalTags}
                            options={this.state.internalTags}
                            onChange={this.handleInternalTagChange}
                            labelField="text"
                            valueField="value"
                            label="Listing Tags"
                          />
                        </div>
                    </div>
                    <div className="form-group">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="hidden-xs hidden-sm">
                          <br />
                        </div>
                        <div className="row">
                          <div className="col-md-8 col-sm-12 col-xs-12">
                            <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                                <label>Price range</label>
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-12 marginBottom10">
                                <OMSNumericBox
                                  id="mPriceFrom"
                                  separator=","
                                  name="mPriceFrom"
                                  maxLength={10}
                                  style={{ width: "100%" }}
                                  value={this.state.mPriceFrom}
                                  isDecimal={false}
                                  onChange={this.handleNumericDropdownChange}
                                />
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-12 marginBottom10">
                                <OMSNumericBox
                                  id="mPriceTo"
                                  separator=","
                                  name="mPriceTo"
                                  maxLength={10}
                                  style={{ width: "100%" }}
                                  value={this.state.mPriceTo}
                                  isDecimal={false}
                                  onChange={this.handleNumericDropdownChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12 col-xs-12">
                            <label>Syndication</label> <br />
                            <i className="fa fa-check-circle" style={{ marginLeft: 5, color: "green" }}></i>
                            <input
                              type="checkbox"
                              id="mSyndication_Active"
                              name="mSyndication_Active"
                              checked={this.state.mSyndication_Active}
                              style={{ marginLeft: 5 }}
                              onChange={this.handleSyndicationChange}
                            />
                            <i className="fa fa-exclamation-triangle" style={{ marginLeft: 15, color: "red" }}></i>
                            <input
                              type="checkbox"
                              id="mSyndication_Error"
                              name="mSyndication_Error"
                              checked={this.state.mSyndication_Error}
                              style={{ marginLeft: 5 }}
                              onChange={this.handleSyndicationChange}
                            />
                            <i className="fa fa-clock-o" style={{ marginLeft: 15, color: "orange" }}></i>
                            <input
                              type="checkbox"
                              id="mSyndication_Pending"
                              name="mSyndication_Pending"
                              checked={this.state.mSyndication_Pending}
                              style={{ marginLeft: 5 }}
                              onChange={this.handleSyndicationChange}
                            />
                          </div>
                        </div>
                        <div className="hidden-xs hidden-sm">
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <label>Area(s)</label>
                        <OMSTwitterAutocomplete2 id="SelectedAreas" name="SelectedAreas" value="" onSelect={this.areaSelectionChanged} />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="search-bar" style={{ marginTop: 10, marginBottom: 15 }}>
                          {this.state.mAreas.length == 0 ? null : (
                            <div className="search-bar__container">
                              <div className="search-bar__autocomplete">
                                <div className="search-bar__autocomplete-input">
                                  {this.state.mAreas.map(function (e, index) {
                                    return (
                                      <div key={index} className="search-bar__autocomplete-selected">
                                        {e}{" "}
                                        <i
                                          onClick={() => self.areaSelectionRemoved(e)}
                                          style={{ fontSize: 18, cursor: "pointer", margin: 2, marginRight: 10 }}
                                          className="fa fa-trash"
                                        ></i>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <label>Mandate start between</label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12 marginBottom10">
                            <DatePicker
                              selected={this.state.mMandateStartFromDT}
                              onChange={(date) => {
                                this.setState({ mMandateStartFromDT: date, mMandateStartFrom: FromJavascriptDateToDateString(date) });
                              }}
                              dateFormat="dd-MM-yyyy"
                              value={this.state.mMandateStartFromDT}
                              placeholderText="Mandate start from"
                              name="mMandateStartFrom"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ width: 150 }}
                              className="form-control MandateDatePickerProperty"
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12 marginBottom10">
                            <DatePicker
                              selected={this.state.mMandateStartToDT}
                              onChange={(date) => this.setState({ mMandateStartToDT: date, mMandateStartTo: FromJavascriptDateToDateString(date) })}
                              dateFormat="dd-MM-yyyy"
                              value={this.state.mMandateStartToDT}
                              placeholderText="Mandate start to"
                              name="mMandateStartTo"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              //style={{ width: 150 }}
                              className="form-control MandateDatePickerProperty"
                            />
                          </div>
                        </div>
                        <div className="hidden-xs hidden-sm">
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12">
                            <label>Mandate expire between</label>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12 marginBottom10">
                            <DatePicker
                              selected={this.state.mMandateEndFromDT}
                              onChange={(date) => this.setState({ mMandateEndFromDT: date, mMandateEndFrom: FromJavascriptDateToDateString(date) })}
                              dateFormat="dd-MM-yyyy"
                              value={this.state.mMandateEndFromDT}
                              placeholderText="Mandate end from"
                              name="mMandateEndFrom"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ width: 150 }}
                              className="form-control MandateDatePickerProperty"
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-12 marginBottom10">
                            <DatePicker
                              selected={this.state.mMandateEndToDT}
                              onChange={(date) => this.setState({ mMandateEndToDT: date, mMandateEndTo: FromJavascriptDateToDateString(date) })}
                              dateFormat="dd-MM-yyyy"
                              value={this.state.mMandateEndToDT}
                              placeholderText="Mandate end to"
                              name="mMandateEndTo"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              style={{ width: 150 }}
                              className="form-control MandateDatePickerProperty"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <a href="#" style={{ marginRight: 5 }} onClick={this.onResetFilterClick}>
                    Reset
                  </a>
                  <button type="button" className="btn btn-primary" onClick={this.onApplyFilterClick}>
                    Apply
                  </button>
                  <button type="button" className="btn btn-default" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-11 col-md-10" style={{ display: this.state.pageView == 100 ? "" : "none" }}>
          <ReportGrid
            ref={this.reportGridRef}
            ID="userReports"
            Category="listings"
            super_admin={this.props.super_admin}
            MandateTypesRptDD={this.state.MandateTypesDD}
            PropertyTypesRptDD={this.state.PropertyTypesDD}
            AgentsRptDD={this.props.AgentsRpt}
          />
        </div>

        <div className="col-lg-11 col-md-10" style={{ display: this.state.pageView == 200 ? "" : "none" }}>
          <SyndicationComponent OfficeDD={this.props.OfficeDD} />
        </div>

        <div className="col-lg-11 col-md-10" style={{ display: this.state.pageView == 500 ? "" : "none" }}>
          <PropertyReportsComponent />
        </div>

        <TermsModal show={this.state.showTermsModal} onAccept={this.handleAcceptTerms} onHide={() => this.setState({ showTermsModal: false })} />
      </div>
    );
  }
}

export default withRouter(PropertyGrid);
