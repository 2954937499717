import React, { useEffect } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink and useHistory
import { withRouter } from "react-router-dom"; // Import withRouter
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import $ from "jquery";

import { DataTableLoading } from "../../app/common/common-components/OMSDatatables";
import "../../js/main.js";
import logo from "../../assets/images/entegral-base-white-fix.png";
import apiAgent from "../../app/api/apiAgent";
import { OMSDiaryNoteItem } from "../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteItem";
import * as Sentry from "@sentry/react";
import PlayStoreBanner from "../../assets/images/PlayStoreBanner.png";

class Header extends React.Component {
  static propTypes = {
    IsReadOnly: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    //console.log("Header props", props);

    this.noteItemDashboardRef = React.createRef();
    this.myModal = React.createRef();
    this.mobileMenu = React.createRef();
    //this.history = useHistory(); // Initialize useHistory
  }


  getDefaultData = () => {
    return {
      Models: [],
      Paging: {
        PageNo: 1,
        PageSize: 10,
        Total: 0,
        HasNext: false,
        HasPrevious: false,
        ShowPaging: false,
        ViewPages: [],
      },
    };
  };

  handleSearchOnFocus = (e) => {
    let a = e.target;
    a.style.width = "265px";
  };

  handleSearchOnBlur = (e) => {
    let a = e.target;
    a.style.width = "115px";
  };

  handleSearchChange = (e) => {
    //console.log("inside handleSearchChange", e.target.value);
    this.setState({
      search: e.target.value,
    });
  };

  handleSearchKeyUp = (e) => {
    var code = e.which; // recommended to use e.which, it's normalized across browsers
    if (code == 13 && this.state.search != "") {
      //const myModal = findDOMNode(this.myModal);
      window.$("#myModal").modal("show");
      //this.loadCloudSearchResults();
      this.loadSearchResults();
    }
  };

  onSearchIconClick = (e) => {
    if (this.state.search != "") {
      //const myModal = findDOMNode(this.myModal);
      window.$("#myModal").modal("show");
      //window.$("#myModal").modal('show');
      this.loadSearchResults();
    }
  };

  onSearchIconMobileClick = (e) => {
    if (this.state.search == "") {
      window.$(".hidden-search-bar").toggle();
      window.$(".custom-dropdown").toggle();
    } else {
      //const myModal = findDOMNode(this.myModal);
      window.$("#myModal").modal("show");
      //window.$("#myModal").modal('show');
      this.loadSearchResults();
    }
  };

  onToggleMobileMenu = (e) => {
    //const mobileMenu = findDOMNode(this.mobileMenu);
    //window.$(mobileMenu).toggle();
    window.$(".mobile-menu").toggle();
  };

  onResetFavouriteClick = (moduleID, dataRef) => {
    //console.log("inside onResetFavouriteClick", moduleID, dataRef);
    var data = new FormData();
    data.append("ID", "#");
    data.append("Module", moduleID);
    data.append("DataRef", dataRef);
    /* var xhr = new XMLHttpRequest();
    xhr.open("post", "/Home/ResetFavouriteAjax", true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      if (data.status == "success") {
        this.loadFavouriteResults();
      } else {
        window.$.toaster(data.message, "Fail", "danger");
        //bootbox.alert(data.message);
      }
    }.bind(this);
    xhr.send(data); */
    apiAgent.Home.resetFavouriteAjax(data)
      .then((response) => {
        this.loadFavouriteResults();
      })
      .catch((error) => {
        //window.$.toaster(data.message, "Fail", "danger");
        toast.info(error.message);
      });
  };

  handleFavouriteClick = (e) => {
    /* const myModal = findDOMNode(this.myModal);
    window.$(myModal).modal("show"); */
    window.$("#myModal").modal("show");
    this.loadFavouriteResults();
  };

  loadFavouriteResults = () => {
    this.setState({ data: this.getDefaultData(), loading: true, view: 1 });
    /* var xhr = new XMLHttpRequest();
    xhr.open("get", "/Home/FavouritesAjax", true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      this.setState({ data: data, loading: false });
    }.bind(this);
    xhr.send(); */
    apiAgent.Home.getFavouritesAjax()
      .then((response) => {
        //console.log("inside get Favourited", response.data);
        this.setState({ data: response.data, loading: false });
      })
      .catch((error) => toast.error("Failed to get Favourites"));
  };

  loadSearchResults = () => {
    this.setState({ data: this.getDefaultData(), loading: true, view: 0 });
    /* var xhr = new XMLHttpRequest();
    xhr.open("get", "/Home/SearchAjax?Search=" + this.state.search + "&SearchType=" + this.state.search_type, true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      this.setState({ data: data, loading: false });
    }.bind(this);
    xhr.send(); */
    //console.log("inside loadSearchResults, before ajax call", this.state);
    apiAgent.Home.searchAjax(this.state.search, this.state.search_type)
      .then((response) => {
        // var data = this.state.data;
        // data.Models = response.models;
        // data.Paging = response.paging;
        //console.log("inside loadSearchResults", response.data);
        this.setState({ data: response.data, loading: false });
      })
      .catch((error) => toast.error("Failed to load results"));
  };

  loadCloudSearchResults = () => {
    this.setState({ data: this.getDefaultData(), loading: true, view: 0 });
    var xhr = new XMLHttpRequest();
    xhr.open("get", "/Home/SearchCloudAjax?Search=" + this.state.search, true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      this.setState({ data: data, loading: false });
    }.bind(this);
    xhr.send();
  };

  onNewEventClick = () => {
    this.noteItemDashboardRef.current.onOpenDairyNoteItem(null);
  };

  onHelpClick = () => {
    window.$("#LS_HelpModel").modal("show");
  };
  getDefaultSearchState = () => {
    return { SDNType: 2, Search: "", CurrentSearch: "", Processing: false, Data: [] };
  };

  state = {
    search: "",
    search_type: 0,
    view: 0,
    data: this.getDefaultData(),
    page: 1,
    loading: false,
    SearchComponent: this.getDefaultSearchState(),
  };

  getSearchTypeName = () => {
    if (this.state.search_type == 1) return "Contacts";
    else if (this.state.search_type == 2) return "Documents";
    else if (this.state.search_type == 3) return "Deals";
    else return "Listings";
  };

  changeSearchType = (type) => {
    //console.log("changeSearchType", type);
    // this.setState({ search_type: type });
    // console.log("AfterSetchangeSearchType", this.state.search_type);
    this.setState({ search_type: type }, () => {
      //console.log(this.state.search_type, 'AfterSetchangeSearchType');
    });
  };

  onSearchComponentInputChange = (e) => {
    if (this.delayTimer !== undefined) clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(
      function () {
        if (this.state.SearchComponent.Search != this.state.SearchComponent.CurrentSearch) {
          this.loadSearchComponentResult(this.state.SearchComponent.Search);
        }
      }.bind(this),
      1000
    );

    var searchComponent = this.state.SearchComponent;
    searchComponent.Search = e.target.value;
    this.setState({ SearchComponent: searchComponent });
  };

  renderSearchComponentResult = (e) => {
    if (this.state.SearchComponent.Processing) {
      return <div className="form-inline">Searching....</div>;
    } else if (this.state.SearchComponent.Data.length == 0) {
      return <div className="form-inline"></div>;
    } else {
      return this.state.SearchComponent.Data.map((item) => (
        <div key={"SR" + item.id} className="form-inline searchresult">
          <a onClick={() => this.onSearchComponentSelectClick(item.id, item.name)} style={{ cursor: "pointer" }}>
            <i className={"fa " + item.typeIcon}></i>&nbsp;{item.name}
          </a>
        </div>
      ));
    }
  };

  loadSearchComponentResult = (Search) => {
    var searchComponent = this.state.SearchComponent;
    searchComponent.Processing = true;
    searchComponent.Data = [];
    this.setState({ SearchComponent: searchComponent });

    apiAgent.Deal.searchPropertiesAjax(Search)
      .then((response) => {
        var searchComponent = this.state.SearchComponent;
        searchComponent.CurrentSearch = Search;
        searchComponent.Processing = false;
        searchComponent.Data = response.data;
        this.setState({ SearchComponent: searchComponent });
      })
      .catch((error) => toast.error(error.response.data));
  };

  onSearchComponentSelectClick(id, name) {
    //window.location.href = `/deal/saveDeal?ID=&propertyID=${id}`;
    this.props.history.push(`/deal/saveDeal?ID=&propertyID=${id}`);
    window.$("#dealSearchModel").modal("hide");
  }
  onCreateDealClick = (e) => {
    window.$("#dealSearchModel").modal("show");
  };

  redirectToNew = (e) => {
    if (window.location.href.includes("/Property/SaveProperty")) {
      window.location.href = e
    }
    if (window.location.href.includes("/Contacts/SaveContact")) {
      window.location.href = e
    }
    if (window.location.href.includes("/Library/SaveContent")) {
      window.location.href = e
    }
  };

  render() {
    var self = this;
    var modelHtml = null;
    var data = Array.from(this.state.data);
    if (data && data.length == 0) {
      modelHtml = "No search result found..";
    } else {
      //console.log("inside render of Header:", data);
      modelHtml = data.map(function (item) {
        return self.state.view == 1 ? (
          <div className="form-inline searchresult">
            <span className="fa fa-trash-o" onClick={() => self.onResetFavouriteClick(item.module, item.dataRef)}></span>
            <span className={"fa " + item.typeIcon}></span>
            <a href={item.url}>{item.name}</a>&nbsp;in&nbsp;
            <a href={item.parentUrl}>{item.parentName}</a>
          </div>
        ) : (
          <div className="form-inline searchresult">
            <span className={"fa " + item.typeIcon}></span>
            <a href={item.url}>{item.name}</a>&nbsp;in&nbsp;
            <a href={item.parentUrl}>{item.parentName}</a>
          </div>
        );
      });
    }
    return (
      <header>
        <div className="container-fluid nopadding custom-navbar navbar-fixed-top color1">
          <div className="row nopadding">
            <div className="col-xs-5 col-sm-5 col-md-2 col-lg-2 color1 nopadding rowheight text-center">
              <div className="logo-part">
                <NavLink to="/Home/Index">

                  {this.props.systemlogo == "" ? (
                    <img style={{ marginTop: -6 }} src={logo} className="img-responsive d-block" />
                  ) : (
                    <img style={{ maxWidth: 140, maxHeight: 45, marginTop: -10 }} src={this.props.systemlogo} className="img-responsive d-block" />
                  )}
                </NavLink>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-sm-3 col-md-3 col-lg-4 color1 nopadding rowheight special-padding special-width">
              <div className="search-text">
                <div className="input-group" id="search-text-group">
                  <div className="input-group-btn">
                    <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {this.getSearchTypeName()}&nbsp;
                      <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                      <li>
                        <a onClick={() => this.changeSearchType(0)}>Listings</a>
                      </li>
                      <li>
                        <a onClick={() => this.changeSearchType(1)}>Contacts</a>
                      </li>
                      <li>
                        <a onClick={() => this.changeSearchType(2)}>Documents</a>
                      </li>
                      <li>
                        <a onClick={() => this.changeSearchType(3)}>Deals</a>
                      </li>
                    </ul>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleSearchChange}
                    onFocus={this.handleSearchOnFocus}
                    //onBlur={this.handleSearchOnBlur}
                    onKeyUp={this.handleSearchKeyUp}
                    value={this.state.search}
                    name="search-input"
                    id="search-input"
                  />
                  <i className="fa fa-search" aria-hidden="true" onClick={this.onSearchIconClick} />
                </div>
              </div>
            </div>
            <div className="col-xs-3 col-sm-3 col-md-2 col-lg-2 color1 nopadding rowheight item-new">
              {this.props.IsReadOnly ? null : (
                <div className="dropdown custom-dropdown" style={{ marginRight: "25px" }}>
                  <button
                    className="btn btn-default dropdown-toggle"
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> New <span className="caret" />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                    <li>
                      <NavLink to="/Property/SaveProperty" onClick={() => this.redirectToNew("/Property/SaveProperty")} title="New Listing">
                        <i className="fa fa-home fa-fw" aria-hidden="true" /> Listing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Contacts/SaveContact" onClick={() => this.redirectToNew("/Contacts/SaveContact")} title="New Contact">
                        <i className="fa fa-address-card-o fa-fw" aria-hidden="true" /> Contact
                      </NavLink>
                    </li>
                    {this.props.IsAdmin ? (
                      <li>
                        <NavLink to="/Library/SaveContent" onClick={() => this.redirectToNew("/Library/SaveContent")} title="New News">
                          <i className="fa fa-file-word-o fa-fw" aria-hidden="true" /> News
                        </NavLink>
                      </li>
                    ) : null}
                    <li>
                      <a href="#" onClick={this.onNewEventClick} title="New Activity">
                        <i className="fa fa-calendar fa-fw" aria-hidden="true" /> Activity
                      </a>
                    </li>
                    {this.props.Modules?.includes(1) ? (
                      <li>
                        <a href="#" onClick={() => this.onCreateDealClick()} title="New Deal">
                          <i className="fa fa-bullseye fa-fw" aria-hidden="true" /> Deal
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              )}
            </div>
            <div className="col-xs-4 col-sm-4 hidden-md hidden-lg color1 rowheight mobile-menu-part text-right">
              <div className="search-text hidden-search-bar" style={{ marginTop: 18, height: "auto" }}>
                <div className="input-group" id="search-text-group">
                  <div className="input-group-btn">
                    <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {this.getSearchTypeName()}&nbsp;
                      <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                      <li>
                        <a onClick={() => this.changeSearchType(0)}>Listings</a>
                      </li>
                      <li>
                        <a onClick={() => this.changeSearchType(1)}>Contacts</a>
                      </li>
                      <li>
                        <a onClick={() => this.changeSearchType(2)}>Documents</a>
                      </li>
                      <li>
                        <a onClick={() => this.changeSearchType(3)}>Deals</a>
                      </li>
                    </ul>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleSearchChange}
                    onKeyUp={this.handleSearchKeyUp}
                    value={this.state.search}
                    name="search-input"
                    id="search-input"
                  />
                </div>
              </div>

              <div className="mobile-buttons">
                <div className="btn-search" onClick={this.onSearchIconMobileClick}>
                  <i className="fa fa-search" aria-hidden="true" />
                </div>
                <button className="btn-menu" onClick={this.onToggleMobileMenu}>
                  <i className="fa fa-bars" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div id="mobile-menu" className="mobile-menu" ref={this.mobileMenu}>
              <div className="col-xs-6 col-sm-6 col-md-3 col-lg-2 nopadding rowheight list-part">
                <ul className="list-inline">
                  <li>
                    <NavLink to="/Home" className="user1" data-placement="bottom" data-toggle="tooltip" title="Dashboard">
                      <i className="fa fa-tachometer user-font1" aria-hidden="true" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/Property" className="user1" data-placement="bottom" data-toggle="tooltip" title="Listings">
                      <i className="fa fa-home user-font1" aria-hidden="true" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/Contacts" className="user1" data-placement="bottom" data-toggle="tooltip" title="Contacts">
                      <i className="fa fa-address-book-o user-font1" aria-hidden="true" />
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/Library" className="user1" data-placement="bottom" data-toggle="tooltip" title="Library">
                      <i className="fa fa-file-text-o user-font1" aria-hidden="true" />
                    </NavLink>
                  </li>

                  {this.props.Modules?.includes(1) ? (
                    <li>
                      <NavLink to="/deal" className="user1" data-placement="bottom" data-toggle="tooltip" title="Deals">
                        <i className="fa fa-bullseye user-font1" aria-hidden="true" />
                      </NavLink>
                    </li>
                  ) : null}
                </ul>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2 color2 nopadding rowheight text-center user-menu-part">
                <div className="user-menu">
                  <div className="pull-left">
                    <div className="dropdown">
                      <button
                        className="btn btn-default dropdown-toggle user2"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <span>{this.props.displayname}</span>
                        <i className="fa fa-cog" aria-hidden="true" />
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li>
                          <NavLink data-intercom-target="profile" to={"/Settings/SaveUser/" + this.props.userid}>
                            <i className="fa fa-user fa-fw" aria-hidden="true" /> My Profile
                          </NavLink>
                        </li>
                        <li>
                          <NavLink data-intercom-target="settings" to={"/Settings"}>
                            <i className="fa fa-cog fa-fw" aria-hidden="true" style={{ paddingLeft: 0 }} /> Settings
                          </NavLink>
                        </li>
                        <li>
                          <a href="#" onClick={this.onHelpClick}>
                            <i className="fa fa-question-circle fa-fw" aria-hidden="true" /> Help
                          </a>
                        </li>
                        {this.props.loginas == true ? (
                          <li>
                            <a href="#" onClick={this.props.logoutAs}>
                              <i className="fa fa-lock fa-fw" aria-hidden="true" /> Sign out (as)
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a href="#" onClick={this.props.logout}>
                              <i className="fa fa-lock fa-fw" aria-hidden="true" /> Sign out
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="pull-left right-menu-icons">
                    <ul className="list-inline">
                      <li>
                        <a href="#" onClick={this.handleFavouriteClick} className="user2" data-placement="bottom" data-toggle="tooltip" title="Favourites">
                          <i className="fa fa-star-o fa-right-menu" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a id="help_link" href="#" className="user2" data-placement="bottom" data-toggle="tooltip" title="Live help">
                          <i className="fa fa-commenting-o fa-right-menu" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="myModal" ref={this.myModal} tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                <h4 className="modal-title text-center" id="myModalLabel">
                  {this.state.view == 1 ? "Favourites" : "Search:" + this.state.search}
                </h4>
              </div>
              <div className="modal-body">
                <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                  {this.state.loading != true ? modelHtml : <DataTableLoading loading={this.state.loading} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="LS_HelpModel" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" style={{ fontsize: "34" }}>
                  &times;
                </button>
                <h4 className="modal-title">Base: Help</h4>
              </div>
              <div className="modal-body">
                <div className="row is-flex" style={{ margintop: "40px", marginbottom: "20px" }}>
                  <div className="col-md-3 col-sm-6 col-xs-6 text-center">
                    <div className="omsactionbutton">
                      <a href="https://help.entegral.net/" className="omsactionbutton" target="_blank">
                        <span className="fa fa-question-circle fa-5x"></span>
                        <br />
                        <label>Help files</label>
                        <p>for all systems</p>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-6 text-center">
                    <div className="omsactionbutton">
                      <a
                        href="https://docs.google.com/forms/u/2/d/e/1FAIpQLSd-vKW07fFHE2me_Kv1kbHl3p6DqOCC_yhDlqO3lJuDwaFqjQ/viewform"
                        className="omsactionbutton"
                        target="_blank"
                      >
                        <span className="fa fa-rocket fa-5x"></span>
                        <br />
                        <label>Suggest a feature</label>
                        <p>or tweak for Base</p>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-6 text-center">
                    <div className="omsactionbutton" data-intercom-target="livechat">
                      <span className="fa fa-commenting-o fa-5x"></span>
                      <br />
                      <label>Live chat</label>
                      <p>for fastest response</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-6 text-center">
                    <div className="omsactionbutton">
                      <a href="http://status.entegral.net/" className="omsactionbutton" target="_blank">
                        <span className="fa fa-bell-o fa-5x"></span>
                        <br />
                        <label>Status dashboard</label>
                        <p>status.entegral.net</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-left" style={{ marginBottom: "-19px", position: "relative" }}>
                <a
                  href="https://play.google.com/store/apps/details?id=net.entegral.base.twa&pcampaignid=web_share"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ position: "relative", zIndex: 1 }}
                >
                  <img
                    src={PlayStoreBanner}
                    alt="Google Play logo"
                    style={{ maxWidth: "185px", height: "50px", marginTop: "-69px", marginLeft: "56px", cursor: "pointer" }}
                  />
                </a>
              </div>

              <div className="modal-footer">
                <p className="text-center">
                  Made with <i className="fa fa-heart" style={{ fontsize: "15px", ariahidden: "true" }}></i> by{" "}
                  <a href="http://www.entegral.net" target="_blank">
                    Entegral
                  </a>{" "}
                  <a href="https://www.facebook.com/entegraltechnologies" target="_blank">
                    <i className="fa fa-facebook-square" style={{ fontsize: "24px", marginleft: "8px" }}></i>
                  </a>
                  &nbsp;
                  <a href="https://twitter.com/entegral" target="_blank">
                    <i className="fa fa-twitter-square" style={{ fontsize: "24px", marginleft: "5px", marginright: "5px" }}></i>
                  </a>
                  &nbsp;<a href="mailto:support@entegral.net">support@entegral.net</a> | help available week days 8am-4pm
                </p>
              </div>
            </div>
          </div>
        </div>
        <OMSDiaryNoteItem
          ref={this.noteItemDashboardRef}
          htmlID="noteItemDashboard"
          ID="noteItemDashboard"
          IsReadOnly={this.props.IsReadOnly}
          OnShowStart={this.props.OnShowStart}
          OnShowEnd={this.props.OnShowEnd}
          DNType={0}
          DiaryActionTags={this.props.DiaryActionTags}
        />
        <HeaderIntercom />
        <div className="modal fade" id={"dealSearchModel"} tabIndex={-1} data-backdrop="static" aria-labelledby={"DealSearchModelLabel"} aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
                <h4 className="modal-title" id={"DealSearchModelLabel"}>
                  Search listing
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group has-feedback">
                      <label htmlFor="SearchComponent.Search">Search by street name, complex name, or webref</label>
                      <input
                        id="SearchComponent.Search"
                        name="SearchComponent.Search"
                        type="text"
                        className="form-control"
                        value={this.state.SearchComponent.Search}
                        onChange={this.onSearchComponentInputChange}
                      />
                      <span
                        className={this.state.SearchComponent.Processing ? "fa fa-spin fa-spinner form-control-feedback" : "fa fa-search form-control-feedback"}
                        style={{ marginTop: 5, maxHeight: 20 }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">{this.renderSearchComponentResult()}</div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

// export default Header;
export default withRouter(Header);

export const HeaderIntercom = () => {
  const {
    userStore: { userSession, isLoggedIn },
  } = useStore();
  const baseUrl = process.env.REACT_APP_ROOT_URL;

  useEffect(() => {
    if (process.env.NODE_ENV != "development") {
      console.log("Intercom Initializing");
      window.Intercom("boot", {
        app_id: "iltrt02r",
        custom_launcher_selector: "#help_link",
        hide_default_launcher: true,
        name: userSession?.displayName,
        email: userSession?.emailAddress,
        phone: userSession?.phone,
        created_at: userSession?.createdAt,
        "System Person ID": userSession?.syncUserID,
        "System Office ID": userSession?.syncOfficeID,
        "Base user link": `https://base.entegral.net/Settings/SaveUser/${userSession?.userId}`,
        "Base office link": `https://base.entegral.net/Settings/SaveOffice?ID=${userSession?.officeID}`,
        Product: "Base",
        "Base user role": userSession?.role,
        company: {
          id: userSession?.officeID,
          name: userSession?.officeName,
          website: userSession?.officeWebsite,
          created_at: userSession?.officeCreatedAt,
        },
        avatar: {
          type: "avatar",
          image_url: userSession?.profilePic,
        },
      });
    }

    if(process.env.NODE_ENV != "development"){
      Sentry.setUser({ id: userSession?.userId, username: userSession?.displayName, email: userSession?.emailAddress });
    }
  }, [userSession, isLoggedIn]);

  return <></>;
};
