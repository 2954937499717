import React from "react";
import { toast } from "react-toastify";
import apiAgent from "../../../app/api/apiAgent";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import { OMSNumericBox } from "../../../app/common/common-components/OMSComponent";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { OMSFavourite } from "../../../app/common/common-components/OMSFavourite";
import { OMSImageUpload } from "../../../app/common/common-components/OMSImageUpload";
import { OMSTabBox } from "../../../app/common/common-components/OMSTabBox";
import { OMSMultiselectDropdown } from "../../../app/common/common-components/OMSMultiselectDropdown";
import { OMSGeoLocation } from "../../../app/common/common-components/OMSGeoLocation";
import bootbox from "bootbox";
import ConfirmationModalPopup from "../../../app/common/common-components/ModalPopups/ConfirmationModalPopup";
import { withRouter } from 'react-router-dom';


class P24AreaMapBox extends React.Component {
  state = {
    BaseProvinceDD: [],
    BaseTownDD: [],
    BaseSuburbDD: [],
    P24ProvinceDD: [],
    P24TownDD: [],
    P24SuburbDD: [],
    P24ProvinceID: "",
    P24TownID: "",
    P24SuburbID: "",
    BaseProvinceID: "",
    BaseTownID: "",
    BaseSuburbID: "",
    CountryName: "South Africa",
    P24Areas: [],
    P24MappingList: this.props.P24MappingList,
    confirmationModalProps: {
      showModal: false,
      isConfirmed: (result) => {},
      title: "",
      body: "",
    },
  };

  componentDidMount() {
    this.loadP24DataFromServer(this.state.CountryName);
    this.loadProvincesDDFromServer(this.state.CountryName);
    //console.log("inside componentDidMount, this.props.P24MappingList=", this.props.P24MappingList);
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  onCancelClick = (e) => {
    this.props.history.push('/Settings');
  };

  handleCountryDDChange = async (e) => {
    this.loadP24DataFromServer(e.newValue);
    this.loadProvincesDDFromServer(e.newValue);
  };

  handleP24DDChange = (e) => {
    if (e.name == "P24ProvinceID") this.loadP24TownDD(e.newValue);
    else if (e.name == "P24TownID") this.loadP24SuburbDD(e.newValue, this.state.P24ProvinceID);
    else this.setState({ P24SuburbID: e.newValue });
  };

  handleBaseDDChange = (e) => {
    if (e.name == "BaseProvinceID") this.loadTownsDDFromServer(e.newValue);
    else if (e.name == "BaseTownID") this.loadSuburbsDDFromServer(e.newValue);
    else this.setState({ BaseSuburbID: e.newValue });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  setToP24MappingList = (item, isdeleted) => {
    var arrayP24 = this.state.P24MappingList;
    var index = -1;
    for (var i = 0; i < arrayP24.length; i++) {
      if (arrayP24[i].sourceId == item.sourceId && arrayP24[i].keyArea == item.keyArea) {
        index = i;
        break;
      }
    }
    if (index == -1 && isdeleted == false) arrayP24.push(item);
    else {
      if (isdeleted == true) {
        arrayP24.splice(index, 1);
      } else {
        arrayP24[index].portalSuburb = item.portalSuburb;
        arrayP24[index].portalTown = item.portalTown;
        arrayP24[index].portalProvince = item.portalProvince;
      }
    }
    this.setState({ P24MappingList: arrayP24 });
  };

  loadP24ProvinceDD = () => {
    var P24ProvinceDD = [];
    P24ProvinceDD.push({ text: "- Select -", value: "" });
    //console.log("inside loadP24ProvinceDD,this.state.P24Areas=", this.state.P24Areas);

    var uniqueProvinces = this.state.P24Areas.map(function (item) {      
      return item.province;
    });

    
    uniqueProvinces = uniqueProvinces.filter(function (value, index, self) {
      return self.indexOf(value) === index;
    });

    uniqueProvinces.sort();
    //console.log("inside loadP24ProvinceDD,uniqueProvinces=", uniqueProvinces);

    P24ProvinceDD = P24ProvinceDD.concat(
      uniqueProvinces.map(function (item) {
        return { text: item, value: item };
      })
    );

    var P24SuburbDD = [];
    P24SuburbDD.push({ text: "- Select -", value: "" });

    var P24TownDD = [];
    P24TownDD.push({ text: "- Select -", value: "" });

    this.setState({
      P24ProvinceDD: P24ProvinceDD,
      P24TownDD: P24TownDD,
      P24SuburbDD: P24SuburbDD,
      P24ProvinceID: "",
      P24TownID: "",
      P24SuburbID: "",
    });
  };

  loadP24TownDD = (P24ProvinceID) => {
    var P24TownDD = [];
    P24TownDD.push({ text: "- Select -", value: "" });

    var uniqueTowns = this.state.P24Areas.filter(function (value) {
      return value.province === P24ProvinceID;
    });
    var uniqueTowns = uniqueTowns.map(function (item) {
      return item.city;
    });
    uniqueTowns = uniqueTowns.filter(function (value, index, self) {
      return self.indexOf(value) === index;
    });

    uniqueTowns.sort();

    P24TownDD = P24TownDD.concat(
      uniqueTowns.map(function (item) {
        return { text: item, value: item };
      })
    );

    var P24SuburbDD = [];
    P24SuburbDD.push({ text: "- Select -", value: "" });

    this.setState({
      P24TownDD: P24TownDD,
      P24SuburbDD: P24SuburbDD,
      P24ProvinceID: P24ProvinceID,
      P24TownID: "",
      P24SuburbID: "",
    });
  };

  loadP24SuburbDD = (P24TownID, P24ProvinceID) => {
    var P24SuburbDD = [];
    P24SuburbDD.push({ text: "- Select -", value: "" });

    var uniqueSuburbs = this.state.P24Areas.filter(function (value) {
      return value.province === P24ProvinceID && value.city === P24TownID;
    });
    var uniqueSuburbs = uniqueSuburbs.map(function (item) {
      return item.suburb;
    });

    uniqueSuburbs.sort();

    P24SuburbDD = P24SuburbDD.concat(
      uniqueSuburbs.map(function (item) {
        return { text: item, value: item };
      })
    );

    this.setState({
      P24SuburbDD: P24SuburbDD,
      P24ProvinceID: P24ProvinceID,
      P24TownID: P24TownID,
      P24SuburbID: "",
    });
  };

  loadP24DataFromServer = async (countryName) => {
    this.setState({
      BaseProvinceDD: [],
      BaseTownDD: [],
      BaseSuburbDD: [],
      BaseProvinceID: "",
      BaseTownID: "",
      BaseSuburbID: "",
    });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Admin/P24AreaAjax?Country=' + countryName, true);
        xhr.onload = function () {
          var data = JSON.parse(xhr.responseText);
          this.setState({ P24Areas: data, CountryName: countryName });
          this.loadP24ProvinceDD();
        }.bind(this);
        xhr.send(); */
    apiAgent.Admin.p24AreaAjax(countryName)
      .then((response) => {
        //console.log("inside loadP24DataFromServer, response=", response[0]);
        this.setState({ P24Areas: response, CountryName: countryName },this.loadP24ProvinceDD);
        //this.loadP24ProvinceDD();
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  loadProvincesDDFromServer = (countryName) => {
    this.setState({
      BaseProvinceDD: [],
      BaseTownDD: [],
      BaseSuburbDD: [],
      BaseProvinceID: "",
      BaseTownID: "",
      BaseSuburbID: "",
    });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Settings/ProvincesDDAjax?Country=' + countryName, true);
        xhr.onload = function () {
          var data = JSON.parse(xhr.responseText);
          this.setState({ BaseProvinceDD: data, CountryName: countryName });
        }.bind(this);
        xhr.send(); */
    apiAgent.Setting.provincesDDAjax(countryName)
      .then((response) => {
        this.setState({ BaseProvinceDD: response, CountryName: countryName });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  loadTownsDDFromServer = (ProvinceID) => {
    this.setState({
      BaseProvinceID: ProvinceID,
      BaseTownDD: [],
      BaseSuburbDD: [],
      BaseTownID: "",
      BaseSuburbID: "",
    });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Settings/TownsDDAjax?ProvinceID=' + ProvinceID, true);
        xhr.onload = function () {
          var data = JSON.parse(xhr.responseText);
          this.setState({ BaseTownDD: data });
        }.bind(this);
        xhr.send(); */
    apiAgent.Setting.townsDDAjax(ProvinceID)
      .then((response) => {
        this.setState({ BaseTownDD: response });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  loadSuburbsDDFromServer = (TownID) => {
    this.setState({ BaseTownID: TownID, BaseSuburbDD: [], BaseSuburbID: "" });
    /* var xhr = new XMLHttpRequest();
        xhr.open('get', '/Settings/SuburbsDDAjax?TownID=' + TownID, true);
        xhr.onload = function () {
          var data = JSON.parse(xhr.responseText);
          this.setState({ BaseSuburbDD: data });
        }.bind(this);
        xhr.send(); */
    apiAgent.Setting.suburbsDDAjax(TownID)
      .then((response) => {
        this.setState({ BaseSuburbDD: response });
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  onClearClick = (e) => {
    this.loadP24ProvinceDD();
    this.loadProvincesDDFromServer();
  };

  executeDeleteClick = () => {
    this.setState({ processing: true });
    /* var data = new FormData();
        data.append('sourceId', sourceId);
        data.append('keyArea', keyArea);
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Admin/DeleteP24AreaMapAjax', true);
        xhr.onload = function () {
          this.setState({ processing: false });
          var data = JSON.parse(xhr.responseText);
          if (data.status == "success") {
            var item = { sourceId: sourceId, keyArea: keyArea };
            this.setToP24MappingList(item, true);
          }
          else {
            $.toaster(data.message, 'Fail', 'danger');
          }
        }.bind(this);
        xhr.send(data); */
    apiAgent.Admin.deleteP24AreaMapAjax(this.state.SourceId, this.state.KeyArea)
      .then((response) => {
        var item = {
          sourceId: this.state.SourceId,
          keyArea: this.state.keyArea,
        };
        this.setToP24MappingList(item, true);
      })
      .catch((error) => {
        toast.info("Fail");
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  onDeleteClick = (sourceId, keyArea) => {
    /* bootbox.confirm({
      title: "Delete mapping?",
      message: "Do you want to delete mapping now? This cannot be undone.",
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Cancel',
        },
        confirm: {
          label: '<i class="fa fa-check"></i> Confirm',
        },
      },
      callback: function (result) {
        if (result) {
          this.executeDeleteClick(sourceId, keyArea);
        }
      }.bind(this),
    }); */
    this.setState({ SourceId: sourceId });
    this.setState({ KeyArea: keyArea });
    let title = "Delete mapping?";
    let body = "Do you want to delete mapping now? This cannot be undone.";
    let callback = this.executeDeleteClick;
    this.showPopupAndDeleteOnConfirmation(title, body, callback);
  };

  onSaveClick = (e) => {
    if (
      this.state.BaseProvinceID == "" ||
      this.state.BaseTownID == "" ||
      this.state.BaseSuburbID == "" ||
      this.state.P24ProvinceID == "" ||
      this.state.P24TownID == "" ||
      this.state.P24SuburbID == ""
    ) {
      toast.info("Please select areas properly");
      return;
    }

    this.setState({ processing: true });
    /* var data = new FormData();
        data.append('BaseProvinceID', this.state.BaseProvinceID);
        data.append('BaseTownID', this.state.BaseTownID);
        data.append('BaseSuburbID', this.state.BaseSuburbID);
        data.append('P24Province', this.state.P24ProvinceID);
        data.append('P24Town', this.state.P24TownID);
        data.append('P24Suburb', this.state.P24SuburbID);
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Admin/P24AreaMapAjax', true);
        xhr.onload = function () {
            this.setState({ processing: false });
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                $.toaster(data.message, 'Success', 'info');
                this.setToP24MappingList(data.result, false);
            }
            else
                $.toaster(data.message, 'Fail', 'danger');

        }.bind(this);
        xhr.send(data); */
    apiAgent.Admin.p24AreaMapAjax(
      this.state.BaseProvinceID,
      this.state.BaseTownID,
      this.state.BaseSuburbID,
      this.state.P24ProvinceID,
      this.state.P24TownID,
      this.state.P24SuburbID
    )
      .then((response) => {
        toast.info(response.message);
        this.setToP24MappingList(response.result, false);
      })
      .catch((error) => {
        toast.info("Fail");
      })
      .finally(() => {
        this.setState({ processing: false });
      });
  };

  render() {
    return (
      <div>
        <ConfirmationModalPopup {...this.state.confirmationModalProps} />
        <div className="col-sm-12">
          <div className="panel panel-default oms-box">
            <div className="panel-heading">
              <div className="row">
                <div className="col-md-12">
                  <label>P24 Area Mapping</label>
                </div>
              </div>
            </div>
            <div className="panel-body">
              <div className="col-md-12">
                <div className="form-group form-inline">
                  <label htmlFor="CountryName" style={{ width: 80 }}>
                    Country
                  </label>
                  <OMSDropdown
                    id="CountryName"
                    options={this.props.CountryDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.CountryName}
                    onChange={this.handleCountryDDChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Base Areas</label>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="BaseProvinceID" style={{ width: 80 }}>
                    Province
                  </label>
                  <OMSDropdown
                    id="BaseProvinceID"
                    options={this.state.BaseProvinceDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.BaseProvinceID}
                    onChange={this.handleBaseDDChange}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="BaseTownID" style={{ width: 80 }}>
                    Town
                  </label>
                  <OMSDropdown
                    id="BaseTownID"
                    options={this.state.BaseTownDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.BaseTownID}
                    onChange={this.handleBaseDDChange}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="BaseSuburbID" style={{ width: 80 }}>
                    Suburb
                  </label>
                  <OMSDropdown
                    id="BaseSuburbID"
                    options={this.state.BaseSuburbDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.BaseSuburbID}
                    onChange={this.handleBaseDDChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Matching P24 Areas</label>
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="P24ProvinceID" style={{ width: 80 }}>
                    Province
                  </label>
                  <OMSDropdown
                    id="P24ProvinceID"
                    options={this.state.P24ProvinceDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.P24ProvinceID}
                    onChange={this.handleP24DDChange}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="P24TownID" style={{ width: 80 }}>
                    Town
                  </label>
                  <OMSDropdown
                    id="P24TownID"
                    options={this.state.P24TownDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.P24TownID}
                    onChange={this.handleP24DDChange}
                  />
                </div>
                <div className="form-group form-inline">
                  <label htmlFor="P24SuburbID" style={{ width: 80 }}>
                    Suburb
                  </label>
                  <OMSDropdown
                    id="P24SuburbID"
                    options={this.state.P24SuburbDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.P24SuburbID}
                    onChange={this.handleP24DDChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="pull-right">
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-check"
                    ui_type="save"
                    ui_text="Save"
                    ui_processing_text="Saving.."
                    processing={this.state.processing}
                    disabled={this.state.processing}
                    onClick={this.onSaveClick}
                  />
                  <OMSButtonStyleL
                    style={{ marginRight: 10 }}
                    type="button"
                    ui_icon="fa-times"
                    ui_type="cancel"
                    ui_text="Clear"
                    ui_processing_text=""
                    processing={false}
                    disabled={false}
                    onClick={this.onClearClick}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <br />
                <br />
                <table className="table table-hover table-responsive table-extended">
                  <thead>
                    <tr>
                      <th>sourceId</th>
                      <th>portalName</th>
                      <th>baseArea</th>
                      <th>portalArea</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.P24MappingList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.sourceId}</td>
                          <td>{item.portalName}</td>
                          <td>{item.sourceSuburb + ", " + item.sourceTown + ", " + item.sourceProvince}</td>
                          <td>{item.portalSuburb + ", " + item.portalTown + ", " + item.portalProvince}</td>
                          <td>
                            <button type="button" className="btn btn-danger" onClick={() => this.onDeleteClick(item.sourceId, item.keyArea)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="panel-footer">
              <div className="row">
                <div className="col-md-12">
                  <div className="pull-right">
                    <OMSButtonStyleL
                      style={{ marginRight: 10 }}
                      type="button"
                      ui_icon="fa-times"
                      ui_type="cancel"
                      ui_text="Cancel"
                      ui_processing_text=""
                      processing={false}
                      disabled={false}
                      onClick={this.onCancelClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(P24AreaMapBox);
